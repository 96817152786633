import { IOEntryModeEnum } from "../types/SettingsTypes";
import { ChassisProject, LocAttributeInfo } from "../types/ProjectTypes";
import {
    AIBits,
    AOBits,
    DIBits,
    DOBits,
    IOBitNameToValue,
    IOBitset,
    IOFilterMasks,
    IOModuleSelection,
    IOPoints,
    PointTypeFilterMask,
    UserSelectedPoints
} from "../types/IOModuleTypes";
import { PointEntryInfo, PointEntrySectionInfo } from "../types/IOPointEntryTypes";
import { IOModuleMap } from "../types/EngDataTypes";
import { getIOFilterMasksFromLoc } from "../implementation/ImplHardwareGen";
import { getLocationSettings } from "./ChassisProject";


export const getPointTypeDisplayTitle = (typeIDBitset: number, abbreviate: boolean, abbreviateInOut = false): string => {
    let title = '';

    // Digital/Analog
    if ((typeIDBitset & IOBitset.Digital) !== 0)
        title = (abbreviate ? 'D' : 'Digital ');
    else
        title = (abbreviate ? 'A' : 'Analog ');

    // Input/Ouput
    if (!abbreviate && abbreviateInOut) {
        if ((typeIDBitset & IOBitset.Input) !== 0)
            title += 'In';
        else
            title += 'Out';

    }
    else {
        if ((typeIDBitset & IOBitset.Input) !== 0)
            title += (abbreviate ? 'I' : 'Input');
        else
            title += (abbreviate ? 'O' : 'Output');
    }

    return title;
}


export const getPointTypeBitsFromText = (text: string): number => {
    let typeBits = 0;
    const lcText = text.toLowerCase();

    if (lcText.indexOf('input') > -1)
        typeBits |= IOBitset.Input;
    else if (lcText.indexOf('output') > -1)
        typeBits |= IOBitset.Output;
    else
        return 0; // something is wrong...

    if (lcText.indexOf('analog') > -1)
        typeBits |= IOBitset.Analog;
    else if (lcText.indexOf('digital') > -1)
        typeBits |= IOBitset.Digital;
    else
        return 0; // something is wrong...


    return typeBits;
}

export const hasIOPointTypeQuantity = (points: IOPoints): boolean => {
    return (points.input > 0 || points.output > 0 || points.selfCfg > 0);
}


export const loadIOPointFilterMap = (mapPointSpec: Map<number, number>, mapCatalogToIOInfo: IOModuleMap) => {
    mapPointSpec.clear();

    let DISpec = DIBits;
    let DOSpec = DOBits;
    let AISpec = AIBits;
    let AOSpec = AOBits;

    mapCatalogToIOInfo.forEach((value) => {
        // Clear the mask out the bits we do not care about.
        // This will leave just the Hart/CC/XT/etc. bits.
        // We omit DI/DO/AI/AO bits.
        const masked = (value.IOMask & PointTypeFilterMask);

        // Determine if we should add the combo bit.
        const comboBit = ((value.IOMask & IOBitset.Combo) !== 0 ? IOBitset.Combo : 0);
        // If the mask contains a 'specialty' bit...
        if (masked !== 0) {
            // Add the specialty bit(s) to the spec for DI/DO/AI/AO
            if ((value.IOMask & DIBits) === DIBits)
                DISpec |= (masked | comboBit);
            if ((value.IOMask & DOBits) === DOBits)
                DOSpec |= (masked | comboBit);
            if ((value.IOMask & AIBits) === AIBits)
                AISpec |= (masked | comboBit);
            if ((value.IOMask & AOBits) === AOBits)
                AOSpec |= (masked | comboBit);
        }
    });

    // Polulate the map with our results for DI/DO/AI/AO
    mapPointSpec.set(DIBits, DISpec);
    mapPointSpec.set(DOBits, DOSpec);
    mapPointSpec.set(AIBits, AISpec);
    mapPointSpec.set(AOBits, AOSpec);
}

// Note: this may need to be moved to a Platform Specific
// function (ie PlatformImplementation API call).
export const getIOFilterMasksFromLocationAttr = (locAttrInfo: LocAttributeInfo): IOFilterMasks => {
    return getIOFilterMasksFromLoc(locAttrInfo);
}

export const getIOFilterMasksFromProject = (project: ChassisProject): IOFilterMasks => {
    const loc = getLocationSettings(project);
    return getIOFilterMasksFromLoc(loc);
}

export const createNewPointEntryInterface = (): PointEntryInfo => {
    return {
        typeID: 0,
        filterInclude: 0,
        filterExclude: 0,
        moduleCatalogs: [],
        moduleDisplayStrs: [],
        points: 0,
        advancedModule: undefined,
        basicModule: undefined,
        isAdvModDefault: false,
        OnChanged: () => { return; },
        advancedModCount: 0,
        basicModCount: 0,
        moduleSelInMultipleEntries: false,
        onDelete: null,
        onDuplicate: null,
        indexEntry: 0,
        platform: '',
        savedSelectionInvalid: false,
        invalidEntry: false,
        consolidateModule: false,
        selected: false,
    };
}

export const getUserModuleSelectionsFromPointSectionInfo = (pointEntryInfo: PointEntrySectionInfo, mode: IOEntryModeEnum): IOModuleSelection[] => {
    const ModuleSelections: IOModuleSelection[] = [];
    const mapCatalogToUserSelections = new Map<string, UserSelectedPoints[]>();
    const mapCatalogToQuantity = new Map<string, number>();


    pointEntryInfo.entries.forEach((entry) => {
        if (entry.invalidEntry === false) {
            if (mode === IOEntryModeEnum.Basic) {
                if (entry.basicModule != null) {
                    const userSel: UserSelectedPoints = { typeID: entry.typeID, userPointCount: entry.points };
                    const entryGroup: IOModuleSelection = {
                        catalog: entry.basicModule,
                        quantity: entry.basicModCount,
                        selectedPoints: [userSel],
                    };
                    ModuleSelections.push(entryGroup)
                }
            }
            else {
                if (entry.advancedModule != null) {
                    // If we are NOT consolidating modules...
                    if (entry.consolidateModule === false) {
                        const userSel: UserSelectedPoints = { typeID: entry.filterInclude, userPointCount: entry.points };
                        const entryGroup: IOModuleSelection = {
                            catalog: entry.advancedModule,
                            quantity: entry.advancedModCount,
                            selectedPoints: [userSel],
                        };
                        ModuleSelections.push(entryGroup)
                    }
                    else {
                        // We are consolidating modules, which means for each
                        // catalog, we add the points requested together.
                        const newMapEntry: UserSelectedPoints = { typeID: entry.filterInclude, userPointCount: entry.points };

                        const selection = mapCatalogToUserSelections.get(entry.advancedModule);
                        if (selection == null) {
                            mapCatalogToUserSelections.set(entry.advancedModule, [newMapEntry]);
                        }
                        else {
                            selection.push(newMapEntry);
                        }

                        if (mapCatalogToQuantity.get(entry.advancedModule) == null) {
                            mapCatalogToQuantity.set(entry.advancedModule, entry.advancedModCount);
                        }
                    }
                }
            }
        }
    });

    mapCatalogToUserSelections.forEach((selectedPointEntry, catalog) => {
        const quantity: number | undefined = mapCatalogToQuantity.get(catalog);
        if (selectedPointEntry != null && quantity != null) {
            const entryGroup: IOModuleSelection = { catalog: catalog, quantity: quantity, selectedPoints: selectedPointEntry };
            ModuleSelections.push(entryGroup);
        }
    });

    return ModuleSelections;
}

export const setAllPointEntriesSelStatus = (selectAll: boolean, info: PointEntrySectionInfo) => {
    info.entries.forEach((entry) => {
        entry.selected = selectAll;
    })
}

export const getIOPointFilterNameToValMap = (
    specialtyMask: number,
    addAnyOption = true,
    addComboModOption = false)
    : IOBitNameToValue[] => {
    const ioSpecialtyMapFilters: IOBitNameToValue[] = [];
    if (addAnyOption)
        ioSpecialtyMapFilters.push({ name: 'Any', value: 0 });

    // After the Any Option, we will add the rest in alphabetical order.
    if (addComboModOption && (specialtyMask & IOBitset.Combo) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Combo Module', value: IOBitset.Combo });

    if ((specialtyMask & IOBitset.AnalogCurrent) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Current', value: IOBitset.AnalogCurrent });

    if ((specialtyMask & IOBitset.Diagnostic) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Diagnostic', value: IOBitset.Diagnostic });

    if ((specialtyMask & IOBitset.ElectronicFused) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Electronically Fused', value: IOBitset.ElectronicFused });

    if ((specialtyMask & IOBitset.HartIO) !== 0)
        ioSpecialtyMapFilters.push({ name: 'HART', value: IOBitset.HartIO });

    if ((specialtyMask & IOBitset.Isolated) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Isolated', value: IOBitset.Isolated });

    if ((specialtyMask & IOBitset.RTD) !== 0)
        ioSpecialtyMapFilters.push({ name: 'RTD', value: IOBitset.RTD });

    if ((specialtyMask & IOBitset.SafetyIO) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Safety I/O', value: IOBitset.SafetyIO });

    if ((specialtyMask & IOBitset.ThermoCouple) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Thermocouple', value: IOBitset.ThermoCouple });

    if ((specialtyMask & IOBitset.AnalogVoltage) !== 0)
        ioSpecialtyMapFilters.push({ name: 'Voltage', value: IOBitset.AnalogVoltage });

    return ioSpecialtyMapFilters;
}


//// Debug Helpers ///////

// Debug: Human readable text from query bitset
export const debugGetIOQueryFromBitset = (bitset: number) => {
    let retVal = '';

    if ((bitset & IOBitset.Exclude) !== 0)
        retVal += 'Exclude: ';

    if ((bitset & IOBitset.Input) !== 0)
        retVal += 'Input/';
    if ((bitset & IOBitset.Output) !== 0)
        retVal += 'Output/';

    if ((bitset & IOBitset.Digital) !== 0)
        retVal += 'Digital/';
    if ((bitset & IOBitset.Analog) !== 0)
        retVal += 'Analog/';

    if ((bitset & IOBitset.HartIO) !== 0)
        retVal += 'HartIO/';

    if ((bitset & IOBitset.ExTemp) !== 0)
        retVal += 'XT/';

    if ((bitset & IOBitset.Conformal) !== 0)
        retVal += 'CC/';

    if ((bitset & IOBitset.Combo) !== 0)
        retVal += 'Combo/';

    if ((bitset & IOBitset.SelfCfg) !== 0)
        retVal += 'SelfCfg/';

    if ((bitset & IOBitset.ThermoCouple) !== 0)
        retVal += 'Thermo/';

    if ((bitset & IOBitset.RTD) !== 0)
        retVal += 'RTD/';

    if ((bitset & IOBitset.SafetyIO) !== 0)
        retVal += 'Saf/';

    if ((bitset & IOBitset.Diagnostic) !== 0)
        retVal += 'Diag/';

    if ((bitset & IOBitset.Isolated) !== 0)
        retVal += 'Iso/';

    if ((bitset & IOBitset.ElectronicFused) !== 0)
        retVal += 'Fused/';

    if ((bitset & IOBitset.DC24) !== 0)
        retVal += 'DC24/';

    if ((bitset & IOBitset.DC48) !== 0)
        retVal += 'DC48/';

    if ((bitset & IOBitset.DC120) !== 0)
        retVal += 'DC120/';

    if ((bitset & IOBitset.AC120) !== 0)
        retVal += 'AC120/';

    if ((bitset & IOBitset.AC240) !== 0)
        retVal += 'AC240/';

    if ((bitset & IOBitset.AnalogCurrent) !== 0)
        retVal += 'AnlgCurr/';

    if ((bitset & IOBitset.AnalogVoltage) !== 0)
        retVal += 'AnlgVolt/';

    const len = retVal.length;
    if (len > 2 && retVal[len - 1] === '/')
        retVal = retVal.substring(0, len - 1);

    return retVal;
}

