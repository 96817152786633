import { ProjectViewType } from "../../types/ViewTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";


interface ViewInfoState {
    currentView: ProjectViewType;
}

const initialState: ViewInfoState = {
    currentView: ProjectViewType.Design,
}

export const viewInfo = createSlice({
    name: 'viewInfo',
    initialState,
    reducers: {
        setCurrentView: (state, action: PayloadAction<ProjectViewType>) => {
            state.currentView = action.payload;
        }
    }
});

export const {
    setCurrentView
} = viewInfo.actions;

export const currentView = (state: RootState) => state.viewInfo.currentView;
export default viewInfo.reducer;

