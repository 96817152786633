import React from 'react';
import { IconButton, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Theme } from '@rockwell-automation-inc/ra-meatball';


interface Props {
    icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
    onClick: () => void;
}

const TitleBarButton = (props: Props) => {

    const Icon = props.icon;

    return (
        <IconButton
            onClick={props.onClick}
            disableRipple
            disableFocusRipple
            sx={{
                color: Theme.palette.mediumGrey.main,
                height: '16px',
                width: '16px',
            }}
        >
            <Icon />
        </IconButton >
    );
}

export default TitleBarButton;

