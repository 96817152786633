import React, { ReactNode, useRef, useState } from 'react';
import { LogMsgLevel, ProjectLogMessage } from '../util/ProjectLog';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import { blue, red, yellow } from '@mui/material/colors';
import { AF_Base, getAutoFix } from '../util/CheckerAutoFix';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const getFixMeBtnText = (logMsg: ProjectLogMessage): string => {
	if (logMsg.idAutoFix) {
		if (logMsg.customFixBtnText) {
			return logMsg.customFixBtnText;
		}
		else {
			switch (logMsg.level) {
				case LogMsgLevel.error:
					return 'FIX ERROR';

				case LogMsgLevel.warning:
					return 'FIX WARNING';

				case LogMsgLevel.info:
					return 'FIX INFO';

				default:
					return 'FIX';
			}
		}
	}
	return '';
}

interface Props {
	logMessage: ProjectLogMessage;
	autoFixCallback: (af: AF_Base) => void;
}

export const ProjectStatusDisplayMessage = (props: Props) => {
	const msgIcon = useRef<ReactNode>(null);
	const msglevel = useRef<LogMsgLevel>(LogMsgLevel.none);
	const [refresh, setRefresh] = useState(0);

	const onFixMe = () => {
		const af = getAutoFix(props.logMessage.idAutoFix);
		if (af == null) {
			// Clear the ID so FixMe button will not show.
			props.logMessage.idAutoFix = '';
			// Post an alert - we cannot pop another modal dlg here!
			alert('Unable to correct issue.');
			// Re-render the message component again.
			setRefresh(refresh + 1);
			return;
		}

		props.autoFixCallback(af);
	}

	// Note: Problems with useEffect(). If the level is 
	// different from what it was last time through,,,,
	if (msglevel.current !== props.logMessage.level) {
		// Update the current level and set the icon.
		msglevel.current = props.logMessage.level;
		switch (props.logMessage.level) {
			case LogMsgLevel.none:
				msgIcon.current = null;
				break;
			default:
			case LogMsgLevel.info:
				msgIcon.current =
					<InfoOutlinedIcon sx={{ fontSize: 14, color: blue[700] }} />
				break;
			case LogMsgLevel.warning:
				msgIcon.current =
					<WarningIcon sx={{ fontSize: 14, color: yellow[800] }} />;
				break;
			case LogMsgLevel.error:
				msgIcon.current =
					<DangerousIcon sx={{ fontSize: 14, color: red[700] }} />
				break;
		}
	}

	const fixBtnText = getFixMeBtnText(props.logMessage);

	return (
		<div className="project-status-display-message-container">
			
			<div className="project-status-display-message">{msgIcon.current }{props.logMessage.message}</div>
			{props.logMessage.idAutoFix ?
				<button className='project-status-display-message__text' onClick={onFixMe}>
					{fixBtnText}
				</button>
				:
				null}
		</div>
	);
}
