import { createLocAttrInfoSearchString, refreshLocAttrInfoSelectionArray } from "./GuidedSelection";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants";
import { AIBits, AOBits, DIBits, DOBits } from "../types/IOModuleTypes";
import { LocAttributeInfo } from "../types/ProjectTypes";
import { PlatformLoadTracker, ApiTypeID } from "../types/APITypes";
import { getProductSelection_API } from "../services/selectionAPIs/SelectionApi";
import ServiceResponse from "../services/apis/Response";
import { AxiosError } from "axios";
import { raTimeoutMessage } from "../services/apis/ProductApiService";
import { LogRender, UseDefaultProductSelection } from "../types/Globals";
import { logger } from "../util/Logger";
import CLXDefProductSel from "../platforms/clx/data/CLXDefProductSelection.json"
import CpLXDefProductSel from "../platforms/cplx/data/CpLXDefProductSelection.json"
import FlexDefProductSel from "../platforms/flex/data/FlexDefProductSelection.json"
import FlexHADefProductSel from "../platforms/flexHA/data/FlexHADefProductSelection.json"
import MicroDefProductSel from "../platforms/micro/data/MicroDefProductSelection.json";
import { getEngInfoForComp } from "../util/EngInfoHelp";
import { getIOModuleInfo } from "../implementation/ImplHardwareGen";

// Cache: Map< platform, ProductSelectionInfo>
const _productSelectionCache = new Map<string, ProductSelectionInfo>();

export const loadProductSelection = (tracker: PlatformLoadTracker) => {
	const prodSel = _productSelectionCache.get(tracker.platform);
	if (prodSel)
		tracker.onAPIResolvedCallBack(ApiTypeID.ProductSelection, tracker);

	if (UseDefaultProductSelection) {
		_onProductSelAPIResolved(getDefaultPlatformProdSel(tracker.platform), true, tracker);
		return;
	}

	_requestProductSelection(tracker);
}

const _onProductSelAPIResolved = (jsonObj: object, defaultJson: boolean, tracker: PlatformLoadTracker) => {
	const prodSelInfo = parseProductSelData(jsonObj, tracker);
	if (prodSelInfo == null) {
		if (defaultJson)
			throw new Error(`Unable to load Product Selection data for ${tracker.platform}.`);

		// Recurse with the default data.
		_onProductSelAPIResolved(getDefaultPlatformProdSel(tracker.platform), true, tracker);
	}
	else {
		// Add the Product Selection Info to our cache.
		_productSelectionCache.set(tracker.platform, prodSelInfo);

		// Update our tracker
		tracker.productSelectionLoaded = true;
		tracker.defProductSelection = defaultJson;

		// Notify the caller
		tracker.onAPIResolvedCallBack(ApiTypeID.ProductSelection, tracker);
	}
}

const _requestProductSelection = async (tracker: PlatformLoadTracker) => {
	try {
		const result = await getProductSelection_API(tracker.platform);
		const response = new ServiceResponse(result);
		if (response.isSuccessful() && response.data) {
			_onProductSelAPIResolved(response.data, false, tracker);
			return;
		}
	}
	catch (error) {
		// If we timed out, the error will be an AxiosError. If so...
		if (error instanceof AxiosError) {
			// If we timed out, the code will be 'ECONNABORTED' and
			// the message will be raTimeoutMessage (RA_REQUEST_TIMEOUT) -
			// see productApiService.GetDetailedProduct() for details...
			if (error.code === 'ECONNABORTED' && error.message === raTimeoutMessage) {
				if (LogRender.SelectionAPIs)
					logger.log(`REQUEST TIMED OUT: Request for ${tracker.platform}'s Product Selection timed out.`);
			}
		}
		else {
			logger.log(`REQUEST Error: ` + error);
		}

		// Use the default.
		_onProductSelAPIResolved(getDefaultPlatformProdSel(tracker.platform), true, tracker);

		return;
	}

	// If we are here, use the default.
	_onProductSelAPIResolved(getDefaultPlatformProdSel(tracker.platform), true, tracker);
}

const parseProductSelData = (data: object, tracker: PlatformLoadTracker): ProductSelectionInfo | undefined => {
	const psInfo: ProductSelectionInfo = {
		arrProdSelIO: [],
		arrProdSel: [],
	}

	const nodeRawData = Object.values(data);

	nodeRawData.forEach((nodeRaw) => {
		const nodeKeys = Object.keys(nodeRaw);
		const nodeData = Object.values(nodeRaw);

		if (nodeKeys.length === 1 && nodeKeys[0] &&
			nodeData.length === 1 && nodeData[0]) {
			const psData = nodeData[0] as KeyValuePair[];
			// Trim the platform prefix (i.e. 'PS_1756' for CLX) from
			// the 'key'. This will give us a device category. For
			// example 'PS_1756Chassis' => 'Chassis'.
			const platformPrefix = getPlatformPSCategoryPrefix(tracker.platform);
			const category = nodeKeys[0].replace(platformPrefix, '');

			// I/O
			if (category === 'IO') {
				let selectable = false;
				const data = createNewProdSelDataIO();

				psData.forEach((nvPair) => {
					switch (nvPair.Key) {
						case 'CATNO_AI':
							data.catalogAI = nvPair.Value;
							break;
						case 'CATNO_AO':
							data.catalogAO = nvPair.Value;
							break;
						case 'CATNO_DI':
							data.catalogDI = nvPair.Value;
							break;
						case 'CATNO_DO':
							data.catalogDO = nvPair.Value;
							break;
						case 'SELECTABLE':
							// There will be entries that 'SELECTABLE'
							// will be '0', which means it cannot be selected.
							selectable = nvPair.Value === '1';
							break;
						case 'MESSAGE':
							// Not used yet, but store it...
							data.message = nvPair.Value;
							break;
						default:
							// We should have an attribute! Assume we do...
							data.attributes.push(`${nvPair.Key}:${nvPair.Value}`);
							break;
					}
				});

				if (selectable && isProdSelDataIO(data)) {
					psInfo.arrProdSelIO.push(data);
				}
			}
			else {
				// Chassis Components (Chassis, PSU, Controller, Comm, etc..)
				let selectable = false;
				const data = createNewProdSelData();
				data.category = category;
				psData.forEach((nvPair) => {
					switch (nvPair.Key) {
						case 'SELECTABLE':
							selectable = nvPair.Value === '1';
							break;
						case 'CATNO':
							data.catalogs = nvPair.Value.split(';');
							break;
						case 'REQ_ACCESSORIES':
							data.accessories = nvPair.Value.split(';');
							break;
						case 'MESSAGE':
							data.message = nvPair.Value;
							break;
						default:
							// We should have an attribute.
							data.attributes.push(`${nvPair.Key}:${nvPair.Value}`);
							break;
					}
				});

				if (selectable && isProdSelData(data))
					psInfo.arrProdSel.push(data);
			}
		}
	});

	// For the platforms we have, we must have
	// elements in both arrays!
	if (psInfo.arrProdSel.length === 0 || psInfo.arrProdSelIO.length === 0)
		return undefined;

	return psInfo;
}

const getDefaultPlatformProdSel = (platform: string): object => {
	switch (platform) {
		case PlatformCLX:
			return CLXDefProductSel;
		case PlatformCpLX:
			return CpLXDefProductSel;
		case PlatformFlex:
			return FlexDefProductSel;
		case PlatformMicro:
			return MicroDefProductSel;
		case PlatformFlexHA:
			return FlexHADefProductSel;
		default:
			break;
	}

	throw new Error(`getDefaultPlatformProdSel(): ${platform} not recognized!`);
}


const getPlatformPSCategoryPrefix = (platform: string) => {
	switch (platform) {
		case PlatformCLX:
			return 'PS_1756';
		case PlatformCpLX:
			return 'PS_5069'; 
		case PlatformFlex:
			return 'PS_5094'; 
		case PlatformMicro:
			return 'PS_2080'; 
		case PlatformFlexHA: 
			return 'PS_5015'; 
		default:
			throw new Error(`Platform '${platform}' not recognized in getPlatformPSCategoryPrefix().`);
	}
}

export enum ProdSelCategory {
	None = '',
	Controller = 'Controller',
	Chassis = 'Chassis',
	PSU = 'PwrSup',
	Comm = 'Comm',
	SlotFiller = 'SlotFill', // name may need to change - currently not in JSON
	IO = 'IO',
}


export interface ProductSelectionInfo {
	arrProdSelIO: ProdSelDataIO[];
	arrProdSel: ProdSelData[]
}


const getProductSelectionInfo = (platform: string): ProductSelectionInfo => {
	// If this is called, we should have it loaded!
	const info = _productSelectionCache.get(platform);
	if (info)
		return info;

	throw new Error('getProductSelectionInfo() should never be called until P.S. is Loaded!')
}

export interface KeyValuePair {
	Key: string;
	Value: string;
}


export interface ProdSelData {
	category: string;
	attributes: string[];
	catalogs: string[];
	accessories: string[];
	message: string;
}

export const createNewProdSelData = (): ProdSelData => {
	return {
		category: '',
		attributes: [],
		catalogs: [],
		accessories: [],
		message: '',
	};
}

export const isProdSelData = (data: ProdSelData): boolean => {
	if (data.attributes.length > 0 && data.catalogs.length > 0 && data.catalogs[0] !== 'Auto')
		return true;
	return false;
}

interface ProdSelDataIO {
	attributes: string[];
	catalogAI: string;
	catalogAO: string;
	catalogDI: string;
	catalogDO: string;
	message: string;
}

export const createNewProdSelDataIO = (): ProdSelDataIO => {
	return {
		attributes: [],
		catalogAI: '',
		catalogAO: '',
		catalogDI: '',
		catalogDO: '',
		message: '',
	};
}

export const isProdSelDataIO = (data: ProdSelDataIO): boolean => {
	if (data.attributes.length > 0) {
		if (data.catalogAI && data.catalogAO && data.catalogDI && data.catalogAO) {
			return true;
		}
	}

	return false;
}

export interface PSHardwareInfo {
	category: string;
	mainCatalog: string;
	associatedCatalogs: string[];
	// At some point we may need a string array for accessories.
	accessoryCatalogs: string[];
	attributes: string[];
}

export interface PSDefaultIO {
	mask: number;
	catalog: string;
}

export const createNewPSHardwareInfo = (): PSHardwareInfo => {
	return {
		category: '',
		mainCatalog: '',
		associatedCatalogs: [],
		accessoryCatalogs: [],
		attributes: [],
	};
}

export const isPSHardwareInfo = (info: PSHardwareInfo): boolean => {
	if (info.category && info.mainCatalog)
		return true;
	return false;
}

export const collectHardwareInfo = (
	loc: LocAttributeInfo,
	additionalAttrs: KeyValuePair[] = [],
	hwCategory: ProdSelCategory = ProdSelCategory.None): PSHardwareInfo[] => {

	// Create a search string.
	refreshLocAttrInfoSelectionArray(loc);
	let searchString = createLocAttrInfoSearchString(loc);

	// If we have additional attributes... This can be used
	// in cases where an attribute has an 'Auto' selection.
	// For instance, Auto Chassis Size in CLX, we can pass
	// calculated chassis sizes to get catalogs back.
	if (additionalAttrs.length > 0) {
		// Add them to the seach string...
		additionalAttrs.forEach((kv) => {
			searchString += ` ${kv.Key}:${kv.Value}`;
		});
	}

	const arrHardware: PSHardwareInfo[] = [];
	const psInfo = getProductSelectionInfo(loc.platform);

	// Note: For efficiency, essenentially copied the
	// body of the loop, but one includes the category
	// conditional.
	if (hwCategory !== ProdSelCategory.None) {
		psInfo.arrProdSel.forEach((data) => {
			if (data.category === hwCategory)
				_AddQualifiedHardwareInfo(searchString, data, arrHardware);
		});
	}
	else {
		psInfo.arrProdSel.forEach((data) => {
			_AddQualifiedHardwareInfo(searchString, data, arrHardware);
		});
	}
	return arrHardware;
}


const _AddQualifiedHardwareInfo = (searchString: string, data: ProdSelData, arrHWInfo: PSHardwareInfo[]) => {
	// To qualify, ALL attribute selections must match. If one does
	// not, doesNotQualify is set to true.
	const doesNotQualify = data.attributes.some((attr) => {
		if (searchString.includes(attr) === false)
			return true;
		return false;
	});

	if (doesNotQualify === false) {
		const info: PSHardwareInfo = {
			category: data.category,
			mainCatalog: data.catalogs[0],
			// Associated catalogs will addition modules to be 
			// placed directly to the right of the main catalog.
			associatedCatalogs: data.catalogs.filter((x, idx) => idx > 0),
			accessoryCatalogs: [],
			attributes: [...data.attributes],
		};

		if (isPSHardwareInfo(info)) {
			// It qualifies - add it to our array.
			arrHWInfo.push(info);
		}
		else if (LogRender.SelectionAPIs)
			logger.log(`_AddQualifiedHardwareInfo(): found hardware is not a valid device (type: ${info.category}, cat:${info.mainCatalog}).`);
	}
}


interface DefIOCatalogs {
	platform_SearchString: string;
	catalogs: PSDefaultIO[];
}

// In many cases, we will get requests for the SAME
// default I/O several times. Save the last search
// instead of creating a cache map.
const _LastIOCatalogs: DefIOCatalogs = { platform_SearchString: '', catalogs: [] };
export const GetDefaultIO = (loc: LocAttributeInfo): PSDefaultIO[] => {

	// Create a search string. Refresh the current selections
	// and create a search string.
	refreshLocAttrInfoSelectionArray(loc);
	const searchString = createLocAttrInfoSearchString(loc);

	// If the last request is the SAME as this one...
	if (_LastIOCatalogs.platform_SearchString === `${loc.platform}${searchString}`)
		return [..._LastIOCatalogs.catalogs];

	// Find the defaults (if we can).
	const psInfo = getProductSelectionInfo(loc.platform);

	// For this we should only get ONE ProdSelDataIO back
	// or 'undefined' if not found.
	const defIO = psInfo.arrProdSelIO.find((data) => {
		const noMatch = data.attributes.some((attr) => {
			if (searchString.includes(attr) === false)
				return true;
			return false;
		});

		return !noMatch;
	});

	// If we have found def I/O, populate our array.
	const arrMaskToIOMod: PSDefaultIO[] = [];
	if (defIO) {
		arrMaskToIOMod.push({ mask: AIBits, catalog: defIO.catalogAI });
		arrMaskToIOMod.push({ mask: AOBits, catalog: defIO.catalogAO });
		arrMaskToIOMod.push({ mask: DIBits, catalog: defIO.catalogDI });
		arrMaskToIOMod.push({ mask: DOBits, catalog: defIO.catalogDO });
	}

	// Store the last request.
	_LastIOCatalogs.platform_SearchString = `${loc.platform}${searchString}`;
	_LastIOCatalogs.catalogs = arrMaskToIOMod;

	// Return our results.
	return arrMaskToIOMod;
}


// Diagnostic function for making sure Prod Sel
// catalogs are in Engineering Data. Uncommented
// in AppView.tsx::onLoaded() <inside a useEffect>
// to activate this validation after a platform is loaded.
export const validateProdSelectionVsEngData = (platform: string = '') => {
	const mapValidationErrors = new Map<string, string>();
	_productSelectionCache.forEach((info, key) => {
		if ((platform && platform === key) || !platform) {
			info.arrProdSelIO.forEach((defIO) => {
				if (defIO.catalogAI && mapValidationErrors.has(defIO.catalogAI) === false) {
					if (getIOModuleInfo(key, defIO.catalogAI) == null) {
						mapValidationErrors.set(defIO.catalogAI, `${key} Product Selection contains ${defIO.catalogAI}, which is NOT in the Engineering Data.`);
					}
					else {
						// Add it to the map without a message.
						mapValidationErrors.set(defIO.catalogAI, '');
					}
				}
				if (defIO.catalogAO && mapValidationErrors.has(defIO.catalogAO) === false) {
					if (getIOModuleInfo(key, defIO.catalogAO) == null) {
						mapValidationErrors.set(defIO.catalogAO, `${key} Product Selection contains ${defIO.catalogAO}, which is NOT in the Engineering Data.`);
					}
					else {
						// Add it to the map without a message.
						mapValidationErrors.set(defIO.catalogAO, '');
					}
				}
				if (defIO.catalogDI && mapValidationErrors.has(defIO.catalogDI) === false) {
					if (getIOModuleInfo(key, defIO.catalogDI) == null) {
						mapValidationErrors.set(defIO.catalogDI, `${key} Product Selection contains ${defIO.catalogDI}, which is NOT in the Engineering Data.`);
					}
					else {
						// Add it to the map without a message.
						mapValidationErrors.set(defIO.catalogDI, '');
					}
				}
				if (defIO.catalogDO && mapValidationErrors.has(defIO.catalogDO) === false) {
					if (getIOModuleInfo(key, defIO.catalogDO) == null) {
						mapValidationErrors.set(defIO.catalogDO, `${key} Product Selection contains ${defIO.catalogDO}, which is NOT in the Engineering Data.`);
					}
					else {
						// Add it to the map without a message.
						mapValidationErrors.set(defIO.catalogDO, '');
					}
				}
			});

			info.arrProdSel.forEach((prod) => {
				if (prod.catalogs) {
					prod.catalogs.forEach((catalog) => {
						if (catalog && mapValidationErrors.has(catalog) === false) {
							if (getEngInfoForComp(key, catalog) == null) {
								mapValidationErrors.set(catalog, `${key} Product Selection contains ${catalog}, which is NOT in the Engineering Data.`);
							}
							else {
								// Add it to the map without a message.
								mapValidationErrors.set(catalog, '');
							}
						}
					});
				}
			});
		}
	});

	// Build our complete message...
	let message = '';
	mapValidationErrors.forEach((msg) => {
		if (msg) {
			if (message)
				message += '\r\n';
			message += msg;		
		}
	});

	if (message) {
		window.alert(message);
	}
}