import React from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { ReactComponent as InfoIconSVG } from '../svg/information-circle.svg';
import { ReactComponent as WarningIconSVG } from '../svg/errors.svg';
import { ReactComponent as ErrorIconSVG } from '../svg/failed.svg';
import { StatusLevel } from '../types/MessageTypes';
import { Theme } from '@rockwell-automation-inc/ra-meatball';


export type ReactSVGComp =
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export type ImportedMuiIcon =
    OverridableComponent<SvgIconTypeMap<object, 'svg'>>;


export const getStatusIconInfo = (level: StatusLevel | undefined):
    [icon: ReactSVGComp | undefined, color: string] => {

    switch (level) {
        case StatusLevel.Error:
            return [ErrorIconSVG, Theme.palette.error.main];

        case StatusLevel.Warning:
            return [WarningIconSVG, Theme.palette.warning.main];

        case StatusLevel.Info:
            return [InfoIconSVG, Theme.palette.ftBlue.main];

        default:
            return [undefined, Theme.palette.white.main];
    }
}
