import { AxiosRequestConfig } from "axios";
import { getApiUrl } from "platform-services";
import axios from "axios";

class ProjectApiService {
    api: string;
    config: AxiosRequestConfig;

    constructor() {
        this.api = "PROJECTS_API";

        this.config = {
            headers: {
                "Content-Type": "application/json-patch+json",
                accept: "text/plain",
            },
            withCredentials: true,
        };
    }

    getUserProjects() {
        const url = `${getApiUrl(
            this.api
        )}/api/Project/GetUserProjects`;
        return axios.get(url, this.config);
    }

    getLastUserOpenedProject() {
        const url = `${getApiUrl(
            this.api
        )}/api/Project/GetLastUserOpenedProject`;
        return axios.get(url, this.config);
    }

    getLastModifiedUserProject() {
        const url = `${getApiUrl(
            this.api
        )}/api/Project/GetLastModifiedUserProject`;
        return axios.get(url, this.config);
    }

    getUserProjectByGuid(projectGuid: string) {
        const url = `${getApiUrl(
            this.api
        )}/api/Project/GetUserProjectByGuid?projectGuid=${projectGuid}`;
        return axios.get(url, this.config);
    }

    getProjectItemList(projectGuid: string) {
        const url = `${getApiUrl(
            this.api
        )}/api/ProjectItem/GetProjectItemList/${projectGuid}`;
        return axios.get(url, this.config);
    }

    getProjectIndustry(projectId: string) {
        const url = `${getApiUrl(
            this.api
        )}/api/Industry/GetProjectIndustry?projectGuid=${projectId}`;

        return axios.get(url, this.config);
    }
}

const projectApiService = new ProjectApiService();
export default projectApiService;
