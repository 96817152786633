import { isSupportedPlatform } from './PlatformIDs';
import { initCLXPlatform } from '../platforms/clx/model/CLXChassis';
import { initCpLXPlatform } from './cplx/model/CpLXPlatform';
import { initFlexPlatform } from './flex/model/FlexPlatform';
import { initMicroPlatform } from './micro/model/MicroPlatform';
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformMicro, PlatformFlexHA } from './PlatformConstants';
import { initFlexHAPlatform } from './flexHA/model/FlexHAPlatform';


const _initializedPlatforms = new Set<string>();

export const initializePlatform = (platform: string) => {
    if (isSupportedPlatform(platform)) {
        if (!_initializedPlatforms.has(platform)) {
            switch (platform) {
                case PlatformCLX:
                    initCLXPlatform();
                    break;

                case PlatformCpLX:
                    initCpLXPlatform();
                    break;

                case PlatformFlex:
                    initFlexPlatform();
                    break;

                case PlatformMicro:
                    initMicroPlatform();
                    break;
    
                case PlatformFlexHA:
                    initFlexHAPlatform();
                    break;

                default:
                    throw new Error('Unexpected error in initializePlatform: ' + platform);
            }

            _initializedPlatforms.add(platform);
        }
    }
    else {
        throw new Error('initializePlatform asked to initialize unsupported platform: ' + platform);
    }
}

