import React from 'react';
import { LogMsgLevel, ProjectLog, ProjectLogMessage,  } from '../util/ProjectLog';
import { ProjectStatusDisplayMessage } from './ProjectStatusDisplayMessage';
import { AF_Base } from '../util/CheckerAutoFix';
import '../components/details/PerformanceInfo.scss';
import { Chassis } from '../types/ProjectTypes';
import DetailsPowerUsage from '../components/details/DetailsPowerUsage';
import { filterButtonTypes } from './ProjectStatusDisplay';
import ProjectStatusAccordionRow from './ProjectStatusAccordionRow';

export interface ProjectLogHeader extends ProjectLog {
	title: string;
	chassis: Chassis | undefined
}



interface DetailsProps {
    log: ProjectLogHeader | undefined;
    selectedButton: filterButtonTypes;
    autoFixCallback: (af: AF_Base) => void;

}
export const PrepareAccordionDetail = (props: DetailsProps) => {
    let nextKey = 0;

    return (
        <>
            {props.log && props.log.messages
                .filter((msg: ProjectLogMessage) => {
                    return (
                        (msg.level === LogMsgLevel.info && props.selectedButton.info) ||
                        (msg.level === LogMsgLevel.warning && props.selectedButton.warning) ||
                        (msg.level === LogMsgLevel.error && props.selectedButton.error) || (msg.level === LogMsgLevel.none)
                    )
                })
                .map((msg) => {
                    return (
                        <ProjectStatusDisplayMessage
                            logMessage={msg}
                            key={nextKey++}
                            autoFixCallback={props.autoFixCallback}
                        />
                    );
                })
            }
        </>
    )
}

interface Props {
    selectedButton: filterButtonTypes;
    expanded: boolean | null;
    autoFixCallback: (af: AF_Base) => void;
    contentChanged: () => void;
    log: ProjectLogHeader;
}
export  const ProjectStatusDisplayHeader = (props: Props) => {

	let nextKey = 0;

	let titleText = props.log.title;
	if (props.log.chassis && !props.log.chassis.isPlaceholder) {
		titleText += (': ' + props.log.chassis.catNo);
	}

	const getDetailsContent = ()=>{
		return (
			<>
			{/*Power Usage Details Information */}
			
			{props.log.chassis && 
                    <DetailsPowerUsage
                        key={nextKey++}
                        chassis={props.log.chassis}
                        selectedButton={props.selectedButton}
                        oneLineFormat={true} />}
			
			{/* Render Filtered Log messages */}
			<PrepareAccordionDetail 
			log={props.log}
			selectedButton={props.selectedButton}
			autoFixCallback = {props.autoFixCallback}
			/>
			</>
			
		)
	}

	return (
		<ProjectStatusAccordionRow
			title={titleText}
		details={getDetailsContent()}
		/>
	);
}
