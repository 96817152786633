import { v4 as uuidv4 } from 'uuid';


export type InstanceId = string;

export const getNewInstanceId = (): InstanceId => {
    const uuid = uuidv4();
    return uuid;
}

