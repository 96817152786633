import { getSlotLocation } from "../implementation/ImplGeneral";
import { isClxRedundancyMod } from "../platforms/clx/model/CLXChassis";
import { Chassis } from "../types/ProjectTypes";
import { Point } from "../types/SizeAndPosTypes";
import { getEmptyPt, getOffsetPoint } from "./GeneralHelpers";
import { ContentSpacing } from "./LayoutHelp";

export enum RedDepictOption {
    Behind = 'Behind',  // Secondary shown behind and 
                        // slightly offset from the primary
    Beside = 'Beside',  // Secondary shown to the right
                        // of the primary.
    Below = 'Below'     // Secondary shown below the primary.
}

let _showBehindSecAbove = false;

export const getShowSecAbovePriWhenBehind = (): boolean => {
    return _showBehindSecAbove;
}

export const showSecAbovePriWhenBehind = (above: boolean) => {
    _showBehindSecAbove = above;
}

// Overlap offset used ONLY for the 'Behind' depict option.
//export const RedBehindSecondaryOffset: Point = { x: 70, y: 60 };

const _horzOffsetForSecBehind = 70;
const _vertOffsetForSecBehind = 60;

const getRedBehindSecOffset = (): Point => {
    const vMult = _showBehindSecAbove ? -1 : 1;
    return {
        x: _horzOffsetForSecBehind,
        y: vMult * _vertOffsetForSecBehind
    };
}

// Horizontal sep between primary and secondary
// used ONLY for the 'Beside' depict option.
export const RedBesideGap = 100;

// Vertical sep between primary and secondary
// used ONLY for the 'Below' depict option.
export const RedBelowGap = ContentSpacing.verticalSep;


// Note: For now, we'll just store this as a global, and
// just to overlap each time we start. Later, we COULD
// change to some other approach:
//    1. Save with project?
//    2. Save as user option in something like local storage.
let _currentRedDepictOpt = RedDepictOption.Beside;


export const getRedDepictOption = (): RedDepictOption => {
    return _currentRedDepictOpt;
}

export const setRedDepictOption = (opt: RedDepictOption) => {
    _currentRedDepictOpt = opt;
}

export const getRedDepictOpacity = (selected: boolean, redOpt: RedDepictOption): number => {
    switch (redOpt) {
        case RedDepictOption.Behind:
            return (selected ? 0.3 : 0.5);

        case RedDepictOption.Beside:
        case RedDepictOption.Below:
            return (selected ? 0.8 : 0.9);

        default:
            throw new Error('Unexpected RedDepictOption in getRedDepictOpacity!');
    }
}

// The following four functions apply ONLY to redundant
// chassis cases where the depiction option is 'Behind'
// (or overlapped). The return gives us the distance that
// the the secondary appears to extend farther than the
// primary in the given direction (above, below, left of,
// or right of). If the secondary isn't visible past the
// primary in the given direction, the return is 0.
export const getRedSecDistAbovePri = (): number => {
    const secOffset = getRedBehindSecOffset();
    return (secOffset.y < 0)
        ? -secOffset.y
        : 0;
}

export const getRedSecDistBelowPri = (): number => {
    const secOffset = getRedBehindSecOffset();
    return (secOffset.y > 0)
        ? secOffset.y
        : 0;
}

export const getRedSecDistLeftOfPri = (): number => {
    const secOffset = getRedBehindSecOffset();
    return (secOffset.x < 0)
        ? -secOffset.x
        : 0;
}

export const getRedSecDistRightOfPri = (): number => {
    const secOffset = getRedBehindSecOffset();
    return (secOffset.x > 0)
        ? secOffset.x
        : 0;
}


export const getRedChassisLoc = (
    chassis: Chassis,
    ptPrimaryOrg: Point,
    redOpt: RedDepictOption
): Point => {

    const redLoc = getEmptyPt();

    switch (redOpt) {
        case RedDepictOption.Behind:
            {
                const secOffset = getRedBehindSecOffset();
                redLoc.x = ptPrimaryOrg.x + secOffset.x;
                redLoc.y = ptPrimaryOrg.y + secOffset.y;
            }
            break;

        case RedDepictOption.Beside:
            redLoc.x = ptPrimaryOrg.x + chassis.layout.size.width + RedBesideGap;
            redLoc.y = ptPrimaryOrg.y;
            break;

        case RedDepictOption.Below:
            redLoc.x = ptPrimaryOrg.x;
            redLoc.y = ptPrimaryOrg.y + chassis.layout.size.height + RedBelowGap;
            break;

        default:
            throw new Error('Unexpected RedDepictOption in getRedChassisLoc!');
    }

    return redLoc;
}

const _redCableDropDist = 80;
const _redCablePassby = 34;

const showCableForDepictOpt = (redOpt: RedDepictOption): boolean => {
    switch (redOpt) {
        case RedDepictOption.Beside:
            return true;

        case RedDepictOption.Below:
            return true;

        case RedDepictOption.Behind:
            return true;

        default:
            return false;
    }
}

export const getRedCablePath = (
    chassis: Chassis,
    ptPrimaryOrg: Point,
    ptRedOrg: Point,
    redOpt: RedDepictOption
): [showCable: boolean, pts: Point[]] => {

    // We only depict a redundancy cable if:
    //   1. The chassis is actually redundant, and
    //   2. We're depicting the 2 chassis side-by-side.
    // If our tests are met...
    if (chassis.redundant && showCableForDepictOpt(redOpt)) {

        // Walk the slots in the chassis. For each...
        for (let slotIdx = 0; slotIdx < chassis.modules.length; slotIdx++) {

            // Get the module at the slot (if any).
            const mod = chassis.modules[slotIdx];

            // If we can, and it's (the first)
            // redundancy module we've found...
            if (mod && isClxRedundancyMod(mod)) {
                const slotLoc = getSlotLocation(chassis, slotIdx);

                // Using the slotLoc, determine the offset from
                // from the upper-left corner of the chassis to
                // the bottom-center pt on the RM module.
                const ptCableOffset: Point = {
                    x: slotLoc.x + (slotLoc.width / 2),
                    y: chassis.layout.size.height
                };

                // The cable will always start at the
                // RM's btm-ctr.
                const ptCableStart = getOffsetPoint(ptPrimaryOrg, ptCableOffset);

                // and will end at the same pt on the red
                // chassis's RM module.
                const ptCableEnd = getOffsetPoint(ptRedOrg, ptCableOffset);

                // Determine the amount we'll drop for the 
                // crossing portion of the cable.
                const ptDropOffset: Point = { x: 0, y: _redCableDropDist };

                // Bump that a bit for the 'behind' red opt.
                if (redOpt === RedDepictOption.Behind) {
                    ptDropOffset.y += 30;
                }

                // Create our return array of Points.
                const pathPts = new Array<Point>();

                // Add the start point.
                pathPts.push(ptCableStart);

                // Get the point our cable will
                // drop down to.
                const ptStartDrop = getOffsetPoint(ptCableStart, ptDropOffset);

                // Add that.
                pathPts.push(ptStartDrop);

                // Then, we'll do things a bit different
                // depending on our depiction option.
                switch (redOpt) {

                    // Beside or behind...
                    case RedDepictOption.Beside:
                    case RedDepictOption.Behind:
                        // Add the point our cable will cross
                        // over to the right, prior to coming
                        // back up to the RM in the red chassis.
                        pathPts.push({ x: ptCableEnd.x, y: ptStartDrop.y });
                        break;

                    // Below...
                    case RedDepictOption.Below:
                        {
                            // Here, our cable will go enough to
                            // the left to be able to 'pass by' our
                            // secondary chassis below.
                            const xPassby = ptPrimaryOrg.x - _redCablePassby;

                            // Add a point at the end of that 
                            // right-to-left crossing.
                            pathPts.push({ x: xPassby, y: ptStartDrop.y });

                            // Pre-determine the point UNDER our
                            // final cable endpoint.
                            const ptEndDrop = getOffsetPoint(ptCableEnd, ptDropOffset);

                            // Add a point that continues our pass-by
                            // path down to the same y as that point.
                            pathPts.push({ x: xPassby, y: ptEndDrop.y });

                            // Then add the point itself.
                            pathPts.push(ptEndDrop);
                        }
                        break;

                    default:
                        throw new Error('Unexpected red depict type?');
                }

                // Regardless of option type, add the final
                // final point to our path, which is the
                // btm-ctr of the RM in the red chassis.
                pathPts.push(ptCableEnd);

                // Return our path.
                return [true, pathPts];
            }
        }
    }

    // No cable should be shown.
    return [false, []];
}

