import {
    Chassis,
    ChassisModule,
    DeviceType,
    EnvRating,
    PSInputVoltage,
    SupportedWiringType
} from "../types/ProjectTypes";
import { getIntValFromString, isTrueStringValue } from "../util/DataValueHelp";
import { getPSLabelText, PowerBreakdown } from "../util/PowerHelp";
import { logger } from "../util/Logger";
import { establishIOMaskForModule } from "../util/IOModuleHelp";
import { Size } from "../types/SizeAndPosTypes";
import { IOPoints } from "../types/IOModuleTypes";
import { FamilyCLX, FamilyCpLX, FamilyMicro } from "../platforms/PlatformConstants";
import { isSupportedFamily } from "../platforms/PlatformIDs";
import { getSAPwrVoltagesFrom, SAPwrVltg, SAPwrVltgs } from "../util/FieldPowerHelp";
import { ControllerRole, establishControllerRole } from "./ControllerRole";
import { CommRole, establishCommRole } from "./CommRole";
import { ConnClientRole, establishIOModConnClient } from "./ConnClientRole";


export enum NetworkType {
    EtherNet = 'EtherNet',
    ControlNet = 'ControlNet',
    Other = 'Other'
}

export const EngPSType = {
    Single: 'Single',
    Redundant: 'Redundant Assemblies'
}

export interface Dimensions {
    height: number;
    width: number;
    depth: number;
}

const _getDimsFromData = (data: IEngDataComponent): Dimensions => {
    return {
        height: Number(data.Height),
        width: Number(data.Width),
        depth: Number(data.Depth)
    }
}

export interface EnvRatingInfo {
    rating: EnvRating;
    stdOk: boolean;
    ccOk: boolean;
    etOk: boolean;
    xtrOk: boolean;
}

const _getEnvRatingType = (ccOk: boolean, etOk: boolean): EnvRating => {
    if (etOk) return EnvRating.ExtTemperature;
    if (ccOk) return EnvRating.ConformalCoated;
    return EnvRating.Standard;
}

const _getEnvInfoFromData = (data: IEngDataComponent): EnvRatingInfo => {
    const ccOk = isTrueStringValue(data.Envir_CC);
    const etOk = isTrueStringValue(data.Envir_ET);
    const xtrOk = isTrueStringValue(data.Envir_XT);

    if (ccOk && etOk) {
        logger.logData(data.CatalogNumber + ' is DUAL-RATED. XTR: ' + xtrOk);
    }
    else {
        if (xtrOk) {
            logger.logData('XTR Env type and NOT DUAL-RATED: ' + data.CatalogNumber);
        }
    }

    const anyNonStd = (ccOk || etOk || xtrOk);
    const stdOk = (!anyNonStd ||
        ((data.Type === DeviceType.SlotFiller) && ccOk));
    return {
        rating: _getEnvRatingType(ccOk, etOk),
        stdOk: stdOk,
        ccOk: ccOk,
        etOk: etOk,
        xtrOk: xtrOk
    }
}

const _getAlternateReqSpecForRating = (envRating: EnvRating):
    [needCC: boolean, needET: boolean] => {
    switch (envRating) {
        case EnvRating.Standard:
            return [false, false];

        case EnvRating.ConformalCoated:
            return [true, false];

        case EnvRating.ExtTemperature:
            return [false, true];

        default:
            throw new Error('Invalide rating in _getAlternateReqSpecForRating!');

    }
}

const _getPowerSupplied = (data: IPowerSupplier): PowerBreakdown => {
    return {
        mAat5V: getIntValFromString(data.Pwr_5V_Avail),
        mAat24V: getIntValFromString(data.Pwr_24V_Avail),
        mWatt: getIntValFromString(data.Pwr_mW_Avail),
        modPower: getIntValFromString(data.Pwr_Mod_Avail),
        saPower: getIntValFromString(data.Pwr_SA_Avail)
    };
}

const _getPowerConsumed = (data: IPowerConsumer): PowerBreakdown => {
    return {
        mAat5V: getIntValFromString(data.Pwr_5V_Used),
        mAat24V: getIntValFromString(data.Pwr_24V_Used),
        mWatt: getIntValFromString(data.Pwr_mW_Used),
        modPower: getIntValFromString(data.Pwr_Mod_Used),
        saPower: getIntValFromString(data.Pwr_SA_Used)
    };
}

export interface PSSupplyVoltage {
    vdc24: boolean;
    vdc48: boolean;
    vdc125: boolean;
    vac120: boolean;
    vac240: boolean;
    allSupported: PSInputVoltage[];
}

const _getPSSupplyVltgInfo = (data: IEngDataPowerSupply): PSSupplyVoltage => {
    const info: PSSupplyVoltage = {
        vdc24: isTrueStringValue(data.PSVoltage_24VDC),
        vdc48: isTrueStringValue(data.PSVoltage_48VDC),
        vdc125: isTrueStringValue(data.PSVoltage_125VDC),
        vac120: isTrueStringValue(data.PSVoltage_120VAC),
        vac240: isTrueStringValue(data.PSVoltage_240VAC),
        allSupported: new Array<PSInputVoltage>()
    }

    if (info.vdc24) info.allSupported.push(PSInputVoltage.DC24V);
    if (info.vdc48) info.allSupported.push(PSInputVoltage.DC48V);
    if (info.vdc125) info.allSupported.push(PSInputVoltage.DC125V);
    if (info.vac120) info.allSupported.push(PSInputVoltage.AC120V);
    if (info.vac240) info.allSupported.push(PSInputVoltage.AC240V);

    return info;
}

export interface IODetails {
    // Analog/digital inputs/outputs
    AI: number;
    DI: number;
    AO: number;
    DO: number;

    // Digital self-configuring
    DSC: number;

    // Relay output
    RO: number;

    // RTD
    RTD: number;

    // Thermocouble
    Therm: number;

    // Safety analog/digital input/output
    SAI: number;
    SAO: number;
    SDI: number;
    SDO: number;

    // High speed counter
    HSC: number;

    PTO: number;
    PWM: number;

    analogInputs: number;
    analogOutputs: number;

    digitalInputs: number;
    digitalOutputs: number;

    safetyInputs: number;
    safetyOutputs: number;

    // Totals
    totalInputs: number;
    totalOutputs: number;

    totalPts: number;

    digital: boolean;
    analog: boolean;
    safety: boolean;
}

const _getIOPointDetails = (data: IEngDataIOModule): IODetails => {
    const dtls: IODetails = {
        AI: getIntValFromString(data.IOpoints_AI),
        DI: getIntValFromString(data.IOpoints_DI),
        AO: getIntValFromString(data.IOpoints_AO),
        DO: getIntValFromString(data.IOpoints_DO),
        DSC: getIntValFromString(data.Iopoints_DSC),
        RO: getIntValFromString(data.IOpoints_RO),
        RTD: getIntValFromString(data.IOpoints_RTD),
        Therm: getIntValFromString(data.IOpoints_Therm),
        SAI: getIntValFromString(data.IOpoints_SAI),
        SAO: getIntValFromString(data.IOpoints_SAO),
        SDI: getIntValFromString(data.IOpoints_SDI),
        SDO: getIntValFromString(data.IOpoints_SDO),
        HSC: getIntValFromString(data.IOpoints_HSC),
        PTO: getIntValFromString(data.IOpoints_PTO),
        PWM: getIntValFromString(data.IOpoints_PWM),
        analogInputs: 0,
        analogOutputs: 0,
        digitalInputs: 0,
        digitalOutputs: 0,
        safetyInputs: 0,
        safetyOutputs: 0,
        totalInputs: 0,
        totalOutputs: 0,
        totalPts: 0,
        digital: false,
        analog: false,
        safety: false
    }

    dtls.analogInputs = dtls.AI + dtls.SAI + dtls.RTD + dtls.Therm;
    dtls.analogOutputs = dtls.AO + dtls.SAO;

    dtls.digitalInputs = dtls.DI + dtls.SDI;
    dtls.digitalOutputs = dtls.DO + dtls.RO + dtls.SDO;

    dtls.safetyInputs = dtls.SDI + dtls.SAI;
    dtls.safetyOutputs = dtls.SDO + dtls.SAO;

    dtls.totalInputs = dtls.AI + dtls.DI + dtls.SAI + dtls.SDI + dtls.RTD + dtls.Therm;
    dtls.totalOutputs = dtls.AO + dtls.DO + dtls.SAO + dtls.SDO + dtls.RO;
    dtls.totalPts = dtls.totalInputs + dtls.totalOutputs + dtls.DSC;

    dtls.digital = ((dtls.digitalInputs > 0) || (dtls.digitalOutputs > 0));
    dtls.analog = ((dtls.analogInputs > 0) || (dtls.analogOutputs > 0));
    dtls.safety = ((dtls.safetyInputs > 0) || (dtls.safetyOutputs > 0));

    return dtls;
}

export interface IOVoltageSupport {
    vac12: boolean;
    vac24: boolean;
    vac48: boolean;
    vac120: boolean;
    vac240: boolean;
    vdc5: boolean;
    vdc12: boolean;
    vdc24: boolean;
    vdc48: boolean;
    vdc60: boolean;
    vdc125: boolean;
    rng0_10V: boolean;
    rng0_20mA: boolean;
    rng4_20V: boolean;
}

const _getIOVoltageSupported = (data: IEngDataIOModule): IOVoltageSupport => {
    return {
        vac12: isTrueStringValue(data.IOvolt_12VAC),
        vac24: isTrueStringValue(data.IOvolt_24VAC),
        vac48: isTrueStringValue(data.IOvolt_48VAC),
        vac120: isTrueStringValue(data.IOvolt_120VAC),
        vac240: isTrueStringValue(data.IOvolt_240VAC),
        vdc5: isTrueStringValue(data.IOvolt_5VDC),
        vdc12: isTrueStringValue(data.IOvolt_12VDC),
        vdc24: isTrueStringValue(data.IOvolt_24VDC),
        vdc48: isTrueStringValue(data.IOvolt_48VDC),
        vdc60: isTrueStringValue(data.IOvolt_60VDC),
        vdc125: isTrueStringValue(data.IOvolt_125VDC),
        rng0_10V: isTrueStringValue(data.IOvolt_010V),
        rng0_20mA: isTrueStringValue(data.IOvolt_020mA),
        rng4_20V: isTrueStringValue(data.IOvolt_420mA)
    }
}

export interface IOFeatures {
    modeDC: boolean;    // Differential current mode
    modeHSDC: boolean;  // High-speed diff current mode
    modeSEV: boolean;   // Single-ended voltage mode
    HART: boolean;
    isolated: boolean;
    diagnostic: boolean;
    elecFused: boolean;
}

const _getIOFeatures = (data: IEngDataIOModule): IOFeatures => {
    return {
        modeDC: isTrueStringValue(data.IOFeature_DC),
        modeHSDC: isTrueStringValue(data.IOFeature_HSDC),
        modeSEV: isTrueStringValue(data.IOFeature_SEV),
        HART: isTrueStringValue(data.IOFeature_HART),
        isolated: isTrueStringValue(data.IOFeature_Isol),
        diagnostic: isTrueStringValue(data.IOFeature_Diag),
        elecFused: isTrueStringValue(data.IOFeature_Fused)
    }
}

const _getFamily = (basicData: IEngDataBasic): string => {
    if (basicData.Family && isSupportedFamily(basicData.Family)) {
        return basicData.Family;
    }
    throw new Error('Missing or unsupported family: ' + basicData.CatalogNumber);
}

const _collectAccyPropVal = (val: string, refs: Set<string>) => {
    if (val) {
        const accys = val.split(';');
        accys.forEach(accy => {
            refs.add(accy.trim())
        })
    }
}

export interface IPowerSupplier {
    Pwr_5V_Avail: string;
    Pwr_24V_Avail: string;
    Pwr_mW_Avail: string;
    Pwr_Mod_Avail: string;
    Pwr_SA_Avail: string;
    Pwr_SA_Avail_Voltage: string;
}

export interface IPowerConsumer {
    Pwr_5V_Used: string;
    Pwr_24V_Used: string;
    Pwr_mW_Used: string;
    Pwr_Mod_Used: string;
    Pwr_SA_Used: string;
    Pwr_SA_Used_Voltage: string;
}

export interface IEngDataBasic {
    CatalogNumber: string;
    Description: string;
    Family: string;
    Type: string;
    SubType1: string;
    Alias_Actual_CatNo?: string;
}

export class EngInfoBasic {
    engData: IEngDataBasic;
    catNo: string;
    description: string;
    family: string;
    type: string;
    subType1: string;
    Alias_Actual_CatNo: string;

    isComponent: boolean;
    isChassis: boolean;
    isPS: boolean;
    isModule: boolean;
    isController: boolean;
    isComm: boolean;
    isCommModule: boolean;
    isIO: boolean;
    isBU: boolean;
    isPlugin: boolean;
    isConnClient: boolean;
    isFPD: boolean;
    isInterconnect: boolean;

    // Used for 'devices' that fill a role, but are NOT real,
    // orderable products. Ex: Chassis in snap-type platforms.
    isPlaceholder: boolean;

    // Used for snap-together platforms only.
    saPwrVltgsAvail: SAPwrVltgs;
    saPwrSupplier: boolean;
    modPwrSupplier: boolean;
    saPwrVltgsUsed: SAPwrVltgs;
    saPwrConsumer: boolean;

    platform: string;

    constructor(data: IEngDataBasic) {
        this.engData = data;
        this.catNo = data.CatalogNumber;
        this.description = data.Description;
        this.family = _getFamily(data);
        this.type = data.Type;
        this.subType1 = data.SubType1;
        this.Alias_Actual_CatNo = (data.Alias_Actual_CatNo ? data.Alias_Actual_CatNo : '');

        this.isComponent = false;
        this.isChassis = false;
        this.isModule = false;
        this.isController = false;
        this.isComm = false;
        this.isCommModule = false;
        this.isPS = false;
        this.isIO = false;
        this.isPlugin = false;
        this.isConnClient = false;
        this.isFPD = false;
        this.isBU = false;
        this.isPlaceholder = false;
        this.isInterconnect = false;

        this.saPwrVltgsAvail = undefined;
        this.saPwrSupplier = false;
        this.modPwrSupplier = false;
        this.saPwrVltgsUsed = undefined;
        this.saPwrConsumer = false;

        // Note: package platform applied to ALL EngInfo 
        // entries AFTER the postload step for the eng
        // data package has been completed.
        this.platform = '';
    }

    collectAccyPropVals(refs: Set<string>, accys: Set<string>) {
        refs;
        accys.add(this.catNo);
    }

    getAvailableSAPwrVltgs(): Set<SAPwrVltg> {
        if (this.saPwrVltgsAvail) {
            return new Set(this.saPwrVltgsAvail);
        }
        return new Set<SAPwrVltg>();
    }

    getSAPwrVltgsUsed(): Set<SAPwrVltg> {
        if (this.saPwrVltgsUsed) {
            return new Set(this.saPwrVltgsUsed);
        }
        return new Set<SAPwrVltg>();
    }

    getAlias(): string {
        // TODO_FLEXHA - 5015-U8IHFTXT (Duplx/Simplx) have
        // alias/actual catalog numbers. Currently they are
        // in the form of "5015-U8IHFTXT::n" where n is 1 or 2.
        if (this.Alias_Actual_CatNo && this.Alias_Actual_CatNo.length > 0) {
            // Trim off any extra info.
            const idxTrim = this.Alias_Actual_CatNo.indexOf(':');
            if (idxTrim > 0) {
                return this.Alias_Actual_CatNo.substring(0, idxTrim )
            }

            return this.Alias_Actual_CatNo
        }

        return this.catNo
    }

    getCatalogFromAlias(alias: string): string {
        // TODO-FLEXHA - This will not account for
        // Simplex or Duplex!.
        if (this.Alias_Actual_CatNo.startsWith(alias))
            return this.catNo;
        else if (alias === this.catNo)
            return this.catNo;

        return '';
    }
}


export interface IEngDataComponent extends IEngDataBasic {
    SubType2: string;
    Image: string;
    Height: string;
    Width: string;
    Depth: string;
    Envir_CC: string;
    Envir_ET: string;
    Envir_XT: string;
    Alt_Std: string;
    Alt_CC: string;
    Alt_ET: string;
    Alt_XT: string;
    Alt_Upsize: string;
    Accy_Req: string;
    Accy_Req1Of: string;
    Accy_Rec: string;
    Accy_Opt: string;
    Included_CatNos: string;
    Layout_Comments: string;
}

export interface IMicroEngDataComponent extends IEngDataModule, IPowerSupplier {
    PS_Support_820: string;
    PS_Support_850: string;
    PS_Support_870: string;
    IOpoints_AI: string;
    IOpoints_AO: string;
    IOpoints_DI: string;
    IOpoints_DO: string;
    ExpIO_Avail: string;
    PlugIn_Avail: string;
}


export interface IEngDataWiringOpts {
    Tb_screw: string;
    Tb_spring: string;
    Tb_IOReady: string;
}

export class EngInfoWiringOptions {
    tbScrew: string;
    tbSpring: string;
    tbIOReady: string;
    tbIOReadyACTUAL: string; // temp to hold full data spec.

    constructor(data: IEngDataWiringOpts) {
        this.tbScrew = data.Tb_screw ? data.Tb_screw : '';
        this.tbSpring = data.Tb_spring ? data.Tb_spring : '';

        this.tbIOReadyACTUAL = data.Tb_IOReady ? data.Tb_IOReady : '';
        this.tbIOReady = this.tbIOReadyACTUAL;

        // TEMPORARY
        // If we get a semi-colon separated list of possible
        // I/O Ready cable catalog numbers, we'll only keep the
        // first. This will change when we decide how to handle
        // the actual selection from a group of them.
        const semiColonPos = this.tbIOReady.indexOf(';');
        if (semiColonPos >= 0) {
            this.tbIOReady = this.tbIOReady.substring(0, semiColonPos);
        }
    }

    getSupportedWiringTypes(): number {
        let types = SupportedWiringType.NA;
        if (this.tbScrew.length > 0) types |= SupportedWiringType.Screw;
        if (this.tbSpring.length > 0) types |= SupportedWiringType.SpringClamp;
        if (this.tbIOReady.length > 0) types |= SupportedWiringType.IOReadyCable;
        return types;
    }

    collectWiringAccyPropVals(refs: Set<string>) {
        _collectAccyPropVal(this.tbScrew, refs);
        _collectAccyPropVal(this.tbSpring, refs);
        _collectAccyPropVal(this.tbIOReadyACTUAL, refs);
    }
}

export class EngInfoComponent extends EngInfoBasic
{
    subType2: string;
    imgName: string;
    imgSize: Size;
    dimensions: Dimensions;
    envInfo: EnvRatingInfo;
    altSTD: string;
    altCC: string;
    altET: string;
    altXTR: string;
    altUpsize: string;
    accysReq: string;
    accysReq1of: string;
    accysRec: string;
    accysOpt: string;

    constructor(data: IEngDataComponent) {
        super(data);
        this.isComponent = true;
        this.subType2 = data.SubType2;
        this.imgName = data.Image;
        this.imgSize = { width: 0, height: 0 }
        this.dimensions = _getDimsFromData(data);
        this.envInfo = _getEnvInfoFromData(data);
        this.altSTD = data.Alt_Std ? data.Alt_Std : '';
        this.altCC = data.Alt_CC ? data.Alt_CC : '';
        this.altET = data.Alt_ET ? data.Alt_ET : '';
        this.altXTR = data.Alt_XT ? data.Alt_XT : '';
        this.altUpsize = data.Alt_Upsize ? data.Alt_Upsize : '';
        this.accysReq = data.Accy_Req ? data.Accy_Req : '';
        this.accysReq1of = data.Accy_Req1Of ? data.Accy_Req1Of : '';
        this.accysRec = data.Accy_Rec ? data.Accy_Rec : '';
        this.accysOpt = data.Accy_Opt ? data.Accy_Opt : '';
    }


    isSlotFiller() {
        return (this.type === DeviceType.SlotFiller);
    }

    getSupportedWiringTypes(): number {
        return SupportedWiringType.NA;
    }

    getWiringOptions(): EngInfoWiringOptions | undefined {
        return undefined;
    }

    

    getAlternate(needCC: boolean, needET: boolean, needXTR = false): string {
        if (needXTR) return this.altXTR;
        if (needET) return this.altET;
        if (needCC) return this.altCC;
        return this.altSTD;
    }

    // Return true if THIS device is compatible
    // with the EnvRating requested.
    isCompatibleWithEnv(env: EnvRating): boolean {

        // We we already have a MATCHING
        // rating, we're compatible.
        if (this.envInfo.rating === env) {
            return true;
        }

        // Otherwise, call a helper to determine
        // what we'd need for the rating requested.
        const [needCC, needET] = _getAlternateReqSpecForRating(env);

        // See if we have a suitable alternate.
        const alt = this.getAlternate(needCC, needET);

        // If our alternate is the SAME as
        // our OWN catalog number, then we're
        // compatible.
        if (alt === this.catNo) {
            return true;
        }
        return false;
    }

    anyAccysPossible(): boolean {
        if (this.accysReq) return true;
        if (this.accysReq1of) return true;
        if (this.accysRec) return true;
        if (this.accysOpt) return true;
        return false;
    }

    collectAccyPropVals(refs: Set<string>, accys: Set<string>) {
        accys;
        _collectAccyPropVal(this.accysReq, refs);
        _collectAccyPropVal(this.accysReq1of, refs);
        _collectAccyPropVal(this.accysRec, refs);
        _collectAccyPropVal(this.accysOpt, refs);
    }
}


export interface IEngDataChassis extends IEngDataComponent {
    SlotsAvail: string;
}


export class EngInfoChassis extends EngInfoComponent {

    numSlots: number;

    constructor(data: IEngDataChassis) {
        super(data);
        this.isChassis = true;
        this.numSlots = getIntValFromString(data.SlotsAvail);
    }
}

export interface IEngDataPowerSupply extends IEngDataComponent, IMicroEngDataComponent,IPowerSupplier {
    PSVoltage_120VAC: string;
    PSVoltage_240VAC: string;
    PSVoltage_24VDC: string;
    PSVoltage_48VDC: string;
    PSVoltage_125VDC: string;
}

export class EngInfoPowerSupply extends EngInfoComponent {

    supplyVltg: PSSupplyVoltage;
    powerAvail: PowerBreakdown;
    redundant: boolean;
    slim: boolean;
    label: string;

    constructor(data: IEngDataPowerSupply) {
        super(data);
        this.isPS = true;
        this.supplyVltg = _getPSSupplyVltgInfo(data);
        this.powerAvail = _getPowerSupplied(data);
        this.redundant = (data.SubType1 === EngPSType.Redundant);
        this.slim = this.redundant ? false : this.dimensions.width < 100;
        this.label = getPSLabelText(this.powerAvail, this.redundant, this.slim);
    }

    getInputVoltage(vltgPref?: PSInputVoltage): PSInputVoltage {
        if (vltgPref) {
            if (this.supplyVltg.allSupported.includes(vltgPref)) {
                return vltgPref;
            }
        }
        return this.supplyVltg.allSupported[0];
    }
}

export class EngMicroInfoPowerSupply extends EngInfoPowerSupply {
    psSupport20: string;
    psSupport50: string;
    psSupport70: string;
    constructor(data: IEngDataPowerSupply) {
        super(data);
        this.isPS = true;
        this.psSupport20 = data.PS_Support_820? data.PS_Support_820: '';
        this.psSupport50 = data.PS_Support_850? data.PS_Support_850: '';
        this.psSupport70 = data.PS_Support_870? data.PS_Support_870: '';
    }
}


export interface IEngDataModule extends IEngDataComponent, IPowerConsumer, IEngDataWiringOpts {
    SlotsUsed: string;
    RedCapable: string;
}


export class EngInfoModule extends EngInfoComponent {

    slotsUsed: number;
    redCapable: boolean;
    powerUsed: PowerBreakdown;
    isSafetyIO: boolean;
    wiring: EngInfoWiringOptions;

    constructor(data: IEngDataModule) {
        super(data);
        this.isModule = true;
        this.slotsUsed = getIntValFromString(data.SlotsUsed);
        this.redCapable = isTrueStringValue(data.RedCapable);
        this.powerUsed = _getPowerConsumed(data);
        this.isSafetyIO = false;
        this.wiring = new EngInfoWiringOptions(data);
    }

    // Override for EngInfoModule
    getSupportedWiringTypes(): number {
        return this.wiring.getSupportedWiringTypes();
    }

    // Override for EngInfoModule
    getWiringOptions(): EngInfoWiringOptions | undefined {
        return this.wiring;
    }

    // Override for EngInfoModule
    anyAccysPossible(): boolean {
        if (super.anyAccysPossible()) return true;
        return (this.getSupportedWiringTypes() !== SupportedWiringType.NA);
    }
} 


export interface IEngDataInterconnect extends IEngDataModule {
    Cable_Length: string;
    Cable_Descr: string;
}
export class EngInfoInterconnect extends EngInfoModule {
    cableLength: number;
    cableLenDescr: string;
    constructor(data: IEngDataInterconnect) {
        super(data);
        this.isInterconnect = true;
        this.cableLength = Number(data.Cable_Length);
        if (isNaN(this.cableLength)) {
            this.cableLength = 0;
        }
        this.cableLenDescr = data.Cable_Descr;
    }
}

export interface IEngDataFPDModule extends IEngDataModule, IPowerSupplier {
}

export class EngInfoFPDModule extends EngInfoModule {
    powerAvail: PowerBreakdown;

    constructor(data: IEngDataFPDModule) {
        super(data);
        this.isFPD = true;
        this.powerAvail = _getPowerSupplied(data);
        this.saPwrVltgsAvail = getSAPwrVoltagesFrom(data.Pwr_SA_Avail_Voltage);
        this.saPwrSupplier = (this.saPwrVltgsAvail !== undefined);
        if (!this.saPwrSupplier) {
            logger.error('ERROR: Invalid FPD module: ' + this.catNo);
        }
    }

    // Override for EngInfoComponent
    collectAccyPropVals(refs: Set<string>, accys: Set<string>) {
        super.collectAccyPropVals(refs, accys);
        this.wiring.collectWiringAccyPropVals(refs);
    }
}


export interface IEngDataController extends IEngDataModule, IPowerSupplier {
    Proc_CIPMotion: string;
    Proc_Safety: string;
    SlotsAvail: string; // Platform specific
}

export class EngInfoController extends EngInfoModule {

    controllerRole: ControllerRole | undefined;
    commRole: CommRole | undefined;

    safetyController: boolean;
    maxMulti: number;

    // Used for snap-together platforms only.
    powerAvail: PowerBreakdown;
    maxSnapModules: number;

    constructor(data: IEngDataController) {
        super(data);

        this.controllerRole = establishControllerRole(data);
        this.isController = (this.controllerRole !== undefined);

        this.commRole = establishCommRole(data);
        this.isComm = (this.commRole !== undefined);

        this.safetyController = ((this.controllerRole !== undefined)
            && this.controllerRole.isSafetyController);

        this.maxMulti = 2;
        this.powerAvail = _getPowerSupplied(data);
        this.saPwrVltgsAvail = getSAPwrVoltagesFrom(data.Pwr_SA_Avail_Voltage);
        this.saPwrSupplier = (this.saPwrVltgsAvail !== undefined);
        this.modPwrSupplier = (this.powerAvail.modPower > 0.0);
        this.maxSnapModules = getIntValFromString(data.SlotsAvail);
    }
}


export interface IEngDataCommModule extends IEngDataModule, IPowerSupplier {
    Node1_NetworkType: string;
    Node1_NbofPorts: string;
    Node1_PortType: string;
    Node1_MaxSpeed: string;
    Node1_Simplex: string;
    Node1_DLR: string;
    Node1_PRP: string;
    EIP_Avail: string;
    CIP_Avail: string;
    TCP_Avail: string;
    EIP_Used: string;
    CIP_Used: string;
    TCP_Used: string;
    SlotsAvail: string; // Platform specific
}

const _getNetTypeFrom = (data: IEngDataCommModule): NetworkType => {
    if (data.Node1_NetworkType === 'EtherNet') return NetworkType.EtherNet;
    if (data.CatalogNumber.includes('-C')) return NetworkType.ControlNet;
    return NetworkType.Other;
}

export class EngInfoCommModule extends EngInfoModule {

    networkType: NetworkType;
    localConns: number;

    commRole: CommRole | undefined;

    // Used for snap-together platforms only.
    powerAvail: PowerBreakdown;
    maxSnapModules: number;

    constructor(data: IEngDataCommModule) {
        super(data);

        this.commRole = establishCommRole(data);
        this.isComm = (this.commRole !== undefined);

        this.isCommModule = true;
        this.networkType = _getNetTypeFrom(data);
        this.localConns = (this.networkType === NetworkType.EtherNet)
            ? 0
            : getIntValFromString(data.CIP_Used);
        this.powerAvail = _getPowerSupplied(data);
        this.saPwrVltgsAvail = getSAPwrVoltagesFrom(data.Pwr_SA_Avail_Voltage);
        this.saPwrSupplier = (this.saPwrVltgsAvail !== undefined);
        this.modPwrSupplier = (this.powerAvail.modPower > 0.0);
        this.maxSnapModules = getIntValFromString(data.SlotsAvail);
    }
}


export interface IEngDataIOModule extends IEngDataModule {
    IOpoints_AI: string;
    IOpoints_DI: string;
    IOpoints_AO: string;
    IOpoints_DO: string;
    Iopoints_DSC: string;
    IOpoints_RO: string;
    IOpoints_RTD: string;
    IOpoints_Therm: string;
    IOpoints_SAI: string;
    IOpoints_SAO: string;
    IOpoints_SDI: string;
    IOpoints_SDO: string;
    IOpoints_HSC: string;
    IOpoints_PTO: string;
    IOpoints_PWM: string;
    IOvolt_12VAC: string;
    IOvolt_24VAC: string;
    IOvolt_48VAC: string;
    IOvolt_120VAC: string;
    IOvolt_240VAC: string;
    IOvolt_5VDC: string;
    IOvolt_12VDC: string;
    IOvolt_24VDC: string;
    IOvolt_48VDC: string;
    IOvolt_60VDC: string;
    IOvolt_125VDC: string;
    IOvolt_010V: string;
    IOvolt_020mA: string;
    IOvolt_420mA: string;
    IOFeature_DC: string;
    IOFeature_HSDC: string;
    IOFeature_SEV: string;
    IOFeature_HART: string;
    IOFeature_Isol: string;
    IOFeature_Diag: string;
    IOFeature_Fused: string;
    IOCurrent_MaxperMod: string;
    IOCurrent_MaxperPoint: string;
}

export class EngInfoIOModule extends EngInfoModule {

    ptDtls: IODetails;
    vltg: IOVoltageSupport;
    features: IOFeatures;
    IOMask: number;
    displayString: string;

    // Note: All of the data contained in the points
    // object here is available in the ptDtls, so we
    // wouldn't need this. However, having it made the
    // changes to previous IO Module code (filtering, etc.)
    // easier to make without introducing any errors.
    points: IOPoints;

    connClientRole: ConnClientRole | undefined;


    constructor(data: IEngDataIOModule) {
        super(data);
        this.isIO = true;

        this.ptDtls = _getIOPointDetails(data);
        this.vltg = _getIOVoltageSupported(data);
        this.features = _getIOFeatures(data);

        this.IOMask = establishIOMaskForModule(this);

        this.points = {
            input: this.ptDtls.totalInputs,
            output: this.ptDtls.totalOutputs,
            selfCfg: this.ptDtls.DSC
        }

        this.displayString = this.catNo + ' - ' + this.description;
        this.isSafetyIO = this.ptDtls.safety;

        this.saPwrVltgsUsed = getSAPwrVoltagesFrom(data.Pwr_SA_Used_Voltage);
        this.saPwrConsumer = (this.saPwrVltgsUsed !== undefined);

        // NOTE: conn client role initially set to undefined.
        // After construction, role is set by call to
        // establishIOModConnClient in makeEngInfoFrom below.
        this.connClientRole = undefined;
    }

    // Override for EngInfoComponent
    collectAccyPropVals(refs: Set<string>, accys: Set<string>) {
        super.collectAccyPropVals(refs, accys);
        this.wiring.collectWiringAccyPropVals(refs);
    }

    // Eventually, we should replace this
    // function with data coming in at load time.
    isAdvancedCtlrReqd(): boolean {
        switch (this.family) {
            case FamilyCLX:
                return this.ptDtls.safety;

            case FamilyCpLX:
                return true;

            default:
                return false;
        }
    }

}

export const makeEngInfoFrom = (data: IEngDataBasic): EngInfoBasic => {
    switch (data.Type) {

        case DeviceType.Accessory:
            return new EngInfoBasic(data);

        case DeviceType.Chassis:
            return new EngInfoChassis(data as IEngDataChassis);

        case DeviceType.PS:
            if(data.Family === FamilyMicro){
                return new EngMicroInfoPowerSupply(data as IEngDataPowerSupply);
            }
            return new EngInfoPowerSupply(data as IEngDataPowerSupply);

        case DeviceType.Controller:
            if(data.Family === FamilyMicro){
                return new EngInfoMicroComponent(data as IMicroEngDataComponent);
            }
            return new EngInfoController(data as IEngDataController);

        case DeviceType.CommModule:
            return new EngInfoCommModule(data as IEngDataCommModule);

        case DeviceType.IOModule:
        case DeviceType.SpecialtyMod:
        case DeviceType.IOExpansion:

            {
                const ioData = data as IEngDataIOModule;
                const ioModInfo = new EngInfoIOModule(ioData);
                establishIOModConnClient(ioModInfo, ioData);
                return ioModInfo;
            }

            case DeviceType.PlugIn:
            {
                const ioData = data as IEngDataIOModule;
                const ioModPlugin = new EngInfoPLUGINModule(ioData);
                return ioModPlugin;
            }

        case DeviceType.FPD:
            return new EngInfoFPDModule(data as IEngDataFPDModule);

        //case DeviceType.SpecialtyMod:
        case DeviceType.SafetyPartner:
        case DeviceType.Motion:
        case DeviceType.RedundMod:
        case DeviceType.SlotFiller:
            return new EngInfoModule(data as IEngDataModule);

        case DeviceType.Cable:
            if (data.SubType1 === 'Interconnect')
                return new EngInfoInterconnect(data as IEngDataInterconnect);
            logger.warn('Unexpected device type in makeEngInfoFrom: ' + data.Type);
            return new EngInfoBasic(data);

        default:
            logger.warn('Unexpected device type in makeEngInfoFrom: ' + data.Type);
            return new EngInfoBasic(data);
    }
}

export type EngInfoMap = Map<string, EngInfoBasic>;

export interface AltChassisSAPwrSupplyInfo {
    chassis: Chassis;
    saPowerSupplier?: ChassisModule;
}


//Plugin interface addition
export class EngInfoPLUGINModule extends EngInfoIOModule {
    constructor(data: IEngDataIOModule) {
            super(data);
            this.isPlugin = true;
            this.isIO = false;
    }
}

export class EngInfoMicroComponent extends EngInfoModule
{
    Eio: string;
    isBU: boolean;
    plugin:string;
    AIpoint: string;
    DIpoint: string;
    DOpoint: string;
    AOpoint: string;
    constructor(data: IMicroEngDataComponent) {
        super(data);
        this.isBU = true;
        this.Eio = data.ExpIO_Avail ? data.ExpIO_Avail : '';
        this.AIpoint = data.IOpoints_AI ? data.IOpoints_AI: '',
        this.DIpoint = data.IOpoints_DI ? data.IOpoints_DI: '',
        this.DOpoint = data.IOpoints_DO ? data.IOpoints_DO: '',
        this.AOpoint = data.IOpoints_AO ? data.IOpoints_AO: '',
        this.plugin = data.PlugIn_Avail ? data.PlugIn_Avail : '';
    }
}
