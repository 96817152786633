import { LocAndSize, NormRect, Point, Size } from '../types/SizeAndPosTypes';
import {
    getEmptyPt,
    getOffset,
    getRectArea,
    getRectIntersection,
    locToNormRect,
    logPoint,
    logSize,
    roundPoint,
    sameSizes,
} from './GeneralHelpers';
import { logger } from './Logger';

const _roundTranslations = true;

export enum LocRelToView {
    Visible = 'Visible',
    Left = 'Left',
    Above = 'Above',
    Right = 'Right',
    Below = 'Below',
    Unknown = 'Unknown'
}


//
// START - FIXED constants (some calculated based on others)
// These probably don't need to ever be changed, and probably
// SHOULD NOT be without careful consideration.
//

// At FULL scale (1.0), our stage resolution
// would effectively be 96 'dots' per inch,
// where one stage unit === one 'dot'.
const _stageUnitsPerInchAtFullScale = 96;

// Using that, we can calculate how many units we'd
// have per screen millimeter (1" === 25.4mm).
// And AGAIN, that would be at FULL SCALE (1.0).
// The resulting number here is about 3.8.
// So, at a stage scale of 1.0, each millimeter
// of any chassis we display would use a little
// less than 4 'dots' in each direction.
const _stageUnitsPerMMAtFullScale = _stageUnitsPerInchAtFullScale / 25.4;

// We CAN 'tweak' the final number of stage units we
// actually use per millimeter by changing our size
// multiplier. Our final number is the number just
// above multiplied by this number.
// Using a number larger than 1.0 for this multiplier
// MIGHT improve our end resolution somewhat, but
// BE CAREFUL when trying larger numbers, as any increase
// in the multipler results in a corresponding increase
// in the actual size of our stage IN EACH DIRECTION.
// NOTE: The 1.0 seems like it'll likely work suitably
// for everything, and as such, we have NOT (yet) made.
// it adjustable on a client-by-client basis. So, a
// change HERE will affect EVERY CLIENT.
const _stageSizeMultiplier = 1.0;

// Margins and separation for the layout of one
// or more chassis. Values here are in millimeters.
const _mmContentSpacing = {
    _mmLeft: 20,         // left margin
    _mmRight: 20,        // right margin
    _mmTop: 30,          // top margin
    _mmBottom: 180,      // bottom margin
                         // (Per Colin - wants effective space
                         // for another chassis row below)
    _mmVerticalSep: 60   // vertical separation between chassis
};

// We'll expose the final number of stage units we're going
// to use (in all cases) per millimeter of actual size of
// whatever we're rendering (chassis, devices, etc.).
export const StageUnitsPerMM =
    _stageUnitsPerMMAtFullScale * _stageSizeMultiplier;
//
// END - FIXED constants
//


//
// START - Default values for adjustables
// These defaults are a good starting point
// in all cases seen so far
//

// Our default 'Base' scale is what we've determined is a
// decent scale starting point without any multiplier (see
// below). Essentially this is the scale (versus actual device
// size) that we want our chassis to be initially displayed.
const _dfltBaseStageScale = 0.4;

export const BaseScale = _dfltBaseStageScale / _stageSizeMultiplier;

const _chassisLabelBaseFontSize = 24;
export const ScaledChassisLabelSize = _chassisLabelBaseFontSize / BaseScale;

const _chassisLabelCharWidthFactor = 0.5;
export const ChassisLabelWidthPerChar =
    ScaledChassisLabelSize * _chassisLabelCharWidthFactor;

// Each zoom (in or out) will determine what our
// new stage scale should be by multiplying or
// the previous scale by the following.
// NOTE: we might at some point want to change this
// approach to use an increment/decrement instead of
// a multiplier, but the multiplier works fine for now.
const _dfltZoomBy = 1.1;

// Our range factor ultimately is used to determine
// maximum and minimum stage scales we'll allow.
// For example, if our range factor is 4, that would
// mean that our minumum scale would be 1/4 of our
// starting scale, and our max would be 4 times the 
// starting scale.
const _dfltZoomRangeFactor = 3;
//
// END - Default values for adjustables
//

const _stageSizeRightMrg = 0;
const _stageSizeBtmMrg = 0;


// Helper - returns the number of stage units
// used for the requested number of millimeters.
export const getStageDistance = (mm: number): number => {
    return (mm * StageUnitsPerMM);
}

// We'll also expose STANDARD spacing for margins and
// Separators. These will be used by ALL clients unless
// or until we'd decide different client cases wanted
// different spacing specs.
export const ContentSpacing = {
    leftMargin: getStageDistance(_mmContentSpacing._mmLeft),
    rightMargin: getStageDistance(_mmContentSpacing._mmRight),
    topMargin: getStageDistance(_mmContentSpacing._mmTop),
    bottomMargin: getStageDistance(_mmContentSpacing._mmBottom),
    verticalSep: getStageDistance(_mmContentSpacing._mmVerticalSep)
};


// Scale and size properties used on the Stage itself.
export interface StageSizeProps {
    scaleX: number;
    scaleY: number;
    x: number;
    y: number;
    width: number;
    height: number;
}


// The key we'll use for our Chassis Layout,
// which is currently our ONLY 'client'.
export const ChassisLayoutKey = "CHASSIS_LAYOUT";


// Stage and wrapping div-related info we keep for each client.
export interface StageInfo {

    // Ref to the outer div wrapping a Konva Stage.
    // The outer div is where any scrolling takes place
    // when the Stage is larger than the div's client area.
    wrapDiv: HTMLDivElement;

    // The original base scale provided when registered
    // (adjusted by our _stageSizeMultiplier) 
    baseScale: number;

    // Adjusment factor for each zoom in or out.
    zoomByFactor: number;

    // Minimum and maximum values we'll use
    // to bracket our allowable scale range.
    minScale: number;
    maxScale: number;

    // X and Y that should be used when asked for
    // Stage Props. Note: in general, this will be
    // at 0,0.
    // DURING wheel zooming, however, and ONLY during
    // wheel zooming, we'll tweak these to get our
    // stage to move around as needed WITHOUT having
    // to actually change our div's scroll position.
    origin: Point;

    // Full (unscaled) extent of content the LAST
    // time our getStageSizeProps function was called.
    contentExtent: Point;

    // Virtual size (width and height) of our stage the LAST
    // time our getStageSizeProps function was called.
    // Note: this size is generally the scaled version
    // of the extent, but is set LARGER if/when the
    // view's client size is larger in either or both
    // directions to take up AT LEAST the size of the
    // view.
    virtualStageSize: Size;

    // Visible size (width and height) of our stage the
    // LAST time anyone checked. This is essentially
    // the same size as the wrapper div's client area
    // minus a bit of buffer space on the right and 
    // below to make scrollbars more visible.
    visibleStageSize: Size;

    // The scale being CURRENTLY USED
    // by the Stage component.
    currentScale: number;

    // Used ONLY when a new chassis is added.
    scrollToBtmPending: boolean;
}

// Map of registered StageInfo objects,
// each with a unique string key.
const _stageInfoMap = new Map<string, StageInfo>();


// Registration Request function.
export const RegisterStageInfo = (
    wrapDiv: HTMLDivElement,
    key: string,
    baseScale = _dfltBaseStageScale,
    zoomBy = _dfltZoomBy,
    zoomRangeFactor = _dfltZoomRangeFactor
) => {

    // We now only allow a given key to be
    // registered ONCE. In order to re-register
    // a key, it must be UN-registered first.
    // If we had info for this key before...
    if (_stageInfoMap.has(key)) {

        // Error out.
        throw new Error('Stage already registered!');
    }
    else {
        // Calculate what our starting scale will be.
        // NOTE: Consider width of div here and adjust accordingly!
        const startScale = baseScale / _stageSizeMultiplier;

        // Then, using that, fill in our info object.
        const info: StageInfo = {
            wrapDiv: wrapDiv,
            baseScale: startScale,
            zoomByFactor: zoomBy,
            minScale: startScale / zoomRangeFactor,
            maxScale: startScale * zoomRangeFactor,
            origin: { x: 0, y: 0 },
            contentExtent: { x: 0, y: 0 },
            virtualStageSize: { width: 100, height: 100 },
            visibleStageSize: _getStageSize(wrapDiv),
            currentScale: startScale,
            //bringIntoViewPending: undefined
            scrollToBtmPending: false
        };

        // Add it to our map.
        _stageInfoMap.set(key, info);
    }
}


export const UnregisterStageInfo = (key: string) => {
    if (_stageInfoMap.has(key)) {
        _stageInfoMap.delete(key);
        logger.logCustom('Stage info removed for: ' + key);
    }
    else {
        logger.error('Request to UnregisterStageInfo FAILED: ' + key);
    }
}


// Get info for the requested key, which we EXPECT to
// be the key to a valid StageInfo object in our map.
// If not, we error out.
export const getStageInfo = (key: string): StageInfo => {

    // If our map has the key...
    if (_stageInfoMap.has(key)) {

        // Get the object at that key.
        const info = _stageInfoMap.get(key);

        // If we can, return it.
        if (info) {
            return info;
        }
    }

    // ALL OTHER cases are unexpected errors.
    throw new Error('ERROR: StageInfo not available for requested key: ' + key);
}


const _getStageSize = (wrapDiv: HTMLDivElement): Size => {
    return {
        width: wrapDiv.clientWidth - _stageSizeRightMrg,
        height: wrapDiv.clientHeight - _stageSizeBtmMrg
    }
}

const _getViewSize = (info: StageInfo, bufferMarg = 0): Size => {
    const viewSize: Size =  {
        width: info.wrapDiv.clientWidth,
        height: info.wrapDiv.clientHeight
    };
    if (bufferMarg !== 0) {
        viewSize.width - bufferMarg;
        viewSize.height - bufferMarg;
    }
    return viewSize;
}

export const getScaledContentSize = (extent: Point, scale: number): Size => {
    return {
        width: extent.x * scale,
        height: extent.y * scale
    };
}

export const setStageOrigin = (key: string, x: number, y: number) => {

    // If we have info for the requested key yet...
    if (_stageInfoMap.has(key)) {

        // Get it.
        const info = getStageInfo(key);

        // If, and ONLY if the incoming numbers are
        // different from our existing origin point...
        if ((x !== info.origin.x) || (y !== info.origin.y)) {

            // Save the new position.
            info.origin.x = x;
            info.origin.y = y;

            // We made a change. 
            // Return true.
            return true;
        }
    }

    // No change was made.
    return false;
}

export const getStageSizeProps = (key: string, contentExtent: Point):
    [
        stageSizeProps: StageSizeProps,
        virtSize: Size,
        maxNameWidth: number,
        scrollToBtmReqd: boolean
    ] => {

    // If we have info for the requested key yet...
    if (_stageInfoMap.has(key)) {

        //logger.log('getStageSizeProps');

        // Get it.
        const info = getStageInfo(key);

        // Save the content extent. Useful for debugging.
        info.contentExtent = { ...contentExtent };

        // Determine the size of our extent when scaled
        // into div coordinates.
        const scaledSize = getScaledContentSize(contentExtent, info.currentScale);

        info.visibleStageSize = _getStageSize(info.wrapDiv);
        info.virtualStageSize.width =
            Math.max(scaledSize.width, info.visibleStageSize.width);
        info.virtualStageSize.height =
            Math.max(scaledSize.height, info.visibleStageSize.height);

        const maxNameWidth = contentExtent.x - ContentSpacing.leftMargin - 10; 

        // Return props.
        return [{
            scaleX: info.currentScale,
            scaleY: info.currentScale,
            x: info.origin.x,
            y: info.origin.y,
            width: info.visibleStageSize.width,
            height: info.visibleStageSize.height
        }, info.virtualStageSize, maxNameWidth, info.scrollToBtmPending];
    }
    else {
        // The FIRST call we get will be BEFORE our
        // layout has been registered as a client. Just
        // return a valid set of props in that case. They
        // won't actually end up being used to render anything.
        return [{
            scaleX: BaseScale,
            scaleY: BaseScale,
            x: 0,
            y: 0,
            width: 100,
            height: 100
        }, { width: 100, height: 100 }, 100, false];
    }
}

// Basic functions.
// In EACH case, we'll check our map for the requested key
// and return a suitable default response if we don't find it.
// Pre-registration calls WILL be made by things like our
// zoom toolbar buttons, which call the canZoom functions to
// establish their enabled states.
export const getStageScale = (key: string): number => {
    if (_stageInfoMap.has(key)) {
        return getStageInfo(key).currentScale;
    }
    else {
        return BaseScale;
    }
}

export const updateStageSize = (key: string, viewSize: Size):
    [changed: boolean, size: Size] => {

    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);

        let sizeChanged = false;

        const newSize: Size = {
            width: viewSize.width - _stageSizeRightMrg,
            height: viewSize.height - _stageSizeBtmMrg
        }

        if (!sameSizes(newSize, info.visibleStageSize)) {
            info.visibleStageSize = newSize;
            sizeChanged = true;
        }

        return [sizeChanged, info.visibleStageSize];
    }
    else {
        throw new Error('Unexpected error in updateStageSize!');
    }
}

export const canZoomIn = (key: string): boolean => {
    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        return (info.currentScale < info.maxScale);
    }
    else {
        // Default to true for canZoom functions.
        return true;
    }
}

export const canZoomOut = (key: string): boolean => {
    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        return (info.currentScale > info.minScale);
    }
    else {
        return true;
    }
}

// Helper used by zoomIn and zoomOut.
// Coordinates out from this function are DOM coordinates.
const _getClientUpperLeftPt = (info: StageInfo): Point => {

    // Get our wrapper div's client rect as a LocAndSize (whose props
    // are all present in the DOMRect returned by getBoundingClientRect).
    const clientRect = info.wrapDiv.getBoundingClientRect();
    return {
        x: clientRect.x,
        y: clientRect.y
    };
}

// Given a point in DOM coordinates, return two
// points relative to our div:
//    ptDiv: incoming pt in div-local coords. This
//        point includes any area above and/or left
//        of what's currently visible in the client
//    ptDivClientOffset: incoming point relative to
//        upper left corner of div client window. This
//        point does NOT include any scrolled off portion.
const _getDivRelativeInfoForDomPt = (ptDOM: Point, info: StageInfo):
    [ptDiv: Point, ptDivClientOffset: Point] => {

    // Get the rect (in DOM coords) of our div's client area.
    const divClientRct = info.wrapDiv.getBoundingClientRect();

    // Determine the points offset from that 
    // rect's upper-left corner.
    const ptDivClientOffset: Point = {
        x: ptDOM.x - divClientRct.x,
        y: ptDOM.y - divClientRct.y
    };

    // Then add in any scrolled-off part
    // to get the point relative to the upper
    // left corner of the div itself.
    const ptDiv: Point = {
        x: (ptDivClientOffset.x + info.wrapDiv.scrollLeft),
        y: (ptDivClientOffset.y + info.wrapDiv.scrollTop)
    };

    return [ptDiv, ptDivClientOffset];
}

// NOTE: Include size of STAGE BORDER somewhere!!
const _tranlateDivToStage = (ptDiv: Point, info: StageInfo): Point => {
    const ptStage = {
        //x: (ptDiv.x - info.origin.x) / info.currentScale,
        x: ptDiv.x / info.currentScale,
        //y: (ptDiv.y - info.origin.y) / info.currentScale
        y: ptDiv.y / info.currentScale
    };

    if (_roundTranslations) {
        roundPoint(ptStage);
    }
    return ptStage;
}

const _translateStageToDiv = (ptStage: Point, info: StageInfo): Point => {
    const ptDiv = {
        x: (ptStage.x * info.currentScale) + info.origin.x,
        y: (ptStage.y * info.currentScale) + info.origin.y
    };

    if (_roundTranslations) {
        roundPoint(ptDiv);
    }

    return ptDiv;
}

const _translateDOMToDiv = (ptDOM: Point, info: StageInfo): Point => {

    // Get the rect (in DOM coords) of our div's client area.
    const divClientRct = info.wrapDiv.getBoundingClientRect();

    return {
        x: ptDOM.x - divClientRct.x + info.wrapDiv.scrollLeft,
        y: ptDOM.y - divClientRct.y + info.wrapDiv.scrollTop
    };
}

export const getStagePointFromDOMPt = (key: string, ptDOM: Point): Point => {
    if (_stageInfoMap.has(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        const ptDiv = _translateDOMToDiv(ptDOM, info);
        const ptStage = _tranlateDivToStage(ptDiv, info);

        return ptStage;
    }
    else {
        return getEmptyPt();
    }
}

export const getViewClientPointFromDOMPt = (key: string, ptDOM: Point): Point => {
    if (_stageInfoMap.has(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        // Get the rect (in DOM coords) of our div's client area.
        const divClientRct = info.wrapDiv.getBoundingClientRect();

        return {
            x: ptDOM.x - divClientRct.x,
            y: ptDOM.y - divClientRct.y
        };
    }
    else {
        return getEmptyPt();
    }
}


const _getRelLocOfDOMPt = (ptDOM: Point, info: StageInfo): LocRelToView => {

    // Get the client rect of the div.
    const divClientRct = info.wrapDiv.getBoundingClientRect();

    // Calculate deltas from point to each side.
    const deltaLeft = divClientRct.left - ptDOM.x;
    const deltaAbove = divClientRct.top - ptDOM.y;
    const deltaRight = ptDOM.x - divClientRct.right;
    const deltaBelow = ptDOM.y - divClientRct.bottom;

    // Directions only apply if associated delta is positive.
    const leftOf = (deltaLeft > 0) ? deltaLeft : 0;
    const above = (deltaAbove > 0) ? deltaAbove : 0;
    const rightOf = (deltaRight > 0) ? deltaRight : 0;
    const below = (deltaBelow > 0) ? deltaBelow : 0;

    // We can have more than one of the above in
    // play at the same time. That is, we can be
    // BOTH above AND to the left, etc. When that
    // happens, we choose the one with the bigger
    // distance.
    if (above > 0) {
        if (leftOf > above) {
            return LocRelToView.Left;
        }
        else if (rightOf > above) {
            return LocRelToView.Right;
        }
        else {
            return LocRelToView.Above;
        }
    }
    else if (below > 0) {
        if (leftOf > below) {
            return LocRelToView.Left;
        }
        else if (rightOf > below) {
            return LocRelToView.Right;
        }
        else {
            return LocRelToView.Below;
        }
    }
    else if (leftOf > 0) {
        return LocRelToView.Left;
    }
    else if (rightOf > 0) {
        return LocRelToView.Right;
    }
    return LocRelToView.Visible;
}

export const _canScollInRelDirection = (info: StageInfo, relLoc: LocRelToView): boolean => {
    switch (relLoc) {
        case LocRelToView.Left:
        case LocRelToView.Right:
            {
                const [canScroll, left, right] = _getHorzScrollInfo(info);
                if (canScroll) {
                    if (relLoc === LocRelToView.Left) {
                        return (left > 0);
                    }
                    else {
                        return (right > 0);
                    }
                }
            }
            break;

        case LocRelToView.Above:
        case LocRelToView.Below:
            {
                const [canScroll, above, below] = _getVertScrollInfo(info);
                if (canScroll) {
                    if (relLoc === LocRelToView.Above) {
                        return (above > 0);
                    }
                    else {
                        return (below > 0);
                    }
                }
            }
            break;

        default:
            break;
    }

    return false;
}

export const getStagePtInfo = (key: string, ptDOM: Point):
    [
        ptStage: Point,     // ptDom translated to stage coords.
        relLoc: LocRelToView, // Relative position of the DOM pt as
                            // compared with the view client window.
        canScroll: boolean  // Indicates whether scrolling is possible
                            // in the direction given by relLoc
    ] => {
    if (_stageInfoMap.has(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        const ptDiv = _translateDOMToDiv(ptDOM, info);

        const ptStage = _tranlateDivToStage(ptDiv, info);

        const relLoc = _getRelLocOfDOMPt(ptDOM, info);

        return [ptStage, relLoc, _canScollInRelDirection(info, relLoc)];
    }
    else {
        return [getEmptyPt(), LocRelToView.Unknown, false];
    }
}

const _scrollBump = 20;

// Adjusts scroll position (if possible) so that more of the
// view in the specified direction becomes visible. Example:
// If the relDir specified is .Left, then we want more of
// what's currently hidden on the left side to come into view.
export const bumpScroll = (key: string, relDir: LocRelToView) => {

    if (_stageInfoMap.has(key)) {

        // Get our stage info.
        const info = getStageInfo(key);

        switch (relDir) {
            case LocRelToView.Left:
                {
                    const [canScroll, left, /*right*/] = _getHorzScrollInfo(info);
                    if (canScroll) {
                        const bump = Math.min(_scrollBump, left);
                        if (bump > 0) {
                            info.wrapDiv.scrollLeft -= bump;
                        }
                    }
                }
                break;

            case LocRelToView.Right:
                {
                    const [canScroll, /*left*/, right] = _getHorzScrollInfo(info);
                    if (canScroll) {
                        const bump = Math.min(_scrollBump, right);
                        if (bump > 0) {
                            info.wrapDiv.scrollLeft += bump;
                        }
                    }
                }
                break;

            case LocRelToView.Above:
                {
                    const [canScroll, above, /*below*/] = _getVertScrollInfo(info);
                    if (canScroll) {
                        const bump = Math.min(_scrollBump, above);
                        if (bump > 0) {
                            info.wrapDiv.scrollTop -= bump;
                        }
                    }
                }
                break;

            case LocRelToView.Below:
                {
                    const [canScroll, /*above*/, below] = _getVertScrollInfo(info);
                    if (canScroll) {
                        const bump = Math.min(_scrollBump, below);
                        if (bump > 0) {
                            info.wrapDiv.scrollTop += bump;
                        }
                    }
                }
                break;

            default:
                break;
        }
    }
}

const logWrapDivInfo = (div: HTMLDivElement) => {
    logger.logCustom('Wrap Div Info');
    logger.logCustom('  .clientWidth: ' + div.clientWidth);
    logger.logCustom('  .clientHeight: ' + div.clientHeight);
    logger.logCustom('  .scrollWidth: ' + div.scrollWidth);
    logger.logCustom('  .scrollHeight: ' + div.scrollHeight);
    logger.logCustom('  .scrollLeft: ' + div.scrollLeft);
    logger.logCustom('  .scrollTop: ' + div.scrollTop);
}

const logStageInfo = (info: StageInfo) => {
    logger.logCustom('Stage Info');
    logger.logCustom('  .scale: ' + info.currentScale.toFixed(2));
    logPoint(info.origin, '  .origin')
    logPoint(info.contentExtent, '  .contentExtent');
    logSize(info.virtualStageSize, '  .virtualStageSize');
    logSize(info.visibleStageSize, '  .visibleStageSize');
}

export const logLayoutInfo = (key: string, logDiv: boolean, logStage: boolean) => {
    if (_stageInfoMap.has(key)) {

        const info = getStageInfo(key);
        if (logDiv) {
            logWrapDivInfo(info.wrapDiv);
        }
        if (logStage) {
            logStageInfo(info);
        }
    }
}

export const testDOMPt = (key: string, ptDOM: Point) => {
    logger.logCustom('testDOMPt');
    logPoint(ptDOM, 'DOM Point');
    if (_stageInfoMap.has(key)) {
        // Get our stage info.
        const info = getStageInfo(key);
        logStageInfo(info);
        logWrapDivInfo(info.wrapDiv);
        const ptStg = getStagePointFromDOMPt(key, ptDOM);
        logPoint(ptStg, 'Stage Pt Calc');
    }
}


export const wheelZoomIn = (key: string, stgZoomPt: Point): boolean => {
    // If zooming IN would be possible...
    if (canZoomIn(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        // Translate our stage pt to div coords
        // BEFORE we change the scale.
        const ptDivBefore = _translateStageToDiv(stgZoomPt, info);

        // Bump our scale up, but no higher than our max scale allowed.
        info.currentScale = Math.min(info.maxScale, info.currentScale * info.zoomByFactor);

        // Then do another translation to div AFTER the scale change.
        const ptDivAfter = _translateStageToDiv(stgZoomPt, info);

        // Determine the offset between our two div
        // points (before and after).
        const offset = getOffset(ptDivBefore, ptDivAfter);

        // Adjust our origin point accordingly.
        info.origin.x -= offset.x;
        info.origin.y -= offset.y;
        return true;
    }
    else {
        return false;
    }
}

export const wheelZoomOut = (key: string, stgZoomPt: Point): boolean => {

    // If zooming OUT would be possible...
    if (canZoomOut(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        // Translate our stage pt to div coords
        // BEFORE we change the scale.
        const ptDivBefore = _translateStageToDiv(stgZoomPt, info);

        // Drop our scale down, but no lower than our min scale allowed. 
        info.currentScale = Math.max(info.minScale, info.currentScale / info.zoomByFactor);

        // Then do another translation to div AFTER the scale change.
        const ptDivAfter = _translateStageToDiv(stgZoomPt, info);

        // Determine the offset between our two div
        // points (before and after).
        const offset = getOffset(ptDivBefore, ptDivAfter);

        // Adjust our origin point accordingly.
        info.origin.x -= offset.x;
        info.origin.y -= offset.y;
        return true;
    }
    else {
        return false;
    }
}

// Caller wants to place incoming stagePt (stage coordinates)
// so that it falls directly on the clientPt provided, which
// is given in pixels relative to the layout viewport.
export const alignStagePtOntoClient = (key: string, stagePt: Point, clientPt: Point) => {

    if (_stageInfoMap.has(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        // DE-scale the stage point to get its location
        // in its parent div (the virtual stage).
        const ptDiv: Point = {
            x: Math.round(stagePt.x * info.currentScale),
            y: Math.round(stagePt.y * info.currentScale)
        }

        // Calculate optimal scroll position that
        // would make points exactly aligned.
        const optScroll: Point = {
            x: ptDiv.x - clientPt.x,
            y: ptDiv.y - clientPt.y
        }

        // We WANT to use that optimal scroll position
        // if we can, but we need to stay in range of what's
        // actually possible. Determine the max scroll possible
        // in each direction.
        const maxScroll: Point = {
            x: Math.round(info.virtualStageSize.width - info.visibleStageSize.width),
            y: Math.round(info.virtualStageSize.height - info.visibleStageSize.height)
        }

        // Now determine where our scroll position should
        // actually end up. For each direction, we'll use
        // our optimal position, but constrained to be
        // somewhere between 0 and max, inclusive.
        const scrollPos: Point = {
            x: Math.min(maxScroll.x, Math.max(0, optScroll.x)),
            y: Math.min(maxScroll.y, Math.max(0, optScroll.y))
        }

        // Set our new scroll position.
        info.wrapDiv.scrollLeft = scrollPos.x;
        info.wrapDiv.scrollTop = scrollPos.y;
    }
}

export const isValidScrollPos = (pos: Point): boolean => {
    return ((pos.x >= 0.0) && (pos.y >= 0.0));
}

// NOTE: for the general-purpose zoomIn and zoomOut functions, we use
// the center of our div's client window as the zoom point (DOM coords).
export const zoomIn = (key: string): boolean => {

    // If zooming IN would be possible...
    if (canZoomIn(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        // Save the old scroll position of our div.
        const oldScrollPos = _getWrapDivScrollPos(info);

        // Call a helper to give us the upper left corner
        // of our div's client window in DOM coordinates.
        const zoomPt = _getClientUpperLeftPt(info);

        // Get div-related info from that DOM pt.
        const [ptDiv, ptDivClientOffset] = _getDivRelativeInfoForDomPt(zoomPt, info);

        // Convert the div-relative pt to a point in stage coordinates.
        const stagePt = _tranlateDivToStage(ptDiv, info);

        // Bump our scale up, but no higher than our max scale allowed. 
        info.currentScale = Math.min(info.maxScale, info.currentScale * info.zoomByFactor);

        // We changed the scale. Determine where our SAME
        // stage point would NOW be relative to our div.
        const ptDivNew: Point = {
            x: stagePt.x * info.currentScale,
            y: stagePt.y * info.currentScale
        };

        // Deterine where the scroll position will
        // NOW be, excluding any range checks.
        const newScrollPos: Point = {
            x: ptDivNew.x - ptDivClientOffset.x,
            y: ptDivNew.y - ptDivClientOffset.y
        };

        // Determine the offset between the old and
        // the new scroll positions.
        const offset = getOffset(newScrollPos, oldScrollPos);

        // And use that to 'pan' the stage.
        panStage(key, offset);

        return true;
    }
    else {
        return false;
    }
}

export const zoomOut = (key: string): boolean => {

    // If zooming OUT would be possible...
    if (canZoomOut(key)) {
        // Get our stage info.
        const info = getStageInfo(key);

        // Save the old scroll position of our div.
        const oldScrollPos = _getWrapDivScrollPos(info);

        // Call a helper to give us the upper left corner
        // of our div's client window in DOM coordinates.
        const zoomPt = _getClientUpperLeftPt(info);

        // Get div-related info from that DOM pt.
        const [ptDiv, ptDivClientOffset] = _getDivRelativeInfoForDomPt(zoomPt, info);

        // Convert the div-relative pt to a point in stage coordinates.
        const stagePt = _tranlateDivToStage(ptDiv, info);

        //const oldScale = info.currentScale.toFixed(2);

        // Drop our scale down, but no lower than our min scale allowed. 
        info.currentScale = Math.max(info.minScale, info.currentScale / info.zoomByFactor);

        //logger.logCustom('Scale changed from ' + oldScale +
        //    ' to ' + info.currentScale.toFixed(2));

        // We changed the scale. Determine where our SAME
        // stage point would NOW be relative to our div.
        const ptDivNew: Point = {
            x: stagePt.x * info.currentScale,
            y: stagePt.y * info.currentScale
        };

        // Deterine where the scroll position will
        // NOW be, excluding any range checks.
        const newScrollPos: Point = {
            x: ptDivNew.x - ptDivClientOffset.x,
            y: ptDivNew.y - ptDivClientOffset.y
        };

        // Determine the offset between the old and
        // the new scroll positions.
        const offset = getOffset(newScrollPos, oldScrollPos);

        // And use that to 'pan' the stage.
        panStage(key, offset);

        return true;
    }
    else {
        return false;
    }
}

const _getHorzScrollInfo = (info: StageInfo):
    [canScroll: boolean, left: number, right: number] => {

    if (info.wrapDiv.scrollWidth > info.wrapDiv.clientWidth) {
        const totalAvail = info.wrapDiv.scrollWidth - info.wrapDiv.clientWidth;
        return [true, info.wrapDiv.scrollLeft, totalAvail - info.wrapDiv.scrollLeft];
    }

    return [false, 0, 0];
}

const _getVertScrollInfo = (info: StageInfo):
    [canScroll: boolean, above: number, below: number] => {

    if (info.wrapDiv.scrollHeight > info.wrapDiv.clientHeight) {
        const totalAvail = info.wrapDiv.scrollHeight - info.wrapDiv.clientHeight;
        return [true, info.wrapDiv.scrollTop, totalAvail - info.wrapDiv.scrollTop];
    }

    return [false, 0, 0];
}

export const getHorzScrollInfo = (key: string):
    [canScroll: boolean, left: number, right: number] => {

    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        return _getHorzScrollInfo(info);
    }
    return [false, 0, 0];
}

export const getVertScrollInfo = (key: string):
    [canScroll: boolean, above: number, below: number] => {

    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        return _getVertScrollInfo(info);
    }
    return [false, 0, 0];
}

export const canScroll = (key: string): boolean => {
    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        if (info.wrapDiv.scrollHeight > info.wrapDiv.clientHeight) {
            return true;
        }
        if (info.wrapDiv.scrollWidth > info.wrapDiv.clientWidth) {
            return true;
        }
    }
    return false;
}


const _getWrapDivScrollPos = (info: StageInfo): Point => {
    return {
        x: info.wrapDiv.scrollLeft,
        y: info.wrapDiv.scrollTop
    };
}

export const getStageDivScrollPos = (key: string): Point => {
    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        return _getWrapDivScrollPos(info);
    }

    return getEmptyPt();
}

const _adjustScroll = (key: string, horz: boolean, shift: number) => {
    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        if (horz) {
            info.wrapDiv.scrollLeft += shift;
        }
        else {
            info.wrapDiv.scrollTop += shift;
        }
    }
}

export const panStage = (key: string, offset: Point) => {
    // If we have any horizontal offset...
    if (offset.x !== 0) {
        // Call a helper to tell us:
        //   1. if horz scrolling is even possible.
        //   2. if so...
        //      how large is the area to the
        //      left and/or right that we COULD
        //      scroll over to.
        const [horzOK, availLeft, availRight] = getHorzScrollInfo(key);

        // If we can do anything horizontally...
        if (horzOK) {
            // If our offset is to the left...
            if (offset.x < 0) {
                // and there's any hidden space on the RIGHT...
                if (availRight > 0) {
                    // We'll shift, but no MORE than
                    // what's actually available.
                    const shift = Math.min(-offset.x, availRight);
                    _adjustScroll(key, true, shift);
                }
            }
            else {
                // Offset MUST be to the right. If we can
                // move accordingly...
                if (availLeft > 0) {
                    // We'll shift, but no MORE than
                    // what's actually available.
                    const shift = Math.min(offset.x, availLeft);
                    _adjustScroll(key, true, -shift);
                }
            }
        }
    }

    // Then do essentially the same thing that we did above
    // if we have any movement in the vertical direction,
    // but this time using vertical scrolling info.
    if (offset.y !== 0) {
        const [vertOK, availAbove, availBelow] = getVertScrollInfo(key);
        if (vertOK) {
            if (offset.y < 0) {
                if (availBelow > 0) {
                    const shift = Math.min(-offset.y, availBelow);
                    _adjustScroll(key, false, shift);
                }
            }
            else {
                if (availAbove > 0) {
                    const shift = Math.min(offset.y, availAbove);
                    _adjustScroll(key, false, -shift);
                }
            }
        }
    }
}

export enum AutoScaleFitType {
    Width = 'Width',
    All = 'All',
    NewContent = 'NewContent'
}

const _minFitToNewContentScale = 0.30;

export const autoScaleToContent = (
    key: string,
    contentExtent: Point,
    fitType: AutoScaleFitType
    ) => {

    // If we have info for the key requested...
    if (_stageInfoMap.has(key)) {

        // Get it.
        const info = getStageInfo(key);

        // Make sure we leave it unscrolled.
        info.wrapDiv.scrollLeft = 0;
        info.wrapDiv.scrollTop = 0;

        // Get the size of our client window.
        const viewSize = _getViewSize(info);

        // Width is used for all of our fit types. Calc
        // the scale we'd end up with if the width of our
        // extent was matched to our view width. For our
        // 'Width' fit type, this will be our new scale.
        let calcScale = viewSize.width / contentExtent.x;

        switch (fitType) {
            case AutoScaleFitType.NewContent:
                // When autoscaling for new content, we don't want
                // to go smaller than a specified scale, so that
                // chassis that are very wide don't get too small.
                calcScale = Math.max(calcScale, _minFitToNewContentScale);
                break;

            case AutoScaleFitType.All:
                // For the 'All' fit type, we'll also consider the scale
                // needed to show the full height our our content's extent.
                // Use the smaller of that number and the scale we already
                // calculated above for the content's width.
                calcScale = Math.min(calcScale, viewSize.height / contentExtent.y);
                break;

            default:
                break;
        }

        // Finally...
        // We don't want a scale that's TOO large.
        // Set the scale to use to be the smaller of
        // what we calculated and our base scale.
        info.currentScale = Math.min(calcScale, info.baseScale);
    }
}

export const _getDivCorners = (info: StageInfo): [ptUL: Point, ptLR: Point] => {
    const viewSize = _getViewSize(info);
    const ptUL: Point = {
        x: info.wrapDiv.scrollLeft,
        y: info.wrapDiv.scrollTop
    };
    const ptLR: Point = {
        x: ptUL.x + viewSize.width,
        y: ptUL.y + viewSize.height
    }
    return [ptUL, ptLR];
}

const _getVisibleStageRect = (info: StageInfo): NormRect => {
    const [divUL, divLR] = _getDivCorners(info);
    const stageUL = _tranlateDivToStage(divUL, info);
    const stageLR = _tranlateDivToStage(divLR, info);
    return {
        left: stageUL.x,
        top: stageUL.y,
        right: stageLR.x,
        bottom: stageLR.y
    }
}

export const getVisibleStageRect = (key: string): NormRect => {
    // If we have info for the key requested...
    if (_stageInfoMap.has(key)) {

        // Get it.
        const info = getStageInfo(key);

        return _getVisibleStageRect(info);
    }
    else {
        return { left: 0, top: 0, right: 0, bottom: 0 };
    }
}

const _pctVisConsideredInView = 0.6;


// This function is generally used to request a scroll to the
// bottom of our layout view after a NEW chassis was added.
// It COULD however be called by anyone that, for some reason,
// just wanted to scroll to the bottom.
export const scrollToBottom = (key: string) => {
    if (_stageInfoMap.has(key)) {

        // Get it.
        const info = getStageInfo(key);
        // Make sure the .scrollToBtmPending is reset.
        info.scrollToBtmPending = false;

        const vertScrollPossible = info.wrapDiv.scrollHeight - info.wrapDiv.clientHeight;
        info.wrapDiv.scrollTop = vertScrollPossible;

        //alert('Scroll To Bottom Here!');

    }
}

export const requestPendingScrollToBtm = (key: string) => {
    if (_stageInfoMap.has(key)) {

        // Get it.
        const info = getStageInfo(key);

        // Sanity check that our info doesn't
        // ALREADY have one pending. If not...
        if (!info.scrollToBtmPending) {

            // Set the flag.
            info.scrollToBtmPending = true;
        }
        else {
            // Unexpected.
            throw new Error('Unexpected error in requestScrollToBtm');
        }
    }
}

// NOTE: This function should NOT be called unless the loc
// provided is ALREADY inside of our existing content, and
// has ALREADY been rendered at least once.
// When a NEW chassis is added (via chassis config), call
// the requestPendingScrollToBtm function above instead.
export const bringLocIntoView = (key: string, loc: LocAndSize) => {

    // If we have info for the key requested...
    if (_stageInfoMap.has(key)) {

        // Get it.
        const info = getStageInfo(key);

        // Sanity check. The scroll-to-btm flag should
        // never be set when bringLocIntoView is called.
        if (info.scrollToBtmPending) {
            // Unexpected.
            throw new Error('Unexpected error in requestScrollToBtm');
        }

        // Convert incoming loc to a NormRect (where right
        // and bottom guaranteed to be >= left and top).
        // Both loc and normLoc are in Stage units.
        const normLoc = locToNormRect(loc);

        //logStageInfo(info);
        //logWrapDivInfo(info.wrapDiv);

        // Get a norm rect representing the visible
        // area of the stage, in Stage units.
        const normStg = _getVisibleStageRect(info);

        // Get the intersection of the two, if there is one.
        const [intersection, intRect] = getRectIntersection(normStg, normLoc);

        // If there is...
        if (intersection) {
            // Determine the percentage of the loc that's actually visible.
            const pctVisible = getRectArea(intRect) / getRectArea(normLoc);

            // If it meets our minimum requirement, just
            // return. The requested loc is already
            // in view (enough).
            if (pctVisible >= _pctVisConsideredInView) {
                return;
            }
        }

        // If we get here, the loc requested is either NOT inside
        // of the visible stage area, or it IS, but not ENOUGH
        // of it is showing. Here, we want to reposition things
        // so that the loc (for a chassis) ends up in a logical
        // position in our viewport.
        // Start by calculating the offset we want the top of the loc
        // to end up, in stage units, relative to the top of our viewport
        let desiredYOffsetInStgUnits = 0;

        // If its ABOVE our current view...
        if (normLoc.top < normStg.top) {
            // We'll use our normal top margin.
            desiredYOffsetInStgUnits = ContentSpacing.topMargin;
        }
        else {
            // Must be below.
            // Use the current viewport height, in stage units, then
            // subtract the loc's height and our normal BOTTOM margin.
            const visHtInStgUnits = normStg.bottom - normStg.top;
            desiredYOffsetInStgUnits = visHtInStgUnits - ContentSpacing.bottomMargin - loc.height;
        }

        // Subtract that offset from the loc's top to determine the new
        // stage y that we want the top of our viewport to positioned at.
        const topInStageUnits = normLoc.top - desiredYOffsetInStgUnits;

        // Then multiply that by our scale to get that position in pixels.
        const topScaled = Math.round(topInStageUnits * info.currentScale);

        // We'll set our vertical scroll position to match, but
        // we want to make sure not to exceed the max possible.
        const maxVertScroll = Math.round(info.wrapDiv.scrollHeight - info.wrapDiv.clientHeight);

        // Set the scroll position on our div.
        // NOTE: Our info's origin will ulimately get set, so
        // that its y will be the negative of our scroll
        // position. However, we DON'T want to do that here.
        // When we change the scroll position on our div, the
        // scroll handler in our layout will adjust that for us.
        info.wrapDiv.scrollTop = Math.min(topScaled, maxVertScroll);
    }
}

// Currently called exclusively from our layout view's
// useDrop implementation. The wndOffset here is essentially
// the offset of a point from the client window of the browser.
// In that useDrop case, it's the current pointer position, or
// potential drop location of something being dragged.
export const getStagePtUsingOffset = (key: string, wndOffset: Point): Point => {

    if (_stageInfoMap.has(key)) {
        const info = getStageInfo(key);
        const scrollPos = _getWrapDivScrollPos(info);
        const rct = info.wrapDiv.getBoundingClientRect();

        return {
            x: (wndOffset.x - rct.x + scrollPos.x) / info.currentScale,
            y: (wndOffset.y - rct.y + scrollPos.y) / info.currentScale
        };
    }

    return { x: -1, y: -1 };
}
