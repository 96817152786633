import { getImageSource } from "../implementation/ImplGeneral";
import { addChassisExtrasToBOM } from "../implementation/ImplHardwareGen";
import { getChassisName, getDeviceCategory, getProductDescription } from "../model/ChassisProject";
import {
	RaiseDevData,
    raiseDevDataQualifiesForRefetch
} from "../raiseDatabase/DeviceCache";
import {
	RADBCacheStatus,
	txtRADB_LOADING,
	txtRADB_NA,
 } from "../types/APITypes";
import {
	Chassis,
	ChassisProject,
	DeviceCategory,
	DeviceType,
	GraphicalDevice,
	RackGroup
} from "../types/ProjectTypes"
import { parseEngDataCatNoAndCountStr, getEngInfoForComp } from "../util/EngInfoHelp";

///////////////// ENUMS //////////////////////////////
export enum BOMItemType {
	MainHeader = 'MainHeader',
	Header = 'Header',
	Product = 'Product',
}


///////////////// INTERFACES //////////////////////////////
export interface BOMItem {
	bomItemType: BOMItemType;
	catalogOrTitle: string;
	quantity: number;
	children: BOMItem[];
}


export interface BOMItemProduct extends BOMItem {
	productType: string; // DeviceType
	productCategory: DeviceCategory; 
	description: string;
	image: string;  // old
	imgSrc: string; // replacement?
	details: BOMDetail[];
	detailsPending: boolean;
	listPriceEa: number;
	totalCost: string;
	parentCatno?: string;
}


export interface BOMDetail {
	title: string;
	value: string;
}

export interface IExternalBOM {
    // Objects - static/global
    consolidatedList: Array<BOMItem>;
    organizedList: Array<BOMItem>;

    // Methods
    addDevice: (
        device: GraphicalDevice,
        chassisConsMap: itemBomItemConsolMap,
        redSecondary: boolean,
        quantity: number
    ) => number;

    editComponent: (comp: BOMItemProduct, chassisConsMap: itemBomItemConsolMap) => void;

    addComponent: (comp: BOMItemProduct, chassisConsMap: itemBomItemConsolMap) => void;

    convertBOMItemMapIntoArray: (localMap: Map<string, BOMItem>) => BOMItem[];
}

///////////////// INTERFACE FACTORIES //////////////////////////////

export const createBOMHeader = (type: BOMItemType, title: string, quantity: number): BOMItem => {
	return {
		bomItemType: type,
		catalogOrTitle: title,
		quantity: quantity,
		children: [],
	};
}


export const createBOMItemProductDetailsFromRaiseData = (product: BOMItemProduct, data: RaiseDevData) => {
    product.details = [];
    product.details.push({ title: 'Stock Status:', value: data.stockStatusDisplay });
    product.details.push({ title: 'Lifecycle Status:', value: data.prodLifeCycleStatus });
    product.details.push({ title: 'Price Per Unit:', value: data.displayPrice });
    if (data.status === RADBCacheStatus.Loaded) {
        // 2024.4.10 We are NOT going to deal with
        // numbers here. The displayPrice has the
        // correct price for a SINGLE unit formatted
        // with the correct currency. Set the total
        // cost to the displayPrice (not using displayPriceVal).
        const totalCost = product.quantity * data.displayPriceVal;
        //product.totalCost = (totalCost === 0 ? txtRADB_NA : formatToDollars(totalCost));
        product.totalCost = (totalCost === 0 ? txtRADB_NA : data.displayPrice);

        // Set the details pending if it qualifies
        // for a re-fetch.
        product.detailsPending = raiseDevDataQualifiesForRefetch(data);
    }
    else {
        product.totalCost = txtRADB_LOADING;
        product.detailsPending = true;
    }
}

export const createBOMItemProduct = (
    platform: string,
    catalog: string,
    deviceType: string,
    quantity = 1,
    description?: string): BOMItemProduct => {

    return {
        bomItemType: BOMItemType.Product,
        productType: deviceType,
        productCategory: DeviceCategory.Other,
        catalogOrTitle: catalog,
        quantity: quantity,
        children: [],
        description: (description ? description : getProductDescription(platform, catalog)),
        image: '',
        imgSrc: '',
        totalCost: txtRADB_LOADING,
        details: [],
        detailsPending: true,
        listPriceEa: 0,
    };
}


///////////////// STATIC OBJECTS //////////////////////////////

const _consolidatedList: Array<BOMItem> = [];
const _organizedList: Array<BOMItem> = [];
let _totalProducts = 0;

// Internal - used to create _consolidatedList.
const _consolidatedMap = new Map<string, BOMItemProduct>();

// Accessors
export const getConsolidatedBomItems = (): Array<BOMItem> => {
	return _consolidatedList;
}

export const getOrganizedBomItems = (): Array<BOMItem> => {
	return _organizedList;
}

export const getTotalProductCount = (): number => {
	return _totalProducts;
}


///////////////// UTILITIES //////////////////////////////
export const updateSummary = (project: ChassisProject) => {
	//logger.log('Updating Summary');
	updateBOMs(project.content);
}

export const isBOMProduct = (item: BOMItem): boolean => {
	if (item.bomItemType === BOMItemType.Product) {
		const asProd = item as BOMItemProduct;
		if (asProd.image != null && asProd.description != null)
			return true;
	}

	return false;
}


const _addProductToConsolidatedMap = (product: BOMItemProduct, productCount: number) => {

	let mapEntry = _consolidatedMap.get(product.catalogOrTitle);
	if (mapEntry == null) {
		mapEntry = { ...product }; // dupe it.
		mapEntry.quantity = productCount;
		_consolidatedMap.set(product.catalogOrTitle, mapEntry);
	}
	else {
		// Increment the product quantity.
		mapEntry.quantity += productCount;
	}

	// Update our total product count.
	_totalProducts += productCount;
}


const _getBOMItemPrecedence = (item: BOMItem): number => {
    // When sorting, a lower precedence item will appear
    // BEFORE an item with a larger precedence.

    switch (item.bomItemType) {
        case BOMItemType.MainHeader:
            // Main Header always at the top.
            return 0;

        case BOMItemType.Header:
            // Regular Header will always be at the bottom. For example,
            // we have a main header with a 'regular' header and some
            // products as children. The products will show directly
            // under the MAIN header followed by the 'regular' header(s). 
            return 100;

        case BOMItemType.Product:
            switch ((item as BOMItemProduct).productType) {
                case DeviceType.Chassis:
                    return 1;
                case DeviceType.PS:
                    return 2;
                case DeviceType.Controller:
                    return 3;
                case DeviceType.SafetyPartner:
                    return 4;
                case DeviceType.CommModule:
                    return 5;
                case DeviceType.RedundMod:
                    return 6;
                case DeviceType.Motion:
                    return 7;
                case DeviceType.IOModule:
                    return 8;
                case DeviceType.SpecialtyMod:
                    return 9;
                case DeviceType.FPD:
                    return 10;
                case DeviceType.SlotFiller:
                    return 11;
                case DeviceType.Other:
                    return 12;
                case DeviceType.Accessory:
                    return 13;
                default:
                    break;
            }
            break;
    }

    // We do not know what it is... 
    // Call it something less than 
    // BOMItemType.Header (100).
    return 99;
}

const _sortPredicateDeviceArray = (a: BOMItem, b: BOMItem): number => {
	const precA = _getBOMItemPrecedence(a);
	const precB = _getBOMItemPrecedence(b);
	if (precA < precB)
		return -1;
	else if (precA > precB)
		return 1;

	// We have the same types and/or precedence -
	// sort by Catalog/Title comparison.
	return a.catalogOrTitle.localeCompare(b.catalogOrTitle);
}

const _sortBOMItemChildren = (children: BOMItem[]) => {
	children.sort(_sortPredicateDeviceArray);

	// Check if we need to recurse... We need to recurse
	// when a child has children.
	children.forEach((child) => {
		if (child.children.length) {
			child.children.sort(_sortPredicateDeviceArray);
		}
	});
}

const _convertBOMItemMapIntoArray = (localMap: Map<string, BOMItem>): BOMItem[] => {
	const arrProds: BOMItem[] = [];
	localMap.forEach((val) => {
		arrProds.push(val);
		// If the item has children...
		if (val.children.length > 0) {
			_sortBOMItemChildren(val.children);
		}
	});

	// Sort our main array... Note: sort does NOT make
	// a new array - it affects the array instance.
	arrProds.sort(_sortPredicateDeviceArray);

	return arrProds;
}


const _redSecAccySkips = new Set<string>();
let _redSecAccySkipsLoaded = false;

const _loadRedSecAccySkips = () => {
	_redSecAccySkips.add('1756-RMC1');
	_redSecAccySkips.add('1756-RMC3');
	_redSecAccySkips.add('1756-RMC10');

	_redSecAccySkipsLoaded = true;
}

type itemBomItemConsolMap = Map<string, BOMItemProduct>;

const addToConsolMap = (item: BOMItemProduct, consMap: itemBomItemConsolMap) => {
    const existingEntry = consMap.get(item.catalogOrTitle);
    //logger.log( "existingentry", consMap)
    if (existingEntry) {
        existingEntry.quantity += item.quantity;
    }
    else {
        consMap.set(item.catalogOrTitle, { ...item });
    }
}

const editToConsolMap = (item: BOMItemProduct, consMap: itemBomItemConsolMap) => {

    consMap?.forEach((value, key) => {
        if (key === item.parentCatno) {
            const existResult = value?.children?.findIndex(
                (res) => res?.catalogOrTitle === item?.catalogOrTitle
            );
            const getMatchingValue = value.children.length !== 0 && value.children[existResult];
            if (getMatchingValue && existResult > -1) {
                getMatchingValue.quantity += item.quantity;
            }
            else {
                value.children.push(item);
            }
        }
    });
}

const editComponent = (comp: BOMItemProduct, chassisConsMap: itemBomItemConsolMap) => {
	editToConsolMap(comp, chassisConsMap);
	_addProductToConsolidatedMap(comp, comp.quantity);
}


const addComponent = (comp: BOMItemProduct, chassisConsMap: itemBomItemConsolMap) => {
	addToConsolMap(comp, chassisConsMap);
	_addProductToConsolidatedMap(comp, comp.quantity);
}

const addDevice = (
    device: GraphicalDevice,
    chassisConsMap: itemBomItemConsolMap,
    redSecondary: boolean,
    quantity = 1
): number => {

    // If our 'device' is a placeholder, don't
    // add anything. Placeholders are devices
    // that serve a role, but aren't 'real', 
    // orderable products. Ex: a 5069 chassis.
    if (device.isPlaceholder) {
        return 0;
    }

    // We'll return the total number of
    // components we end up adding. Init
    // that to be the incoming quantity.
    let qtyAdded = quantity;

    // Declare an 'Alias Added' flag.
    let aliasProdAdded = false;
    // This will be the catalog that any
    // accessories will be place under.
    let accyParentCat = device.catNo;

    // Check if this product has an alias...
    const info = getEngInfoForComp(device.platform, device.catNo);
    if (info) {
        // If we have an alias...
        if (info.Alias_Actual_CatNo.length > 0) {
            // Note: Some catalogs may have more than one
            // associated aliases. When this occurs, the
            // FIRST alias listed will be the Main Catalog.
            const [mainCat, numAdded] = addAlias(device, info.Alias_Actual_CatNo, quantity, chassisConsMap);
            // If the alias added any items...
            if (numAdded > 0 && mainCat.length > 0) {
                // Any accessories for this device will
                // be added under the alias' main catalog.
                // Set our flag that an alias has been 
                // added and reset the added quantity to 
                // what the alias added.
                accyParentCat = mainCat;
                aliasProdAdded = true;
                qtyAdded = numAdded;
            }
        }
    }

    // If we this device did NOT have an alias...
    if (!aliasProdAdded) {
        // Create a BOM item for the device we were given.
        //const prodItem: BOMItemProduct = {
        //	bomItemType: BOMItemType.Product,
        //	productType: device.deviceType,
        //	productCategory: DeviceCategory.Other,
        //	catalogOrTitle: device.catNo,
        //	quantity: quantity,
        //	children: [],
        //	description: device.description,
        //	image: '',
        //	imgSrc: device.imgSrc,
        //	totalCost: txtRADB_LOADING,
        //	details: [],
        //	detailsPending: true,
        //	listPriceEa: 0
        //};
        const prodItem = createBOMItemProduct(device.platform, device.catNo, device.deviceType, quantity, device.description);
        prodItem.imgSrc = device.imgSrc;

        // and add it.
        addComponent(prodItem, chassisConsMap);
    }

    // Then, if the device has any accessories...
    if (device.accys) {

        // For each one...
        device.accys.forEach(accy => {

            // Determine if we should skip this one.
            // The only time we skip is if we're adding
            // to the secondary of a redundant chassis,
            // and then only if the accy is in our set
            // of catNos we're supposed to skip over in
            // for that case.
            const skip = redSecondary
                ? _redSecAccySkips.has(accy)
                : false;

            // If we're NOT skipping this one...
            if (!skip) {
                // Create a BOM item.
                //const accyItem: BOMItemProduct = {
                //	bomItemType: BOMItemType.Product,
                //	productType: DeviceType.Accessory,
                //	productCategory: DeviceCategory.Other,
                //	catalogOrTitle: accy,
                //	parentCatno: devCatalog,
                //	quantity: 1,
                //	children: [],
                //	description: getProductDescription(device.platform, accy),
                //	image: '',
                //	imgSrc: '',
                //	totalCost: txtRADB_LOADING,
                //	details: [],
                //	detailsPending: true,
                //	listPriceEa: 0,
                //};

                // 2024.6.7 If we have a quantity of
                // more than one DEVICE being added, 
                // should we be adding that quantity 
                // of accessories ???? 
                const accyItem = createBOMItemProduct(device.platform, accy, DeviceType.Accessory);
                accyItem.parentCatno = accyParentCat;

                // Increment our total added.
                qtyAdded++;

                // And add the accy item.
                editComponent(accyItem, chassisConsMap);
            }
        });
    }

    // Return our final total.
    return qtyAdded;
}

const addAlias = (device: GraphicalDevice, Alias_Actual_CatNo: string, quantity: number, chassisConsMap: itemBomItemConsolMap): [mainCat: string, qtyAdded: number] => {
    // IMPORTANT: We do NOT add any Alias Accessories.
    // Any Accessories or required catalogs should
    // be ON THE PARENT DEVICE (device: GraphicalDevice).
    let totalQty = 0;
    let mainCat = '';
    // The alias can contain more than 1 catalog.
    // Each alias catalog can have a count to add.
    // Parse the string into {catalog, count}[].
    const arrAlias = parseEngDataCatNoAndCountStr(Alias_Actual_CatNo);
    if (arrAlias) {
        arrAlias.forEach((infoCatCnt) => {
            // The first alias will be the Main Catalog.
            // Any accessories on the Device will be
            // added under the Main Catalog.
            if (!mainCat)
                mainCat = infoCatCnt.catalog;

            // We can have a quantity of the alias catalog to add.
            // Total item qty will be parent qty * alias cat qty.
            const totalItemQty = infoCatCnt.count * quantity;

            // Create the BOMItemProduct and start the image
            // source to the parent device.
            const prodItem = createBOMItemProduct(device.platform, infoCatCnt.catalog, DeviceType.Other, totalItemQty);
            prodItem.imgSrc = device.imgSrc;

            // Get the Eng Info for the alias catalog. If we can...
            const info = getEngInfoForComp(device.platform, infoCatCnt.catalog);
            if (info) {
                // Set the product type and catagory.
                prodItem.productType = info.type;
                prodItem.productCategory = getDeviceCategory(info.type);

                // If the alias catalog has an image...
                if (info.imgName.length > 0) {
                    // If we can get the image source,
                    // update the BOMItemProduct.
                    const imgSrc = getImageSource(device.platform, prodItem.productCategory, info.imgName);
                    if (imgSrc.length > 0)
                        prodItem.imgSrc = imgSrc;
                }
            }

            // Add the BOMItemProduct.
            addComponent(prodItem, chassisConsMap);

            totalQty += totalItemQty
        });
    }

    return [mainCat, totalQty];
}


const addChassis = (chassis: Chassis, mainHdr: BOMItem, redSecondary = false) => {

    // If/when we encounter a redundant secondary case,
    // make sure that our accy skip set is loaded.
    if (redSecondary) {
        if (!_redSecAccySkipsLoaded) {
            _loadRedSecAccySkips();
        }
    }

    // Define a map for considating the local Chassis' components.
    const chassisConsComps = new Map<string, BOMItemProduct>();

    // Get the name of the chassis, and add our
    // decorator to it if our call is for a
    // redundant secondary chassis.
    let chassisName = getChassisName(chassis);
    if (redSecondary) {
        chassisName += '(R)';
    }

    // Create the header for the chassis.
    const chassisHeader = createBOMHeader(BOMItemType.Header, chassisName, 0);

    // Add it to the main header for our organized BOM.
    mainHdr.children.push(chassisHeader);

    // Add the chassis itself.
    chassisHeader.quantity +=
        addDevice(chassis, chassisConsComps, redSecondary);

    // If we have a power supply, add it.
    if (chassis.ps) {
        chassisHeader.quantity +=
            addDevice(chassis.ps, chassisConsComps, redSecondary);
    }

    // If we have modules...
    if (chassis.modules) {
        // Then add each one we have.
        chassis.modules.forEach((module) => {
            if (module) {
                chassisHeader.quantity +=
                    addDevice(module, chassisConsComps, redSecondary);
            }
        });
    }

    // Call a platform specific function to add
    // any extra chassis items to the BOM.
    addChassisExtrasToBOM(chassis, chassisConsComps, chassisHeader, redSecondary, _IExtBOM);

    // Now take our chassis's considated map and turn it into
    // an array of children under the chassis header.
    const arrDevices = _convertBOMItemMapIntoArray(chassisConsComps);
    arrDevices.forEach(comp => {
        chassisHeader.children.push(comp);
    });
}

const updateBOMs = (rackGroup: RackGroup) => {
    // Clear our lists... 
    _organizedList.length = 0;
    _consolidatedList.length = 0;
    _consolidatedMap.clear();
    _totalProducts = 0;

    // Add the main header - Note: we can update the
    // 'mainHeader' object after it's added to the list.
    // NOTE: Even though the Organized and Consolidated BOMs
    // are arrays, we will add everything as CHILDREN of the
    // Main Header. This will leave flexibility for future
    // BOM modifications (i.e. the BOM arrays can have more
    // main header sections like Hardware/Network/etc...).
    const organizedMainHeader = createBOMHeader(BOMItemType.MainHeader, 'Configuration Overview', 0);
    _organizedList.push(organizedMainHeader);

    // For each rack we have...
    rackGroup.racks.forEach(rack => {

        // Add the associated chassis.
        addChassis(rack.chassis, organizedMainHeader);

        // Then, if the chassis is redundant...
        if (rack.chassis.redundant) {

            // Add it AGAIN, but this time with the
            // redSecondary argument set to true.
            addChassis(rack.chassis, organizedMainHeader, true);
        }
    });

    // Update the main header quantity. Note: _mainHeader
    // is currently the only item in the Organized BOM list.
    organizedMainHeader.quantity = _totalProducts;

    // Create a Main header for the consolidated list... Note:
    // we cannot use the same main header since it HAS CHILDREN
    // for the organized BOM. 
    const consolMainHeader = createBOMHeader(BOMItemType.MainHeader, 'Configuration Overview', 0);

    // Set the quantity to the total.
    consolMainHeader.quantity = _totalProducts;

    // Set the children of the consolidated header.
    consolMainHeader.children = _convertBOMItemMapIntoArray(_consolidatedMap);

    // Add the header to the list.
    _consolidatedList.push(consolMainHeader);

    _consolidatedMap.clear();
}

// TO DO:
// This is a temporary implementation used to provide
// some sort of BOM-related info, to be included in
// the 'package' that gets saved for a project. The
// actual format expected for BOM-related data has
// NOT yet been defined. When it is, this function
// will need to be modified/moved/replaced accordingly.
export const getBomInfoForProjectSave = (project: ChassisProject):
    [org: BOMItem[], consol: BOMItem[], total: number] => {

    updateBOMs(project.content);

    return [_organizedList, _consolidatedList, _totalProducts];
}

// Declare/Initialize our external interface. The
// interface can be passed to external functions to
// add additional BOM items outside of this file.
const _IExtBOM: IExternalBOM = {
    //// Objects - static/global
    consolidatedList: _consolidatedList,
    organizedList: _organizedList,

    //// Methods
    addDevice: addDevice,
    editComponent: editComponent,
    addComponent: addComponent,
    convertBOMItemMapIntoArray: _convertBOMItemMapIntoArray,
};
