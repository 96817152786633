import React, { useRef } from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { GraphicalDevice } from '../../types/ProjectTypes';
import { DocLinkGroupInfo, makePlatformDocLinkGrp } from '../../model/DeviceDetail';
import DocumentLinkGroup from './DocumentLinkGroup';


interface Props {
    device: GraphicalDevice;
}

const DetailsCommonDocTab = (props: Props) => {

    const platform = props.device.platform;
    const docGroup = useRef<DocLinkGroupInfo>(makePlatformDocLinkGrp(platform));

    if (docGroup.current.platform !== platform) {
        docGroup.current = makePlatformDocLinkGrp(platform)
    }

    return (
        <div className='floating-details-tab'>
            <DocumentLinkGroup group={docGroup.current} />
        </div>
    );
}

export default DetailsCommonDocTab;
