import React from 'react';
import './ExpandCollapseBtn.scss';
import chevronUpIcon from '../svg/chevron-up.svg';

const _dfltBtnSize = 20;

interface Props {
	expanded: boolean;
	changeExp: () => void;
	btnSize?: number;
}

const ExpandCollapseBtn = (props: Props) => {

	const buttonSize = props.btnSize ? props.btnSize : _dfltBtnSize;

	// 2023.10.16 The expand and collapse handler was
	// changed from 'onClick' to 'onPointerDown' and
	// we stop the event propagation to prevent the
	// parent component (currently only used by the
	// <ProjTreeChassisHdr>) from processing the Pointer
	// Down event. If we ever have a parent component that
	// needs to process the Pointer Down event, it should
	// do so in the Props:changeExp() handler.
	const onPointerDownHndlr = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		props.changeExp();
	}

	return (
		<button
			className="expand-collapse-button"
			onPointerDown={onPointerDownHndlr}
		>
			<img
				src={chevronUpIcon}
				width={buttonSize}
				className={props.expanded ?
					"expand-collapse-button-expanded" :
					"expand-collapse-button-collasped"} />
		</button>
	);
}

export default ExpandCollapseBtn;
