import React from 'react';
import { BOMHeader } from './BomHeader';
import { BOMProduct } from './BomItem';
import './Summary.scss';
import { BOMItem, BOMItemProduct, isBOMProduct } from './SummaryHelp';
import { Box } from '@mui/material';



interface Props {
    bom: BOMItem[];
    bomType?: string;
}

export const Bom = (props: Props) => {
    
    let nextKey = 1;
    return (
        <Box sx={{
            fontFamily:"Barlow"
        }}>
            {
                props.bom.map((entry) => {
                    if (isBOMProduct(entry)) {
                        return (
                            <BOMProduct
                                item={entry as BOMItemProduct}
                                key={nextKey++}
                                bomType = { props.bomType }
                            />
                        );
                    }
                    else {
                        return (
                            <BOMHeader
                                header={entry}
                                key={nextKey++}
                                bomType = { props.bomType }
                            />
                        );
                    }
				})
			}
        </Box>
    );

}
