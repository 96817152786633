import {
	ChassisProject,
	createNewLocAttributeInfo,
	LocAttrPersist,
	LocAttributeInfo,
    locAttrStartupDetails
} from "../types/ProjectTypes";
import { onGuidedSelLoadedCallback } from "../types/APITypes";
import { IOEntryModeEnum, SettingValue } from "../types/SettingsTypes";
import { refreshLocAttrInfoSelectionArray, updateGuidedSelection } from "./GuidedSelection";
import { getUserModuleSelectionsFromPointSectionInfo } from "./IOModule";
import { getFamilyFromPlatform } from "../platforms/PlatformIDs";

///////////// Added to manage Location instnaces outside of ChassisProject //////////////////////////

const initLocationName = (loc: LocAttributeInfo) => {
	// Start the new chassis name as the root
	const rootName = loc.name.replace('*Platform*', getFamilyFromPlatform(loc.platform));
	// Modify the new chassis name until unique.
	let namePostFix = 1;
	loc.name = `${rootName}_${String(namePostFix++).padStart(3, '0')}`;
	while (_LocAttr.some((lc) => lc.name === loc.name))
		loc.name = `${rootName}_${String(namePostFix++).padStart(3, '0')}`;
}

const _LocAttr: LocAttributeInfo[] = [];

export const getLocationAttrInfo = (locationID: string): LocAttributeInfo | undefined => {
	const loc = _LocAttr.find(x => x.id === locationID);
	return loc;
}

export const addLocationAttrInfo = (
	platform: string,
	industryID: string,
	installLocationID: string,
	ioMode: IOEntryModeEnum = IOEntryModeEnum.Basic): string =>
{
	const newLoc = createNewLocAttributeInfo(platform, industryID, installLocationID, ioMode);
	initLocationName(newLoc);
	_LocAttr.push(newLoc);
	return newLoc.id;
}

export const addLocAttrInfoFromStrtDtls = (strtDtls: locAttrStartupDetails): LocAttributeInfo => {
	// Do we have this location...
	const existing = getLocationAttrInfo(strtDtls.idLocAttr);
	if (existing)
		return existing;

	const newLoc = createNewLocAttributeInfo(strtDtls.platform, strtDtls.industryID, strtDtls.installLocID, IOEntryModeEnum.Basic);
	newLoc.id = strtDtls.idLocAttr;
	initLocationName(newLoc);
	_LocAttr.push(newLoc);
	return newLoc;
}

export const deleteLocationAttrInfo = (locID: string) => {
	const idxLoc = _LocAttr.findIndex(x => x.id === locID);
	if (idxLoc >= 0)
		_LocAttr.splice(idxLoc, 1);
}


export const updateLocAttrPersistData = (loc: LocAttributeInfo): LocAttrPersist => {
	refreshLocAttrInfoSelectionArray(loc);
	return {
		id: loc.id,
		platform: loc.platform,
		industryID: loc.industryID,
		installLocID: loc.installLocationID,
		ioEntryMode: loc.ioEntryMode,
		ioSelections: getUserModuleSelectionsFromPointSectionInfo(loc.pointEntrySection, loc.ioEntryMode),
		settings: [...loc.arrAttributeNameToValue ],
	};
}

export const prepProjectConfigForPersist = (project: ChassisProject) => {
	// Clear the configs LocAttr array. Note:
	// We cannot/'will not' store the actual
	// LocAttrInfo instances. Instead, we save
	// a LocAttr structure for each info.
	project.config.arrLocAttr = [];
	_LocAttr.forEach((loc) => {
		const locAttrPersist = updateLocAttrPersistData(loc);
		project.config.arrLocAttr.push(locAttrPersist)
	});
}

export const updateLocAttrInfo = (locID: string, callback: onGuidedSelLoadedCallback) => {
	const loc = getLocationAttrInfo(locID);
	if (loc == null) {
		callback(false, undefined);
		return;
	}

	updateGuidedSelection(loc, callback);
}

export const getAvailableLocAttrInfoIDs = (): string[] => {
	const arrIDs: string[] = [];
	_LocAttr.forEach((loc) => {
		arrIDs.push(loc.id);
	});

	return arrIDs;
}

export const getExistingLocAttrInfoForPlatform = (platform: string): LocAttributeInfo[] => {
	const arrLocs: LocAttributeInfo[] = [];
	_LocAttr.forEach((loc) => {
		if (loc.platform === platform)
			arrLocs.push(loc);
	});

	return arrLocs;
}

export const setAttrsFromSelectionArray = (arrSelections: SettingValue[], loc: LocAttributeInfo) => {
	loc.attrGroups.forEach((grp) => {
		grp.settings.forEach((setting) => {
			// Do we have a selection for this setting...
			const sel = arrSelections.find((x) => x.attrID === setting.id);
			if (sel && setting.gsMapOptionTxtValToInfo) {
				// The options array in the setting may
				// or may not have ALL of the options
				// depending on if there was a validation
				// done on the location. Look for the
				// Option ID in the gsMap...
				setting.gsMapOptionTxtValToInfo.forEach((entryVal) => {
					if (entryVal.id === sel.optionID)
						setting.selectedOption = { id: entryVal.id, display: entryVal.txtValue };
				});
			}
		});
	});
}