import { convertGuidedSelAttrToAppVal } from "../../implementation/ImplHardwareGen";
import { getEnvRatingFromSpec, getModuleSlotBreakdown } from "../../model/ChassisProject";
import { BaseChoiceInfo, ChoiceGroupTipInfo, ChoiceGroupType, ChoiceInfoType, ChoicesGroup, GroupChoiceInfo, GuidedSelChoiceInfo, MessageCategory, MessageType, PanelMessage, StatusLevel } from "../../types/MessageTypes";
import { Chassis, ChassisProject, EnvRating, LocAttributeInfo, ResultStatus, SelectableDevice, MicroChassis } from "../../types/ProjectTypes";
import { SettingValue } from "../../types/SettingsTypes";
import { Point } from "../../types/SizeAndPosTypes";
import { getGroupChoiceMatch, resetChoiceGroupTipInfo, setChoiceGroupTipInfo } from "../../util/ChoicesHelp";
import { getEngInfoForComp, getProductAlternateFor } from "../../util/EngInfoHelp";
import { isNewStatusWorse, makeBold } from "../../util/GeneralHelpers";
import { logger } from "../../util/Logger";
import { snapGetChassisCatalogFromEnvRating, snapGetChassisCfgDataFromLocAttr } from "../snap/snapGuidedSelection";
import { clxGetCLXChassisCfgDataFromLocAttr } from "../clx/model/CLXGuidedSelection";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA } from "../PlatformConstants";
import { cfgDlgAddPowerMessage } from "./ChassisConfigPower";
import { replaceChassis } from "./ChassisConfigReplaceChassis";
import { cfgDlgAddWiringChangeMsg } from "./ChassisConfigWiring";


//////////////////// CHASSIS REPLACEMENT RELATED /////////////////////////////

export const chassisCfgMsgIndent = '       ';

export const getModulesInUseBy = (chassis: Chassis): Set<string> => {
    const modsInUse = new Set<string>();

    const [modSlots,] = getModuleSlotBreakdown(chassis.modules);
    for (let i = 0; i < modSlots.length; i++) {
        const mod = chassis.modules[modSlots[i]];
        if (mod) {
            modsInUse.add(mod.catNo);
        }
        else {
            throw new Error('Unexpected Error in getModulesInUseBy');
        }
    }
    return modsInUse;
}


export const getModuleSwapDetails = (
    platform: string,
    existingModCatNos: Set<string>,
    needCC: boolean,
    needET: boolean,
    modSwapMap: Map<string, string>,
    noMatchSet: Set<string>
) => {

    // Sanity check. Caller-provided swap map
    // and no-match set expected to be empty.
    if ((modSwapMap.size > 0) || (noMatchSet.size > 0)) {
        throw new Error('Invalid call to getModuleSwapDetails');
    }

    // See how many cat numbers were provided.
    const numMods = existingModCatNos.size;

    // If we have any...
    if (numMods > 0) {

        // Get the set as an array for easier iteration.
        // Remember that since these came in in a set,
        // we should NOT ever have any duplicates.
        const existingCats = Array.from(existingModCatNos.values());

        // For each catno provided...
        for (let i = 0; i < numMods; i++) {
            // Get the catno.
            const existingCat = existingCats[i];

            // Call a helper to find us an alternate product
            // that meets our required spec. If the existing
            // catalog number ALREADY meets the spec, we'll just
            // get the SAME catalog number back.
            //const alternate = getAlternateProductFor(existingCat, needXT, needConformal);
            const alternate = getProductAlternateFor(platform, existingCat, needCC, needET);

            // If we get something back...
            if (alternate && alternate.length) {

                // Then check to see if it's the SAME as what
                // we passed in. If NOT, add the pair to our
                // swap map.
                if (alternate !== existingCat) {
                    modSwapMap.set(existingCat, alternate);
                }
            }
            else {
                // No alternate was available. Add
                // the cat to the no-match set.
                noMatchSet.add(existingCat);
            }
        }
    }
}

export const getRatingTextFor = (platform: string, catNo: string): string => {
    const engInfo = getEngInfoForComp(platform, catNo);
    if (engInfo) {
        return engInfo.envInfo.rating.toString();
    }

    return '<rating info not available>';
}


export const makePanelMsg = (
    text: string,
    level: StatusLevel,
    category: MessageCategory,
    type: MessageType
): PanelMessage => {
    return {
        text: text,
        level: level,
        category: category,
        type: type
    };
}

export const getChassisReplaceMsgs = (
    platform: string,
    movesReqd: boolean,
    swapMap: Map<string, string>,
    noSwaps: Set<string>
): [level: StatusLevel, messages: PanelMessage[]] => {

    const messages = new Array<PanelMessage>();
    let msgLevel: StatusLevel = StatusLevel.Info;

    if (noSwaps.size > 0) {
        const single = (noSwaps.size === 1);
        let msg = single
            ? 'A suitable counterpart'
            : 'Suitable counterparts';
        msg += ' matching the chassis rating';
        msg += single ? ' is ' : ' are ';
        msg += makeBold('NOT') + ' available for the following module';
        msg += single ? '.' : 's.';
        msg += '\n';

        noSwaps.forEach(cat => {
            msg += chassisCfgMsgIndent + makeBold(cat) + '  (' + getRatingTextFor(platform, cat) + ')\n';
        });

        msg += '\n' + makeBold('WARNING') + ': The effective rating of a chassis is ' +
            'determined to be the ' + makeBold('LOWEST RATING') + ' common to all of the ' +
            'chassis itself, its power supply, and any installed modules.';
        messages.push(makePanelMsg(msg, StatusLevel.Warning, MessageCategory.Environment, MessageType.Situational));
        msgLevel = StatusLevel.Warning;
    }

    if (swapMap.size > 0) {
        const single = (noSwaps.size === 1);
        let msg = 'The following existing module';
        if (!single) {
            msg += 's';
        }
        msg += ' will be replaced with ';
        msg += single ? 'a suitable counterpart' : 'suitable counterparts';
        msg += ' to match the new chassis rating:\n';
        swapMap.forEach((newMod, oldMod) => {
            msg += chassisCfgMsgIndent + makeBold(oldMod) + '   (replaced with ' + makeBold(newMod) + ')\n';
        });
        messages.push(makePanelMsg(msg, StatusLevel.Info, MessageCategory.Environment, MessageType.Situational));
    }

    if (movesReqd) {
        const msg = 'The selected chassis has fewer slots than the chassis being ' +
            'replaced. Some modules will need to be relocated, but the ' +
            'relative order of the existing modules will be retained.';
        messages.push(makePanelMsg(msg, StatusLevel.Info, MessageCategory.Size, MessageType.Situational));
    }


    return [msgLevel, messages];
}

//////////////////// CHASSIS CONFIG RELATED /////////////////////////////

export const CfgDlgTabID = {
    Chassis: 1,
    PowerSupply: 2,
    Messages: 3
};

export interface InitCfgData {
    project: ChassisProject,
    selectChassisCallback: (chassis: Chassis | undefined) => void,
    selectDeviceCallback: (device: SelectableDevice | undefined) => void,
    contentChangedCallback: () => void,
    chassis?: Chassis
}

export const cfgDlgUnspecifiedChassisName = 'Unnamed_Chassis';

export interface CfgDlgMsgDtls {
    level: StatusLevel;
    msgs: PanelMessage[];
}

export interface CfgDlgGSPanelMessage {
    tipInfo: ChoiceGroupTipInfo;
    msgDetails: CfgDlgMsgDtls;
}

// Helper - returns an enumerated environment type for a chassis.
export const getChassisEnvType = (chassis: Chassis): EnvRating => {
    return getEnvRatingFromSpec(chassis.extendedTemp, chassis.conformal);
}


export interface ConfigChassisCBData {
    project: ChassisProject;
    // Note: cfgAttrInfo is NOT the same as the Location
    // Settings in the Project! It is either a modified
    // clone or default.
    cfgAttrInfo: LocAttributeInfo;

    // Calbacks to clear or set selected devices.
    selectDeviceCallback: (device: SelectableDevice | undefined) => void,
    selectChassisCallback: (chassis: Chassis | undefined) => void;
    contentChangedCallback: () => void;

    // Edit chassis related.
    chassis?: MicroChassis;
    origTotalSlotsInUse: number;
    origSlotFillers: number;
    origQtyRMMods: number;
    origCat: string;
    origPSCat: string;
    chassisName: string;

    psGrp: GroupChoiceInfo;
    additionalGrps: GroupChoiceInfo[];

    allPossibleWiringTypes: number;
    mapCatToWiringSpt?: Map<string, number>;

    msgLevel: StatusLevel;
    panelMessages: PanelMessage[];
    gsPanelMessage: PanelMessage[];

    initialGSSelections: SettingValue[];

    ChassisPage: BaseChoiceInfo[];
    PowerPage: BaseChoiceInfo[];
}

export const cfgDlgGetGroupSelection = (groupInfo: GroupChoiceInfo): string => {
    if (groupInfo.group.type === ChoiceGroupType.RequireOneOf) {
        if (groupInfo.selections.length === 1) {
            return groupInfo.selections[0];
        }
    }

    // 2024.2.26 Can have groups without ANY choices. Return
    // empty string instead of tossing an error.
    //throw new Error('Unexpected ERROR in getGroupSelection');
    return '';
}

// Helper. ALL of our groups for chassis configuration
// are of type RequireOneOf. After sanity-checking that
// the incoming group actually IS of that type and has
// exactly one entry in its selections array, like it
// ALWAYS should, returns the single entry.
export const cfgDlgIsGroupSelectionValid = (grp: ChoicesGroup, sel: string): boolean => {

    const matchingChoice = getGroupChoiceMatch(grp, sel);
    return ((matchingChoice !== undefined) &&
        (matchingChoice.disabled !== true));
}


export const getCfgDlgInfoMsgFor = (category: MessageCategory): PanelMessage => {

    let msgText = '';

    switch (category) {
        case MessageCategory.Environment:
            msgText = 'Environmental Rating will set all items ' +
                'on the chassis to be meet the requested rating.\n\n' +
                '* Environmental Rating may be limited based upon ' +
                'the number of modules you currently have on your ' +
                'chassis. Extended Temperature has limited chassis sizes.';
            break;

        case MessageCategory.Size:
            msgText = 'ControlLogix is a fixed chassis size platform. ' +
                'Chassis Size denotes how many modules can be ' +
                'placed on the chassis\n\n' +
                '* Chassis Size is limited based upon the number ' +
                'of modules currently in the chassis.';
            break;

        case MessageCategory.Wiring:
            msgText = 'Some Modules require a separate Terminal Base or ' +
                'Cable to wire end devices/power supplies. These wiring ' +
                'terminals will automatically be included as accessories ' +
                'on the module in you configuration.';
            break;

        case MessageCategory.HighAvail:
            msgText = 'ControlLogix Controllers can be used as a ' +
                'High-Availability system to provide automatic ' +
                'Controller switchover in event of a primary ' +
                'Controller failure. This will add an additional ' +
                'Redundancy Module to your system.\n\n' +
                '* High Availability requires the addition of a ' +
                'redundancy module. If the chassis is full, you will ' +
                'not be able to select High Availability. Please be ' +
                'aware that Non-Redundant modules (I/O, Safety ' +
                'Controllers, etc.) will be flagged as an error ' +
                'if included in a chassis with a Redundancy module.';
            break;

        case MessageCategory.Voltage:
            msgText = 'Specifies the input voltage for the Power Supply ' +
                'that powers the Chassis and all modules. If you ' +
                'require a different input voltage, you may need ' +
                'to use a power supply or transformer in conjunction ' +
                'with the in-chassis Power Supply.\n\n' +
                '* Input voltage may be limited based upon Environmental ' +
                'Ratings as not all voltages are supported on Conformal ' +
                'Coated or Extended Temperature Chassis.';
            break;

        case MessageCategory.Power:
            msgText = 'Specifies the size and capability of the ' +
                'in-chassis Power Supply. Certain voltages have ' +
                'the option to include redundant power supplies ' +
                'for better resilience against power failures.';
            break;

        case MessageCategory.InterconnectCbl:
            msgText = 'The Flex 5000 interconnect cable can be used to ' +
                'split the chassis into 2 separate banks of modules to ' +
                'allow for different layout configurations.Each chassis ' +
                'supports a single interconnect cable that provides ' +
                'system-side power to modules on the 2nd bank.';
            break;

        default:
            msgText = 'Unexpected category: ' + category;
            break;
    }
    return {
        level: StatusLevel.NA,
        category: category,
        type: MessageType.GeneralInfo,
        text: msgText
    }
}


////////////////////// TIPS and MESSAGES ///////////////////////////////////////////////////////////////////

export type CfgDlgMapMsgInfo = Map<MessageCategory, CfgDlgMsgDtls>;
export type CfgDlgTipCallback = (cat: MessageCategory, type: MessageType, pt: Point) => void;

export const distributeMessages = (msgInfo: CfgDlgMapMsgInfo, messages: PanelMessage[]) => {

    // Start by clearing any previous content
    // from our 'by-category' map.
    msgInfo.clear();

    // See how many panel messages we have. There's
    // nothing to do if we don't have any. 
    const numMsgs = messages.length;

    // If any...
    if (numMsgs > 0) {

        // Walk the messages. For each...
        for (let idx = 0; idx < numMsgs; idx++) {

            // Get the message.
            const msg = messages[idx];

            // ALL messages in the collection SHOULD be
            // our 'situational' type. If this one is...
            if (msg.type === MessageType.Situational) {

                // See if our map already has an entry
                // for the message's category. If so...
                if (msgInfo.has(msg.category)) {

                    // Get the entry.
                    const dtls = msgInfo.get(msg.category);

                    // If we can...
                    if (dtls) {
                        // Compare the new message's status level
                        // to what we've got so far. Use the new
                        // level if 'worse' (ex: was info, now error)
                        if (isNewStatusWorse(msg.level, dtls.level)) {
                            dtls.level = msg.level;
                        }

                        // Add the new msg to the entry.
                        dtls.msgs.push(msg);
                    }
                    else {
                        // Unexpected error.
                        throw new Error('Missing entry in _situationalMsgInfo map!');
                    }
                }
                else {
                    // No entry yet.
                    // Create a new dtls entry containing the
                    // message, and set the level accordingly.
                    const newDtls: CfgDlgMsgDtls = {
                        level: msg.level,
                        msgs: [msg]
                    }

                    // Add the new entry to the map
                    msgInfo.set(msg.category, newDtls);
                }
            }
            else {
                // Wrong type. Log it and skip this one.
                logger.error('Unexpected message type in distributeMessages');
            }
        }
    }
}

export const cfgDlgUpdateMessages = (data: ConfigChassisCBData, msgInfo: CfgDlgMapMsgInfo, tipCallback: CfgDlgTipCallback) => {
    switch (data.cfgAttrInfo.platform) {
        case PlatformCLX:
            clxUpdateMessages(data, msgInfo, tipCallback);
            break;
        case PlatformCpLX:
        case PlatformFlex:
            snapUpdateMessages(data, msgInfo, tipCallback);
            break;

        case PlatformFlexHA: // TODO_FLEXHA
        default:
            break;
    }

    return;
}

const snapUpdateMessages = (data: ConfigChassisCBData, msgInfo: CfgDlgMapMsgInfo, tipCallback: CfgDlgTipCallback) => {

    // Clear the panel messages, but NOT the 
    // Guided Selection Messages
    data.panelMessages.length = 0;

    // We'll only have messages when we're EDITING
    // the configuration of an existing chassis that
    // actually has any modules in it. If so...
    // 2024.2.24 We only have Wiring/Chassis msgs when
    // we have a chassis. Power messages can happen even
    // when adding a new chassis.
    if (data.chassis && data.chassis.modules.length > 0) {
        // Get our config data from the AttrInfo.
        const gsData = snapGetChassisCfgDataFromLocAttr(data.cfgAttrInfo);

        // Make sure we have what we need. For CpLX, there
        // is not much. Determine the Chassis Catalog.
        const chassisCatalog = snapGetChassisCatalogFromEnvRating(data.cfgAttrInfo.platform, gsData.envType);

        // Run the replace function in testOnly mode.
        const [rsltStatus, msgLevel, msgs] =
            replaceChassis(data.chassis, chassisCatalog, '', true);

        if (rsltStatus === ResultStatus.TestOnlyMsg) {
            data.msgLevel = msgLevel;
            data.panelMessages = msgs;
        }
        else {
            alert('Got unexpected ResultStatus updating message: ' + rsltStatus);
        }

        if (gsData.wiringType != data.chassis.defaultIOModWiring)
            cfgDlgAddWiringChangeMsg(gsData.wiringType, data);
    }

    if (data.panelMessages || data.gsPanelMessage) {
        // Combine the Guided Sel messages with the 'regular' ones.
        const arrMsg: PanelMessage[] = [];
        arrMsg.push(...data.panelMessages);
        arrMsg.push(...data.gsPanelMessage);

        // Call a helper to separate any messages
        // we have by category.
        distributeMessages(msgInfo, arrMsg);

        // Then another to update the tip info
        // on each of our groups.
        updateSituationalTipInfo(msgInfo, tipCallback, data);
    }
}

const clxUpdateMessages = (data: ConfigChassisCBData, msgInfo: CfgDlgMapMsgInfo, tipCallback: CfgDlgTipCallback) => {

    // Get our PSU from the PSU Grp. If we do
    // not get it (we should), use the PSU from
    // the Product Selection.
    const selectedPSU = cfgDlgGetGroupSelection(data.psGrp);

    // Clear the panel messages, but NOT the 
    // Guided Selection Messages
    data.panelMessages.length = 0;

    // We'll only have messages when we're EDITING
    // the configuration of an existing chassis that
    // actually has any modules in it. If so...
    // 2024.2.24 We only have Wiring/Chassis msgs when
    // we have a chassis. Power messages can happen even
    // when adding a new chassis.
    if (data.chassis && (cfgDlgGetMinSlotsNeeded(data) > 0)) {
        // Get our config data from the AttrInfo.
        const gsData = clxGetCLXChassisCfgDataFromLocAttr(data.cfgAttrInfo);

        // Make sure we have what we need. 
        if (selectedPSU) {
            // There are cases when there is an error in Guided
            // Selection and we do NOT have a chassis catalog.
            // No chassis cat equals a choke on replaceCLXChassis().
            if (gsData.chassisCatalog) {
                // Run the replace function in testOnly mode.
                const [rsltStatus, msgLevel, msgs] =
                    replaceChassis(data.chassis, gsData.chassisCatalog, selectedPSU, true);

                if (rsltStatus === ResultStatus.TestOnlyMsg) {
                    data.msgLevel = msgLevel;
                    data.panelMessages = msgs;
                }
                else {
                    alert('Got unexpected ResultStatus updating message: ' + rsltStatus);
                }
            }
        }

        if (gsData.wiringType != data.chassis.defaultIOModWiring)
            cfgDlgAddWiringChangeMsg(gsData.wiringType, data);
    }

    // Add the Pwr Messages.
    cfgDlgAddPowerMessage(PlatformCLX, data, selectedPSU);

    if (data.panelMessages || data.gsPanelMessage) {
        // Combine the Guided Sel messages with the 'regular' ones.
        const arrMsg: PanelMessage[] = [];
        arrMsg.push(...data.panelMessages);
        arrMsg.push(...data.gsPanelMessage);

        // Call a helper to separate any messages
        // we have by category.
        distributeMessages(msgInfo, arrMsg);

        // Then another to update the tip info
        // on each of our groups.
        updateSituationalTipInfo(msgInfo, tipCallback, data);
    }
}

const _updateGSSitTipInfoFor = (msgInfo: CfgDlgMapMsgInfo, tipCallback: CfgDlgTipCallback, category: MessageCategory, gsInfo: GuidedSelChoiceInfo) => {
    // If our map has an entry for the requested category...
    if (msgInfo.has(category)) {

        // Get it.
        const dtls = msgInfo.get(category);

        // If we can...
        if (dtls) {
            let state = StatusLevel.Info;
            dtls.msgs.forEach((msg) => {
                if (isNewStatusWorse(msg.level, state))
                    state = msg.level;
            });

            // Set the group's tip info accordingly.
            const info: ChoiceGroupTipInfo = {
                level: state,
                category: category,
                type: MessageType.Situational,
                callback: tipCallback,
            }

            gsInfo.subGroup.situationalTipInfo = info;
        }
    }
    else {
        gsInfo.subGroup.situationalTipInfo = undefined;
    }
}

const _updateSitTipInfoFor = (msgInfo: CfgDlgMapMsgInfo, tipCallback: CfgDlgTipCallback, category: MessageCategory, grpInfo: GroupChoiceInfo) => {

    // If our map has an entry for the requested category...
    if (msgInfo.has(category)) {

        // Get it.
        const dtls = msgInfo.get(category);

        // If we can...
        if (dtls) {
            // Set the group's tip info accordingly.
            setChoiceGroupTipInfo(grpInfo.group, dtls.level, category,
                MessageType.Situational, tipCallback);
        }
        else {
            // Unexpected error.
            throw new Error('Missing entry in _updateSitTipInfoFor map!');
        }
    }
    else {
        // No entry, which means this category has NO
        // situational messages applicable. In that case,
        // we want to RESET the group's tip info.
        resetChoiceGroupTipInfo(grpInfo.group, MessageType.Situational);
    }
}

export const updateSituationalTipInfo = (msgInfo: CfgDlgMapMsgInfo, tipCallback: CfgDlgTipCallback, data: ConfigChassisCBData) => {
    // Update the wiring group. This is a special
    // case for the GS Groups.
    data.ChassisPage.forEach((x) => {
        if (x.infoType === ChoiceInfoType.GuideSel) {
            const gsInfo = x as GuidedSelChoiceInfo;
            _updateGSSitTipInfoFor(msgInfo, tipCallback, gsInfo.setting.id as MessageCategory, gsInfo);
        }
    });

    data.PowerPage.forEach((x) => {
        if (x.infoType === ChoiceInfoType.GuideSel) {
            const gsInfo = x as GuidedSelChoiceInfo;
            _updateGSSitTipInfoFor(msgInfo, tipCallback, gsInfo.setting.id as MessageCategory, gsInfo);
        }
    });

    // Update the 'normal' Power group. 
    _updateSitTipInfoFor(msgInfo, tipCallback, MessageCategory.Power, data.psGrp);
}

export const createPanelMessageForGS = (
    data: ConfigChassisCBData,
    tipCallback: CfgDlgTipCallback,
    msgText: string,
    state: StatusLevel,
    msgCategory: MessageCategory): CfgDlgGSPanelMessage =>
{
    // Is the new state worse than what it was...
    if (isNewStatusWorse(state, data.msgLevel))
        data.msgLevel = state;

    const sitTip: ChoiceGroupTipInfo = {
        level: state,
        category: msgCategory,
        type: MessageType.Situational,
        callback: tipCallback,
    }

    const pnlMessage: PanelMessage = {
        category: msgCategory,
        level: state,
        text: msgText,
        type: MessageType.Situational
    };

    const msg: CfgDlgMsgDtls = {
        level: state,
        msgs: [pnlMessage],
    };

    return {
        tipInfo: sitTip,
        msgDetails: msg
    };
}

export const loadTipPanelMsgs = (infoMsgs: PanelMessage[], msgInfo: CfgDlgMapMsgInfo, category: MessageCategory, type: MessageType) => {
    infoMsgs.length = 0;

    // Check the type requested.
    switch (type) {

        // For general info...
        case MessageType.GeneralInfo:
            // Get and add the standard info
            // message applicable to the category.
            infoMsgs.push(getCfgDlgInfoMsgFor(category));
            break;

        // For situational...
        case MessageType.Situational:

            // Since we were called, we would EXPECT our
            // situational msg map to have an entry for
            // the category requested. If so...
            if (msgInfo.has(category)) {

                // Get the entry.
                const dtls = msgInfo.get(category);

                // If we can...
                if (dtls) {
                    // Copy its messages over to infoMsgs.
                    infoMsgs.push(...dtls.msgs);
                }
                else {
                    // Unexpected.
                    throw new Error('Missing map entry in loadTipPanelMsgs');
                }
            }
            else {
                logger.error('loadTipPanelMsgs found no map entry for: ' + category);
            }
            break;

        default:
            logger.error('Unexpected msg type in loadTipPanelMsgs!');
    }
}

export const cfgDlgIsHighAvailSelected = (data: ConfigChassisCBData): boolean => {
    if (data.cfgAttrInfo.platform !== PlatformCLX)
        return false;

    const ca = data.cfgAttrInfo.arrAttributeNameToValue.find(x => x.attrID === 'CA');
    if (ca)
        return convertGuidedSelAttrToAppVal(data.cfgAttrInfo.platform, ca.attrID, ca.optionID, true) as boolean;

    throw new Error('isHighAvailSelected(): Location Attributes does not have CA - Controller Availability.')
}


export const cfgDlgGetMinSlotsNeeded = (data: ConfigChassisCBData): number => {
    if (data.chassis == null)
        return 0;

    if (data.cfgAttrInfo.platform === PlatformCLX) {
        // Get the current selection from the high-avail group.
        const highAvailSelected = cfgDlgIsHighAvailSelected(data);

        // Determine the number of slots in use,
        // NOT including any RM module(s) and NOT
        // including slot fillers.
        const nonRMNonFillerSlotsInUse = data.origTotalSlotsInUse -
            data.origSlotFillers - data.origQtyRMMods;

        // If high-avail is True, we need 1 more than our
        // qty of NON-RM slots. If not, we just need that
        // qty (of NON-RM slots in use).
        return (highAvailSelected ? nonRMNonFillerSlotsInUse + 1 : nonRMNonFillerSlotsInUse);
    }
    else {
        // We have a snap chassis. Just return the chassis'
        // module array length.
        return data.chassis.modules.length;
    }
}
