import { DeviceType } from "../types/ProjectTypes";
import { getIntValFromString, isTrueStringValue } from "../util/DataValueHelp";
import { logger } from "../util/Logger";
import { IEngDataModule } from "./EngineeringInfo";


const _isAdvancedCtlr = (catNo: string) => {
    if (catNo.startsWith('1756-L')) {
        return catNo.startsWith('1756-L8');
    }
    if (catNo.startsWith('5069-L')) {
        return true;
    }
    // For micro 800 family no need for advance 
    if (catNo.startsWith('2080-L')) {
        return false;
    }
    throw new Error('ERROR: Unexpected call to _isAdvancedCtlr!');
}

export interface IControllerRoleData extends IEngDataModule {
    Proc_CIPMotion: string;
    Proc_Safety: string;
    Memory_User_Avail: string;
    CIP_Avail: string;
    EIP_Avail: string;
    CM_NonPosAxis_Avail: string;
    CM_PosAxis_Avail: string;
    CM_VirtAxis_Avail: string;
    CM_TotalAxis_Avail: string;
}


export interface ControllerRole {
    isAdvanced: boolean;
    memory: number;
    supportsCIPMotion: boolean;
    isSafetyController: boolean;
    maxCIPConns: number;
    enetNodeType: boolean;
    maxEnetNodes: number;
    maxPosAxes: number;
    maxNonPosAxes: number;
    maxVirtAxes: number;
    maxTotalAxes: number;
}

export const establishControllerRole = (data: IEngDataModule): ControllerRole | undefined => {
    const roleData = data as IControllerRoleData;

    if (roleData.Type === DeviceType.Controller) {

        const maxEnetNodes = getIntValFromString(roleData.EIP_Avail);

        const role: ControllerRole = {
            isAdvanced: _isAdvancedCtlr(roleData.CatalogNumber),
            memory: getIntValFromString(roleData.Memory_User_Avail),
            supportsCIPMotion: isTrueStringValue(roleData.Proc_CIPMotion),
            isSafetyController: isTrueStringValue(roleData.Proc_Safety),
            maxCIPConns: getIntValFromString(roleData.CIP_Avail),
            enetNodeType: (maxEnetNodes > 0),
            maxEnetNodes: maxEnetNodes,
            maxPosAxes: getIntValFromString(roleData.CM_PosAxis_Avail),
            maxNonPosAxes: getIntValFromString(roleData.CM_NonPosAxis_Avail),
            maxVirtAxes: getIntValFromString(roleData.CM_VirtAxis_Avail),
            maxTotalAxes: getIntValFromString(roleData.CM_TotalAxis_Avail)
        }
        return role;
    }
    else {
        logger.error('Attempt to establishControllerRole for non-Controller device.');
    }
}