import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import StandardMenuItem, { StandardMenuItemDetail } from './StandardMenuItem';


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 4,
        marginTop: '4px',
        minWidth: 180,
        color: '#000000',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));



interface Props {
    menuItems: StandardMenuItemDetail[];
    isOpen: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onTBAction: (action: string) => void;
}

const MenuForButtons = (props: Props) => {


    const onClickMenuItem = (id: string) => {
        props.onClose();
        props.onTBAction(id);
    }

    let nextKey = 1;

    return (
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={props.anchorEl}
            open={props.isOpen}
            onClose={props.onClose}
        >
            {props.menuItems.map(menuItem => {
                return (
                    <StandardMenuItem
                        key={nextKey++}
                        detail={menuItem}
                        onClickItem={onClickMenuItem}
                    />
                );
            })}
        </StyledMenu>
    );

}

export default MenuForButtons;

