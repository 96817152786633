import { EngInfoComponent } from "../engData/EngineeringInfo";
import {
    canExtendChassis,
    deleteBaseUnitAtChassis,
    doesSlotQualifyForBtnAction,
    getActBtnInfo,
    getModuleSlotRestriction,
    hasGetActBtnInfo
} from "../implementation/ImplGeneral";
import { getRack } from "../model/ChassisProject";
import { PlatformFlexHA } from "../platforms/PlatformConstants";
import { ActBtnInfo, CopyModeCompat, LayoutActionType } from "../types/LayoutActions";
import { Chassis, ModuleSlotRestriction, Rack, RackGroup } from "../types/ProjectTypes";
import { IdxRange } from "../types/SizeAndPosTypes";
import { logger } from "./Logger";


export enum ChassisItemRenderType {
    Normal = 'Normal',
    Fade = 'Fade',
    Pop = 'Pop',
    Skip = 'Skip'
}

export interface ChassisItemsRenderSpec {
    ps: ChassisItemRenderType;
    bu: ChassisItemRenderType;
    modules: ChassisItemRenderType;
    slotFillers: ChassisItemRenderType;
    emptySlots: ChassisItemRenderType;
    slotSeps: ChassisItemRenderType;
    slotGrpSeps: ChassisItemRenderType;
    rightCap: ChassisItemRenderType;
    ioBases: ChassisItemRenderType;
    backplate: ChassisItemRenderType;
    ioTermBlks: ChassisItemRenderType;
    jumper: ChassisItemRenderType;
}

export enum LayoutModeType {
    Normal = 'Normal',
    Copy = 'Copy',
    Delete = 'Delete',
    Drag = 'Drag'
}

export interface LayoutMode {
    type: LayoutModeType;

    // Used for copy and drag modes only
    origCat: string;
    origCC: boolean; // conformal
    origET: boolean; // extended temp
    engInfo: EngInfoComponent | undefined;
}

export const getModeType = (layoutMode: LayoutMode | undefined): LayoutModeType => {
    if (layoutMode) {
        return layoutMode.type;
    }
    return LayoutModeType.Normal;
}

const _getNormalChassisRenderSpec = (): ChassisItemsRenderSpec => {
    return {
        ps: ChassisItemRenderType.Normal,
        bu: ChassisItemRenderType.Normal,
        modules: ChassisItemRenderType.Normal,
        slotFillers: ChassisItemRenderType.Normal,
        emptySlots: ChassisItemRenderType.Normal,
        slotSeps: ChassisItemRenderType.Normal,
        slotGrpSeps: ChassisItemRenderType.Normal,
        rightCap: ChassisItemRenderType.Normal,
        ioBases: ChassisItemRenderType.Normal,
        backplate: ChassisItemRenderType.Normal,
        ioTermBlks: ChassisItemRenderType.Normal,
        jumper: ChassisItemRenderType.Normal
    }
}

const _getFadedChassisRenderSpec = (): ChassisItemsRenderSpec => {
    return {
        ps: ChassisItemRenderType.Fade,
        bu: ChassisItemRenderType.Fade,
        modules: ChassisItemRenderType.Fade,
        slotFillers: ChassisItemRenderType.Fade,
        emptySlots: ChassisItemRenderType.Fade,
        slotSeps: ChassisItemRenderType.Fade,
        slotGrpSeps: ChassisItemRenderType.Fade,
        rightCap: ChassisItemRenderType.Fade,
        ioBases: ChassisItemRenderType.Fade,
        backplate: ChassisItemRenderType.Fade,
        ioTermBlks: ChassisItemRenderType.Fade,
        jumper: ChassisItemRenderType.Fade
    }
}

export const getChassisRenderSpec = (
    mode: LayoutMode | undefined,
    chassis: Chassis,
    primaryChassis: boolean,
    showSelected: boolean
)
    : ChassisItemsRenderSpec => {

    const spec = _getNormalChassisRenderSpec();

    const modeType = getModeType(mode);

    // Show as normal if we're either:
    //   - in normal mode, OR 
    //   - we're in Drag mode AND our chassis
    //     is a potential drag target.
    const showNormal = ((modeType === LayoutModeType.Normal) ||
        ((modeType === LayoutModeType.Drag) && chassis.dragTarget));

    if (showNormal) {
        if (showSelected) {
            spec.slotFillers = ChassisItemRenderType.Fade;
            //spec.ioBases = ChassisItemRenderType.Fade;
        }
        return spec;
    }
    else if (modeType === LayoutModeType.Drag) {
        // If here and in Drag mode, it means our
        // chassis is NOT a potential drag target.
        return _getFadedChassisRenderSpec();
    }

    spec.ps = ChassisItemRenderType.Fade;
    spec.bu = ChassisItemRenderType.Fade;
    spec.slotSeps = ChassisItemRenderType.Fade;
    spec.slotGrpSeps = ChassisItemRenderType.Fade;
    spec.rightCap = ChassisItemRenderType.Fade;
    spec.ioTermBlks = ChassisItemRenderType.Fade;
    spec.jumper = ChassisItemRenderType.Fade;

    if (primaryChassis) {
        if (modeType === LayoutModeType.Copy) {
            spec.modules = ChassisItemRenderType.Fade;
            spec.slotFillers = ChassisItemRenderType.Fade;
            spec.emptySlots = ChassisItemRenderType.Pop;
            spec.ioBases = ChassisItemRenderType.Pop;
        }
        else {
            spec.modules = ChassisItemRenderType.Pop;
            spec.slotFillers = ChassisItemRenderType.Pop;
            spec.emptySlots = ChassisItemRenderType.Fade;
            spec.ioBases = ChassisItemRenderType.Fade;
        }
    }
    else {
        spec.modules = ChassisItemRenderType.Fade;
        spec.emptySlots = ChassisItemRenderType.Fade;
        spec.ioBases = ChassisItemRenderType.Fade;
        spec.backplate = ChassisItemRenderType.Fade;
    }

    return spec;
}


const _finalizeCopyBtnInfo = (mode: LayoutMode, btnInfo: ActBtnInfo) => {
    // Sanity checks. The mode type should be Copy,
    // and we should have an original catalog number
    // AND product data for that original. If so...
    if (mode && (mode.type === LayoutModeType.Copy) &&
        mode.origCat.length && mode.engInfo) {

        // If the chassis destination is XT...
        if (btnInfo.chassis.extendedTemp) {
            // Return best match info.
            if (mode.origET) {
                btnInfo.copyCat = mode.origCat;
                btnInfo.compat = CopyModeCompat.Match;
            }
            else {
                const altET = mode.engInfo.getAlternate(false, true);
                if (altET.length > 0) {
                    btnInfo.copyCat = altET;
                    btnInfo.compat = CopyModeCompat.Swap;
                }
                else {
                    btnInfo.copyCat = mode.origCat;
                    btnInfo.compat = CopyModeCompat.Mismatch;
                }
            }
        }
        // Not XT. If conformally coated...
        else if (btnInfo.chassis.conformal) {

            // Return best match info for that.
            if (mode.origCC) {
                btnInfo.copyCat = mode.origCat;
                btnInfo.compat = CopyModeCompat.Match;
            }
            else {
                const altCC = mode.engInfo.getAlternate(true, false);
                if (altCC.length > 0) {
                    btnInfo.copyCat = altCC;
                    btnInfo.compat = CopyModeCompat.Swap;
                }
                else {
                    btnInfo.copyCat = mode.origCat;
                    btnInfo.compat = CopyModeCompat.Mismatch;
                }
            }
        }
        else {
            // Chassis is standard.
            // If the original copy dev was is not...
            if (mode.origET || mode.origCC) {

                // Return its standard alternate, or
                // the orig if it doesn't have one.
                const altStd = mode.engInfo.getAlternate(false, false);
                if (altStd.length > 0) {
                    btnInfo.copyCat = altStd;
                    btnInfo.compat = CopyModeCompat.Swap;
                }
                else {
                    btnInfo.copyCat = mode.origCat;
                    btnInfo.compat = CopyModeCompat.Mismatch;
                }
            }
            else {
                btnInfo.copyCat = mode.origCat;
                btnInfo.compat = CopyModeCompat.Match;
            }
        }
    }
    else {
        // Invalid
        throw new Error('Invalid call to _finalizeCopyBtnInfo');
    }
}

const _getSlotRangeForBtnInfo = (action: LayoutActionType, chassis: Chassis,
    restriction: ModuleSlotRestriction): [startIdx: number, endIdx: number] => {

    if (action === LayoutActionType.ModeCopy) {
        switch (restriction) {
            case ModuleSlotRestriction.FirstSlotOnly:
                return [0, 0];

            case ModuleSlotRestriction.NotFirstSlot:
                return [1, chassis.modules.length - 1];

            default:
                break;
        }
    }

    return [0, chassis.modules.length - 1];
}

export const getAddOrCopyBtnInfo = (
    layoutMode: LayoutMode,
    action: LayoutActionType,
    rack: Rack,
    copySrcRestricion: ModuleSlotRestriction,
    locs: ActBtnInfo[]) => { 
    // Logic of micro 800 once deleted controller , put index as -1 , base unit schema is inside chassis not  inside modules and make it plaform specific
    if(deleteBaseUnitAtChassis(rack.chassis)){
        locs.push(getActBtnInfo(action, rack, -1));
        if (canExtendChassis(rack.chassis)) {
            const newSlotIdx = rack.chassis.modules.length;
            locs.push(getActBtnInfo(action, rack, newSlotIdx));
        }
    }
   
    else  if (hasGetActBtnInfo(rack.chassis.platform)) {

        const [startIdx, endIdx] = _getSlotRangeForBtnInfo(action, rack.chassis, copySrcRestricion);

        // Walk all of the slots in the chassis. For each...
        for (let slotIdx = startIdx; slotIdx <= endIdx; slotIdx++) {

            // Get the module at the slot (if there is one).
            const mod = rack.chassis.modules[slotIdx];

            try {
                // If not, or we have a module that's a slot filler...
                if (!mod || mod.slotFiller) {
                    if (doesSlotQualifyForBtnAction(layoutMode, action, rack.chassis, slotIdx)) {
                        // Then we'd have a button over this slot.
                        // Get the location from the implementer and
                        // add it to our array.
                        locs.push(getActBtnInfo(action, rack, slotIdx));
                    }
                }
            }
            catch (error) {
                logger.error('Error in getAddOrCopyBtnInfo!');
                logger.error(error);
            }
        }

        if (canExtendChassis(rack.chassis) &&
            (copySrcRestricion !== ModuleSlotRestriction.FirstSlotOnly)) {
            const newSlotIdx = rack.chassis.modules.length;
            locs.push(getActBtnInfo(action, rack, newSlotIdx));
        }

    }
}


const getOtherBtnInfo = (
    layoutMode: LayoutMode,
    rack: Rack,
    copySrcRestricion: ModuleSlotRestriction,
    locs: ActBtnInfo[]) => {

    // TODO_FLEXHA - Make this a GenImpl Func???
    // If we have FlexHA, add any Duplex/Simplex
    // toggle buttons. 
    const chassis = rack.chassis;
    if (chassis.platform === PlatformFlexHA) {
        if (copySrcRestricion === ModuleSlotRestriction.FirstSlotOnly)
            return;

        // Run the module array
        const arrLen = chassis.modules.length;
        for (let idx = 1; idx < arrLen; ++idx) {
            if (doesSlotQualifyForBtnAction(layoutMode, LayoutActionType.MakeDuplex, chassis, idx)) {
                locs.push(getActBtnInfo(LayoutActionType.MakeDuplex, rack, idx));
            }
            else if (doesSlotQualifyForBtnAction(layoutMode, LayoutActionType.MakeSimplex, chassis, idx)) {
                locs.push(getActBtnInfo(LayoutActionType.MakeSimplex, rack, idx));
            }
        }
    }
}

export const getActionBtnInfo = (
    content: RackGroup,
    layoutMode: LayoutMode,
    selectedChassis: Chassis | undefined,
    idxRange: IdxRange
): [
        anyInfo: boolean,
        actionType: LayoutActionType,
        btnInfo: ActBtnInfo[] | undefined
    ] => {

    const copyRestriction = (layoutMode.engInfo &&
        (layoutMode.type === LayoutModeType.Copy))
        ? getModuleSlotRestriction(layoutMode.engInfo)
        : ModuleSlotRestriction.None;

    switch (layoutMode.type) {
        case LayoutModeType.Normal:
            if (selectedChassis) {
                const selRack = getRack(selectedChassis);
                if (selRack) {
                    const btnInfo = new Array<ActBtnInfo>();
                    getAddOrCopyBtnInfo(layoutMode, LayoutActionType.AddModule, selRack, copyRestriction, btnInfo);
                    getOtherBtnInfo(layoutMode, selRack, copyRestriction, btnInfo);
                    if (btnInfo.length > 0) {
                        return [true, LayoutActionType.AddModule, btnInfo];
                    }
                }
            }
            break;

        case LayoutModeType.Delete:
            {
                const numRacks = content.racks.length;
                if (numRacks > 0) {
                    const btnInfo = new Array<ActBtnInfo>();

                    for (let rackIdx = idxRange.first; rackIdx <= idxRange.last; rackIdx++) {
                        const rack = content.racks[rackIdx];
                        getDeleteBtnInfo(rack, btnInfo)
                    }

                    if (btnInfo.length > 0) {
                        return [true, LayoutActionType.ModeDelete, btnInfo];
                    }
                }
            }
            break;

        case LayoutModeType.Copy:
            {
                const copySrcPlatform = layoutMode.engInfo
                    ? layoutMode.engInfo.platform
                    : '';

                const numRacks = content.racks.length;
                if (numRacks > 0) {
                    const btnInfo = new Array<ActBtnInfo>();

                    for (let rackIdx = idxRange.first; rackIdx <= idxRange.last; rackIdx++) {
                        const rack = content.racks[rackIdx];
                        if (rack.chassis.platform === copySrcPlatform) {
                            getAddOrCopyBtnInfo(layoutMode, LayoutActionType.ModeCopy, rack, copyRestriction, btnInfo)
                        }
                    }

                   // See if we actually ended up with any info objects.
                    const qty = btnInfo.length;

                    // If so...
                    if (qty > 0) {

                        // Finalize each to get target catNos to
                        // use and associated compatibility.
                        for (let infoIdx = 0; infoIdx < qty; infoIdx++) {
                            _finalizeCopyBtnInfo(layoutMode, btnInfo[infoIdx]);
                        }
                        return [true, LayoutActionType.ModeCopy, btnInfo];
                    }
                }
            }
            break;
    }

    return [false, LayoutActionType.None, undefined];
}

export const getDeleteBtnInfo = (rack: Rack, locs: ActBtnInfo[]) => {
    // See if associated platform has an implementation for
    // action button locations. If not, there's nothing to do.
    if (hasGetActBtnInfo(rack.chassis.platform)) {
        // Walk all of the slots in the chassis. For each...
        for (let slotIdx = 0; slotIdx < rack.chassis.modules.length; slotIdx++) {

            // If there's a module in this slot
            // that is NOT an FPD module...
            const mod = rack.chassis.modules[slotIdx];
            if (mod && !mod.isFPD && !mod.isPlaceholder) {

                // Then we'd have a button over the slot.
                // Get the location from the implementer and
                // add it to our array.
                locs.push(getActBtnInfo(LayoutActionType.ModeDelete, rack, slotIdx));
            }
        }
    }
}
