import { updateChassisLayout } from "../../../implementation/ImplGeneral";
import { getChassisAndRackIndexById } from "../../../model/ChassisProject";
import { ChassisProject } from "../../../types/ProjectTypes";
import { AFType, AF_Base } from "../../../util/CheckerAutoFix";
import { snapCorrectChassisMODPower, snapMigrateModulesToMaxSupported, snapUpsizeSlot0ToSupportModCount } from "../../snap/SnapChecker";
import { snapCompactModuleArray } from "../../snap/SnapGeneralImpl";


export const flexExecuteAutoFix = (af: AF_Base, project: ChassisProject, callback: () => void): boolean => {
	let success = false;
	switch (af.type) {
		case AFType.Snap_CommHasTooManyModules:
			success = _afCommHasTooManyModules(project, af);
			break;

		case AFType.Snap_NoCommInChassis:
			success = _afAddCommToChassis(project, af);
			break;

		case AFType.ChassisOutOfPower:
		case AFType.ChassisLowOnPower:
			success = snapCorrectChassisMODPower(project, af);
			break;

		case AFType.Snap_NumBanksExceeded:
			success = _afCorrectNumberOfBanks(project, af);
			break;

		default:
			window.alert('AutoFix not yet implemented: ' + af.type);
			break;
	}

	if (success)
		callback();

	return success;
}


const _afCommHasTooManyModules = (project: ChassisProject, af: AF_Base): boolean => {
	// Call our helper to get the chassis and rack index
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}

	// First try to upsize the Slot 0 component.
	if (snapUpsizeSlot0ToSupportModCount(targetChassis, -1, false)) {
		return true;
	}

	// Next, migrate modules to a new chassis.
	return snapMigrateModulesToMaxSupported(targetChassis, idxRack);
}

const _afAddCommToChassis = (project: ChassisProject, af: AF_Base): boolean => {
	// Call our helper to get the chassis and rack index
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}

	// The upsize function will try to get a
	// starting comm from Product Selection,
	// then upsize it to handle the number of
	// I/O Modules in the chassis. Note: If we
	// cannot upsize Slot 0 to handle the
	// I/O Module count, another error with a 
	// 'Fix Me' will be on the chassis - we are 
	// NOT creating an additional chassis(s) here!
	if (snapUpsizeSlot0ToSupportModCount(targetChassis, -1, false)) {
		return true;
	}

	return false;
}

const _afCorrectNumberOfBanks = (project: ChassisProject, af: AF_Base): boolean => {
	// Call our helper to get the chassis and rack index
	const [targetChassis, ] = getChassisAndRackIndexById(project, af.targetInstanceID);
	if (targetChassis) {
		// Find the first Interconnect cable.
		const firstIC = targetChassis.modules.find(mod => mod && mod.isInterconnect);

		// Walk the module array in REVERSE and remove
		// any ICs until we get to the first one.
		let icRemoved = false;
		const modLen = targetChassis.modules.length;
		for (let ritr = modLen - 1; ritr > 0; --ritr) {
			const mod = targetChassis.modules[ritr];
			if (mod === firstIC)
				break;

			if (mod && mod.isInterconnect) {
				// Set the module to undefined
				targetChassis.modules[ritr] = undefined;
				icRemoved = true;
			}
		}

		if (icRemoved) {
			snapCompactModuleArray(targetChassis);
			updateChassisLayout(targetChassis, true);
			return true;
		}
	}

	return false;
}