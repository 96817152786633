import { EngInfoPowerSupply } from "../../engData/EngineeringInfo";
import { getPowerDetails } from "../../implementation/ImplGeneral";
import { convertGuidedSelAttrToAppVal } from "../../implementation/ImplHardwareGen";
import { getAllChoiceIDs, getPowerBreakdown } from "../../model/ChassisProject";
import { LogRender } from "../../types/Globals";
import { ChoiceGroupType, ChoiceInfo, ChoicesGroup, MessageCategory, MessageType, PanelMessage, StatusLevel } from "../../types/MessageTypes";
import { EnvRating, PSInputVoltage } from "../../types/ProjectTypes";
import { PSUErrorThreshold, PSUWarningThreshold } from "../../util/Checker";
import { addChoiceInfo, getFirstGroupChoicePossible, makeChoicesGroup, setChoiceGroupTipInfo } from "../../util/ChoicesHelp";
import { getPowerSuppliesFor } from "../../util/EngInfoHelp";
import { isNewStatusWorse, makeBold } from "../../util/GeneralHelpers";
import { logger } from "../../util/Logger";
import { IsPowerThresholdExceeded } from "../../util/PowerHelp";
import { getClosestCLXPowerSupplyMatchTo } from "../clx/model/CLXData";
import { PlatformCLX } from "../PlatformConstants";
import { cfgDlgGetGroupSelection, cfgDlgIsGroupSelectionValid, CfgDlgTipCallback, ConfigChassisCBData, makePanelMsg } from "./ChassisConfig";



export const cfgDlgUpdatePSGroup = (data: ConfigChassisCBData, tipCallback: CfgDlgTipCallback) => {
	switch (data.cfgAttrInfo.platform) {
		case PlatformCLX:
			updateCLXPSGroup(data, tipCallback);
			break;
		default:
			break;
	}

	// Platform config dies not have power.
	return;
}

const updateCLXPSGroup = (data: ConfigChassisCBData, tipCallback: CfgDlgTipCallback) => {

	// Get the current selection from the PS group.
	const psSelected = cfgDlgGetGroupSelection(data.psGrp);

	// Get the current env and voltage.
	const er = data.cfgAttrInfo.arrAttributeNameToValue.find(x => x.attrID === 'ER');
	const cv = data.cfgAttrInfo.arrAttributeNameToValue.find(x => x.attrID === 'CV');
	if (er == null || cv == null) {
		// Serious....
		throw new Error('CLXChassisCfg::updatePSGroup(): Unable to determine Env Rating and/or Control Voltage.')
	}

	const envType = convertGuidedSelAttrToAppVal(data.cfgAttrInfo.platform, er.attrID, er.optionID, true) as EnvRating;
	const vltgSel = convertGuidedSelAttrToAppVal(data.cfgAttrInfo.platform, cv.attrID, cv.optionID, true) as PSInputVoltage;

	// Create a new choices group for PS.
	const newPSGrp = cfgDlgGetPSGroup(PlatformCLX, envType, vltgSel, tipCallback);
	if (newPSGrp === '')
		return;

	// Use it for our ps group info.
	data.psGrp.group = newPSGrp;

	// If the last thing that changed was voltage,
	// and we went from 120V AC to 240V AC (or vice-
	// versa), the old PS selection will STILL be valid,
	// so we'll check just in case. If it is NOT a valid
	// choice anymore...
	// 2024.2.23 We can get into cases where we do NOT
	// have any PSUs. In that case, do not do any extra work.
	if (!cfgDlgIsGroupSelectionValid(newPSGrp, psSelected)) {
		data.psGrp.selections = [];
		if (getAllChoiceIDs(newPSGrp).length > 0) {
			// Call a helper to get us the choice from the
			// NEW group that is 'closest' in functionality
			// to the previously selected PS. Use what we
			// get back as the new psGrp selection.
			data.psGrp.selections = [cfgDlgGetClosestPSGroupMatch(data, newPSGrp, psSelected)];
		}
	}
}

export const cfgDlgGetClosestPSGroupMatch = (data: ConfigChassisCBData, psGroup: ChoicesGroup, psToMatch: string): string => {
	switch (data.cfgAttrInfo.platform) {
		case PlatformCLX:
			return getCLXClosestPSGroupMatch(psGroup, psToMatch);
		default:
			break;
	}

	// Platform config dies not have power.
	return '';
}

const getCLXClosestPSGroupMatch = (psGroup: ChoicesGroup, psToMatch: string): string => {

	// Get all possible ps choices from the group.
	const candidates = getAllChoiceIDs(psGroup);

	// We SHOULD ALWAYS have at least 1. If so...
	if (candidates.length > 0) {
		// Call a helper to find the best match
		// from our candidate power supplies.
		// If we have a PSU to match....
		if (psToMatch) {
			const closestMatch = getClosestCLXPowerSupplyMatchTo(psToMatch, candidates);
			return closestMatch;
		}

		// Return the first one.
		return candidates[0];
	}
	else {
		// Unexpected.
		// 2024.2.26 Can have no candidates here. return an empty string
		// instead of throwing an error.
		// throw new Error('No candidates in getClosestPSGroupMatch!');
		return '';
	}
}

export const cfgDlgGetPSGroup = (platform: string, envType: EnvRating, vltgSel: string, tipCallback: CfgDlgTipCallback) => {
	switch (platform) {
		case PlatformCLX:
			return getCLXPSGroup(envType, vltgSel, tipCallback);
		default:
			break;
	}

	// Platform config dies not have power.
	return '';

}

const getCLXPSGroup = (envType: EnvRating, vltgSel: string, tipCallback: CfgDlgTipCallback)
	: ChoicesGroup => {

	// Get enum value for incoming voltage selection.
	const vltg: PSInputVoltage = vltgSel as PSInputVoltage;

	// Get all power supplies available for the 
	// requested env type and voltage.
	const availPS = getPowerSuppliesFor(PlatformCLX, envType, vltg);

	// If we can...
	if (availPS) {

		// See which type we have.
		const haveSingles = (availPS.singles.length > 0);
		const haveReds = (availPS.redundants.length > 0);

		if (haveSingles || haveReds) {
			const psGroup = makeChoicesGroup(ChoiceGroupType.RequireOneOf, 'Power Supply');
			psGroup.row = true;

			if (haveSingles) {
				const sglChoices = getPSChoices(availPS.singles);
				sglChoices.forEach(choice => {
					addChoiceInfo(psGroup, choice);
				});
			}
			if (haveReds) {
				const redChoices = getPSChoices(availPS.redundants);
				redChoices.forEach(choice => {
					addChoiceInfo(psGroup, choice);
				});
			}

			setChoiceGroupTipInfo(psGroup, StatusLevel.Info,
				MessageCategory.Power, MessageType.GeneralInfo, tipCallback);

			// Return the group.
			return psGroup;
		}
	}

	// Create and return an empty group. If either
	// the Guided Selection or Engineering Data is
	// off, we need to GRACEFULLY handle it.
	const psGroup = makeChoicesGroup(ChoiceGroupType.RequireOneOf, 'Power Supply');
	if (LogRender.SelectionAPIs)
		logger.error('getPSGroup called, but found NO suitable power supplies! Got here because Guided Sel says the ER and CV selections should have a PSU, but Engineering Data does NOT.');

	return psGroup;
}

const getPSChoices = (psAvail: EngInfoPowerSupply[]): ChoiceInfo[] => {
	const choices = new Array<ChoiceInfo>();

	psAvail.forEach(ps => {
		choices.push({
			label: ps.label,
			id: ps.catNo
		});
	});

	return choices;
}

export const cfgDlgGetFirstPSAvailable = (platform: string, tipCallback: CfgDlgTipCallback, envType: EnvRating, vltg: PSInputVoltage): string => {
	const psGroup = cfgDlgGetPSGroup(platform, envType, vltg as string, tipCallback);
	if (psGroup === '')
		return '';

	return getFirstGroupChoicePossible(psGroup);
}

export const cfgDlgAddPowerMessage = (platform: string, data: ConfigChassisCBData, psCat: string) => {
	if (!psCat) {
		const msg: PanelMessage = {
			level: StatusLevel.Error,
			text: 'Unable to find a suitable Power Supply for the given configuration. Please contact customer support.',
			category: MessageCategory.Power,
			type: MessageType.Situational,
		};
		data.panelMessages.push(msg);
		data.msgLevel = StatusLevel.Error;
	}
	else {
		if (data.chassis) {
			const [/*pwrSupplied*/, pwrConsumed] = getPowerDetails(data.chassis);

			const psPwr = getPowerBreakdown(platform, psCat);

			// Start by asking if we have crossed the Warning Threshold...
			if (IsPowerThresholdExceeded(platform, pwrConsumed, psPwr, PSUWarningThreshold)) {
				let msg = makePanelMsg('', StatusLevel.NA, MessageCategory.General, MessageType.Situational);
				// If we have an error state....
				if (IsPowerThresholdExceeded(platform, pwrConsumed, psPwr, PSUErrorThreshold)) {
					msg = {
						level: StatusLevel.Warning,
						text: makeBold('WARNING') + ': The Power Supply currently ' +
							'selected does ' + makeBold('NOT') + ' provide sufficient ' +
							'power to meet the requirements of all of the modules ' +
							'in the chassis.',
						category: MessageCategory.Power,
						type: MessageType.Situational
					};
				}
				else {
					// We have a Power Warning state. Post an informational message.
					msg = {
						level: StatusLevel.Info,
						text: makeBold('INFORMATION') + ': The Power Supply currently ' +
							'selected ' + makeBold('MAY NOT') + ' provide sufficient ' +
							'power to meet the requirements of all of the modules ' +
							'in the chassis.',
						category: MessageCategory.Power,
						type: MessageType.Situational
					};
				}

				data.panelMessages.push(msg);

				if (isNewStatusWorse(msg.level, data.msgLevel))
					data.msgLevel = msg.level;
			}
		}
	}
}
