import React from 'react';
import '../styles/AppLayout.scss';
import ChassisLayoutView from './ChassisLayoutView';
import LayoutToolbar from '../toolbar/LayoutToolbar';
import { ActBtnInfo } from '../types/LayoutActions';
import { ChassisProject } from '../types/ProjectTypes';
//import { logger } from '../util/Logger';



interface Props {
    project: ChassisProject;
    requestModeAction: (actBtnInfo: ActBtnInfo) => void;
    onTBAction: (action: string) => void;
    contentChanged: () => void;
    onFirstVScroll: () => void;
}


const ChassisSelLayoutPanel = (props: Props) => {

    //logger.logCustom('ChassisSelLayoutPanel');

    return (
        <div className='chassis-selection-layout-panel'>
            <LayoutToolbar
                project={props.project}
                onTBAction={props.onTBAction}
            />
            <ChassisLayoutView
                project={props.project}
                requestModeAction={props.requestModeAction}
                contentChanged={props.contentChanged}
                onFirstVScroll={props.onFirstVScroll}
            />
        </div>
        );

}

export default ChassisSelLayoutPanel;

