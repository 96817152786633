import { LocAttributeInfo } from "./ProjectTypes";
import { ProjectSetting } from "./SettingsTypes";
import { Point } from "./SizeAndPosTypes";

export enum StatusLevel {
    NA = 'NA',
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error'
}

export enum MessageCategory {
    General = 'General',
    Power = 'Power',
    InterconnectCbl = 'InterconnectCbl',
    // 2024.2.13 Changed to align with Guided
    // Selection attribute IDs.
    Environment = 'ER',
    Size = 'CS',
    Wiring = 'RTB',
    HighAvail = 'CA',
    Voltage = 'CV',
}

export enum MessageType {
    GeneralInfo = 'GeneralInfo',
    Situational = 'Situational'
}

export interface PanelMessage {
    level: StatusLevel;
    text: string;
    category: MessageCategory;
    type: MessageType;
}

export enum ChoiceGroupType {
    // ALL in group are required.
    Require = 'Require',

    // One (and ONLY one) in group is required.
    RequireOneOf = 'RequireOneOf',

    // All in group are recommended, but optional.
    Recommend = 'Recommend',

    // All in group are optional.
    Optional = 'Optional'
}

export interface ChoiceInfo {
    label: string;
    id: string;
    disabled?: boolean;
}

export type ChoiceGrpTipCallback = (category: MessageCategory, type: MessageType, pt: Point) => void;

export interface ChoiceGroupTipInfo {
    level: StatusLevel;
    category: MessageCategory;
    type: MessageType;
    callback: ChoiceGrpTipCallback;
}

export interface ChoicesSubgroup {
    label: string;
    selectionInfo: ChoiceInfo[];
    generalTipInfo: ChoiceGroupTipInfo | undefined;
    situationalTipInfo: ChoiceGroupTipInfo | undefined;
}

export interface ChoicesGroup {
    type: ChoiceGroupType;
    subgroups: ChoicesSubgroup[];
    default?: string; // <- Used only for special cases.
    row?: boolean;
}


export enum ChoiceInfoType {
    Undetermined = 'Undetermined',
    Choice = 'Choice',
    GuideSel = 'GuideSel',
    CustomContent = 'CustomContent',
}

export interface BaseChoiceInfo {
    pageID: string;
    index: number;
    infoType: ChoiceInfoType;
}

export interface GroupChoiceInfo extends BaseChoiceInfo {
    group: ChoicesGroup;
    selections: string[];
}

export const createNewGrpChoiceInfo = (
    group: ChoicesGroup,
    selections: string[],
    pageID = '',
    index = -1): GroupChoiceInfo => {
    return {
        group: group,
        selections: selections,
        pageID: pageID,
        index: index,
        infoType: ChoiceInfoType.Choice,
    };
}

export interface GuidedSelChoiceInfo extends BaseChoiceInfo {
    setting: ProjectSetting;
    attrInfo: LocAttributeInfo;
    subGroup: ChoicesSubgroup;
    row?: boolean;
}

export const createNewGdSelGrpChoiceInfo = (
    locAttrInfo: LocAttributeInfo,
    setting: ProjectSetting,
    tipCallback: ChoiceGrpTipCallback,
    pageID = '',
    index = -1): GuidedSelChoiceInfo => {

    const choiceInfo: ChoiceInfo[] = [];

    // Determine what options are valid -> When the
    // Attr Info is validated, ONLY Valid Options are
    // are present in the Settings.options array EXCEPT
    // when an invalid option is selected...
    const validOptIDs: string[] = [];
    setting.options.forEach((opt) => {
        // If the option is the selected options...
        if (opt.id === setting.selectedOption.id) {
            // If the selection is valid...
            if (setting.valid)
                validOptIDs.push(opt.id);
        }
        else {
            // This is a valid option that is not selected.
            validOptIDs.push(opt.id);
        }
    });

    setting.gsMapOptionTxtValToInfo?.forEach((settingInfo) => {
        const settingChoice: ChoiceInfo =
        {
            label: settingInfo.txtValue,
            id: settingInfo.id,
            disabled: (validOptIDs.some(x => x === settingInfo.id) === false),
        };
        choiceInfo.push(settingChoice);
    });

    const genTip: ChoiceGroupTipInfo = {
        callback: tipCallback,
        level: StatusLevel.Info,
        category: setting.id as MessageCategory,
        type: MessageType.GeneralInfo,
    };

    const subgroup: ChoicesSubgroup = {
        label: setting.name,
        selectionInfo: choiceInfo,
        generalTipInfo: genTip,
        situationalTipInfo: undefined,
    }

    const info: GuidedSelChoiceInfo = {
        setting: setting,
        attrInfo: locAttrInfo,
        pageID: pageID,
        index: index,
        infoType: ChoiceInfoType.GuideSel,
        subGroup: subgroup,
        row: true,
    };

    return info;
}
