import { DEV_DEBUG } from "../types/Globals";
import { logger } from "../util/Logger";

declare global {
    interface Window {
        // Required for easy access to object
        // array used by adobe analytics.
        digitalData: unknown[];
    }
}

// Set as desired to enable adobe analytics event
// tracking in local debug builds/runs. Should GENERALLY
// be set to (and left at) false for Pull Requests, so that
// we're NOT tracking activity by developers. When adding
// new track calls, temporarily set to true for testing.
const _enableTrackingForDebug = true;


// Do NOT modify this.
const EventTrackingEnabled = DEV_DEBUG
    ? _enableTrackingForDebug
    : true;


// Structure of the the detail we pass
// to adobe for our 'basic' events.
interface BasicEventDetail {
    action: string;
    properties: {
        category: string;
        label: string;
    }
}

// Detailed version of track function. Use this form for
// any event that requires MORE, or otherwise special
// info included in the detail object than what we include
// in our basic details form.
export const trackEventDetailed = (action: string, detail: object) => {
    if (EventTrackingEnabled) {
        window.digitalData.push({
            event: action,
            eventDetails: detail
        });
    }
}

// Basic form of track function. Use this for MOST
// actions we want to track with adobe analytics.
export const trackEvent = (action: string) => {

    // If event tracking is enabled...
    if (EventTrackingEnabled) {

        // Action text is required.
        if (action.length) {

            logger.logTracking('trackBasicEvent: ' + action);

            // Construct basic detail object. Note that we
            // use the actionText provided for BOTH the
            // action AND the label in our event detail.
            const basicDetail: BasicEventDetail = {
                action: action,
                properties: {
                    category: "WebApp",
                    label: action
                }
            }

            // Pass it on to the 'detailed' version.
            trackEventDetailed(action, basicDetail);
        }
        else {
            throw new Error('ERROR: Invalid call to trackBasicEvent');
        }
    }
}
