import configurationApiService from "../services/apis/ConfigurationApiService";
import { StatusLevel } from "../types/MessageTypes";
import { ChassisProject } from "../types/ProjectTypes";
import { logger } from "../util/Logger";
import { displayAlertMsg } from "../util/MessageHelp";
import ServiceResponse from '../services/apis/Response';
import { AxiosError } from "axios";
import { makeConfigExchangePackage, setConfigModified } from "./UserProjectHelp";
import { cleanupResidualSnapshots, removeLocalPersist } from "./LocalPersist";
import toast from "../toast/toast";
import { store } from "../redux/store";
import { setConfigEverSaved, setSaveInProgress } from "../redux/slices/SaveRestoreSlice";
import projectApiService from "../services/apis/ProjectApiService";


const _reportSaveFailure = (msg: string, e?: unknown) => {
    if (e) {
        logger.error(e);
        const axErr = e as AxiosError;
        if (axErr && axErr.message) {
            msg += ('\n\n' + axErr.message);
        }
    }
    logger.error(msg);
    displayAlertMsg(msg, StatusLevel.Error, 'ERROR');
}

const _getProjectItemList = async (projectGuid: string) => {
    try {
        const result = await projectApiService.getProjectItemList(projectGuid);
        const response = new ServiceResponse(result);
        if (!response.isSuccessful()) {
            logger.warn('getProjectItemList response FAILED');
        }
    }
    catch (e) {
        logger.warn('getProjectItemList FAILED');
        logger.error(e);
    }
}

const _saveConfiguration = async (userProjGuid: string, cfg: ChassisProject) => {

    if (store.getState().saveRestInfo.saveInProgress) {
        displayAlertMsg('Second save attempted while save was in progress.',
            StatusLevel.Error, 'ERROR');
        return;
    }

    store.dispatch(setSaveInProgress(true));

    const data = makeConfigExchangePackage(userProjGuid, cfg, true);

    try {
        const toastId = toast.saving('Saving configuration...');
        const result = await configurationApiService.saveConfigToProject(data)
        const response = new ServiceResponse(result);

        // If the save was successful...
        if (response.isSuccessful()) {

            // If this is the FIRST time this particular 
            // configuration has EVER been saved...
            if (!configurationApiService.hasConfigBeenSaved(cfg.id)) {

                // Remember that its id has now been saved AT LEAST once.
                // This will ALSO reset our config modified flag.
                configurationApiService.recordConfigIdSaved(cfg.id);

                toast.loadProgress(toastId, 'Updating user project...')

                // Call one of the project APIs that refreshes
                // which items are actually children of the project.
                // We only need to do that the FIRST time a config
                // has been saved, and then only in case our user
                // expires prior to us returning up to the user project.
                // Without this step, a GET on the config we just
                // saved would FAIL.
                await _getProjectItemList(userProjGuid);

                store.dispatch(setConfigEverSaved(true));
            }
            else {
                // Not the first save. Just reset
                // our config-modified flag.
                setConfigModified(false);
            }

            // Remove localStorage persist info
            // we have for it, if any.
            removeLocalPersist(userProjGuid, cfg);

            // Call a helper to remove any old snapshots
            // we don't want to retain any longer.
            cleanupResidualSnapshots();

            toast.saved(toastId, 'Configuration Saved!');
        }
        else {
            const msg = 'Save FAILED with status: ' + response.status +
                '\n\n' + response.statusText;
            _reportSaveFailure(msg);
        }
    } catch (e) {
        const msg = 'Exception attempting Save';
        _reportSaveFailure(msg, e);
    }

    setTimeout(() => store.dispatch(setSaveInProgress(false)), 0);
}

// Called to save our current configuration (a ChassisProject)
// to the user's active project. The higher-level user project
// can contain several of our configurations, along with configs
// sourced from other applications.
export const saveConfiguration = (
    userProjGuid: string,
    cfg: ChassisProject
) => {

    // We MIGHT want to also check to see:
    //   - if our project.content has anything in it.
    //   - that we have a valid name for our configuration.
    // At a minimum, however, we need a valid user project ID.
    // If we have that...
    if (userProjGuid) {

        // Call our 'actual' save function.
        _saveConfiguration(userProjGuid, cfg);
    }
    else {
        displayAlertMsg('There is no active project available to add to.',
            StatusLevel.Error, 'Active Project Required');
    }
}

