import { EngInfoModule } from "../../../engData/EngineeringInfo";
import { addModuleAtSlot, deleteModuleAtSlot, updateChassisLayout } from "../../../implementation/ImplGeneral";
import { ActBtnInfo, LayoutActionType } from "../../../types/LayoutActions";
import { StatusLevel } from "../../../types/MessageTypes";
import { Chassis, NO_SLOT } from "../../../types/ProjectTypes";
import { displayAlertMsg } from "../../../util/MessageHelp";
import { chassisChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import { PlatformFlexHA } from "../../PlatformConstants";
import { flexHADefDuplexMod, flexHADefSimplexMod } from "./FlexHAEngDataImpl";



export const flexHADoesSlotQualifyForCopy = (
    chassis: Chassis,
    infoModToCopy: EngInfoModule,
    slotNum: number): boolean => {

    if (chassis.platform !== PlatformFlexHA)
        throw new Error('flexHADoesSlotQualifyForCopy(): Invalid platform!');

    // We should not be called unless we are in
    // 'copy mode' and the source module occupies
    // more than 1 slot. Check that we have the
    // duplex module.
    if (infoModToCopy.slotsUsed < 2)
        return true;

    // If the slot is NOT ODD...
    if (slotNum % 2 !== 1)
        return false;

    // If the next slot is occupied... 
    const modNextSlot = chassis.modules[slotNum + 1];
    if (modNextSlot && modNextSlot.slotFiller === false)
        return false;

    return true;
}

export const flexHAToggleDuplex = (btnInfo: ActBtnInfo) => {
    // If we are going from Duplex to Simplex...
    const chassis = btnInfo.chassis;
    const slot = btnInfo.slot;

    const toSimplex = btnInfo.action === LayoutActionType.MakeSimplex;
    const msgCompType = (toSimplex ? 'Simplex' : 'Duplex');
    const msgFailure = `Unable to convert the module at slot ${slot} to ${msgCompType}`;

    // We can only operate on modules in ODD
    // number slots...
    if ((slot % 2) !== 1) {
        displayAlertMsg(msgFailure, StatusLevel.Warning);
        return;
    }

    if (toSimplex) {
        const modDuplex = chassis.modules[slot];
        if (!modDuplex || modDuplex.slotsUsed < 2) {
            displayAlertMsg(msgFailure, StatusLevel.Warning);
            return;
        }

        const duplexPlaceholder = chassis.modules[slot + 1];
        // This is more of a diagnostic check. 
        // We should not fail here!
        if (!duplexPlaceholder || !duplexPlaceholder.isPlaceholder || duplexPlaceholder.id !== modDuplex.id) {
            console.error(`FlexHA Duplex data corrupted: ${msgFailure}`);
            displayAlertMsg(msgFailure, StatusLevel.Warning);
            return;
        }

        // Replace the Duplex module with 2 Simplex modules.
        chassisChanging(chassis);
        const wasSuspended = suspendUndoSnapshots(true);

        // Note: We could manipulate module array here, but
        // the overhead from standard funcs is not that bad.
        if (deleteModuleAtSlot(chassis, slot)) {
            addModuleAtSlot(chassis, flexHADefSimplexMod, slot, true);
            addModuleAtSlot(chassis, flexHADefSimplexMod, slot + 1, true);

            suspendUndoSnapshots(wasSuspended);

            updateChassisLayout(chassis, false);
            return;
        }

        // Something went wrong, reset undo/redo snapshots.
        suspendUndoSnapshots(wasSuspended);
    }
    else {
        // Making a Duplex
        const modSlotA = chassis.modules[slot]; 
        const modSlotB = chassis.modules[slot + 1]; 
        if (modSlotA && modSlotB && modSlotA.slotsUsed === 1) {

            chassisChanging(chassis);
            const wasSuspended = suspendUndoSnapshots(true);

            // Note: We could manipulate module array here, but
            // the standard funcs do everything correctly and, if
            // something changes, only one place to change the code.
          if (deleteModuleAtSlot(chassis, slot) && deleteModuleAtSlot(chassis, slot + 1)) {
                addModuleAtSlot(chassis, flexHADefDuplexMod, slot, true);

                suspendUndoSnapshots(wasSuspended);

                updateChassisLayout(chassis, false);
                return;
            }

            // Something went wrong, reset undo/redo snapshots.
            suspendUndoSnapshots(wasSuspended);
       }
    }

    displayAlertMsg(msgFailure, StatusLevel.Warning);
    return;
}

export const flexHAGetChassisSlotCount = (chassis: Chassis): number => {

    return chassis.modules.length;

    // TODO_FLEXHA BANKEXT
    //let slots = 1;
    //const lenModArr = chassis.modules.length;
    //for (let idx = 1; idx < lenModArr; ++idx) {
    //    const mod = chassis.modules[idx];
    //    if (!mod || !mod.isInterconnect)
    //        slots++;
    //}

    //return slots;
}

export const flexHAGetIOBaseIndexForSlot = (chassis: Chassis, idxSlot: number): number => {

    if (idxSlot >= chassis.modules.length || idxSlot < 1)
        throw new Error('flexHAGetIOBaseIndexForSlot() slot out of range for I/O Base index.');

    const adjustedIndex = idxSlot - 1;
    return Math.floor(adjustedIndex / 4);

    // TODO_FLEXHA BANKEXT
    //// Note: We are getting the I/O SLOT
    //// count, which excludes Slot 0 (Adapter)
    //// any Bank Ext Kits.
    //let checkForBankExt = true;
    //while (checkForBankExt) {
    //    const modAtSlot = chassis.modules[idxSlot];
    //    if (modAtSlot && modAtSlot.isInterconnect) {
    //        idxSlot++;
    //        if (idxSlot >= chassis.modules.length) {
    //            idxSlot = chassis.modules.length - 1;
    //            checkForBankExt = false;
    //        }
    //    }
    //    else
    //        checkForBankExt = false;
    //}

    //if (idxSlot >= chassis.modules.length || idxSlot < 1)
    //    throw new Error('flexHAGetIOBaseIndexForSlot() slot out of range for I/O Base index.');

    //const nBankExtKits = flexHAGetBankExtKitCount(chassis, idxSlot);
    //const adjustedIndex = idxSlot - nBankExtKits - 1;
    //return Math.floor(adjustedIndex / 4);
}

export const flexHAGetIOBaseFirstSlotIdx = (chassis: Chassis, ioBaseIndex: number): number => {

    return (ioBaseIndex * 4) + 1;

    // TODO_FLEXHA BANKEXT
    //// Start the calculated index soully
    //// based on I/O Base Index.
    //const calcIdxFirstIOBaseSlot = (ioBaseIndex * 4) + 1;
    //// The max index will add the allowed ext kits.
    //const maxIdxFirstIOBaseSlot = calcIdxFirstIOBaseSlot + maxFlexHABankExtKits;
    //// Get the number of extension kits up to
    //// and including the max first index.
    //const cntBankExtKits = flexHAGetBankExtKitCount(chassis, maxIdxFirstIOBaseSlot);

    //return calcIdxFirstIOBaseSlot + cntBankExtKits;
}

export const flexHAAssignSlotIDs = (chassis: Chassis) => {
    const lenModArr = chassis.modules.length;
    for (let idx = 0; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];
        if (mod) {
            mod.slotIdx = idx;
            if (mod.isPlaceholder)
                mod.slotID = NO_SLOT;
            else
                mod.slotID = idx;

        }
    }

    // TODO_FLEXHA BANKEXT
    //let slotID = 0;
    //const lenModArr = chassis.modules.length;
    //for (let idx = 0; idx < lenModArr; ++idx) {
    //    const mod = chassis.modules[idx];
    //    if (!mod) {
    //        slotID++;
    //    }
    //    else {
    //        mod.slotIdx = idx;
    //        // If we have an interconnect...
    //        if (mod.isInterconnect) {
    //            mod.slotID = NO_SLOT;
    //        }
    //        else {
    //            if (mod.isPlaceholder)
    //                mod.slotID = NO_SLOT;
    //            else
    //                mod.slotID = slotID;

    //            slotID++;
    //        }
    //    }
    //}
}

// TODO_FLEXHA BANKEXT
export const flexHAGetBankExtKitCount = (chassis: Chassis, uptoSlotIndex = -1): number => {
    // If we have an 'up to' slot index passed
    // in, we will get the Kit Count up to AND
    // INCLUDING that slot.
    const len = (uptoSlotIndex === -1 ? chassis.modules.length : Math.min(uptoSlotIndex + 1, chassis.modules.length));
    let cntKits = 0;
    for (let idx = 1; idx < len; ++idx) {
        const mod = chassis.modules[idx];
        if (mod && mod.isInterconnect)
            cntKits++;
    }

    return cntKits;
}