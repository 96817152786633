import { getConfigSpec } from "../../../config";
import { EngInfoChassis, EngInfoComponent, EngInfoModule } from "../../../engData/EngineeringInfo";
import {
    createModule,
    createModuleFor,
    GeneralImplSpec,
    RegisterGeneralImpl,
    updateChassisLayout,
    ChassisCompProps,
    canExtendChassis,
    addModuleAtSlot,
    getModuleSlotRestriction
} from "../../../implementation/ImplGeneral";
import { detachModule, getDefaultImageSource, isDeviceCompatibleWithChassis } from "../../../model/ChassisProject";
import {
    Chassis,
    ChassisElement,
    ChassisModule,
    DeviceCategory,
    DeviceType,
    FlexHAChassis,
    IOModuleWiring,
    ModuleDragStatus,
    ModuleSlotRestriction,
    NO_SLOT,
    Rack
} from "../../../types/ProjectTypes";
import { LocAndSize, Point, Size } from "../../../types/SizeAndPosTypes";
import {
    DragDeviceInfo,
    DropResult,
    DropStatus,
} from "../../../util/DragAndDropHelp";

import { addRequiredAccys } from "../../../util/AccessoriesHelp";
import { getEngInfoForComp } from "../../../util/EngInfoHelp";
import { getNewInstanceId } from "../../../util/InstanceIdHelp";
import {
    areUndoSnapshotsSuspended,
    chassisChanging,
    suspendUndoSnapshots
} from "../../../util/UndoRedo";
import { PlatformFlexHA } from "../../PlatformConstants";
import {
    RegisterSnapClientDetails,
    snapFilterAvailableModules,
    snapGetDefaultChassisName,
    snapGetModuleSlotRestriction,
    snapGetSlotTypeRestriction,
    SnapPlatformDetails
} from "../../snap/SnapGeneralImpl";
import FlexHAChassisComp from "../components/FlexHAChassisComp";
import { flexHAAddInitialSAPSUs, flexHACreatePowerSupply } from "./FlexHAHardwareImpl";
import { getEmptyLoc, getLocCenter, isEmptyLoc, isPointInLoc, offsetLoc } from "../../../util/GeneralHelpers";
import { ActBtnInfo, DfltActBtnSpecs, LayoutActionType } from "../../../types/LayoutActions";
import { SelectedCompInfo } from "../../../selectComponents/SelectComponentsTypes";
import { snapConfigureChassis } from "../../snap/SnapChassisConfig";
import { LayoutMode } from "../../../util/LayoutModeHelp";
import { flexHADoesSlotQualifyForCopy } from "./FlexHAChassis";
import {
    FlexHALayoutInfo,
    flexHALayoutChassis,
    getFHASizeDetails,
    maxFlexHAChassisSize,
    maxFlexHAIOModules
} from "./FlexHALayout";
import { Use5015TransparentImgs } from "../../../types/Globals";
import { logger } from "../../../util/Logger";


const configSpec = getConfigSpec();
const _flexHAChassisImgLoc = `${configSpec.ISD_URL}/assets/flexHA/`;

export const flexHAGetEmptySlotImage = (chassis: Chassis): string => {
    chassis;
    return _flexHAChassisImgLoc + '5015-Empty.png';
}

export const flexHAGetDefaultXSlotWidth = (platform: string): number => {
    platform;
    const dtls = getPlatformDetails();
    const xSlotWidth = dtls.defaultXSlotWidth * dtls.imgScaleFactor;
    return xSlotWidth;
}

let _platformDetails: SnapPlatformDetails | undefined = undefined;

const getPlatformDetails = (): SnapPlatformDetails => {
    if (!_platformDetails) {

        const sizeDtls = getFHASizeDetails();

        _platformDetails = {
            imgScaleFactor: sizeDtls.baseScale,
            leftSlotStartSize: { ...sizeDtls.sizeAdapterKit },
            defaultXSlotWidth: sizeDtls.ioBaseInfo.baseSize.width,
            firstSlotRestricted: true,
            absMaxModules: maxFlexHAChassisSize,
            cableSplitAllowed: false,
            rightCapInfo: undefined,
            getFPDMap: undefined,
            isALeftSlotModule: (modInfo: EngInfoModule) => { return modInfo.isComm },
        }
    }

    return _platformDetails;
}

export enum FlexHABaseType {
    IOBase = 'I/O Base',
    AdapterBase = 'Adapter Base',
}

export const flexHACreateModule = (catNo: string): ChassisModule | null => {
    const info = getEngInfoForComp(PlatformFlexHA, catNo);
    if (info) {
        if (info.isModule)
            return createModuleFor(info as EngInfoModule);

    }

    return null;
}

const flexHACreateChassis = (chasEngInfo: EngInfoChassis, psCatNo?: string): Chassis | undefined => {

    const layout: FlexHALayoutInfo = {
        platform: chasEngInfo.platform,
        numFPDs: 0,
        extendedTemp: chasEngInfo.envInfo.etOk,
        conformal: chasEngInfo.envInfo.ccOk,
        slotLocs: new Array<LocAndSize>(),
        rightCapImgSrc: '',
        rightCapLoc: { x: 0, y: 0, width: 0, height: 0 },
        size: { width: 0, height: 0 },
        ioBaseLocs: new Array<LocAndSize>(),
        ioTBLocs: new Array<LocAndSize>(),
        backplateLoc: { x: 0, y: 0, width: 0, height: 0 }
    };

    const chassis: FlexHAChassis = {
        id: getNewInstanceId(),
        bump: 0,
        dragTarget: false,
        xSlotWidth: 0,
        platform: chasEngInfo.platform,
        deviceType: DeviceType.Chassis,
        catNo: chasEngInfo.catNo,
        description: chasEngInfo.description,
        isPlaceholder: chasEngInfo.isPlaceholder,
        imgSrc: '',
        extendedTemp: chasEngInfo.envInfo.etOk,
        conformal: chasEngInfo.envInfo.ccOk,
        accysPossible: chasEngInfo.anyAccysPossible(),
        layout: layout,
        ps: flexHACreatePowerSupply(psCatNo),
        modules: new Array<ChassisModule>(1),
        selected: false,
        parent: undefined,
        redundant: false,
        defaultIOModWiring: IOModuleWiring.Screw,
        statusLog: undefined,
        saPSU: [],
    }

    if (chassis.ps) {
        chassis.ps.parent = chassis;
    }

    updateChassisLayout(chassis);

    return chassis;
}


export const getPlaceholderModSource = (module: ChassisModule): ChassisModule => {
    let parentMod = module;
    if (parentMod.isPlaceholder && module.parent) {
        // For clarity...
        const chassis = module.parent;
        for (let ritr = module.slotIdx - 1; ritr >= 0; --ritr) {
            const prevMod = chassis.modules[ritr];
            if (!prevMod || prevMod.id !== module.id)
                break;

            parentMod = prevMod;
        }
    }

    return parentMod;
}


// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
const _testModuleAddition = (catNo: string, chassis: Chassis, slot = -1): number => {
    // TODO_FLEXHA BANKEXT - remove cntBankExt && maxSlotIndex
    //const cntBankExt = flexHAGetBankExtKitCount(chassis);
    const cntBankExt = 0;
    const maxSlotIndex = maxFlexHAIOModules + cntBankExt;

    // Check if the slot is defined. 5015 can have
    // a max of 6 4-slot I/O bases plus any Bank 
    // Extension Kits. Is the slot in this range...
    if (slot >= 0 && slot > maxSlotIndex)
        return NO_SLOT;

    // Get the module info.
    const engInfo = getEngInfoForComp(PlatformFlexHA, catNo) as EngInfoModule;
    if (!engInfo)
        return NO_SLOT;

    const duplex = (engInfo.slotsUsed === 2);
    const lenModArray = chassis.modules.length;
    const leftSlot = engInfo.isComm;
    if (leftSlot) {
        // Slot passed in must be 0 or -1.
        if (slot !== -1 && slot !== 0)
            return NO_SLOT;
        // Slot 0 must be empty. Slot fillers
        // are NOT valid here...
        if (chassis.modules[0] != null)
            return NO_SLOT;

        return 0;
    }
    else if (slot > 0) {
        // We have a specific slot to add the module...
        if (duplex) {
            // We have a duplex module. As organized with
            // the Comm in slot 0, each I/O base has 2 to 4
            // slots and a duplex module cannot bridge I/O
            // bases and cannot bridge the center div in a 4 slot
            // I/O base. So,the slot we are adding it to MUST
            // be an ODD number. Also if the next slot is beyond
            // the allowed max slots...
            // TODO_FLEXHA BANKEXT - remove trueSlot
            // const trueSlot = slot - flexHAGetBankExtKitCount(chassis, slot);
            const trueSlot = slot;
            if (trueSlot % 2 === 0 || slot + 1 > maxSlotIndex)
                return NO_SLOT;

            // IF the slot exists in the array...
            if (slot < lenModArray) {
                let slotMod = chassis.modules[slot];
                if (slotMod && !slotMod.slotFiller)
                    return NO_SLOT;
                slotMod = chassis.modules[slot + 1];
                if (slotMod && !slotMod.slotFiller)
                    return NO_SLOT;
            }

            // Return true here since we can add
            // I/O bases to accommodate this module.
            return slot;
        }
        else {
            // IF the slot exists in the array...
            if (slot < lenModArray) {
                const slotMod = chassis.modules[slot];
                if (slotMod && !slotMod.slotFiller)
                    return NO_SLOT;
            }

            // Return true here since we can add
            // I/O bases to accommodate this module.
            return slot;
        }
    }
    else {
        // We do not have a specific slot for a module.
        // Do we have an open slot - first if we have
        // the max modules allowed in our array (meaning
        // we CANNOT extend the chassis)...
        if (lenModArray === maxFlexHAChassisSize + cntBankExt) {
            if (duplex) {
                // Need to find 2 adjacent empty slots where
                // the first slot's index is ODD (not even).
                let success = false;
                let idxSlot = NO_SLOT;
                for (let idx = 1; idx < lenModArray; idx += 2) {
                    const slotA = chassis.modules[idx];
                    const slotB = chassis.modules[idx + 1];
                    if (slotA && slotB) {
                        // TODO_FLEXHA BANKEXT
                        //if (slotA.isInterconnect || slotB.isInterconnect) {
                        //    // bump the index for each one that
                        //    // is an interconnect (ie Bank Ext Kit).
                        //    idx++;
                        //    if (slotA.isInterconnect && slotB.isInterconnect)
                        //        idx++;

                        //    // Start the loop again.
                        //    continue;
                        //}

                        success = (slotA.slotFiller && slotB.slotFiller);
                    }
                    else if (slotA && !slotB) {
                        // TODO_FLEXHA BANKEXT
                        //if (slotA.isInterconnect) {
                        //    idx++;
                        //    continue;
                        //}
                        success = slotA.slotFiller;
                    }
                    else if (!slotA && slotB) {
                        // TODO_FLEXHA BANKEXT - remove comment
                        // Slot B should NEVER be a Bank Ext Kit!
                       success = slotB.slotFiller;
                    }
                    else {
                        // both slots are empty
                        success = true;
                    }

                    if (success) {
                        idxSlot = idx;
                        break;
                    }
                }

                return idxSlot;
            }
            else {
                let idxSlot = NO_SLOT;
                // Set idxSlot to any open slots.
                (chassis.modules.some((mod, idx) => {
                    if (idx > 0) {
                        if (!mod || mod.slotFiller) {
                            idxSlot = idx;
                            return true;
                        }
                    }
                    return false;

                }));

                return idxSlot;
            }
        }
        else {
            // We have room to add another I/O base if needed.
            if (duplex) {
                // Try to find to adjacent emtpy slots. We will
                // only look at ODD indexes (1,3,5, etc.)
                for (let idx = 1; idx < lenModArray - 1; idx += 2) {
                    const mod = chassis.modules[idx];

                    // TODO_FLEXHA BANKEXT
                    //if (mod && mod.isInterconnect) {
                    //    idx++;
                    //    continue;
                    //}

                    if (!mod || mod.slotFiller) {
                        const modAdj = chassis.modules[idx + 1];
                        if (!modAdj || modAdj.slotFiller)
                            return idx;
                    }
                }
            }
            else {
                // Try to find an open slot first (no slot filler)
                let idx = chassis.modules.findIndex((mod, ind) => ind > 0 && !mod);
                if (idx > 0)
                    return idx;
                // Try to find a slot filler.
                idx = chassis.modules.findIndex((mod, ind) => ind > 0 && mod && mod.slotFiller);
                if (idx > 0)
                    return idx;
            }

            // If we are here, we need to add another I/O base
            // and the add index will be the first slot in the
            // new base.
            return lenModArray;
        }
    }
}

// 2024.6.18 RETAIN THIS - DO NOT DELETE!
//const _SAPwrSortPredicate = (a: FlexHASAPowerSupply, b: FlexHASAPowerSupply) => {
//    if (a.ioBaseIndex < b.ioBaseIndex)
//        return -1;

//    return 1;
//}

// 2024.6.18 RETAIN THIS - DO NOT DELETE!
//const _adjustSAPwrArray = (saPSUs: FlexHASAPowerSupply[]) => {
//    // Sort the array so that I/O Base
//    // are from low to high.
//    saPSUs.sort(_SAPwrSortPredicate);
//    const len = saPSUs.length;
//    // Reverse iterate.
//    let lastBaseIdx = -1;
//    for (let ritr = len - 1; ritr >= 0; --ritr) {
//        // Start by saying we will remove any
//        // undefined PSUs from the array.
//        let remove = (saPSUs[ritr] == null);
//        // If we're still not removing it, but
//        // we have a repeat I/O Base index...
//        if (!remove && saPSUs[ritr].ioBaseIndex === lastBaseIdx)
//            remove = true;

//        if (remove)
//            saPSUs.splice(ritr, 1);
//        else
//            lastBaseIdx = saPSUs[ritr].ioBaseIndex;
//    }
//}

const _assignSlotIDs = (chassis: Chassis) => {

    const lenModArr = chassis.modules.length;
    for (let idx = 0; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];
        if (mod) {
            mod.slotIdx = idx;

            if (mod.isPlaceholder) {
                mod.slotID = NO_SLOT;
            }
            else {
                mod.slotID = idx;
            }
        }
    }
    // TODO_FLEXHA BANKEXT
    //const lenModArr = chassis.modules.length;
    //let idSlot = 0;
    //for (let idx = 0; idx < lenModArr; ++idx) {
    //    const mod = chassis.modules[idx];
    //    if (mod) {
    //        mod.slotIdx = idx;

    //        if (mod.isPlaceholder || mod.isInterconnect) {
    //            mod.slotID = NO_SLOT;
    //            if (mod.isInterconnect)
    //                continue; // Do NOT increment ID.

    //        }
    //        else {
    //            mod.slotID = idSlot;
    //        }
    //    }
    //    idSlot++;
    //}
}

const _adjustModifiedChassis = (chassis: FlexHAChassis) => {

    // Store the original length.
    const originalModArrLen = chassis.modules.length;

    // First make sure we have the correct
    // number of slots that align with 4
    // slot I/O Bases
    let adjustedLen = chassis.modules.length;

    // Make sure the I/O Slots align with I/O bases.
    // 'adjustedLen' represents ONLY slots now.
    // Subtract 1 for the adapter.
    const extraSlots = (adjustedLen - 1) % 4;
    // If we have any extra non-aligned slots...
    if (extraSlots > 0) {
        // Add any slots needed for alignment.
        adjustedLen += 4 - extraSlots;
    }
    // We cannot exceed the max slots allowed.
    const newModArrLen = Math.min(adjustedLen, maxFlexHAChassisSize);
    chassis.modules.length = newModArrLen

    // TODO_FLEXHA BANKEXT
    //// Subtract any Bank Ext Kits we find.
    //const nBankExtKits = flexHAGetBankExtKitCount(chassis);
    //adjustedLen -= nBankExtKits;
    //// Make sure the I/O Slots align with I/O bases.
    //// 'adjustedLen' represents ONLY slots now.
    //// Subtract 1 for the adapter.
    //const extraSlots = (adjustedLen - 1) % 4;
    //// If we have any extra non-aligned slots...
    //if (extraSlots > 0) {
    //    // Add any slots needed for alignment.
    //    adjustedLen += 4 - extraSlots;
    //}
    //// We cannot exceed the max slots allowed. Note:
    //// we have NOT added the Bank Ext Kits back YET!
    //adjustedLen = Math.min(adjustedLen, maxFlexHAChassisSize);

    //// The new mod array len will be the adjusted
    //// slot-only length plus any Bank Ext Kits.
    //const newModArrLen = adjustedLen + nBankExtKits;
    //chassis.modules.length = newModArrLen;

    // Now walk the array in reverse...
    for (let ritr = newModArrLen - 1; ritr > 3; ritr -= 4) {
        // If we do NOT have an empty 4 slot block...
        // Note: we do NOT count slot fillers as empties
        // and we will break on Bank Ext Kits as well.
        if (chassis.modules[ritr] ||
            chassis.modules[ritr - 1] ||
            chassis.modules[ritr - 2] ||
            chassis.modules[ritr - 3]) {
            break;
        }
        else {
            // Shorten the array by 4 removing
            // the empty I/O base.
            chassis.modules.length -= 4;
        }
    }

    // Update slot indexes/IDs
    _assignSlotIDs(chassis);

    // TODO_FLEXHA BANKEXT - remove nBankExtKits.
    const nBankExtKits = 0;

    // See if we need to refresh the SA PSUs.
    // If the mod array len has changed OR we
    // have I/O Bases, but not any SA PSUs....
    const lenModArr = chassis.modules.length;
    let refreshSAPower = (originalModArrLen !== lenModArr);
    refreshSAPower = refreshSAPower || (chassis.saPSU.length === 0 && lenModArr - nBankExtKits > 1);

    if (refreshSAPower) {
        // TODO_FLEXHA BANKEXT - adjust comment.
        // If we only have the adapter and
        // nothing else (except Bank Ext Kits)...
        if (lenModArr - nBankExtKits === 1) {
            // We do not have any SA PSUs.
            chassis.saPSU = [];
            return;
        }

        // For now, just re-establish the initial
        // SA PSUs, which will be on the I/O Base
        // and any I/O Bases following a Bank 
        // Extension Kit.
        flexHAAddInitialSAPSUs(chassis);

        // Uncomment to cleanup the SA PSU array
        // verse just resetting it (flexHAAddInitialSAPSUs)

        //// Clean up the SA PSU array (order
        //// and remove invalid entries, etc.).
        //_adjustSAPwrArray(chassis.saPSU);

        //const lenPSUs = chassis.saPSU.length;

        //// If we do NOT have any SA Power...
        //if (lenPSUs === 0) {
        //    // By default, add one to the first
        //    // I/O Base.
        //    const saPSU = flexHACreatePowerSupply(flexHASAPSUCatalog) as FlexHASAPowerSupply;
        //    if (saPSU) {
        //        saPSU.ioBaseIndex = 0;
        //        chassis.saPSU.push(saPSU);
        //    }
        //    return;
        //}

        //// Walk the SA PSUs in reverse. Remove any
        //// PSUs whose ioBaseIndex is greater than
        //// the max. Break as soon as we have a PSU
        //// whose ioBaseIndex is <= the max. Note:
        //// the PSU in the array should be in order
        //// of I/O Base Index (low to high).
        //const maxIOBaseIdx = ((lenModArr - nBankExtKits - 1) / 4) - 1;
        //for(let ritr = lenPSUs - 1; ritr >= 0; --ritr) {
        //    const psu = chassis.saPSU[ritr];
        //    if (psu.ioBaseIndex > maxIOBaseIdx)
        //        chassis.saPSU.splice(ritr, 1);
        //    else
        //        break;
        //}
    }
}


//// Trims the last chassis' last 4 slots if ALL are empty.
//// Returns true if trimming occurred and false if not.
//const _trimLastIOBaseIfEmpty = (chassis: Chassis): boolean => {

//    // Get the offset of the LAST I/O base.
//    const lastBaseIdx = flexHAGetNumIOBases(chassis) - 1;

//    // If we have any...
//    if (lastBaseIdx >= 0) {

//        // Calculate the base's slot range.
//        const firstSlot = lastBaseIdx * 4 + 1;
//        const lastSlot = firstSlot + 3;

//        // Walk the slots. If any are NOT empty, there's
//        // nothing to trim. Return false.
//        for (let slot = firstSlot; slot <= lastSlot; slot++) {
//            if (chassis.modules[slot]) {
//                return false;
//            }
//        }

//        // If we're still here, all slots on
//        // the base were empty. Shorten the
//        // chassis' module array by 4 and
//        // return true.
//        chassis.modules.length -= 4;
//        return true;
//    }

//    // The chassis has no I/O bases
//    // to do any trimming.
//    return false;
//}


//const _adjustModuleArray = (chassis: Chassis) => {

//    // See how many I/O slots the chassis
//    // currently has (not including slot 0).
//    const ioSlots = chassis.modules.length - 1;

//    // Our number should be a multiple of 4.
//    // See if we have any stragglers.
//    const extras = ioSlots % 4;

//    // If so, extent the modules array
//    // to get to the next 4-slot boundary.
//    if (extras > 0) {
//        chassis.modules.length += (4 - extras);
//    }

//    // Trim any slots on the right that would
//    // represent completely empty I/O bases.
//    let trimComplete = false;
//    while (!trimComplete) {
//        if (!_trimLastIOBaseIfEmpty(chassis)) {
//            trimComplete = true;
//        }
//    }


//    //// First make sure we have the correct 
//    //// number of slots that align with 4 
//    //// slot I/O Bases
//    //let currLen = chassis.modules.length;
//    //currLen += (currLen - 1) % 4;
//    //currLen = Math.min(currLen, maxFlexHAChassisSize);
//    //chassis.modules.length = currLen;

//    //// Now walk the array in reverse...
//    //for (let ritr = currLen - 1; ritr > 0; ritr -= 4) {
//    //    // If we do NOT have an empty 4 slot block...
//    //    // Note: we do NOT count slot fillers as empties.
//    //    if (chassis.modules[ritr] ||
//    //        chassis.modules[ritr - 1] ||
//    //        chassis.modules[ritr - 2] ||
//    //        chassis.modules[ritr - 3]) {
//    //        break;
//    //    }
//    //    else {
//    //        // Shorten the array by 4 essentailly
//    //        // removing an empty trailing I/O base.
//    //        chassis.modules.length -= 4;
//    //    }
//    //}

//    // Update slot indexes/IDs
//    const lenModArr = chassis.modules.length;
//    for (let idx = 1; idx < lenModArr; ++idx) {
//        const mod = chassis.modules[idx];
//        if (mod) {
//            mod.slotIdx = idx;

//            if (mod.isPlaceholder) {
//                mod.slotID = NO_SLOT;
//            }
//            else {
//                mod.slotID = idx;
//            }
//        }
//    }
//}

// Dummy Module to occupy slots used by Duplex modules.
// TODO_FLEXHA - If model holds, Move to a general function.
const _createMultiSlotModPlaceholder = (parentMod: ChassisModule): ChassisModule => {
    return {
        id: parentMod.id,                       // SET THE PARENT's ID.
        platform: parentMod.platform,
        deviceType: parentMod.deviceType,
        catNo: parentMod.catNo,
        description: parentMod.description,
        isPlaceholder: true,                    // Mark it as Placeholder
        extendedTemp: parentMod.extendedTemp,
        conformal: parentMod.conformal,
        accysPossible: false,
        category: DeviceCategory.Other,  
        imgSrc: '',
        imgSize: { height: 0, width: 0 },
        movable: false,
        slotIdx: parentMod.slotIdx+1,
        slotID: NO_SLOT,
        slotsUsed: 1,
        selected: false,
        dragStatus: ModuleDragStatus.NA,
        parent: parentMod.parent,               // Set the ref to the parent chassis.
        isController: parentMod.isController,
        isComm: parentMod.isComm,
        isConnClient: parentMod.isConnClient,
        spclLocalConns: 0,
        slotFiller: false,
        isFPD: false,
        isInterconnect: false,
    };
} 

// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
const flexHACanModuleBeAdded = (module: ChassisModule, chassis: Chassis): number => {
    return _testModuleAddition(module.catNo, chassis);
}

const flexHAAddModuleAtSlot = (chassis: Chassis, catNo: string, slot: number, envMismatchOk: boolean): boolean => {
    // For 5015, all components (as of now) should
    // be compatible.
    envMismatchOk = true;

    // Check if we can add the module.
    const actualSlot = _testModuleAddition(catNo, chassis, slot);

    // If the slot returned is 'no-slot' (-1) or is NOT the
    // requested slot (should always be the same, but check)
    if (actualSlot === NO_SLOT || (slot !== NO_SLOT && actualSlot !== slot))
        return false;

    // Create the requested module.
    const module = createModule(chassis.platform, catNo);

    // If we can, and if we have a suitable environment
    // match between it and our chassis...
    if (module) {
        if (envMismatchOk || isDeviceCompatibleWithChassis(module, chassis)) {

            // Notify the undo/redo before making changes.
            chassisChanging(chassis);

            // Do we need another I/O base - This is to
            // adjust the length of the module array to
            // align with 4-slot I/O bases.
            const lenModArr = chassis.modules.length;
            let numIOBasesToAdd = 0;
            if (actualSlot >= lenModArr) {
                const slotsNeeded = actualSlot - lenModArr + 1;
                numIOBasesToAdd = Math.ceil(slotsNeeded / 4);
                // Add any I/O bases we need via array length...
                const slotsToAdd = numIOBasesToAdd * 4;
                for (let idxAdd = 0; idxAdd < slotsToAdd; ++idxAdd)
                    chassis.modules.push(undefined);
            }


            chassis.modules[actualSlot] = module;
            module.parent = chassis;
            addRequiredAccys(module);

            for (let cnt = 1; cnt < module.slotsUsed; ++cnt) {
                const placeholder = _createMultiSlotModPlaceholder(module);
                const idxPlaceHolder = actualSlot + cnt;
                chassis.modules[idxPlaceHolder] = placeholder;
            }

            // TODO_FLEXHA BANKEXT - remove _assignSlotIDs()
            // and set slot index and ID Above.
            _assignSlotIDs(chassis);

            updateChassisLayout(chassis);

            return true;
        }
    }
    return false;
}

const flexHADeleteModuleAtSlot = (chassis: Chassis, slot: number): boolean => {
    const lenModArr = chassis.modules.length;
    if (slot < 0 || slot >= lenModArr)
        return false;

    const module = chassis.modules[slot];
    if (module) {
        // Handle any related undo/redo work.
        chassisChanging(chassis);

        // Make sure the module doesn't have
        // a ref back to our chassis.
        module.parent = undefined;

        // Set slot content to undefined.
        chassis.modules[slot] = undefined;

        // Cleanup any placeholders. Get the index
        // of the first module with the target ID (if any).
        const idxStart = chassis.modules.findIndex(mod => mod && mod.id === module.id);
        if (idxStart >= 0) {
            for (let idx = idxStart; idx < lenModArr; ++idx) {
                // Any placeholders will have the same id
                // as the parent - Remove them.
                const mod = chassis.modules[idx];
                if (mod) {
                    if (mod.id !== module.id)
                        break;
                    mod.parent = undefined;
                    chassis.modules[idx] = undefined;
                }
                else if(idx > slot)
                    break;
            }
        }

        // TODO_FLEXHA BANKEXT - remove _assignSlotIDs() call.
        _assignSlotIDs(chassis);

        updateChassisLayout(chassis);

        // Success.
        return true;
    }

    return false;
}


const flexHAGetSlotFillerInfo = (chassis: Chassis): [string, boolean] => {
    if (chassis.platform !== PlatformFlexHA)
        throw new Error(`flexHAGetSlotFillerInfo(): Unexpected chassis platform (${chassis.platform})`);
    return ['5015-N2IOXT', true];
}

const flexHAGetChassisRenderer = (): React.FC<ChassisCompProps> | undefined => {
    return FlexHAChassisComp;
}

const flexHAUpdateChassisLayout = (chassis: Chassis) => {
    // If undo snapshots are currently suspended, so
    // are layout updates. Just return in that case.
    if (areUndoSnapshotsSuspended()) {
        return;
    }

    // Make any adjustments to the chassis.
    const flexHAChassis = chassis as FlexHAChassis;
    _adjustModifiedChassis(flexHAChassis);

    flexHALayoutChassis(chassis);
}


// flexHAGetChassisAvailableSlots()
// From the returned maxDup & maxSimp numbers, the number
// of Simplex Only Slots will be (maxSimp - (2 * maxDup)).
// Simplex Only Slots will have Even number slot indexes 
// with a module in the previous slot.
const flexHAGetChassisMaxModuleAdditions = (
    chassis: Chassis,
    restrict: ModuleSlotRestriction,
    treatSlotFillerAsEmptySlot: boolean
): [maxDuplex: number, maxSimplex: number] => {

    let maxDuplex = 0;
    let maxSimplex = 0;

    if (restrict === ModuleSlotRestriction.FirstSlotOnly) {
        maxSimplex = chassis.modules[0] ? 0 : 1;
        return [maxDuplex, maxSimplex];
    }

    const [arrDuplex, arrSimplexOnly] = flexHAGetChassisSlotBreakdown(chassis, treatSlotFillerAsEmptySlot);
    maxDuplex = arrDuplex.length;

    // max Simplex will be maxDuplex * 2 + any SimplexOnly slots.
    maxSimplex = (maxDuplex * 2) + arrSimplexOnly.length;

    return [maxDuplex, maxSimplex];
}


// flexHAGetChassisSlotBreakdown()
// returns the indexes of Duplex and 
// Simplex Only slots. 
const flexHAGetChassisSlotBreakdown = (
    chassis: Chassis,
    treatSlotFillerAsEmptySlot: boolean
): [duplexSlots: number[], simplexOnlySlots: number[] ] => {

    const duplexSlots: number[] = [];
    const simplexOnlySlots: number[] = [];

    // TODO_FLEXHA BANKEXT - remove nBankExtKit
    let nBankExtKit = 0;
    const lenModArr = chassis.modules.length;
    for (let idx = 1; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];

        if (mod && mod.isInterconnect) {
            nBankExtKit++;
            continue;
        }

        if (!mod || (treatSlotFillerAsEmptySlot && mod.slotFiller)) {
            // If the I/O slot is ODD, it is a potential
            // duplex module opening.
            const trueIdx = idx - nBankExtKit;
            const potentialDuplex = (trueIdx % 2 !== 0);

            // If not a duplex capable slot...
            if (!potentialDuplex) {
                // If the previous slot is occupied...
                const prevMod = chassis.modules[idx - 1];
                if (prevMod) {
                    // If it is a slot filler, this slot
                    // will NOT be a Simplex Only Slot.
                    if (prevMod.slotFiller && treatSlotFillerAsEmptySlot)
                        continue;
                    
                    simplexOnlySlots.push(idx);
                }
                continue
            }

            // Check the next slot - there should
            // always be one!
            const nextMod = chassis.modules[idx + 1];
            if (!nextMod || (treatSlotFillerAsEmptySlot && nextMod.slotFiller)) {
                duplexSlots.push(idx);
            }
            else
                simplexOnlySlots.push(idx);
        }
    }

    // Now check how many extra I/O bases we can add.
    for (let idx = lenModArr; idx < maxFlexHAChassisSize + nBankExtKit; idx += 2)
        duplexSlots.push(idx);

    return [duplexSlots, simplexOnlySlots];
}


const flexHAGetMaxNewModules = (chassis: Chassis, restrict: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {
    if (restrict === ModuleSlotRestriction.FirstSlotOnly) 
        return (chassis.modules[0] ? 0 : 1);

    // Since we can ONLY add Simplex
    // modules via the Add Module Dlg...
    const [ , maxSimplex] = flexHAGetChassisMaxModuleAdditions(chassis, restrict, treatSlotFillerAsEmptySlot);
    return maxSimplex;
}


const flexHACanExtendChassis = (chassis: Chassis) => {
    // TODO_FLEXHA BANKEXT 
    //const maxSlots = maxFlexHAChassisSize + flexHAGetBankExtKitCount(chassis);
    //return (maxSlots > chassis.modules.length);

    return (maxFlexHAChassisSize > chassis.modules.length);
}


const flexHAGetChassisSizeAsDrawn = (chassis: Chassis, copyMode: boolean): Size => {

    // Get platform details.
    const dtls = _platformDetails;
    if (!dtls)
        return { height: 0, width: 0 };

    // Start with assumption that we're NOT
    // including a 'X' (extra empty) slot.
    let extWidth = 0;

    const xWidth =
        Math.max(dtls.defaultXSlotWidth * dtls.imgScaleFactor, chassis.xSlotWidth);

    // If we can extend the chassis... Note: Other 
    // 'snap' platforms always allow module move 
    // within the same chassis to the Extended Slot, 
    // but FlexHA does NOT.
    if (flexHACanExtendChassis(chassis)) {
        // We will if the chassis is a drag target AND it has
        // an xSlotWidth already. Note, however, that the default
        // xSlotWidth set on the chassis is the size of a module,
        // and WE want the size of the base.
        if (chassis.dragTarget && chassis.xSlotWidth > 0) {
            extWidth = xWidth;
        }
        else {
            // Otherwise, if we are in copy mode or selected...
            if (chassis.selected || copyMode) {
                extWidth = xWidth;
            }
        }
    }

    // Id we DO have an 'extra slot' width...
    if (extWidth > 0) {
        // Start with the chassis' full size.
        const sz = { ...chassis.layout.size };

        // Add the specified extra width
        sz.width += extWidth;

        //console.warn('As drawn ext width: ' + sz.width);

        return sz;
    }

    // Otherwise, return the actual size.
    //console.warn('As drawn norm width: ' + chassis.layout.size.width);
    return { ...chassis.layout.size };
}

const _getLastOccupiedSlotLoc = (chassis: Chassis): LocAndSize => {
    const numLocs = chassis.layout.slotLocs.length;
    for (let locIdx = numLocs - 1; locIdx >= 0; locIdx--) {
        const loc = chassis.layout.slotLocs[locIdx];
        if (!isEmptyLoc(loc)) {
            return { ...loc };
        }
    }
    throw new Error('Unexpected ERROR in _getLastOccupiedSlotLoc!');
}

const _getAddCopyActionBtnInfo = (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo => {
    // If the slot requested is 1 to the right
    // of our chassis' last ACTUAL slot...
    const slots = rack.chassis.modules.length;
   if (slotNum === slots) {

        // See if the chassis can be extended
        // with another module. If so...
        if (canExtendChassis(rack.chassis)) {

            // Get platform details...
            const dtls = getPlatformDetails();

            // Get the location of the last actual slot.
            const lastSlotLoc = _getLastOccupiedSlotLoc(rack.chassis);
            offsetLoc(lastSlotLoc, rack.ptOrg);

            // Start our 'x' (extra) slot as a copy of that.
            const xSlotLoc = { ...lastSlotLoc };

            // Place it's left side at the right
            // side of the last real slot.
            xSlotLoc.x += lastSlotLoc.width;

            // Set its width to be the platform's default.
            xSlotLoc.width = dtls.defaultXSlotWidth * dtls.imgScaleFactor;

            // Position the act btn pt inside.
            const pt = getLocCenter(xSlotLoc);
            pt.y += DfltActBtnSpecs.size;

            // And return our btn info.
            return {
                action: action,
                chassis: rack.chassis,
                slot: slotNum,
                ctrPt: pt
            };
        }
        else {
            throw new Error('Invalid extension attempt in flexHAGetActionBtnInfo()::_getAddCopyActionBtnInfo()!');
        }
    }

    const slotLoc = { ...rack.chassis.layout.slotLocs[slotNum] };
    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.size;
    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt
    };
}

const _getGeneralActionBtnInfo = (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo => {
    // 
    const slotLoc = { ...rack.chassis.layout.slotLocs[slotNum] };
    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.size;
    if (action === LayoutActionType.MakeDuplex ||
        action === LayoutActionType.MakeSimplex) {
        // Move the button to the top quarter
        // of the slot.
        pt.y -= (slotLoc.height / 4);
    }

    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt,
    };
}


export const flexHAGetActionBtnInfo = (action: LayoutActionType,
    rack: Rack, slotNum: number): ActBtnInfo => {

    // Note: We allow the slot number to be the
    // 'extension slot' index, but NOT beyond.
    const slots = rack.chassis.modules.length;
    if (slotNum > slots) {
        throw new Error('flexHAGetActionBtnInfo(): Invalid slot number passed in!');
    }

    // These may include the Extension Slot
    if (action === LayoutActionType.AddModule || 
        action === LayoutActionType.ModeCopy) 
        return _getAddCopyActionBtnInfo(action, rack, slotNum);

    // Any other actions that are module specific.
    return _getGeneralActionBtnInfo(action, rack, slotNum);
}


const _getNextCatalog = (arrCatalogs: string[]): string | undefined => {
    let catalog: string | undefined = '';
    while (!catalog && arrCatalogs.length > 0) {
        catalog = arrCatalogs.pop();
    }
    return catalog;
}

const _EndAddModulesToChassis = (chassis: Chassis, wereSuspended: boolean) => {
    suspendUndoSnapshots(wereSuspended);
    updateChassisLayout(chassis);
}

export const flexHAAddModulesToChassis = (chassis: Chassis,
    sels: SelectedCompInfo[],
    totalQty: number,
    targetSlot: number) => {

    const arrCatalogs: string[] = [];
    sels.forEach((sel) => {
        if (sel.catNo && sel.catNo.length > 0) {
            for (let cnt = 0; cnt < sel.quantity; ++cnt) {
                arrCatalogs.push(sel.catNo);
            }
        }
    });

    if (arrCatalogs.length === 0)
        return;

    let nextCatalog = _getNextCatalog(arrCatalogs);
    if (!nextCatalog)
        return;

    chassisChanging(chassis);
    const wereSuspended = suspendUndoSnapshots(true);

    // Fill the target slot first.
    const mod: ChassisModule | undefined = chassis.modules[targetSlot];
    if (!mod || mod.slotFiller) {
        addModuleAtSlot(chassis, nextCatalog, targetSlot, true);
    }

    const lenExistingSlots = chassis.modules.length;
    // Fill any empty slots
    for (let idx = 1; idx < lenExistingSlots; ++idx) {
       if (chassis.modules[idx] == null) {
           nextCatalog = _getNextCatalog(arrCatalogs);
           if (!nextCatalog) {
               _EndAddModulesToChassis(chassis, wereSuspended);
               return;
           }

           addModuleAtSlot(chassis, nextCatalog, idx, true);
        }
    }

    // Fill any slot fillers - Maybe we extend
    // the chassis first, then replace slot fillers(?)
    for (let idx = 1; idx < lenExistingSlots; ++idx) {
        if (chassis.modules[idx]?.slotFiller) {
            nextCatalog = _getNextCatalog(arrCatalogs);
            if (!nextCatalog) {
                _EndAddModulesToChassis(chassis, wereSuspended);
                return;
            }

            addModuleAtSlot(chassis, nextCatalog, idx, true);
        }
    }

    // Add any mods left by expanding the chassis.
    // TODO_FLEXHA - BANKEXT
    //const maxModules = maxFlexHAChassisSize + flexHAGetBankExtKitCount(chassis);
    const maxModules = maxFlexHAChassisSize;
    for (let idx = lenExistingSlots; idx < maxModules; ++idx) {
        nextCatalog = _getNextCatalog(arrCatalogs);
        if (!nextCatalog) {
            _EndAddModulesToChassis(chassis, wereSuspended);
            return;
        }

        addModuleAtSlot(chassis, nextCatalog, idx, true);
    }

    _EndAddModulesToChassis(chassis, wereSuspended);
}


const _getNonFillerOccupant = (chassis: Chassis, slotNum: number):
    ChassisModule | undefined => {

    const occupant = chassis.modules[slotNum];
    if (occupant && !occupant.slotFiller) {
        return occupant;
    }

    return undefined;
}

// Helper used when determining drop status while dragging and when
// actually dropping a module. Caller provides a slot number, which
// is the actual slot offset under the drag (or drop) point, and a
// bool telling us whether whatever's being dragged is a duplex module
// or not. The function returns an array guaranteed to contain either
// 1 or 2 slot numbers (offsets), 2 IFF the forDuplex argument is true.
// For non-duplex cases, the array returned will just contain the
// slot requested. For a duplex, the array will contain two entries. The
// first will be an odd-numbered slot and the second will be the even
// numbers slot immediately following it.
// Examples for duplex cases:
//   1. If slot 3 is requested, the return will be [3, 4].
//   2. If slot 4 is requested, the return will be [3, 4].
const _getDropTargetSlots = (slotRequested: number, forDuplex: boolean)
    : number[] => {

    const targetSlots = new Array<number>();
    targetSlots.push(slotRequested);

    if (forDuplex) {

        // Add the 'other slot' that we'd use to our
        // our targets. If we started with an 'even'
        // numbered slot, we add the slot to the LEFT
        // of it to the START of our targets array. If
        // 'odd', we add the slot to the RIGHT of it
        // to the END of the array.

        // TODO_FLEXHA BANKEXT - if bank ext kits are 
        // in the module array, this will need to CHANGE.
        if ((slotRequested % 2) === 0) {
            targetSlots.unshift(slotRequested - 1);
        }
        else {
            targetSlots.push(slotRequested + 1);
        }
    }

    return targetSlots;
}

export const flexHAGetChassisDropStatus = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slotNum: number
): DropStatus => {

    // Immediately disqualify if either:
    //  1. Module being dragged and chassis
    //     aren't of the same platform.
    //  2. What we're touching isn't a slot.
    if ((dragInfo.dragMod.platform !== chassis.platform) ||
        (touchEl !== ChassisElement.Slot)) {
        return DropStatus.NoDrop;
    }

    // Do not show the insert.
    dragInfo.showInsert = false;

    // If the slot is beyond the max...
    // TODO_FLEXHA BANKEXT - remove nBankExtKits
    //const nBankExtKits = flexHAGetBankExtKitCount(chassis);
    const nBankExtKits = 0;
    if (slotNum >= maxFlexHAChassisSize + nBankExtKits)
        return DropStatus.NoDrop;

    // If the slot is beyond our current mod array length,
    // we should be good. 
    if (slotNum >= chassis.modules.length) 
        return DropStatus.DropOk;

    // See if the module being dragged is a duplex (2 slots).
    const duplexMod = (dragInfo.dragMod.slotsUsed === 2);

    // Call a helper to get the actual slot(s) we 
    // should target. See comments there for more info.
    const targetSlots = _getDropTargetSlots(slotNum, duplexMod);

    // If we're dropping a module on itself, regardless
    // of whether its a slot filler or not...
    if (chassis.modules[targetSlots[0]] === dragInfo.dragMod) {
        // Return true if we're NOT copying and
        // false if we ARE. For status purposes, a
        // MOVE to the same location should show OK.
        return dragInfo.copy ? DropStatus.NoDrop : DropStatus.DropOk;
    }

    // Get the occupant of the first (leftmost or only)
    // target slot that is NOT a slot filler.
    const occupant = _getNonFillerOccupant(chassis, targetSlots[0]);

    // If there is one...
    if (occupant) {
        // Target slot is occupied. The drag
        // module can't be moved or copied here.
        return DropStatus.NoDrop;
    }
    else {
        // First (or only) slot wasn't occupied.
        // If we're dragging a duplex module...
        if (duplexMod) {
            // Then, we CAN'T drop if the SECOND slot
            // that would be used is occupied.
            if (_getNonFillerOccupant(chassis, targetSlots[1])) {
                return DropStatus.NoDrop;
            }
        }
    }

    // If we're still here, target slot(s) we'd
    // use should be available. A move or copy
    // here should be OK.
    return DropStatus.DropOk;
}

const flexHADropDragDeviceOnChassis = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    requestedSlot: number
): DropResult => {

    // If we're called, a drop SHOULD be possible.
    // If all prequalifications are met...
    if ((dragInfo.dropStatus !== DropStatus.NoDrop) &&
        (chassis.platform === dragInfo.dragMod.platform) &&
        (touchEl === ChassisElement.Slot)) {

        // See if the module being dragged is a duplex (2 slots).
        const duplexMod = (dragInfo.dragMod.slotsUsed === 2);

        // Call a helper to get the actual slot(s) we 
        // should target. See comments there for more info.
        const targetSlots = _getDropTargetSlots(requestedSlot, duplexMod);

        // If we're dropping a module on itself, regardless
        // of whether its a slot filler or not, 
        if (chassis.modules[targetSlots[0]] === dragInfo.dragMod) {

            // Sanity check. The only valid case (where we have
            // a DropOK status) is the drag was a MOVE (not a copy).
            if (dragInfo.copy) {
                throw new Error('ERROR: Copy on self in flexHADropDragDeviceOnChassis!');
            }

            // Drag was a move to the module's original location.
            // The failed return here just indicates that we
            // didn't actually do anything, but this is
            // a valid case.
            return DropResult.DropFailed;
        }

        // We KNOW now that we DON'T have a drop-on-self case.
        // See if the first target slot is occupied
        // by anything OTHER than a slot filler.
        const slotOccupant = _getNonFillerOccupant(chassis, targetSlots[0]);

        // It should NOT be if we're still here. If it is, error out.
        if (slotOccupant) {
            throw new Error('ERROR: Invalid drop in flexHADropDragDeviceOnChassis!');
        }

        // Get the dragged module's parent chassis.
        const sourceChassis = dragInfo.dragMod.parent as Chassis;

        // It should ALWAYS have one, but fail out if not.
        if (!sourceChassis) {
            return DropResult.DropFailed;
        }

        // We should only be called when a drop has
        // been pre-qualified. As such, we expect to
        // actually succeed here.
        // Start by calling a helper to handle any
        // related undo/redo work for us.
        chassisChanging(chassis);

        // Then temporarily suspend any
        // interim undo/redo snapshots.
        const wereSuspended = suspendUndoSnapshots(true);

        // Remove any slot fillers...
        let abort = false;
        targetSlots.forEach(slot => {
            const destMod = chassis.modules[slot];
            if (destMod) {
                if (destMod.slotFiller) {
                    chassis.modules[slot] = undefined;
                }
                else {
                    logger.error('ERROR: Target slot found occupied in flexHADropDragDeviceOnChassis');
                    abort = true;
                }
            }
        });

        // Bail if we encountered a
        // NON-slotfiller occupant above.
        if (abort) {
            suspendUndoSnapshots(wereSuspended);
            return DropResult.DropFailed;
        }

        // Use the FIRST of our target slots. If our dragged
        // module is duplex, that slot num will ALWAYS be odd.
        const slotTarget = targetSlots[0];

        // If copying...
        if (dragInfo.copy) {
            // Add a new module at the target slot.
            const success = addModuleAtSlot(chassis, dragInfo.dragMod.catNo, slotTarget, true);
            suspendUndoSnapshots(wereSuspended);
            updateChassisLayout(chassis);
            return (success ? DropResult.DropSuccess : DropResult.DropFailed);
        }

        // We're MOVING the existing module
        // to the specified location. Start
        // by detaching it from its old location.
        detachModule(dragInfo.dragMod);

        // Then insert it into the specified chassis
        // at the specified slot location.
        chassis.modules[slotTarget] = dragInfo.dragMod;

        // Record that slot location in the module itself.
        dragInfo.dragMod.slotIdx = slotTarget;
        dragInfo.dragMod.slotID = slotTarget;

        // And set its new parent chassis.
        dragInfo.dragMod.parent = chassis;

        // Add placeholder module as needed.
        for (let cnt = 1; cnt < dragInfo.dragMod.slotsUsed; cnt++) {
            const placeholder = _createMultiSlotModPlaceholder(dragInfo.dragMod);
            const phSlot = slotTarget + cnt;
            chassis.modules[phSlot] = placeholder;
            placeholder.slotIdx = phSlot;
        }

        // Re-enable snapshots.
        suspendUndoSnapshots(wereSuspended);

        // A return of success will result in the target
        // chassis' layout getting updated. If the source
        // of the drag was a different chassis, we'll
        // update that one ourselves here.
        if (sourceChassis !== chassis) {
            updateChassisLayout(sourceChassis);
        }

        // Success.
        return DropResult.DropSuccess;
    }

    throw new Error('Unexpected call to flexHADropDragDeviceOnChassis?');
}


// flexHADoesSlotQualifyForBtnAction() - Unlike other platforms (so far),
// Flex HA has duplex modules. Certain actions do NOT make sense for the
// duplex modules, which require 2 slots starting at an odd slot index.
const flexHADoesSlotQualifyForBtnAction = (
    mode: LayoutMode,
    type: LayoutActionType,
    chassis: Chassis,
    slotNum: number): boolean => {

    if (chassis.platform !== PlatformFlexHA)
        throw new Error('flexHADoesSlotQualifyForBtnAction(): Invalid Platform!');

    if (type === LayoutActionType.ModeCopy) {
        if (mode.engInfo && mode.engInfo.isModule) {
            // If the module to copy occupies more than 1 slot...
            if ((mode.engInfo as EngInfoModule).slotsUsed > 1) {
                if (!flexHADoesSlotQualifyForCopy(chassis, mode.engInfo as EngInfoModule, slotNum)) {
                    return false;
                }
            }
        }
    }
    else if (type === LayoutActionType.MakeDuplex) {
        // Must be an odd slot number
        if ((slotNum % 2) !== 1)
            return false;

        // Must have a simplex mod in primary slot.
        const mod = chassis.modules[slotNum];
        if (!mod || mod.slotFiller || mod.slotsUsed > 1)
            return false;

        // Must have a simplex in secondary slot.
        const nextMod = chassis.modules[slotNum + 1];
        if (!nextMod || nextMod.slotFiller)
            return false;
    } 
    else if (type === LayoutActionType.MakeSimplex) {
        // Must be an odd slot number
        if ((slotNum % 2) !== 1)
            return false;

        // Must have a Duplex mod in primary slot.
        const mod = chassis.modules[slotNum];
        if (!mod || mod.slotsUsed < 2)
            return false;
    } 

    return true;
}


const flexHAGetNumIOBases = (chassis: Chassis): number => {

    // Slot 0 reserved for adapter kit. Remaining
    // slots are used for I/O modules.
    // TODO_FLEXHA BANKEXT 
    //const numModSlots = chassis.modules.length - 1 - flexHAGetBankExtKitCount(chassis);
    const numModSlots = chassis.modules.length - 1;

    // Sanity checks. Mod slot qty should be multiple
    // of 4, and not exceed 24 slots (6 4-slot bases).
    if ((numModSlots % 4 === 0) && (numModSlots <= 24)) {
        return (numModSlots / 4);
    }

    throw new Error('Unexpected ERROR in flexHAGetNumIOBases')
}

const flexHAIsPointOnPS = (ptLocal: Point, chassis: Chassis): boolean => {
    if (chassis.ps) {
        const sizeDtls = getFHASizeDetails();
        if (isPointInLoc(ptLocal, sizeDtls.psuLoc1)) {
            return true;
        }
        else {
            return isPointInLoc(ptLocal, sizeDtls.psuLoc2);
        }
    }
    return false;
}

// NOTE: getXSlotWidthFor returns the x-slot width we COULD
// have IFF the chassis that cares can actually ACCEPT another
// module. This function may still return a non-zero value
// even if the chassis is already at its module capacity!
const flexHAGetXSlotWidthFor = (modInfo: EngInfoComponent): number => {
    const restrict = getModuleSlotRestriction(modInfo);
    if (restrict === ModuleSlotRestriction.FirstSlotOnly) {
        return 0;
    }

    const sizeDtls = getFHASizeDetails();
    return sizeDtls.ioBaseInfo.baseSize.width;
}

const flexHAGetXSlotLoc = (chassis: Chassis): LocAndSize => {

    // Start with an empty loc.
    const xSlotLoc = getEmptyLoc();

    // If the chassis currently qualifies...
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {

        // Get its layout.
        const layout = chassis.layout as FlexHALayoutInfo;

        // See how many I/O bases it has, if any.
        const numIOBases = layout.ioBaseLocs.length;

        // If any...
        if (numIOBases > 0) {
            // Position the x-slot loc to the right
            // of the last one.
            const lastIOBaseLoc = layout.ioBaseLocs[numIOBases - 1];
            xSlotLoc.x = lastIOBaseLoc.x + lastIOBaseLoc.width;
            xSlotLoc.y = lastIOBaseLoc.y;
            xSlotLoc.width = lastIOBaseLoc.width;
            xSlotLoc.height = lastIOBaseLoc.height;
        }
        else {
            // No I/O bases. Get general sizing details.
            const sizeDtls = getFHASizeDetails();

            // Position the x-slot loc to the right of
            // the adapter kit.
            xSlotLoc.x = sizeDtls.adapterKitLeft + sizeDtls.sizeAdapterKit.width;
            xSlotLoc.y = 0;
            xSlotLoc.width = sizeDtls.ioBaseInfo.baseSize.width;
            xSlotLoc.height = sizeDtls.ioBaseInfo.baseSize.height;
        }
    }

    // Return whatever we end up with.
    return xSlotLoc;
}

const flexHAGetImageSource = (category: DeviceCategory, imgName: string): string => {

    const ucname = imgName.toUpperCase();
    if (Use5015TransparentImgs) {
        switch (ucname) {
            case '5015-A4IOKIT.PNG':
                return `${configSpec.ISD_URL}/assets/flexHA/ALT_5015-A4IOKIT.png`;

            case '5015-U8IHFT_SIMPLEX.PNG':
                return `${configSpec.ISD_URL}/assets/flexHA/ALT_5015-U8IHFT_Simplex.png`;

            case '5015-U8IHFT_DUPLEX.PNG':
                return `${configSpec.ISD_URL}/assets/flexHA/ALT_5015-U8IHFT_Duplex.png`;

            case '5015-N2IO.PNG':
                return `${configSpec.ISD_URL}/assets/flexHA/ALT_5015-N2IO.png`;

            default:
                break;
        }
    }
    else {
        switch (ucname) {
            case '5015-U8IHFT_DUPLEX.PNG':
                return `${configSpec.ISD_URL}/assets/flexHA/DARK_5015-U8IHFT_Duplex.png`;

            default:
                break;
        }
    }
    return getDefaultImageSource(imgName);
}


const flexHAImpl: GeneralImplSpec = {
    platform: PlatformFlexHA,
    //imageScaleFactor: _flexHAImageScaleFactor,
    imageScaleFactor: 1.0,

    //replaceChassisPowerSupply?: _implReplaceChassisPowerSupply,
    //getNumSlots?: _implGetNumSlots;
    //getChassisSlotUsage?: _implGetChassisSlotUsage;
    //getSlotID?: _implGetSlotID;
    //createModule?: _implCreateModule;
    //addModuleToChassis?: _implAddModuleToChassis;
    ////////////////addNewModuleAtSlot?: _implAddNewModuleAtSlot; /// Obsolete - remove
    //onChassisLoaded?: _implOnChassisLoaded;
    //getImageSource?: _implGetImageSource;
    //getChassisElementAtPt?: _implGetChassisElementAtPt;
    //duplicateChassis?: _implDuplicateChassis;
    //getChassisDetails?: _implGetChassisDetails;
    //getDeviceDetails?: _implGetDeviceDetails;
    //getPowerDetails?: _implGetPowerDetails;
    //getSlotLocation?: _implGetSlotLocation;

    createChassis: flexHACreateChassis,
    addModuleAtSlot: flexHAAddModuleAtSlot,
    canModuleBeAdded: flexHACanModuleBeAdded,
    deleteModuleAtSlot: flexHADeleteModuleAtSlot,
    getSlotFillerInfo: flexHAGetSlotFillerInfo,
    getChassisRenderer: flexHAGetChassisRenderer,
    updateChassisLayout: flexHAUpdateChassisLayout,
    canExtendChassis: flexHACanExtendChassis,
    getChassisSizeAsDrawn: flexHAGetChassisSizeAsDrawn, 
    getDefaultXSlotWidth: flexHAGetDefaultXSlotWidth, 
    getActBtnInfo: flexHAGetActionBtnInfo,
    addModulesToChassis: flexHAAddModulesToChassis,
    getChassisDropStatus: flexHAGetChassisDropStatus,
    dropDragDeviceOnChassis: flexHADropDragDeviceOnChassis,
    getMaxNewModules: flexHAGetMaxNewModules, 
    getEmptySlotImage: flexHAGetEmptySlotImage,
    doesSlotQualifyForBtnAction: flexHADoesSlotQualifyForBtnAction,
    getNumIOBases: flexHAGetNumIOBases,
    isPointOnPS: flexHAIsPointOnPS,
    getXSlotLoc: flexHAGetXSlotLoc,

    getImageSource: flexHAGetImageSource,

    // Forward to Snap.
    getSlotTypeRestriction: snapGetSlotTypeRestriction,
    filterAvailableModules: snapFilterAvailableModules,
    getDefaultChassisName: snapGetDefaultChassisName,
    getModuleSlotRestriction: snapGetModuleSlotRestriction,
    //getXSlotWidthFor: snapGetXSlotWidthFor,
    getXSlotWidthFor: flexHAGetXSlotWidthFor,
    configureChassis: snapConfigureChassis,
}


export const RegisterFlexHAGeneralImpl = () => {
 
    // We ONLY need this if we are going to 
    // leverage "Snap" General Impl functions.
    RegisterSnapClientDetails(PlatformFlexHA, getPlatformDetails());
    RegisterGeneralImpl(flexHAImpl);
}
