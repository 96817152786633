import { onChassisLoaded } from "../implementation/ImplGeneral";
import {
    chassisChanged,
    getModuleSlotBreakdown,
    updateRackLayout
} from "../model/ChassisProject";
import {
    Chassis,
    ChassisProject,
    ModuleDragStatus,
    RackGroup,
    SelectableDevice
} from "../types/ProjectTypes";
import { unexpectedError } from "./ErrorHelp";

const _maxUndos = 10;

const _undoSnaps: string[] = new Array<string>();
const _redoSnaps: string[] = new Array<string>();


const _undoSnapshotPropReplacer = (key: string, value: unknown) => {
    switch (key) {

        // Properties that either could cause circular
        // issues, and/or any whose data is recreated anyway
        // whenever our JSON is reloaded should be set to
        // null.
        case 'parent':
        case 'statusLog':
        case 'ctlrCommsSpt':
        case 'remCommsReqd':
            return null;

        case 'dragTarget':
            return false;

        case 'xSlotWidth':
            return 0;

        // WCS - selected props now retain their value so that
        // we can recover previous chassis and/or device selections
        // after an undo or redo. Any prop replacer used for
        // longer-term storage (like saving to a server) should
        // reset any selected prop to false.
        case 'selected':
            //    return false;
            return value;

        case 'dragStatus':
            return ModuleDragStatus.NA;

        default:
            break;
    }

    return value;
}

const rackGroupToJSON = (group: RackGroup): string => {
    // Stringify the ChassisGroup object into a string
    // in JSON format, using a 2-space indent spec.
    const json = JSON.stringify(group, _undoSnapshotPropReplacer, 2);
    return json;
}

const jsonToRackGroup = (json: string): RackGroup | null => {
    // Parse the JSON into an object.
    const ob = JSON.parse(json);

    // See if we can get it as a RackGroup object.
    const group = ob as RackGroup;

    // If we can...
    if (group) {

        // Allow each chassis to do any internal modification
        // of properties (like parent properties, which were
        // all set to null when we created the json from the
        // group object.
        group.racks.forEach(rack => {
            rack.chassis.parent = group;
            onChassisLoaded(rack.chassis);
            chassisChanged(rack.chassis);
        });

        // Call a helper to update our layout.
        updateRackLayout(group);

        // Return the group.
        return group;
    }
    else {
        unexpectedError('Attempt to convert JSON to ChassisGroup FAILED!', true, false);
        return null;
    }
}

// Helper. Adds incoming snapshot to our undo array.
// Then, if the resulting array size is greater than
// our max size, trims off oldest snapshots.
const _addUndoSnapshot = (jsonSnap: string) => {
    _undoSnaps.push(jsonSnap);
    while (_undoSnaps.length > _maxUndos) {
        _undoSnaps.shift();
    }
}


let _undoSnapshotsSuspended = false;

export const areUndoSnapshotsSuspended = (): boolean => {
    return _undoSnapshotsSuspended;
}

// Use to suspend (or re-enable) the normal snapshots we
// normally take as content changes for use by our undo/
// redo system. For example, when we auto-generate hardware,
// we want to suspend. If we don't, we get a separate undo-able
// snapshot for each chassis and module added.
export const suspendUndoSnapshots = (suspend: boolean): boolean => {
    const wasSuspended = _undoSnapshotsSuspended; 
    _undoSnapshotsSuspended = suspend;
    return wasSuspended;
}

export const contentChanging = (content: RackGroup) => {
    // Our project content IS GOING TO change.
    // If undo snapshots aren't currently suspended...
    if (!_undoSnapshotsSuspended) {

        // Take a snapshot of our content BEFORE the change, and
        // add it to out undo array. Note that the _add helper
        // here does that for us, as well as making sure that
        // that array doesn't grow too large.
        const snap = rackGroupToJSON(content);
        _addUndoSnapshot(snap);

        // Clear our any redos every time we
        // add a new snap to our undos because
        // our content IS GOING TO change.
        _redoSnaps.length = 0;
    }
 }

export const chassisChanging = (chassis: Chassis, bump = true) => {

    // We don't do ANYTHING if snapshots are currently
    // suspended. It that is NOT the case...
    if (!_undoSnapshotsSuspended) {

        // The chassis SHOULD have a parent. If so...
        if (chassis.parent) {

            // Call contentChanging, which will
            // take a snapshot of our entire content
            // for undo purposes.
            contentChanging(chassis.parent);

            // Unless we were told NOT to, call our
            // chassisChanged helper.
            // Note that we need to do this AFTER the
            // undo snapshot.
            if (bump) {
                chassisChanged(chassis);
            }
        }
        else {
            // Unexpected
            throw new Error('chassisChanging got chassis without parent RackGroup!');
        }
    }
}

export const canUndo = (): boolean => {
    return (_undoSnaps.length > 0);
}

export const canRedo = (): boolean => {
    return (_redoSnaps.length > 0);
}

// Find the the device and/or chassis that had their
// .selected property set in the incoming RackGroup, which
// was JUST loaded from JSON via undo or redo.
const findSelections = (rackGroup: RackGroup):
    [selChassis: Chassis | undefined, selDevice: SelectableDevice | undefined] => {

    // For each rack in the group...
    for (let rackIdx = 0; rackIdx < rackGroup.racks.length; rackIdx++) {

        // Get the associated chassis.
        const chassis = rackGroup.racks[rackIdx].chassis;

        // If a module is selected, the chassis should ALSO
        // be selected, so we'll look at the chassis first. 
        // If IT's selected...
        if (chassis.selected) {

            // Get the slots in the chassis that have modules in them.
            const [modSlots,] = getModuleSlotBreakdown(chassis.modules);

            // For each...
            for (let idx = 0; idx < modSlots.length; idx++) {

                // Get the slot number.
                const slot = modSlots[idx];

                // And the associated module.
                const module = chassis.modules[slot];

                // If it's selected, we have both.
                // Return what we got.
                if (module && module.selected) {
                    return [chassis, module];
                }
            }

            // If we get here, we found a selected chassis,
            // but NOT a selected module. Note that this
            // MIGHT not really be the correct answer for
            // a selected device, since a POWER SUPPLY can
            // ALSO be selected. Will need a bit more work
            // to find that in a general way as well.
            return [chassis, undefined];
        }
    }

    // No chassis was selected, which means that
    // no device should be able to either.
    return [undefined, undefined];
}

export const undoLastRackChange = (project: ChassisProject):
    [selChassis: Chassis | undefined, selDevice: SelectableDevice | undefined] => {
    // Caller SHOULD have PRE-QUALIFIED that
    // an UNDO was possible. If it is...
    if (_undoSnaps.length > 0) {

        // Take a snapshot of our CURRENT content,
        // and add it to our redo array. 
        const currentContentSnapshot = rackGroupToJSON(project.content);
        _redoSnaps.push(currentContentSnapshot);

        // Pop the last-added snapshot off of our undo
        // array, which should represent what our content
        // was PRIOR to what we currently have.
        const priorContentSnapshot = _undoSnaps.pop();

        // If we can...
        if (priorContentSnapshot) {
            // Convert that to a RackGroup.
            const rackGroup = jsonToRackGroup(priorContentSnapshot);

            // If we can...
            if (rackGroup) {
                // Replace the project's content with it.
                rackGroup.parent = project;
                project.content = rackGroup;
                return findSelections(rackGroup);
            }
        }
    }

    // Nothing we can do.
    throw new Error('Unexpected error in undoLastRackChange!');
}

export const redoLastRackChange = (project: ChassisProject):
    [selChassis: Chassis | undefined, selDevice: SelectableDevice | undefined] => {
    // Caller SHOULD have PRE-QUALIFIED that
    // a REDO was possible. If it is...
    if (_redoSnaps.length > 0) {
        // Take a snapshot of our CURRENT content, and add
        // it to our undo array.Note that the _add function
        // does that for us, as well as making sure that our
        // array doesn't grow too large.
        const currentContentSnapshot = rackGroupToJSON(project.content);
        _addUndoSnapshot(currentContentSnapshot);

        // Pop the last-added snap off of
        // our redo stack.
        const lastRedo = _redoSnaps.pop();

        // If we can...
        if (lastRedo) {

            // Then convert it to a RackGroup.
            const rackGroup = jsonToRackGroup(lastRedo);

            // If we can...
            if (rackGroup) {
                // Replace the project's content with it.
                rackGroup.parent = project;
                project.content = rackGroup;
                return findSelections(rackGroup);
            }
        }
    }

    // Nothing we can do.
    throw new Error('Unexpected error in redoLastRackChange!');
}