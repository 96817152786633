import { getConfigSpec } from "../../../config";
import { convertGuidedSelAttrToAppVal, HardwareGenImplSpec, RegisterHardwareGenImpl } from "../../../implementation/ImplHardwareGen";
import { refreshLocAttrInfoSelectionArray } from "../../../model/GuidedSelection";
import { getUserModuleSelectionsFromPointSectionInfo } from "../../../model/IOModule";
import { collectHardwareInfo, KeyValuePair, ProdSelCategory } from "../../../model/ProductSelection";
import { BOMItem, BOMItemProduct, createBOMItemProduct, IExternalBOM } from "../../../summary/SummaryHelp";
import { DEV_DEBUG } from "../../../types/Globals";
import {
    Chassis,
    ChassisProject,
    DeviceCategory,
    DeviceType,
    EnvRating,
    FlexHAChassis,
    FlexHASAPowerSupply,
    ChassisPowerSupply,
    LocAttributeInfo,
    ModuleDragStatus,
    PSInputVoltage
} from "../../../types/ProjectTypes";
import { Size } from "../../../types/SizeAndPosTypes";
import { addRequiredAccys } from "../../../util/AccessoriesHelp";
import { getModuleEngInfo, getPowerSupplyEngInfo } from "../../../util/EngInfoHelp";
import { getNewInstanceId } from "../../../util/InstanceIdHelp";
import { StageUnitsPerMM } from "../../../util/LayoutHelp";
import { logger } from "../../../util/Logger";
import { genCreateHardwareFromSettings, getCreateHWFromSettingsErrors } from "../../common/HardwareGen";
import { PlatformFlexHA } from "../../PlatformConstants";
import { flexHAGetIOBaseIndexForSlot } from "./FlexHAChassis";
import { flexHAGetLocAttrInfoForChassisEdit } from "./FlexHAGuidedSelection";
import { flexHAGetChassisCatalog, maxFlexHAChassisSize } from "./FlexHALayout";

const configSpec = getConfigSpec();
const _flexHAImgLoc = `${configSpec.ISD_URL}/assets/flexHA/`;

export const flexHASAPSUCatalog = 'SA Power Kit';
export const flexHAMODPSUCatalog = '1606-XLE240ECRZ';
export const flexHASAPSUImage = '5015_SA_Power_2-2.png'; 
export const flexHAMODPSUImage = '5015_System_PS.png';

// There seems to be some variance in actual product dims,
// but all of the images we use to render CLX chassis
// elements are the same height - 280 pixels. And, in all
// cases, the given image's height overlays our entire chassis'
// height. That is, each individual image and the combination
// of all of them comprising a chassis rendering are always
// 280 pixels in height. 
const _flexHAElemImageHt = 280; // pixels TODO_FLEXHA

// Again, there appears to be some minor variance in height dims
// according to product documentation between specific chassis,
// but they're close enough to establish what WE will consider
// to be the (nominal) height of an entire CLX chassis (not
// including any mounting tabs that we don't show). That total
// height, as well as the height of the elements that make UP
// the chassis, are ALL considered to be 145 mm tall.
const _flexHANominalHt = 145;   // millimeters TODO_FLEXHA

// Determine a multiplier we can use to convert a
// number in image-size units to millimeters.
const _flexHAImageSizeToMM = _flexHANominalHt / _flexHAElemImageHt;
const _getSizesInStageUnits = true;

const tempSortCache = new Map<string, number>;
export const flexHAGenHWModArrSortPredicate = (a: string, b: string) => {
    // We want Duplex modules to be added first,
    // then the Simplex Modules. 
    if (a === b)
        return 0;

    let aSlots: number | undefined = tempSortCache.get(a);
    let bSlots: number | undefined = tempSortCache.get(b);

    if (!aSlots) {
        const info = getModuleEngInfo(PlatformFlexHA, a);
        if (info)
            aSlots = info.slotsUsed;
        else
            aSlots = 1;
        tempSortCache.set(a, aSlots);
    }
    if (!bSlots) {
        const info = getModuleEngInfo(PlatformFlexHA, b);
        if (info)
            bSlots = info.slotsUsed;
        else
            bSlots = 1;
        tempSortCache.set(b, bSlots);
    }
        
    if (aSlots > bSlots)
        return -1;

    return 1;
}

export const flexHACreatePowerSupply = (psCat?: string, inputVoltage?: PSInputVoltage): ChassisPowerSupply | FlexHASAPowerSupply | undefined => {
    if (!psCat)
        return undefined;

    const psEngInfo = getPowerSupplyEngInfo(PlatformFlexHA, psCat);
    if (psEngInfo) {
        const psInputVltg = psEngInfo.getInputVoltage(inputVoltage);
        const voltage = (psInputVltg == null ? PSInputVoltage.DC24V : psInputVltg);

        const scaledSize: Size = {
            width: psEngInfo.imgSize.width * _flexHAImageSizeToMM,
            height: psEngInfo.imgSize.height * _flexHAImageSizeToMM
        };
        if (_getSizesInStageUnits) {
            scaledSize.width *= StageUnitsPerMM;
            scaledSize.height *= StageUnitsPerMM;
        }

        // Default to the MOD Pwr (main) PSU. Start
        // by creating a standard PSU.
        const isSAPSU = (psCat === flexHASAPSUCatalog);
        const psuImage = (isSAPSU ? flexHASAPSUImage : flexHAMODPSUImage);

        // Start by creating a standard 'MOD Pwr' 
        // PSU for the chassis.
        const ps: ChassisPowerSupply = {
            id: getNewInstanceId(),
            platform: PlatformFlexHA,
            deviceType: DeviceType.PS,
            catNo: psCat,
            description: psEngInfo.description,
            isPlaceholder: psEngInfo.isPlaceholder,
            extendedTemp: psEngInfo.envInfo.etOk,
            conformal: psEngInfo.envInfo.ccOk,
            accysPossible: psEngInfo.anyAccysPossible(),
            category: DeviceCategory.PS,
            imgSrc: _flexHAImgLoc + psuImage,
            imgSize: scaledSize,
            selected: false,
            movable: false,
            dragStatus: ModuleDragStatus.NA,
            redundant: psEngInfo.redundant,
            loc: {
                x: 0,
                y: 0,
                width: scaledSize.width,
                height: scaledSize.height
            },
            inputVoltage: voltage,
            // Needs to be set by the caller.
            parent: undefined,   
        };

        addRequiredAccys(ps);

        // If we have an SA PSU...
        if (isSAPSU) {
            // Create the SA instance by copying the 'MOD
            // Pwr' PSU and adding the ioBaseIndex prop.
            // Return the SA PSU.
            const saPSU: FlexHASAPowerSupply = { ...ps, ioBaseIndex: -1 };
            return saPSU;
        }

        return ps;
    }
    return undefined;
}

export const flexHAAddInitialSAPSUs = (chassis: Chassis) => {
    const fhaChassis = chassis as FlexHAChassis;
    if (fhaChassis.saPSU == null)
        throw new Error('flexHAAddInitialSAPSUs(): FLEXHA SA Power Supply Array Does Not Exist!');

    // Start fresh - Clear the array;
    const existingPSUs = { ...fhaChassis.saPSU };
    fhaChassis.saPSU.length = 0;

    const hasMods = chassis.modules.some((mod, idxSlot) => idxSlot > 0 && mod && !mod.isInterconnect);
    if (!hasMods) {
        // There are not any I/O bases. We just
        // have the adapter (maybe), therefore
        // we do NOT have any SA Power Supplies.
        return;
    }

    // We need at least one SA PSU on the
    // first I/O Base (index 0).
    let idxExistingPSU = existingPSUs.length - 1;
    let saPSU = (idxExistingPSU >= 0 ? existingPSUs[idxExistingPSU] : flexHACreatePowerSupply(flexHASAPSUCatalog) as FlexHASAPowerSupply);
    if (saPSU) {
        saPSU.ioBaseIndex = 0;
        saPSU.parent = fhaChassis;
        fhaChassis.saPSU.push(saPSU);
        idxExistingPSU--;
    }

    // TODO_FLEXHA BANKEXT - This has to change
    // if Bank Ext are not in the module array.

    // Next add an SA PSU after every
    // Bank Ext Kit (interconnect) we find.
    const lenModArr = chassis.modules.length;
    for (let idx = 4; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];
        // If we have an interconnect and it's not 
        // the last slot.
        if (mod && mod.isInterconnect && idx + 1 != lenModArr) {
            // If the next slot is also an interconnect.
            const nextMod = chassis.modules[idx + 1];
            if (nextMod && nextMod.isInterconnect)
                continue;

            saPSU = (idxExistingPSU >= 0 ? existingPSUs[idxExistingPSU] : flexHACreatePowerSupply(flexHASAPSUCatalog) as FlexHASAPowerSupply);
            if (saPSU) {
                // Get the I/O base index for the 
                // next slot past the IC.
                const idxIOBase = flexHAGetIOBaseIndexForSlot(chassis, idx + 1);
                saPSU.ioBaseIndex = idxIOBase;
                saPSU.parent = fhaChassis;
                fhaChassis.saPSU.push(saPSU);
                idxExistingPSU--;
            }
        }
    }
}

const _prepareLocAttrHardwareForGen = (loc: LocAttributeInfo, project: ChassisProject) => {
    refreshLocAttrInfoSelectionArray(loc);

    const platform = loc.platform;

    // Start our chassis catalog out as 'standard.
    loc.hardware.catChassis = flexHAGetChassisCatalog(EnvRating.Standard);

    // Hardcode necessity: Attribute 'ER' - Env. Rating.
    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er) {
        loc.hardware.envRating = convertGuidedSelAttrToAppVal(platform, er.attrID, er.optionID, true) as EnvRating;
        loc.hardware.catChassis = flexHAGetChassisCatalog(loc.hardware.envRating);
    }

    // Set the controller chassis catalog to the remote.
    loc.hardware.catControllerChassis = undefined;
    loc.hardware.numCtrlChassisSlot = 0;
    // We will never need a comm in a controller chassis.
    loc.hardware.ctrlChassisNeedsScanner = false;

    // Hardcode necessity: Attribute 'CTRL' - defines whether a
    // configuration will be generated with or without a Controller
    loc.hardware.remoteIOOnly = true;

    // Hardcode necessity: Attribute 'CT' - Defines whether I/O
    // will be placed in Controller chassis or if all I/O will
    // be in remote chassis. Value 'Ded' for 'dedicated'. Note:
    // CpLX does not have 'CT' right now but may at some point.
    loc.hardware.ctrlDedicatedChassis = false;

    // We have a redundant remote PSU - set it.
    loc.hardware.catPowerSupply = flexHAMODPSUCatalog;
    loc.hardware.catPwrSupCtrlChassis = undefined;

    // We do not have redundancy
    loc.hardware.redCtrlChassis = false;

    loc.hardware.ioModuleSelections = getUserModuleSelectionsFromPointSectionInfo(loc.pointEntrySection, project.config.IOEntryMode);
    loc.hardware.ioModuleSelections.forEach((sel) => {
        for (let idx = 0; idx < sel.quantity; ++idx)
            loc.hardware.catIOModules.push(sel.catalog);
    });

    loc.hardware.ioModuleCount = loc.hardware.catIOModules.length;

    // For chassis size, 'Auto' will return a default value of 31
    // which is the Max modules allowed in a CpLX "chassis"". Below,
    // when retrieving Controller and Comm, we will take the lesser
    // of chassisSize (set here) and what the Controller/Comm says 
    // it supports. Note: We do NOT add any Bank Ext Kits here.
    let chassisSize = maxFlexHAChassisSize; // slot 0 + up to 6 4-slot I/O bases.
    const cs = loc.arrAttributeNameToValue.find(x => x.attrID === 'CS');
    if (cs)
        chassisSize = Number(convertGuidedSelAttrToAppVal(platform, cs.attrID, cs.optionID, true));

    loc.hardware.numChassisSlot = chassisSize;

    // If we need any additional attributes to get the correct
    // selections, add them here. Currently 'snap' does not need any.
    const additionalAttributes: KeyValuePair[] = [];

    const arrHardware = collectHardwareInfo(loc, additionalAttributes);
    arrHardware.forEach((hwInfo) => {
        switch (hwInfo.category) {
            // We should have only one COMM/
            case ProdSelCategory.Comm:
                {
                    loc.hardware.catScanner = hwInfo.mainCatalog;
                    // The Adapter Kit is the power supplier for the chassis.
                    // TODO_FLEXHA - Verify what supplies power.
                    loc.hardware.catRemotePowerSupplier = loc.hardware.catScanner;
                }
                break;

            case ProdSelCategory.SlotFiller:
                {
                    loc.hardware.catSlotFiller = hwInfo.mainCatalog;
                }
                break;

            default:
                logger.error('Unknown Hardware Category: ' + hwInfo.category);
                break;
        }
    });


    // Validate the hardware - we should always have values for
    // the remote I/O chassis/PSU/Comm
    if (!loc.hardware.catScanner)
        throw new Error('snapPrepareLocAttrHardwareForGen(): Failed to select valid Snap Remote Comm.');

    return;
}

const flexHAsortIOModArrForHWGen = (arrModules: string[], reverseSort: boolean) => {
    // Note: Both sort() and reverse() array functions
    // affect the original array instance (i.e. the
    // source array is affected).
    arrModules.sort(flexHAGenHWModArrSortPredicate);
    if (reverseSort)
        arrModules.reverse();
}

const flexHAAddChassisExtrasToBOM = (
    chassis: Chassis,
    chassisConsComps: Map<string, BOMItemProduct>,
    organizedMainHeader: BOMItem,
    redSecondary: boolean,
    IExtBOM: IExternalBOM) => {

    if (DEV_DEBUG) {
        // TODO_FLEXHA - Test example to add 2 backplates to BOM.
        const backplate = createBOMItemProduct(chassis.platform, '5015-MP1250XT', DeviceType.Other, 2);
        IExtBOM.addComponent(backplate, chassisConsComps);
    }
}

const flexHAFinalizeHardwareGenChassis = (chassis: Chassis) => {
    // We have a new chassis created
    // from the Design Page. Add SA
    // PSUs where needed.
    flexHAAddInitialSAPSUs(chassis);
}

export const RegisterFlexHAHWGenImpl = () => {
    const impl: HardwareGenImplSpec = {
        //// General Functions  //////////////////////////////////////////////
        //    queryIOModules?: _implQueryIOModules;
        //    getIOPointFilterMap?: _implGetIOPointFilterMap;
        //    calcModQtyFromPoints?: _implCalcModQtyFromPoints;
        //    getInitialPointEntryInfo?: _implGetInitialPointEntryInfo;
        //    getIOModuleClosestMatch?: _implGetIOModuleClosestMatch;
        //    createDefaultPointEntry?: _implCreateDefaultPointEntry;
        //    validIOExists?: _implValidIOExists;
        //    getIOFilterMasksFromLoc?: _implGetIOFilterMasksFromLoc;
        //    getDefaultIOModuleCatalog?: _implGetDefaultIOModuleCatalog;
        //    finalizeGuidedSelection?: _implFinalizeGuidedSelection;
        //    convertGuidedSelAttrToAppVal?: _implGuidedSelAttrToAppVal;
        //    convertAppValToGuidedSelAttrOptID?: _implAppValToGuidedSelAttrOptID;
        //    getLocIOWiringTypeSel: GetIOModWiringSelectionForApp,

        platform: PlatformFlexHA,
        createHardwareFromSettings: genCreateHardwareFromSettings,
        getHardwareGenErrors: getCreateHWFromSettingsErrors,
        prepLocAttrHardwareForGen: _prepareLocAttrHardwareForGen,
        getLocAttrInfoForChassisEdit: flexHAGetLocAttrInfoForChassisEdit,
        sortIOModulesForHWGen: flexHAsortIOModArrForHWGen,
        addChassisExtrasToBOM: flexHAAddChassisExtrasToBOM,
        finalizeHardwareGenChassis: flexHAFinalizeHardwareGenChassis,
    }

    RegisterHardwareGenImpl(impl);
}