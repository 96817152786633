import React from 'react';
import Konva from 'konva';
import { Rect, Text } from 'react-konva';
import { LocAndSize, Point } from '../types/SizeAndPosTypes';
import { ScaledChassisLabelSize } from '../util/LayoutHelp';
import { Chassis } from '../types/ProjectTypes';
import { DefaultChassisToLabelGap, DefaultLeftIconGap } from '../util/NameHelp';
import { logger } from '../util/Logger';
import { LogRender } from '../types/Globals';
import { Theme } from '@rockwell-automation-inc/ra-meatball';


const _clrNameNormal = Theme.palette.primary.main;
const _clrNameSelected = Theme.palette.abBlue.main;
const _clrNameShadow = Theme.palette.lightGrey.main;
const _shadowOffset = 3;


const nameTextProps = {
    fontSize: ScaledChassisLabelSize,
    align: 'left',
    verticalAlign: 'bottom',
    wrap: 'none'
};


const getNameProps = (nameText: string, boxLoc: LocAndSize,
    selected: boolean): object => {

    return {
        ...nameTextProps,
        ...boxLoc,
        text: nameText,
        fill: selected ? _clrNameSelected : _clrNameNormal,
        ellipsis: true,
        shadowEnabled: selected,
        shadowColor: _clrNameShadow,
        shadowOffsetX: selected ? _shadowOffset : 0,
        shadowOffsetY: selected ? _shadowOffset : 0,
        fontFamily: 'Barlow',
        fontStyle: '500'
    };
}


interface Props {
    chassis: Chassis;
    label: string;
    showSelected: boolean;
    ptOrg: Point;
    maxNameWidth: number;
    onClick: (chassis: Chassis) => void;
}

const ChassisLabel = (props: Props) => {

    const ptLabel: Point = {
        x: props.ptOrg.x + DefaultLeftIconGap,
        y: props.ptOrg.y - DefaultChassisToLabelGap
    };

    const boxheight = ScaledChassisLabelSize;

    const boxLoc: LocAndSize = {
        x: ptLabel.x,
        y: ptLabel.y - boxheight,
        width: props.maxNameWidth,
        height: boxheight
    };

    const showNameBox = false;
    const nameBoxProps = showNameBox
        ? {
            ...boxLoc,
            stroke: 'red',
            strokeWidth: 1,
            strokeScaleEnabled: false
        }
        : {};

    const onPointerDown = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();
        props.onClick(props.chassis);
    }


    if (LogRender.Layout) {
        logger.logRender('ChassisLabel for ' + props.chassis.name);
    }

    return (
        <>
            {showNameBox
                ? <Rect
                    {...nameBoxProps}
                />
                : null}
            <Text
                {...getNameProps(props.label, boxLoc, props.chassis.selected)}
                fontFamily={'Barlow'}
                onPointerDown={onPointerDown}
            />
        </>
    );

}

export default React.memo(ChassisLabel);

