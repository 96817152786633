import { URL } from "url";
import { getFamilyFromPlatform } from "../../platforms/PlatformIDs";
import { logger } from "../../util/Logger";

const CSALocKey = 'CSA_loc';

export interface UrlComponents {
    origin: string;
    path: string;
    search: string;
}

const _makeUriFromLoc = (loc: UrlComponents): string => {
    let uri = loc.origin;
    if (loc.path) uri += loc.path;
    if (loc.search) uri += loc.search;
    return uri;
}

export const getLocationComponents = (url?: URL): UrlComponents => {

    if (url) {
        return {
            origin: url.origin,
            path: url.pathname,
            search: url.search
        }
    }

    return {
        origin: window.location.origin,
        path: window.location.pathname,
        search: window.location.search
    }
}

export const getURLParamValue = (param: string, url?: URL): string | undefined => {

    if (param) {
        const locInfo = getLocationComponents(url);
        const urlQueryParams = new URLSearchParams(locInfo.search);
        const paramVal = urlQueryParams.get(param);
        if (paramVal) {
            return paramVal;
        }
    }

    return undefined;
}

export const saveCurrentLocation = () => {
    const loc = getLocationComponents();
    const val = JSON.stringify(loc);
    localStorage.setItem(CSALocKey, val);
}

export const retrieveSavedLocation = (): UrlComponents | undefined => {
    const val = localStorage.getItem(CSALocKey);
    if (val) {
        const asLoc = JSON.parse(val) as UrlComponents;
        if (asLoc.origin) {
            return asLoc;
        }
    }
    return undefined;
}

export const gotoSavedLocation = (dfltUri?: string) => {
    logger.logAuth('auth: gotoSavedLocation');
    const loc = retrieveSavedLocation();
    const uri = loc
        ? _makeUriFromLoc(loc)
        : dfltUri ? dfltUri : window.location.origin;

    if (window.location.href !== uri) {
        window.location.replace(uri);
    }
    else {
        logger.logAuth('auth: gotoSavedLocation - SAME URI');
    }
}

export const refreshLocation = (projGuid: string, cid: string) => {
    const loc = retrieveSavedLocation();
    if (loc) {
        loc.search = `?projectGuid=${projGuid}&cid=${cid}`;
        const uri = _makeUriFromLoc(loc);
        window.location.replace(uri);
    }
    else {
        logger.error('Error retrieving saved loc info in refreshLocation');
    }
}

// TO DO:
// MIGHT want to replace this with platform-service stuff?
export const getNormalizedNavPath = (platform: string, projGuid: string, cid: string): string => {

    //if (platform && projGuid) {
    if (platform) {
        const family = getFamilyFromPlatform(platform);
        let normNav = `/${family}?projectGuid=${projGuid}`;
        if (cid) {
            normNav += `&cid=${cid}`;
        }
        return normNav;
    }
    else {
        throw new Error('Missing requirements in getNormalizedNavPath!');
    }
}
