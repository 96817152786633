import { addModuleAtSlot, getChassisSlotUsage } from "../../implementation/ImplGeneral";
import { attachModule, copyAccys } from "../../model/ChassisProject";
import { MessageCategory, MessageType, PanelMessage, StatusLevel } from "../../types/MessageTypes";
import { Chassis, ChassisModule, ResultStatus, MicroChassis } from "../../types/ProjectTypes";
import { unexpectedError } from "../../util/ErrorHelp";
import { clxAddModuleAtSlot, clxChangeChassisDefinition, clxGetModuleLayout, getCLXChassisBasics } from "../clx/model/CLXChassis";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../PlatformConstants";
import { snapGetModuleLayoutAndPrepForReplace } from "../snap/SnapChassis";
import { snapAttachModuleAtSlot, snapCompactModuleArray } from "../snap/SnapGeneralImpl";
import { getChassisReplaceMsgs, getModulesInUseBy, getModuleSwapDetails, makePanelMsg } from "./ChassisConfig";


export const replaceChassis = (
	chassis: Chassis,   // existing chassis to replace
	newCatNo: string,   // catalog number for NEW chassis
	newPSCatNo: string, // catalog number for PS to use
	testOnly: boolean   // if true, no replacement is actually performed.
	// Instead, a message and message level are
	// returned to provide caller with info about
	// a POSSIBLE future replacement using the new
	// chassis and PS catalog numbers.
): [
		result: ResultStatus,
		msgLevel: StatusLevel,
		messages: PanelMessage[],
		swapMap: Map<string, string>
	] => {

	switch (chassis.platform) {
		case PlatformCLX:
			return replaceCLXChassis(chassis, newCatNo, newPSCatNo, testOnly);
		case PlatformCpLX:
		case PlatformFlex:
			return replaceSnapChassis(chassis, newCatNo, newPSCatNo, testOnly);
		case PlatformFlexHA: 
			return replaceFlexHAChassis(chassis, newCatNo, newPSCatNo, testOnly);
		case PlatformMicro:
			return replacesMicroChassis(chassis, newCatNo, newPSCatNo, testOnly);
		default:
			break;
	}

	unexpectedError(`replaceChassis(): Not implemented for platform ${chassis.platform}!`);

	return [ResultStatus.Error, StatusLevel.Error, [], new Map<string, string>];
}


// NOTE: EXCEPT for when called with 'testOnly === true',
// this function should NOT be called with newCatNo (new
// chassis catalog number) matching that of the existing
// chassis. It is NOT designed to JUST replace the power
// supply on a chassis that is not itself changing.
const replaceCLXChassis = (
	chassis: Chassis,   // existing chassis to replace
	newCatNo: string,   // catalog number for NEW chassis
	newPSCatNo: string, // catalog number for PS to use
	testOnly: boolean   // if true, no replacement is actually performed.
	// Instead, a message and message level are
	// returned to provide caller with info about
	// a POSSIBLE future replacement using the new
	// chassis and PS catalog numbers.
): [
		result: ResultStatus,
		msgLevel: StatusLevel,
		messages: PanelMessage[],
		swapMap: Map<string, string>
	] => {

	// See note above. If we're NOT called with testOnly set to true,
	// ERROR out if the new and existing chassis catNos match.
	if (!testOnly && (chassis.catNo === newCatNo)) {
		throw new Error('Invalid call to replaceCLXChassis with matching new and existing chassis catNos!');
	}

	// Create empty collections for swap/no-swap info.
	const swapMap = new Map<string, string>();
	const noSwaps = new Set<string>();

	// Get slot usage info from the existing chassis.
	const [totalUsedSlots, slotFillers, lastSlotUsed] = getChassisSlotUsage(chassis);
	const minSlotsNeeded = totalUsedSlots - slotFillers;

	// Get environment detail and the number of slots
	// provided by the replacement chassis.
	const [newIsET, newIsCC, newNumSlots] =
		getCLXChassisBasics(newCatNo);

	// NOTE: Caller SHOULD have already avoided calling
	// us if the existing chassis has MORE slots filled
	// than could be accommodated by the new chassis
	// But, we'll STILL test for that. If the new chassis
	// doesn't have enough slots...
	if (minSlotsNeeded > newNumSlots) {
		// Return with an ERROR status. This SHOULD
		// be the ONLY case where we return that status.
		const failText = 'There are ' + minSlotsNeeded +
			' modules in the existing chassis, and' +
			' the chassis being dropped has only ' +
			newNumSlots + ' slots.';

		const pnlMsg = makePanelMsg(failText, StatusLevel.Error,
			MessageCategory.Size, MessageType.Situational);

		return [ResultStatus.Error, StatusLevel.Error, [pnlMsg], swapMap];
	}

	// Determine if any modules (or swaps) will
	// need to be moved if we change the chassis.
	const movesRequired = (lastSlotUsed >= newNumSlots);

	// If the existing chassis has ANY modules...
	if (totalUsedSlots > 0) {

		// Get a set of unique catNos present.
		const modsInUse = getModulesInUseBy(chassis);

		// Sanity check. We SHOULD get at least one.
		if (modsInUse.size === 0) {
			throw new Error('ERROR: Slots used but no modules in replaceCLXChassis?');
		}

		// Call a helper to provide us with swap info.
		// For each module that doesn't ALREADY meet the
		// environmental rating we're asking for, we get
		// either:
		//    - an entry in the swap map containing a suitable
		//      catNo to use that DOES meet the env requirements, OR
		//    - an entry in the noSwaps set, indicating that the
		//      catNo does NOT match the env, but we don't have,
		//      or cannot determine what a suitable match would be.
		getModuleSwapDetails(PlatformCLX, modsInUse, newIsCC, newIsET, swapMap, noSwaps);
	}

	// At this point, we know that a chassis replacement
	// WOULD be possible. Any noSwaps would be incompatible,
	// but we'd still include them in the new chassis. 
	// If we're in testOnly mode...
	if (testOnly) {

		// If there's anything the user should know about
		// prior to actually confirming the replacement
		// (the only way we should hasve any of these
		// conditions is if we have an EXISTING chassis
		// that has AT LEAST one existing module)...
		if (movesRequired || (swapMap.size > 0) || (noSwaps.size > 0)) {

			// Call a helper to get message info.
			const [level, msgs] = getChassisReplaceMsgs(PlatformCLX, movesRequired, swapMap, noSwaps);

			// Return the TestOnlyMsg status, along with it.
			return [ResultStatus.TestOnlyMsg, level, msgs, swapMap];
		}
		else {
			// Otherwise, return the TestOnly status with NO msgs.
			return [ResultStatus.TestOnlyMsg, StatusLevel.NA, [], swapMap];
		}
	}

	// If we're still here, we'll continue with the actual
	// replacement. Call a helper to detach all of the modules
	// in the existing chassis, and to return us an array of
	// data pairs. Each pair contains a module catalog number,
	// and the slot idx where that module SHOULD BE INSERTED
	// in the NEW chassis.
	const modLocPairs = clxGetModuleLayout(chassis, newNumSlots - 1);

	// Call another helper to effectively replace all of the
	// underlying layout info, etc., in our chassis with
	// new info associated with the new catalog number. Note
	// that since we're NOT changing environment here, we 
	// should be able to continue to use the SAME power supply
	// catalog number that was used with the old one.
	clxChangeChassisDefinition(chassis, newCatNo, newPSCatNo);

	// Start optimistic
	let addFailed = false;

	// Then walk our data pairs. For each...
	for (let pairIdx = 0; pairIdx < modLocPairs.length; pairIdx++) {

		// Get the pair.
		const dataPair = modLocPairs[pairIdx];

		// Use the catalog number of the old module in our
		// pair to see if we have some OTHER catalog number
		// we're supposed to swap to.
		const swapCat = swapMap.get(dataPair.module.catNo);

		// If we do...
		if (swapCat) {
			// Add the associated module at the specified slot idx.
			// Note that we'll use the CLX-specific version of
			// this add function. The general one adds required 
			// accessories, and we don't want them. If the add works...
			if (clxAddModuleAtSlot(chassis, swapCat, dataPair.slot)) {

				// Get the new module from the chassis.
				const newMod = chassis.modules[dataPair.slot];

				// If we can...
				if (newMod) {
					// Copy the accessories from the old
					// module to the new one.
					copyAccys(dataPair.module, newMod);
				}
				else {
					addFailed = true;
				}
			}
			else {
				addFailed = true;
			}
		}
		else {
			// We're NOT swapping this one. Instead, we'll
			// just attach it into the new chassis at the
			// location given in the pair. Note that any 
			// previous accessories will go along.
			if (!attachModule(chassis, dataPair.module, dataPair.slot)) {
				addFailed = false;
			}
		}
	}

	// We shouldn't ever fail above, but
	// error out if we do for some reason...
	if (addFailed) {
		throw new Error('Not all modules from the old chassis could be added to the new!');
	}

	// Success.
	return [ResultStatus.Success, StatusLevel.NA, [], swapMap];
}

// Edit chassis logic for updation of controller for micro 800 family
const replacesMicroChassis = (
	chassis: MicroChassis,   // existing chassis to replace
	newCatNo: string,   // catalog number for NEW chassis
	newPSCatNo: string, // catalog number for PS to use
	testOnly: boolean   // if true, no replacement is actually performed.

): [
		result: ResultStatus,
		msgLevel: StatusLevel,
		messages: PanelMessage[],
		swapMap: Map<string, string>
	] => {
	newPSCatNo; // prevent warning.
	testOnly
	// We still need to create a swap map.
	const swapMap = new Map<string, string>();
	// Get environment detail
	const [newIsET, newIsCC] = _getSnapEnvRatingFromChassisCatalog(chassis.platform, newCatNo);

	// Update the chassis.
	chassis.extendedTemp = newIsET;
	chassis.conformal = newIsCC;
	chassis.catNo = newCatNo;
	chassis.description = '';
	return [ResultStatus.Success, StatusLevel.NA, [], swapMap];
}

// NOTE: EXCEPT for when called with 'testOnly === true',
// this function should NOT be called with newCatNo (new
// chassis catalog number) matching that of the existing
// chassis. It is NOT designed to JUST replace the power
// supply on a chassis that is not itself changing.
const replaceSnapChassis = (
	chassis: Chassis,   // existing chassis to replace
	newCatNo: string,   // catalog number for NEW chassis
	newPSCatNo: string, // catalog number for PS to use
	testOnly: boolean   // if true, no replacement is actually performed.
	// Instead, a message and message level are
	// returned to provide caller with info about
	// a POSSIBLE future replacement using the new
	// chassis and PS catalog numbers.
): [
		result: ResultStatus,
		msgLevel: StatusLevel,
		messages: PanelMessage[],
		swapMap: Map<string, string>
	] => {
	newPSCatNo; // prevent warning.

	// See note above. If we're NOT called with testOnly set to true,
	// ERROR out if the new and existing chassis catNos match.
	if (!testOnly && (chassis.catNo === newCatNo)) {
		throw new Error('Invalid call to replaceSnapChassis with matching new and existing chassis catNos!');
	}

	// Create empty collections for swap/no-swap info.
	const swapMap = new Map<string, string>();
	const noSwaps = new Set<string>();
	const platform = chassis.platform;

	// Get slot usage info from the existing chassis.
	let totalUsedSlots = 0;
	chassis.modules.forEach((mod) => {
		if (mod) 
			totalUsedSlots++;
	});

	// Get environment detail 
	const [newIsET, newIsCC] = _getSnapEnvRatingFromChassisCatalog(platform, newCatNo);

	// If the existing chassis has ANY modules...
	if (totalUsedSlots > 0) {

		// Get a set of unique catNos present.
		const modsInUse = getModulesInUseBy(chassis);

		// Sanity check. We SHOULD get at least one.
		if (modsInUse.size === 0) {
			throw new Error('ERROR: Slots used but no modules in replaceSnapChassis?');
		}

		// Call a helper to provide us with swap info.
		// For each module that doesn't ALREADY meet the
		// environmental rating we're asking for, we get
		// either:
		//    - an entry in the swap map containing a suitable
		//      catNo to use that DOES meet the env requirements, OR
		//    - an entry in the noSwaps set, indicating that the
		//      catNo does NOT match the env, but we don't have,
		//      or cannot determine what a suitable match would be.
		getModuleSwapDetails(platform, modsInUse, newIsCC, newIsET, swapMap, noSwaps);
	}

	// At this point, we know that a chassis replacement
	// WOULD be possible. Any noSwaps would be incompatible,
	// but we'd still include them in the new chassis. 
	// If we're in testOnly mode...
	if (testOnly) {

		// If there's anything the user should know about
		// prior to actually confirming the replacement
		// (the only way we should hasve any of these
		// conditions is if we have an EXISTING chassis
		// that has AT LEAST one existing module)...
		if ((swapMap.size > 0) || (noSwaps.size > 0)) {

			// Call a helper to get message info.
			const [level, msgs] = getChassisReplaceMsgs(platform, false, swapMap, noSwaps);

			// Return the TestOnlyMsg status, along with it.
			return [ResultStatus.TestOnlyMsg, level, msgs, swapMap];
		}
		else {
			// Otherwise, return the TestOnly status with NO msgs.
			return [ResultStatus.TestOnlyMsg, StatusLevel.NA, [], swapMap];
		}
	}

	// If we're still here, we'll continue with the actual
	// replacement. Call a helper to detach all of the modules
	// in the existing chassis, and to return us an array of
	// data pairs. Each pair contains a module catalog number,
	// and the slot idx where that module SHOULD BE INSERTED
	// in the NEW chassis.
	const modLocPairs = snapGetModuleLayoutAndPrepForReplace(chassis);

	// Call another helper to effectively replace all of the
	// underlying layout info, etc., in our chassis with
	// new info associated with the new catalog number. 
	chassis.extendedTemp = newIsET;
	chassis.conformal = newIsCC;
	chassis.catNo = newCatNo;
	chassis.description = '';
	chassis.modules = new Array<ChassisModule | undefined>();


	// Start optimistic
	let addFailed = false;

	// Then walk our data pairs. For each...
	for (let pairIdx = 0; pairIdx < modLocPairs.length; pairIdx++) {

		// Get the pair.
		const dataPair = modLocPairs[pairIdx];

		// Use the catalog number of the old module in our
		// pair to see if we have some OTHER catalog number
		// we're supposed to swap to.
		const swapCat = swapMap.get(dataPair.module.catNo);

		// If we do...
		if (swapCat) {
			// Add the associated module at the specified slot idx.
			// Note that we'll use the CpLX-specific version of
			// this add function. The general one adds required 
			// accessories, and we don't want them. If the add works...
			if (addModuleAtSlot(chassis, swapCat, dataPair.slot, true)) {
				// Get the new module from the chassis.
				const newMod = chassis.modules[dataPair.slot];

				// If we can...
				if (newMod) {
					// Clear any accessories added to the new module.
					newMod.accys = undefined;

					// Copy the accessories from the old
					// module to the new one.
					copyAccys(dataPair.module, newMod);
				}
				else {
					addFailed = true;
				}
			}
			else {
				addFailed = true;
			}
		}
		else {
			// We're NOT swapping this one. Instead, we'll
			// just attach it into the new chassis at the
			// location given in the pair. Note that any
			// previous accessories will go along. Note:
			// We are NOT updating/validating the chassis.
			if (!snapAttachModuleAtSlot(chassis, dataPair.module, dataPair.slot, true)) {
				addFailed = true;
			}
		}
	}

	// We shouldn't ever fail above, but
	// error out if we do for some reason...
	if (addFailed) {
		throw new Error('Not all modules from the old chassis could be added to the new!');
	}

	// This will remove any undefined/empty slots
	// and update/validate chassis.
	snapCompactModuleArray(chassis);

	// Success.
	return [ResultStatus.Success, StatusLevel.NA, [], swapMap];
}

const replaceFlexHAChassis = (
	chassis: Chassis,   // existing chassis to replace
	newCatNo: string,   // catalog number for NEW chassis
	newPSCatNo: string, // catalog number for PS to use
	testOnly: boolean   // if true, no replacement is actually performed.

): [
		result: ResultStatus,
		msgLevel: StatusLevel,
		messages: PanelMessage[],
		swapMap: Map<string, string>
	] => {
	newPSCatNo; // prevent warning.

	/////// FlexHA does NOT need to replace any modules. ///////////
	/////// All modules are Env.Rated as Std/CC/XT.      ///////////

	// If we're NOT called with testOnly set to true,
	// ERROR out if the new and existing chassis catNos match.
	if (!testOnly && (chassis.catNo === newCatNo)) {
		throw new Error('Invalid call to replaceFlexHAChassis with matching new and existing chassis catNos!');
	}

	// We still need to create a swap map.
	const swapMap = new Map<string, string>();

	// At this point, we know that a chassis replacement
	// WOULD be possible. Any noSwaps would be incompatible,
	// but we'd still include them in the new chassis. 
	// If we're in testOnly mode...
	if (testOnly) {
		// Otherwise, return the TestOnly status with NO msgs.
		return [ResultStatus.TestOnlyMsg, StatusLevel.NA, [], swapMap];
	}

	// Get environment detail
	const [newIsET, newIsCC] = _getSnapEnvRatingFromChassisCatalog(chassis.platform, newCatNo);

	// Update the chassis.
	chassis.extendedTemp = newIsET;
	chassis.conformal = newIsCC;
	chassis.catNo = newCatNo;
	chassis.description = '';
	return [ResultStatus.Success, StatusLevel.NA, [], swapMap];
}


// Helpers.
const _getSnapEnvRatingFromChassisCatalog = (platform: string, catChassis: string): [xt: boolean, cc: boolean] => {
	switch (platform) {
		case PlatformCpLX:
		case PlatformFlex:
		case PlatformMicro:
		case PlatformFlexHA:
			{
				if (catChassis.endsWith('-K'))
					return [false, true];
				else if (catChassis.endsWith('-XT'))
					return [true, false];

				return [false, false];
			}
	}

	throw new Error(`_getSnapEnvRatingFromChassisCatalog(): Platform ${platform} not recognized as a 'snap' platform.`);
}