import React, { useEffect, useState } from 'react';
import '../styles/AppLayout.scss';
import { SvgIcon, IconButton, Tooltip } from '@mui/material';
import { Chassis, ChassisProject, Rack } from '../types/ProjectTypes';
import { addButton, ModalRequestSpec, ModalStatus, requestModal } from '../modals/ModalHelp';
import { logger } from '../util/Logger';
import { ReactComponent as DoubleArrowDown } from '../svg/Double_Arrow_Down.svg'
import { ReactComponent as DoubleArrowUp } from '../svg/Double_Arrow_Up.svg'
import { ReactComponent as ArrowUp } from '../svg/chevron-up.svg'
import { ReactComponent as ArrowDown } from '../svg/chevron-down.svg'
import { unexpectedError } from '../util/ErrorHelp';
import { getChassisLabelText, updateRackLayout } from '../model/ChassisProject';
import { contentChanging } from '../util/UndoRedo';


type ReactSVGComp =
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

const OrganizeChassisActonButtonType = {
    MoveTop: "Move to Top",
    MoveBottom: "Move to Bottom",
    MoveUp: "Move Up",
    MoveDown: "Move Down",
}
interface cbdataOrganizeChassis {
    tempracks: Rack[];
    project: ChassisProject;
    selectChassisCallback: (chassis: Chassis | undefined) => void;
    contentChangedCallback: () => void;
}

const OrganizeChassisCallback = (status: number, data?: object) => {

    // We're being notified that the results from
    // our confirmAndCallBack call below are in.
    // If the user confirmed the chassis delete...
    if (status === ModalStatus.Confirmed) {

        // Get the data that should have been sent
        // back to us in the form we expect.
        const OrgData = data as cbdataOrganizeChassis;

        // If we can, and we have a chassis...
        /*if (OrgData && OrgData.project.content.racks.length) {*/
        // BEFORE making any actual change, call our
        // helper to handle any related undo/redo
        // work for us.


        if (OrgData.tempracks.length > 0) {
            contentChanging(OrgData.project.content);
            OrgData.project.content.racks = [...OrgData.tempracks]
            updateRackLayout(OrgData.project.content);
            // Specify that NO chassis should
            // be currently selected. Ours
            // should have been the one selected
            // at this point.

            OrgData.selectChassisCallback(undefined);
            // Then notify our parent that
            // we changed project content.
            OrgData.contentChangedCallback();
        }
        else {
            unexpectedError('OrganizeChassisCallback missing expected data!', true, false);
        }
    }
}


const getActionbuttonTransform = (translateX: string | undefined, translateY: string | undefined): string | undefined => {

    let transformString = '';

    if (translateX) {
        transformString = `translateX(${translateX})`;
    }
    if (translateY) {
        if (transformString.length > 0) {
            transformString += ' ';
        }
        transformString += `translateY(${translateY})`;
    }

    return (transformString.length ? transformString : undefined);
}


interface OrganizeChassisActonButtonProps {
    id: string;
    tip?: string;
    iconSvg?: ReactSVGComp;
    disabled?: boolean;
    translateY?: string;
    translateX?: string;
    onClick?: (id: string) => void;
}

const OrganizeChassisActonButton = (props: OrganizeChassisActonButtonProps) => {
    const disabled = (props.disabled === true);

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.id);
        }
    }

    const transform = getActionbuttonTransform(props.translateX, props.translateY);

    const renderIcon = () => {
        if (props.iconSvg) {
            const ReqSvgIcon = props.iconSvg;

            return (
                <SvgIcon
                    component={ReqSvgIcon}
                    inheritViewBox
                    sx={{
                        padding: '2px 0px 2px 0px',
                        width: '20px',
                        height: '20px',
                        transform: transform
                    }}
                />
            );
        }


        return null;
    }

    const renderButton = () => {
        return (
            <IconButton
                onClick={onClick}
                disabled={disabled}
                disableRipple
                disableFocusRipple
                sx={{
                    color: '#2A79C4',
                    height: '26px',
                    width: '64px',
                    borderRadius: '4px',
                    border: '1px solid #2A79C4',
                    padding: '4px 10px 4px 10px',
                }}
            >
                {renderIcon()}
            </IconButton>
        );
    }

    if (!disabled && props.tip && (props.tip.length > 0)) {
        return (
            <Tooltip
                title={props.tip}
                placement='right-start'
            >
                {renderButton()}
            </Tooltip>
        );
    }

    return (
        <>
            {renderButton()}
        </>
    );
}


const OrganizeChassis = (request: ModalRequestSpec) => {

    const cbData = request.requestorData as cbdataOrganizeChassis;
    const origRacks = cbData ? cbData.project.content.racks : [];

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [tempracks, setTempracks] = useState<Rack[]>(origRacks);

    const data = request.requestorData as cbdataOrganizeChassis;

    const updateTemprackGroup = (racks: Rack[]) => {
        setTempracks(racks);

    }
    useEffect(() => {
        logger.log("UseEffect Ran", tempracks)
        data.tempracks = [...tempracks];
    }, [tempracks, data])

    const handleclick = (index: number) => {
        setSelectedItemIndex(index)
    }

    const handleIndexModification = (index: number, newIndex: number) => {
        // Move selected item 
        if (newIndex < 0 || newIndex >= tempracks.length) return;

        const updatedCopiedArray = [...tempracks];
        const movedItem = updatedCopiedArray.splice(index, 1)[0];
        updatedCopiedArray.splice(newIndex, 0, movedItem);
        updateTemprackGroup(updatedCopiedArray);
        handleclick(newIndex)
    };

    const onButtonClick = (action: string) => {
        switch (action) {
            case OrganizeChassisActonButtonType.MoveTop:
                handleIndexModification(selectedItemIndex, 0);
                return;

            case OrganizeChassisActonButtonType.MoveBottom:
                handleIndexModification(selectedItemIndex, tempracks.length - 1);
                return;

            case OrganizeChassisActonButtonType.MoveUp:
                handleIndexModification(selectedItemIndex, selectedItemIndex - 1);
                return;

            case OrganizeChassisActonButtonType.MoveDown:
                handleIndexModification(selectedItemIndex, selectedItemIndex + 1);
                return;

            default:
                handleIndexModification(selectedItemIndex, selectedItemIndex);
                return;
        }
    }

    return (
        <div className='organize-chassis-body'>
            <div className='organize-chassis-body-title-list'>
                {tempracks.map((rack, index) => {
                    return (
                        <div
                            className='organize-chassis-body-title'
                            onClick={() => handleclick(index)}
                            style={{ backgroundColor: index == selectedItemIndex ? 'rgba(33, 150, 243, 0.08)' : "white" }}
                            key={index}>
                            <span className='organize-chassis-body-title__text'>{getChassisLabelText(rack.chassis, false, true)}</span>
                        </div>);
                })}
            </div>

            <div className='organize-chassis-body-action-icons'>


                <OrganizeChassisActonButton
                    id={OrganizeChassisActonButtonType.MoveTop}
                    tip={"Move to Top"}
                    iconSvg={DoubleArrowUp}
                    disabled={tempracks.length > 0 ? false : true}
                    translateY='10%'
                    onClick={onButtonClick}

                />
                <OrganizeChassisActonButton
                    id={OrganizeChassisActonButtonType.MoveUp}
                    tip={"Move up"}
                    iconSvg={ArrowUp}
                    disabled={tempracks.length > 1 ? false : true}
                    onClick={onButtonClick}

                />
                <OrganizeChassisActonButton
                    id={OrganizeChassisActonButtonType.MoveDown}
                    tip={"Move Down"}
                    iconSvg={ArrowDown}
                    disabled={tempracks.length > 1 ? false : true}
                    onClick={onButtonClick}

                />
                <OrganizeChassisActonButton
                    id={OrganizeChassisActonButtonType.MoveBottom}
                    tip={"Move to Bottom"}
                    iconSvg={DoubleArrowDown}
                    disabled={tempracks.length > 1 ? false : true}
                    translateY='10%'
                    onClick={onButtonClick}

                />
            </div>

        </div>
    );
}


export const ReorderChassis = (
    project: ChassisProject,
    selectChassisCallback: (chassis: Chassis | undefined) => void,
    contentChangedCallback: () => void
) => {

    const data: cbdataOrganizeChassis = {
        tempracks: [],
        project: project,
        selectChassisCallback: selectChassisCallback,
        contentChangedCallback: contentChangedCallback
    }

    const request: ModalRequestSpec = {
        includeButtons: true,
        closeOnInsideClick: false,
        stayOpenOnBackdropClick: true,
        title: 'Organize Chassis',
        callback: OrganizeChassisCallback,
        requestorData: data,
        content: OrganizeChassis
    };

    addButton(request, 'CANCEL', ModalStatus.Cancelled, 'contained');
    addButton(request, 'ACCEPT', ModalStatus.Confirmed, 'contained');

    requestModal(request);
};
