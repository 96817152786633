import { EngInfoPackage } from "../types/EngDataTypes";
import { logger } from "../util/Logger";


// Registered Eng Data Implementations.
const _engDataImplRegistry = new Map<string, EngDataImplementation>();


// Specifications for optional platform-specific routes.
export interface _implPreload {
    (pkg: EngInfoPackage): Promise<void>;
}

interface _implGetDfltJSON {
    (): object;
}

export interface _implPostLoad {
    (pkg: EngInfoPackage): Promise<void>;
}


// Routing specifications for a platform.
export interface EngDataImplementation {
    platform: string;
    preload?: _implPreload;
    getDfltEngData?: _implGetDfltJSON;
    postLoad?: _implPostLoad;
}


// Implementation registration.
export const RegisterEngDataImpl = (impl: EngDataImplementation) => {
    const platformID = impl.platform.trim();
    if (platformID && platformID === impl.platform) {
        if (!_engDataImplRegistry.has(platformID)) {
            _engDataImplRegistry.set(platformID, impl);
        }
        else {
            logger.warn('RegisterEngDataImpl ignoring duplicate register: ' + impl.platform);
        }
    }
    else {
        throw new Error('Invalid platform ID in RegisterChecker!');
    }
}

// Implementation retrieval - NOT EXPORTED.
const _getImplementation = (platform: string): EngDataImplementation | undefined => {
    return _engDataImplRegistry.get(platform);
}


// EXPORTED API
// NOTE: In ANY of the following, an empty or invalid
// platform ID will result in default functionality.

export const engDataPreload = (platform: string, pkg: EngInfoPackage):
    Promise<void> | undefined => {

    const impl = _getImplementation(platform);
    if (impl && impl.preload) {
        return impl.preload(pkg);
    }

    // Default - No preload.
    return undefined;
}

export const getDfltEngData = (platform: string): object | undefined => {

    const impl = _getImplementation(platform);
    if (impl && impl.getDfltEngData) {
        return impl.getDfltEngData();
    }

    // Default - None.
    return undefined;
}

export const engDataPostload = (platform: string, pkg: EngInfoPackage):
    Promise<void> | undefined => {

    const impl = _getImplementation(platform);
    if (impl && impl.postLoad) {
        return impl.postLoad(pkg);
    }

    // Default - No postLoad.
    return undefined;
}

//export const engDataLoadCommDtls = (platform: string, pkg: EngInfoPackage):
//    Promise<void> | undefined => {

//    const impl = _getImplementation(platform);
//    if (impl && impl.loadCommDtls) {
//        return impl.loadCommDtls(pkg);
//    }

//    // Default - No comm details loader.
//    return undefined;
//}
