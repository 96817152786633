import { EngInfoIOModule, IOFeatures } from "../engData/EngineeringInfo";
//import { IOModuleEngData, IOModuleSubtype } from "../types/EngDataTypes";
import {
    AIBits,
    AOBits,
    ControlVoltageMask,
    DIBits,
    DigitalAnalogBits,
    DOBits,
    InputOutputBits,
    IOBitset
} from "../types/IOModuleTypes";
import { logger } from "./Logger";


const _getIOFeaturesMask = (features: IOFeatures): number => {
    let mask = 0;
    if (features.modeDC) mask |= IOBitset.AnalogCurrent;
    if (features.modeSEV) mask |= IOBitset.AnalogVoltage;
    if (features.HART) mask |= IOBitset.HartIO;
    if (features.isolated) mask |= IOBitset.Isolated;
    if (features.diagnostic) mask |= IOBitset.Diagnostic;
    if (features.elecFused) mask |= IOBitset.ElectronicFused;

    if (features.modeHSDC) {
        logger.warn('HSDC feature not yet supported!');
    }

    return mask;
}

// NOTE: This function ONLY sets bits for voltages
// supported at the time. and makes the old assumption
// about supported voltage for analogs. The incoming
// vltg spec now has additional voltages AND ranges
// that would apply to analogs.
const _getIOVoltageMask = (digital: boolean, mod: EngInfoIOModule): number => {
    let mask = 0;

    if (digital) {
        if (mod.vltg.vdc24) mask |= IOBitset.DC24;
        if (mod.vltg.vdc48) mask |= IOBitset.DC48;
        if (mod.vltg.vdc125) mask |= IOBitset.DC120;
        if (mod.vltg.vac120) mask |= IOBitset.AC120;
        if (mod.vltg.vac240) mask |= IOBitset.AC240;
        if (mask === 0) {
            logger.logData('Digital mod without currently supported voltage.: ' + mod.catNo);
        }
    }
    else {
        // For now, we say analog modules can
        // handle all control voltages...
        mask |= ControlVoltageMask;
    }

    return mask;
}

export const establishIOMaskForModule = (mod: EngInfoIOModule): number => {
    let mask = 0;

    if (mod.envInfo.ccOk) mask |= IOBitset.Conformal;
    if (mod.envInfo.etOk) mask |= IOBitset.ExTemp;

    const dtls = mod.ptDtls;

    if (dtls.analog) mask |= IOBitset.Analog;
    if (dtls.digital) mask |= IOBitset.Digital;
    if (dtls.totalInputs > 0) mask |= IOBitset.Input;
    if (dtls.totalOutputs > 0) mask |= IOBitset.Output;
    if (dtls.safety) mask |= IOBitset.SafetyIO;

    if ((mask & IOBitset.Input) && (mask & IOBitset.Output)) {
        mask |= IOBitset.Combo
    }

    if (dtls.DSC > 0) {
        mask |= (
            IOBitset.Input |
            IOBitset.Output |
            IOBitset.SelfCfg |
            IOBitset.Combo);
    }

    if (dtls.RTD > 0) mask |= IOBitset.RTD;
    if (dtls.Therm > 0) mask |= IOBitset.ThermoCouple;

    if (dtls.RO === 0) {
        mask |= _getIOVoltageMask(dtls.digital, mod);
    }

    mask |= _getIOFeaturesMask(mod.features);

    return mask;
}

const _basicIOTypeSortPredicate = (a: number, b: number): number => {
    // Start by comparing Digital to Analog.
    // Analog goes before Digital.
    if (a & IOBitset.Analog) {
        if (b & IOBitset.Digital)
            return 1;
    }
    else if (b & IOBitset.Analog) {
        return -1;
    }

    // If we are here, a/b are both Digital
    // or Analog. Input goes before Output.
    if (a & IOBitset.Input)
        return -1;
    return 1;
}

export const getBasicIOTypesFromSet = (setTypes: Set<number>): number[] => {
    // While the set contains unique types from what
    // we found in the I/O Modules, we might have entries
    // that are both input and output (combo modules) or
    // both Digital and Analog (Universal Modules). We need
    // to break these into individual DI/DO/AI/AO types.
    const basicTypes = new Set<number>
    setTypes.forEach((type) => {
        // If we do NOT have all 4 basic types yet...
        if (basicTypes.size < 4) {
            const combo = (type & InputOutputBits) === InputOutputBits;
            const universal = (type & DigitalAnalogBits) === DigitalAnalogBits;
            if (combo && universal) {
                basicTypes.add(DIBits);
                basicTypes.add(DOBits);
                basicTypes.add(AIBits);
                basicTypes.add(AOBits);
            }
            else if (combo) {
                const bitDigitalOrAnalog = (type & DigitalAnalogBits);
                basicTypes.add(IOBitset.Input | bitDigitalOrAnalog);
                basicTypes.add(IOBitset.Output | bitDigitalOrAnalog);
            }
            else if (universal) {
                const bitInuputOrOutput = (type & InputOutputBits);
                basicTypes.add(IOBitset.Digital | bitInuputOrOutput);
                basicTypes.add(IOBitset.Analog | bitInuputOrOutput);
            }
            else {
                basicTypes.add(type);
            }
        }
    });

    return Array.from(basicTypes).sort(_basicIOTypeSortPredicate);
}

