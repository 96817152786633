
const currFormatterUSNoCents = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //2024.4.5 Commented to prevent rounding to
    // whole dollar.
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export const formatToDollars = (value: number): string => {
    const formatted = currFormatterUSNoCents.format(value);
    return formatted;
}

export const getCurrencyValueFromString = (currency: string): number => {
    // Strip any characters from the price (RegEx) leaving
    // only digits and a period (hopefully there's only one).
    const val = Number(currency.replace(/[^0-9.]/g, ''))
    if (isNaN(val))
        return 0;

    return val;
}