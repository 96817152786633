import EngineeringIcon from '@mui/icons-material/Engineering';
import {
    createBasicConfirmRequest,
    createBasicMessageRequest,
    IModalResultCallback,
    requestModal
} from "../modals/ModalHelp"
import { StatusLevel } from '../types/MessageTypes';


export const displayUnderConstructionMsg = (msg: string) => {
    const modalReq = createBasicMessageRequest(msg, 'Functionality Coming Soon...',
        StatusLevel.NA);
    modalReq.iconMui = EngineeringIcon;
    modalReq.iconColor = 'green',
    requestModal(modalReq);
}

// Note: In most cases, it's better to use the unexpectedError
// function (in ErrorHelp.ts) instead of calling this function
// directly.
export const displayUnexpectedErrorMsg = (
    msg: string,
    callback?: IModalResultCallback,
    callbackData?: object
) => {
    const modalReq = createBasicMessageRequest(msg, 'UNEXPECTED ERROR',
        StatusLevel.Error);
    modalReq.callback = callback;
    modalReq.requestorData = callbackData;
    requestModal(modalReq);
}

export const displayAlertMsg = (
    msg: string,
    statusLevel = StatusLevel.Warning,
    title?: string) => {
    const modalReq = createBasicMessageRequest(msg, title, statusLevel);
    requestModal(modalReq);
}

export const confirmAndCallBack = (
    msg: string,
    callback: IModalResultCallback,
    data: object,
    title = 'Are you sure?',
    statusLevel = StatusLevel.Warning,
) => {
    const modalReq = createBasicConfirmRequest(msg, title,
        callback, data, statusLevel);
    requestModal(modalReq);
}