import React, { ReactNode, useRef } from 'react';
import { ProjectSetting, SettingOption } from '../types/SettingsTypes';
import './Settings.scss';
import { InputAdornment, TextField } from '@mui/material';
import { HdrErrState } from './SettingHeader';
import { SettingIconButton } from './SettingIconButton';
import { SettingsStatusCtrl } from './SettingsStatusCtrl';

interface Props {
    setting: ProjectSetting;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
}


const EditSetting = (props: Props) => {
	const _iconButtonLabel = useRef<ReactNode>(null);
	const prevMessage = useRef<string>('');
	const prevState = useRef<HdrErrState>(HdrErrState.none);
	const textField = useRef<HTMLInputElement>(null);

	// Place any hooks above here!.
	if (props.setting.visible === false)
		return null;

    const onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.valueChanged(props.setting, { id: e.currentTarget.value, display: e.currentTarget.value });
    }

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		switch (e.key) {
			case 'Escape':
			case 'Enter':
				if (textField.current) {
					textField.current.blur();
				}
				break;
			default:
				break;
		}
	}

	const ctrlColor = (props.setting.valid ? '#c0c0c0' : '#ff0000');
	const textColor = (props.setting.valid ? '#000000' : '#ff0000');
	const labelColor = (props.setting.valid ? '#808080' : '#ff0000');

	const message = (props.setting.msgWarningOrError ? props.setting.msgWarningOrError : props.setting.defaultMessage);

	let state = HdrErrState.info;
	if (props.setting.msgWarningOrError) {
		state = (props.setting.valid ? HdrErrState.warning : HdrErrState.error);
	}

	if (state !== prevState.current || (message && message !== prevMessage.current)) {
		prevState.current = state;
		prevMessage.current = message;
		_iconButtonLabel.current = <SettingIconButton message={message} state={state} iconSize={'16'} />
	}

	const showStatusIcon = (state === HdrErrState.error || state === HdrErrState.warning);

	return (
		<div>
			<div className={showStatusIcon ? "setting-control-container-error" : "setting-control-container"}>
				<div className="setting-control-body">
					<TextField
						id={props.setting.id}
						autoComplete={'off'}
						value={props.setting.selectedOption.display} // We always want the label in the border.
						onChange={onChanged}
						onFocus={event => { event.target.select(); }}
						onKeyDown={onKeyDown}
						inputRef={textField}
						InputProps={{ endAdornment: < InputAdornment position="end" >{props.setting.units}</InputAdornment> }}	
						// shrink will keep the label on the border even when text is empty.
						InputLabelProps={{ shrink: true }}
						label={
							<div className="setting-control-label-div" style={{ color: labelColor }}>
								<label className="a-type__caption" style={{ fontSize: '16px', fontWeight: 400 }}>{props.setting.name}</label>
								{_iconButtonLabel.current}
								</div>
						}
						size="small"
						sx={{
							width: '280px',			
							marginBottom: 0,
							color: {ctrlColor},
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: { ctrlColor },
							},
							"&:hover > .MuiOutlinedInput-notchedOutline": {
								borderColor: "black",
								border: 1.5
							},
							input: {
								fontSize: 16, fontWeight: 400, color: { textColor } }
						}}
					/>	
				</div>
				{showStatusIcon ?
					<SettingsStatusCtrl
						state={state}
						message={message}
					/>
					:
					null
				}
			</div>
		</div>
	);
}

export default EditSetting;
