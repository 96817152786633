import React from 'react';
import { LogRender } from '../../types/Globals';
import { ActBtnInfo, LayoutActionType } from '../../types/LayoutActions';
import { logger } from '../../util/Logger';
import AddModActionButton from './AddModActionButton';
import ModeActionButton from './ModeActionButton';


interface Props {
    actionType: LayoutActionType;
    btnInfo: ActBtnInfo[] | undefined;
    onClickBtn: (actBtnInfo: ActBtnInfo) => void;
}

const ActionButtons = (props: Props) => {

    // Sanity check that we're actually provided
    // with at least one btn info object. If not,
    // we should NOT have been included.
    if ((props.btnInfo === undefined) || (props.btnInfo.length < 1)) {
        throw new Error('Expected button info not found in ActionButtons!');
    }

    if (LogRender.ActButtons) {
        logger.logRender('Render ActionButtons');
    }

    if (props.btnInfo.length === 0)
        return null;

    let nextKey = 1;

    // 2024.6.5 The BtnInfo Array may contain MORE
    // than a SINGLE action type. Not sure if we still
    // need the 'action type' passed in the props(?)
    //switch (props.actionType) {
    //    case LayoutActionType.AddModule:
    //        return (
    //            <>
    //                {props.btnInfo.map(info => {
    //                    return <AddModActionButton
    //                        key={nextKey++}
    //                        btnInfo={info}
    //                        onClick={props.onClickBtn}
    //                    />
    //                })}
    //            </>
    //        );

    //    case LayoutActionType.ModeDelete:
    //    case LayoutActionType.ModeCopy:
    //        return (
    //            <>
    //                {props.btnInfo.map(info => {
    //                    return <ModeActionButton
    //                        key={nextKey++}
    //                        btnInfo={info}
    //                        onClick={props.onClickBtn}
    //                    />
    //                })}
    //            </>
    //        );

    //    default:
    //        return (null);
    //}

    return (
        <>
            {props.btnInfo.map(info => {
                const ctrl =
                    (
                        info.action === LayoutActionType.AddModule
                    ?
                        <AddModActionButton
                            key={nextKey++}
                            btnInfo={info}
                            onClick={props.onClickBtn}
                        />
                    :
                        <ModeActionButton
                            key={nextKey++}
                            btnInfo={info}
                            onClick={props.onClickBtn}
                            />
                    )

                return ctrl;
            })}
        </>
    );
}

export default React.memo(ActionButtons);

        