import React from 'react';
import './Choices.scss';
import { ChoiceInfo, ChoicesSubgroup, GuidedSelChoiceInfo } from '../../types/MessageTypes';
import ChoiceGroupHdr from './ChoiceGroupHdr';
import ChoiceSelector from './ChoiceSelector';
import { ProjectSetting } from '../../types/SettingsTypes';

interface Props {
	gsSetting: ProjectSetting;
	grpInfo: GuidedSelChoiceInfo;
	gsSelectionChanged: (gsSetting: ProjectSetting, newChoiceInfo: ChoiceInfo) => void;
	outlinedSelectors?: boolean;
}

const GuidedSelChoiceGroup = (props: Props) => {

	const choicesClass = (props.grpInfo.row === true)
		? 'choices-subgroup-choices-row'
		: 'choices-subgroup-choices-column';

	const grpChoiceChanged = (info: ChoiceInfo, selected: boolean) => {
		if (selected)
			props.gsSelectionChanged(props.gsSetting, info);
	}

	let nextKey = 1;

	const renderSubgroup = (subgroup: ChoicesSubgroup) => {

		return (
			<div
				key={nextKey++}
				className='choices-subgroup'
			>
				<ChoiceGroupHdr subgroup={subgroup} />
				<div className={choicesClass}>
					{subgroup.selectionInfo.map(info => {
						return (
							<ChoiceSelector
								key={nextKey++}
								info={info}
								radio={true}
								checked={props.gsSetting.selectedOption.id === info.id}
								onSelChanged={grpChoiceChanged}
								outlined={props.outlinedSelectors}
							/>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<div className='choices-group'>
			{
				renderSubgroup(props.grpInfo.subGroup)
			}
		</div>
	);
}

export default GuidedSelChoiceGroup;
