import React, { useEffect, useRef, useState } from 'react';
import './ProjectTree.scss';
import { TextField } from '@mui/material';
import { sanitizeNameString } from '../util/NameHelp';


interface Props {
	startValue: string;
	onFinished: (cancelled: boolean, newName: string) => void;
}

const ProjTreeNameEditor = (props: Props) => {
	const [count, setCount] = useState(0)
	const textInputRef = useRef<HTMLInputElement | null>(null);

	const editText = useRef<string>("");

	useEffect(() => {
		if (textInputRef.current) {
			textInputRef.current.focus();
			editText.current = props.startValue;
			setCount(count => count + 1);
		}
	}, [props.startValue]);

	const finishEdit = () => {
		// If we have a text value...
		if (editText.current) {
			// Sanitize it into an allowable 'final' name
			// for a chassis. Inside spaces NOT allowed.
			editText.current =
				sanitizeNameString(editText.current, false, true);

			// Treat an empty result as a cancel, and return.
			if (editText.current.length === 0) {
				editText.current = '';
				props.onFinished(true, '');
				return;
			}
		}

		// If our final version is DIFFERENT than
		// what we were given in our props value...
		if (editText.current !== props.startValue) {
			// Notify upward that we have a new name.
			props.onFinished(false, editText.current);
		}
		else {
			// Otherwise, pass notice up that our
			// parent should ignore anything we did here.
			props.onFinished(true, '');
		}
	}

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

		// Call a helper to massage the editor text into
		// what would be allowable as a chassis name in progress,
		// and store the result into our editText ref.
		editText.current = sanitizeNameString(event.target.value, false, false);

		// Trigger another render.
		setCount(count + 1);
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		switch (event.key) {
			case 'Enter':
				finishEdit();
				break;

			case 'Escape':
				props.onFinished(true, '');
				break;

			default:
				break;
		}
	}

	// Blur event is losing focus
	const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
		event;
		finishEdit();
	};

	const inputStyles = {
		fontSize: '15px',
		fontWeight: 'bold'
	};

	return (
		<div className="name-editor-div">
			<TextField
				inputRef={textInputRef}
				id="outlined-basic"
				variant="outlined"
				size={'small'}
				fullWidth={true}
				label=""
				inputProps={{ style: { ...inputStyles }, spellCheck: 'false' }}
				value={editText.current}
				autoFocus={true}
				onBlur={handleBlur}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/>
		</div>
	);
}

export default ProjTreeNameEditor;

