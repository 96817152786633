import { getNewInstanceId } from './InstanceIdHelp'

export enum AFType {
	NA = 'NA',

	ChassisOutOfPower = 'ChassisOutOfPower',
	ChassisLowOnPower = 'ChassisLowOnPower',

	Red_RemoteChassisCannotBeRed = 'Red_RemoteChassisCannotBeRed',
	Red_MigrateNonRedModules = 'Red_MigrateNonRedModules',
	Red_RemoveRedundancyModule = 'Red_RemoveRedundancyModule',
	Red_MultpleRMModules = 'Red_MultpleRMModules',
	Red_RMModNotNextToController = 'Red_RMModNotNextToController',

	Red_TooManyL8s = 'Red_TooManyL8s',
	Red_TooManyL7s = 'Red_TooManyL7s',
	Red_MixedL8andL7 = 'Red_MixedL8andL7',

	Sys_Performance = 'Performance',
	Safety_NoL7SP = 'Safety_NoL7SP',
	Safety_NoSafetyCtlrInConfig = 'Safety_NoSafetyCtlrInConfig',

	Snap_CommHasTooManyModules = 'Snap_CommHasTooManyModules',
	Snap_InefficientFPDs = 'Snap_InefficientFPDs',
	Snap_NoCommInChassis = 'Snap_NoCommInChassis',
	Snap_NumBanksExceeded = 'Snap_NumBanksExceeded',

	No_Controller='No_Controller',
}

// Note: If an Auto Fix needs more data than
// AF_Base provides, define a new interface
// that EXTENDS AF_Base. In the AF implementation
// cast the AF_Base object to the Extended interface.
export interface AF_Base {
	type: AFType;
	id: string;
	platform: string;
	targetInstanceID: string;
}

export enum ChassisOutOfPower {
	NoOp = 0x0000,
	Unfixable = 0x0001,
	MissingPSU = 0x0002,
	UpgradePSU = 0x0004,
	CreateNewChassis = 0x0008,
}

export const getNewAutoFix = (platform: string = '', targetID: string = '', type: AFType): AF_Base => {
	return {
		type: type,
		id: getNewInstanceId(),
		platform: platform,
		targetInstanceID: targetID,
	}
}

const _AF: AF_Base[] = [];

export const getAutoFix = (afID: string): AF_Base | undefined => {
	return _AF.find(x => x.id === afID);
}

export const addAutoFix = (af: AF_Base) => {
	_AF.push(af);
}

export const deleteAutoFix = (af: AF_Base) => {
	const idx = _AF.indexOf(af);
	if (idx >= 0)
		_AF.splice(idx, 1);
}

export const getFirstAutoFixIDByType = (type: AFType): string => {
	const af = _AF.find(x => x.type === type);
	if (af)
		return af.id;
	return '';
}

export const clearAllAutoFixData = () => {
	_AF.length = 0;
}

