import React, { useState } from 'react';
import './OLD_ModuleSelection.scss'
import { ModuleSelectionProps, getNewModuleSelection } from './ModuleSelectionUtil';
import { ModuleSelectionTree } from './ModuleSelectionTree';
import { ModalRequestSpec, ModalStatus } from '../modals/ModalHelp';
import { EngInfoModule } from '../engData/EngineeringInfo';


const OLD_ModuleSelection = (propsModal: ModalRequestSpec) => {
	// Component setup for single selection. For Phase 2,
	// we will be allowing multiple selections with quantities.
	// 'selProps.selectionData.selections' is an array for
	// that purpose.

	const [/*singleSelection*/, setSingleSelection] = useState('');


	// Cast the requestorData to our props.
	const selProps = propsModal.requestorData as ModuleSelectionProps;

	const onModuleAdded = (module: EngInfoModule, auto: boolean) => {
		if (selProps.selections.length === 0)
			selProps.selections.push(getNewModuleSelection(module, 1));
		else
			selProps.selections[0] = getNewModuleSelection(module, 1);

		if (auto && (propsModal.endModal !== undefined)) {
			propsModal.endModal(ModalStatus.Confirmed);
		}
		else {
			const selCat = selProps.selections[0].module.catNo;
			setSingleSelection(selCat);

			if (propsModal.buttonInfo && propsModal.fullRender) {
				const addBtn = propsModal.buttonInfo[0];
				addBtn.btnText = 'ADD: ' + selCat;
				addBtn.disabled = undefined;
				propsModal.fullRender();
			}
		}

		// If we do not have a chassis...
		if (selProps.chassis === undefined) {
			return (<div style={{ margin: 'auto' }}>Error encounterd. Please select Cancel and try again.</div>);
		}
	}

	return (
		<div className="moduleselection-container">
			<ModuleSelectionTree
				treeRoot={selProps.treeRoot}
				onModuleAdded={onModuleAdded}
			/>
		</div>
	);
}

export default OLD_ModuleSelection;