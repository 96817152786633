import React, { FunctionComponent, SVGProps } from "react";
import { Button, SvgIcon } from "@mui/material"

export interface ProjectStatusFilterButtonDetails {
    label: string;
    borderColor: string;
    isDisabled: boolean;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    id: string;
    selectionStatus: boolean
}



interface ProjectStatusFilterButtonProps {
    buttonInfo: ProjectStatusFilterButtonDetails;
    onButtonClick: (id: string) => void;
}

const ProjectStatusFilterButton = (props: ProjectStatusFilterButtonProps) => {
    const onClick = () => {
        props.onButtonClick(props.buttonInfo.id)
    }
    return (
        <Button
            onClick={onClick}
            sx={{
                py: "6px",
                px: 0,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 500,
                textTransform: "capitalize",
                color: "#2D2D2D",
                border: "none",
                borderRadius: 0,
                borderBottom: props.buttonInfo.selectionStatus ? `2px solid ${props.buttonInfo.borderColor}` : "2px solid",
                '.MuiButton-startIcon':{marginRight:'6px'}
            }}
            startIcon={<SvgIcon component={props.buttonInfo.icon} viewBox="0 0 32 32" sx={{height:"12px",  width:"12px",color:props.buttonInfo.borderColor}} /> }
        >
            {props.buttonInfo.label}
        </Button>)
}

export default ProjectStatusFilterButton;