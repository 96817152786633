import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { DetailGroup, DetailGrpType } from '../../model/DeviceDetail';
import DetailsItem from './DetailsItem';

interface Props {
    group: DetailGroup;
}

const DetailsGroup = (props: Props) => {

    const haveTitle = ((props.group.type === DetailGrpType.Group) &&
        (props.group.title.length > 0));

    let nextKey = 1;

    // Note: Our floating-details-items CSS class uses a
    // grid display type, and is defined with TWO columns.
    // As such, the first two <div>'s encountered go into
    // columns 1 and 2 of the first row. The next two go
    // into the second row, and so forth. Keep that in mind
    // when adding anything expected to fit into this grid,
    // and avoid extra <div> tags. For example, the outer
    // tag in the render for our DetailsItem component is
    // a fragment (<>), and NOT a <div> for just that reason.

    const renderGroup = (group: DetailGroup) => {
        switch (group.type) {
            case DetailGrpType.Singleton:
                return (
                    <div className='floating-details-group'>
                        <div className='floating-details-items'>
                            <DetailsItem
                                item={group.items[0]}
                            />
                        </div>
                    </div>
                );

            case DetailGrpType.Group:
                return (
                    <div className='floating-details-group'>
                        {haveTitle
                            ? <div className='a-type__body highlighted-item'>
                                {group.title}
                            </div>
                            : null}
                        <div className="floating-details-items floating-details-items-as-children">
                            {group.items.map(item => {
                                return (
                                    <DetailsItem
                                        key={nextKey++}
                                        item={item}
                                    />
                                );
                            })}
                        </div>
                    </div>
                );

            default:
                throw new Error('Unexpected group type in OLD_DetailsGroup renderGroup');
        }
    }

    return (
        <>
            {renderGroup(props.group)}
        </>
    );
}

export default DetailsGroup;
