import React from 'react';
import { Image, Rect } from 'react-konva';
import SwapImage from './images/SwapDrop.png';
import { LocAndSize, Point } from '../types/SizeAndPosTypes';
import { useCachedImage } from '../imageHelp/useCachedImage';



interface Props {
    ptCtr: Point;
    size: number;
}

const SwapDropSymbol = (props: Props) => {
    const [image, imageStatus, /*imgSize*/] = useCachedImage(SwapImage);

    const imageReady = (imageStatus === 'loaded');

    const loc: LocAndSize = {
        x: props.ptCtr.x - (props.size / 2),
        y: props.ptCtr.y - (props.size / 2),
        width: props.size,
        height: props.size
    };


    if (imageReady) {
        return (
            <Image
                image={image}
                { ...loc }
            />
        );
    }
    else {
        return (
            <Rect
                fill={'lightblue'}
                {...loc}
            />
        );
    }
}

export default SwapDropSymbol;

