import {
    ChoiceGroupTipInfo,
    ChoiceGroupType,
    ChoiceGrpTipCallback,
    ChoiceInfo,
    ChoicesGroup,
    ChoicesSubgroup,
    MessageCategory,
    MessageType,
    StatusLevel
} from "../types/MessageTypes";
import { logger } from "./Logger";


// Adds a new subgroup to the choices group
// using the data provided. Returns the offset
// of the new subgroup added.
export const addChoiceSubgroup = (
    group: ChoicesGroup,
    label: string
): number => {
    const selInfo = new Array<ChoiceInfo>();
    group.subgroups.push({
        label: label,
        selectionInfo: selInfo,
        generalTipInfo: undefined,
        situationalTipInfo: undefined
    });

    return (group.subgroups.length - 1);
}

// Create a new ChoicesGroup of the given type.
// Initially creates a single subgroup with the given
// label and the provided collection of selectionInfo.
// If none is provided, creates an empty collection.
export const makeChoicesGroup = (
    type: ChoiceGroupType,
    label: string
    //choices?: ChoiceInfo[]
): ChoicesGroup => {

    // Create an empty group of the requested type.
    const group: ChoicesGroup = {
        type: type,
        subgroups: new Array<ChoicesSubgroup>()
    };

    // Add a subgroup with data provided.
    addChoiceSubgroup(group, label);

    return group;
}

export const addChoiceInfo = (group: ChoicesGroup, info: ChoiceInfo, subGrpIdx = 0) => {
    if (group.subgroups.length > subGrpIdx) {
        group.subgroups[subGrpIdx].selectionInfo.push(info);
    }
    else {
        throw new Error('ERROR: addChoiceInfo called on group with NO subgroups!');
    }
}

// Helper to add a choice to the FIRST
// subgroup in the incoming ChoicesGroup.
export const addChoice = (
    group: ChoicesGroup,
    choiceLabel: string,
    choiceId: string,
    subGrpIdx = 0,
    disabled?: boolean
) => {
    const choiceInfo: ChoiceInfo = {
        label: choiceLabel,
        id: choiceId
    };

    if (disabled === true) {
        choiceInfo.disabled = true;
    }

    addChoiceInfo(group, choiceInfo, subGrpIdx);
}

export const getSubgroupMatch = (
    subgroup: ChoicesSubgroup,
    id: string
): ChoiceInfo | undefined => {

    for (let idx = 0; idx < subgroup.selectionInfo.length; idx++) {
        const choice = subgroup.selectionInfo[idx];
        if (choice.id === id) {
            return choice;
        }
    }

    return undefined;
}

export const getGroupChoiceMatch = (
    group: ChoicesGroup,
    id: string
): ChoiceInfo | undefined => {

    for (let idx = 0; idx < group.subgroups.length; idx++) {
        const match = getSubgroupMatch(group.subgroups[idx], id);
        if (match) {
            return match;
        }
    }

    return undefined;
}

export const getFirstSubgroupChoicePossible = (subgroup: ChoicesSubgroup): string => {
    for (let idx = 0; idx < subgroup.selectionInfo.length; idx++) {
        const choice = subgroup.selectionInfo[idx];
        if ((choice.disabled !== true) &&
            (choice.id.length > 0)) {
            return choice.id;
        }
    }

    return '';
}

export const getFirstGroupChoicePossible = (group: ChoicesGroup): string => {
    for (let idx = 0; idx < group.subgroups.length; idx++) {
        const id = getFirstSubgroupChoicePossible(group.subgroups[idx]);
        if (id.length > 0) {
            return id;
        }
    }

    return '';
}


export const resetChoiceGroupTipInfo = (
    group: ChoicesGroup,
    type: MessageType,
    subGrpIdx: number = 0
) => {
    if (group.subgroups.length > subGrpIdx) {
        const subgroup = group.subgroups[subGrpIdx];

        switch (type) {
            case MessageType.GeneralInfo:
                subgroup.generalTipInfo = undefined;
                break;

            case MessageType.Situational:
                subgroup.situationalTipInfo = undefined;
                break;

            default:
                logger.error('Unexpected type in resetChoiceGroupTipInfo');
        }
    }

}

export const setChoiceGroupTipInfo = (
    group: ChoicesGroup,
    level: StatusLevel,
    category: MessageCategory,
    type: MessageType,
    callback: ChoiceGrpTipCallback,
    subGrpIdx: number = 0
) => {
    if (group.subgroups.length > subGrpIdx) {
        const subgroup = group.subgroups[subGrpIdx];

        const info: ChoiceGroupTipInfo = {
            level: level,
            category: category,
            type: type,
            callback: callback
        }

        switch (type) {
            case MessageType.GeneralInfo:
                subgroup.generalTipInfo = info;
                break;

            case MessageType.Situational:
                subgroup.situationalTipInfo = info;
                break;

            default:
                logger.error('Unexpected type in setChoiceGroupTipInfo');
        }
    }
}