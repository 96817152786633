import React, { ReactNode, useRef } from 'react';
import { getSettingLabelColor, SettingIconButton } from './SettingIconButton';
import './Settings.scss';
import '../styles/Meteor.scss'

export enum HdrErrState {
	none = 'none',
	info = 'info',
	warning = 'warning',
	error = 'error',
}

export interface SettingHeaderProps {
	state: HdrErrState;
	message?: string;
	title: string;
} 

export const SettingHeader = (props: SettingHeaderProps) => {
	const _iconButton = useRef<ReactNode>(null);
	const prevMessage = useRef<string>('');
	const prevState = useRef<HdrErrState>(HdrErrState.none);

	// Note: useEffect() was one step behind or completely out of synch
	// with changes. If we have a state or message change...
	if (props.state !== prevState.current || (props.message && props.message !== prevMessage.current)) {
		_iconButton.current = <SettingIconButton message={props.message} state={props.state} iconSize={'14'} />
	}

	// For the header, keep the text the standard color
	// when we have a warning.
	const clrText = (props.state === HdrErrState.error ? getSettingLabelColor(props.state, '#404040') : '#404040');

	return (
		<div className="setting-control-header">
			<label className="a-type__caption" style={{ color: clrText } } >{props.title}</label>
			{_iconButton.current}
		</div>
	);
}
