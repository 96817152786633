
export const getIntValFromString = (s: string | undefined): number => {
    if (s) {
        return parseInt(s);
    }
    else {
        return 0;
    }
}

export const isTrueStringValue = (s: string): boolean => {
    return (s === '1');
}
