import React, {
	useEffect,
	useState,
	useCallback,
	useRef
} from 'react';
import './PointEntry.scss'
import '../styles/Meteor.scss'
//import * as IOMod from '../model/IOModule';
import { PointEntryHeader } from './PointEntryHeader';
import { IOEntryModeEnum } from '../types/SettingsTypes';
import {
	ComponentRenderInfo,
	DesignPageChildID,
	getDesignPageCompRenderInfo,
	registerDesignPageCompRenderInfo,
	unregisterDesignPageCompRenderInfo
} from '../settings/DesignPageComponentRenderInfo';
import { getLocAttributeSetting } from '../model/GuidedSelection';
import { ChassisProject, LocAttributeInfo } from '../types/ProjectTypes';
import { PointEntryInfo, PointEntrySectionInfo } from '../types/IOPointEntryTypes';
import {
	IOBitset,
	IOFilterMasks,
	PointTypeFilterMask,
	IOPoints
} from '../types/IOModuleTypes';
import { getIOFilterMasksFromLocationAttr } from '../model/IOModule';
import { PointEntry } from './PointEntry';
import { EngInfoIOModule } from '../engData/EngineeringInfo';
import { calcModQtyFromPoints, getDefaultIOModuleCatalog, findClosestIOModMatch } from '../implementation/ImplHardwareGen';
import { getLocationSettings } from '../model/ChassisProject';
import { setConfigModified } from '../userProject/UserProjectHelp';

/// Flags to turn on/off component features.
export const overrideShowAddButton = false;
export const overrideShowAllEntries = false;
export const overrideShowDeleteButton = false;
export const overrideShowModuleSelections = false; // Will show module combo in Basic I/O Entry Mode.
// Features
export const overrideShowConsolidateModuleSelections = false;
export const overrideShowModuleCount = false;

const resolvePointEntries = (info: PointEntrySectionInfo, locAttrInfo: LocAttributeInfo) => {
	// We need to resolve the new point counts. We will walk the
	// groups and their entries and populate 2 maps. One is catalog
	// to accumulated points (input/ouput). The other is catalog to
	// an array of entries. The idea here is to CONSOLIDATE the 
	// same modules selected in more than 1 entry.
	const mapCatalogToPoints = new Map<string, IOPoints>();
	const mapCatalogToEntries = new Map<string, PointEntryInfo[]>();

	// Hardcode Attribute - 'SC' Spare I/O Capacity. We are treating
	// this as NON-PLATFORM Specific (for now). Either a platform has
	// it or they do not and the meaning will always be the same!
	let percentSpareIO = 0;
	const settingSpareIO = getLocAttributeSetting(locAttrInfo, 'SC');
	if (settingSpareIO) {
		percentSpareIO = Number(settingSpareIO.selectedOption.id) / 100;
	}

	info.entries.forEach((entry) => {
		if (entry.invalidEntry === false) {
			// Handle the Basic Entry...
			if (entry.basicModule) {
				const userPoints: IOPoints = { input: 0, output: 0, selfCfg: 0 };
				if ((entry.typeID & IOBitset.Input) !== 0)
					userPoints.input += Math.ceil(entry.points + (percentSpareIO * entry.points));
				else
					userPoints.output += Math.ceil(entry.points + (percentSpareIO * entry.points));

				entry.basicModCount = calcModQtyFromPoints(locAttrInfo.platform, entry.basicModule, userPoints);
			}

			// Handle the Advanced Entry
			// If we have a selected module (and we should)...
			if (entry.advancedModule != null) {
				// Are the points input or output... From the TypeID, which is
				// a bitset, add the points to input or output.
				const userPoints: IOPoints = { input: 0, output: 0, selfCfg: 0 };
				if ((entry.typeID & IOBitset.Input) !== 0)
					userPoints.input += Math.ceil(entry.points + (percentSpareIO * entry.points));
				else
					userPoints.output += Math.ceil(entry.points + (percentSpareIO * entry.points));

				// If the Advanced Entry is NOT consolidated, meaning it
				// is a standalone entry, calculate the module quantity for
				// this entry ONLY. Otherwise, process the entry into our maps.
				// Note: Consolidation functionality is hidden at this point, 
				// which means we will treat each entry 
				if (entry.consolidateModule === false) {
					const quantity = calcModQtyFromPoints(locAttrInfo.platform, entry.advancedModule, userPoints);
					entry.moduleSelInMultipleEntries = false;
					entry.advancedModCount = quantity;
					entry.invalidEntry = (quantity < 0);
				}
				else {
					// Tally the points in our map. If we have an entry
					// for the catalog, add the user points. Otherwise,
					// add a new entry to the map.
					const points = mapCatalogToPoints.get(entry.advancedModule);
					if (points != null) {
						points.input += userPoints.input;
						points.output += userPoints.output;
					}
					else {
						mapCatalogToPoints.set(entry.advancedModule, userPoints);
					}

					// Add the entry to the array of entries for the catalog.
					const arrEntries = mapCatalogToEntries.get(entry.advancedModule);
					if (arrEntries == null)
						mapCatalogToEntries.set(entry.advancedModule, [entry]);
					else
						arrEntries.push(entry);
				}
			}
		}
	});

	// Now walk the points map... This ONLY applies to Advanced Selections!
	mapCatalogToPoints.forEach((points, catalog) => {
		// Get the array of entries associated with the catalog.
		const entries = mapCatalogToEntries.get(catalog);
		if (entries != null) {
			// Call our component manager to calculate the total
			// module count based off of the accumulated user points.
			// Note: if the manager does NOT find the I/O module
			// or the module cannot support the selected point
			// types, the function will return a -1.
			let quantity = calcModQtyFromPoints(locAttrInfo.platform, catalog, points);
			const invalidEntry = (quantity < 0);
			if (invalidEntry)
				quantity = 0;

			// Update each entry with the module count and whether
			// or not the module is selected in multiple entries.
			const multiEntry = (entries.length > 1);
			entries.forEach((entry) => {
				entry.advancedModCount = quantity;
				entry.moduleSelInMultipleEntries = multiEntry;
				entry.invalidEntry = invalidEntry;
			});
		}
	});
}

const _resolveAdvancedModOnExtMaskChanged = (entry: PointEntryInfo, extMasks: IOFilterMasks, platform: string) => {
	// When this function is called, the default module
	// has been set ing the Basic-Mode Module.
	if (entry.basicModule == null) {
		// Should never get here if we do not have a default!
		entry.invalidEntry = true;
		return;
	}

	const catDefaultModule = entry.basicModule;

	// Reset the exclude filter for the entry. We only want
	// to use the exclude mask from the project settings.
	entry.filterExclude = 0;

	// Start the new include filter as the type (DI/DO/AI/AO)
	// with any filter bits included. Note: we NEVER include
	// any bits from the project's extMasks in the entry filters,
	const oldIncludeFilter = (entry.filterInclude & PointTypeFilterMask);
	entry.filterInclude = entry.typeID | oldIncludeFilter;

	// Determine the current entry selection. We can
	// have cases where this is undefined. Note: Even
	// though can set the module catalog to the default,
	// we MUST continue so that we can query compatible
	// module options for the entry's combo selections.
	let oldSelection = '';
	if (entry.advancedModule != null) {
		oldSelection = entry.advancedModule;

		// If the old module is a default module...
		if (entry.isAdvModDefault) {
			// Set the old selection to the new default.
			oldSelection = catDefaultModule;
		}
	}
	else {
		// Set the old selection to the new default.
		oldSelection = catDefaultModule;
		entry.isAdvModDefault = true;
	}

	// Let our first attempt use the Filter, which may have other
	// filter bits in it, and the external include mask...
	const compatibleModules: EngInfoIOModule[] = [];
	let maskInclude = entry.filterInclude | extMasks.includeMask;
	let closestMatch = findClosestIOModMatch(platform, oldSelection,
		maskInclude, extMasks.excludeMask, compatibleModules);

	// If we did not get a result...
	if (closestMatch == null) {
		// If we had any filter bits...
		if (entry.filterInclude !== entry.typeID) {
			// Clear any filter bits & try again.
			entry.filterInclude = entry.typeID;
			maskInclude = entry.filterInclude | extMasks.includeMask;
			closestMatch = findClosestIOModMatch(platform, oldSelection,
				maskInclude, extMasks.excludeMask, compatibleModules);
		}
	}

	if (closestMatch == null) {
		entry.invalidEntry = true;
	}
	else {
		entry.invalidEntry = false;
		entry.advancedModule = closestMatch.catNo;
		entry.moduleCatalogs = compatibleModules.map(x => { return x.catNo });
		entry.moduleDisplayStrs = compatibleModules.map(x => { return x.displayString });
		if (entry.isAdvModDefault === false)
			entry.isAdvModDefault = (closestMatch.catNo === catDefaultModule);
	}
}


const resolveExternalMaskChanged = (info: PointEntrySectionInfo, locAttrInfo: LocAttributeInfo) => {

	// Get the new masks
	const extMasks = getIOFilterMasksFromLocationAttr(locAttrInfo);

	info.entries.forEach((entry) => {
		// Reset the invalid flag.
		entry.invalidEntry = false;

		// Get the default Module
		const catDefault = getDefaultIOModuleCatalog(entry.typeID, locAttrInfo);
		if (catDefault != null) {
			entry.basicModule = catDefault;
			_resolveAdvancedModOnExtMaskChanged(entry, extMasks, info.platform);
		}
		else {
			// We do NOT have a default module!
			entry.invalidEntry = true;
		}
	});
}

interface Props {
	project: ChassisProject;
	info: PointEntrySectionInfo;
	onModuleListChanged: () => void; // Callback to notify parent when modules change 
	contentChanged: () => void;
}


export const PointEntrySection = (props: Props) => {
	const [/*renderCnt*/, setRenderCnt] = useState(0);

	const onPointSelectionChanged = useCallback(() => {
		const loc = getLocationSettings(props.project);
		resolvePointEntries(props.info, loc);
		props.onModuleListChanged();

		// IOMod.debugLogPointTypeGroup(pointGroups); // Uncomment for console dump.

		setConfigModified(true);
		props.contentChanged();

		const info = getDesignPageCompRenderInfo(DesignPageChildID.PointEntrySection);
		if (info)
			info.setRenderCount(++info.renderCount);
	}, [props]);

	const ioEntrySectionDiv = useRef<HTMLDivElement>(null);
	const paddingForVertScroll = useRef<string>('0px');
	useEffect(() => {
		// See comments for 'paddingBottom' definition below.
		if (ioEntrySectionDiv.current) {
			// We are adding a 4px fudge-factor.
			paddingForVertScroll.current = `${ioEntrySectionDiv.current.offsetTop + 4}px`;
		}

		// Register our refresh hooks so that other comps can use them.
		const hook: ComponentRenderInfo = { componentID: DesignPageChildID.PointEntrySection, renderCount: 1, setRenderCount: setRenderCnt };
		registerDesignPageCompRenderInfo(hook);

		return (() => unregisterDesignPageCompRenderInfo(hook));
	}, []);

	const locAttrInfo = getLocationSettings(props.project);

	useEffect(() => {
		if (props.info.externalMaskChanged === true) {
			props.info.externalMaskChanged = false;
			resolveExternalMaskChanged(props.info, locAttrInfo);
			resolvePointEntries(props.info, locAttrInfo);
			props.onModuleListChanged();
		}
		else if (props.info.externalPercentSpareIOChanged === true) {
			props.info.externalPercentSpareIOChanged = false;
			resolvePointEntries(props.info, locAttrInfo);
			props.onModuleListChanged();
		}
	}, [props, locAttrInfo, props.info.externalMaskChanged, props.info.externalPercentSpareIOChanged]);


	// 2024.1.24 If we do not have a location or the
	// Point Entry Section is not initialized...
	if (locAttrInfo == null || locAttrInfo.pointEntrySection.initialized === false) {
		return null;
	}

	const advancedMode = props.project.config.IOEntryMode === IOEntryModeEnum.Advanced;
	let nextKey = 1;
	const entryCount = props.info.entries.length;

	// 2023.11.3 There were issues with the vertical scrolling of
	// the point entry section. The content extent was based from
	// the TOP of the page, not the TOP of the P.E. section <div>.
	// The paddingForVertScroll is the Offset of the section <div>
	// to the top of the page. By adding bottom padding to the
	// section <div>, we essentially extend the extent of the
	// content to compensate. Now the vertical scrollbar will show
	// when the browser starts to cover the lower Point Entries.
	const paddingBottom = (paddingForVertScroll.current ? paddingForVertScroll.current : '0px');

	return (
		<div ref={ioEntrySectionDiv} className="point-entry-section" style={{ paddingBottom: paddingBottom }}>
			<PointEntryHeader
				advancedMode={advancedMode}
				info={props.info}
				onSelectionChanged={() => onPointSelectionChanged()}
			/>
			{
				props.info.entries.map((entry, index) => {
					entry.indexEntry = index;
					entry.OnChanged = onPointSelectionChanged;
					return (
						<PointEntry
							project={props.project}
							entry={entry}
							key={nextKey++}
							groupEntries={entryCount}
						/>
					);
				})
			}
		</div>
	);
}
