import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { ImportedMuiIcon } from "../util/IconHelp";

interface ProjectStatusAccordionRowProps {
    title: string;
    titleicon?: ImportedMuiIcon
    details: ReactNode;
}

const ProjectStatusAccordionRow = (props: ProjectStatusAccordionRowProps) => {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


    return (
        <Accordion
            disableGutters={true}
            sx={{
                minWidth: "740px",
                "&:before": {
                    display: "none",
                },

            }}
            elevation={0}
            expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                sx={{
                    p: "4px",
                    color: "#2D2D2D",
                    minHeight: "24px",
                    borderBottom: "1px solid #E5E6E9",
                    ".MuiAccordionSummary-content": {
                        margin: 0
                    },
                }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography
                    sx={{
                        fontSize: "18px",
                        lineHeight: "24px",
                        fontWeight: 500,
                    }}
                >
                    {props.titleicon && <props.titleicon />}
                    {props.title}
                </Typography>

            </AccordionSummary>
            <AccordionDetails
                sx={{
                    py: "4px",
                    px: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                }}
            >
                {props.details}
            </AccordionDetails>
        </Accordion>

    )
}

export default ProjectStatusAccordionRow;