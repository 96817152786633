
// Spec for what we want to log.
// NOTE: Each entry added to the following will ALSO need to have a
// corresponding function added to the ConsoleLogger class below.
interface LogMsgSpec {
    logMessages: boolean;  // like console.log
    logWarnings: boolean;  // like console.warn
    logErrors: boolean;    // like console.error
    logRender: boolean;    // reserved for render tracking use
    logContext: boolean;   // reserved for context-related use
    logImage: boolean;     // reserved for use by ImageCache and useCachedImage
    logData: boolean;      // reserved for use by data loading and related
    logImpl: boolean;      // reserved for platform implementation routing use
    logTracking: boolean;  // reserved for event tracking
    logStartup: boolean;   // reserved for application startup use.
    logAuth: boolean;      // reserved for user state tracking, etc.
    logUser: boolean;      // reserved for user state tracking, etc.
    logPersist: boolean;   // reserved for localStorage persistence and related
    logProdData: boolean;  // reserved for device cache and related
    logCustom: boolean;    // special-purpose as needed
}

// Configurable by developer.
// Settings will only affect runs in dev mode.
const _logSpecDev: LogMsgSpec = {
    logMessages: true,
    logWarnings: true,
    logErrors: true,
    logRender: false,
    logContext: false,
    logImage: false,
    logData: false,
    logImpl: true,
    logTracking: false,
    logStartup: false,
    logAuth: false,
    logUser: false,
    logPersist: false,
    logProdData: false,
    logCustom: false
};


// Standards for production.
// Any changes should be made carefully 
// and only if/as needed for testing, etc.
const _logSpecProd: LogMsgSpec = {
    logMessages: false,
    logWarnings: true,
    logErrors: true,
    logRender: false,
    logContext: false,
    logImage: false,
    logData: false,
    logImpl: false,
    logTracking: false,
    logStartup: false,
    logAuth: false,
    logUser: false,
    logPersist: false,
    logProdData: false,
    logCustom: false
};

// Determine if we're running in dev mode.
const _devBuild = process.env.NODE_ENV === 'development';

// Choose the log spec we'll use based on mode.
// Note that we DO export this. That allows a client to
// do something like group a bunch of calls inside a 
// conditional block to avoid several calls for special-
// purpose logging: if (LogSpec.logCustom) {... }
export const LogSpec = _devBuild ? _logSpecDev : _logSpecProd;


// Standard form of console.? functions (log, warn, error).
interface LogFunc {
    (what: unknown, ...optionalParams: unknown[]): void;
}

// Replacement 'do nothing' function.
const LOG_NO_OP = () => null;


/** Basic logger interface */
interface ILogger {
    log: LogFunc;
    warn: LogFunc;
    error: LogFunc;
    logRender: LogFunc;
    logContext: LogFunc,
    logImage: LogFunc,
    logData: LogFunc,
    logImpl: LogFunc,
    logTracking: LogFunc,
    logStartup: LogFunc,
    logAuth: LogFunc,
    logUser: LogFunc,
    logPersist: LogFunc,
    logProdData: LogFunc,
    logCustom: LogFunc
}


/** Logger which outputs to the browser console */
export class ConsoleLogger implements ILogger {
    readonly log: LogFunc;
    readonly warn: LogFunc;
    readonly error: LogFunc;
    readonly logRender: LogFunc;
    readonly logContext: LogFunc;
    readonly logImage: LogFunc;
    readonly logData: LogFunc;
    readonly logImpl: LogFunc;
    readonly logTracking: LogFunc;
    readonly logStartup: LogFunc;
    readonly logAuth: LogFunc;
    readonly logUser: LogFunc;
    readonly logPersist: LogFunc;
    readonly logProdData: LogFunc;
    readonly logCustom: LogFunc;

    constructor() {
        // Tie each of our functions to the associated console
        // function or our NO_OP, depending on whether we want
        // to actually log the associated type or not.
        // General functions matching console functions.
        this.log = LogSpec.logMessages ? console.log.bind(console) : LOG_NO_OP;
        this.warn = LogSpec.logWarnings ? console.warn.bind(console) : LOG_NO_OP;
        this.error = LogSpec.logErrors ? console.error.bind(console) : LOG_NO_OP;

        // Special-use functions. These will generally be bound
        // to the console.log function, but COULD use console.warn
        // or console.error. Either of those shows the message in 
        // color in the console.
        this.logRender = LogSpec.logRender ? console.log.bind(console) : LOG_NO_OP;
        this.logContext = LogSpec.logContext ? console.log.bind(console) : LOG_NO_OP;
        this.logImage = LogSpec.logImage ? console.warn.bind(console) : LOG_NO_OP;
        this.logData = LogSpec.logData ? console.warn.bind(console) : LOG_NO_OP;
        this.logImpl = LogSpec.logImpl ? console.warn.bind(console) : LOG_NO_OP;
        this.logTracking = LogSpec.logTracking ? console.warn.bind(console) : LOG_NO_OP;
        this.logStartup = LogSpec.logStartup ? console.warn.bind(console) : LOG_NO_OP;
        this.logAuth = LogSpec.logAuth ? console.warn.bind(console) : LOG_NO_OP;
        this.logUser = LogSpec.logUser ? console.warn.bind(console) : LOG_NO_OP;
        this.logPersist = LogSpec.logPersist ? console.warn.bind(console) : LOG_NO_OP;
        this.logProdData = LogSpec.logProdData ? console.log.bind(console) : LOG_NO_OP;
        this.logCustom = LogSpec.logCustom ? console.log.bind(console) : LOG_NO_OP;
    }
}

export const logger = new ConsoleLogger();