import React, { useState } from 'react';
import ExpandCollapseBtn from '../treeShared/ExpandCollapseBtn';
import './ProjectTree.scss';
import { Chassis, SelectableDevice } from '../types/ProjectTypes';
import { chassisChanged, getChassisName, updateRackLayout } from '../model/ChassisProject';
import ProjTreeNameEditor from './ProjTreeNameEditor';
import { chassisChanging } from '../util/UndoRedo';
import { LogRender, setTextEditInProgress } from '../types/Globals';
import { logger } from '../util/Logger';
import { IconButton, Tooltip } from '@mui/material';
import { EditIcon } from '@rockwell-automation-inc/ra-meatball';

interface Props {
	chassis: Chassis;
	showSelected: boolean;
	expanded: boolean;
	changeExp: () => void;
	onItemClick: (chassis: Chassis, device: SelectableDevice | undefined) => void;
	contentChanged: () => void;
}

const ProjTreeChassisHdr = (props: Props) => {
	const [editMode, setEditMode] = useState(false);

	const onClickEditName = () => {
		if (!editMode) {
			setEditMode(true);
			setTextEditInProgress(true);
		}
	}

	const onNameEditFinished = (cancelled: boolean, newName: string | undefined) => {
		// If editing wasn't cancelled...
		if (!cancelled) {

			// And the new name is actually different from
			// the existing name on the chassis...
			if (props.chassis.name !== newName) {

				// BEFORE making any change, call our helper
				// to handle any related undo/redo work for us.
				chassisChanging(props.chassis);

				// Set the .name prop on the chassis.
				props.chassis.name = newName;

				// Bump it so the name label will actually
				// get re-rendered in the layout.
				chassisChanged(props.chassis);

				if (props.chassis.parent) {
					updateRackLayout(props.chassis.parent);
				}

				props.contentChanged();
			}
		}
		setEditMode(false);
		setTextEditInProgress(false);
	}

	const onPointerDown = () => {
		// NOTE: Replace bring-into-view functionality!!
		props.onItemClick(props.chassis, undefined);
	}

	let headerText = getChassisName(props.chassis);
	if (!props.chassis.isPlaceholder) {
		headerText += ('  : ' + props.chassis.catNo);
	}

	// Extra stlye attributes. Essentially, we want to
	// include a border if our associated chassis is
	// selected, but ONLY IF we're NOT currently in
	// name edit mode. Our edit control stands out and
	// looks better if not crowded inside of the border.
	//const showAsSelected = (props.chassis && props.chassis.selected && !editMode)

	// Programatically determine the related styles we want.
	const styleAttrs = {
		backgroundColor: props.showSelected ? 'rgba(33, 150, 243, 0.08)' : '#FFFFFF',
		border: '1px solid rgba(255,255,255,0)',
		borderRadius: '3px',
		minHeight: '48px',
		height: 'auto',

	};

	if (LogRender.ProjTree) {
		logger.logRender('ProjTreeChassisHdr: ' + props.chassis.name);
	}

	return (
		<div
			className="proj-tree-chassis-hdr"
			style={{ ...styleAttrs }}
			onPointerDown={onPointerDown}
		>
			<ExpandCollapseBtn
				expanded={props.expanded}
				changeExp={props.changeExp}
			/>
			{editMode
				? <ProjTreeNameEditor
					startValue={props.chassis.name ? props.chassis.name : ''}
					onFinished={onNameEditFinished}
				/>
				: <>
					<div
						className="proj-tree-chassis-hdr-text"
						onDoubleClick={onClickEditName}
					>
						<span>{headerText}</span>
					</div>
					{props.showSelected ?
						<Tooltip title='edit name'>
							<IconButton
								disableRipple
								color='primary'
								size='small'
								onClick={onClickEditName}
							>
								<EditIcon fontSize='large' />
							</IconButton>
						</Tooltip>
						:
						null
					}
				</>
			}
		</div>
	);
}

export default React.memo(ProjTreeChassisHdr);

