import { DEV_DEBUG } from "../types/Globals";
import { 
    ILoggerSpec, 
    IClientOptions,
    IClientConfig,
    IConfigSpec
} from "platform-services";
import { setConfigModified } from "../userProject/UserProjectHelp";
import axios from "axios";


const csaOnSigninRedirect = () => {
    // We're being notified by platform-services that we're
    // GOING TO BE redirected AWAY from our current page to
    // a login page. Reset our modified flag to prevent
    // the 'losing your data' alert when we leave. If our
    // config WAS modified, that state will be restored when
    // we come back and reload from a localStorage snapshot.
    setConfigModified(false);
}

const logSpec: ILoggerSpec = DEV_DEBUG
    ? {
        log: false,
        info: false,
        warn: true,
        error: true,
        debug: false,
        auth: false,
        user: false,
        custom: false,
    }
    : {
        log: false,
        info: false,
        warn: true,
        error: true,
        debug: false,
        auth: false,
        user: false,
        custom: false,
    };

// 0: Log.NONE
// 1: Log.ERROR
// 2: Log.WARN
// 3: Log.INFO
// 4: Log.DEBUG

const clientOpts: IClientOptions = {
    onSignInRedirect: csaOnSigninRedirect,
    userMgrLogLevel: DEV_DEBUG ? 2 : 2,

    // Uncomment the following to have unexpired
    // user attempt a silent-renew in 30 seconds.
    //forceSecondsToExpire: 90
}


// NOTE: The following is a TEMPORARY workaround.
// In platform-services default configSpec for local (DEV_DEBUG)
// runs, it sets the RA_HEADER_ORIGIN property to:
//       'https://dev-aem.rockwellautomation.com'
// which is essentially the DEV site for
//       https://www.rockwellautomation.com
// At times (like when this was added), that DEV site, doesn't
// work correctly when we try to log in via the blue login bar.
// In a future version of p-s, the RA_HEADER_ORIGIN prop will
// be set to use https://www.rockwellautomation.com for all build
// versions. Until then, we'll provide our own override.
const _cfgSpec: IConfigSpec | undefined = DEV_DEBUG
    ? {
        ISD_URL: "https://localhost:3000",
        PW_API: "https://productadvisor-bomapidev.rockwellautomation.com",
        WEB_API: "https://productadvisor-apidev.rockwellautomation.com",
        BOM_API: "https://productconfigurationapi.advisordev.rockwellautomation.com",
        IDENTITY_SERVER:
            "https://as-external-advisor-centralus-dev-adfs-proxy.azurewebsites.net",
        GUIDESEL_API:
            "https://guidedselection.advisordev.rockwellautomation.com/api/GuidedSelections",
        COUNTRY_API:
            "https://guidedselection.advisordev.rockwellautomation.com/api/Countries",
        INDUSTRY_API:
            "https://guidedselection.advisordev.rockwellautomation.com/api/Industries",
        PRODSEL_API:
            "https://controlsystemhardwaregen.advisordev.rockwellautomation.com/api/ProductSelections",
        PLATFORMDATA_API:
            "https://controlsystemengineeringdata.advisordev.rockwellautomation.com/EngineeringData/GetEngineeringDataByBusinessType",
        PRODUCT_CATALOG: "https://advisordev.rockwellautomation.com/",
        PW_SITE: "https://advisordev.rockwellautomation.com/projects/",
        PCA_SITE: "https://powercontrol.advisordev.rockwellautomation.com/",
        RA_HEADER_ORIGIN: "https://www.rockwellautomation.com",
        PROJECTS_API: "https://projectapi.advisordev.rockwellautomation.com",
        CONTROLSYSTEMCONFIGURATION_API:
            "https://controlsystemconfigurationapi.advisordev.rockwellautomation.com",
        PROJECTS_SITE: "https://projects.advisordev.rockwellautomation.com/",
        CS_PAGE: "https://advisordev.rockwellautomation.com/systemdesigner/controlsystem",
        SERVER_ENV: "DEV" // ('DEV', 'QA', or 'PROD') 
    }
    : undefined;


export const psClientCfg: IClientConfig = {
    clientAppName: 'Control System Advisor',
    identityConfig: undefined, // use p-s default
    configSpec: _cfgSpec,      // generally use ps-default
    axiosInstance: axios,
    options: clientOpts,
    loggerSpec: logSpec,
}

