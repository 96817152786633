import { LocAndSize } from "../../../types/SizeAndPosTypes";
import { ChassisLayoutInfo } from "../../../types/ProjectTypes";
import { ImageInfo } from "../../common/CommonPlatformTypes";


//export interface ImageInfo {
//    imgSrc: string;
//    width: number;
//    height: number;
//}

export const CLXChassisImages = {
    emptySlotStd: 'EmptySlotStd',
    emptySlotXT: 'EmptySlotXT',
    grpSepStd: 'GrpSepStd',
    slotSepXT: 'SlotSepXT',
    rightCapStd: 'RightCapStd',
    rightCapXT: 'RightCapXT',
    none: ''
};

export const CLXEmptySlotImages = {
    standard: 'EEE',
    XT: 'XTE'
};

export const CLXGrpSepImages = {
    standard: 'GS',
    XT: ''
};

export const CLXSlotSepImages = {
    standard: '',
    XT: 'SSS'
};

export const CLXRightCapImages = {
    standard: 'RC',
    XT: 'RCXT'
};

export const CLXSizes = {
    height: 280,
    widthStdSlot: 70,
    widthXTSlot: 70,
    widthStdGrpSep: 20,
    widthXTGrpSep: 0,
    widthStdSlotSep: 0,
    widthXTSlotSep: 30,
    widthStdRightCap: 20,
    widthXTRightCap: 57
};


export interface CLXChassisTemplate {
    extendedTemp: boolean;
    conformal: boolean;
    totalSlots: number;
    slotGroups: number[];
    emptySlot: ImageInfo,
    slotSep: ImageInfo,
    grpSep: ImageInfo,
    rightCap: ImageInfo
}

export interface CLXLayoutInfo extends ChassisLayoutInfo {
    numSlots: number;
    emptySlotImgSrc: string;
    slotSepImgSrc: string;
    slotSepLocs: LocAndSize[];
    grpSepImgSrc: string;
    grpSepLocs: LocAndSize[];
}

export const CLXSlotFiller = {
    Standard: '1756-N2',
    XT: '1756-N2XT'
}

