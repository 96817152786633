import React, { useState, FormEvent } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { CloseIcon, SearchIcon } from '@rockwell-automation-inc/ra-meatball';
import {
  Box,
} from "@mui/material";


interface SearchFormProps {
  onSubmit: (searchCriteria: string) => void;
}

const SearchForm = (props: SearchFormProps) => {
  const [query, setQuery] = useState("");

  const handleSearchSubmit = (event: FormEvent) => {
    event.preventDefault();
    props.onSubmit(query);
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };


  const handleCloseClick = () => {
    setQuery("");
    props.onSubmit("");
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      position="relative"
      sx={{ width: "100%" }}
    >
      <Box
        display="flex"
        flexDirection="row"
        position="relative"
        sx={{ width: "100%" }}
      >
        <Paper
          variant="outlined"
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "10px",
            border: "1px solid #D9D9D9",
            position: "relative",
          }}
          onSubmit={handleSearchSubmit}
        >
          <InputBase
            sx={{
              ml: 2,
              flex: 1,
            }}
            placeholder="Search"
            inputProps={{
              "aria-label": "Search",
            }}
            value={query}
            onChange={handleQueryChange}
          />
          <IconButton
            aria-label="Clear"
            sx={{
              borderRadius: "50%",
              padding: "7px",
              color: "#6D6E71",
            }}
            onClick={handleCloseClick}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            type="submit"
            aria-label="Search"
            disableRipple
            sx={{
              padding: "7px 22px 7px 22px",
              borderRadius: "4px",
              color: "#FFFFFF",
              backgroundColor: "#2A79C4",
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
    </Box>
  );
};

export default SearchForm;
