import { LocAttributeInfo } from '../../../types/ProjectTypes';
import { ChassisProject, EnvRating, IOModuleWiring, PSInputVoltage, ChassisCfgGrpCategory, onCfgLocAttrCreatedCallback, createNewLocAttributeInfo } from "../../../types/ProjectTypes";
import { getUserModuleSelectionsFromPointSectionInfo } from "../../../model/IOModule";
import { updateGuidedSelection } from '../../../model/GuidedSelection';
import {
    getMicroModules, getPowerSuppliesMicro
} from "../../../util/EngInfoHelp";
import { logger } from '../../../util/Logger';
import { refreshLocAttrInfoSelectionArray, getLocAttributeSetting } from "../../../model/GuidedSelection";
import { collectHardwareInfo, KeyValuePair, ProdSelCategory } from "../../../model/ProductSelection"
import { makeSettingGroup } from '../../../settings/SettingsHelp';
import { displayAlertMsg } from "../../../util/MessageHelp";
import {  PlatformMicro } from '../../PlatformConstants';
import { convertGuidedSelAttrToAppVal } from '../../../implementation/ImplHardwareGen';
import { IOEntryModeEnum } from '../../../types/SettingsTypes';
import { EngMicroInfoPowerSupply } from '../../../engData/EngineeringInfo';

export const getPowerCatnumberMicroCat = (microPower:EngMicroInfoPowerSupply[], catNumber: string)=>{
    const getExactPower = microPower.filter((powerModule)=> {
        //micro 800 only 3 occurence will happens L20,L50 and L70
        if(catNumber?.indexOf('LC20') !== -1){
            return powerModule.psSupport20 === '1' && powerModule.catNo
        }
        if(catNumber?.indexOf('L50') !== -1){
            return powerModule.psSupport50 === '1' && powerModule.catNo
        }
        if(catNumber?.indexOf('L70') !== -1){
            return powerModule.psSupport70 === '1' && powerModule.catNo
        }
        else{
            return false;
        }
        })  
        return getExactPower
}


export const microGetChassisCatalog = (envRat: EnvRating) => {
    switch (envRat) {
        case EnvRating.ConformalCoated:
            return '2080-CHASSIS-K';

        default:
            return '2080-CHASSIS';
    }
}


export const microPrepareLocAttrHardwareForGen = (loc: LocAttributeInfo, project: ChassisProject) => {
    refreshLocAttrInfoSelectionArray(loc);
   // Start our chassis catalog out as 'standard.
   loc.hardware.catChassis = microGetChassisCatalog(EnvRating.Standard);
    // Hardcode for consistency: Attribute 'ER' - Env. Rating.
    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er)
        loc.hardware.envRating = convertGuidedSelAttrToAppVal(PlatformMicro, er.attrID, er.optionID, true) as EnvRating;
        loc.hardware.catChassis = microGetChassisCatalog(loc.hardware.envRating);
    // Hardcode necessity: Attribute 'CTRL' - defines whether a 
    // configuration will be generated with or without a Controller
    loc.hardware.remoteIOOnly = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'CTRL' && attr.optionID === 'No');

    // Hardcode necessity: Attribute 'CT' - Defines whether I/O 
    // will be placed in Controller chassis or if all I / O will 
    // be in remote chassis. Value 'Ded' for 'dedicated'.
    loc.hardware.ctrlDedicatedChassis = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'CT' && attr.optionID === 'Ded');
    loc.hardware.ioModuleSelections = getUserModuleSelectionsFromPointSectionInfo(loc.pointEntrySection, project.config.IOEntryMode);
    loc.hardware.ioModuleSelections.forEach((sel) => {
        for (let idx = 0; idx < sel.quantity; ++idx)
            loc.hardware.catIOModules.push(sel.catalog);
    });

    loc.hardware.ioModuleCount = loc.hardware.catIOModules.length;
    let percentSpareIO = 0;
	const settingSpareIO = getLocAttributeSetting(loc, 'SC');
	if (settingSpareIO) {
		percentSpareIO = Number(settingSpareIO.selectedOption.id) / 100;
	}

    loc.hardware.spareCapacity = percentSpareIO;




    // Hardcode necessity: Attribute 'CS' - Defines the fixed slot
    // chassis size to be used in the configuration for all chassis.
    // (Auto - size of Ctrl/Remote Chassis(s) depending upon your
    // I/O requirements and Dedicated Ctrl Chassis).
    const additionalAttributes: KeyValuePair[] = [];
    const arrHardware = collectHardwareInfo(loc, additionalAttributes);
    arrHardware.forEach((hwInfo) => {
        switch (hwInfo.category) {
            case ProdSelCategory.Comm:
                loc.hardware.catScanner = hwInfo.mainCatalog;
                break;

            default:
                displayAlertMsg('Unknown Hardware Category: ' + hwInfo.category);
                break;
        }
    });
   
    //Power supply integration
    const selectedPowervalue = getLocAttributeSetting(loc, 'CV');
    const microPower = getPowerSuppliesMicro(PlatformMicro)
    if( selectedPowervalue && (selectedPowervalue.selectedOption.display !== PSInputVoltage.DC24V) && loc.hardware.catScanner){
        const getPowerCatnumberMicro = getPowerCatnumberMicroCat(microPower, loc.hardware.catScanner)
        loc.hardware.catPowerSupply = getPowerCatnumberMicro[0].catNo 
        loc.hardware.catRemotePowerSupplier =getPowerCatnumberMicro[0].catNo
        
       
    }
    // Validate the hardware - we should always have values for
    // the remote I/O chassis/PSU/Comm
    if (!loc.hardware.catChassis)
        throw new Error('Failed to select valid MICRO Remote chassis(s) and PSUs.');

    const getEngData = loc?.hardware?.catScanner && getMicroModules(PlatformMicro, loc?.hardware?.catScanner);

    if(getEngData){
       loc.hardware.numChassisSlot = parseInt(getEngData.Eio) + parseInt(getEngData.plugin);
       loc.hardware.numCtrlChassisSlot= parseInt(getEngData.Eio) + parseInt(getEngData.plugin);
       loc.hardware.ExpansionIO = parseInt(getEngData.Eio);
       loc.hardware.Plugin = parseInt(getEngData.plugin);
    }
    else {
    throw new Error('microCreateChassis FAILED to get template for:');
    }
    return;
}



export interface MICROChassisCfgData {
    envType: EnvRating;
    numSlots: number;
    wiringType: IOModuleWiring;
    highAvail: boolean;
    numRMs: number;
    psVoltage: PSInputVoltage;
    psSelCatalog: string;
    chassisCatalog: string;
    controllerOnly?:string;
    buSelCatalog: string;
}

export const createMICROChassisCfgData = (): MICROChassisCfgData => {
    return {
        envType: EnvRating.Standard,
        numSlots: 10,
        wiringType: IOModuleWiring.Screw,
        highAvail: false,
        numRMs: 0,
        psVoltage: PSInputVoltage.DC24V,
        psSelCatalog: '',
        chassisCatalog: '',
        buSelCatalog: '',
    };
}

let _cfgAttrCallback: onCfgLocAttrCreatedCallback | undefined = undefined;

export const microGetLocAttrInfoForChassisEdit = (platform: string, callback: onCfgLocAttrCreatedCallback) => {
    _cfgAttrCallback = callback;

    const configAttrInfo = createNewLocAttributeInfo(platform, '', '', IOEntryModeEnum.Basic);

    // Note: We skip validation so that everything is
    // reloaded and all options are present (3rd param === true)
    updateGuidedSelection(configAttrInfo, _onGdSelForChassisEditLoaded, true);
}

const _onGdSelForChassisEditLoaded = (success: boolean, locAttrInfo: LocAttributeInfo | undefined) => {
    if (_cfgAttrCallback == null)
        throw new Error('microGetLocAttrInfoForChassisEdit(): callback function undefined!');

    if (success && locAttrInfo) {
        const valid = microPrepareLocAttrForChassisConfig(locAttrInfo);
        _cfgAttrCallback(valid, locAttrInfo);
        return;
    }

    throw new Error('_onGdSelForChassisEditLoaded(): Guided Selection failed!');
}

export const microPrepareLocAttrForChassisConfig = (locAttrInfo: LocAttributeInfo): boolean => {
    // Start by pulling ALL of the settings into a groups.
    // The idea here is to rearrange the settings into 3
    // attribute groups: Cfg Page 1; Cfg Page 2; and all
    // the other settings not in the first 2 pages. The
    // pages relate to the Cfg Dlg Tabs.
    const cfgDlgPage1 = makeSettingGroup(ChassisCfgGrpCategory.Chassis);
    const cfgDlgPage2 = makeSettingGroup(ChassisCfgGrpCategory.Power);
    const cfgNonDisplay = makeSettingGroup(ChassisCfgGrpCategory.Hidden);


    // Note: the settings will be added in the order that
    // they appear in the guide selection, which at this
    // point is the correct order. If that changes, we will
    // need to add a sort function or a smarter way of
    // building out each page's setting array.
    locAttrInfo.attrGroups.forEach((grp) => {
        grp.settings.forEach((setting) => {
            switch (setting.id) {
                case 'ER':
                case 'RTB':
                    cfgDlgPage1.settings.push(setting);
                    break;
                case 'CV':
                    cfgDlgPage2.settings.push(setting);
                    break;
                default:
                    // 2024.4.22 Ignore any errors on
                    // the hidden attributes. These
                    // attributes should NOT impact 
                    // the Edit/Add of a chassis.
                    setting.ignoreError = true;

                    cfgNonDisplay.settings.push(setting);
                    break;
            }
        });
    });

    // Validate we have the correct number of settings in
    // each page. From above, Page1 has 4 and Page2 has 1.
    const valid = (cfgDlgPage1.settings.length === 2 && cfgDlgPage2.settings.length === 1);
    if (valid === false)
        logger.error('microGetLocAttrInfoForChassisEdit(): Error - not all required attribute settings found in Guided Selection.');

    // Dump the original attr groups, and add
    // the new groups.
    locAttrInfo.attrGroups.length = 0;
    locAttrInfo.attrGroups.push(cfgDlgPage1);
    locAttrInfo.attrGroups.push(cfgDlgPage2);
    locAttrInfo.attrGroups.push(cfgNonDisplay);

    return valid;
}

export const microGetChassisCfgDataFromLocAttr = (loc: LocAttributeInfo): MICROChassisCfgData => {
    const data = createMICROChassisCfgData();
    // Refresh the selections in the loc's arrAttributeNameToValue.
    refreshLocAttrInfoSelectionArray(loc);

    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er)
        data.envType = convertGuidedSelAttrToAppVal(PlatformMicro, er.attrID, er.optionID, true) as EnvRating;

    const cv = loc.arrAttributeNameToValue.find(x => x.attrID === 'CV');
    if (cv)
        data.psVoltage = convertGuidedSelAttrToAppVal(PlatformMicro, cv.attrID, cv.optionID, true) as PSInputVoltage;

    const rtb = loc.arrAttributeNameToValue.find(x => x.attrID === 'RTB');
    if (rtb)
        data.wiringType = convertGuidedSelAttrToAppVal(PlatformMicro, rtb.attrID, rtb.optionID, true) as IOModuleWiring;
    const arrHW = collectHardwareInfo(loc, []);
    arrHW.forEach((comp) => {
        switch (comp.category) {
            case ProdSelCategory.Comm:
                data.buSelCatalog = comp.mainCatalog;
                break;
            default:
                break;
        }
    })

    return data;
}