

export enum IOBitset {
    // Bitset to characterized I/O.
    // Note: In Javascript, numbers are 64 bit meaning
    // we can add 8 more hex digits (0x0000000000000000).
    // We might need to migrate these bits to a Platform
    // Specific implementation.
    None = 0x00000000,
    Input = 0x00000001,
    Output = 0x00000002,
    Digital = 0x00000004,
    Analog = 0x00000008,
    HartIO = 0x00000010,
    Conformal = 0x00000020,
    ExTemp = 0x00000040,
    ThermoCouple = 0x00000080,
    RTD = 0x00000100,
    SelfCfg = 0x00000200,
    Combo = 0x00000400,
    DC24 = 0x00000800,
    DC48 = 0x00001000,
    DC120 = 0x00002000,
    AC120 = 0x00004000,
    AC240 = 0x00008000,
    Isolated = 0x00010000,
    SafetyIO = 0x00020000,
    Diagnostic = 0x00040000,
    ElectronicFused = 0x00080000,
    AnalogCurrent = 0x00100000,
    AnalogVoltage = 0x00200000,
    Exclude = 0x80000000, // Bit to indicate 'exclude set bits' (ie ~bits) - used in point entry filtering.
}

export const DIBits = (IOBitset.Digital | IOBitset.Input);
export const DOBits = (IOBitset.Digital | IOBitset.Output);
export const AIBits = (IOBitset.Analog | IOBitset.Input);
export const AOBits = (IOBitset.Analog | IOBitset.Output);

export const InputOutputBits = (IOBitset.Input | IOBitset.Output);
export const DigitalAnalogBits = (IOBitset.Digital | IOBitset.Analog);
export const PointTypeBits = (IOBitset.Input | IOBitset.Output | IOBitset.Analog | IOBitset.Digital | IOBitset.SelfCfg);
export const MainPointTypeMask = (IOBitset.Input | IOBitset.Output | IOBitset.Analog | IOBitset.Digital);

export const PointTypeFilterMask = (IOBitset.HartIO | IOBitset.RTD |
    IOBitset.ThermoCouple | IOBitset.Isolated | IOBitset.ElectronicFused |
    IOBitset.SafetyIO | IOBitset.Diagnostic |
    IOBitset.AnalogCurrent | IOBitset.AnalogVoltage);

export const ControlVoltageMask = (IOBitset.DC24 | IOBitset.DC48 | IOBitset.DC120 | IOBitset.AC120 | IOBitset.AC240);
export const EnvironmentalRatingMask = (IOBitset.Conformal | IOBitset.ExTemp);

export interface IOFilterMasks {
    includeMask: number;
    excludeMask: number;
}


export interface IOPoints {
    input: number;
    output: number;
    selfCfg: number;
}


export interface IOBitNameToValue {
    name: string;
    value: number;
}

// Condensed summary of point entries - UserSelectedPoints/ModuleSelection
export interface UserSelectedPoints {
    typeID: number; // Type of points - Bitset
    userPointCount: number;  // Requested points
}

export interface IOModuleSelection {
    // Note: need an array of selected points for 
    // combo/universal modules.
    selectedPoints: UserSelectedPoints[];
    quantity: number;  // Module Count
    catalog: string;
}

