import React, { useState } from 'react';
import './ConfigAccys.scss';
import {
    addButton,
    ModalRequestSpec,
    ModalStatus,
    requestModal
} from '../modals/ModalHelp';
import {
    ChoicesGroup,
    ChoiceGroupType,
    GroupChoiceInfo,
    StatusLevel,
    createNewGrpChoiceInfo
} from '../types/MessageTypes';
import {
    SelectableDevice
} from '../types/ProjectTypes';
import { displayAlertMsg } from '../util/MessageHelp';
import { chassisChanging } from '../util/UndoRedo';
import ChoiceGroup from '../components/choices/ChoiceGroup';
import { getAccyDetails } from '../util/AccessoriesHelp';


const unexpected = (msg: string) => {
    alert(msg);
    throw new Error(msg);
}


export interface ConfigAccysCBData {
    device: SelectableDevice;
    grpChoiceInfo: GroupChoiceInfo[];
    allSelections: string[];
}


const getGroupSelInfo = (group: ChoicesGroup, devSelections: string[]): GroupChoiceInfo => {

    // Create a sel info object starting with
    // .possibles and .selections arrays.
    // 2024.2.14 GroupChoiceInfo now extends BaseChoiceInfo, which
    // provides a common base interface (with additional info) that
    // was needed in the Guided Selection / Config Chassis task.
    const selInfo: GroupChoiceInfo = createNewGrpChoiceInfo(group, new Array<string>());

    // At least for now, we assume that each accessory
    // group is made up of a single subgroup.
    const subgroup = group.subgroups[0];

    // Walk the group's info objects. For each...
    subgroup.selectionInfo.forEach(info => {

        // We'll always include the given id in
        // our collectons of .possibles for the group.
        //selInfo.possibles.push(info.id);

        // But we'll determine what's actually selected
        // differently based on the group's type.
        switch (group.type) {

            case ChoiceGroupType.RequireOneOf:
                // For RequireOneOf, we'll add this one
                // ONLY if:
                //   - the group doesn't already have a selection, AND
                //   - we find this one's id already in the device's
                //     collection of accessories.
                if (selInfo.selections.length === 0) {
                    if (devSelections.includes(info.id)) {
                        selInfo.selections.push(info.id);
                    }
                }
                break;

            case ChoiceGroupType.Require:
                // For Require, ALL in the group are 
                // REQUIRED to be selected. 
                selInfo.selections.push(info.id);
                break;

            case ChoiceGroupType.Recommend:
            case ChoiceGroupType.Optional:
                // For optional types, we'll include this in our
                // .selections array to begin with if we
                // find the id in the device's collection
                // of accessories.
                if (devSelections.includes(info.id)) {
                    selInfo.selections.push(info.id);
                }
                break;

            // Unexpected.
            default:
                throw new Error('Unexpected accy group type in getGroupSelInfo');
        }
    });

    // Finally, we'll make sure that any RequireOneOf
    // groups actually HAVE a selection. If this group
    //is one of that type...
    if (group.type === ChoiceGroupType.RequireOneOf) {

        // And we have no selections yet...
        if (selInfo.selections.length === 0) {

            // Add the first possible as the selection.
            selInfo.selections.push(subgroup.selectionInfo[0].id);
        }
    }

    return selInfo;
}

const isSameContent = (devAccys: string[] | undefined, newAccys: string[]): boolean => {
    const newQty = newAccys.length;
    if (devAccys) {
        const oldQty = devAccys.length;
        if (oldQty === newQty) {
            if (newQty === 0) {
                return true;
            }
            else {
                const oldSet = new Set<string>();

                devAccys.forEach(accy => {
                    oldSet.add(accy);
                });

                for (let newIdx = 0; newIdx < newAccys.length; newIdx++) {
                    if (!oldSet.has(newAccys[newIdx])) {
                        return false;
                    }
                }

                return true;
            }
        }
        else {
            return false;
        }
    }
    else {
        return (newQty === 0);
    }
}

const configAccysCallback = (status: number, data?: object) => {
    if (status === ModalStatus.Confirmed) {

        // Get our data object.
        const cbData = data as ConfigAccysCBData;

        // If we can, and it has what we need...
        if (cbData && cbData.device && cbData.allSelections) {

            // Compare the selections in the data object
            // to the accys on the device. If any difference...
            if (!isSameContent(cbData.device.accys, cbData.allSelections)) {

                // Call our changing function to take
                // an undo snapshot prior to actually
                // making the accy change.
                if (cbData.device.parent) {
                    chassisChanging(cbData.device.parent)
                }

                // Then set the new accys on the device.
                cbData.device.accys = cbData.allSelections;
            }
        }
        else {
            throw new Error('ERROR: Invalid data in configAccysCallback!');
        }

    }
}

export const configureAccysFor = (device: SelectableDevice) => {

    // Get details about any/all accessories 
    // that are relevant for this device.
    const devAccyGrps = getAccyDetails(device, false);

    // If we get back no group definitions...
    if (devAccyGrps.length === 0) {

        // Just display a suitable messasge and return.
        displayAlertMsg('There are no known accessory products applicable to this device.',
            StatusLevel.Info);

        // Rethink this at some point...
        //// At least for now, if the device doesn't have
        //// any possible accys according to our details,
        //// but it DOES, for some reasone have a collection,
        //// with or without anthing in it, remove it.
        //if (device.accys) {
        //    device.accys = undefined;
        //}
        return;
    }

    // Get an array containing all of the accys
    // currently included by the device.
    const devSelections = new Array<string>();
    if (device.accys) {
        device.accys.forEach(accy => {
            devSelections.push(accy);
        })
    }

    // Build selection info for each accy group.
    const grpSelInfo = new Array<GroupChoiceInfo>();
    devAccyGrps.forEach(group => {
        // At least for now, we assume that each accessory
        // group is made up of a single subgroup.
        const subgroup = group.subgroups[0];

        if (subgroup.selectionInfo.length > 0) {
            grpSelInfo.push(getGroupSelInfo(group, devSelections));
        }
        else {
            throw new Error('ChoicesGroup encountered with NO possible selections!');
        }
    });

    // Set up the data we'll use as .requestorData
    // in our modal request. Includes:
    const callbackData: ConfigAccysCBData = {
        // Our device
        device: device,

        // Selection info for each accy group
        grpChoiceInfo: grpSelInfo,

        // An array containing all accessory catNos
        // that SHOULD be included for the device.
        // Init that to what's there to begin with.
        allSelections: devSelections
    };

    const request: ModalRequestSpec = {
        includeButtons: true,
        closeOnInsideClick: false,
        stayOpenOnBackdropClick: true,
        title: 'Accessory Products for: ' + device.catNo,
        callback: configAccysCallback,
        requestorData: callbackData,
        content: ConfigAccys
    };

    addButton(request, 'SAVE', ModalStatus.Confirmed, 'contained');

    requestModal(request);
}

const updateSelections = (data: ConfigAccysCBData) => {
    // Create a new empty array.
    const currentSelections = new Array<string>();

    // Wall the sel info for each of our groups. For each...
    data.grpChoiceInfo.forEach(selInfo => {

        // Add each selection from the group to our array.
        selInfo.selections.forEach(grpSel => {
            currentSelections.push(grpSel);
        })
    })

    // Set the NEW .allSelections to current.
    data.allSelections = currentSelections;
}

const ConfigAccys = (request: ModalRequestSpec) => {
    const [count, setCount] = useState<number>(0);

    const data = request.requestorData as ConfigAccysCBData;

    if (!data) {
        unexpected('INVALID Request to ConfigAccys!');
    }

    const selectionChanged = () => {
        // An option selection was changed, or at
        // least an attempt to change it was made.
        // Update the .allSelections collection in
        // our data object to match what we now
        // SHOULD have.
        updateSelections(data);

        // Trigger a re-render.
        setCount(count + 1);
    }

    let nextKey = 1;

    return (
        <div className='config-accys'>
            {data.grpChoiceInfo.map(selInfo => {
                return <ChoiceGroup
                    key={nextKey++}
                    grpInfo={selInfo}
                    selectionChanged={selectionChanged}
                />
            })}
        </div>
        );
}

export default ConfigAccys;
