import { RegisterMicroCheckerImpl } from "./Microchecker";
import { RegisterMicroEngDataImpl } from "./MicroEngDataImpl";
import { RegisterMicroGeneralImpl } from "./MicroGeneralImpl";
import {  PlatformMicro } from "../../PlatformConstants";
import {
	microPrepareLocAttrHardwareForGen,microGetLocAttrInfoForChassisEdit
} from "./MicroGuidedSelection";
import { HardwareGenImplSpec, RegisterHardwareGenImpl } from "../../../implementation/ImplHardwareGen";
import { genCreateHardwareFromSettingsMicro, getCreateHWFromSettingsErrors } from "../../common/HardwareGen";



const registerMicroHWGenImpl = () => {
	const impl: HardwareGenImplSpec = {
		platform: PlatformMicro,
		createHardwareFromSettings: genCreateHardwareFromSettingsMicro,
		getHardwareGenErrors: getCreateHWFromSettingsErrors,
		prepLocAttrHardwareForGen: microPrepareLocAttrHardwareForGen,
		getLocAttrInfoForChassisEdit: microGetLocAttrInfoForChassisEdit,
	
	}

	RegisterHardwareGenImpl(impl);
}



export const initMicroPlatform = (): boolean => {
	registerMicroHWGenImpl();
	RegisterMicroEngDataImpl();
	RegisterMicroGeneralImpl();
	RegisterMicroCheckerImpl();

	

	return true;
}