import { DeviceType } from "../types/ProjectTypes";
import { getIntValFromString } from "../util/DataValueHelp";
import { logger } from "../util/Logger";
import { IEngDataModule } from "./EngineeringInfo";


export interface ICommRoleData extends IEngDataModule {
    Node1_NetworkType: string;
    CIP_Avail: string;
    TCP_Avail: string;
    PPS_IO_Avail: string;
    CM_NonPosAxis_Avail: string;
    CM_PosAxis_Avail: string;
}


export interface CommRole {
    maxCIPConns: number;
    maxTCPConns: number;
    maxIOPPS: number;
    supportsCIPMotion: boolean;
    maxPosAxes: number;
    maxNonPosAxes: number;
}

export const establishCommRole = (data: IEngDataModule): CommRole | undefined => {
    const roleData = data as ICommRoleData;

    // Device must be either a Controller or a Comm Module. If so...
    if ((roleData.Type === DeviceType.Controller) ||
        (roleData.Type === DeviceType.CommModule)) {

        // We'll only make comm roles for EtherNet devices.
        if (roleData.Node1_NetworkType === 'EtherNet') {

            const maxNonPosAxes = getIntValFromString(roleData.CM_NonPosAxis_Avail);
            const maxPosAxes = getIntValFromString(roleData.CM_PosAxis_Avail);

            const role: CommRole = {
                maxCIPConns: getIntValFromString(roleData.CIP_Avail),
                maxTCPConns: getIntValFromString(roleData.TCP_Avail),
                maxIOPPS: getIntValFromString(roleData.PPS_IO_Avail),
                supportsCIPMotion: ((maxNonPosAxes + maxPosAxes) > 0),
                maxPosAxes: maxPosAxes,
                maxNonPosAxes: maxNonPosAxes
            }
            return role;
        }
        else {
            return undefined;
        }
    }
    else {
        logger.error('Attempt to establishCommRole for unexpected device type: ' +
            roleData.Type);
    }
}