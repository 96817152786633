import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { StartupDetails } from "../../util/AppStartupHelp";
//import { AuthStatus } from "../../authentication/util/AuthTypes";


interface StartupInfoState {
    //authStatus: AuthStatus;
    startupDetails: StartupDetails | undefined;
}

const initialState: StartupInfoState = {
    //authStatus: AuthStatus.Unknown,
    startupDetails: undefined
}

export const startupInfo = createSlice({
    name: 'startupInfo',
    initialState,
    reducers: {
        //setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
        //    state.authStatus = action.payload;
        //},
        setStartupDetails: (state, action: PayloadAction<StartupDetails | undefined>) => {
            state.startupDetails = action.payload;
        }
    }
});

export const {
    //setAuthStatus,
    setStartupDetails
} = startupInfo.actions;

//export const authStatus = (state: RootState) => state.startupInfo.authStatus;
export const startupDetails = (state: RootState) => state.startupInfo.startupDetails;
export default startupInfo.reducer;
