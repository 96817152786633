import { Theme } from "@rockwell-automation-inc/ra-meatball";
import { Chassis } from "./ProjectTypes";
import { Point } from "./SizeAndPosTypes";


export enum LayoutActionType {
    None = 'None',
    AddModule = 'AddModule',
    ModeCopy = 'ModeCopy',
    ModeDelete = 'ModeDelete',
    MakeDuplex = 'MakeDuplex',
    MakeSimplex = 'MakeSimplex',
}

export enum CopyModeCompat {
    Match = 'Match',
    Swap = 'Swap',
    Mismatch = 'Mismatch'
}

export interface ActBtnInfo {
    action: LayoutActionType;
    chassis: Chassis;
    slot: number;
    ctrPt: Point;
    copyCat?: string;
    compat?: CopyModeCompat;
    event?: PointerEvent;
}

export interface ActBtnSpecs {
    size: number;
    cornerRadPct: number;
    lineGapPct: number;
    imgSizePct: number;
    strokeWidth: number;
    stroke: string;
    fill: string;
    mouseoverStroke: string;
    mouseoverFill: string;
}

export const DfltActBtnSpecs: ActBtnSpecs = {
    size: 40,
    cornerRadPct: 0.20,
    lineGapPct: 0.10,
    imgSizePct: 0.80,
    strokeWidth: 3,
    stroke: Theme.palette.mediumGrey.main,
    fill: Theme.palette.white.main,
    mouseoverStroke: Theme.palette.darkGrey.main,
    mouseoverFill: Theme.palette.lightGrey.main
};
