import React from 'react';
import { Rect } from 'react-konva';
import { Point } from '../types/SizeAndPosTypes';

const _thinDimDiv = 5;
const _outerMargin = 3;

const _outerColor = '#EFEFEF';
const _symFillColor = '#00A300';


const getRectProps = (ptCtr: Point, height: number, width: number, color: string) => {
    return {
        x: ptCtr.x - (width / 2),
        y: ptCtr.y - (height / 2),
        width: width,
        height: height,
        fill: color
    };
}


interface Props {
    ptCtr: Point;
    size: number;
}

const CopyDropSymbol = (props: Props) => {

    const thinDim = props.size / _thinDimDiv;
    const twoMargins = _outerMargin * 2;

    const vertBg = getRectProps(props.ptCtr, props.size + twoMargins, thinDim + twoMargins, _outerColor);
    const horzBg = getRectProps(props.ptCtr, thinDim + twoMargins, props.size + twoMargins, _outerColor);

    const vertFg = getRectProps(props.ptCtr, props.size, thinDim, _symFillColor);
    const horzFg = getRectProps(props.ptCtr, thinDim, props.size, _symFillColor);

    return (
        <>
            <Rect
                {...vertBg}
            />
            <Rect
                {...horzBg}
            />
            <Rect
                {...vertFg}
            />
            <Rect
                {...horzFg}
            />
        </>
    );
}

export default CopyDropSymbol;

