import React from 'react';
import { Group, Image, Rect } from 'react-konva';
import { LocAndSize, Point } from '../../types/SizeAndPosTypes';
import { locToNormRect, offsetLoc } from '../../util/GeneralHelpers';
import { ChassisItemRenderType } from '../../util/LayoutModeHelp';
import { logger } from '../../util/Logger';
import { LogRender } from '../../types/Globals';
import { useCachedImage } from '../../imageHelp/useCachedImage';
import { ChassisElementType } from './CommonPlatformTypes';
import { Context as KonvaContext } from 'konva/lib/Context';


const getFillColor = (type: ChassisElementType): string => {
    switch (type) {
        case ChassisElementType.EmptySlot:
            return 'lightgray';

        case ChassisElementType.SlotSep:
            return 'gray';

        case ChassisElementType.GrpSep:
            return 'red';

        case ChassisElementType.RightCap:
            return 'lightgray';

        default:
            return 'lightblue';
    }
}

const getImageOpacity = (renderType: ChassisItemRenderType,
    reqOpacity: number | undefined): number => {

    switch (renderType) {
        case ChassisItemRenderType.Fade:
            return 0.5;

        case ChassisItemRenderType.Pop:
            return 0.9;

        default:
            return ((reqOpacity !== undefined) ? reqOpacity : 1.0);
    }

}

const getOffsetLoc = (locSrc: LocAndSize, ptOffset: Point): LocAndSize => {
    const loc: LocAndSize = { ...locSrc };
    offsetLoc(loc, ptOffset);
    return loc;
}

// The fullLoc and clipped params should contain locs
// representing 2 rectangles on the stage.
const clipperFunc = (ctx: KonvaContext, fullLoc: LocAndSize, clipped: LocAndSize) => {

    // Start by making 'normalized' rect 
    // objects for each loc.
    const fullRct = locToNormRect(fullLoc);
    const clippedRct = locToNormRect(clipped);

    // Start with a beginPath call on the
    // Konva context provided. Note that this
    // is done just one time.
    ctx.beginPath();

    // From here, we essentially add rectangular-shaped
    // paths to the context. Clockwise paths are used to
    // indicate areas where content is allowed. That is,
    // anything outside of the clockwise area(s) is clipped.
    // Counterclockwise paths are used to denote areas that
    // will be clipped, even if/when those areas are INSIDE
    // of the clockwise path(s). For the ChassisElComp, we have
    // one path in each of the directions.
    
    // The first path is clockwise, and is used to say that
    // anything outside of the element location we were given
    // should be clipped (not shown).
    ctx.moveTo(fullRct.left, fullRct.top);
    ctx.lineTo(fullRct.right, fullRct.top);
    ctx.lineTo(fullRct.right, fullRct.bottom);
    ctx.lineTo(fullRct.left, fullRct.bottom);
    ctx.closePath();

    // The second path is counterclockwise, and used
    // for another area that is (at least partially)
    // 'inside' of our element's location. Effectively,
    // anything content that falls over the intersection
    // of the two will be clipped.
    ctx.moveTo(clippedRct.left, clippedRct.top);
    ctx.lineTo(clippedRct.left, clippedRct.bottom);
    ctx.lineTo(clippedRct.right, clippedRct.bottom);
    ctx.lineTo(clippedRct.right, clippedRct.top);
    ctx.closePath();
}


interface Props {
    elType: ChassisElementType;
    renderType: ChassisItemRenderType;
    imgSrc: string;
    ptOrg: Point;
    relLocation: LocAndSize;  // location relative to ptOrg
    opacity?: number;
    borderProps?: object;
    clippedLoc?: LocAndSize;  // location relative to ptOrg
}

const ChassisElComp = (props: Props) => {

    const [image, imageStatus, /*imgSize*/] = useCachedImage(props.imgSrc);

    const imageReady = (imageStatus === 'loaded');

    const renderLoc = getOffsetLoc(props.relLocation, props.ptOrg);

    const clipFunc = (ctx: KonvaContext) => {

        // If we're called, we SHOULD have been
        // provided with a clippedLoc. If so...
        if (props.clippedLoc) {
            // Get that loc offset to its final position.
            const clipped = getOffsetLoc(props.clippedLoc, props.ptOrg);

            // Call our helper to actually add the clip magic.
            clipperFunc(ctx, renderLoc, clipped);
        }
        else {
            throw new Error('Unexpected call to clipFunc!');
        }
    }


    const imgOpacity = getImageOpacity(props.renderType, props.opacity);

    if (LogRender.Layout) {
        logger.logRender('ElComp: ' + props.elType);
    }

    if (imageReady) {
        return (
            <Group
                clipFunc={props.clippedLoc ? clipFunc : undefined}
            >
                <Image
                    image={image}
                    {...renderLoc}
                    opacity={imgOpacity}
                />
                {props.borderProps
                    ? <Rect
                        {...props.borderProps}
                        {...renderLoc}
                    />
                    : null
                }
            </Group>
        );
    }
    else {
        return (
            <Rect
                fill={getFillColor(props.elType)}
                stroke={'black'}
                {...renderLoc}
            />
        )

    }
}

export default React.memo(ChassisElComp);
