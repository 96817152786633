import React, { useEffect, useState } from 'react';
import './ProjInfoSummary.scss';
import SummaryToolbar from '../toolbar/SummaryToolbar';
import { Box } from '@mui/material';
import { IconButton } from '@mui/material';
import { txtRADB_LOADING } from '../types/APITypes';
import RefreshIcon from '@mui/icons-material/Refresh';
import ProjInfoBrief from './ProjInfoBrief';
import { logger } from '../util/Logger';
import { LogRender } from '../types/Globals';
import { getCurrentLocPlatform } from '../model/GuidedSelection';
import { ChassisProject } from '../types/ProjectTypes';


export interface LinkInfo {
    text: string;
    url: string;
}

export interface LinkGroupInfo {
    title?: string;
    links: LinkInfo[];
}

export interface ProjInfoDetail {
    title: string;
    subtitle?: string;
    prodPageInfo?: LinkInfo;
    imageSource?: string;
    extraText?: string[];
    linkGroups?: LinkGroupInfo[];
}


interface Props {
    project: ChassisProject;
    info: ProjInfoDetail;
    onTBAction: (action: string) => void;
    totals:string;
    setShowRefreshIcon:(showRefreshIcon:boolean)=>void;
    showRefreshPricing:boolean;
}

const CheckPrice=(price:string)=>{

  const currencySymbol = price.charAt(0); // Extract the currency symbol
  const valueWithoutCurrency = price.slice(1); // Remove the currency symbol

  const numberValue = parseFloat(valueWithoutCurrency); // Parse the string to a float

  let formattedValue;
  if (numberValue > 0) {
    formattedValue = `${currencySymbol}${valueWithoutCurrency}`; 
  } else {
    formattedValue = 'N/A';
  }
return formattedValue;
}

const ProjInfoSummary = (props: Props) => {

    const [price, setPrice] = useState(txtRADB_LOADING);
    const [totalquantity,setTotalQuantity] = useState(txtRADB_LOADING);

    useEffect(() => {
        if (props.totals !== txtRADB_LOADING) {
            const data = props.totals.split("/");
            const ActualPrice=CheckPrice(data[0].trim())
            setPrice(ActualPrice);
            setTotalQuantity(data[1].trim());
        }

    }, [props.totals])

    // referesh pricing icon status modify if API does not return all produts.
    const toggleShowIcon=()=>{
        props.setShowRefreshIcon(true);
    }

    //const LeftPanelEntry = (title: string, value: string) => {
    //    return <Typography
    //    sx={{ display:"flex", gap:4}}>
    //        <Typography sx={{fontWeight:500}}>{`${title}:`}</Typography>
    //        <span>{value}</span>
    //    </Typography>
    //}

    // The version above caused a huge warning in the console.
    const LeftPanelEntry = (title: string, value: string) => {
        return (
            <div className='proj-info-summary-left-panel'>
                <span style={{ fontWeight: 500 }}>{title + ':'}</span>
                <span>{value}</span>
            </div>
        );
    }

    // 2023.11.1 Clicking this refresh icon causes
    // an infinite loop. Do not show it!
    // const showRefreshPricing = props.showRefreshPricing;
    const showRefreshPricing = false;
    // 2024.4.10 Until we figure out the different
    // currencies, do NOT show total price.
    const showTotalPrice = false;

    if (LogRender.SummaryComps) {
        logger.logRender('Render ProjInfoSummary');
    }

    return (
        <Box>
            <SummaryToolbar onTBAction={props.onTBAction} />
            <ProjInfoBrief
                project={props.project}
                platform={getCurrentLocPlatform(props.project)}
            />
            <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: "8px", mb: "1px" }}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'row'
                }}
                >
                    {showTotalPrice
                        ?
                        <>
                            {LeftPanelEntry("List Price", price)}
                            {showRefreshPricing
                                ? <IconButton sx={{ p: 0 }} onClick={toggleShowIcon}>
                                    <RefreshIcon htmlColor="darkorange" fontSize="medium" />
                                </IconButton>
                                : null
                            }
                        </>
                        :
                        null
                    }
                </Box>
                {LeftPanelEntry("Qty Totals", totalquantity)}
            </Box>
            
        </Box>
    );
}

export default ProjInfoSummary;
