import Konva from 'konva';
import { Filter } from 'konva/lib/Node';
import { ModuleDragStatus } from '../types/ProjectTypes';
import { DragDeviceInfo, DropStatus } from './DragAndDropHelp';

const _rgbPrimary = 255;
const _rgbSecondary = 150;

const _rgbPrimaryDark = 180;
const _rgbSecondaryDark = 72;

export interface HighlightProps {
    filters?: Filter[];
    red?: number;
    green?: number;
    blue?: number;
    brightness?: number;
    contrast?: number;
}


export const AddRGBFilter = (hlProps: HighlightProps, r: number, g: number, b: number) => {
    if (!hlProps.filters) {
        hlProps.filters = [];
    }
    hlProps.filters.push(Konva.Filters.RGB);
    hlProps.red = r;
    hlProps.green = g;
    hlProps.blue = b;
}

export const AddBrightness = (hlProps: HighlightProps, brightness: number) => {
    if (!hlProps.filters) {
        hlProps.filters = [];
    }
    hlProps.filters.push(Konva.Filters.Brighten);
    hlProps.brightness = brightness;
}

export const AddContrast = (hlProps: HighlightProps, contrast: number) => {
    if (!hlProps.filters) {
        hlProps.filters = [];
    }
    hlProps.filters.push(Konva.Filters.Contrast);
    hlProps.contrast = contrast;
}

export const AddInvertFilter = (hlProps: HighlightProps) => {
    if (!hlProps.filters) {
        hlProps.filters = [];
    }
    hlProps.filters.push(Konva.Filters.Invert);
}

export const AddSolarizeFilter = (hlProps: HighlightProps) => {
    if (!hlProps.filters) {
        hlProps.filters = [];
    }
    hlProps.filters.push(Konva.Filters.Solarize);
}

const _getRGBColorVals = (darkImages: boolean): [primary: number, secondary: number] => {
    if (darkImages) {
        return [_rgbPrimaryDark, _rgbSecondaryDark];
    }
    else {
        return [_rgbPrimary, _rgbSecondary];
    }
}

export const getModuleHighlightProps = (
    selected: boolean,
    mouseOver: boolean,
    dragStatus: ModuleDragStatus,
    darkImagery = false
): [hlProps: HighlightProps, opacity: number] => {
    const hlProps: HighlightProps = {};

    if (selected) {
        const [pri, sec] = _getRGBColorVals(darkImagery);
        if (darkImagery) {
            AddInvertFilter(hlProps);
        }
        AddRGBFilter(hlProps, pri, pri, sec);
    }

    if (mouseOver) {
        AddBrightness(hlProps, 0.10);
    }

    const opacity = darkImagery ? 1.0 : _getModuleOpacity(dragStatus);

    if (darkImagery) {
        switch (dragStatus) {
            case ModuleDragStatus.Copying:
                AddContrast(hlProps, -10);
                break;

            case ModuleDragStatus.Moving:
                AddContrast(hlProps, -30);
                break;

            default:
                break;
        }
    }

    return [hlProps, opacity];
}


const _getModuleOpacity = (dragStatus: ModuleDragStatus): number => {
    switch (dragStatus) {
        case ModuleDragStatus.Copying:
            return 0.8;

        case ModuleDragStatus.Moving:
            return 0.6;

        default:
            return 1.0;
    }
}

const _getDragCompColors = (dragDevInfo: DragDeviceInfo): [r: number, g: number, b: number] => {

    const [pri, sec] = _getRGBColorVals(dragDevInfo.darkImage);

    switch (dragDevInfo.dropStatus) {
        case DropStatus.DropOk:
            return [sec, pri, sec];

        case DropStatus.NoDrop:
            return [pri, sec, sec];

        case DropStatus.DropOkAfterSwap:
            return [sec, pri, pri];

        default:
            return [pri, pri, pri];
    }
}

export const getDragHighlightProps = (dragDevInfo: DragDeviceInfo): HighlightProps => {
    const props: HighlightProps = {};

    if (dragDevInfo.darkImage) {
        AddInvertFilter(props);
    }

    const [r, g, b] = _getDragCompColors(dragDevInfo);
    AddRGBFilter(props, r, g, b);

    return props;
}