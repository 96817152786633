import { EngInfoIOModule } from "../engData/EngineeringInfo";
import { convertGuidedSelAttrToAppVal, getDefaultIOModuleCatalog, getIOFilterMasksFromLoc, getIOModuleInfo, queryIOModules } from "../implementation/ImplHardwareGen";
import { getIOModInfoMap, getIOModTypes } from "../model/EngineeringData";
import {
    createNewPointEntryInterface,
    getIOFilterMasksFromLocationAttr,
    hasIOPointTypeQuantity,
    loadIOPointFilterMap
} from "../model/IOModule";
import { GetDefaultIO } from "../model/ProductSelection";
import {
    ControlVoltageMask,
    EnvironmentalRatingMask,
    IOBitset,
    IOFilterMasks,
    IOModuleSelection,
    IOPoints,
    MainPointTypeMask
} from "../types/IOModuleTypes";
import { PointEntryInfo, PointTypeEntry, PointTypeGroup } from "../types/IOPointEntryTypes";
import { EnvRating, HardwareBuilder, IOModuleWiring, LocAttributeInfo, PSInputVoltage } from "../types/ProjectTypes";
import { bitCount32 } from "../util/GeneralHelpers";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants";
import { getLocAttributeSetting, refreshLocAttrInfoSelectionArray } from "../model/GuidedSelection";

//////////// UTILITIES ////////////////////////////////////
export const getHardwareErrorMessages = (hw: HardwareBuilder): string[] => {
    const messages: string[] = [];
    hw.mapErrMessages.forEach((msg) => {
        messages.push(msg);
    });

    return messages;
}

export const getHardwarePreview = (hw: HardwareBuilder): string => {
    let message = '';

    message += '\nDedicated Controller Chassis: ';
    message += hw.ctrlDedicatedChassis;

    message += '\nCtrl Chassis Needs Scanner: '
    message += hw.ctrlChassisNeedsScanner;
    message += '\n'

    if (hw.catControllerChassis) {
        message += '\nController Chassis: ';
        message += hw.catControllerChassis;
    }

    if (hw.catPwrSupCtrlChassis) {
        message += '\nController Chassis PSU: ';
        message += hw.catPwrSupCtrlChassis;
    }

    if (hw.catChassis) {
        message += '\nChassis: ';
        message += hw.catChassis;
    }

    if (hw.catPowerSupply) {
        message += '\nChassis PSU: ';
        message += hw.catPowerSupply;
    }

    if (hw.catController) {
        message += '\nController: ';
        message += hw.catController;
    }

    if (hw.catControllerPartner) {
        message += '\nAdditional Ctrl Partner(s):';
        hw.catControllerPartner.forEach((coMod) => {
            message += ' ' + coMod;
        })
    }

    if (hw.catScanner) {
        message += '\nScanner: ';
        message += hw.catScanner;
    }

    if (hw.redCtrlChassis) {
        message += '\nRedundant Ctrl Chassis: ';
        message += hw.redCtrlChassis;
    }

    if (hw.ioModuleSelections.length) {
        message += '\nI/O Module Count: ';
        message += hw.ioModuleCount;
        message += '\nI/O Modules: ';
        hw.ioModuleSelections.forEach((x, idx) => {
            if (idx > 0)
                message += '/';
            message += x.catalog;
            message += ' (' + x.quantity + ')';
        })
    }

    return message;
}

//////////////////////////////////////////////////////////////////////
// The idea here is to "Hide" the Default Hardware Generation
// Function from the outside world. At the bottom of the
// file we export an interface similar to HardwareGenImplSpec
// containing non-exported functions defined locally in this file.
///////////////////////////////////////////////////////////////////////


// Cache of queries performed. Map of query key to result.
const _ioPreviousQueries = new Map<string, EngInfoIOModule[]>();

const createIOModQueryKey = (platform: string, mask: number, excludeMask: number) => {
    return `${platform}_${mask.toString()}_${excludeMask.toString()}`;
}

const _queryIOModules = (platform: string, mask: number, excludeMask: number): EngInfoIOModule[] => {
    // Do we have the query cached...
    const queryKey = createIOModQueryKey(platform, mask, excludeMask);
    const prevQuery = _ioPreviousQueries.get(queryKey);
    if (prevQuery !== undefined)
        return prevQuery;

    // Remove any Voltage bits from the mask. The
    // voltage mask for I/O modules is for FIELD POWER.
    // However when we sort the I/O modules, we will
    // try to place the matching voltage I/O modules
    // at the top of the list. We will NOT remove bits
    // from the Exclude Mask.
    const nonVoltageIncludeMask = (mask & ~ControlVoltageMask);

    // Check if we retrieved the IO module map.
    const ioInfo = getIOModInfoMap(platform);

    const results: EngInfoIOModule[] = [];
    ioInfo.forEach((info) => {

        if ((info.IOMask & nonVoltageIncludeMask) === nonVoltageIncludeMask &&
            (info.IOMask & excludeMask) === 0) {
            results.push(info);
        }
    });

    const voltageBit = (mask & ControlVoltageMask);
    sortIOModulesOnVoltage(results, voltageBit);

    _ioPreviousQueries.set(queryKey, results);

    return results;
}

const sortIOModulesOnVoltage = (ioModules: EngInfoIOModule[], voltageBit: number) => {
    // Note: we are NOT creating a new instance of the array.
    // We are just re-ordering ioModules.
    ioModules.sort((a, b) => {
        const voltageMatchA = (a.IOMask & voltageBit);
        const voltageMatchB = (b.IOMask & voltageBit);
        if (voltageMatchA > voltageMatchB)
            return -1;
        else if (voltageMatchA < voltageMatchB)
            return 1;

        // Sort on catalog.
        return a.catNo.localeCompare(b.catNo);

    })
}

const _ioPointFilterMap = new Map<string, Map<number, number>>();
const _getIOPointFilterMap = (platform: string): Map<number, number> => {
    let cacheMap = _ioPointFilterMap.get(platform);
    if (cacheMap)
        return cacheMap;

    cacheMap = new Map<number, number>();

    const IOModuleInfo = getIOModInfoMap(platform);
    loadIOPointFilterMap(cacheMap, IOModuleInfo);

    // ITISAB-646: Allow Safety I/O feature in 1756 filters.
    // Note: if any platform needs to modify the I/O feature
    // filters, this is the place to do it.
    //switch (platform) {        
    //    case PlatformCLX:
    //        {
    //            // For ControlLogix, we are REMOVING the Safety I/O
    //            // Bit from all point types. Note: the value (val)
    //            // is just a number - changing it will NOT update
    //            // the value in the map.
    //            const omitMask = (~IOBitset.SafetyIO);
    //            cacheMap.forEach((val, key, map) => {
    //                val = (val & omitMask);
    //                map.set(key, val);
    //            });
    //        }
    //        break;
    //    default:
    //        break;
    //}

    // Cache it
    _ioPointFilterMap.set(platform, cacheMap);

    return cacheMap;
}


const _calcModQtyFromPoints = (platform: string, catalog: string, userPoints: IOPoints): number => {
    // if there are not any user points....
    if (hasIOPointTypeQuantity(userPoints) === false)
        return 0;

    // Retrieved the IO module map.
    const ioModuleInfo = getIOModInfoMap(platform);

    // Get the module information for the given platform. 
    // If no module info or I/O points....
    const modInfo = ioModuleInfo.get(catalog);
    if (modInfo == null || hasIOPointTypeQuantity(modInfo.points) === false) {
        // Returning a negative number indicates an error/invalid catalog.
        return -1;
    }

    let totalModules = 0;

    // If there are NOT any self config points on the module...
    if (modInfo.points.selfCfg === 0) {

        // Calc the number of modules need based on the input points.
        if (userPoints.input > 0) {
            if (modInfo.points.input > 0) {
                totalModules = Math.ceil(userPoints.input / modInfo.points.input);
            }
            else {
                // Error!
                return -1;
            }
        }

        // Calc module count based on output points.
        if (userPoints.output > 0) {
            if (modInfo.points.output > 0) {
                // For a combo module, subtract any output points that
                // we have from any modules allocated for the Input points.
                const outputNeeded = userPoints.output - (totalModules * modInfo.points.output);
                if (outputNeeded > 0)
                    totalModules += Math.ceil(outputNeeded / modInfo.points.output);
            }
            else {
                // Error!
                return -1;
            }
        }
    }
    else {
        // Determine the module quantity from the accumulated points.
        let spareSelfCfg = 0;

        if (modInfo.points.input > modInfo.points.output) {
            if (userPoints.output > 0) {
                totalModules = Math.ceil(userPoints.output / (modInfo.points.output + modInfo.points.selfCfg));
                spareSelfCfg = userPoints.output % (modInfo.points.output + modInfo.points.selfCfg);
            }

            if (userPoints.input > 0) {
                // first get the quantity of input points from
                // fulfilling the output requirements. Subtract
                // those inputs and any spare SelfCfg from what we need.
                const inputs = totalModules * modInfo.points.input;
                const inputsNeeded = userPoints.input - (inputs + spareSelfCfg);
                // If we do not have enough...
                if (inputsNeeded > 0) {
                    // Add more modules...
                    totalModules += Math.ceil(inputsNeeded / (modInfo.points.input + modInfo.points.selfCfg));
                }
            }
        }
        else {
            if (userPoints.input > 0) {
                totalModules = Math.ceil(userPoints.input / (modInfo.points.input + modInfo.points.selfCfg));
                spareSelfCfg = userPoints.input % (modInfo.points.input + modInfo.points.selfCfg);
            }

            if (userPoints.output > 0) {
                // first get the quantity of output points from
                // fulfilling the input requirements. Subtract
                // those output and any spare SelfCfg from what we need.
                const outputs = totalModules * modInfo.points.output;
                const outputsNeeded = userPoints.output - (outputs + spareSelfCfg);
                // If we do not have enough...
                if (outputsNeeded > 0) {
                    // Add more modules...
                    totalModules += Math.ceil(outputsNeeded / (modInfo.points.output + modInfo.points.selfCfg));
                }
            }
        }
    }

    return totalModules;
}

// Helper for _getInitialPointEntryInfo()
const getInitialPointTypeGroups = (platform: string, projMasks: IOFilterMasks): PointTypeGroup[] => {
    // Get the point types allowed for this platform...
    // Note: by default, DI/DO/AI/AO should be included
    // for most platforms
    const types: PointTypeGroup[] = [];
    // Walk the supported CLX types... 
    const arrPointTypesBits = getIOModTypes(platform);
    arrPointTypesBits.forEach((type) => {
        // PointTypeEntry is Catalog/DisplayString.
        const entries: PointTypeEntry[] = [];

        // If we are NOT exluding the basic type...
        if ((type & IOBitset.Exclude) === 0) {
            // Query for an array of IO Module Infos based on the type.
            const arrInfo = queryIOModules(platform, (type | projMasks.includeMask), projMasks.excludeMask);

            // For each info, add it to the entries array.
            arrInfo.forEach((info) => {
                entries.push({ catalog: info.catNo, display: info.displayString });
            })

            // Add the point type group to our array.
            types.push({
                typeID: type,
                options: entries,
                groupNotSupported: entries.length === 0,
            })
        }
        else {
            types.push({
                typeID: type,
                options: [],
                groupNotSupported: true,
            })
        }
    })

    return types;
}

const _getInitialPointEntryInfo = (locAttrInfo: LocAttributeInfo, initUserSelections: IOModuleSelection[]): PointEntryInfo[] => {
    const platform = locAttrInfo.platform;
    const masks = getIOFilterMasksFromLoc(locAttrInfo);
    const initPointTypeGroups = getInitialPointTypeGroups(platform, masks);

    const initialEntries: PointEntryInfo[] = [];

    // If we do NOT have user selections....
    if (initUserSelections.length == 0) {
        initPointTypeGroups.forEach((type) => {
            // If the type is not supported, do not add it.
            if (type.groupNotSupported === false) {
                const defEntry = createNewPointEntryInterface();

                defEntry.typeID = type.typeID;
                defEntry.filterInclude = type.typeID;
                defEntry.platform = platform;
                defEntry.invalidEntry = type.groupNotSupported;
                defEntry.isAdvModDefault = true;

                if (defEntry.invalidEntry === false) {
                    defEntry.moduleCatalogs = type.options.map(x => { return x['catalog']; });
                    defEntry.moduleDisplayStrs = type.options.map(x => { return x['display']; });
                    defEntry.advancedModule = getDefaultIOModuleCatalog(type.typeID, locAttrInfo);
                    defEntry.basicModule = defEntry.advancedModule
                }
                initialEntries.push(defEntry);
            }
        });

        return initialEntries;
    }

    // We have user selections. We will ONLY add these... Note: we will
    // recalc the module counts later - the Quantity in the selection is
    // used when we generate the hardware from the settings.
    initUserSelections.forEach((sel) => {
        const entryCount = sel.selectedPoints.length;
        if (entryCount > 0) {
            let selCatalog = sel.catalog;
            const multiMod = sel.selectedPoints.length > 1;
            let typeID = sel.selectedPoints[0].typeID
            const defModule = getDefaultIOModuleCatalog(typeID, locAttrInfo);
            // Get the information for the basic type in our initPointTypeGroups.
            const maskBasicType = (typeID & MainPointTypeMask);
            const initTypeInfo = initPointTypeGroups.find((val) => val.typeID === maskBasicType);

            // Set some flags to indicate if the type is no longer valid
            // OR the selected catalog is no longer available.
            const invalid = (initTypeInfo == null || initTypeInfo.groupNotSupported);
            let invalidCatalog = (invalid || initTypeInfo == null || initTypeInfo.options.some((ent) => ent.catalog === selCatalog) === false)
            if (invalidCatalog && defModule && invalid === false) {
                // We no longer have the catalog that was saved.
                // Keep it simple and just select the default module
                // and clear the filter.
                selCatalog = defModule;
                typeID = maskBasicType;
                invalidCatalog = false;
            }

            sel.selectedPoints.forEach((point) => {
                // Create and add the entry to the returned array...
                const userEntry = createNewPointEntryInterface();
                initialEntries.push(userEntry);

                userEntry.filterInclude = typeID;
                userEntry.typeID = maskBasicType;
                userEntry.points = point.userPointCount;
                userEntry.platform = platform;

                userEntry.savedSelectionInvalid = invalidCatalog;
                userEntry.invalidEntry = invalid;

                if (invalid === false) {
                    userEntry.advancedModule = selCatalog;
                    userEntry.isAdvModDefault = (selCatalog === defModule);
                    userEntry.basicModule = defModule;
                    // Consolidate is for the UI and moduleSelInMultipleEntries indicates
                    // that we have more than one consolidated entry. While using the
                    // UI, we can have a module marked as 'consolidate' but moduleSelInMultipleEntries
                    // may be false. We do not persist the consildate flag itself, so it will be
                    // cleared while loading in and there's only one entry.
                    userEntry.consolidateModule = multiMod;
                    userEntry.moduleSelInMultipleEntries = multiMod;
                    // Add the options.
                    userEntry.moduleCatalogs = (initTypeInfo ? initTypeInfo.options.map(x => { return x.catalog; }) : []);
                    userEntry.moduleDisplayStrs = (initTypeInfo ? initTypeInfo.options.map(x => { return x.display; }) : []);
                }
            });
        }
    });

    return initialEntries;
}

const _findClosestIOModMatch = (
    platform: string,
    oldCatalog: string,
    includeMask: number,
    excludeMask: number,
    matchingModules: EngInfoIOModule[]): EngInfoIOModule | undefined => {
    // clear the array of potential matches and get
    // the old module's info. We are returning results
    // in this array so do NOT create a new instance!!!!!
    matchingModules.length = 0;
    const oldInfo = getIOModuleInfo(platform, oldCatalog);
    if (!oldInfo)
        return undefined;

    // Query for potential matches.
    let potentialReplacements = queryIOModules(platform, includeMask, excludeMask);
    // If we found none...
    if (potentialReplacements.length == 0)
        return undefined;

    // Copy the potentials into the 'matching' module array.
    // Note: We do NOT want to change the indtance of the array.
    // This array is used to populate the combos and we want all
    // of the queried modules to be in it.
    potentialReplacements.forEach((info) => { matchingModules.push(info); });

    // Is the old catalog in the array...
    if (potentialReplacements.find(x => x.catNo === oldCatalog))
        return oldInfo;

    // Based on the module's characterization bitset,
    // find module(s) with the most matching bits and 
    // least un-matched bits. We strip Env Rating bits 
    // away for doing our comparisons.
    const oldCompareMask = (oldInfo.IOMask & ~EnvironmentalRatingMask);

    // 2024.2.26 Note: We may need to change bitCount32()
    // below to bitCount64() if more IO bits are needed.

    const typeMatches: EngInfoIOModule[] = [];
    let mostMatchBits = 0;
    let mostUnlikeBits = 32;
    potentialReplacements.forEach((info) => {
        const matchMask = (info.IOMask & oldCompareMask);
        const bits = bitCount32(matchMask);
        if (bits > mostMatchBits) {
            // Best match so far. Start a new array.
            mostMatchBits = bits;
            typeMatches.length = 0;
            typeMatches.push(info);

            // Remove the Env Ratings bits.
            const nonEnvRatingMask = (info.IOMask & ~EnvironmentalRatingMask);
            // Set the most UNLIKE bits. Note: Matching bit count takes
            // precedence over unlike bit count.
            mostUnlikeBits = bitCount32(nonEnvRatingMask ^ matchMask);
        }
        else if (bits == mostMatchBits) {
            // We have the same matching bit count. Check the
            // unlike bit count. Remove the Env Ratings bits and
            // get the unlike bit count by comparing (XOR) it to
            // the mask of matching bits.
            const matchCompareMask = (info.IOMask & ~EnvironmentalRatingMask);
            const unmatchedBits = bitCount32(matchCompareMask ^ matchMask);

            // If the info has fewer unlike bits...
            if (mostUnlikeBits > unmatchedBits) {
                // This module is the best match. Start a new array.
                mostUnlikeBits = unmatchedBits;
                typeMatches.length = 0;
                typeMatches.push(info);
            }
            else if (mostUnlikeBits === unmatchedBits) {
                // This module has the same matching and unlike
                // bit counts as the module(s) already in our matches.
                // Add it to the array.
                typeMatches.push(info);
            }
        }
    })

    // If we have any best modules after the bitset comparison (we should)...
    if (typeMatches.length > 0) {
        // If we have just ONE module...
        if (typeMatches.length === 1)
            return typeMatches[0];

        // Set our potential replacements to the
        // best matches we found. This should make
        // short work of the point comparison (next).
        potentialReplacements = typeMatches;
    }

    // Next look at the points... Determine if we
    // are trying to match Input or Output points.
    const pointsMatches: EngInfoIOModule[] = [];
    const pointsGreaterThanOrg: EngInfoIOModule[] = [];
    const pointsSelfCfg: EngInfoIOModule[] = [];
    if ((includeMask & IOBitset.Input) != 0) {
        potentialReplacements.forEach((info) => {
            if (info.points.input === oldInfo.points.input)
                pointsMatches.push(info);
            else if (info.points.input > oldInfo.points.input)
                pointsGreaterThanOrg.push(info);
            else if (info.points.input + info.points.selfCfg >= oldInfo.points.input + oldInfo.points.selfCfg)
                pointsSelfCfg.push(info);
        });
    }
    else {
        potentialReplacements.forEach((info) => {
            if (info.points.output === oldInfo.points.output)
                pointsMatches.push(info);
            else if (info.points.output > oldInfo.points.output)
                pointsGreaterThanOrg.push(info);
            else if (info.points.output + info.points.selfCfg >= oldInfo.points.output + oldInfo.points.selfCfg)
                pointsSelfCfg.push(info);
        });
    }

    if (pointsMatches.length > 0)
        return pointsMatches[0];
    else if (pointsGreaterThanOrg.length > 0)
        return pointsGreaterThanOrg[0]
    else if (pointsSelfCfg.length > 0)
        return pointsSelfCfg[0]

    return potentialReplacements[0];
}


const _getIOFilterMasksFromLoc = (locAttrInfo: LocAttributeInfo): IOFilterMasks => {
    const ioMasks: IOFilterMasks = { includeMask: 0, excludeMask: 0 };

    // Update the locAttrInfo.arrAttributeNameToValue array.
    refreshLocAttrInfoSelectionArray(locAttrInfo);

    // Not sure how to avoid a 'Hardcode'? We need to create a
    // bit mask and if the bit values are not in the JSON, we have
    // to create a translation...

    // So far, it looks like ControlLogix and CompactLogix
    // will work in this general function.
    const erSetting = locAttrInfo.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (erSetting) {
        switch (erSetting.optionID) {
            case 'CC':
                ioMasks.includeMask |= IOBitset.Conformal;
                break;
            case 'XT':
                ioMasks.includeMask |= IOBitset.ExTemp;
                break;
            default:
                // Standard - For 5015 FlexHA, since all modules
                // are rated Std/CC/XT, we do NOT exclude CC or XT.
                // For all other platforms, we must exclude CC/XT.
                if (locAttrInfo.platform !== PlatformFlexHA) {
                    // Exclude conformal and XT
                    ioMasks.excludeMask |= (IOBitset.Conformal | IOBitset.ExTemp);
                }
                break;
        }
    }

    const cvSetting = locAttrInfo.arrAttributeNameToValue.find(x => x.attrID === 'CV');
    if (cvSetting) {
        switch (cvSetting.optionID) {
            case '24D':
                ioMasks.includeMask |= IOBitset.DC24;
                break;
            case '48D':
                ioMasks.includeMask |= IOBitset.DC48;
                break;
            case '125D':
                ioMasks.includeMask |= IOBitset.DC120;
                break;
            case '120A':
                ioMasks.includeMask |= IOBitset.AC120;
                break;
            case '240A':
                ioMasks.includeMask |= IOBitset.AC240;
                break;
        }
    }

    return ioMasks;
}


const _createDefaultPointEntry = (locAttrInfo: LocAttributeInfo, typeID = 0): PointEntryInfo => {
    // If we do not have a requested type, set it to Analog Input.
    const platform = locAttrInfo.platform;
    if (typeID === 0) {
        const arrPointTypes = getIOModTypes(platform);
        if (!arrPointTypes)
            throw new Error(`_createDefaultPointEntry(): Platform <${platform}> not implemented!`);

        typeID = arrPointTypes[0];
    }

    const projMasks = getIOFilterMasksFromLocationAttr(locAttrInfo);

    const defCatalog = getDefaultIOModuleCatalog(typeID, locAttrInfo);

    const newEntry = createNewPointEntryInterface();

    newEntry.typeID = typeID;
    newEntry.filterInclude = typeID;
    newEntry.platform = locAttrInfo.platform;
    newEntry.invalidEntry = (defCatalog == null);
    newEntry.isAdvModDefault = true;

    if (newEntry.invalidEntry === false) {
        const arrInfo = queryIOModules(platform, (typeID | projMasks.includeMask), projMasks.excludeMask);
        newEntry.moduleCatalogs = arrInfo.map(x => { return x.catNo; });
        newEntry.moduleDisplayStrs = arrInfo.map(x => { return x.displayString; });
        // Set both the Adv and Basic mods to the default.
        newEntry.advancedModule = defCatalog;
        newEntry.basicModule = newEntry.advancedModule
    }

    return newEntry;
}


const _getDefaultIOModuleCatalog = (IOType: number, locAttrInfo: LocAttributeInfo): string | undefined => {
    // We should have the Guided Selection loaded before
    // any calls are made here! Note: IOType should only
    // be the basic IOType (DO/DI/AO/AI) without anything else.
    // Get an array of mask to catalog,
    // Note: maskToCat.catalog.match(/^\d/) will return TRUE
    // when the catalog starts with a NUMBER. In Prod Sel data
    // there are some inconsistencies for non exist catalogs.
    const defIOMods = GetDefaultIO(locAttrInfo);
    const defMod = defIOMods.find(maskToCat => (maskToCat.mask === IOType && maskToCat.catalog.match(/^\d/)));
    if (defMod)
        return defMod.catalog;

    return undefined;
}


const _validIOExists = (locAttrInfo: LocAttributeInfo) => {
    // Get the supported types of the platform.
    const arrTypes = getIOModTypes(locAttrInfo.platform);
    const ioFound = arrTypes.some(type => getDefaultIOModuleCatalog(type, locAttrInfo) != null);
    return ioFound;
}

// Unfortunately, a hardcode to return values/enums that the
// application understands from Guided Selection Attributes.
// Note: AS LONG AS THE ATTR ID IS VALID -> although 'void'
// is returned in the signature, there should be 'something'
// returned that needs to be cast to the correct value/enum type.
const _convertGuidedSelAttrToAppVal = (platform: string, attrID: string, optionID: string, returnDefOnFail: boolean) => {
    switch (attrID) {
        case 'ER':
            {
                switch (optionID) {
                    case 'Std':
                        return EnvRating.Standard;
                    case 'CC':
                        return EnvRating.ConformalCoated;
                    case 'XT':
                        return EnvRating.ExtTemperature;
                    default:
                        if (returnDefOnFail)
                            return EnvRating.Standard;
                }
            }
            return '';

        case 'CA':
            // This is a true/false
            return (optionID === 'Red');

        case 'CV':
            {
                switch (optionID) {
                    case '24D':
                        return PSInputVoltage.DC24V;
                    case '48D':
                        return PSInputVoltage.DC48V;
                    case '125D':
                        return PSInputVoltage.DC125V;
                    case '120A':
                        return PSInputVoltage.AC120V;
                    case '240A':
                        return PSInputVoltage.AC240V;
                    default:
                        if (returnDefOnFail)
                            return PSInputVoltage.DC24V;
                }
            }
            return '';

        case 'CS':
            {
                const numSlots = Number(optionID);
                if (isNaN(numSlots) || numSlots < 4) {
                    if (returnDefOnFail) {
                        switch (platform) {
                            case PlatformCLX:
                                return 10;
                            case PlatformCpLX:
                                return 31;
                            case PlatformFlex:
                                return 16;
                                case PlatformMicro:
                                    return 11;
                        }
                    }
                }
                else
                    return numSlots;
            }
            return 0;

        case 'RTB':
            {
                switch (optionID) {
                    case 'Screw':
                        return IOModuleWiring.Screw;
                    case 'Spring':
                        return IOModuleWiring.SpringClamp;
                    case 'IOReady':
                        return IOModuleWiring.IOReadyCable;
                    default:
                        if (returnDefOnFail)
                            return IOModuleWiring.Screw;
                }
            }
            return '';

        // Ctrl Capability (Std, Safety, and Process). Based on 
        // the Guided Sel Opt Id, return the Eng Data's SubType1.
        case 'CC':
            {
                switch (optionID) {
                    case 'Std':
                        return 'Standard';
                    case 'Safe':
                        return 'Safety';
                    case 'Proc':
                        return 'Process';
                    default:
                        if (returnDefOnFail)
                            return 'Standard';
                }
            }
            return '';
    }

    // Someone did not request the right thing!
    // (or we need to enhance/hardcode more attributes)...
    throw new Error('convertGuidedSelAttrToAppValue(): An invalid Attribute ID or Option ID was passed in.')
}


// Unfortunately, a hardcode to return Guided Selection Option
// IDs from Application enums/values that the Guided Selection
// understands.
// NOTE: May need to add a platform argument here. We do not
// have Power Availability yet, but when/if we do, Guided Sel
// Options IDs are different for CLX/CpLX/Flex.
const _convertAppValToGuidedSelAttrOptionID = (attrID: string, appVal: string): string => {
    switch (attrID) {
        case 'ER':
            {
                switch (appVal) {
                    case EnvRating.Standard:
                        return 'Std';
                    case EnvRating.ConformalCoated:
                        return 'CC';
                    case EnvRating.ExtTemperature:
                        return 'XT';
                    default:
                        return 'Std';
                }

            }

        case 'CA':
            // This is a true/false
            return (appVal === 'true' ? 'Red' : 'Std');

        case 'CV':
            {
                switch (appVal) {
                    case PSInputVoltage.DC24V:
                        return '24D';
                    case PSInputVoltage.DC48V:
                        return '48D';
                    case PSInputVoltage.DC125V:
                        return '125D';
                    case PSInputVoltage.AC120V:
                        return '120A';
                    case PSInputVoltage.AC240V:
                        return '240A';
                    default:
                        return '24D';
                }
            }

        case 'CS':
            {
                // Should be the same, meaning the option
                // id for a 4 Slot chassis is '4'...
                return appVal;
            }
                
        case 'RTB':
            {
                switch (appVal) {
                    case IOModuleWiring.Screw:
                        return 'Screw';
                    case IOModuleWiring.SpringClamp:
                        return 'Spring';
                    case IOModuleWiring.IOReadyCable:
                        return 'IOReady';
                    default:
                        return 'Screw';
                }
            }

        // Based on the Eng Data's SubType1 value, return the 
        // Ctrl Capability 'CC' Option ID. 
        case 'CC':
            {
                switch (appVal) {
                    case 'Standard':
                        return 'Std';
                    case 'Safety':
                        return 'Safe';
                    case 'Process':
                        return 'Proc';
                    default:
                        return 'Std';
                }
            }
            return '';
   }

    // Someone did not request the right thing!
    // (or we need to enhance/hardcode more attributes)...
    throw new Error('convertGuidedSelAttrToAppValue(): An invalid Attribute ID or Option ID was passed in.')
}

const _getIOModWiringSelectionForApp = (locAttrInfo: LocAttributeInfo, gsValueAlternate = ''): IOModuleWiring => {
    // Note there is a case when we already know the attribute
    // value. That can be passed in as the gsValueAlternate and
    // we can skip over getting/finding the attribute in the loc.
    if (gsValueAlternate)
        return convertGuidedSelAttrToAppVal(locAttrInfo.platform, 'RTB', gsValueAlternate, true) as IOModuleWiring;

    const setting = getLocAttributeSetting(locAttrInfo, 'RTB');
    if (setting) {
        return convertGuidedSelAttrToAppVal(locAttrInfo.platform, 'RTB', setting.selectedOption.id, true) as IOModuleWiring;
    }

    // Just call it screw...
    return IOModuleWiring.Screw;
}

// Specifications for optional platform-specific routes.
interface _implDefQueryIOModules {(platform: string, mask: number, excludeMask: number): EngInfoIOModule[];}
interface _implDefGetIOPointFilterMap {(platform: string): Map<number, number>;}
interface _implDefCalcModQtyFromPoints {(platform: string, catalog: string, userPoints: IOPoints): number;}
interface _implDefGetInitialPointEntryInfo {(locAttrInfo: LocAttributeInfo, initValues: IOModuleSelection[]): PointEntryInfo[];}
interface _implDefGetIOModuleClosestMatch {(platform: string, oldCatalog: string, includeMask: number, excludeMask: number, matchingModInfo: EngInfoIOModule[]): EngInfoIOModule | undefined;}
interface _implDefCreateDefaultPointEntry {(locAttrInfo: LocAttributeInfo, typeID: number): PointEntryInfo;}
interface _implDefValidIOExists {(locAttrInfo: LocAttributeInfo): boolean;}
interface _implDefGetIOFilterMasksFromLoc {(locAttrInfo: LocAttributeInfo): IOFilterMasks}
interface _implDefGetDefaultIOModuleCatalog {(pointTypeMask: number, locAttrInfo: LocAttributeInfo): string | undefined;}
interface _implGuidedSelAttrToAppVal { (platform: string, attrID: string, optionID: string, returnDefOnFail: boolean): unknown}
interface _implAppValToGuidedSelAttrOptID {(attrID: string, appValue: string): string}
interface _implGetLocIOWiringTypeSel {(locAttrInfo: LocAttributeInfo): IOModuleWiring}

interface DefHardwareGenImplSpec {
    queryIOModules: _implDefQueryIOModules;
    getIOPointFilterMap: _implDefGetIOPointFilterMap;
    calcModQtyFromPoints: _implDefCalcModQtyFromPoints;
    getInitialPointEntryInfo: _implDefGetInitialPointEntryInfo;
    findClosestIOModMatch: _implDefGetIOModuleClosestMatch;
    createDefaultPointEntry: _implDefCreateDefaultPointEntry;
    validIOExists: _implDefValidIOExists;
    getIOFilterMasksFromLoc: _implDefGetIOFilterMasksFromLoc;
    getDefaultIOModuleCatalog: _implDefGetDefaultIOModuleCatalog;
    convertGuidedSelAttrToAppVal: _implGuidedSelAttrToAppVal;
    convertAppValToGuidedSelAttrOptID: _implAppValToGuidedSelAttrOptID;
    getLocIOWiringTypeSel: _implGetLocIOWiringTypeSel;
}

export const defHardwareGenImplFn: DefHardwareGenImplSpec = {
    queryIOModules: _queryIOModules,
    getIOPointFilterMap: _getIOPointFilterMap,
    calcModQtyFromPoints: _calcModQtyFromPoints,
    getInitialPointEntryInfo: _getInitialPointEntryInfo,
    createDefaultPointEntry: _createDefaultPointEntry,
    validIOExists: _validIOExists,
    getIOFilterMasksFromLoc: _getIOFilterMasksFromLoc,
    findClosestIOModMatch: _findClosestIOModMatch,
    getDefaultIOModuleCatalog: _getDefaultIOModuleCatalog,  
    convertGuidedSelAttrToAppVal: _convertGuidedSelAttrToAppVal,
    convertAppValToGuidedSelAttrOptID: _convertAppValToGuidedSelAttrOptionID,
    getLocIOWiringTypeSel: _getIOModWiringSelectionForApp,
};

