import { EngInfoIOModule } from "../engData/EngineeringInfo";
import { getIOModInfoMap } from "../model/EngineeringData";
import { generalFinalizeGuidedSelection, LocAttrInfoCollector } from "../model/GuidedSelection";
import { defHardwareGenImplFn } from "./ImplHardwareGeneralFn";
import { LogImplDefaults } from "../types/Globals";
import { IOFilterMasks, IOModuleSelection, IOPoints } from "../types/IOModuleTypes";
import { PointEntryInfo } from "../types/IOPointEntryTypes";
import { Chassis, ChassisProject, HardwareBuilder, IOModuleWiring, LocAttributeInfo, onCfgLocAttrCreatedCallback } from "../types/ProjectTypes";
import { logger } from "../util/Logger";
import { BOMItem, BOMItemProduct, IExternalBOM } from "../summary/SummaryHelp";

// Registered General Implementations.
const _hwGenImplRegistry = new Map<string, HardwareGenImplSpec>();


// Specifications for optional platform-specific routes.
interface _implQueryIOModules {
    (mask: number, excludeMask: number): EngInfoIOModule[];
}

interface _implGetIOPointFilterMap {
    (): Map<number, number>;
}

interface _implCalcModQtyFromPoints {
    (catalog: string, userPoints: IOPoints): number;
}

interface _implGetInitialPointEntryInfo {
    (locAttrInfo: LocAttributeInfo, initValues: IOModuleSelection[]): PointEntryInfo[];
}

interface _implFindClosestIOModMatch {
    (oldCatalog: string, includeMask: number, excludeMask: number, matchingModInfo: EngInfoIOModule[]): EngInfoIOModule | undefined;
}

interface _implCreateHardwareFromSettings {
    (hardware: HardwareBuilder, project: ChassisProject, clearHardware: boolean): void;
}

interface _implCreateDefaultPointEntry {
    (locAttrInfo: LocAttributeInfo, typeID: number): PointEntryInfo;
}

interface _implValidIOExists {
    (locAttrInfo: LocAttributeInfo): boolean;
}

interface _implGetHardwareGenErrors {
    (clearLog: boolean): string[];
}

interface _implGetIOFilterMasksFromLoc {
    (locAttrInfo: LocAttributeInfo): IOFilterMasks
}

interface _implGetDefaultIOModuleCatalog {
    (pointTypeMask: number, locAttrInfo: LocAttributeInfo): string | undefined;
}

interface _implPrepareLocAttrHardwareForGen {
    (locAttrInfo: LocAttributeInfo, project: ChassisProject): void;
}

interface _implGetLocIOWiringTypeSel {
    (locAttrInfo: LocAttributeInfo): IOModuleWiring;
}

interface _implFinalizeGuidedSelection {
    (locAttrInfo: LocAttributeInfo, collector: LocAttrInfoCollector): void;
}

interface _implGetLocAttrInfoForChassisEdit {
    (platform: string, callback: onCfgLocAttrCreatedCallback): void;
}

// Unfortunately, a hardcode to return values/enums that the
// application understands from Guided Selection Attributes.
// Note: AS LONG AS THE ATTR ID IS VALID -> although 'void'
// is returned in the signature, there should be 'something'
// returned that needs to be cast to the correct value/enum type.
interface _implGuidedSelAttrToAppVal {
    (platform: string, attrID: string, optionID: string, returnDefOnFail: boolean): unknown
}

interface _implAppValToGuidedSelAttrOptID {
    (attrID: string, appValue: string): string
}

interface _implGetIOModuleInfo {
    (platform: string, catalog: string): EngInfoIOModule | undefined
}

interface _implSortIOModulesForHWGen {
    (modulesCats: string[], reverseOrder: boolean): void
}

interface _implAddChassisExtrasToBOM {
    (chassis: Chassis, chassisConsComps: Map<string, BOMItemProduct>, organizedMainHeader: BOMItem, redSecondary: boolean, IExtBOM: IExternalBOM): void;
}

interface _implFinalizeHardwareGenChassis {
    (chassis: Chassis): void;
}

export interface HardwareGenImplSpec {
    platform: string;
    queryIOModules?: _implQueryIOModules;
    getIOPointFilterMap?: _implGetIOPointFilterMap;
    calcModQtyFromPoints?: _implCalcModQtyFromPoints;
    getInitialPointEntryInfo?: _implGetInitialPointEntryInfo;
    findClosestIOModMatch?: _implFindClosestIOModMatch;
    createHardwareFromSettings?: _implCreateHardwareFromSettings;
    createDefaultPointEntry?: _implCreateDefaultPointEntry;
    validIOExists?: _implValidIOExists;
    getHardwareGenErrors?: _implGetHardwareGenErrors;
    getIOFilterMasksFromLoc?: _implGetIOFilterMasksFromLoc;
    getDefaultIOModuleCatalog?: _implGetDefaultIOModuleCatalog;
    prepLocAttrHardwareForGen?: _implPrepareLocAttrHardwareForGen;
    getLocIOWiringTypeSel?: _implGetLocIOWiringTypeSel;
    finalizeGuidedSelection?: _implFinalizeGuidedSelection;
    getLocAttrInfoForChassisEdit?: _implGetLocAttrInfoForChassisEdit;
    convertGuidedSelAttrToAppVal?: _implGuidedSelAttrToAppVal;
    convertAppValToGuidedSelAttrOptID?: _implAppValToGuidedSelAttrOptID;
    getIOModuleInfo?: _implGetIOModuleInfo;
    sortIOModulesForHWGen?: _implSortIOModulesForHWGen;
    addChassisExtrasToBOM?: _implAddChassisExtrasToBOM;
    finalizeHardwareGenChassis?: _implFinalizeHardwareGenChassis;
}


// Implementation registration.
export const RegisterHardwareGenImpl = (impl: HardwareGenImplSpec) => {
    const platformID = impl.platform.trim();
    if (platformID && platformID === impl.platform) {
        if (!_hwGenImplRegistry.has(platformID)) {
            _hwGenImplRegistry.set(platformID, impl);
        }
        else {
            logger.warn('RegisterHardwareGenImpl ignoring duplicate register: ' + impl.platform);
        }
    }
    else {
        throw new Error('Invalid platform ID in RegisterGeneralImpl!');
    }
}


// Implementation retrieval - NOT EXPORTED.
const _getImplementation = (platform: string): HardwareGenImplSpec | undefined => {
    return _hwGenImplRegistry.get(platform);
}

// defHardwareGenImplFn
export const queryIOModules = (platform: string, mask: number, excludeMask: number): EngInfoIOModule[] => {
    const impl = _getImplementation(platform);
    if (impl && impl.queryIOModules) {
        return impl.queryIOModules(mask, excludeMask);
    }

    return defHardwareGenImplFn.queryIOModules(platform, mask, excludeMask);
}

// defHardwareGenImplFn
export const getIOPointFilterMap = (platform: string): Map<number, number> => {
    const impl = _getImplementation(platform);
    if (impl && impl.getIOPointFilterMap) {
        return impl.getIOPointFilterMap();
    }

    return defHardwareGenImplFn.getIOPointFilterMap(platform);
}

// defHardwareGenImplFn
export const calcModQtyFromPoints = (
    platform: string,
    catalog: string,
    userPoints: IOPoints): number => {

    const impl = _getImplementation(platform);
    if (impl && impl.calcModQtyFromPoints) {
        return impl.calcModQtyFromPoints(catalog, userPoints);
    }

    return defHardwareGenImplFn.calcModQtyFromPoints(platform, catalog, userPoints);
}


// defHardwareGenImplFn
export const getInitialPointEntryInfo = (
    locAttrInfo: LocAttributeInfo,
    initValues: IOModuleSelection[]
): PointEntryInfo[] => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.getInitialPointEntryInfo) {
        return impl.getInitialPointEntryInfo(locAttrInfo, initValues);
    }

    return defHardwareGenImplFn.getInitialPointEntryInfo(locAttrInfo, initValues);
}

// defHardwareGenImplFn
export const findClosestIOModMatch = (
    platform: string,
    oldCatalog: string,
    includeMask: number,
    excludeMask: number,
    matchingModInfo: EngInfoIOModule[]
): EngInfoIOModule | undefined => {

    const impl = _getImplementation(platform);
    if (impl && impl.findClosestIOModMatch) {
        return impl.findClosestIOModMatch(oldCatalog, includeMask,
            excludeMask, matchingModInfo);
    }

    return defHardwareGenImplFn.findClosestIOModMatch(
        platform,
        oldCatalog,
        includeMask,
        excludeMask,
        matchingModInfo);
}

// defHardwareGenImplFn
export const createDefaultPointEntry = (
    locAttrInfo: LocAttributeInfo,
    typeID: number
): PointEntryInfo => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.createDefaultPointEntry) {
        return impl.createDefaultPointEntry(locAttrInfo, typeID);
    }

    return defHardwareGenImplFn.createDefaultPointEntry(locAttrInfo, typeID);
}

// defHardwareGenImplFn
export const validIOExists = (locAttrInfo: LocAttributeInfo): boolean => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.validIOExists) {
        return impl.validIOExists(locAttrInfo);
    }

    return defHardwareGenImplFn.validIOExists(locAttrInfo);
}



// defHardwareGenImplFn
export const getIOFilterMasksFromLoc = (
    locAttrInfo: LocAttributeInfo
): IOFilterMasks => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.getIOFilterMasksFromLoc) {
        return impl.getIOFilterMasksFromLoc(locAttrInfo);
    }

    return defHardwareGenImplFn.getIOFilterMasksFromLoc(locAttrInfo);
}

// defHardwareGenImplFn
export const getDefaultIOModuleCatalog = (
    pointTypeMask: number,
    locAttrInfo: LocAttributeInfo
): string | undefined => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.getDefaultIOModuleCatalog) {
        return impl.getDefaultIOModuleCatalog(pointTypeMask, locAttrInfo);
    }

    return defHardwareGenImplFn.getDefaultIOModuleCatalog(pointTypeMask, locAttrInfo);
}


export const sortIOModulesForHWGen = (platform: string, modulesCats: string[], reverseOrder: boolean) => {
    const impl = _getImplementation(platform);
    if (impl && impl.sortIOModulesForHWGen) {
        return impl.sortIOModulesForHWGen(modulesCats, reverseOrder);
    }

    // default does nothing.
    return;
}


export const getIOModuleInfo = (
    platform: string,
    catalog: string
): EngInfoIOModule | undefined => {

    // From the IO module map, get the info
    // for the catalog (if we can).
    const info = getIOModInfoMap(platform).get(catalog);
    return info;
}


export const convertGuidedSelAttrToAppVal = (
    platform: string,
    attrID: string,
    optionID: string,
    returnDefOnFail: boolean,
) => {

    const impl = _getImplementation(platform);
    if (impl && impl.convertGuidedSelAttrToAppVal) {
        return impl.convertGuidedSelAttrToAppVal(platform, attrID, optionID, returnDefOnFail);
    }

    // Default
    return defHardwareGenImplFn.convertGuidedSelAttrToAppVal(platform, attrID, optionID, returnDefOnFail);
}

export const convertAppValToGuidedSelAttrOptID = (
    platform: string,
    attrID: string,
    appValue: string
) => {

    const impl = _getImplementation(platform);
    if (impl && impl.convertAppValToGuidedSelAttrOptID) {
        return impl.convertAppValToGuidedSelAttrOptID(attrID, appValue);
    }

    // Default
    return defHardwareGenImplFn.convertAppValToGuidedSelAttrOptID(attrID, appValue);
}

export const getLocIOWiringTypeSel = (
    locAttrInfo: LocAttributeInfo
): IOModuleWiring => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.getLocIOWiringTypeSel) {
        return impl.getLocIOWiringTypeSel(locAttrInfo);
    }

    // Default
    return defHardwareGenImplFn.getLocIOWiringTypeSel(locAttrInfo);
}


///////////// PLATFORM SPECIFIC ///////////////////////////////////////////////////////////////

export const createHardwareFromSettings = (
    platform: string,
    hardware: HardwareBuilder,
    project: ChassisProject,
    clearHardware: boolean,
): void => {

    const impl = _getImplementation(platform);
    if (impl && impl.createHardwareFromSettings) {
        return impl.createHardwareFromSettings(hardware, project, clearHardware);
    }

    // Default - Do nothing
    if (LogImplDefaults && platform) {
        logger.logImpl('No createHardwareFromSettings implemented for: ' + platform);
    }
}


export const getHardwareGenErrors = (platform: string, clearLog: boolean): string[] => {

    const impl = _getImplementation(platform);
    if (impl && impl.getHardwareGenErrors) {
        return impl.getHardwareGenErrors(clearLog);
    }

    // Default
    if (LogImplDefaults && platform) {
        logger.logImpl('No getHardwareGenErrors implemented for: ' + platform);
    }

    return [];
}

export const prepLocAttrHardwareForGen = (
    locAttrInfo: LocAttributeInfo,
    project: ChassisProject
): void => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.prepLocAttrHardwareForGen) {
        return impl.prepLocAttrHardwareForGen(locAttrInfo, project);
    }

    // Default
    if (LogImplDefaults && locAttrInfo.platform) {
        logger.logImpl('No prepareLocAttrHardwareForGen implemented for: ' + locAttrInfo.platform);
    }
}

export const finalizeGuidedSelection = (
    locAttrInfo: LocAttributeInfo,
    collector: LocAttrInfoCollector
): void => {

    const impl = _getImplementation(locAttrInfo.platform);
    if (impl && impl.finalizeGuidedSelection) {
        return impl.finalizeGuidedSelection(locAttrInfo, collector);
    }

    // Default
    generalFinalizeGuidedSelection(locAttrInfo, collector);
}

export const getLocAttrInfoForChassisEdit = (
    platform: string,
    callback: onCfgLocAttrCreatedCallback
): void => {

    const impl = _getImplementation(platform);
    if (impl && impl.getLocAttrInfoForChassisEdit) {
        return impl.getLocAttrInfoForChassisEdit(platform, callback);
    }

    // Default
    if (LogImplDefaults && platform) {
        logger.logImpl('No getLocAttrInfoForChassisEdit implemented for: ' + platform);
    }
}

export const addChassisExtrasToBOM = (
    chassis: Chassis,
    chassisConsComps: Map<string, BOMItemProduct>,
    organizedMainHeader: BOMItem,
    redSecondary: boolean,
    extBOMInfo: IExternalBOM) => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.addChassisExtrasToBOM) {
        return impl.addChassisExtrasToBOM(chassis, chassisConsComps, organizedMainHeader, redSecondary, extBOMInfo );
    }

    // Default does nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No addChassisExtrasToBOM implemented for: ' + chassis.platform);
    }
}

export const finalizeHardwareGenChassis = (chassis: Chassis) => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.finalizeHardwareGenChassis) {
        return impl.finalizeHardwareGenChassis(chassis);
    }

    // Default does nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No finalizeHardwareGenChassis() implemented for: ' + chassis.platform);
    }
}




