

export interface ImageInfo {
    imgSrc: string;
    width: number;
    height: number;
}

export enum ChassisElementType {
    PowerSupply = 'PowerSupply',
    EmptySlot = 'EmptySlot',
    SlotSep = 'SlotSep',
    GrpSep = 'GrpSep',
    RightCap = 'RightCap',
    IOBase = 'IOBase',
    Backplate = 'Backplate',
    IOTermBlk = 'IOTermBlk',
    Jumper = 'Jumper'
}
