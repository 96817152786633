import React from 'react';
import './PerformanceInfo.scss';
import '../../styles/Meteor.scss'
import { LinearProgress } from '@mui/material';
import { UsageLevelStatus } from '../../types/ProjectTypes';
import './PerformanceInfo.scss';
import { Theme } from '@rockwell-automation-inc/ra-meatball';


const getColor = (status: UsageLevelStatus): string => {
    switch (status) {
        case UsageLevelStatus.OK:
            return Theme.palette.success.main;

        case UsageLevelStatus.Warning:
            return Theme.palette.warning.main;

        default:
            return Theme.palette.error.main;
    }
}


interface Props {
    label: string;
    pct: number;
    text: string;
    status: UsageLevelStatus;
    progWidth?: number;
}

const PerfColUsageItem = (props: Props) => {

    const progBarValue = props.pct * 100;
    const ctlColor = getColor(props.status);
    const textColor = (props.status === UsageLevelStatus.Error)
        ? Theme.palette.error.main
        : Theme.palette.black.main;

    const progWidth = (props.progWidth !== undefined)
        ? props.progWidth
        : 150;

    const lableFontWeight = 'normal';

    return (
        <div className='perf-column-usage-item'>
            <span
                className='main-body-highlighted'
                style={{ color: textColor, fontWeight: lableFontWeight }}
            >
                {props.label + ': '}
            </span>
            <LinearProgress
                variant={'determinate'}
                value={progBarValue}
                sx={{
                    marginTop: '10px',
                    width: progWidth,
                    bgcolor: `${ctlColor}40`,
                    '& .MuiLinearProgress-bar': {
                        bgcolor: ctlColor,
                    }
                }}
            />
            <span
                className='a-type__body-new'
                style={{ color: textColor }}
            >
                {props.text}
            </span>
        </div>
    );
}

export default PerfColUsageItem;
