import { getModuleSlotBreakdown, getProductDescription, isWiringTypeSupported } from "../../model/ChassisProject";
import { ChoicesGroup, MessageCategory, MessageType, PanelMessage, StatusLevel } from "../../types/MessageTypes";
import { Chassis, ChassisModule, IOModuleWiring, SupportedWiringType } from "../../types/ProjectTypes";
import { getSupportedWiringTypes, getTerminalConnectivityChoices } from "../../util/AccessoriesHelp";
import { makeBold } from "../../util/GeneralHelpers";
import { ConfigChassisCBData } from "./ChassisConfig";



////////////////// WIRING ///////////////////////////////////////////////////////
export const cfgDlgChangeModuleWiringOpt = (module: ChassisModule, optGroup: ChoicesGroup) => {
    // The incoming choices group SHOULD contain all of the
    // wiring options available for the given module in its
    // first (and only) subgroup, and the group should include
    // a .default value. The default will be the actual
    // accy code we want to use on the module.
    // Confirm that we were actually given a valid default.
    const desiredID = (optGroup.default && (optGroup.default.length > 0))
        ? optGroup.default
        : undefined;

    // If so, and if the group has a single subgroup...
    if (desiredID && (optGroup.subgroups.length === 1)) {

        // The choices available will be found in
        // the selection info from that first subgroup.
        const choices = optGroup.subgroups[0].selectionInfo;

        // Set up module accessories collection.
        const modAccys: string[] = module.accys ? module.accys : new Array<string>();

        // and arrays of IDs we'll need to 
        // remove from that accys collection.
        const accysToRemove = new Array<string>();

        // Start pessimistic about finding
        // what we're looking for.
        let foundDesired = false;

        // And start with assumption we'll
        // end up adding the desired id to
        // the accys.
        let addDesired = true;

        // For each possible choice we have...
        for (let idx = 0; idx < choices.length; idx++) {

            // Get the id of the choice.
            const choiceID = choices[idx].id;

            // If this one MATCHES what we want
            // the module to use (desiredID)...
            if (choiceID === desiredID) {
                // Set our found-it flag.
                foundDesired = true;

                // If our mod accys already includes
                // it, then we won't need to add it.
                if (modAccys.includes(choiceID)) {
                    addDesired = false;
                }
            }
            else {
                // Not what we're looking for.
                // If our mod accys includes this
                // one, we'll want to remove it.
                if (modAccys.includes(choiceID)) {
                    accysToRemove.push(choiceID);
                }
            }
        }

        // We should ALWAYS find what we're looking
        // for in the available choices we're given.
        // If so...
        if (foundDesired) {
            // Remove any/all identified above
            // from the modAccys collection.
            accysToRemove.forEach(id => {
                const pos = modAccys.indexOf(id);
                if (pos >= 0) {
                    modAccys.splice(pos);
                }
                else {
                    // Unexpected
                    throw new Error('Unexpected Error in changeModuleWiringOpt?');
                }
            })

            // And if we decided we need to add
            // the desired id, do so.
            if (addDesired) {
                modAccys.push(desiredID);
            }

            // Finally, assign our final accys
            // array back to the module.
            module.accys = modAccys;
        }
        else {
            // Unexpected
            throw new Error('Error in changeModuleWiringOpt - missing option.');
        }
    }
}

export const cfgDlgChangeWiringTypeOnMods = (
    chassis: Chassis,
    wiringType: IOModuleWiring,
    catSptMap: Map<string, number>
) => {
    // Determine which slots have modules in them.
    const [modSlots,] = getModuleSlotBreakdown(chassis.modules);

    // For each of those slots...
    for (let idx = 0; idx < modSlots.length; idx++) {

        // Get the module located there.
        const mod = chassis.modules[modSlots[idx]];

        // If we can...
        if (mod) {

            // Then we only (MIGHT) care, if the module's
            // catalog number is present in our map. If so...
            if (catSptMap.has(mod.catNo)) {

                // Get the wiring types supported by
                // the given module from the map.
                const modSptTypes = catSptMap.get(mod.catNo);

                // if we can (we always should be able to)...
                if (modSptTypes) {

                    // Then check if this module
                    // supports the new type. If so...
                    if (isWiringTypeSupported(wiringType, modSptTypes)) {

                        // Call a helper to give us a ChoicesGroup with all
                        // possible wiring accy options, and provide our
                        // wiring type as our desired default.
                        const wiringOpts = getTerminalConnectivityChoices(mod, wiringType);

                        // We SHOULD get a result, and it should
                        // include a .default prop. If so...
                        if (wiringOpts && wiringOpts.default) {

                            // Call another helper to use that to make our change.
                            cfgDlgChangeModuleWiringOpt(mod, wiringOpts);
                        }
                        else {
                            throw new Error('Error 3 in changeWiringTypeOnMods!');
                        }
                    }
                }
                else {
                    throw new Error('Error 2 in changeWiringTypeOnMods!');
                }
            }
        }
        else {
            throw new Error('Error 1 in changeWiringTypeOnMods!');
        }
    }
}

export const cfgDlgAdjustWiringMapForSwaps = (
    wiringSptMap: Map<string, number>,
    swapMap: Map<string, string>) => {

    // For each entry in the swap map...
    swapMap.forEach((newCat, oldCat) => {

        // If our wiring map includes the OLD cat...
        if (wiringSptMap.has(oldCat)) {

            // Get the val we have for it.
            const sptVal = wiringSptMap.get(oldCat);

            // If we can...
            if (sptVal) {
                // Add an entry using the NEW cat
                // as a key and the old value.
                wiringSptMap.set(newCat, sptVal);

                // Remove the OLD entry.
                wiringSptMap.delete(oldCat);
            }
        }
    });
}

export const cfgDlgGetPossibleModWiringTypes = (chassis: Chassis):
    [allTypesPossible: number, mapByCat: Map<string, number>] => {

    // Init return to NA (none applicable)
    let allTypesPossible = SupportedWiringType.NA;

    // Create our return map. Start empty.
    const catMap = new Map<string, number>();

    // Set up a set to remember cat
    // numbers we've already checked.
    const checked = new Set<string>();

    const lastSlot = chassis.modules.length - 1;
    let slotIdx = 0;

    // Walk slots until we're done.
    while (slotIdx <= lastSlot) {

        // Get the module in the slot.
        const mod = chassis.modules[slotIdx];

        // If there is one...
        if (mod) {
            // If we haven't already checked this one...
            if (!checked.has(mod.catNo)) {

                // Add it to our set so we don't
                // look up the same one again.
                checked.add(mod.catNo);

                // See what, if any, wiring types
                // are supported by the module.
                const supportThisMod = getSupportedWiringTypes(mod);

                // If any...
                if (supportThisMod > 0) {

                    // Map the cat with its OWN value.
                    catMap.set(mod.catNo, supportThisMod);

                    // and include any of its types
                    // in our combined all-types value.
                    allTypesPossible |= supportThisMod;
                }
            }

            // Bump slot index to get
            // past the module.
            slotIdx += mod.slotsUsed;
        }
        else {
            // No module in this slot.
            // Just increment index.
            slotIdx++;
        }
    }

    // Return our results.
    return [allTypesPossible, catMap];
}

export const cfgDlgAddWiringChangeMsg = (wiringSel: string, data: ConfigChassisCBData) => {
    // Get the wiring type from the sel.
    const wiringType = wiringSel as IOModuleWiring;

    let abbrev = '';
    let optText = '';
    switch (wiringType) {
        case IOModuleWiring.Screw:
            abbrev = makeBold('Screw Terminal Base');
            optText = 'a ' + abbrev + ' accessory';
            break;

        case IOModuleWiring.SpringClamp:
            abbrev = makeBold('Spring-Clamp Terminal Base');
            optText = 'a ' + abbrev + ' accessory';
            break;

        case IOModuleWiring.IOReadyCable:
            abbrev = makeBold('I/O Ready Cable');
            optText = 'an appropriate cable as an accesory';
            break;

        case IOModuleWiring.WiringSys:
            abbrev = makeBold('Wiring System');
            optText = 'a Wiring System';
            break;
    }

    const message: PanelMessage = {
        level: StatusLevel.Info,
        text: 'The default terminal wiring style for the chassis ' +
            'will be changed to:  ' + makeBold(wiringSel) + '. Any I/O module ' +
            'added after the change will be initially configured to ' +
            'include ' + optText + ' (if supported by the module).',
        category: MessageCategory.Wiring,
        type: MessageType.Situational
    };

    // If we have any I/O modules at all, we'll have a
    // non-zero value in our all-possibles prop. If so...
    if (data.allPossibleWiringTypes !== 0) {

        // Then we should ALSO have a mapping of unique I/O module
        // cat numbers found to their support levels. If so...
        if (data.mapCatToWiringSpt && (data.mapCatToWiringSpt.size > 0)) {


            // Set up two arrays, one for catNos that support
            // the type, and one for those catNos that DON'T.
            const okMods = new Array<string>();
            const badMods = new Array<string>();

            // Walk the map. For each entry...
            data.mapCatToWiringSpt.forEach((supported, cat) => {

                // Check if the type is supported, and
                // add to the corresponding array.
                if (isWiringTypeSupported(wiringType, supported)) {
                    okMods.push(cat);
                }
                else {
                    badMods.push(cat);
                }
            });

            const platform = data.cfgAttrInfo.platform;

            if (okMods.length > 0) {
                if (badMods.length > 0) {
                    // Some of each.
                    message.text += '\n\nThe configurations of all existing ' +
                        'I/O modules that support the new style will ' +
                        'be changed to include the ' + abbrev + ' accessory. Existing modules that ' +
                        'do ' + makeBold('NOT') + ' support the ' + wiringSel +
                        ' style are shown below:\n\n';

                    badMods.forEach(cat => {
                        const desc = getProductDescription(platform, cat);
                        message.text += '      ' + makeBold(cat) + ' - ' +
                            desc + '\n';
                    });
                }
                else {
                    // All OK
                    message.text += '\n\nThe configurations of all ' +
                        'I/O modules currently in the chassis will ' +
                        'be changed to include the ' + abbrev + ' accessory.';
                }
            }
            else {
                if (badMods.length > 0) {
                    message.text += '\n\n' + makeBold('NONE') + ' of the I/O modules ' +
                        'currently in the chassis support the ' +
                        makeBold(wiringSel) + ' style. Their wiring ' +
                        'configurations will not be changed.';
                }
                else {
                    // Unexpected.
                    throw new Error('Unexpected error in addWiringChangeMsg!');
                }
            }
        }
        else {
            throw new Error('Missing map in addWiringChangeMsg!');
        }
    }
    else {
        message.text += '\n\nThe chassis currently contains no I/O modules.';
    }

    data.panelMessages.push(message);
    if (data.msgLevel === StatusLevel.NA) {
        data.msgLevel = StatusLevel.Info;
    }
}
