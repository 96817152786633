import { getChassisName } from '../model/ChassisProject';
import { Chassis, EnvRating } from '../types/ProjectTypes';
import { makeBold } from './GeneralHelpers';
/* import { ToolbarAction } from '../toolbar/ToolbarActions'; */


export const getConfirmMsgChassisDelete = (chassis: Chassis): string => {
    const msg = makeBold('Please Note:') + ' If you delete this chassis (' +
        getChassisName(chassis) + '), all of its components will ' + 
    makeBold('ALSO') + ' be deleted.';
    return msg;
}

export const getMsgTextForEnvType = (env: EnvRating): string => {
    switch (env) {
        case EnvRating.ExtTemperature:
            return 'rated for use in Extended Temperatures';

        case EnvRating.ConformalCoated:
            return 'Conformally Coated';

        case EnvRating.Standard:
            return 'not Conformally Coated or rated for use in Extended Temperatures';

        default:
            return 'UNKNOWN TYPE';
    }
}

/* export const getFutureFeatureDesc = (action: string): string => {

    const genMsg = 'This feature will be added in an upcoming release.\n\n' +
        makeBold('Stay tuned!');

    switch (action) {
        case ToolbarAction.ORGANIZECHASSIS:
            return 'The ' + makeBold('Organize') + ' feature will allow you to' +
                ' rearrange the order of chassis in your project. ' + genMsg;

        case ToolbarAction.EDITALLCHASSIS:
            return 'The ' + makeBold('Edit all Chassis') + ' feature will allow you to' +
                ' apply common selections to all of the chassis in your project. ' +
                genMsg;

        //case ToolbarAction.COPYMODE:
        //    return 'The ' + makeBold('Advanced Copy Mode') + ' feature will allow you to' +
        //        ' quickly copy a selected module to any empty slot in any chassis, or ' +
        //        'to as many slots as needed. ' +
        //        genMsg;

        //case ToolbarAction.DELMODE:
        //    return 'The ' + makeBold('Advanced Delete Mode') + ' feature will allow you to' +
        //        ' quickly delete as many modules as needed.\n\n' +
        //        genMsg;

        default:
            return genMsg;
    }
}
 */

