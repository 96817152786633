import { RegisterCpLXCheckerImpl } from "./CpLXChecker";
import { RegisterCpLXEngDataImpl } from "./CpLXEngDataImpl";
import { RegisterCpLXGeneralImpl } from "./CpLXGeneralImpl";
import { registerCpLXHWGenImpl } from "./CpLXHardwareImpl";


export const initCpLXPlatform = (): boolean => {
	registerCpLXHWGenImpl();
	RegisterCpLXGeneralImpl();
	RegisterCpLXCheckerImpl();
	RegisterCpLXEngDataImpl();

	return true;
}
