import { LogImplDefaults } from "../types/Globals";
import { Chassis, ChassisProject } from "../types/ProjectTypes";
import { AF_Base } from "../util/CheckerAutoFix";
import { logger } from "../util/Logger";

// Registered Checker Implementations.
const _checkerRegistry = new Map<string, CheckerImplementation>();

// Specifications for optional platform-specific routes.
interface _implGeneralCheck {
    (chassis: Chassis): void;
}

interface _implChassisPowerCheck {
    (chassis: Chassis): boolean;
}

interface _implSwapCtlrForSafetyCtrl {
    (chassis: Chassis, advCtrlRequired: boolean): boolean;
}

interface _implAutoFix {
    (autoFix: AF_Base, project: ChassisProject, callback: () => void): boolean;
}

// Routing specifications for a platform.
export interface CheckerImplementation {
    // Required
    platform: string;

    // Optional
    doGeneralCheck?: _implGeneralCheck;
    doChassisPowerCheck?: _implChassisPowerCheck;
    swapCtlrForSafetyCtrl?: _implSwapCtlrForSafetyCtrl; // Not sure which impl this should go into(?).
    executeAutoFix?: _implAutoFix;
}

// Implementation registration.
export const RegisterCheckerImpl = (impl: CheckerImplementation) => {
    const platformID = impl.platform.trim();
    if (platformID && platformID === impl.platform) {
        if (!_checkerRegistry.has(platformID)) {
            _checkerRegistry.set(platformID, impl);
        }
        else {
            logger.warn('RegisterChecker ignoring duplicate register: ' + impl.platform);
        }
    }
    else {
        throw new Error('Invalid platform ID in RegisterChecker!');
    }
}

// Implementation retrieval - NOT EXPORTED.
const _getImplementation = (platform: string): CheckerImplementation | undefined => {
    return _checkerRegistry.get(platform);
}

// EXPORTED API
// NOTE: In ANY of the following, an empty or invalid
// platform ID will result in default functionality.

export const doGeneralCheck = (chassis: Chassis) => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.doGeneralCheck) {
        return impl.doGeneralCheck(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No generalCheck implemented for: ' + chassis.platform);
    }
}

export const doChassisPowerCheck = (chassis: Chassis) => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.doChassisPowerCheck) {
        return impl.doChassisPowerCheck(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No chassisPowerCheck implemented for: ' + chassis.platform);
    }
}

export const swapCtlrForSafetyCtrl = (chassis: Chassis, advCtrlRequired: boolean): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.swapCtlrForSafetyCtrl) {
        return impl.swapCtlrForSafetyCtrl(chassis, advCtrlRequired);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No swapCtlrForSafetyCtrl() implemented for: ' + chassis.platform);
    }
    return false;
}


export const executeAutoFix = (platform: string, autoFix: AF_Base,
    project: ChassisProject, callback: () => void) => {

    const impl = _getImplementation(platform);
    if (impl && impl.executeAutoFix) {
        return impl.executeAutoFix(autoFix, project, callback);
    }

    // Default - Do nothing
    if (LogImplDefaults && platform) {
        logger.logImpl('No executeAutoFix implemented for: ' + platform);
    }

    return false;
}
