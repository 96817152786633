import { AxiosRequestConfig } from "axios";
import { getApiUrl } from "platform-services";
import axios from "axios";
import { PostProductGetProducts} from "../../selectComponents/SelectComponentsTypes";

export const raTimeoutMessage = 'RA_REQUEST_TIMEOUT';

class ProductApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "WEB_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
      withCredentials: true,
    };
  }

  GetProduct(catalogNumber: string, locale: string) {
    const url = `${getApiUrl(
      this.api
    )}/Product/GetProduct?catalogNumber=${catalogNumber}&locale=${locale}`;

    return axios.get(url);
  }

  GetDetailedProduct(catalogNumber: string, sourceType: string, timeout: number = -1) {
        this.config.timeout = undefined;
        this.config.timeoutErrorMessage = undefined;
        if (timeout > 0) {
            this.config.timeout = timeout;
            this.config.timeoutErrorMessage = raTimeoutMessage;
        }

    const url = `${getApiUrl(
      this.api
    )}/Product/GetDetailedProduct?catalogNumber=${catalogNumber}&type=${sourceType}`;

    return axios.get(url, this.config);
  }

  GetProducts(tid: string, locale: string) {
    const url = `${getApiUrl(
      this.api
    )}/Product/GetProducts?tid=${tid}&locale=${locale}`;

    return axios.get(url);
  }

  GetShorterLeadTimeProducts(catalogNumber: string, locale: string) {
    const url = `${getApiUrl(
      this.api
    )}/Product/GetShorterLeadTimeProducts?catalogNumber=${catalogNumber}&locale=${locale}`;

    return axios.get(url);
  }

  PostProductAttributeComparisons(catalogNumbers: string[]) {
    const formatedCatalogNumbers = {
      catalogNumbers: catalogNumbers,
    };

    const url = `${getApiUrl(
      this.api
    )}/Product/PostProductAttributeComparisons`;

    return axios.post(url, formatedCatalogNumbers);
  }

  PostProductGetProducts(PostProductGetProductsRequest: PostProductGetProducts){
    const url = `${getApiUrl(
      this.api
    )}/Product/GetProducts`;

    return axios.post(url, PostProductGetProductsRequest);
  }
}

const productApiService = new ProductApiService();
export default productApiService;
