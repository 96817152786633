import React from "react";
import {
  getModuleSlotBreakdown,
  getModuleSlotID,
} from "../../../model/ChassisProject";
import { MICROLayoutInfo } from "../types/MicroTypes";
import SelectableDeviceComp from "../../../components/SelectableDeviceComp";
import { getChassisRenderSpec } from "../../../util/LayoutModeHelp";
import { ChassisRendType, ModuleDragStatus } from "../../../types/ProjectTypes";
import { LocAndSize } from '../../../types/SizeAndPosTypes';
import { getEmptyLoc } from '../../../util/GeneralHelpers';
import {
  MicroChassisCompProps, canExtendChassis
} from "../../../implementation/ImplGeneral";
import ChassisElComp from "../../common/ChassisElComp";
import { ChassisElementType } from "../../common/CommonPlatformTypes";

const MicroChassisComp = (props: MicroChassisCompProps) => {
  // Get our chassis layout. We should ALWAYS be able to.
  const layout = props.chassis.layout as MICROLayoutInfo;
  if (!layout) {
    throw new Error("MicroChassisComp passed chassis without valid layout!");
  }

  // This is a primary chassis unless our render type
  // specifically says we're a redundant secondary.
  const primaryChassis = props.renderType !== ChassisRendType.RedSecondary;

  const defaultRender = !props.renderType || !props.layoutMode;
  const modeForSpec = defaultRender ? undefined : props.layoutMode;

  // Call a helper to give us rendering details for the various
  // elements of our chassis (ps, modules, empty slots, etc.)
  const elRenderSpec = getChassisRenderSpec(
    modeForSpec,
    props.chassis,
    primaryChassis,
    props.showAsSelected === true
  );

  
  const _addXSlot = (
    props: MicroChassisCompProps
  ): [show: boolean, loc: LocAndSize, id: string] => {
    if (canExtendChassis(props.chassis)) {
      // See how many slot locs the chassis layout actually has.
      const slotLocs = props.chassis.modules.length;

      // Get a copy of the LAST one.
      const loct = { ...props.chassis.layout.slotLocs[slotLocs - 1] };
      const currentloc = { ...props.chassis.layout.slotLocs[slotLocs] };

      loct.x += currentloc.width;

      const xSlotId = props.chassis.modules.length;

      // We DO want a right slot.
      return [true, currentloc, xSlotId.toString()];
    }

    return [false, getEmptyLoc(), ""];
  };

   const [extraRightSlot, extraSlotLoc] = _addXSlot(props);


  // Get breakdown of i/o expansion slots (which have modules and which are empty).
  const [modSlots] = getModuleSlotBreakdown(props.chassis.modules);

  // See if our chassis should be depicted as 'selected'.
  const showSelected = props.showAsSelected === true;
  // Determine the opacity we'll use for empty slots.
  // We want them to be faded and lighter when we have
  // add ('+') action buttons on them.
  const showSlotIds = defaultRender ? false : true;
  const renderModule = (slotNum: number) => {
    const module = props.chassis.modules[slotNum];
    if (module) {
      const rndType =
        props.renderType !== undefined
          ? module.slotFiller
            ? elRenderSpec.slotFillers
            : elRenderSpec.modules
          : undefined;
      const slotId = showSlotIds ? getModuleSlotID(module) : undefined;
      return (
        <SelectableDeviceComp
          key={slotNum}
          device={module}
          devDragStatus={module.dragStatus}
          showSelected={showSelected && props.localDeviceSelected === module}
          renderType={rndType}
          ptOrg={props.ptOrg}
          relLocation={layout.slotLocs[slotNum]}
          slotId={slotId}
        />
      );
    } else {
      return null;
    }
  };

  // Note: In order to best preserve key uniqueness
  // from render to render, we'll ONLY use an incrementing
  // number as a key for separators (slot seps and grp seps).
  // Those should ALWAYS be the same to the same chassis layout.
  return (
    <>
      {props.chassis.ps ? (
        <SelectableDeviceComp
          key={"PS"}
          device={props.chassis.ps}
          devDragStatus={ModuleDragStatus.NA}
          showSelected={
            showSelected && props.localDeviceSelected === props.chassis.ps
          }
          renderType={props.renderType ? elRenderSpec.ps : undefined}
          ptOrg={props.ptOrg}
          relLocation={props.chassis.ps.loc}
        />
      ) : null}

      {props.chassis.bu ? (
        <SelectableDeviceComp
          key={"BU"}
          device={props.chassis.bu}
          devDragStatus={ModuleDragStatus.NA}
          showSelected={
            showSelected && props.localDeviceSelected === props.chassis.bu
          }
          renderType={props.renderType ? elRenderSpec.bu : undefined}
          ptOrg={props.ptOrg}
          relLocation={props.chassis.bu.loc}
        />
      ) : null}

  
      {modSlots.map((slot) => {
        return renderModule(slot);
      })}


      {extraRightSlot && props.chassis.selected
                ? <>
                    <ChassisElComp
                        key={'XRS'}
                        elType={ChassisElementType.EmptySlot}
                        renderType={elRenderSpec.emptySlots}
                        imgSrc={layout.emptySlotImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={extraSlotLoc}
                    />
                </>:  null
}

    </>
  );
};

export default MicroChassisComp;
