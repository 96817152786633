import { PlatformCpLX } from "../../PlatformConstants";
import CpLXDefEngData from '../data/EngData5069.json';
import CpLXChassisEngData from '../data/ChassisEngData5069.json';
import {
    EngDataImplementation,
    RegisterEngDataImpl
} from "../../../implementation/ImplEngData";
import { EngInfoPackage } from "../../../types/EngDataTypes";
import { getModulesInPkg } from "../../../util/EngInfoHelp";
import { isSizeEmpty } from "../../../util/GeneralHelpers";
import { EngInfoModule, IEngDataComponent, makeEngInfoFrom } from "../../../engData/EngineeringInfo";
import { logger } from "../../../util/Logger";


const loadChassis = (pkg: EngInfoPackage) => {
    const chasData = CpLXChassisEngData as IEngDataComponent[];
    if (chasData) {
        for (let idx = 0; idx < chasData.length; idx++) {
            const entry = chasData[idx];
            const engInfo = makeEngInfoFrom(entry);
            if (engInfo && engInfo.isChassis) {
                if (engInfo.catNo) {
                    engInfo.isPlaceholder = true;
                    if (!pkg.infoMap.has(engInfo.catNo)) {
                        pkg.infoMap.set(engInfo.catNo, engInfo);
                    }
                    else {
                        logger.error('Dup chassis in loadChassis: ' + engInfo.catNo);
                    }
                }
                else {
                    logger.error('loadChassis: entry missing catNo!');
                }
            }
            else {
                logger.error('loadChassis: unexpected makeEngInfoFrom result!');
            }
        }
    }
    else {
        logger.error('loadChassis: no data?');
    }
}

const cplxGetDfltEngDataJSON = (): object => {
    return CpLXDefEngData;
}

const _getModuleImgWidth = (mod: EngInfoModule) => {
    if (mod.isController) return (mod.dimensions.width === 153.5) ? 349 : 223;
    if (mod.isCommModule) return 117;
    if (mod.isIO) return (mod.dimensions.width === 36) ? 86 : 52;
    return 52;
}

const cplxEngDataPostLoad = async (pkg: EngInfoPackage) => {

    const modules = getModulesInPkg(pkg);
    modules.forEach(mod => {
        if (isSizeEmpty(mod.imgSize)) {
            mod.imgSize.height = 280;
            mod.imgSize.width = _getModuleImgWidth(mod);
        }
    });
    loadChassis(pkg);
    return;
}


const cplxImpl: EngDataImplementation = {
    platform: PlatformCpLX,
    preload: undefined,
    getDfltEngData: cplxGetDfltEngDataJSON,
    postLoad: cplxEngDataPostLoad,
}


export const RegisterCpLXEngDataImpl = () => {
    RegisterEngDataImpl(cplxImpl);
}
