import React, { useState } from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { Chassis } from '../../types/ProjectTypes';
import DetailsChassisProduct from './DetailsChassisProduct';
import DetailsStatus from './DetailsStatus';
import SimpleTabs, { SimpleTabInfo } from '../choices/SimpleTabs';
import DetailsChassisSpecs from './DetailsChassisSpecs';
import DetailsChassisPower, { ChassisPowerType } from './DetailsChassisPower';
import DetailsCommonDocTab from './DetailsCommonDocTab';

const TabID = {
    Specifications: 1,
    Power: 2,
    Documentation: 3
};

const loadTabInfo = (): SimpleTabInfo[] => {
    const info = new Array<SimpleTabInfo>();

    info.push({
        text: 'Specifications',
        id: TabID.Specifications
    });

    info.push({
        text: 'Power',
        id: TabID.Power
    });

    info.push({
        text: 'Documentation',
        id: TabID.Documentation
    });

    return info;
}

const tabInfo = loadTabInfo();


interface Props {
    chassis: Chassis;
    onClickEdit: () => void;
}

const DetailsChassis = (props: Props) => {
    const [currentTab, setCurrentTab] = useState<number>(TabID.Specifications);


    const onTabSelected = (tabID: number) => {
        if (tabID !== currentTab) {
            setCurrentTab(tabID);
        }
    }

    const renderTab = (tabID: number) => {
        switch (tabID) {
            case TabID.Specifications:
                return (
                    <DetailsChassisSpecs chassis={props.chassis} />
                );

            case TabID.Power:
                return (
                    <DetailsChassisPower chassis={props.chassis} powerType={ChassisPowerType.chassis} />
                );

            case TabID.Documentation:
                return (
                    <DetailsCommonDocTab device={props.chassis} />
                );

            default:
                return (null);
        }
    }

    return (
        <div
            className='floating-details-content'
        >
            <div className='floating-details-upper'>
                <DetailsChassisProduct
                    chassis={props.chassis}
                    onClickEdit={props.onClickEdit}
                />
                <DetailsStatus device={props.chassis} />
            </div>
            <div className='floating-details-tab-area'>
                <SimpleTabs
                    tabInfo={tabInfo}
                    currentID={currentTab}
                    onTabSelected={onTabSelected}
                />
            </div>
            <div className='floating-details-tab-content'>
                {renderTab(currentTab)}
            </div>
       </div>
    );
}

export default DetailsChassis;
