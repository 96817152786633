import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { PlatformFlex } from "../../PlatformConstants";
import { snapDoChassisPowerCheck, snapDoGeneralCheck } from "../../snap/SnapChecker";
import { flexExecuteAutoFix } from "./FlexAutoFix";


const flexImpl: CheckerImplementation = {
    platform: PlatformFlex,
    doGeneralCheck: snapDoGeneralCheck,
    doChassisPowerCheck: snapDoChassisPowerCheck,
    executeAutoFix: flexExecuteAutoFix,
}


export const RegisterFlexCheckerImpl = () => {
    RegisterCheckerImpl(flexImpl);
}
