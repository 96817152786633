import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { DeviceType, SelectableDevice } from '../../types/ProjectTypes';
import { makeDevicePowerGrp, makeDimensionsGrp, getIOusageMicro } from '../../model/DeviceDetail';
import DetailsGroup from './DetailsGroup';
import { getDevEnvRatingDtlText } from '../../platforms/clx/model/CLXChassis';
import { getDeviceDimensions, getPowerBreakdown } from '../../model/ChassisProject';


interface Props {
    device: SelectableDevice;
    ps: boolean;
}

const DetailsDeviceSpecs = (props: Props) => {
    

    const pwrInfo = getPowerBreakdown(props.device.platform, props.device.catNo);
    const pwrGroup = makeDevicePowerGrp(props.device.platform, props.ps, pwrInfo);

    const deviceDims = getDeviceDimensions(props.device);
    const dimsGrp = makeDimensionsGrp(deviceDims);
    const getSlot = getIOusageMicro(props.device.parent);
    return (
        <div className='floating-details-tab'>
            <DetailsGroup group={pwrGroup} />
            <DetailsGroup group={dimsGrp} />
            {props.device.deviceType === DeviceType.BU && <DetailsGroup group={getSlot} /> } 
            <div className='floating-details-device-env'>
                <span className='a-type__body highlighted-item'>
                    Environmental Rating
                </span>
                <span className='a-type__body'>
                    {getDevEnvRatingDtlText(props.device)}
                </span>
            </div>
        </div>
    );
}

export default DetailsDeviceSpecs;
