import React from 'react';
import { Rect } from 'react-konva';
import { LocAndSize } from '../types/SizeAndPosTypes';
import { Theme } from '@rockwell-automation-inc/ra-meatball';


const selRectProps = {
    stroke: Theme.palette.primary.main,
    strokeWidth: 5,
    dash: [15, 10],
    cornerRadius: 15,
};


interface Props {
    selRect: LocAndSize;
}

const SelectedChassisRect = (props: Props) => {

    return (
        <Rect
            listening={false}
            {...props.selRect}
            {...selRectProps}
        />
    );
}

export default SelectedChassisRect;
