import { LocAndSize, NormRect, Point, Size } from "../types/SizeAndPosTypes";
import { normRectToLoc } from "./GeneralHelpers";
import { getRedSecDistAbovePri, getRedSecDistBelowPri, getRedSecDistLeftOfPri, getRedSecDistRightOfPri, RedBelowGap, RedBesideGap, RedDepictOption } from "./RedChassisHelp";


const selRectMrg = {
    left: 14,
    right: 14,
    top: 14,
    bottom: 14
};


const addSelRectMargins = (rct: NormRect) => {
    rct.left -= selRectMrg.left;
    rct.top -= selRectMrg.top;
    rct.right += selRectMrg.right;
    rct.bottom += selRectMrg.bottom;
}


export const getChassisSelRect = (
    ptOrg: Point,
    chSize: Size,
    redundant: boolean,
    redDepict: RedDepictOption
): LocAndSize => {

    const rct: NormRect = {
        left: ptOrg.x,
        top: ptOrg.y,
        right: ptOrg.x + chSize.width,
        bottom: ptOrg.y + chSize.height
    };

    if (redundant) {
        switch (redDepict) {
            case RedDepictOption.Behind:
                rct.left -= getRedSecDistLeftOfPri();
                rct.top -= getRedSecDistAbovePri();
                rct.right += getRedSecDistRightOfPri();
                rct.bottom += getRedSecDistBelowPri();
                break;

            case RedDepictOption.Beside:
                rct.right += (chSize.width + RedBesideGap);
                break;

            case RedDepictOption.Below:
                rct.bottom += (chSize.height + RedBelowGap);
                break;

            default:
                break;
        }
    }

    addSelRectMargins(rct);
    const loc = normRectToLoc(rct);
    return loc;

}
