import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface Props {
    selection: string;
	choices: string[];
	selectionChanged: (newTextValue: string) => void;
}

const SimpleCombo = (props: Props) => {

	const onChoiceMade = (event: SelectChangeEvent) => {
        props.selectionChanged(event.target.value as string);
	};

    let nextChoiceKey = 1;

	return (
        <FormControl>
            <Select
                labelId="simple-select-label"
                id="simple-select"
                value={props.selection}
                size={'small'}
                sx={{
                    width: '270px',
                    height: '48px',
                    justifyContent: 'left'
                }}
                onChange={onChoiceMade}
            >
                {props.choices.map(choice => {
                    return (
                        <MenuItem
                            key={nextChoiceKey++}
                            value={choice}
                            sx={{ fontSize: 14, margin: '4px', padding: '0px 4px 0px 8px' }}
                        >
                            {choice}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
	);
}

export default SimpleCombo;

