import { getNumIOBases } from "../../../implementation/ImplGeneral";
import { Use5015TransparentImgs } from "../../../types/Globals";
import { Chassis, ChassisLayoutInfo, EnvRating } from "../../../types/ProjectTypes";
import { LocAndSize, Point, Size } from "../../../types/SizeAndPosTypes";
import { CableSpec, makeEmptyCableSpec } from "../../../util/CableHelp";
import { getEmptyLoc, scaleSize } from "../../../util/GeneralHelpers";


export const maxFlexHAChassisSize = 25;
export const maxFlexHAIOBases = 6;
export const maxFlexHAIOModules = 24;
export const maxFlexHABankExtKits = 2;


export const flexHAGetChassisCatalog = (envRat: EnvRating) => {
    switch (envRat) {
        case EnvRating.ExtTemperature:
            return '5015-CHASSIS-XT';
        case EnvRating.ConformalCoated:
            return '5015-CHASSIS-K';

        default:
            return '5015-CHASSIS';
    }
}

export interface FlexHALayoutInfo extends ChassisLayoutInfo {
    ioBaseLocs: LocAndSize[];
    ioTBLocs: LocAndSize[];
    backplateLoc: LocAndSize;
}

interface FlxHASizeDetailsIO {
    baseSize: Size;
    orgSlots: Point;
    slotSize: Size;
    orgIOTBs: Point;
    iotbSize: Size;
}

export interface FlxHASizeDetails {
    baseScale: number;
    adapterKitLeft: number;
    sizeAdapterKit: Size;
    bpYOffset: number;
    bpSideExt: number;
    bpBtmExt: number;
    bpHeight: number;
    ioBaseInfo: FlxHASizeDetailsIO;
    sizePwrJumper: Size;
    psuLoc1: LocAndSize;
    psuLoc2: LocAndSize;
    psuCables: CableSpec[];
}

// NOTE: We use 'scaled' here to know that we can use the
// info directly. For FlexHA, the image scale factor is 1.0,
// so 1 pixel for a position, width, or height will translate
// directly to 1 Stage Unit in use.
let _scaledSizeInfo: FlxHASizeDetails | undefined = undefined;

const _establishSizeDtls = () => {

    // Size of images used.
    // PSU modules
    const psuSize = { width: 120, height: 473 };

    const baseHeight = 915;

    // 4-slot I/O base unit.
    const ioBaseSize: Size = { width: 816, height: baseHeight };

    // Simplex I/O module (Duplex has twice width)
    const modImgSize: Size = { width: 204, height: 586 }; 

    // Simple I/O TB and cover (Duplex version has twice width)
    const iotbImgSize: Size = { width: 178, height: 270 }; 

    // Note: Our OVERALL scale for FlexHA is 1.0, which
    // generally means that 1 pixel === 1 stage unit. However,
    // in some cases we make some internal scale adjustments
    // when sizing our slots / modules and terminal blocks.

    // When NOT using our transparent image variants, the
    // slots (modules in position) look better when inset
    // a bit from the left and right sides. 
    const slotSideMrg = Use5015TransparentImgs ? 0 : 6;

    const slotAreaWidth = ioBaseSize.width - (2 * slotSideMrg);
    const slotScale = slotAreaWidth / ioBaseSize.width;
    const slotSize = scaleSize(modImgSize, slotScale);

    const iotbSideMrg = 44;
    const iotbAreaWidth = ioBaseSize.width - (2 * iotbSideMrg);
    const tbWidthAvailEa = iotbAreaWidth / 4;
    const iotbScale = tbWidthAvailEa / iotbImgSize.width;
    const iotbSize = scaleSize(iotbImgSize, iotbScale);

    const iotbBtmMrg = 2;
    const iotbTop = ioBaseSize.height - iotbBtmMrg - iotbSize.height;

    // Our SA power jumper is scaled using the tb scale.
    const pwrJumperImgSize = { width: 80, height: 270 };
    const pwrJumperSize = scaleSize(pwrJumperImgSize, iotbScale);

    const psuTop = 240;
    const psu1LeftX = 0;
    const psuSep = 30;
    const psuToBP = 30;

    const bpYOffset = 210;
    const bpSideExt = 28;
    const bpBtmExt = 71

    _scaledSizeInfo = {
        baseScale: 1.0,
        adapterKitLeft: psu1LeftX + (2 * psuSize.width) +
            psuSep + psuToBP + bpSideExt,
        sizeAdapterKit: { width: 995, height: baseHeight },
        bpYOffset: bpYOffset,
        bpSideExt: bpSideExt,
        bpBtmExt: bpBtmExt,
        bpHeight: baseHeight + bpBtmExt - bpYOffset,
        ioBaseInfo: {
            baseSize: { ...ioBaseSize },
            orgSlots: { x: slotSideMrg, y: 59 },
            slotSize: slotSize,
            orgIOTBs: { x: iotbSideMrg, y: iotbTop },
            iotbSize: iotbSize
        },
        sizePwrJumper: pwrJumperSize,
        psuLoc1: {
            x: psu1LeftX,
            y: psuTop,
            width: psuSize.width,
            height: psuSize.height
        },
        psuLoc2: {
            x: psu1LeftX + psuSize.width + psuSep,
            y: psuTop,
            width: psuSize.width,
            height: psuSize.height
        },
        psuCables: new Array<CableSpec>()
    };

    _makePSCableSpecs(_scaledSizeInfo);
}

export const getFHASizeDetails = (): FlxHASizeDetails => {
    if (!_scaledSizeInfo) {
        _establishSizeDtls();
    }
    if (_scaledSizeInfo) {
        return _scaledSizeInfo;
    }
    throw new Error('Unexpected ERROR in getFHASizeDetail!');
}

const _isOdd = (num: number): boolean => {
    return (num % 2) ? true : false;
}

const _isDuplexPrimary = (chassis: Chassis, slot: number): boolean => {
    if (_isOdd(slot)) {
        const mod = chassis.modules[slot];
        if (mod && mod.slotsUsed === 2) {
            return true;
        }
    }
    return false;
}

const _addLocs = (
    layout: FlexHALayoutInfo,
    info: FlxHASizeDetailsIO,
    nextSlotOrg: Point,
    nextTBOrg: Point,
    duplex: boolean
) => {
    const widthMult = duplex ? 2 : 1;
    const slotWidth = info.slotSize.width * widthMult;
    layout.slotLocs.push({
        x: nextSlotOrg.x,
        y: nextSlotOrg.y,
        width: slotWidth,
        height: info.slotSize.height
    });
    nextSlotOrg.x += slotWidth;

    const iotbWidth = info.iotbSize.width * widthMult;
    layout.ioTBLocs.push({
        x: nextTBOrg.x,
        y: nextTBOrg.y,
        width: iotbWidth,
        height: info.iotbSize.height
    });
    nextTBOrg.x += iotbWidth;

    if (duplex) {
        layout.slotLocs.push(getEmptyLoc());
        layout.ioTBLocs.push(getEmptyLoc());
    }
}

const _addLocsForIOBase = (
    chassis: Chassis,
    ioBaseNum: number,
    baseLeft: number,
    info: FlxHASizeDetailsIO) => {

    // Get the FlexHA version of our chassis layout.
    const layout = chassis.layout as FlexHALayoutInfo;

    // Add the loc for the base itself.
    layout.ioBaseLocs.push({
        x: baseLeft,
        y: 0,
        width: info.baseSize.width,
        height: info.baseSize.height
    });

    // Calculate the idx's of the first and last
    // chassis slot used by this I/O base unit.
    const firstSlotIdx = ioBaseNum * 4 + 1;
    const lastSlotIdx = firstSlotIdx + 3;

    // Start origin (upper-left) points for both
    // our slots and our I/O terminal blocks.
    const slotOrg = { ...info.orgSlots };
    slotOrg.x += baseLeft;

    const iotbOrg = { ...info.orgIOTBs };
    iotbOrg.x += baseLeft;

    // For each idx our base covers...
    for (let slotIdx = firstSlotIdx; slotIdx <= lastSlotIdx; slotIdx++) {

        // Determine if the module (if any) in the slot is
        // a duplex module. We only care if the slot is the
        // leftmost slot occupied by the module in question.
        const duplexMod = _isDuplexPrimary(chassis, slotIdx);

        // Call a helper to add associated locs for slot
        // and I/O terminal blocks. Note, the helper advances
        // our 'org' arguments each time it's called.
        _addLocs(layout, info, slotOrg, iotbOrg, duplexMod);

        // Duplex modules 'consume' two slots. If we have one,
        // advance our slot idx past the duplex mod's right slot.
        if (duplexMod) {
            slotIdx++;
        }
    }
}


export const flexHALayoutChassis = (chassis: Chassis) => {
    const sizeDtls = getFHASizeDetails();

    const numIOBases = getNumIOBases(chassis);

    const layout = chassis.layout as FlexHALayoutInfo;

    layout.slotLocs = new Array<LocAndSize>();
    layout.ioBaseLocs = new Array<LocAndSize>();
    layout.ioTBLocs = new Array<LocAndSize>();

    let baseLeftX = sizeDtls.adapterKitLeft;

    // The FIRST slot loc will ALWAYS be used
    // for the adapter base (slot 0).
    const locAdapter: LocAndSize = {
        x: baseLeftX,
        y: 0,
        width: sizeDtls.sizeAdapterKit.width,
        height: sizeDtls.sizeAdapterKit.height,
    };

    layout.slotLocs.push(locAdapter);

    // Our I/O TB locs line up with the slots they're
    // used by. The adapter doesn't HAVE an I/O TB, so
    // we'll add an empty (unused) one at that position.
    layout.ioTBLocs.push(getEmptyLoc());

    // Calculate the location details for the backplate.
    layout.backplateLoc.x = baseLeftX - sizeDtls.bpSideExt;
    layout.backplateLoc.y = sizeDtls.bpYOffset;
    layout.backplateLoc.width = sizeDtls.sizeAdapterKit.width +
        (numIOBases * sizeDtls.ioBaseInfo.baseSize.width) +
        (2 * sizeDtls.bpSideExt);
    layout.backplateLoc.height = sizeDtls.bpHeight;

    // Base left gets moved to right side of adapter
    // before we add locations that fall in I/O bases.
    baseLeftX += locAdapter.width;

    // For each I/O base we have...
    for (let ioBaseNum = 0; ioBaseNum < numIOBases; ioBaseNum++) {

        // Add the associated locs (for slots and tbs).
        _addLocsForIOBase(chassis, ioBaseNum, baseLeftX, sizeDtls.ioBaseInfo);

        // Then advance base left for the next one.
        baseLeftX += sizeDtls.ioBaseInfo.baseSize.width;
    }

    // Finalize the overall size of our chassis.
    // Note: Our width is everything from the start of the
    // reserved PSU area over to (and including) the backplate
    // extension on the right side.
    layout.size.width = baseLeftX + sizeDtls.bpSideExt;

    // Our height includes everything from the top of
    // our base units down to (and including) the backplate
    // extension below.
    layout.size.height = sizeDtls.bpYOffset + sizeDtls.bpHeight;
}

// TO DO
// TEMPORARY IMPLEMENTATION
const _flexHAGetPoweredIOBaseIdxs = (chassis: Chassis): number[] => {

    const idxs = new Array<number>();

    const numBases = getNumIOBases(chassis);
    if (numBases > 0) {
        idxs.push(0);
    }

    return idxs;
}

// TO DO
// TEMP. Will need modification after Nick's new FlexHA 
// Chassis extension interface gets in.
export const flexHAGetSAPwrLocInfo = (chassis: Chassis):
    [saLocs: LocAndSize[], jumperLocs: LocAndSize[], tbCoverLocs: LocAndSize[]] => {

    const saLocs = new Array<LocAndSize>();
    const jumperLocs = new Array<LocAndSize>();
    const tbCoverLocs = new Array<LocAndSize>();

    const saPoweredBases = _flexHAGetPoweredIOBaseIdxs(chassis);
    if (saPoweredBases.length > 0) {
        const sizeDtls = getFHASizeDetails();
        const topLoc = sizeDtls.ioBaseInfo.baseSize.height - sizeDtls.sizePwrJumper.height;
        const pwrTBWidth = sizeDtls.sizePwrJumper.width / 2;
        const locHeight = sizeDtls.sizePwrJumper.height;

        const layout = chassis.layout as FlexHALayoutInfo;
        let baseLoc = getEmptyLoc();
        for (let baseIdx = 0; baseIdx < layout.ioBaseLocs.length; baseIdx++) {
            baseLoc = layout.ioBaseLocs[baseIdx];

            if (saPoweredBases.includes(baseIdx)) {
                saLocs.push({
                    x: baseLoc.x,
                    y: topLoc,
                    width: pwrTBWidth,
                    height: locHeight
                });

                if (baseIdx !== 0) {
                    tbCoverLocs.push({
                        x: baseLoc.x - pwrTBWidth,
                        y: topLoc,
                        width: pwrTBWidth,
                        height: locHeight
                    });
                }
            }
            else {
                jumperLocs.push({
                    x: baseLoc.x - pwrTBWidth,
                    y: topLoc,
                    width: pwrTBWidth * 2,
                    height: locHeight
                });
            }
        }

        tbCoverLocs.push({
            x: baseLoc.x + baseLoc.width - pwrTBWidth,
            y: topLoc,
            width: pwrTBWidth,
            height: locHeight
        });
    }

    return [saLocs, jumperLocs, tbCoverLocs];
}

//export const flexHAGetSAPwrJumperLocs = (layout: FlexHALayoutInfo): LocAndSize[] => {

//    const sizeDtls = getFHASizeDetails();
//    const topJmpr = sizeDtls.ioBaseInfo.baseSize.height - sizeDtls.sizePwrJumper.height;
//    const jmprWidth = sizeDtls.sizePwrJumper.width;
//    const halfJmpr = jmprWidth / 2;

//    const locs = new Array<LocAndSize>();

//    for (let baseIdx = 1; baseIdx < layout.ioBaseLocs.length; baseIdx++) {
//        const baseLoc = layout.ioBaseLocs[baseIdx];
//        locs.push({
//            x: baseLoc.x - halfJmpr,
//            y: topJmpr,
//            width: jmprWidth,
//            height: sizeDtls.sizePwrJumper.height
//        });
//    }

//    return locs;
//}


const _redCableColor = 'red';
const _blueCableColor = 'blue';
const _cableWidth = 8;  // strokeWidth

const _makePSCableSpecs = (sizeDtls: FlxHASizeDetails) => {

    // Empty any existing content.
    sizeDtls.psuCables.length = 0;

    // Calculate some common y ordinates.
    const btmPSU = sizeDtls.psuLoc1.y + sizeDtls.psuLoc1.height;
    const btmAdptr = sizeDtls.sizeAdapterKit.height;
    const btmBackplate = sizeDtls.bpYOffset + sizeDtls.bpHeight;

    // Cables drop out of PSU's at third
    // points across their widths.
    const psuThird = sizeDtls.psuLoc1.width / 3;

    // We have 4 cables, 1 red and 1 blue for each
    // PSU. They drop from the PSU, cross over to
    // the right, then 'rise' to touch the bottom
    // of the adapter base at specific pts.
    const riseR1X = sizeDtls.adapterKitLeft + 361;
    const riseB1X = sizeDtls.adapterKitLeft + 177;
    const riseR2X = sizeDtls.adapterKitLeft + 54;
    const riseB2X = sizeDtls.adapterKitLeft + 136;

    // Decent looking vertical distance
    // between crossing cables.
    const crossVertSep = 20;

    // Start the crossing y ord to include and extra
    // separation between the cable and btm of backplate.
    let crossY = btmBackplate + (4 * crossVertSep) + 4;

    // Set the smallest cable corner radius we want.
    const minRad = 22;

    // And how much the radius should change
    // from cable to cable.
    const radShrink = 16;

    // Use that info to set our starting radius.
    let radius = minRad + (3 * radShrink);

    // First cable drops from left of first PSU.
    let dropX = sizeDtls.psuLoc1.x + psuThird;

    // Set up that RED cable.
    const p1Red = makeEmptyCableSpec(_redCableColor, _cableWidth, radius);
    p1Red.pathPts.push({ x: dropX, y: btmPSU });
    p1Red.pathPts.push({ x: dropX, y: crossY });
    p1Red.pathPts.push({ x: riseR1X, y: crossY });
    p1Red.pathPts.push({ x: riseR1X, y: btmAdptr });
    sizeDtls.psuCables.push(p1Red);

    // Adjust as we go.
    dropX += psuThird;
    crossY -= crossVertSep;
    radius -= radShrink;

    // BLUE cable for first PSU.
    const p1Blue = makeEmptyCableSpec(_blueCableColor, _cableWidth, radius);
    p1Blue.pathPts.push({ x: dropX, y: btmPSU });
    p1Blue.pathPts.push({ x: dropX, y: crossY });
    p1Blue.pathPts.push({ x: riseB1X, y: crossY });
    p1Blue.pathPts.push({ x: riseB1X, y: btmAdptr });
    sizeDtls.psuCables.push(p1Blue);

    dropX = sizeDtls.psuLoc2.x + psuThird;
    crossY -= crossVertSep;
    radius -= radShrink;

    // RED cable for second PSU.
    const p2Red = makeEmptyCableSpec(_redCableColor, _cableWidth, radius);
    p2Red.pathPts.push({ x: dropX, y: btmPSU });
    p2Red.pathPts.push({ x: dropX, y: crossY });
    p2Red.pathPts.push({ x: riseR2X, y: crossY });
    p2Red.pathPts.push({ x: riseR2X, y: btmAdptr });
    sizeDtls.psuCables.push(p2Red);

    dropX += psuThird;
    crossY -= crossVertSep;
    radius -= radShrink;

    // BLUE cable for second PSU.
    const p2Blue = makeEmptyCableSpec(_blueCableColor, _cableWidth, radius);
    p2Blue.pathPts.push({ x: dropX, y: btmPSU });
    p2Blue.pathPts.push({ x: dropX, y: crossY });
    p2Blue.pathPts.push({ x: riseB2X, y: crossY });
    p2Blue.pathPts.push({ x: riseB2X, y: btmAdptr });
    sizeDtls.psuCables.push(p2Blue);
}