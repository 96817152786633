import React, { useCallback, useEffect, useRef, useState } from 'react';
import './PointEntry.scss'
import { IOEntryModeEnum } from '../types/SettingsTypes';
//import * as IOModule from '../model/IOModule';
import {
	Autocomplete,
	Checkbox,
	FormControl,
	IconButton,
	Select,
	SelectChangeEvent,
	MenuItem,
	TextField,
	Popper,PopperProps
} from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
	overrideShowDeleteButton,
	overrideShowModuleCount,
	overrideShowModuleSelections,
	overrideShowConsolidateModuleSelections
} from './PointEntrySection';
import JoinInnerOutlinedIcon from '@mui/icons-material/JoinInnerOutlined';
import JoinFullOutlinedIcon from '@mui/icons-material/JoinFullOutlined';
import {
	hdrChkFlexValue,
	hdrTxtCatFlexValue,
	hdrTxtQtyFlexValue,
	hdrTxtTypeFilterFlexValue,
	hdrTxtTypeFlexValue,
	hdrTxtTypeFlexValueBasic
} from './PointEntryHeader';
import { txtRADB_NA } from '../types/APITypes';
import { displayAlertMsg } from '../util/MessageHelp';
import { StatusLevel } from '../types/MessageTypes';
import { PointEntryMaxVal } from '../types/Globals';
import { getLocationSettings } from '../model/ChassisProject';
import { ChassisProject } from '../types/ProjectTypes';
import { IOBitNameToValue } from '../types/IOModuleTypes';
import {
	ControlVoltageMask,
	IOFilterMasks,
	MainPointTypeMask,
	PointTypeFilterMask
} from '../types/IOModuleTypes';
import {
	getIOFilterMasksFromLocationAttr,
	getIOFilterMasksFromProject,
	getIOPointFilterNameToValMap,
	getPointTypeDisplayTitle
} from '../model/IOModule';
import { PointEntryInfo } from '../types/IOPointEntryTypes';
import { EngInfoIOModule } from '../engData/EngineeringInfo';
import { getIOModTypes } from '../model/EngineeringData';
import { getDefaultIOModuleCatalog, findClosestIOModMatch, getIOPointFilterMap, queryIOModules } from '../implementation/ImplHardwareGen';
const sxPropsPointEntry = {
	fontFamily: 'Barlow',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '24px'
}

const sxPropsPointEntryIOType = {
	fontFamily: 'Barlow',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '20px'
}

interface PointTypeSelection {
	typeID: string;
	display: string;
}

const ioTypeToTypeFilterEntries = new Map< string, Map < number, IOBitNameToValue[]>>();
const getIOFilterSelections = (
	platform: string,
	IOTypeID: number,
	optionalCallback: undefined | (() => void) = undefined)
	: IOBitNameToValue[] => {

	let result: IOBitNameToValue[] = [{ name: 'Any', value: 0 }];
	// Do we have the platform map
	const currentPlatformMap = ioTypeToTypeFilterEntries.get(platform);
	const platformMap = (currentPlatformMap ? currentPlatformMap : new Map<number, IOBitNameToValue[]>);
	if (currentPlatformMap == null ) {
		// create the inner map for the platform and add the entry
		ioTypeToTypeFilterEntries.set(platform, platformMap);

		const specMap = getIOPointFilterMap(platform);
		specMap.forEach((specMask, typeID) => {
			const entries = getIOPointFilterNameToValMap(specMask, true, false);
			platformMap.set(typeID, entries);
			if (typeID === IOTypeID)
				result = entries;
		});
	}
	else {
		const filterOptions = currentPlatformMap.get(IOTypeID);
		if (filterOptions)
			result = filterOptions;
	}

	if (optionalCallback)
		optionalCallback();

	return result;
}

interface Props {
	project: ChassisProject;
	entry: PointEntryInfo;
	groupEntries: number;
}

export const PointEntry = (props: Props) => {

	const [refresh, setRefresh] = useState(0);
	const inputPointsControl = useRef<HTMLInputElement>();
	const projectFilters = useRef<IOFilterMasks>(getIOFilterMasksFromProject(props.project));
	const arrPointTypes = useRef<PointTypeSelection[]>([]);
	const arrPointTypeFilters = useRef<IOBitNameToValue[]>([]);
	const arrPointTypesLoaded = useRef(false);
	const currentEntry = useRef<PointEntryInfo>(props.entry);

	useEffect(() => {
		projectFilters.current = getIOFilterMasksFromProject(props.project);

		let refreshEntry = false;
		// Get our valid I/O point types. Clear the array then fill.
		if (arrPointTypes.current.length === 0) {
			const arrTypes = getIOModTypes(props.entry.platform);
			if (arrTypes.length === 0) {
				arrPointTypes.current.push({ typeID: '0', display: txtRADB_NA });
				props.entry.invalidEntry = true;
			}
			else {
				arrTypes.forEach((type) => {
					arrPointTypes.current.push({ typeID: type.toString(), display: getPointTypeDisplayTitle(type, false, false) });
				});
			}

			// Note: this flag added to cleanup the console
			// warnings where the point type value is set to
			// a non-existent option until types are loaded.
			arrPointTypesLoaded.current = true;

			refreshEntry = true;
		}

		// Get our valid I/O point FILTER types. 
		if (arrPointTypeFilters.current.length === 0 || currentEntry.current !== props.entry) {
			arrPointTypeFilters.current = getIOFilterSelections(props.entry.platform, props.entry.typeID);
			refreshEntry = true;
		}


		if (refreshEntry)
			setRefresh(refresh + 1);

	}, [props, refresh]);


	// All hooks above here^

	//const locAttrInfo = project.config.locationSettings;
	const locAttrInfo = getLocationSettings(props.project);

	const updateEntryDefaultStatus = () => {
		// Should only be called is the catalog number has changed!
		if (props.entry.isAdvModDefault) {
			props.entry.isAdvModDefault = false;
		}
		else{
			props.entry.isAdvModDefault =
				(props.entry.advancedModule ===
					getDefaultIOModuleCatalog(props.entry.typeID, locAttrInfo));
		}
	}

	const OnCmbModuleSelChanged = (event: unknown, newValue: string | null) => {
		if (newValue != null) {
			const idx = props.entry.moduleDisplayStrs.indexOf(newValue);
			// Validate and range check the index.
			if (idx != null && idx >= 0 && idx <= props.entry.moduleCatalogs.length) {
				const newModule = props.entry.moduleCatalogs[idx];
				if (props.entry.advancedModule !== newModule) {
					props.entry.advancedModule = newModule;
					updateEntryDefaultStatus();
					setRefresh(refresh + 1);
					props.entry.OnChanged();
				}
			}
		}
		const changeIOCatNoClick = new CustomEvent("designChangeIOCatNo", {
			detail: {
			action: "Change IO Catalogue Number",
			properties: {
				category: "WebApp",
				label: "Change IO Catalogue Number",
			},
			},
		});
		document.getElementById("root")?.dispatchEvent(changeIOCatNoClick);
	}

	const resolvePointTypeFilter = (includeMask: number, excludeMask: number, entry: PointEntryInfo, defModCatalog: string = ''): boolean => {
		let resolved = false;

		// If a default catalog is passed in, we have a Point Type change and
		// the default will take precedence over the entry's ADV catalog.
		const pointTypeChange = (defModCatalog.length > 4);
		const currentCatalog = (defModCatalog ? defModCatalog : entry.advancedModule);

		// Define some default vars.
		let arrModInfo: EngInfoIOModule[] = [];

		//let newEntryModInfo: IOModInformation = { catalog: '', displayString: '', IOMask: 0, points: { input: 0, output: 0, selfCfg: 0 } };
		let newEntryModInfo: EngInfoIOModule | undefined = undefined;

		let entryIncludeFilter = includeMask;

		// If we have a current catalog (and we should)...
		if (currentCatalog && currentCatalog.length > 4) {
			// Start with the current filters...
			let newCataloginfo = findClosestIOModMatch(props.entry.platform,
				currentCatalog, includeMask, excludeMask, arrModInfo);
			// If we failed...
			if (arrModInfo.length === 0) {
				// Strip the voltage restriction.
				const ctrlVoltage = (includeMask & ControlVoltageMask)
				let newIncludeMask = (includeMask & ~ctrlVoltage);
				newCataloginfo = findClosestIOModMatch(props.entry.platform,
					currentCatalog, newIncludeMask, excludeMask, arrModInfo);
				// If we failed...
				if (pointTypeChange && arrModInfo.length === 0 && (newIncludeMask & PointTypeFilterMask) != 0) {
					// Strip the filter bit(s).
					newIncludeMask = (newIncludeMask & ~PointTypeFilterMask);
					newCataloginfo = findClosestIOModMatch(props.entry.platform,
						currentCatalog, newIncludeMask, excludeMask, arrModInfo);
				}

				entryIncludeFilter = newIncludeMask;
			}

			// Clean out Project Masks.
			entryIncludeFilter = (entryIncludeFilter & (MainPointTypeMask | PointTypeFilterMask));
			if (newCataloginfo != null && arrModInfo.length > 0) {
				newEntryModInfo = newCataloginfo;
				resolved = true;
			}
		}
		else {
			arrModInfo = queryIOModules(props.entry.platform, includeMask, excludeMask);
			if (arrModInfo.length === 0) {
				// Try removing the Control Voltage bits.
				let newIncludeMask = (includeMask & ~ControlVoltageMask);
				arrModInfo = queryIOModules(props.entry.platform, newIncludeMask, excludeMask);
				// If we still do NOT have any modules and we have filter bits
				if (pointTypeChange && arrModInfo.length === 0 && (newIncludeMask & PointTypeFilterMask) != 0) {
					newIncludeMask = (newIncludeMask & ~PointTypeFilterMask);
					arrModInfo = queryIOModules(props.entry.platform, newIncludeMask, excludeMask);
				}

				entryIncludeFilter = newIncludeMask;
			}

			entryIncludeFilter = (entryIncludeFilter & (MainPointTypeMask | PointTypeFilterMask));
			if (arrModInfo.length > 0 && arrModInfo[0] != null) {
				let idxMod = 0;
				arrModInfo.some((info, index) => {
					if (info.catNo === entry.basicModule) {
						idxMod = index;
						return true;
					}
				});

				newEntryModInfo = arrModInfo[idxMod];
				resolved = true;
			}
		}

		if (resolved) {
			entry.advancedModule = newEntryModInfo ? newEntryModInfo.catNo : '';
			entry.filterInclude = entryIncludeFilter;
			entry.moduleCatalogs = arrModInfo.map((info) => { return info.catNo; });
			entry.moduleDisplayStrs = arrModInfo.map((info) => { return info.displayString; });
			entry.isAdvModDefault = (entry.advancedModule === entry.basicModule);
		}

		return resolved;
	}

	const handlePointTypeChange = (event: SelectChangeEvent) => {
		let newType = (arrPointTypes.current.find(x => x.typeID === event.target.value));
		// Should not happen, but cover it.
		if (newType == null) {
			newType = (arrPointTypes.current.length > 0 ? arrPointTypes.current[0] : { typeID: '0', display: txtRADB_NA });
		}

		if (newType.typeID === '0' && props.entry.invalidEntry) {
			// If the entry type is invalid AND it WAS invalid, we're done.
			return;
		}
		else if (newType.typeID === props.entry.typeID.toString()) {
			return;
		}

		const newTypeID = Number(newType.typeID);
		const oldFilterBit = props.entry.filterInclude & PointTypeFilterMask;

		// We know we have a valid change... Clear the invalid
		// flag and get the default catalog.
		props.entry.invalidEntry = false;
		props.entry.typeID = newTypeID;
		props.entry.filterInclude = newTypeID;
		props.entry.filterExclude = 0;

		const defCatalog = getDefaultIOModuleCatalog(props.entry.typeID, locAttrInfo);
		if (defCatalog == null) {
			props.entry.invalidEntry = true;
			props.entry.OnChanged();
			return;
		}

		// The basic entry will always be the default.
		props.entry.basicModule = defCatalog;

		// Get the project filters (i.e EnvRating/CtrlVoltage)
		const projFilters = getIOFilterMasksFromLocationAttr(locAttrInfo);

		arrPointTypeFilters.current = getIOFilterSelections(props.entry.platform, props.entry.typeID);
		if (oldFilterBit !== 0 && arrPointTypeFilters.current.some(x => x.value === oldFilterBit)) {
			// Call a helper to resolve the current FILTERED selection.
			// Note: by passing in the Default Catalog, we are telling
			// the function it's being called from the Point Type Change
			// as opposed to a Filter Change.
			const qryInclude = (props.entry.typeID | oldFilterBit | projFilters.includeMask);
			if (resolvePointTypeFilter(qryInclude, projFilters.excludeMask, props.entry, defCatalog)) {
				// The entry was resolved and we are done.
				props.entry.OnChanged();
				return;
			}
		}

		// Set the default in the entry.
		props.entry.advancedModule = defCatalog;
		const includeFilters = props.entry.typeID | projFilters.includeMask;
		const excludeMask = projFilters.excludeMask;
		const arrModInfo = queryIOModules(props.entry.platform, includeFilters, excludeMask);

		props.entry.moduleCatalogs = arrModInfo.map((info) => { return info.catNo; });
		props.entry.moduleDisplayStrs = arrModInfo.map((info) => { return info.displayString; });

		props.entry.OnChanged();

		const changeIOTypeClick = new CustomEvent("designChangeIOType", {
			detail: {
			action: "Change IO Type",
			properties: {
				category: "WebApp",
				label: "Change IO Type",
			},
			},
		});
		document.getElementById("root")?.dispatchEvent(changeIOTypeClick);
	};

	
	const handlePointTypeFilterChange = (event: SelectChangeEvent) => {
		// NOTE: This filter will ONLY apply to ADVANCED
		// ENTRY MODE. Basic mode should not get us here.
		if (advancedEntry === false)
			return;

		const filterbit = Number(event.target.value);
		if (isNaN(filterbit)) {
			// Call the on changed even though we
			// did not change the filter (meaning
			// the last value will still be there).
			props.entry.OnChanged();
			return;
		}

		// Is the filter bit is set in the entry already...
		if ((props.entry.filterInclude & filterbit) !== 0)
			return;

		// Also if we have the 'any' bit, which is 0, and
		// the include filter does not have any filter bits
		// set (i.e. the include filter is just DI/DO/AI/AO)...
		if (filterbit === 0 && props.entry.filterInclude === props.entry.typeID)
			return;

		// Cleanup the entry's filters (include/exclude). Note:
		// At this time we are not using the exclude filter
		// but we may in the future to exclude something.
		const newEntryFilter = props.entry.typeID | filterbit;
		props.entry.filterExclude = 0;
		props.entry.invalidEntry = false;

		// Get the PROJECT filters, which are for Environmental
		// Rating and Control Voltage values.
		const projFilters = getIOFilterMasksFromLocationAttr(locAttrInfo);
		const qryInclude = newEntryFilter | projFilters.includeMask;
		const qryExclude = props.entry.filterExclude | projFilters.excludeMask;

		const currentCatalog = (
				props.entry.advancedModule ?
				props.entry.advancedModule :
				getDefaultIOModuleCatalog(props.entry.typeID, locAttrInfo)
		);

		if (currentCatalog == null) {
			props.entry.OnChanged();
			return;
		}

		if (resolvePointTypeFilter(qryInclude, qryExclude, props.entry) === false) {
			const msg = 'No compatible I/O Modules could be found for the current system configuration.';
			displayAlertMsg(msg, StatusLevel.Warning, 'Filter Not Valid')
		}

		props.entry.OnChanged();

		const changeIOFunctionClick = new CustomEvent("designChangeIOFunction", {
			detail: {
			action: "Change IO Function",
			properties: {
				category: "WebApp",
				label: "Change IO Function",
			},
			},
		});
		document.getElementById("root")?.dispatchEvent(changeIOFunctionClick);
	};


	const OnQuantityChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (event != null) {
			const val = Math.round(Number(event.target.value));
			if (val !== props.entry.points) {
				// If the value is valid...
				if (val >= 0)
					props.entry.points = val;
				// Regardless, refresh the entry. If the value
				// was invalid, wipe it out.
				setRefresh(refresh + 1);
				props.entry.OnChanged();
				const enterIOPointClick = new CustomEvent("designEnterIOPoint", {
					detail: {
					action: "Enter IO Point",
					properties: {
						category: "WebApp",
						label: "Enter IO Point",
					},
					},
				});
				document.getElementById("root")?.dispatchEvent(enterIOPointClick);
			}
		}
	}

	const getDisplayStringFromSelectedCatalog = useCallback((): string => {
		if (props.entry.advancedModule != null) {
			const idx = props.entry.moduleCatalogs.findIndex((x) => x === props.entry.advancedModule);
			if (idx != null && idx < props.entry.moduleDisplayStrs.length)
				return props.entry.moduleDisplayStrs[idx];
		}

		return (props.entry.moduleDisplayStrs.length > 0 ? props.entry.moduleDisplayStrs[0] : '');
	}, [props]);

	const CustomPopper = useCallback((props: PopperProps) => {
		return (
		<Popper {...props} placement="auto-end">
			{props.children}
		</Popper>
		);
	}, []);


	const onDelete = () => {
		if (props.entry.onDelete != null && props.entry.indexEntry !== 0) {
			props.entry.onDelete(props.entry.indexEntry);
		}
	}

	const onConsolidate = () => {
		props.entry.consolidateModule = !props.entry.consolidateModule;
		props.entry.OnChanged();
	}

	const showDelete = (overrideShowDeleteButton || props.project.config.IOEntryMode === IOEntryModeEnum.Advanced);
	const showModuleCount = (overrideShowModuleCount);
	const advancedEntry = (props.project.config.IOEntryMode === IOEntryModeEnum.Advanced);
	let showAdvancedModeCtrls = (overrideShowModuleSelections || props.project.config.IOEntryMode === IOEntryModeEnum.Advanced);
	const showConsolidateModuleSelections = overrideShowConsolidateModuleSelections;

	// Note: this should not happen...
	if (props.entry.moduleCatalogs.length == 0) {
		return (
			<div className="point-entry-no-compatible-modules-note">** No I/O Modules are compatible with the current platform/settings.</div>
		);
	}

	const validateQuantity: React.FormEventHandler<HTMLInputElement> = (e) => {
		const target = e.target as HTMLInputElement;
		if (target) {
			if (target.valueAsNumber > PointEntryMaxVal) {
				target.value = props.entry.points.toString();
			}
			else {
				target.value = target.valueAsNumber.toString();
			}
		}
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		switch (event.key) {
			case 'Enter':
			case 'Escape':
				inputPointsControl.current?.blur();
				break;

			default:
				break;
		}
	}

	const selectedID = `selected-${props.entry.indexEntry}${props.entry.filterInclude}`;
	const pointCountID = `point-count-${props.entry.indexEntry}${props.entry.filterInclude}`;
	const modSelComboID = `mod-sel-combo-${props.entry.indexEntry}${props.entry.filterInclude}`;
	const modSelTextID = `mod-sel-text-${props.entry.indexEntry}${props.entry.filterInclude}`;
	const pointTypeTextID = `point-type-${props.entry.indexEntry}${props.entry.filterInclude}`;
	const pointTypeFilterTextID = `point-typefilter--${props.entry.indexEntry}${props.entry.filterInclude}`;

	let selected = (advancedEntry ? props.entry.advancedModule : props.entry.basicModule);
	if (showAdvancedModeCtrls) {
		const index = props.entry.moduleCatalogs.findIndex((val) => val === selected);
		if (index >= 0 && index < props.entry.moduleDisplayStrs.length) {
			selected = props.entry.moduleDisplayStrs[index];
		}
		else {
			showAdvancedModeCtrls = false;
		}
	}

	const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		props.entry.selected = checked;
		setRefresh(refresh + 1);
		props.entry.OnChanged();
		const selectIndivIORowsClick = new CustomEvent("designSelectIndivIORow", {
			detail: {
				action: "Select Individual IO Row",
				properties: {
					category: "WebApp",
					label: "Select Individual IO Row",
				},
			},
		});
		document.getElementById("root")?.dispatchEvent(selectIndivIORowsClick);
	}


	const render_PointQuantity = () => {
		return (
			<TextField
				inputRef={inputPointsControl}
				id={pointCountID}
				variant="standard"
				label=""
				disabled={props.entry.invalidEntry}
				error={props.entry.invalidEntry}
				type="number"
				value={props.entry.points}
				className="point-entry-hdr-pt"
				sx={{
					flex: hdrTxtQtyFlexValue,
					"& .MuiInputBase-input.Mui-disabled": {
						WebkitTextFillColor: "black",
					},
				}}
				onKeyDown={handleKeyDown}
				onChange={OnQuantityChanged}
				onFocus={event => { event.target.select(); }}
				onInput={validateQuantity}
				InputProps={{ style: { ...sxPropsPointEntry } }}
			/>
		);
	}


	const render_PointType = () => {
		return (
			<div className={showAdvancedModeCtrls ? "point-entry-type" : "point-entry-type-basic"} >
				<FormControl
					variant="standard"
					className="point-entry-hdr-pt-type"
					disabled={props.entry.invalidEntry}
					error={props.entry.invalidEntry}
					sx={{
						flex: showAdvancedModeCtrls ? hdrTxtTypeFlexValue : hdrTxtTypeFlexValueBasic,
						width: '100%',
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "black",
						},
					}}
				>
					<Select
						disabled={props.entry.invalidEntry}
						variant="standard"
						id={pointTypeTextID}
						value={arrPointTypesLoaded.current ? props.entry.typeID.toString() : ''}
						onChange={handlePointTypeChange}
						label=""
						sx={{ ...sxPropsPointEntryIOType }}
					>
						{
							arrPointTypes.current.map((type) => {
								return (
									<MenuItem
										value={type.typeID}
										key={type.typeID}
										style={{ ...sxPropsPointEntry }}
									>
										{type.display}
									</MenuItem>);
							})
						}
					</Select>

				</FormControl>
			</div>
		);
	}


	const render_PointTypeFilter = () => {
		return (
			<div className="point-entry-type-filter">
				<FormControl
					variant="standard"
					className="point-entry-hdr-pt-type"
					disabled={props.entry.invalidEntry}
					error={props.entry.invalidEntry}
					sx={{
						width: '100%',
						flex: hdrTxtTypeFilterFlexValue,
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "black",
						},
					}}
				>
					<Select
						disabled={props.entry.invalidEntry}
						variant="standard"
						id={pointTypeFilterTextID}
						value={arrPointTypeFilters.current.length > 0 ? (props.entry.filterInclude & PointTypeFilterMask).toString() : '0'}
						onChange={handlePointTypeFilterChange}
						label=""
						sx={{ ...sxPropsPointEntryIOType }}
					>
						{
							arrPointTypeFilters.current.map((type) => {
								return (<MenuItem
									value={type.value}
									key={type.name}
									style={{ ...sxPropsPointEntry }}
								>
									{type.name}
								</MenuItem>);
							})
						}
					</Select>

				</FormControl>
			</div>
		);
	}


	const render_ModuleSelections = () => {
		// 2023.9.28 Added renderOption to size the text
		// in the combobox list items.
		return (
			<div className="point-entry-modules">
				< Autocomplete
					size='medium'
					disablePortal
					id={modSelComboID}
					options={props.entry.moduleDisplayStrs}
					defaultValue={props.entry.moduleDisplayStrs[0]}
					value={getDisplayStringFromSelectedCatalog()}
					onChange={OnCmbModuleSelChanged}
					disableClearable
					role="listbox"
					sx={{ minWidth: 0 }}
					PopperComponent={CustomPopper}
					renderOption={(props, option: string) => (
						<li {...props} style={{ ...sxPropsPointEntry }}>{option}</li>
					)}
					renderInput={(params) =>
						<TextField
							{...params}
							variant="standard"
							id={modSelTextID}
							label=""
							margin="none"
							className="point-entry-hdr-catalog"
							sx={{
								flex: hdrTxtCatFlexValue,
								minWidth: 0,
								"& .MuiInputBase-input.Mui-disabled": {
									WebkitTextFillColor: "black",
								}
							}}
							InputProps={{ ...params.InputProps, style: { ...sxPropsPointEntry } }}
						/>}
				/>
			</div>
		);
	}

	//// MAIN COMPONENT RENDER /////////////////
	return (
		<div className="point-entry-container">
			<div style={{ height: '27px' }} >
			<Checkbox
				id={selectedID}
				checked={props.entry.selected}
				onChange={onCheckChanged}
				disabled={props.entry.invalidEntry}
				sx={{ flex: hdrChkFlexValue, marginTop: '-8px', marginRight:'-15px' }}
				/>
				</div>
			{
				render_PointQuantity()
			}

			{
				render_PointType()
			}
			{
				props.entry.invalidEntry ?
					<div className="point-entry-invalid"> * There are no I/O modules that support your requirements.</div>
					:
					<div id="comment: ADVANCED POINT TYPE ENTRY CONTROLS" className="point-entry-advanced-control-container">
						{
							showAdvancedModeCtrls ?
								render_PointTypeFilter()
								:
								null
						}

						{
							showAdvancedModeCtrls ?
								render_ModuleSelections()
								:
								null
						}

						{
							showDelete ?
								<div className="point-entry-icon-button-div">
									{(props.entry.onDelete != null && props.entry.indexEntry !== 0) ?
										<IconButton onClick={onDelete} >
											<DeleteForeverOutlinedIcon htmlColor="darkslategrey" fontSize="medium" />
										</IconButton>
										:
										null
									}
								</div>
								:
								null
						}

						{
							showModuleCount ?
								<div className="point-entry-module-count-div">
									{props.entry.moduleSelInMultipleEntries ?
										<a className="point-entry-module-count-text">
											{`*${props.entry.advancedModCount} Total Modules`}
										</a>
										:
										<a className="point-entry-module-count-text">
											{`${props.entry.advancedModCount} Modules`}
										</a>
									}
								</div>
								:
								null
						}

						{
							showConsolidateModuleSelections ?
								<IconButton onClick={onConsolidate} >
									{props.entry.consolidateModule ?
										<JoinFullOutlinedIcon htmlColor="darkslategrey" fontSize="medium" />
										:
										<JoinInnerOutlinedIcon htmlColor="darkslategrey" fontSize="medium" />
									}
								</IconButton>
								:
								null
						}
					</div>
			}
		</div >
	);
}
