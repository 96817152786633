import React from 'react';
import { Image } from 'react-konva';
import InsertImage from './images/InsertionImg.png';
import { DragDeviceInfo } from '../util/DragAndDropHelp';
import { useCachedImage } from '../imageHelp/useCachedImage';


interface Props {
    dragDev: DragDeviceInfo;
}

const DragInsertGraphic = (props: Props) => {
    const [image, imageStatus, /*imgSize*/] = useCachedImage(InsertImage);

    const imageReady = (imageStatus === 'loaded');

    if (imageReady) {
        return (
            <Image
                image={image}
                {...props.dragDev.insertLoc}
            />
        );
    }
    else {
        return (
            null
        );
    }
}

export default DragInsertGraphic;
