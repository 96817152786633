import React, { useState } from 'react';
import { BOMProduct } from './BomItem';
import './Summary.scss';
import { isBOMProduct, BOMItem, BOMItemProduct } from './SummaryHelp';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

interface Props {
	header: BOMItem;
	bomType?: string;
}

export const BOMHeader = (props: Props) => {
	const [expanded, setExpanded] = useState<string | false>('panel4');

	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};
	let nextKey = 0;

	return (
		<Accordion

			expanded={expanded === 'panel4'} onChange={handleChange('panel4')} elevation={0}>
			<AccordionSummary
				sx={{
					borderBottom: "1px solid #979797",
					height: "23px",
					p: "16px",
					":hover": {
						backgroundColor: "#979797",
					}
				}}

				aria-controls="panel4bh-content"
				id="panel4bh-header">
				<Typography sx={{
					fontSize: "24px",
					fontWeight: 500,
				}}>{props.header.catalogOrTitle}</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{ px: 0, }}
			>
				{props.header.children.length > 0 ?
					<>
						{
							props.header.children.map((child) => {
								if (isBOMProduct(child)) {
									return (
										<BOMProduct
											item={child as BOMItemProduct}
											bomType = { props.bomType }
											key={nextKey++}
										/>
									)
								}
								else {
									return (
										<BOMHeader
											header={child}
											key={nextKey++}
											bomType = {props.bomType}
										/>
									)
								}
							})
						}
					</>
					:
					null
				}
			</AccordionDetails>
		</Accordion>
	);
}

