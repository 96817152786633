import { EngInfoPowerSupply } from '../../../engData/EngineeringInfo';
import { PlatformCLX } from '../../PlatformConstants';
import { getPowerSupplyEngInfo } from '../../../util/EngInfoHelp';


const _scoreNoInfo = 999999;
const _scoreTypeMismatch = 100000;

const _score5Above = 1;
const _score5Below = 3;

const _score24Above = 1;
const _score24Below = 3;

// The idea here is to 'score' a comparison between a
// ps (our baseline) and a potential replacement (candidate).
// The LOWER the score, the closer the candidate 'matches'
// the baseline.
const _getMatchScore = (baseLine: EngInfoPowerSupply, candidate: string): number => {

    // Get info for the candidate.
    const info = getPowerSupplyEngInfo(PlatformCLX, candidate);

    // If we can...
    if (info) {
        // Start our score at either 0, if the two
        // have matching types (single vs redundant),
        // and a suitably LARGE number if not.
        let score = (info.redundant === baseLine.redundant)
            ? 0
            : _scoreTypeMismatch;

        // Get the difference in ma provided at 5V.
        const ma5Diff = info.powerAvail.mAat5V - baseLine.powerAvail.mAat5V;

        // Add to the score for any difference.
        // Note we apply a score constant per ma
        // difference, and we use DIFFERENT constants
        // based on whether the candidates number is
        // larger than the baseline (not so bad), or
        // falls short of what's provided by the
        // baseline (worse than providing more).
        // is WORSE for each ma our candidate falls
        if (ma5Diff > 0) {
            score += (ma5Diff * _score5Above);
        }
        else {
            score += (Math.abs(ma5Diff) * _score5Below);
        }

        // Then, do the same for ma at 24V.
        const ma24Diff = info.powerAvail.mAat24V - info.powerAvail.mAat24V;
        if (ma24Diff > 0) {
            score += (ma24Diff * _score24Above);
        }
        else {
            score += (Math.abs(ma24Diff) * _score24Below);
        }

        // return the final tally.
        return score;
    }
    else {
        // A candidate with no info available
        // results in a VERY bad (big) score.
        return _scoreNoInfo;
    }
}

// Based on type and power provided (but ignoring input
// voltage), return the ps catalog number from the candidates
// provided that is the closest (best) match to psToMatch.
// NOTE: psToMatch MUST be a valid CLX power supply!
export const getClosestCLXPowerSupplyMatchTo = (psToMatch: string,
    candidates: string[]): string => {

    // See how many candidates were provided.
    const numCandidates = candidates.length;

    // If any...
    if (numCandidates > 0) {

        // If we have only one, we'll just return
        // it and save any additional effort, since
        // its the ONLY choice.
        if (numCandidates === 1) {
            return candidates[0];
        }
        else {
            // We have MORE than one candidate.
            // Get eng info for the one we're supposed
            // to be matching to.
            const infoToMatch = getPowerSupplyEngInfo(PlatformCLX, psToMatch);

            // If we can...
            if (infoToMatch) {

                // Establish the first as the initial 'best'
                // choice, and save its 'score' as the best score.
                let bestCandidate = candidates[0];
                let bestScore = _getMatchScore(infoToMatch, bestCandidate);

                // 0 is the best possible score. If we
                // already have a 'perfect match', just
                // return it, since we're not going to be
                // able to find a better one.
                if (bestScore === 0) {
                    return bestCandidate;
                }

                // Then walk all of the OTHER candidates. For each...
                for (let idx = 1; idx < candidates.length; idx++) {

                    // Get the candidate.
                    const candidate = candidates[idx];

                    // Get its match score.
                    const score = _getMatchScore(infoToMatch, candidate);

                    // If it's a 'perfect match', just
                    // return it, since we're not going to be
                    // able to find a better one.
                    if (score === 0) {
                        // just return it, since we're not
                        // going to find a better one.
                        return candidate;
                    }

                    // If better than what we've seen already,
                    // promote this one to the new best.
                    if (score < bestScore) {
                        bestScore = score;
                        bestCandidate = candidate;
                    }
                }

                // Return the best we found.
                return bestCandidate;
            }
            else {
                // Unexpected.
                throw new Error('getClosestCLXPowerSupplyMatchTo given unknown ps!');
            }
        }
    }
    else {
        // Unexpected
        throw new Error('getClosestCLXPowerSupplyMatchTo given no candidates!');
    }
}