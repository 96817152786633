import { getConfigSpec } from "../../../config";
import {
    EngInfoFPDModule
} from "../../../engData/EngineeringInfo";
import {
    GeneralImplSpec,
    RegisterGeneralImpl,
} from "../../../implementation/ImplGeneral";
import {
    Chassis,
    EnvRating,
} from "../../../types/ProjectTypes";
import { Size } from "../../../types/SizeAndPosTypes";
import { getFPDModules } from "../../../util/EngInfoHelp";
import { StageUnitsPerMM } from "../../../util/LayoutHelp";
import { snapAddModulesToChassis } from "../../common/HardwareGen";
import { PlatformCpLX } from "../../PlatformConstants";
import { snapConfigureChassis } from "../../snap/SnapChassisConfig";
import {
    PlatformFPDMap,
    RegisterSnapClientDetails,
    snapAddModuleAtSlot,
    snapCanExtendChassis,
    snapCanModuleBeAdded,
    snapCreateChassis,
    snapDeleteModuleAtSlot,
    snapDropDragDeviceOnChassis,
    snapDuplicateChassis,
    snapFilterAvailableModules,
    snapGetActionBtnInfo,
    snapGetChassisDropStatus,
    snapGetChassisRenderer,
    snapGetChassisSizeAsDrawn,
    snapGetDefaultChassisName,
    snapGetDefaultXSlotWidth,
    snapGetMaxNewModules,
    snapGetModuleSlotRestriction,
    snapGetSlotTypeRestriction,
    snapGetXSlotWidthFor,
    SnapPlatformDetails,
    snapUpdateChassisLayout
} from "../../snap/SnapGeneralImpl";

const _cplxElemImageHt = 280; // pixels
const _cplxNominalHt = 145;   // millimeters
const _cplxImageSizeToMM = _cplxNominalHt / _cplxElemImageHt;
const _cplxImageScaleFactor = _cplxImageSizeToMM * StageUnitsPerMM;

const configSpec = getConfigSpec();
const _rightCapImgSrc = `${configSpec.ISD_URL}/assets/cplx/5069_RightCap.png`;
const _rightCapImgSize: Size = { width: 15, height: 280 };

const _cplxChassisImgLoc = `${configSpec.ISD_URL}/assets/cplx/`;


export const cpxlGetEmptySlotImage = (chassis: Chassis): string => {
    chassis;
    return _cplxChassisImgLoc + '5069_Empty.png';
}

const cplxImpl: GeneralImplSpec = {
    platform: PlatformCpLX,
    imageScaleFactor: _cplxImageScaleFactor,

    createChassis: snapCreateChassis,
    replaceChassisPowerSupply: undefined,
    configureChassis: snapConfigureChassis,
    addModuleAtSlot: snapAddModuleAtSlot,
    deleteModuleAtSlot: snapDeleteModuleAtSlot,
    getChassisDropStatus: snapGetChassisDropStatus,
    dropDragDeviceOnChassis: snapDropDragDeviceOnChassis,
    getChassisRenderer: snapGetChassisRenderer,
    getDefaultChassisName: snapGetDefaultChassisName,
    getActBtnInfo: snapGetActionBtnInfo,
    filterAvailableModules: snapFilterAvailableModules,
    getMaxNewModules: snapGetMaxNewModules,
    canExtendChassis: snapCanExtendChassis,
    canModuleBeAdded: snapCanModuleBeAdded,
    getSlotTypeRestriction: snapGetSlotTypeRestriction,
    getModuleSlotRestriction: snapGetModuleSlotRestriction,
    duplicateChassis: snapDuplicateChassis,
    updateChassisLayout: snapUpdateChassisLayout,
    addModulesToChassis: snapAddModulesToChassis,
    getChassisSizeAsDrawn: snapGetChassisSizeAsDrawn,
    getXSlotWidthFor: snapGetXSlotWidthFor,
    getDefaultXSlotWidth: snapGetDefaultXSlotWidth,
    getEmptySlotImage: cpxlGetEmptySlotImage
}

const getCpLXFPDMap = (): PlatformFPDMap => {

    // Get all available FPDs for our platform.
    const fpdMods = getFPDModules(PlatformCpLX);

    // If we got any...
    if (fpdMods.length > 0) {

        // Create our return map.
        const fpdMap = new Map<EnvRating, EngInfoFPDModule>();

        // Add each fpd to the map keyed by its
        // environmental rating.
        fpdMods.forEach(mod => {
            fpdMap.set(mod.envInfo.rating, mod);
        })

        // 5069 has standard and conformally coated
        // products. If we DON'T have a CC-specific
        // FPD, but DO have a standard one, map
        // the standard to the CC rating as well.
        if (!fpdMap.has(EnvRating.ConformalCoated) &&
            fpdMap.has(EnvRating.Standard)) {
            const stdFPD = fpdMap.get(EnvRating.Standard);
            if (stdFPD) {
                fpdMap.set(EnvRating.ConformalCoated, stdFPD);
            }
        }

        // Return the map.
        return fpdMap;
    }

    // No FPDs.
    return undefined;
}

export const RegisterCpLXGeneralImpl = () => {
    const cplxSnapDtls: SnapPlatformDetails = {
        imgScaleFactor: _cplxImageScaleFactor,
        leftSlotStartSize: { width: 117, height: 280 },
        defaultXSlotWidth: 52,
        firstSlotRestricted: true,
        absMaxModules: 31,
        cableSplitAllowed: false,
        rightCapInfo: {
            imgSrc: _rightCapImgSrc,
            width: _rightCapImgSize.width,
            height: _rightCapImgSize.height
        },
        getFPDMap: getCpLXFPDMap
    }

    RegisterSnapClientDetails(PlatformCpLX, cplxSnapDtls);
    RegisterGeneralImpl(cplxImpl);
}
