
export enum SettingCode {
	// Project details
	ConfigName = 'ConfigName',
	Location = 'Location',
	Industry = 'Industry',
	IOEntryMode = 'IOEntryMode',

	// Setting section IDs - could separate these out
	AttrGrpIOSettings = 'IOSettings',
	AttrGrpProjectSettings = 'AttrGrpProjectSettings',
	AttrGrpGuidedSelSettings = 'AttrGrpGuidedSelSettings',
}

export enum SelectionType {
	Edit = 'Edit',
	Combo = 'Combo',
	RequiredCombo = 'RequiredCombo',
	Slider = 'Slider',
	Switch = 'Switch',
	SwitchNoHeader = 'SwitchNoHeader',
}

export enum IOEntryModeEnum {
	Basic = 'BASIC',
	Advanced = 'ADVANCED',
}

export interface SettingOption {
	id: string;
	display: string;
}

export enum SettingRuleType {
	MustEqual = 'MustEqual',
	MustNotEqual = 'MustNotEqual'
}

export enum SettingRuleMsgPlaceHolders {
	AttrName = '*ATTRID*',
	AttrValue = '*ATTRVAL*',
	RuleAttrName = '*RULEID*',
	RuleAttrValue = '*RULEVAL*',
}

export interface SettingRule {
	attrID: string;
	optionID: string[];
	type: SettingRuleType;
	formatMessage: string;
}

export interface SettingValue {
	attrID: string;
	optionID: string;
}

export const getSettingRuleIssues = (selectionString: string, setting: ProjectSetting, rules: SettingRule[]): SettingRule[] => {
	const arrViolations: SettingRule[] = [];

	// Walk the rules... We could have more than one violation.
	rules.forEach((rule) => {
		// If the rule is MustNotEqual
		if (rule.type === SettingRuleType.MustNotEqual) { 
			// If we find the attribute set to ANY of the options...
			if (rule.optionID.some((optID) => selectionString.includes(`${rule.attrID}:${optID}`)))
				arrViolations.push(rule);
		}
		else {
			// We assume the rule to a Must Equal type. If we do 
			// NOT find the attribute set to ANY of the options...
			if (rule.optionID.some((optID) => selectionString.includes(`${rule.attrID}:${optID}`)) === false)
				arrViolations.push(rule);			
		}
	});

	return arrViolations;
}

export interface SettingOptionInfo {
	id: string;
	txtValue: string;
	rules?: SettingRule[];
}

export interface ProjectSetting {
	id: string;
	type: SelectionType;
	name: string;
	units: string; // Used on the EditSetting control where a Unit is needed (i.e. '%', 'mW', etc.). 
	selectedOption: SettingOption;
	options: SettingOption[]; // Used only for UI control w/ options.
	defaultMessage: string; // Informational message that does not change.
	msgWarningOrError?: string; // Overrides the default message when set.
								// Error when valid is false, else a Warning when msgWarningOrError is set..
	valid: boolean;
	visible: boolean;

	// Guided Selection
	gsMapOptionTxtValToInfo?: Map<string, SettingOptionInfo>;
	validateOnChange?: boolean;
	updateIOSelections?: boolean;
	// 2024.4.22 Added a flag to ignore the error
	// state for a setting. This is important in the
	// Chassis Config Dlg, where hidden settings can
	// become invalid, but have nothing to do with an
	// existing chassis (i.e. applies to HW Generation,
	// but not to an existing chassis).
	ignoreError?: boolean;
}

export interface SettingGroupInfo {
	title: string; // Use As ID
	settings: ProjectSetting[];
	note?: string;
	expandable: boolean;
	expanded: boolean;
}

