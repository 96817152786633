import { ChassisProject } from "../types/ProjectTypes";
import { LinkGroupInfo, LinkInfo, ProjInfoDetail } from "../projInfo/ProjectInfo";
import CLXImage from '../platforms/clx/images/1756ChassisPic2.png';
import CpLXImage from '../platforms/cplx/images/5069ChassisPic.png';
import FlexImage from '../platforms/flex/images/5094ChassisPic.png';
import FlexHAImage from '../platforms/flexHA/images/5015Chassis.png';
import MicroImage from '../platforms/micro/images/2080-micro.png';
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants";

const _projInfoTitle = '1756 ControlLogix Advisor';
const _subtitleSettings = 'Verify your Settings to get started';

const _subtitleLeadSummary = 'Configuration Name: ';
//const _locationLead = 'Install Location: ';
//const _industryLead = 'Industry Type: ';
const _noValue = '<not specified>';

const _urlProdPage = 'https://www.rockwellautomation.com/en-us.html';
const _urlSelGuide = 'https://www.rockwellautomation.com/en-us.html';
const _urlTechData = 'https://www.rockwellautomation.com/en-us.html';
const _urlUserManual = 'https://www.rockwellautomation.com/en-us.html';

// 1756 ControlLogix
export const urlControlLogixPlatform = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers/large-controllers/controllogix.html';
export const infoControlLogixPlatform = 'ControlLogix controllers provide high performance in an easy-to-use environment.';
export const docControlLogixSelGuide = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/sg/1756-sg002_-en-p.pdf';
export const docControlLogixTechInfo = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/td/1756-td002_-en-e.pdf';
export const docControlLogixUserGuide = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/um/1756-um058_-en-p.pdf';

// 5069 CompactLogix
export const urlCompactLogixPlatform = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers/small-controllers/compactlogix-family/compactlogix-5380-controllers.html';
export const infoCompactLogixPlatform = 'CompactLogix and Compact GuardLogix controllers provide mid-range application control in an easy-to-use environment. 5069 I/O, the next generation midrange I/O, possesses much higher I/O backplane performance and capability as compared to its predecessor, 1769 I/O.';
export const docCompactLogixSelGuide = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/sg/1769-sg003_-en-p.pdf';
export const docCompactLogixTechInfo = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/td/5069-td002_-en-p.pdf';
export const docCompactLogixUserGuide = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/um/5069-um002_-en-p.pdf';

// 5094 Flex 5000 I/O
export const urlFlexPlatform = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/i-o/in-cabinet-distributed-i-o/5094-flex-5000-i-o.html';
export const infoFlexPlatform = 'FLEX 5000 I/O modules are a rugged and flexible distributed I/O solution';
export const docFlexSelGuide = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/i-o/in-cabinet-distributed-i-o/5094-flex-5000-i-o.html#selection';
export const docFlexTechInfo = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/td/5094-td001_-en-p.pdf';
export const docFlexUserGuide = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/um/5094-um001_-en-p.pdf';

// 2080 Micro 800 I/O (TODO)
export const urlMicroPlatform = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/i-o/in-cabinet-distributed-i-o/5094-flex-5000-i-o.html';
export const infoMicroPlatform = 'Micro800 controllers are designed for low-cost, standalone machines.';
export const docMicroSelGuide = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/i-o/in-cabinet-distributed-i-o/5094-flex-5000-i-o.html#selection';
export const docMicroTechInfo = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/td/5094-td001_-en-p.pdf';
export const docMicroUserGuide = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/um/5094-um001_-en-p.pdf';

// 5015 FlexHA 5000
export const urlFlexHAPlatform = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/i-o/high-availability-distributed-i-o/flexha-5000-io.html';
export const infoFlexHAPlatform = 'FlexHA modules are a rugged and flexible redundant distributed I/O solution';
export const docFlexHASelGuide = 'https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/i-o/high-availability-distributed-i-o/flexha-5000-io.html';
export const docFlexHATechInfo = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/td/5015-td001_-en-p.pdf';
export const docFlexHAUserGuide = 'https://literature.rockwellautomation.com/idc/groups/literature/documents/um/5015-um001_-en-p.pdf';

export interface PlatformProjInfo {
    platform: string;
	title: string;
    url: string;
    img: string;
    infoText: string;
    docSelGuide: string;
    docTechInfo: string;
    docUserManual: string;
}

export const getPlatformProjectInfo = (platform: string): PlatformProjInfo => {
    switch (platform) {
        case PlatformCLX:
            return {
                platform: PlatformCLX,
                title: 'ControlLogix 1756',
                url: urlControlLogixPlatform,
                img: CLXImage,
                infoText: infoControlLogixPlatform,
                docSelGuide: docControlLogixSelGuide,
                docTechInfo: docControlLogixTechInfo,
                docUserManual: docControlLogixUserGuide,
            };
        case PlatformCpLX:
            return {
                platform: PlatformCpLX,
                title: 'CompactLogix 5069',
                url: urlCompactLogixPlatform,
                img: CpLXImage,
                infoText: infoCompactLogixPlatform,
                docSelGuide: docCompactLogixSelGuide,
                docTechInfo: docCompactLogixTechInfo,
                docUserManual: docCompactLogixUserGuide,
            };
        case PlatformFlex:
            return {
                platform: PlatformFlex,
                title: '5094 Flex 5000 I/O',
                url: urlFlexPlatform,
                img: FlexImage,
                infoText: infoFlexPlatform,
                docSelGuide: docFlexSelGuide,
                docTechInfo: docFlexTechInfo,
                docUserManual: docFlexUserGuide,
            };

        case PlatformMicro:
            return {
                platform: PlatformMicro,
                title: 'Micro 800 Series',
                url: urlMicroPlatform,
                img: MicroImage,
                infoText: infoMicroPlatform,
                docSelGuide: docMicroSelGuide,
                docTechInfo: docMicroTechInfo,
                docUserManual: docMicroUserGuide,
            };

        case PlatformFlexHA:
            return {
                platform: PlatformFlexHA,
                title: '5015 FLEXHA 5000 I/O',
                url: urlFlexHAPlatform,
                img: FlexHAImage,
                infoText: infoFlexHAPlatform,
                docSelGuide: docFlexHASelGuide,
                docTechInfo: docFlexHATechInfo,
                docUserManual: docFlexHAUserGuide,
            };
    }

    throw new Error(`getPlatformProjectInfo(): Unrecognized platform <${platform}>.`);
}

const makeLinkGroup = (): LinkGroupInfo => {
    return {
        links: new Array<LinkInfo>()
    };
}

const getProdPage = (): LinkInfo => {
    return {
        text: 'PRODUCT PAGE',
        url: _urlProdPage
    };
}

const getMainDocGroup = (): LinkGroupInfo => {
    const grp = makeLinkGroup();
    grp.title = 'Documentation';
    grp.links.push({ text: '1756 ControlLogix Selection Guide', url: _urlSelGuide });
    grp.links.push({ text: '1756 ControlLogix Technical Data', url: _urlTechData });
    grp.links.push({ text: '1756 ControlLogix User Manual', url: _urlUserManual });
    return grp;
} 

const getTempDocGroup = (lead: string): LinkGroupInfo => {
    const grp = makeLinkGroup();
    grp.links.push({ text: lead + ' Selection Guide', url: _urlSelGuide });
    grp.links.push({ text: lead + ' Technical Data', url: _urlTechData });
    grp.links.push({ text: lead + ' User Manual', url: _urlUserManual });
    return grp;
}

export const getSettingsProjInfo = (imgSrc: string): ProjInfoDetail => {
    const info: ProjInfoDetail = {
        title: _projInfoTitle,
        subtitle: _subtitleSettings,
        prodPageInfo: getProdPage(),
        imageSource: imgSrc,
        linkGroups: new Array<LinkGroupInfo>()
    };

    info.linkGroups?.push(getMainDocGroup());

    return info;
}

export const getProjInfoForSummary = (project: ChassisProject, imgSrc: string): ProjInfoDetail => {
    const projName = (project.config.projectName && project.config.projectName.length)
        ? project.config.projectName
        : _noValue
    const info: ProjInfoDetail = {
        title: _projInfoTitle,
        subtitle: _subtitleLeadSummary + projName,
        prodPageInfo: getProdPage(),
        imageSource: imgSrc,
        extraText: undefined, //getLocAndIndustyTextForSummary(project),
        linkGroups: new Array<LinkGroupInfo>()
    };

    info.linkGroups?.push(getMainDocGroup());

    info.linkGroups?.push(getTempDocGroup('Other'));

    return info;
}