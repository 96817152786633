import { Workbook, Worksheet, CellErrorValue, CellFormulaValue, Cell } from "exceljs";
import { getLocationSettings } from "../model/ChassisProject";
import { getLocAttributeSetting, refreshLocAttrInfoSelectionArray } from "../model/GuidedSelection";
import { getIOPointFilterNameToValMap, getPointTypeDisplayTitle } from "../model/IOModule";
import { getIndustryDisplayStr, getInstallLocDisplayStr } from "../projInfo/LocationAndIndustry";
import { LogRender } from "../types/Globals";
import { PointTypeFilterMask } from "../types/IOModuleTypes";
import { User } from "oidc-client";
import { ChassisProject } from "../types/ProjectTypes";
import { IOEntryModeEnum } from "../types/SettingsTypes";
import { logger } from "../util/Logger";
import { getPlatformFromBusCode } from "./PlatformIDs";
import { onTemplateLoadedCallback, tplNoOp, XLSpec, XLTemplateInfo, XLTplAction } from "./PlatformTemplateHelp";

export const TPLFld_Industry = 'industry';
export const TPLFld_InstLoc = 'installLocation';
export const TPLFld_ProjName = 'projectName';
export const TPLSht_DataCells = 'DataCells';
export const TPLSht_UserSels = 'IOList';

export const getXLCellValueAsString = (sheet?: Worksheet, addrCell?: string): string => {
	let strVal = '';
	if (sheet && addrCell) {
		const cell = sheet.getCell(addrCell);
		if (cell) {
			if (cell.result) {
				if (isXLResultAnError(cell) === false) {
					strVal = cell.result.toString().trim();
				}
			}
			else if (cell.value)
				strVal = cell.value.toString().trim();
		}
	}

	return strVal;
}

export const isXLResultAnError = (cell: Cell): boolean => {
	const error = Object(cell.result) as CellErrorValue;
	if (!error.error)
		return false;
	return true;
}



export const createNewXLSpec = (specSheet: Worksheet): XLSpec => {
	return {
		dataSheet: specSheet,
		mapVarNameToCellAddress: new Map<string, string>(),
		platformDataCellAddress: '',
		platformBusCode: '',
		ioEntryRowStart: 0,
		ioEntryColType: '',
		ioEntryColFunction: '',
		ioEntryColPointQty: '',
		ioEntryColLocation: '',
		ioEntryColNotes: '',
		mapDisplay_AttrToCellAddr: new Map<string, string>(),
		valid: false,
	};
}


export const createXLSpec = (specSheet: Worksheet): XLSpec => {
	// Create a new spec.
	const spec = createNewXLSpec(specSheet);

	// We start at row 1. When we encounter an empty
	// column A cell, we're done looking.
	let row = 1;
	while (row > 0) {
		const keyCellVal = getXLCellValueAsString(specSheet, `A${row}`);
		const addrCellVal = getXLCellValueAsString(specSheet, `B${row}`);
		const key = (keyCellVal == null ? undefined : keyCellVal.toString());
		if (!key)
			row = -1;
		else if (addrCellVal) {
			const addr = addrCellVal.toString();
			if (addr) {
				if (key.startsWith('ioEntry')) {
					// We have an I/O Entry spec. The keys are NOT a part
					// of any interface. We need the starting row of Cells
					// with the entries and the Columns with the entry values.
					// i.e. Start row is 11 and the Point Quantity Column is
					// 'D', the Cell Addresses D11, D12, D13, etc. will contain
					// the Point Quantities for each entry we have in the template.
					switch (key) {
						case 'ioEntryRowStart':
							spec.ioEntryRowStart = Number(addr);
							break;
						case 'ioEntryColFunction':
							spec.ioEntryColFunction = addr;
							break;
						case 'ioEntryColType':
							spec.ioEntryColType = addr;
							break;
						case 'ioEntryColNotes':
							spec.ioEntryColNotes = addr;
							break;
						case 'ioEntryColPointQty':
							spec.ioEntryColPointQty = addr;
							break;
						case 'ioEntryColLocation':
							spec.ioEntryColLocation = addr;
							break;
					}
				}
				else if (key.startsWith('display_')) {
					const attr = key.replace('display_', '').trim();
					spec.mapDisplay_AttrToCellAddr.set(attr, addr);
				}
				else if (key === 'platform') {
					// Platform is NOT a Cell Address - it's the platform.
					spec.platformBusCode = addr;
				}
				else {
					spec.mapVarNameToCellAddress.set(key, addr);
				}
			}
		}
		row++;
	}

	// Start by saying the spec is valid...
	spec.valid = true;

	// Validate the spec - if something is off, abort.
	if (isNaN(spec.ioEntryRowStart) || spec.ioEntryRowStart < 5)
		spec.valid = false;
	else if (!spec.ioEntryColType || !spec.ioEntryColPointQty)
		spec.valid = false;
	else if (spec.mapVarNameToCellAddress.size === 0)
		spec.valid = false;

	return spec;
}

export const loadXLTemplateInfo = async (
	file: File,
	project: ChassisProject,
	callback: onTemplateLoadedCallback | undefined): Promise<XLTemplateInfo | undefined> => {

	const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
	const buffer = await blob.arrayBuffer();

	if(LogRender.Templates)
		logger.log(`${file.name} ${file.size} buffer ${buffer.byteLength}`);

	const wkbook = new Workbook();
	if (wkbook) {
		await wkbook.xlsx.load(buffer);

		if (LogRender.Templates)
			logger.log(`worksheet count ${wkbook.worksheets.length}`);

		const userSelSheet = wkbook.getWorksheet(TPLSht_UserSels); 
		const specSheet = wkbook.getWorksheet(TPLSht_DataCells);
		if (userSelSheet && specSheet) {
			// Create the worksheet Spec, which tells us
			// what cells correspond to what data.
			const spec = createXLSpec(specSheet);
			if (spec) {
				return {
					workbook: wkbook,
					action: XLTplAction.None,
					onLoadedCallback: (callback ? callback : tplNoOp),
					settingSheet: userSelSheet,
					specSheet: specSheet,
					platform: getPlatformFromBusCode(spec.platformBusCode),
					platformBusCode: spec.platformBusCode,
					project: project,
					spec: spec,
				};
			}
		}
	}

	return undefined;
}


export const AddDataToTemplate = (tplInfo: XLTemplateInfo, user?: User): boolean => { 
	const getProfile = user?.profile;
	// Refresh all of the selections in the Loc.
	const locAttrInfo = getLocationSettings(tplInfo.project);
	refreshLocAttrInfoSelectionArray(locAttrInfo);

	// Make a copy and add some more data...
	const arrNameVal = [...locAttrInfo.arrAttributeNameToValue];

	// Add project name, industry and installLoc.
	const projName = (tplInfo.project.config.projectName ? tplInfo.project.config.projectName : 'Project001');
	arrNameVal.push({ attrID: TPLFld_ProjName, optionID: projName });
	arrNameVal.push({ attrID: TPLFld_Industry, optionID: locAttrInfo.industryID || getProfile?.raJobIndustry});
	arrNameVal.push({ attrID: TPLFld_InstLoc, optionID: locAttrInfo.installLocationID  || getProfile?.Country });

	// Run the selections and for each attr found in
	// the spec, set the value in the sheet.
	arrNameVal.forEach((sel) => {
		let addrCell = '';
		let valCell = '';
		let addrFormula = '';
		let valFormResult = '';
		// Check if we have a 'Display Cell Address' for the selection.
		// Certain template cells contain formulas tied to 'Display Cells'.
		// When importing data, we pull ATTR ID values from the 'Formula Cells'
		// and when Exporting, we add data to the 'Display Cells', which have 
		// ATTR Display Strings.
		const displayAddr = tplInfo.spec.mapDisplay_AttrToCellAddr.get(sel.attrID);
		if (displayAddr) {
			addrCell = displayAddr;
			// Get the formula Addr
			const formAddr = tplInfo.spec.mapVarNameToCellAddress.get(sel.attrID);
			if (formAddr)
				addrFormula = formAddr;

			switch (sel.attrID) {
				case TPLFld_Industry:
					valCell = getIndustryDisplayStr(locAttrInfo.platform, sel.optionID);
					valFormResult = sel.optionID;
					break;
				case TPLFld_InstLoc:
					valCell = getInstallLocDisplayStr(locAttrInfo.platform, sel.optionID);
					valFormResult = sel.optionID;
					break;
				default:
					{
						// We should have a guided selection attribute.
						const setting = getLocAttributeSetting(locAttrInfo, sel.attrID);
						if (setting) {
							const opt = setting.options.find(x => x.id === sel.optionID);
							if (opt) {
								valCell = opt.display;
								valFormResult = opt.id;
							}
						}
					}
					break;
			}
		}
		else {
			const cellAddress = tplInfo.spec.mapVarNameToCellAddress.get(sel.attrID);
			if (cellAddress) {
				addrCell = cellAddress;
				valCell = sel.optionID;
			}
		}

		if (addrCell && valCell) {
			const cell = tplInfo.settingSheet.getCell(addrCell);
			if (cell) {
				cell.value = valCell;
			}
		}

		if (addrFormula && valFormResult) {
			const cell = tplInfo.settingSheet.getCell(addrFormula);
			if (cell) {
				const formValue = cell.value as CellFormulaValue;
				if (formValue.formula) {
					const form: CellFormulaValue = {
						formula: formValue.formula,
						result: valFormResult,
						date1904: formValue.date1904
					}

					cell.value = form;
				}
			}
		}
	});


	// Point entries - This data will (most likely) have multiple
	// rows. We start by getting how many rows the sheet currently
	// has. The SPEC has the starting ROW and the COLUMNS for each
	// piece of entry data.
	let sheetRowCount = tplInfo.settingSheet.rowCount;

	let row = 0;
	let ioEntryColType = '';
	let ioEntryColFunction = '';
	let ioEntryColPointQty = '';
	row = tplInfo.spec.ioEntryRowStart;
	ioEntryColType = tplInfo.spec.ioEntryColType;
	ioEntryColFunction = tplInfo.spec.ioEntryColFunction;
	ioEntryColPointQty = tplInfo.spec.ioEntryColPointQty;

	// Verify we have everything we need.
	if (sheetRowCount === 0 || row === 0 || !ioEntryColType || !ioEntryColFunction || !ioEntryColPointQty)
		return false;

	// Get a map of I/O filters (Filter Name to Filter Bit).
	const functionMap = getIOPointFilterNameToValMap(PointTypeFilterMask, false, false);

	// Run the entries and populate the sheet.
	locAttrInfo.pointEntrySection.entries.forEach((entry) => {
		if (row > sheetRowCount) {
			if (tplInfo.settingSheet.addRow(['', '', '', '', '']))
				sheetRowCount++;
		}

		// Point Type
		let cell = tplInfo.settingSheet.getCell(`${ioEntryColType}${row}`);
		if (cell)
			cell.value = getPointTypeDisplayTitle(entry.typeID, false);

		// Point Functionality/Filter (Advanced Mode Only)
		cell = tplInfo.settingSheet.getCell(`${ioEntryColFunction}${row}`);
		if (cell) {
			let fnName = 'Any';
			if (tplInfo.project.config.IOEntryMode === IOEntryModeEnum.Advanced) {
				const functionBit = entry.filterInclude & PointTypeFilterMask;
				functionMap.some((x) => {
					if (x.value === functionBit) {
						fnName = x.name;
						return true;
					}
				});
			}
			cell.value = fnName;
		}

		// Point Quantity
		cell = tplInfo.settingSheet.getCell(`${ioEntryColPointQty}${row}`);
		if (cell)
			cell.value = entry.points;

		// Increment our row.
		row++;
	});


	return true;
}