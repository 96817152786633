import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { PlatformCpLX } from "../../PlatformConstants";
import { cplxExecuteAutoFix, cplxSwapCtrlForSafetyCtrl } from "./CpLXAutoFix";
import { snapDoChassisPowerCheck, snapDoGeneralCheck } from "../../snap/SnapChecker";


const cplxImpl: CheckerImplementation = {
    platform: PlatformCpLX,
    doGeneralCheck: snapDoGeneralCheck,
    doChassisPowerCheck: snapDoChassisPowerCheck,
    swapCtlrForSafetyCtrl: cplxSwapCtrlForSafetyCtrl,
    executeAutoFix: cplxExecuteAutoFix,
}


export const RegisterCpLXCheckerImpl = () => {
    RegisterCheckerImpl(cplxImpl);
}
