import { FamilyCLX, FamilyCpLX, PlatformCLX, PlatformCpLX, PlatformFlex } from "../platforms/PlatformConstants";
import { UseSmartPowerDsply } from "../types/Globals";
import { StatusLevel } from "../types/MessageTypes";
import { Chassis, ChassisModule, PowerUnitType, PwrUsageInfo, UsageItemProgbarReplacement, UsageLevelStatus } from "../types/ProjectTypes";
import { PSUErrorThreshold, PSUWarningThreshold } from "./Checker";
import { getEngInfoForComp } from "./EngInfoHelp";
import { isPlatformSnapType } from "./PlatformHelp";
import { LogMsgLevel } from "./ProjectLog";


export enum PowerType {
    PT_524mA = 'PT_524mA',
    PT_ModSA = 'PT_ModSA'
}

export const getFamilyPowerType = (family: string): PowerType => {
    switch (family) {
        case FamilyCLX:
            return PowerType.PT_524mA;

        case FamilyCpLX:
            return PowerType.PT_ModSA;

        default:
            throw new Error('Unknown PowerType for family: ' + family);
    }
}

export const getPlatformPowerType = (family: string): PowerType => {
    switch (family) {
        case PlatformCLX:
            return PowerType.PT_524mA;

        case PlatformCpLX:
            return PowerType.PT_ModSA;

        default:
            throw new Error('Unknown PowerType for family: ' + family);
    }
}

// Dual purpose.
//  - For a power supplier, props indicate supplied,
///   or available power.
//  - For a power consumer, props indicate consumed power
export interface PowerBreakdown {
    // Used for PT_524mA power type
    // only. 0's for other types.
    mAat5V: number;
    mAat24V: number;
    mWatt: number;

    // Used for PT_ModSA power type
    // only. 0's for other types.
    modPower: number;
    saPower: number;
}

export interface PowerBreakdownTips {
    tip5V?: UsageItemProgbarReplacement;
    tip24V?: UsageItemProgbarReplacement;
    tipWatt?: UsageItemProgbarReplacement;
    tipMod?: UsageItemProgbarReplacement;
    tipSA?: UsageItemProgbarReplacement;
}

export interface PowerBreakdownUsageInfo {
    info5V: PwrUsageInfo;
    info24V: PwrUsageInfo;
    infoWatt: PwrUsageInfo;
    infoMod: PwrUsageInfo;
    infoSA: PwrUsageInfo;
}

export const isMoreOrEqualPower = (p1: PowerBreakdown, p2: PowerBreakdown): boolean => {
    if (p2.mAat24V > p1.mAat24V) return false;
    if (p2.mAat5V > p1.mAat5V) return false;
    if (p2.mWatt > p1.mWatt) return false;
    return true;
}

export const _getConvDtl = (unitType: PowerUnitType): [divisor: number, unitTag: string] => {
    switch (unitType) {
        case PowerUnitType.mA:
            return [1000, 'Amp'];

        case PowerUnitType.mW:
            return [1000, 'Watt'];

        default:
            throw new Error('Invalid unit type in _getConvDtl');
    }
}

export const getEmptyPowerBreakdown = (): PowerBreakdown => {
    return {
        mAat5V: 0,
        mAat24V: 0,
        mWatt: 0,
        modPower: 0,
        saPower: 0
    };
}

export const isEmptyPower = (bd: PowerBreakdown): boolean => {
    return ((bd.mAat5V === 0) &&
        (bd.mAat24V === 0) &&
        (bd.mWatt === 0) &&
        (bd.modPower === 0) &&
        (bd.saPower === 0));
}

const _valQualifiesForConv = (value: number): boolean => {
    const valStr = value.toString();
    if (valStr.length >= 4) {
        if (valStr.indexOf('.') === -1) {
            if (valStr[valStr.length - 1] === '0') {
                return true;
            }
        }
    }
    return false;
}

// Warning: value expected to be PRE-qualified as being
// suitable for conversion.
const _getConvertedValStr = (value: number, unitType: PowerUnitType): string => {
    const [divisor, unitTag] = _getConvDtl(unitType);
    const cnvVal = value / divisor;
    const pluralExt = (cnvVal !== 1.0) ? 's' : '';
    return cnvVal + ' ' + unitTag + pluralExt;
}

export const getPwrValStr = (value: number, unitType: PowerUnitType, forceFullUnits?: boolean): string => {
    if (UseSmartPowerDsply && (_valQualifiesForConv(value) || forceFullUnits)) {
        return _getConvertedValStr(value, unitType);
    }
    return value + ' ' + unitType.toString();
}

export const getPowerUsageStatus = (pctUsed: number): UsageLevelStatus => {

    if (pctUsed > PSUErrorThreshold) {
        return UsageLevelStatus.Error;
    }
    else if (pctUsed > PSUWarningThreshold) {
        return UsageLevelStatus.Warning;
    }
    else {
        return UsageLevelStatus.OK;
    }
}

const _getPwrUsageUsedSide = (used: number, forceFullUnits?: boolean): string => {

    // Incoming 'used' value is in 'milli' form (mA or mW).
    // Start by converting to FULL units (Amps or Watts).
    const usedFullUnits = used / 1000;
    const useFullUnits = (usedFullUnits > 1 || forceFullUnits);

    // If the resulting number is greater than 1,
    // We'll use it as a string rounded to include
    // one digit to the right of the decimal point
    //The below code is changed to show exact value
    //in the issues modal.
    //Modfied to prevent rounding of the decimal 
    //value due to toFixed() Funtion
    if (useFullUnits) {
        return String(Math.trunc(usedFullUnits * 10) / 10);
    }

    const usedPrec = used.toPrecision(1);
    const newUsed = parseFloat(usedPrec);
    const newUsedFullUnits = newUsed / 1000;
    return newUsedFullUnits.toPrecision(1);
}

export const getPwrUsageInfo = (used: number, avail: number, unitType: PowerUnitType): PwrUsageInfo => {
    const pctUsed = Math.min(1.0, used / avail);

    // 2024.3.26 We ran into situations where the units
    // of the 'used side' are in Amps and the supplied
    // side is in mAmps. Check if either side qualifies
    // to be converted to Amps (or Watts). If one side
    // does and the other does NOT, force full units
    // on both sides (i.e. both in Amps instead of mAmps).
    const forceFullUnits = (_valQualifiesForConv(used) != _valQualifiesForConv(avail));

    const usageText = _getPwrUsageUsedSide(used, forceFullUnits) + ' / ' + getPwrValStr(avail, unitType, forceFullUnits);

    return { pctUsed: pctUsed, usageText: usageText, status: getPowerUsageStatus(pctUsed) };
}

const _addToPwrBrkdown = (target: PowerBreakdown, toAdd: PowerBreakdown): PowerBreakdown => {
    const bdSum: PowerBreakdown = { ...target };
    bdSum.mAat24V += toAdd.mAat24V;
    bdSum.mAat5V += toAdd.mAat5V;
    bdSum.mWatt += toAdd.mWatt;
    bdSum.modPower += toAdd.modPower;
    bdSum.saPower += toAdd.saPower;
    return bdSum;
}

const _subtractFromPwrBrkdown = (target: PowerBreakdown, toSubtract: PowerBreakdown): PowerBreakdown => {
    const bdRslt: PowerBreakdown = { ...target };
    bdRslt.mAat24V -= toSubtract.mAat24V;
    bdRslt.mAat5V -= toSubtract.mAat5V;
    bdRslt.mWatt -= toSubtract.mWatt;
    bdRslt.modPower -= toSubtract.modPower;
    bdRslt.saPower -= toSubtract.saPower;
    return bdRslt;
}

export const addPowerBreakdown = (pwrTarget: PowerBreakdown, pwrToAdd: PowerBreakdown, tallyOnTarget: boolean): PowerBreakdown => {
    const retVal = _addToPwrBrkdown(pwrTarget, pwrToAdd);
    if (tallyOnTarget) {
        setPowerBreakdownEqual(pwrTarget, retVal);
    }
    return retVal;
}

export const subtractPowerBreakdown = (pwrTarget: PowerBreakdown, pwrToSubtract: PowerBreakdown, tallyOnTarget: boolean): PowerBreakdown => {
    const retVal = _subtractFromPwrBrkdown(pwrTarget, pwrToSubtract);
    if (tallyOnTarget) {
        setPowerBreakdownEqual(pwrTarget, retVal);
    }
    return retVal;
}

export const isPowerBreakdownNegative = (pwrTarget: PowerBreakdown): boolean => {
    return (
        pwrTarget.mAat24V < 0 ||
        pwrTarget.mAat5V < 0 ||
        pwrTarget.mWatt < 0 ||
        pwrTarget.modPower < 0 ||
        pwrTarget.saPower < 0
    );
}


export const setPowerBreakdownEqual = (destination: PowerBreakdown, source: PowerBreakdown) => {
    destination.mAat24V = source.mAat24V;
    destination.mAat5V = source.mAat5V;
    destination.mWatt = source.mWatt;
    destination.modPower = source.modPower;
    destination.saPower = source.saPower;
}

export interface PowerStatusLogMessage {
    status: LogMsgLevel;
    message: string;
}

export const getChassisPowerLogMessages = (consumed: PowerBreakdown, supplied: PowerBreakdown, chassis: Chassis): PowerStatusLogMessage[] => {
    const arrayMsgs: PowerStatusLogMessage[] = [];
    if (chassis.platform === PlatformCLX) {
        // 5V, 24V, mW
        let percentage = calcPowerRatio(consumed.mAat5V, supplied.mAat5V);
        // If the percentage consumed is > than 
        // the Warning threshold, we at least have 
        // a warning and maybe an Error...
        if (percentage > PSUWarningThreshold) {
            const status = (percentage > PSUErrorThreshold ? LogMsgLevel.error : LogMsgLevel.warning);
            const pctThreshold = (percentage > PSUErrorThreshold ? PSUErrorThreshold : PSUWarningThreshold) * 100;
            const msg = `The consumed 5V power (${(consumed.mAat5V / 1000.0).toFixed(2)} Amps) in chassis '${chassis.name}' exceeds ${pctThreshold.toFixed(1)}% of the supplied power of ${(supplied.mAat5V / 1000.0).toFixed(2)} Amps.`;
            arrayMsgs.push({ status: status, message: msg });
        }

        percentage = calcPowerRatio(consumed.mAat24V, supplied.mAat24V);
        if (percentage > PSUWarningThreshold) {
            const status = (percentage > PSUErrorThreshold ? LogMsgLevel.error : LogMsgLevel.warning);
            const pctThreshold = (percentage > PSUErrorThreshold ? PSUErrorThreshold : PSUWarningThreshold) * 100;
            const msg = `The consumed 24V power (${(consumed.mAat24V / 1000.0).toFixed(2)} Amps) in chassis '${chassis.name}' exceeds ${pctThreshold.toFixed(1)}% of the supplied power of ${(supplied.mAat24V / 1000.0).toFixed(2)} Amps.`;
            arrayMsgs.push({ status: status, message: msg });
        }

        percentage = calcPowerRatio(consumed.mWatt, supplied.mWatt);
        if (percentage > PSUWarningThreshold) {
            const status = (percentage > PSUErrorThreshold ? LogMsgLevel.error : LogMsgLevel.warning);
            const pctThreshold = (percentage > PSUErrorThreshold ? PSUErrorThreshold : PSUWarningThreshold) * 100;
            const msg = `The consumed power (${(consumed.mWatt / 1000.0).toFixed(2)} Watts) in chassis '${chassis.name}' exceeds ${pctThreshold.toFixed(1)}% of the supplied power of ${(supplied.mWatt / 1000.0).toFixed(2)} Watts.`;
            arrayMsgs.push({ status: status, message: msg });
        }
        return arrayMsgs;
    }

    // Add any other non-CLX platforms here....

    return arrayMsgs;
}

export const IsPowerThresholdExceeded = (platform: string, consumed: PowerBreakdown, supplied: PowerBreakdown, threshold: number): boolean => {

    if (platform === PlatformCLX) {
        let percentage = calcPowerRatio(consumed.mAat5V, supplied.mAat5V);
        if (percentage > threshold)
            return true;
        percentage = calcPowerRatio(consumed.mAat24V, supplied.mAat24V);
        if (percentage > threshold)
            return true;
        percentage = calcPowerRatio(consumed.mWatt, supplied.mWatt);
        if (percentage > threshold)
            return true;
    }
    else if (isPlatformSnapType(platform)) {
        let percentage = calcPowerRatio(consumed.modPower, supplied.modPower);
        if (percentage > threshold)
            return true;
        percentage = calcPowerRatio(consumed.saPower, supplied.saPower);
        if (percentage > threshold)
            return true;
    }

    return false;
}

export const calcPowerRatio = (consumed: number, supplied: number): number => {
    // Avoid div by zero.
    if (supplied === 0) {
        // if we have a consumed value, return > 100%.
        if (consumed > 0)
            return 1.000009;
        else
            return 0.0;
    }

    return consumed / supplied;
}

const _getPowerLabelPart = (breakdown: PowerBreakdown): string => {
    return getPwrValStr(breakdown.mAat5V, PowerUnitType.mA) + ' (5v) / ' +
        getPwrValStr(breakdown.mAat24V, PowerUnitType.mA) + ' (24v)';
}

export const getPSLabelText = (pwrInfo: PowerBreakdown, redundant: boolean, slim: boolean):
    string => {

    let label = '';
    if (redundant) {
        label += 'Redundant: ';
    }

    label += _getPowerLabelPart(pwrInfo);

    if (slim) {
        label += ' (Slim)';
    }

    return label;
}

////////////////////// POWER BREAKDOWN TIPS ///////////////////////////////////
// These tips are intended to REPLACE the power
// progressbar in the Floating Details Panel as
// well in the Modal Issues window. If a tip is
// defined for MOD/SA/5V/24/Watt, the corresponding
// progress bar for the power type will be replaced
// with the 'repLabel' text with a icon button next
// to the label.When the icon button is clicked, the
// 'replTipText' is displayed.

const _chassis5069SAPwrMsg = 'SA Power is supplied at the Controller, Adapter, or Field Power Distributor(s) within the chassis. Review those modules for details.';
const _chassis5094SAPwrMsg = 'SA Power is connected directly to I/O Modules. See Module Details for specific information.';

export const getModPowerBreakdownTips = (module: ChassisModule, singleLineFrm?: boolean): PowerBreakdownTips => {
    const tips: PowerBreakdownTips = {};

    if (module && isPlatformSnapType(module.platform)) {
        if (module.isFPD) {
            const label = (singleLineFrm ? 'N/A' : 'Not Applicable');
            tips.tipMod = {
                replTipText: 'An FPD module does not supply MOD Power.',
                replTipStatus: StatusLevel.Info,
                repLabel: label,
            }
        }
        else if(module.platform === PlatformFlex) {
            // If we have a MOD Power supplier, but not an SA Power supplier...
            const modInfo = getEngInfoForComp(module.platform, module.catNo);
            if (modInfo && modInfo.modPwrSupplier && !modInfo.saPwrSupplier) {
                const label = (singleLineFrm ? 'N/A' : 'Not Applicable');
                tips.tipSA = {
                    replTipText: _chassis5094SAPwrMsg,
                    replTipStatus: StatusLevel.Info,
                    repLabel: label,
                }
            }
        }
    }
    return tips;
}


export const getChassisPowerBreakdownTips = (chassis: Chassis, singleLineFrm?: boolean): PowerBreakdownTips => {
    const tips: PowerBreakdownTips = {};
    if (chassis) {
        if (chassis.isPlaceholder) {
            // We have a 5069 or 5094 chassis.
            // Note: 5094 Flex has a fake 'placeholder'
            // chassis, but has ONE SA/MOD power supplier,
            // where CompactLogix could have more than one
            // SA power supplier (Ctrl/Comm and any FPDs).

            const chassisSAMsg = (chassis.platform === PlatformCpLX ? _chassis5069SAPwrMsg : _chassis5094SAPwrMsg);

            // Start by asking if we have a Slot 0 Module,
            // which is the starting SA/MOD power provider.
            if (chassis.modules.length === 0 || chassis.modules[0] == null) {
                const label = (singleLineFrm ? 'N/A' : 'Not Applicable');
                tips.tipSA = {
                    replTipText: chassisSAMsg,
                    replTipStatus: StatusLevel.Info,
                    repLabel: label,
                };

                // Single Line Format indicates this will be in
                // the MODAL Issues Dialog. If so, set the status 
                // level to 'NA' - this will prevent the icon from 
                // showing in the modal version (as well as the tip).
                const statusMOD = (singleLineFrm ? StatusLevel.NA : StatusLevel.Info);
                tips.tipMod = {
                    replTipText: 'MOD Power is supplied from the Slot 0 module, which does not exist.',
                    replTipStatus: statusMOD,
                    repLabel: label,
                };
            }

            // If we are Flex or CompactLogix...
            if (isPlatformSnapType(chassis.platform)) {
                // If we do not have an SA 'tip' yet...
                if (tips.tipSA == null) {
                    const label = (singleLineFrm ? 'N/A' : 'Not Applicable');
                    tips.tipSA = {
                        replTipText: chassisSAMsg,
                        replTipStatus: StatusLevel.Info,
                        repLabel: label,
                    };
                }
            }
        }
    }

    return tips;
}
