
export const formatAsIntWithCommas = (num: number): string => {

	// This function is used to format things like PPS, where
	// we want to include one for any portion, not simply round.
	// That keeps numbers in alignment with PPS on Adv Comm results page.
	const asInt = Math.ceil(num);

	// Return in a format suitable for the
	// current locale.
	return asInt.toLocaleString();
}

export const formatBytesString = (bytes: number, longUnits: boolean, useKiloBinForm: boolean): string => {

	const k1 = useKiloBinForm ? 1024 : 1000;
	const k5 = k1 * 5;
	const mega = k1 * k1;
	const giga = mega * k1;

	if (bytes > giga) {
		const numPart = bytes / giga;
		return String(Math.trunc(numPart * 10) / 10) + ' GB' + (longUnits ? 'ytes' : '');
	}

	if (bytes > mega) {
		const numPart = bytes / mega;
		return String(Math.trunc(numPart * 10) / 10) + ' MB' + (longUnits ? 'ytes' : '');
	}

	if (bytes > k5) {
		const numPart = bytes / k1;
		return String(Math.trunc(numPart * 10) / 10) + ' KB' + (longUnits ? 'ytes' : '');
	}

	if (bytes > k1) {
		const numPart = bytes / k1;
		return String(Math.trunc(numPart * 10) / 10) + ' KB' + (longUnits ? 'ytes' : '');
	}

	return String(Math.trunc(bytes * 10) / 10) + ' bytes';
}

export const formatBitsPerSecond = (bitsPerSec: number): string => {

	const k1 = 1000;
	const k5 = k1 * 5;
	const mega = k1 * k1;
	const giga = mega * k1;

	if (bitsPerSec > giga) {
		const numPart = bitsPerSec / giga;
		return String(Math.trunc(numPart * 10) / 10) + ' Gbits per second';
	}

	if (bitsPerSec > mega) {
		const numPart = bitsPerSec / mega;
		return String(Math.trunc(numPart * 10) / 10) + ' Mbits per second';
	}

	if (bitsPerSec > k5) {
		const numPart = bitsPerSec / k1;
		return String(Math.trunc(numPart * 10) / 10) + ' Kbits per second';
	}

	if (bitsPerSec > k1) {
		const numPart = bitsPerSec / k1;
		return String(Math.trunc(numPart * 10) / 10) + ' Kbits per second';
	}

	return String(Math.trunc(bitsPerSec * 10) / 10) + ' bits per second';
}


