import React, { useRef } from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { Chassis, ChassisModule } from '../../types/ProjectTypes';
import DetailsPowerUsage from './DetailsPowerUsage';
import { useSelectedDevice } from '../../context/SelectionInfoContext';
import { getChassisAltSAPwrSupplier } from '../../implementation/ImplGeneral';
import { PlatformCpLX } from '../../platforms/PlatformConstants';

export enum ChassisPowerType {
    chassis = 'chassis',
    modAsPSU = 'modAsPSU',
}

const getGenPSInfo = (chassis: Chassis): [catNo: string, desc: string] => {
    if (chassis.ps) {
        return [chassis.ps.catNo, chassis.ps.description];
    }
    else {
        return ['<none>', ''];
    }
}

interface Props {
    chassis: Chassis;
    powerType: ChassisPowerType;
}

const DetailsChassisPower = (props: Props) => {

    const [psCatInit, psDescInit] = getGenPSInfo(props.chassis);
    const psCat = useRef(psCatInit);
    const psDesc = useRef(psDescInit)
    const { selectedDevice, /*setSelectedDevice*/ } = useSelectedDevice();
    const modAsPwrSupplier = useRef<ChassisModule | undefined>(undefined);
    const title = useRef('Power Supply');

    modAsPwrSupplier.current = undefined;

    const saPwrInfo = getChassisAltSAPwrSupplier(props.chassis, selectedDevice);
    if (saPwrInfo) {
        // We have a chassis that is a SA/MOD power
        // 'snap' platform. Set the title.
        title.current = 'Power Supplied from...';
        // If the selected device NOT the Slot 0
        // power supplier...
        if (selectedDevice && selectedDevice.id !== saPwrInfo.saPowerSupplier?.id) {
            // We should have an CompactLogix FPD. If not...
            if (selectedDevice.platform !== PlatformCpLX) {
                // Just return null. We can get into this case
                // when we WERE on the power tab for a legit
                // power module, then clicked an I/O module.
                return null;
            }

            psCat.current = selectedDevice.catNo;
            psDesc.current = selectedDevice.description;
            modAsPwrSupplier.current = (selectedDevice as ChassisModule);
        }
        else {          
            if (saPwrInfo.saPowerSupplier) {
                psCat.current = saPwrInfo.saPowerSupplier.catNo;
                psDesc.current = saPwrInfo.saPowerSupplier.description;
                modAsPwrSupplier.current = saPwrInfo.saPowerSupplier;
            }
            else {
                psCat.current = 'Module Required: ';
                psDesc.current = 'A module is required in Slot 0 to supply power.';
            }
        }
    }

    return (
        <div className='floating-details-tab'>
            <div className='floating-details-chassis-ps-info'>
                <span className='a-type__body highlighted-item'>
                    {title.current}
                </span>
                <span className='a-type__body'>
                    {psCat.current}
                </span>
                <span className='a-type__body'>
                    {psDesc.current}
                </span>
            </div>
            {/*We need to differentiate the power display for a chassis*/}
            {/*opposed to a module that supplies power. Only assign 'module'*/}
            {/*when the caller is asking for a 'module as power supplier'.*/}
            <DetailsPowerUsage
                chassis={props.chassis}
                module={props.powerType === ChassisPowerType.modAsPSU ? modAsPwrSupplier.current : undefined}
            />
        </div>
    );
}

export default DetailsChassisPower;
