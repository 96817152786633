import { HardwareGenImplSpec, RegisterHardwareGenImpl } from "../../../implementation/ImplHardwareGen";
import { genCreateHardwareFromSettings, getCreateHWFromSettingsErrors } from "../../common/HardwareGen";
import { PlatformFlex } from "../../PlatformConstants";
import { snapGetLocAttrInfoForChassisEdit, snapPrepareLocAttrHardwareForGen } from "../../snap/snapGuidedSelection";


export const registerFlexHWGenImpl = () => {
	const impl: HardwareGenImplSpec = {
        //// General Functions  //////////////////////////////////////////////
        //    queryIOModules?: _implQueryIOModules;
        //    getIOPointFilterMap?: _implGetIOPointFilterMap;
        //    calcModQtyFromPoints?: _implCalcModQtyFromPoints;
        //    getInitialPointEntryInfo?: _implGetInitialPointEntryInfo;
        //    getIOModuleClosestMatch?: _implGetIOModuleClosestMatch;
        //    createDefaultPointEntry?: _implCreateDefaultPointEntry;
        //    validIOExists?: _implValidIOExists;
        //    getIOFilterMasksFromLoc?: _implGetIOFilterMasksFromLoc;
        //    getDefaultIOModuleCatalog?: _implGetDefaultIOModuleCatalog;
        //    finalizeGuidedSelection?: _implFinalizeGuidedSelection;
        //    convertGuidedSelAttrToAppVal?: _implGuidedSelAttrToAppVal;
        //    convertAppValToGuidedSelAttrOptID?: _implAppValToGuidedSelAttrOptID;
        //    getLocIOWiringTypeSel: GetIOModWiringSelectionForApp,

		platform: PlatformFlex,
        createHardwareFromSettings: genCreateHardwareFromSettings,
		getHardwareGenErrors: getCreateHWFromSettingsErrors,
		prepLocAttrHardwareForGen: snapPrepareLocAttrHardwareForGen,
		getLocAttrInfoForChassisEdit: snapGetLocAttrInfoForChassisEdit,
	}

	RegisterHardwareGenImpl(impl);
}