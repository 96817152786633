import React, { useEffect } from 'react';
import { Point } from '../types/SizeAndPosTypes';
import { PanelMessage } from '../types/MessageTypes';
import './FloatingMessagePanel.scss';
import MessagePanel, { DefaultFltMsgPanelTopOffset, DefaultFltMsgPanelWidth } from './MessagePanel';


const _dfltWidth = DefaultFltMsgPanelWidth;
const _dfltTopOffset = DefaultFltMsgPanelTopOffset;

interface Props {
    ptCenter: Point;
    messages: PanelMessage[];
    onClose: () => void;
}

const FloatingMessagePanel = (props: Props) => {

    const onCloseCallback = props.onClose;

    useEffect(() => {
        const handlePointerDown = (event: PointerEvent) => {
            event.stopPropagation();
            onCloseCallback();
        };

        document.addEventListener('pointerdown', handlePointerDown);

        return () => {
            document.removeEventListener('pointerdown', handlePointerDown);
        };
    }, [onCloseCallback]);

    const getDivProps = () => {
        return {
            left: props.ptCenter.x - (_dfltWidth / 2),
            top: props.ptCenter.y + _dfltTopOffset,
            minWidth: _dfltWidth,
            maxWidth: _dfltWidth,
        }
    }


    return (
        <div
            className='floating-message-panel-window'
            style={{ ...getDivProps() }}
        >
            <MessagePanel
                messages={props.messages}
                addPrettyTextClass={'pretty-text-alt-font-size'}
            />
        </div>
    )
}

export default FloatingMessagePanel;