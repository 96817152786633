import React from 'react';
import './VerticalTab.scss'
import '../../styles/Meteor.scss'
import ArrowIcon from '../../svg/VertTabArrow.svg'
import { Theme } from '@rockwell-automation-inc/ra-meatball';

interface Props {
    text: string;
    id: number;
    isSelected: boolean;
    onSelect: (id: number) => void;
}

const VerticalTab = (props: Props) => {

    const onClick = () => {
        props.onSelect(props.id);
    }

    const bgColor = props.isSelected ? Theme.palette.lightGrey.main : Theme.palette.white.main;

    return (
        <div
            className='vertical-tab'
            onClick={onClick}
        >
            <span
                className='a-type__body vertical-tab-text'
                style={{ backgroundColor: bgColor }}
            >
                {props.text}
            </span>
            {props.isSelected
                ? <img src={ArrowIcon} />
                : null
            }
        </div>
    );

}

export default VerticalTab;

