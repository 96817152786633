import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import { Layer, Stage, Rect, Group } from 'react-konva';
import '../styles/AppLayout.scss';
import { IdxRange, Point, Size } from '../types/SizeAndPosTypes';
import {
    //DebounceLayoutScrolling,
    DebugAids,
    //LogRender,
} from '../types/Globals';
import {
    //getEmptyLoc,
    getEmptyPt,
    getLocCenter,
    getOffset,
    getOffsetPoint,
    isZeroOffset,
    movedEnough,
} from '../util/GeneralHelpers';
import { StatusLevel } from '../types/MessageTypes';
import {
    Chassis,
    ChassisElement,
    ChassisModule,
    ChassisProject,
    MicroChassis,
    ModuleDragStatus,
    Rack,
    SelectableDevice
} from '../types/ProjectTypes';
import {
    alignStagePtOntoClient,
    AutoScaleFitType,
    autoScaleToContent,
    bumpScroll,
    canScroll,
    ChassisLayoutKey,
    getStagePointFromDOMPt,
    getStagePtInfo,
    getStageScale,
    getStageSizeProps,
    getViewClientPointFromDOMPt,
    LocRelToView,
    panStage,
    RegisterStageInfo,
    scrollToBottom,
    setStageOrigin,
    StageSizeProps,
    UnregisterStageInfo,
    updateStageSize,
    wheelZoomIn,
    wheelZoomOut,
} from '../util/LayoutHelp';
import { getActionBtnInfo, LayoutModeType } from '../util/LayoutModeHelp';
import {
    DragDeviceInfo,
    DropResult,
    DropStatus,
    getDropSwapInfo,
    makeModuleDragInfo,
    updateDragInfo
} from '../util/DragAndDropHelp';
import ChassisComp from '../components/ChassisComp';
import {
    dropDragDevice,
    getChassisLabelText,
    getChassisLoc,
    getChassisStatus,
    getContentAtPoint,
    getDropStatusForDrag,
    getModuleInSlot,
    getRack,
    resetDropTargets,
    tagDropTargets,
    updateProjContentExtent,
} from '../model/ChassisProject';
import DeviceDragComp from '../components/DeviceDragComp';
import { ActBtnInfo, LayoutActionType } from '../types/LayoutActions';
import {
    getRedCablePath,
    getRedChassisLoc,
    getRedDepictOpacity,
    getRedDepictOption,
    RedDepictOption
} from '../util/RedChassisHelp';
import { ChassisRendType } from '../types/ProjectTypes';
import { getChassisSelRect } from '../util/ChassisSelRectHelp';
import SelectedChassisRect from '../components/SelectedChassisRect';
import { confirmAndCallBack, displayAlertMsg } from '../util/MessageHelp';
import { ModalStatus } from '../modals/ModalHelp';
import FloatingDetails from '../components/details/FloatingDetails';
import FancyPath, { FancyPathProps } from '../components/cabling/FancyPath';
import { logger } from '../util/Logger';
import ChassisStatusIconBtn from '../components/layoutButtons/ChassisStatusIconBtn';
import ChassisLabel from '../components/ChassisLabel';
import ActionButtons from '../components/layoutButtons/ActionButtons';
import {
    useLayoutMode,
    useSelectedChassis,
    useSelectedDevice
} from '../context/SelectionInfoContext';
import { LaunchSelectCompsFor } from '../selectComponents/SelectCompsWrapper';
import { getChassisSizeAsDrawn, getSlotLocation } from '../implementation/ImplGeneral';
import DragInsertGraphic from '../components/DragInsertGraphic';


const _defaultCursor = 'default';
const _noCursor = 'none';
const _noDropCursor = 'no-drop';

// function from https://stackoverflow.com/a/15832662/512042
// (modified for Typescript)
// Used to snap a picture of our Stage (via ctrl-p)
function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const contentExtRectProps = {
    stroke: 'MediumBlue',
    strokeWidth: 1,
    strokeScaleEnabled: false,
    dash: [5, 3]
};

const getStageRectProps = (stageSizeProps: StageSizeProps) => {
    return {
        x: (-stageSizeProps.x) / stageSizeProps.scaleX,
        y: (-stageSizeProps.y) / stageSizeProps.scaleY,
        width: stageSizeProps.width / stageSizeProps.scaleX,
        height: stageSizeProps.height / stageSizeProps.scaleY,
        fill: '#D3FFFA',
        stroke: '#0000FF',
        strokeWidth: 1,
        strokeScaleEnabled: false
    };
}

enum DragStatus {
    Stopped = 'Stopped',
    Pending = 'Pending',
    Started = 'Started'
}

enum PanStatus {
    Stopped = 'Stopped',
    Pending = 'Pending',
    Started = 'Started'
}

enum CallbackAction {
    //AddModAtSLot = 'AddModAtSlot',
    //DeleteModAtSlot = 'DeleteModAtSlot',
    DropDragDevice = 'DropDragDevice'
}

interface actionCallbackData {
    action: CallbackAction;
    chassis?: Chassis;
    catNo?: string;
    slotNum?: number;
    dragDevice?: DragDeviceInfo;
    dropPt?: Point;
    dropCopy?: boolean;
}


export interface CallbackSizeChanged {
    callbackToFloatingDtls: (sz: Size) => void;
    callbackToPerformanceInfo: (sz: Size) => void;
}


interface Props {
    project: ChassisProject;
    requestModeAction: (actBtnInfo: ActBtnInfo) => void;
    contentChanged: () => void;
    onFirstVScroll: () => void;
}

const ChassisLayoutView = (props: Props) => {

    const { layoutMode, setLayoutMode, resetLayoutMode } = useLayoutMode();
    const { selectedChassis, selectChassis } = useSelectedChassis();
    const { selectedDevice, selectDevice } = useSelectedDevice();

    // Simple incremental state, used to force re-renders.
    const [renderCount, setRenderCount] = useState(0);

    // useRef to grab and keep what we get for an
    // onFirstVScroll in our props. Once set, we won't
    // change this as long as our component stays alive.
    const onFirstScrollCallback = useRef<() => void>(props.onFirstVScroll);

    // Counter that gets incremented on every render
    // of our layout. Useful when analyzing/debugging
    // performance issues.
    const numRenders = useRef<number>(0);

    const stageRegistered = useRef<boolean>(false);

    // Ref to outer <div> element in our render.
    const divRef = useRef<HTMLDivElement | null>(null);

    // Ref to outer <div> element in our render.
    const innerDivRef = useRef<HTMLDivElement | null>(null);

    const cursorType = useRef<string>(_defaultCursor);

    const firstVertScrollReported = useRef<boolean>(false);

    // Indication that we need to scroll to the bottom. 
    // This is used ONLY in the special case where
    // a NEW chassis was added via chassis config.
    const scrollToBtmReqstPending = useRef<boolean>(false);

    // The specific child callbacks in this reference are set
    // in FloatingDetails and FltgPerformanceInfo. Both are
    // then called when our ResizeObserver picks up a change.
    const callbackSizeChangeRef = useRef<CallbackSizeChanged>(
        {
            callbackToFloatingDtls: (sz: Size) => { sz; },
            callbackToPerformanceInfo: (sz: Size) => { sz; }
        })

    // Ref to our Konva <Stage>
    type StageRefHandle = React.ElementRef<typeof Stage>;
    const stageRef = useRef<StageRefHandle>(null);

    // Ref to the Rect component we show indicating
    // the full size of the Konva stage (viewport)
    // This is ONLY used if DebugAids.ShowStageBackground
    // is set to true.
    type RectRefHandle = React.ElementRef<typeof Rect>;
    const stageRectRef = useRef<RectRefHandle>(null);

    const [dropPt, setDropPt] = useState<Point>(getEmptyPt());

    const dragDevInfo = useRef<DragDeviceInfo | null>(null);

    const draggingStatus = useRef<DragStatus>(DragStatus.Stopped);
    const initDragPoint = useRef<Point>({ x: 0, y: 0 });
    const dragOffset = useRef<Point>({ x: 0, y: 0 });
    const dragModule = useRef<ChassisModule | null>(null);

    const panningStatus = useRef<PanStatus>(PanStatus.Stopped);
    const lastPan = useRef<Point>({ x: 0, y: 0 });

    const controlKeyDown = useRef<boolean>(false);
    const wheelZooming = useRef<boolean>(false);
    const wheelClientZoomPt = useRef<Point>({ x: 0, y: 0 });
    const wheelStageZoomPt = useRef<Point>({ x: 0, y: 0 });

    // NOTE. This and a number of useEffects immediately
    // following this one are set up to run ONE TIME, after
    // the first render. THIS one registers our Stage info,
    // which is required by the others below it. As such,
    // THIS ONE MUST BE COME FIRST.
    useEffect(() => {

        const outerDiv = divRef ? divRef.current : null;

        const registerStage = (wrapDiv: HTMLDivElement) => {
            logger.logCustom('registerStage called');

            if (!stageRegistered.current) {
                logger.logCustom('Calling RegisterStageInfo');
                RegisterStageInfo(wrapDiv, ChassisLayoutKey);
                stageRegistered.current = true;
                setRenderCount(renderCount => renderCount + 1);
            }
            else {
                logger.logCustom('skipped?');
            }
        }

        // If we have our div ref...
        if (outerDiv) {

            // Register the stage.
            registerStage(outerDiv);

            // When our layout component is dismounted,
            // UN-register the stage info.
            return () => {
                logger.logCustom('Layout dismount - Unregistering Stage Info for: ' + ChassisLayoutKey);
                UnregisterStageInfo(ChassisLayoutKey);
                stageRegistered.current = false;
            }
        }
 
        // NOTE: We want to BE SURE that this useEffect gets
        // hit only ONCE, after the FIRST render of our component.
        // As such, the [] below NEEDS TO STAY EMPTY.
    }, []);

    // Resize observation for outer div.
    useEffect(() => {

        const outerDiv = divRef ? divRef.current : null;
        const theStage = stageRef ? stageRef.current : null;

        const observer = new ResizeObserver(entries => {

            if (outerDiv && theStage) {

                //logger.logCustom('scrollHeight: ' + outerDiv.scrollHeight);

                // Our outer div was resized. Get the
                // associated rect (client area).
                const clientRect = entries[0].contentRect;

                // Get a Size from it.
                const viewSize: Size = {
                    width: clientRect.width,
                    height: clientRect.height
                };

                // Call a helper to update our stage's visible size,
                // using the view size. The stage size will always be
                // a little smaller than our client area. The helper
                // returns the new size and a boolean telling us
                // whether the new one is different from what we 
                // already had.
                const [sizeChanged, visibleSize] = updateStageSize(ChassisLayoutKey, viewSize);

                // If the new size is different...
                if (sizeChanged) {

                    //logger.logCustom('sizeChanged: ' +
                    //    visibleSize.width + 'w, ' +
                    //    visibleSize.height + 'h');

                    // Notify our 'floating' windows for Details and
                    // Performance Info that our view size has changed.
                    callbackSizeChangeRef.current.callbackToFloatingDtls(visibleSize);
                    callbackSizeChangeRef.current.callbackToPerformanceInfo(visibleSize);

                    // NOTE: An attempt was made to directly tweak our
                    // Stage here, similarly to what we do in our
                    // scroll handler below, but tweaking size instead
                    // of origin and transform/translate. However, doing
                    // it that way caused our ResizeObserver to throw
                    // errors sometimes. Scrolling can cause resizing
                    // and vice-versa. The errors thrown seemed to indicate
                    // cases where the observer was already reacting to
                    // and processing a size change when it got another
                    // one. To get around the issue completely and cleanly,
                    // we just trigger a re-render when our size changes
                    // instead. Resizes aren't as common as scrolling,
                    // and re-renders are now extremely fast, since our
                    // chassis components are all memo-ized.
                    setRenderCount(renderCount => renderCount + 1);
                }

                // If haven't ever reported that
                // vertical scrolling is possible...
                if (!firstVertScrollReported.current) {

                    // Check our div to see if it is. If so...
                    if (outerDiv.scrollHeight > outerDiv.clientHeight) {

                        // Call the function we initially got as a 
                        // prop for 'first-scroll' notification. The
                        // function itself MAY end up collapsing the
                        // RA header above us(if its still expanded,
                        // and the user hasn't already manually
                        // expanded/collapsed it).
                        onFirstScrollCallback.current();

                        // Remember that we've done this so we
                        // don't ever do it again. 
                        firstVertScrollReported.current = true;
                    }
                }
            }
        });

        if (outerDiv) {

            //logger.logCustom('Observing resize');
            observer.observe(outerDiv);

            return () => {
                //logger.logCustom('UN-Observing resize');
                observer.unobserve(outerDiv);
            }
        }

        // NOTE: We want to BE SURE that this useEffect gets
        // hit only ONCE, after the FIRST render of our component.
        // As such, the [] below NEEDS TO STAY EMPTY. If not,
        // we observe and then unobserve on EVERY RENDER.
    }, []);

    // Resize observation for inner div. This div's size is set to the
    // size of our virtual stage. We need this ONLY for one special case,
    // when a new chassis is added via the 'add chassis' function.
    // When that happens, our stage info gets its .scrollToBtmPending
    // flag set. Our view then gets notified of that when we call
    // getStageSizeProps. The problem, however, is that even though
    // we know that our inner (virtual) content is now larger than it
    // had been, our outer div's .scrollHeight isn't yet set to match,
    // and it's STILL not matched even after we trigger a re-render.
    // So, here we watch for changes in the size of that inner content.
    useEffect(() => {

        const innerDiv = innerDivRef ? innerDivRef.current : null;

        const observer = new ResizeObserver(entries => {

            entries;

            // If have our ref AND we outstanding request
            // to scroll to the bottom...
            if (innerDiv && scrollToBtmReqstPending.current) {

                // Reset our pending flag.
                scrollToBtmReqstPending.current = false;

                // And call a helper to handle the rest.
                // logger.warn('calling scrollToBottom');
                scrollToBottom(ChassisLayoutKey,);
            }
        });

        if (innerDiv) {

            //logger.logCustom('Observing virtDiv resize');
            observer.observe(innerDiv);

            return () => {
                //logger.logCustom('UN-Observing virtDiv resize');
                observer.unobserve(innerDiv);
            }
        }

        // NOTE: We want to BE SURE that this useEffect gets
        // hit only ONCE, after the FIRST render of our component.
        // As such, the [] below NEEDS TO STAY EMPTY. If not,
        // we observe and then unobserve on EVERY RENDER.
    }, []);


    useEffect(() => {

        const outerDiv = divRef ? divRef.current : null;
        const theStage = stageRef ? stageRef.current : null;

        const scrollHandler = () => {

            //logger.logCustom('scrollHandler');

            // We need our refs to our our outer
            // div and stage. If we have them...
            if (outerDiv && theStage) {

                // Here, we'll make adjustments necessary to
                //  1. Keep the stage fully in the viewport
                //  2. Shift stage's origin point so we'll see
                //     its content move to match scroll.
                //  3. Do it WITHOUT requiring a re-render
                //     of our view.


                // Get the current scroll positions
                // of our outer div.
                const left = outerDiv.scrollLeft;
                const top = outerDiv.scrollTop;

                //logger.logCustom('scrollHandler top: ' + top);

                // Our stage origin ordinates will always be the
                // those numbers * -1. For example, if the height
                // ABOVE our visible viewport is 100px, then the
                // top (.scrollTop) value will be 100. In that case,
                // we want the top of what we can see to be 100. To
                // get that, we set the .y prop to -100.

                // Call a helper to set our new stage origin.
                setStageOrigin(ChassisLayoutKey, -left, -top);

                // Apply css transform to the stage's container. If
                // we inspect the elements in a running system, we can
                // see that the container here is a div ABOVE the
                // "konvajs-content" div, and BELOW our inner div.
                // This keeps the stage in the viewport.
                const stageContainer = theStage.container();
                stageContainer.style.transform =
                    `translate(${left}px, ${top}px)`;

                // Set the stage's .x and .y props
                // to match the new origin.
                theStage.x(-left);
                theStage.y(-top);

                // If we're showing the stage background rect...
                if (DebugAids.ShowStageBackground && stageRectRef.current) {

                    //// Get the current stage scale.
                    const scale = getStageScale(ChassisLayoutKey,);

                    // Our rect is using stage units. Set
                    // its new origin point to our new
                    // position (scaled).
                    stageRectRef.current.x(left / scale);
                    stageRectRef.current.y(top / scale);
                }
            }
        }

        if (outerDiv) {

            //logger.logCustom('Adding scroll event listener');
            outerDiv.addEventListener('scroll', scrollHandler);

            scrollHandler();

            return () => {
                //logger.logCustom('Removing scroll event listener');
                outerDiv.removeEventListener('scroll', scrollHandler);
            }
        }

        // NOTE: We want to BE SURE that this useEffect gets
        // hit only ONCE, after the FIRST render of our component.
        // As such, the [] below NEEDS TO STAY EMPTY. If not,
        // we add and then remove our listener on EVERY RENDER.
    }, []);


    useEffect(() => {
        const outerDiv = divRef ? divRef.current : null;

        const handleWheel = (e: WheelEvent) => {
            // We only want to handle the event if the
            // control key is down. Otherwise just return.
            //if (controlKeyDown.current === false) {
            //    return;
            //}
            if (!e.ctrlKey) {
                return;
            }

            // If we're still here, we KNOW that we got a
            // wheel event AND that the Ctrl key is currently
            // down. However, we DON'T know if our application
            // even has focus. Regardless, we want to control
            // what happens, and prevent anyone above us from
            // getting the wheel event.
            e.preventDefault();
            e.stopPropagation();

            // Now we'll check our entire app for focus.
            // If we DON'T have it, we'll just bail out
            // and do nothing. Essentially, we're saying
            // that we'll ONLY do Ctrl-Wheel zooming if
            // SOMETHING we know about has focus.
            //if (!document.hasFocus()) {
            //    return;
            //}

            // If we haven't already 'started'
            // wheel-zooming yet...
            if (!wheelZooming.current) {

                // Get our pointer location in DOM
                // coordinates.
                const ptDOM: Point = {
                    x: e.clientX,
                    y: e.clientY
                };

                //logger.logCustom('WheelZoom starting');
                //logPoint(ptDOM, 'ptDom');

                // Use that to get the location in the view client area.
                const ptClient = getViewClientPointFromDOMPt(ChassisLayoutKey, ptDOM);
                //logPoint(ptClient, 'ptClient');

                // Get the DOM pt converted to stage coordinates.
                const ptStg = getStagePointFromDOMPt(ChassisLayoutKey, ptDOM);
                //logPoint(ptStg, 'Stage pt calc');

                //logLayoutInfo(true, true);

                // Lock in that point as what we want to use
                // as a zoom pt (center of our zoom area).
                wheelStageZoomPt.current = ptStg;

                // And remember our client point.
                wheelClientZoomPt.current = ptClient;

                // Turn on wheel-zooming.
                wheelZooming.current = true;
            }

            // Zoom in or out based on the wheel
            // direction. In either case, we'll pass
            // in the SAME zoom center pt that we chose
            // when we started wheel-zooming.
            if (e.deltaY < 0) {
                if (wheelZoomIn(ChassisLayoutKey, wheelStageZoomPt.current)) {
                    setRenderCount(renderCount => renderCount + 1);
                }
            }
            else {
                if (wheelZoomOut(ChassisLayoutKey, wheelStageZoomPt.current)) {
                    setRenderCount(renderCount => renderCount + 1);
                }
            }
        }

        if (outerDiv) {
            //logger.logCustom('Add wheel listener');
            outerDiv.addEventListener('wheel', handleWheel , { passive: false });
        }

        return () => {
            if (outerDiv) {
                //logger.logCustom('Remove wheel listener');
                outerDiv.removeEventListener('wheel', handleWheel);
            }
        }
        // The [] below NEEDS TO STAY EMPTY. If not,
        // we add and then remove our listener on EVERY RENDER.
    }, []);


    // Note: Pointer events come from the INNER div.
    // All capture related functions need to use the
    // innerDivRef accordingly.

    const haveCapture = (pointerId: number): boolean => {
        if (innerDivRef.current) {
            if (innerDivRef.current.hasPointerCapture(pointerId)) {
                return true;
            }
        }
        return false;
    }

    const capturePointer = (pointerId: number) => {
        if (innerDivRef.current) {
            if (!innerDivRef.current.hasPointerCapture(pointerId)) {
                innerDivRef.current.setPointerCapture(pointerId);
            }
            else {
                throw new Error('Attempt to capture pointer when ALREADY captured!');
            }
        }
    }

    const releaseCapture = (pointerId: number) => {
        if (innerDivRef.current) {
            if (innerDivRef.current.hasPointerCapture(pointerId)) {
                innerDivRef.current.releasePointerCapture(pointerId);
            }
        }
    }

    const getDragStatus = (): DragStatus => {
        return draggingStatus.current;
    }

    const setDragStatus = (status: DragStatus) => {
        draggingStatus.current = status;
    }

    const getPanStatus = (): PanStatus => {
        return panningStatus.current;
    }

    const setPanStatus = (status: PanStatus) => {
        panningStatus.current = status;
    }

    const updateDrag = useCallback((ptStage: Point, copy: boolean) => {
        // If our drag status is 'pending' (which it
        // CAN only be for a potential drag move or
        // copy of an existing chassis module)...
        if (getDragStatus() === DragStatus.Pending) {

            // Then dragging hasn't really started. See if
            // our new point is different (enough) from where
            // we started. If so...
            if (movedEnough(ptStage, initDragPoint.current)) {

                // We SHOULD have saved the module we were
                // pointing at when we initiated the (pending)
                // drag move. If we do...
                if (dragModule.current) {

                    // Make a drag info object using the module.
                    dragDevInfo.current = makeModuleDragInfo(dragModule.current, copy);

                    // We should now be completely prepped
                    // for our ACTUAL drag move. Set our
                    // status to Started.
                    setDragStatus(DragStatus.Started);

                    tagDropTargets(props.project.content, dragModule.current)
                    setLayoutMode(LayoutModeType.Drag);
                }
                else {
                    throw new Error('ERROR in updateDragMove - Pending status with NO drag module!');
                }
            }
        }

        // If dragging is (now) underway...
        if (getDragStatus() === DragStatus.Started) {

            // Then we SHOULD have a drag info object. If so...
            if (dragDevInfo.current) {

                // Using the stage point we were given, get that
                // point offset by our dragOffset, which was the
                // initial distance from the starting stage point
                // we got in onPointerDown to the center of the
                // module we were over.U s
                const ptMod = getOffsetPoint(ptStage, dragOffset.current);

                // Use that adjusted point to position our 
                // drag image. If we don't do the offset,
                // the drag image initially jumps to be centered
                // on wherever the pointer happens to be. That is,
                // the drag image appears to initially JUMP by 
                // however big that initial offset was.
                updateDragInfo(dragDevInfo.current, ptMod, copy);

                // And update its drop status.
                dragDevInfo.current.dropStatus =
                    getDropStatusForDrag(props.project.content, dragDevInfo.current);

                // If we're dragging an existing module...
                if (dragModule.current) {

                    // Determine what its drag status should be.
                    const dragStat = copy
                        ? ModuleDragStatus.Copying
                        : ModuleDragStatus.Moving;

                    // If different than what it's currently set to...
                    if (dragModule.current.dragStatus !== dragStat) {

                        // Set its new status.
                        dragModule.current.dragStatus = dragStat;

                        // Trigger a chassis re-render so that our drag source
                        // module gets a chance to render with a different look.
                        // Note that we won't use chassisChanged to do that for
                        // us, and will bump it ourselves, as we DON'T want to
                        // set our config modified flag. We're just dragging, 
                        // and haven't actually changed anything yet.
                        if (dragModule.current.parent) {
                            dragModule.current.parent.bump++;
                        }
                    }
                    // Update its .dragStatus property.
                    dragModule.current.dragStatus = copy
                        ? ModuleDragStatus.Copying
                        : ModuleDragStatus.Moving;
                }

                // Set our dropPt state to
                // force a re-render of our
                // dragged component, etc.
                setDropPt(ptStage);
            }
            else {
                logger.warn('updateDrag without dragDevInfo?');
            }
        }
    }, [props.project.content, setLayoutMode]);


    const updateDragCopyStatus = useCallback((copy: boolean) => {
        // If we have a drag in progress...
        if (getDragStatus() === DragStatus.Started) {

            // Then we SHOULD have drag info. If so, AND if
            // the info's copy prop does NOT match the incoming
            // 'copy' spec, do an 'EXTRA' drag update. That should
            // get our rendering updated even if the pointer
            // (mouse) didn't actually move.
            if (dragDevInfo.current && (dragDevInfo.current.copy !== copy)) {
                updateDrag(dropPt, copy);

                // Normally, updateDrag causes a re-render when
                // it sets a new value for the dropPt state. Here,
                // however, we're using the SAME dropPt, so we'll
                // force our own render.
                setRenderCount(renderCount + 1);
            }
        }
    }, [dropPt, renderCount, updateDrag]);

    const finishWheelZoom = useCallback(() => {
        // Wheel zooming is done, triggered by
        // the release of the Ctrl key.

        //logger.logCustom('finishWheelZoom');

        // When we started, we saved the cursor location in
        // two coordinate variations, stage units and view client.
        // Call a helper now to RE-ALIGN those two points.
        alignStagePtOntoClient(ChassisLayoutKey,
            wheelStageZoomPt.current, wheelClientZoomPt.current);

        // Set our status to 'not running'.
        //console.log('wheelZooming OFF');
        wheelZooming.current = false;

        // Trigger a re-render for posterity.
        //setRenderCount(renderCount => renderCount + 1);
    }, [wheelZooming])

    const theProject = props.project;

    // Note: We COULD get onKeyUp events easier with a simple
    // handler hooked to the onKeyup prop of our outer div. When
    // we do that, however, we don't get the event unless our
    // view itself had focus at the time of the corresponding
    // keydown. This way, we'll get it as long as something
    // withing our parent document had focus.
    useEffect(() => {

        // By including our handler function definition
        // INSIDE of our useEffect, we can simplify it.
        // If we called an outside function, it'd have
        // to be in a useCallback.
        const handleKeyUp = (evt: KeyboardEvent) => {

            //console.log('handleKeyUp');

            if ((evt.key === 'Escape') &&
                (layoutMode.type !== LayoutModeType.Normal)) {
                resetLayoutMode();
                updateProjContentExtent(theProject, false);
                return;
            }

            // We ONLY care about the Ctrl key itself.
            // If that's the key that came up...
            if (evt.key === 'Control') {
                //console.log('controlKeyDown set FALSE');
                controlKeyDown.current = false;

                // Then we have two different operations that
                // MIGHT have been in progress. If neither is
                // we don't do anything.
                // If a drag is in progress...
                if (getDragStatus() === DragStatus.Started) {

                    // Then update its 'copy' status. The
                    // dragged image renders itself differently
                    // if a copy (Ctrl key down) or not (Ctrl
                    // key up). We just got the 'up'.
                    updateDragCopyStatus(false);
                }
                // Not dragging. If we were zooming
                // via the mouse wheel (with the Ctrl
                // key down)...
                else if (wheelZooming.current) {
                    // a helper to finalize it.
                    finishWheelZoom(); 
                }
            }
        };

        // attach the event listener
        document.addEventListener('keyup', handleKeyUp);

        // remove the event listener
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [finishWheelZoom,
        updateDragCopyStatus,
        layoutMode.type,
        resetLayoutMode,
        theProject]);

    // Callback function called (from our Modal system).
    // Used for various actions that require user confirmation.
    const confirmActionCallback = (status: number, data?: object) => {

        // If the user confirmed the action...
        if (status === ModalStatus.Confirmed) {

            // Cast the data we were given to 
            // our expected object type.
            const info = data as actionCallbackData;

            // If we can...
            if (info) {

                // Then decide what to do based on the
                // action included in our data package.
                switch (info.action) {

                    // Dropping a device of some sort...
                    case CallbackAction.DropDragDevice:

                        // If we have all of the expected (and
                        // required) data included in our package...
                        if (info.dragDevice && info.dropPt &&
                            (info.dropCopy !== undefined)) {

                            // Call our drop function using the saved info.
                            // Note that here, we specify true for the
                            // autoConfirm argument. We ALREADY GOT confirmation
                            // from the user, so the dropDrag function won't
                            // have to do that again.
                            dropDrag(info.dragDevice, info.dropPt, info.dropCopy, true);
                        }
                        else {
                            throw new Error('confirmActionCallback missing reqd data for DropDragDevice!');
                        }
                        break;

                    default:
                        break;
                }
            }
            else {
                throw new Error('confirmActionCallback with invalid data!');
            }
        }
    }

    const stopDrag = () => {
        setDragStatus(DragStatus.Stopped);
        dragDevInfo.current = null;

        if (dragModule.current) {
            dragModule.current.dragStatus = ModuleDragStatus.NA;
            dragModule.current = null;
        }

        setDropPt(getEmptyPt());
    }

    // Prior to actually dropping a device being dragged, our
    // caller has determined that user confirmation is required.
    const prepForDropRequiringConfirmation = (
        dragDev: DragDeviceInfo,
        dropPt: Point,
        dropCopy: boolean,
        confirmMsg: string
    ) => {

        // Set up our callback data object with everything
        // we'll need to actually proceed with the drop if
        // we DO get a confirmation.
        const callbackData: actionCallbackData = {
            action: CallbackAction.DropDragDevice,
            dragDevice: dragDev,
            dropPt: { ...dropPt },
            dropCopy: dropCopy
        };

        // Hand off to our confirm helper. When the
        // resulting modal has been confirmed or cancelled,
        // our callback function will be called, and we'll
        // get passed the result along with our data object.
        confirmAndCallBack(confirmMsg, confirmActionCallback, callbackData, 'Please Note');
    }

    // Function called for all drops of a module,
    // moved or copied from inside of our layout.
    // Note: We do NOT want to use our dragDevInfo (useRef)
    // here, since it MAY not be there anymore in some cases.
    const dropDrag = (
        dragDev: DragDeviceInfo,
        dropPt: Point,
        copy: boolean,
        autoConfirm: boolean) => {

        // Gatekeeper step...
        // Check the drop status. Based on that...
        switch (dragDev.dropStatus) {

            // DropOk indicates that we have a
            // PREQUALIFIED module drop (move or copy).
            // DropOk's immediately get a 'pass' here.
            // Break and continue below.
            case DropStatus.DropOk:
                break;

            // NoDrop means we've ALREADY determined in
            // updateDrag that the module being dragged
            // CANNOT POSSIBLY be dropped at the target pt.
            // For these, we'll immediately return NoDrop.
            case DropStatus.NoDrop:
                return;

            // DropOkAfterSwap means that we have a module drop
            // that WILL work, but not until we swap the dragged
            // module with an env-matching counterpart.
            case DropStatus.DropOkAfterSwap:
                // If we were told to autoConfirm...
                if (autoConfirm) {
                    // then our caller has already given
                    // us confirmation. Let this one pass.
                    break;
                }
                else {
                    // We need to get user confirmation before
                    // continuing. Call a helper to get us a
                    // suitable confirmation message.
                    const [swapOk, confirmMsg] = getDropSwapInfo(dragDev);

                    // The DropOkAfterSwap status implies that a
                    // swap SHOULD be Ok here. If so...
                    if (swapOk) {
                        // Hand off to our drop confirmer, and then
                        // just return. If the user DOES confirm the
                        // drop, we'll end up getting called again,
                        // but with our autoConfirm arg set to true.
                        prepForDropRequiringConfirmation(dragDev, dropPt, copy, confirmMsg);
                        return;
                    }
                    else {
                        // Unexpected.
                        throw new Error('Unexpected swap result in dropDrag!');
                    }
                }
                break;

            default:
                throw new Error('Invalid DropStatus in isDropAcceptable')
        }

        // If we get here, we SHOULD have a valid drop, either
        // already a DropOk, OR a swap case that's ALREADY been 
        // confirmed. Sanity check.
        switch (dragDev.dropStatus) {
            case DropStatus.DropOk:
                break;

            case DropStatus.DropOkAfterSwap:
                if (!autoConfirm) {
                    throw new Error('Unexpected dropDrag not autoConfirm!');
                }
                break;

            default:
                throw new Error('Unexpected dropDrag dropStatus!');
        }

        // Then call a helper to attempt the drop.
        const dropRslt = dropDragDevice(props.project, dragDev);

        // Act specifically based on result...
        switch (dropRslt) {

            // If the drop was successful...
            case DropResult.DropSuccess:

                // If we were copying an existing module,
                // make sure we have no selected module.
                if (dragDev.copy) {
                    selectDevice(undefined);
                }

                // Notify our parent that
                // content was changed.
                props.contentChanged();
                break;

            default: // DropFailed
                break;

            // We should NEVER get a ConfirmReqd status back.
            case DropResult.ConfirmReqd:
                throw new Error('Unexpected status returned from dropDragDevice!');
        }
    }

    const initiateDragMove = (module: ChassisModule, pt: Point) => {
        // Sanity check. We should NOT have been called
        // unless the current drag move status is Stopped.
        // If it's not, stop any current move and error out.
        if (dragDevInfo.current ||
            (getDragStatus() !== DragStatus.Stopped)) {
            logger.error('ERROR: initiateDragMove called with unexpected status!');
            stopDrag();
            return;
        }

        // Record the incoming point as the
        // 'initial' drag point.
        initDragPoint.current = pt;

        // And the actual comp that will
        // (potentially be moved).
        dragModule.current = module;

        // Set the drag status to Pending.
        // Actual drags don't really do anything
        // until the staus gets changed to Started.
        setDragStatus(DragStatus.Pending);

        // For dragging, we typically don't want
        // a cursor present over our dragged image.
        cursorType.current = _noCursor;
    }

    // NOTE: initiate assumes that caller has
    // ALREADY pre-qualified that panning is
    // actually possible.
    const initiatePan = (pt: Point) => {
        // Sanity check. We should NOT have been called
        // unless the current drag move status is Stopped.
        // If it's not, stop any current move and error out.
        const panStatus = getPanStatus();
        if (panStatus !== PanStatus.Stopped) {
            logger.error('ERROR: initiatePan called with unexpected status: ' + panStatus);
            stopPan();
            return;
        }

        // Record the incoming point as
        // the 'initial' last pan point.
        lastPan.current = { ...pt };

        // Set the drag pan status to Pending.
        // Actual pans don't really do anything
        // until the status gets changed to Started.
        setPanStatus(PanStatus.Pending);
    }

    const updatePan = (pt: Point) => {
        // If our status is still Pending...
        if (getPanStatus() === PanStatus.Pending) {
            // Then check to see if the point has 'moved
            // enough' from where we started in order to
            // really 'start' a drag move. If it hasn't,
            // We'll just leave the status at Pending.
            // If it HAS moved enough...
            if (movedEnough(pt, lastPan.current)) {
                setPanStatus(PanStatus.Started);
            }
        }

        // If our move status is Started...
        if (getPanStatus() === PanStatus.Started) {
            // Determine the offset of the new pt from the
            // last one we looked at. Remember that these were
            // CLIENT points, NOT stage-relative. 
            const offset = getOffset(lastPan.current, pt);

            // If our y moved enough to actually change
            // the view's y position...
            if (!isZeroOffset(offset)) {
                // Set the new pt as the new 'last' one.
                lastPan.current = { ...pt };

                // Then call a helper to make any actual
                // scroll adjustments needed to pan our view.
                panStage(ChassisLayoutKey, offset);
            }
        }
    }

    const stopPan = () => {
        // If our status isn't ALREADY Stopped, do so.
        if (getPanStatus() !== PanStatus.Stopped) {
            setPanStatus(PanStatus.Stopped);
            lastPan.current = { x: 0, y: 0 };
        }
    }

    const getDeviceAtPt = (pt: Point):
        [
            chassis: Chassis | undefined,
            module: ChassisModule | undefined,
            other: SelectableDevice | undefined,
            onRed: boolean
        ] => {

        const [chassis, element, slot,] =
            getContentAtPoint(props.project.content, pt);
        if (chassis) {
            const microChassis = chassis as MicroChassis;
            switch (element) {
                case ChassisElement.PS:
                    return [chassis, undefined, chassis.ps, false];
                // Base unit case for micro 800
                case ChassisElement.BU:
                    return [chassis, undefined, microChassis.bu, false];
                case ChassisElement.Slot:
                    return [chassis, getModuleInSlot(chassis, slot), undefined, false];

                default:
                    return [chassis, undefined, undefined, (element === ChassisElement.RedChassis)];
            }
        }
        return [undefined, undefined, undefined, false];
    }

    const isMovingOrPanning = (): [moving: boolean, panning: boolean] => {
        const moving = (getDragStatus() !== DragStatus.Stopped);
        const panning = (getPanStatus() !== PanStatus.Stopped);
        if (moving && panning) {
            throw new Error('Invalid - moving AND panning???');
        }
        return [moving, panning];
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        let handled = false;

        // If the key that went down was
        // itself the Ctrl key...
        if (e.key === 'Control') {
            //console.log('controlKeyDown set TRUE');
            controlKeyDown.current = true;

            // Call a helper to update the copy status
            // of a drag IFF we have one in progress.
            if (getDragStatus() === DragStatus.Started) {
                updateDragCopyStatus(true);
                handled = true;
            }
        }
        else {
            // Otherwise, if the Ctrl key is (also) down...
            if (e.ctrlKey) {
                switch (e.key) {
                    case 'P': // Ctrl-p
                    case 'p':
                        if (stageRef && stageRef.current) {
                            const uri = stageRef.current.toDataURL();
                            downloadURI(uri, 'stage.png');
                        }
                        handled = true;
                        break;

                    case 'X': // Ctrl-x (cut/delete)
                    case 'x':
                        // Our chassis project currently does NOT
                        // support deletes using a ctrl-x shortcut.
                        // If/when we add that (for something like
                        // a selected module), we'd handle that here.
                        handled = true;
                        break;

                    default:
                        logger.log('layout ignoring ctrl plus key: ' + e.key);
                        break;
                }
            }
        }

        if (handled) {
            e.preventDefault();
            e.stopPropagation();
        }
    };


    // NOTE: We handle the pointer events we're interested
    // in via the outer Div, and NOT the Stage. Using the
    // div allows us to setPointerCapture.
    const onPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {

        // Note that if the user put the pointer down on a
        // chassis label (name), selection of that chassis
        // takes place via that control. We won't get
        // that onPointerDown here.

        // Start by getting the stage location of the pointer.
        const ptDOM = { x: e.clientX, y: e.clientY };
        const pt = getStagePointFromDOMPt(ChassisLayoutKey, ptDOM);

        //logPoint(ptDOM, 'Event pt');
        //logPoint(pt, 'Stage pt');

        // Call a helper to tell us what we're pointing at,
        // if anything. The otherSelectable is used when we're
        // pointing at something like a power supply, which IS
        // selectable, but is NOT movable (draggable).
        const [chassis, module, otherSelectable, onRed] = getDeviceAtPt(pt);

        // If we're pointing at anything chassis related, we'll
        // always get a chassis back. If we did, and we're NOT
        // in 'normal' layout mode, just return. If we're NOT
        // in normal layout mode, but DIDN'T get a chassis, we'll
        // keep going in case the user wants to pan the layout.
        if (chassis && (layoutMode.type !== LayoutModeType.Normal)) {
            return;
        }

        if (onRed) {
            displayAlertMsg('Click on Primary to make selections',
                StatusLevel.Info,
                'Redundant Chassis');
            return;
        }

        // Set capture. As long as we have capture,
        // we'll continue to get pointer events even
        // when the pointer leaves our div client area,
        // or even when it leaves the browser window.
        capturePointer(e.pointerId);

        // If we're pointing at a module...
        if (chassis && module) {
            const chassisLoc = getChassisLoc(chassis);

            // Get its location on the stage.
            const slotLoc = getSlotLocation(chassis, module.slotIdx)

            // Get the center point from that loc.
            // We'll get a center point, but it will
            // be local to the chassis itself.
            const ptModCtr = getLocCenter(slotLoc);

            // Offset it using the chassis upper
            // left point to get the stage coord.
            ptModCtr.x += chassisLoc.x;
            ptModCtr.y += chassisLoc.y;

            // And save the offset between its center 
            // and the stage point we're starting at.
            dragOffset.current = getOffset(pt, ptModCtr);
        }

        // Inform our parent about what chassis we're on (if any).
        selectChassis(chassis);

        // If we have a module, and it's ALREADY selected,
        // we'll 'initiate' a drag-move. That doesn't
        // actually start one.Instead, it sets our drag
        // move status to Pending. The actual start
        // doesn't/won't occur unless the mouse actually
        // moves far enough.
        if (module && module.selected) {
            logger.logCustom('call initiateDragMove 1 from onPointerDown');
            initiateDragMove(module, pt);
            return;
        }

        // If we're still here, determine which device,
        // if anything should be selected.
        const devToSelect = module ? module : otherSelectable;

        // Call our device selection helper with
        // the whatever we have (or null if we weren't
        // pointing at anything selectable).
        selectDevice(devToSelect);

        // If we a module, initiate a
        // drag move. See comment above.
        if (module) {
            logger.logCustom('call initiateDragMove 2 from onPointerDown');
            initiateDragMove(module, pt);
        }
        else {
            // Since we can't move what we were
            // pointing at, if anything, see if 
            // ANY scrolling is possible. If so,
            // initiate a possible drag 'pan'.
            if (canScroll(ChassisLayoutKey,)) {
                logger.logCustom('call initiatePan from onPointerDown');
                initiatePan({ x: e.clientX, y: e.clientY });
            }
        }
    }

    const onPointerMove = (e: React.PointerEvent<HTMLDivElement>) => {

        // Check to see if we have capture of the
        // pointer. If not, we can't be doing anything
        // related to moving or panning, so just return.
        if (!haveCapture(e.pointerId)) {
            return;
        }

        // Check if we're either moving or panning.
        const [moving, panning] = isMovingOrPanning();

        // Unless all drag status shows 'Stopped', then ONE
        // should either be Pending or Started. In either
        // case, we want to report to our associated update function.
        // If still pending, the update will ignore any
        // requests until the point has actually moved enough
        // from the start point to BE a real drag. When it
        // sees enough movement, updates will handle
        // the ACTUAL start and all that it entails.
        // So, if any sort of drag is happening...
        if (moving || panning) {

            // If we're moving...
            if (moving) {

                // Get the stage location of the pointer,
                // along with the point's relative location
                // in the view (visible, left, above, etc.).
                const [pt, relLoc, dirScrollOk] =
                    getStagePtInfo(ChassisLayoutKey, { x: e.clientX, y: e.clientY });

                // Update the cursor type. Normally, we want no cursor
                // during dragging. But, if the current point is off of
                // our view, set it to the 'no-drop' cursor instead.
                cursorType.current = (relLoc === LocRelToView.Visible)
                    ? _noCursor : _noDropCursor;

                // Update our drag element.
                updateDrag(pt, e.ctrlKey);

                // If the pointer was OFF of the view's client
                // area itself (above, to the left, etc.), AND
                // the current scroll position would allow us
                // to 'move' the viewport in that direction, the
                // dirScrollOk we get above is true. If so...
                if (dirScrollOk) {

                    // Call a helper to 'bump' our scroll
                    // position a bit in that direction.
                    bumpScroll(ChassisLayoutKey, relLoc);
                }
            }
            else {
                // Must be panning.
                // Update it with new location.
                updatePan({ x: e.clientX, y: e.clientY });
            }
            return;
        }
    }

    const onPointerUp = (e: React.PointerEvent<HTMLDivElement>) => {

        // Check to see if we have capture of the
        // pointer. If not, we can't be doing anything
        // related to moving or panning, so just return.
        if (!haveCapture(e.pointerId)) {
            return;
        }

        // Release our capture. We're done with it.
        releaseCapture(e.pointerId);

        // Turn the cursor back 'on'.
        cursorType.current = _defaultCursor;

        // Check if we're either moving or panning.
        // Note: returns indicate whether a move or
        // pan is NOT-stopped or stopped. That is, if
        // a status is pending, we get a true return.
        const [moving, panning] = isMovingOrPanning();

        // If we got a hit on a non-stopped moving state...
        if (moving) {

            // Then, if we we actually started...
            if (getDragStatus() === DragStatus.Started) {

                // We SHOULD have drag dev info. If so...
                if (dragDevInfo.current) {

                    // Get the stage location of the pointer.
                    const pt = getStagePointFromDOMPt(ChassisLayoutKey,
                        { x: e.clientX, y: e.clientY });
                  
                    // Call a helper to handle the drop (attempt).
                    dropDrag(dragDevInfo.current, pt, e.ctrlKey, false);

                    resetDropTargets(props.project.content);
                    resetLayoutMode();
                }
                else {
                    // Unexpected.
                    throw new Error('Drag STARTED, but no drag device!');
                }
            }

            // Stop the drag move.
            stopDrag();
        }
        else if (panning) {
            // If the drag pan actually started...
            if (getPanStatus() === PanStatus.Started) {

                // Update it with new location.
                updatePan({ x: e.clientX, y: e.clientY });
            }

            // Stop the pan.
            stopPan();
        }
    }

    const onContextMenu = (e: React.PointerEvent<HTMLDivElement>) => {
        if ((getDragStatus() === DragStatus.Started) ||
            (getPanStatus() === PanStatus.Started)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const cntCngdFunc = props.contentChanged;
    const reqModeActFunc = props.requestModeAction;

    const onActionRequested = useCallback((actBtnInfo: ActBtnInfo) => {

        // Note: Any actions that require user confirmation should
        // be done indirectly using a function that:
        //    - prechecks and prepares needed data, including
        //      a confirmation message.
        //    - calls confirmAndCallback (or something like it)
        //    - actually performs any action in the callback itself.

        // Direct action based on requested action type.
        switch (actBtnInfo.action) {

            // AddModule a module
            case LayoutActionType.AddModule:
                LaunchSelectCompsFor(actBtnInfo.chassis, actBtnInfo.slot, cntCngdFunc, actBtnInfo.event);
                break;

            case LayoutActionType.ModeCopy:
            case LayoutActionType.ModeDelete:
            case LayoutActionType.MakeDuplex:
            case LayoutActionType.MakeSimplex:
                reqModeActFunc(actBtnInfo);
                break;

            default:
                break;
        }
    }, [cntCngdFunc, reqModeActFunc]);


    // If we have a ref for our div yet (if we do, we
    // should then have ALSO registered our stage info),
    // AND our project content is marked as new...
    if (divRef.current && props.project.content.newContent) {

        // Scale it to fit width-wise into our view.
        autoScaleToContent(ChassisLayoutKey,
            props.project.content.totalExtent,
            AutoScaleFitType.NewContent);

        // And reset the .netContent flag.
        props.project.content.newContent = false;
    }

    const dragging = (getDragStatus() === DragStatus.Started);

    // Call a helper to get our stage size props, and
    // the absolute maximum width we could use for
    // a chassis label.
    const [
        stageSizeProps,
        stageVirtSize,
        absMaxNameWidth,
        scrollToBtmReqd
    ] = getStageSizeProps(ChassisLayoutKey, props.project.content.totalExtent);

    const stgRectProps = DebugAids.ShowStageBackground
        ? getStageRectProps(stageSizeProps)
        : {};

    // The call above also tells us whether a scroll to btm
    // will be required. If so, and we're not already doing it...
    if (!scrollToBtmReqstPending.current && scrollToBtmReqd) {

        // Just set the flag. We can't actually do
        // the scroll part itself yet, though. We have
        // to wait until our outer div's scroll height
        // has already settled for the new inner div's
        // height. The scroll itself takes place inside
        // a useRef above set up to observe size changes
        // in our inner div.
        scrollToBtmReqstPending.current = true;
    }

    const getRedCableProps = (pts: Point[]): FancyPathProps => {
        return {
            pts: pts,
            stroke: '#AAAAAA',
            strokeWidth: 12,
            maxRadius: 40
        };
    }

    const getLocalDeviceSelection = (chassis: Chassis): SelectableDevice | undefined => {
        if (selectedDevice && (chassis === selectedChassis) &&
            (selectedDevice.parent === chassis)) {
            return selectedDevice;
        }
        return undefined;
    }

    // NOTE: In a callback like this one, we want to always be
    // very careful to avoid using context STATE values as
    // dependencies. For example, here we would NOT want to also
    // use selectedDevice and/or selectedChassis. If we did, and
    // then included them as dependencies, our function here would
    // be RECREATED new (with a new reference) every time one of
    // those things changed. And, since we pass this function down
    // to any/all ChassisLabel components, EACH of those would see
    // a property change (and thus re-render). By only including
    // dependencies that DON'T change (the setter functions in
    // this case), our function should always used the cached 
    // (useCallback) copy.
    const onClickChassisLabel = useCallback((chassis: Chassis) => {
        selectDevice(undefined);
        selectChassis(chassis);
    }, [selectChassis, selectDevice]);


    //if (LogRender.Views || LogRender.Layout) {
    //    logger.logRender('Render ChassisLayoutView');
    //}

    // Note: The idea of limiting the racks included in our layout
    // render to only those that are (at least partially) visible
    // was experimented with (using the getRacksInView function in
    // ChassisProject). However, the overall idea was then abandoned
    // for a number of reasons, including:
    //   1. Our Stage (and its underlying canvas) used to be large
    //      enough to completely include all of our content, regardless
    //      of the scale (zoom level). That allowed us, however, to
    //      exceed(sometimes vastly) the canvas size limits. Now, the
    //      Stage and canvas sizes are the same size as the visible
    //      viewport. Scroll handling in the new system utilizes tricks
    //      to change the stage origin point and the transform/translate
    //      of its container directly, which allows us to scroll (or pan)
    //      quickly WITHOUT actually requiring a view re-render. HOWEVER,
    //      in order to use those 'tricks', ALL of our content needs to
    //      be included in the Stage.
    //   2. Rendering only what was visible DID have a performance benefit
    //      on the first render. However, we also needed to then trigger
    //      re-renders on every scroll, in order to ensure that any rack
    //      that 'came into view' via the scroll actually got included.
    //   3. Our new approach for images more than compensated
    //      for any prior perceived performance gain.
    //   4. When rendering (virtually) large content onto a stage that's
    //      sized only to the viewport, Konva seems to be very smart about
    //      batching draws and excluding elements outside of the canvas.
    // After changing back to always including ALL racks, the range aspects
    // were left in place, but that range is set accordingly, and the
    // racksToRender collection includes everything.
    const rackRange: IdxRange = (props.project.content.racks.length > 0)
        ? { first: 0, last: props.project.content.racks.length - 1 }
        : { first: -1, last: -1 };

    const racksToRender = (rackRange.first >= 0)
        ? props.project.content.racks
        : new Array<Rack>();
    let rackIdx = rackRange.first;

    // We want to leave our ChassisComp 'pure', as it's NOT
    // supposed to know anything about platform specifics, etc.,
    // and that includes the complexities of rendering when our
    // chassis is redundant. The way OUR app wants to depict
    // our various options for that, layout positioning, etc.,
    // is all fine to do here, but we DON'T want to pass any
    // of that responsibility down to anything that needs to
    // stay generic, such as our ChassisComp level.
    const renderRack = (rack: Rack, idx: number) => {

        // Get info we need up front.
        const chassis = rack.chassis;
        const selected = chassis.selected;

        const chassisLabel = getChassisLabelText(chassis, false);

        const redDepict = getRedDepictOption();

        const chassisStatus = getChassisStatus(chassis);

        const localDevSel = getLocalDeviceSelection(chassis);

        // Note: Rather than just using an incrementing
        // key, we'll assign specific keys to each
        // component in the render. That way, the same
        // element for the same rack (idx) will always
        // get the same key, which helps to minimize
        // re-renders. A memo'd component WILL rerender
        // if a prop changes, INCLUDING the key.
        const rackKey = 'R' + idx;
        const priChKey = 'P' + idx;
        const statKey = 'St' + idx;
        const priLblKey = 'L' + idx;

        // Then render specifically based on if we are or are not.
        // If we ARE a redundant chassis...
        if (rack.chassis.redundant) {

            // Determine 'where' we want our secondary (redundant
            // counterpart) chassis to be positioned in our layout.
            const ptRedOrg = getRedChassisLoc(chassis, rack.ptOrg, redDepict);

            const redOpacity = getRedDepictOpacity(selected, redDepict);

            const [showCable, cablePathPts] =
                getRedCablePath(chassis, rack.ptOrg, ptRedOrg, redDepict);

            const secChKey = 'S' + idx;
            const secLblKey = 'SL' + idx;
            const cblKey = 'C' + idx;

            const showRedLabel = (redDepict !== RedDepictOption.Behind);

            // For redundant cases, we render our secondary chassis
            // (redundant counterpart) FIRST, and we alter its
            // appearance and interaction capabilities as needed.
            // After that, we render our primary in its normal
            // location just like we do for non-redundant cases.
            return (
                <React.Fragment key={rackKey}>
                    <Group key={secChKey} opacity={redOpacity}>
                        <ChassisComp
                            chassis={chassis}
                            bumper={chassis.bump}
                            ptOrg={ptRedOrg}
                            showAsSelected={selected}
                            localDeviceSelected={localDevSel}
                            renderType={ChassisRendType.RedSecondary}
                            layoutMode={layoutMode}
                        />
                        {showRedLabel
                            ? <ChassisLabel
                                key={secLblKey}
                                chassis={chassis}
                                showSelected={selected}
                                label={chassisLabel + '(R)'}
                                ptOrg={ptRedOrg}
                                maxNameWidth={absMaxNameWidth}
                                onClick={onClickChassisLabel}
                            />
                            : null}
                    </Group>
                    <ChassisComp
                        key={priChKey}
                        chassis={chassis}
                        bumper={chassis.bump}
                        ptOrg={rack.ptOrg}
                        showAsSelected={selected}
                        localDeviceSelected={localDevSel}
                        renderType={ChassisRendType.RedPrimary}
                        layoutMode={layoutMode}
                    />
                    <ChassisStatusIconBtn
                        key={statKey}
                        chassis={chassis}
                        ptOrg={rack.ptOrg}
                        status={chassisStatus}
                        contentChanged={props.contentChanged}
                    />
                    <ChassisLabel
                        key={priLblKey}
                        chassis={chassis}
                        showSelected={selected}
                        label={chassisLabel}
                        ptOrg={rack.ptOrg}
                        maxNameWidth={absMaxNameWidth}
                        onClick={onClickChassisLabel}
                    />
                    {showCable
                        ? <FancyPath
                            key={cblKey}
                            pathProps={getRedCableProps(cablePathPts)}
                        />
                        : null}
                </React.Fragment>
            );
        }
        else {
            // The chassis we were asked to render is NOT redundant.
            // Just render the single, 'standard' chassis.
            return (
                <React.Fragment key={rackKey}>
                    <ChassisComp
                        key={priChKey}
                        chassis={chassis}
                        bumper={chassis.bump}
                        ptOrg={rack.ptOrg}
                        showAsSelected={selected}
                        localDeviceSelected={localDevSel}
                        renderType={ChassisRendType.Standard}
                        layoutMode={layoutMode}
                    />
                    <ChassisStatusIconBtn
                        key={statKey}
                        chassis={chassis}
                        ptOrg={rack.ptOrg}
                        status={chassisStatus}
                        contentChanged={props.contentChanged}
                    />
                    <ChassisLabel
                        key={priLblKey}
                        chassis={chassis}
                        showSelected={selected}
                        label={chassisLabel}
                        ptOrg={rack.ptOrg}
                        maxNameWidth={absMaxNameWidth}
                        onClick={onClickChassisLabel}
                    />
                </React.Fragment>
            );
        }
    }

    // Add extra highlights for our selected chassis.
    const indicateSelectedChassis = (chassis: Chassis) => {

    // Get its rack.
        const rack = getRack(chassis);

        // If we can...
        if (rack) {

            const szAsDrawn = getChassisSizeAsDrawn(chassis, false);

            // Get the surround rect we'll use
            // for our highlighting.
            const selRect = getChassisSelRect(
                rack.ptOrg,
                szAsDrawn,
                chassis.redundant,
                getRedDepictOption());

            // Return the our sel rect component.
            // Note that we do this in its OWN layer.
            return (
                <SelectedChassisRect
                    key={'selchasrct'}
                    selRect={selRect}
                />
            );
        }
    }

    // Determine if and what action buttons we have.
    // Notes:
    //   1. We don't want any when dragging, to avoid all
    //      of the unecessary re-calc and rendering of all of them
    //      over and over and over.
    //   2. The getActionBtnInfo function returns very quickly
    //      if no action buttons are possible. For example, we
    //      don't have any in normal mode unless we have a
    //      chassis selected.
    const [anyActionBtns, btnActionType, actBtnInfo] = dragging
        ? [false, LayoutActionType.None, undefined]
        : getActionBtnInfo(
            props.project.content,
            layoutMode,
            selectedChassis,
            rackRange);

    numRenders.current += 1;


    //logger.logCustom('view - chassis: ' + project.content.racks.length +
    //    ', rnd#: ' + numRenders.current);

    const renderContent = () => {
        return (
            <Layer>
                {DebugAids.ShowStageBackground
                    ?
                    <Rect
                        ref={stageRectRef}
                        key={'stgbgrct'}
                        {...stgRectProps}
                    />
                    : null}
                {DebugAids.ShowContentExtentRct
                    ?
                    <Rect
                        key={'extrct'}
                        x={0}
                        y={0}
                        width={props.project.content.totalExtent.x - 2}
                        height={props.project.content.totalExtent.y - 2}
                        {...contentExtRectProps}
                    />
                    : null}
                {racksToRender.map(rack => {
                    return renderRack(rack, rackIdx++);
                })}
                {selectedChassis
                    ? indicateSelectedChassis(selectedChassis)
                    : null}
                {anyActionBtns
                    ? <ActionButtons
                        actionType={btnActionType}
                        btnInfo={actBtnInfo}
                        onClickBtn={onActionRequested}
                    />
                    : null}
            </Layer>
        );
    }

    // NOTE: Below, we specifically want our onPointer event
    // handlers on the INNER div. That way, we DON'T get
    // pointer down events from the scrollbars (if present).

    return (
        <div
            ref={divRef}
            className='chassis-layout-outer-div'
            onKeyDown={onKeyDown}
            tabIndex={-1} /*tabIndex needed to get onKeyDown */
            onContextMenu={onContextMenu}
            style={{ cursor: cursorType.current }}
        >
            <div
                ref={innerDivRef}
                className='chassis-layout-inner-div'
                onPointerDown={onPointerDown}
                onPointerMove={onPointerMove}
                onPointerUp={onPointerUp}
               style={{
                    minWidth: stageVirtSize.width, minHeight: stageVirtSize.height
                }}
            >
                <Stage
                    ref={stageRef}
                    {...stageSizeProps}
                >
                    {stageRegistered.current ? renderContent() : null}
                    {dragDevInfo.current
                        ? <Layer listening={false}>
                            {dragDevInfo.current.showInsert
                                ? <DragInsertGraphic
                                    key={'dragins'}
                                    dragDev={dragDevInfo.current}
                                />
                                : null}
                            <DeviceDragComp
                                key={'dragdev'}
                                dragDev={dragDevInfo.current}
                            />
                        </Layer>
                        : null}
                </Stage>
            </div>
            <FloatingDetails
                callbackSzChanged={callbackSizeChangeRef.current}
                contentChanged={props.contentChanged}
            />
        </div>
    );
}

export default ChassisLayoutView;

