import { Checkbox } from '@mui/material'
import React, { useRef } from 'react'
import './PointEntry.scss'
import '../styles/Meteor.scss'
import { PointEntrySectionInfo } from '../types/IOPointEntryTypes';

// Make sure these align with the variables declared in PointEntry.scss
export const hdrChkFlexValue = '0 0 20px';
export const hdrTxtQtyFlexValue = '0 0 55px';
export const hdrTxtTypeFlexValue = '0 0 130px';
export const hdrTxtTypeFlexValueBasic = '0 0 200px';
export const hdrTxtTypeFilterFlexValue = '0 0 165px';
export const hdrTxtCatFlexValue = '1 0 auto';

interface Props {
	advancedMode: boolean;
	info: PointEntrySectionInfo;
	onSelectionChanged: () => void;
}

export const PointEntryHeader = (props: Props) => {

	const selectAllChecked = useRef(false);

	// If we have a request to clear the Select All checkbox
	// and the box is checked...
	if (props.info.clearSelectAll && selectAllChecked.current === true) {
		// Set the ref to unchecked.
		selectAllChecked.current = false;
	}
	// Regardless of what happens above, we clear the
	// 'clear select all' flag afterwards.
	props.info.clearSelectAll = false

	const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		selectAllChecked.current = checked;
		props.info.entries.forEach((entry) => entry.selected = checked);
		props.onSelectionChanged();
		const selectAllIORowsClick = new CustomEvent("designSelectAllIORows", {
			detail: {
			action: "Select All IO Rows",
			properties: {
				category: "WebApp",
				label: "Select All IO Rows",
			},
			},
		});
		document.getElementById("root")?.dispatchEvent(selectAllIORowsClick);
	}

	//const advancedMode = props.project.config.IOEntryMode === IOEntryModeEnum.Advanced;

	return (
		<div className="point-entry-container--hdr">
			<div style={{ height: '37px' }} >
				<Checkbox
					onChange={onCheckChanged}
					sx={{ flex: hdrChkFlexValue, marginRight: '-15px' }}
					checked={selectAllChecked.current}
			/>
			</div>

			<div className="point-entry-hdr-qty point-entry-hdr-border">
				<span className="a-type__subTitle3">Points</span>
			</div>

			<div className={props.advancedMode ? "point-entry-hdr-type-adv point-entry-hdr-border" : "point-entry-hdr-type-basic point-entry-hdr-border"} >
				<span className="a-type__subTitle3">I/O Type</span>
			</div>

			{
				props.advancedMode ?
					<div className="point-entry-hdr-filter point-entry-hdr-border" >
						<span className="a-type__subTitle3">I/O Functionality</span>
					</div>
					:
					null
			}

			{
				props.advancedMode ?
					<div className="point-entry-hdr-catalog point-entry-hdr-border" >
						<span className="a-type__subTitle3">Catalog Number</span>
					</div>
					:
					null
			}
		</div>
	);
}