import { getEngineeringInfoFor, getProductDescription } from "../model/ChassisProject";
import { ChoiceGroupType, ChoiceInfo, ChoicesGroup } from "../types/MessageTypes";
import { ChassisModule, GraphicalDevice, IOModuleWiring, SupportedWiringType } from "../types/ProjectTypes";
import { addChoice, makeChoicesGroup } from "./ChoicesHelp";
import { logger } from "./Logger";
import { displayAlertMsg } from "./MessageHelp";

const _grpLblOptAccys = 'Optional Accessories';
const _grpLblReqAccys = 'Required Accessories';
const _grpLblReq1ofAccys = 'Required Accessories - One of the following is Required';
const _grpLblRecAccys = 'Recommended Accessories';


const getSpecElementValue = (text: string): string | undefined => {
    const trimmed = text.trim();
    if (trimmed.length > 0) {
        return trimmed;
    }
    else {
        return undefined;
    }
}

const getPropValueAccys = (platform: string, propValue: string): [grpLabel: string, accys: ChoiceInfo[]] => {

    // Initialize the label we'll return that can
    // be used as the accy group label. Start empty.
    let grpLabel = '';

    // Create an array of ChoiceInfo
    const accys = new Array<ChoiceInfo>();

    // Split the incoming prop value at semicolons.
    const specs = propValue.split(';');

    // For each separate 'spec' piece we got...
    specs.forEach(spec => {

        // Further split at colons into elements.
        const specEls = spec.split(':');

        // See how many elements we have.
        const numEls = specEls.length;

        // If any...
        if (numEls > 0) {

            // Get the first one (if it has
            // anything in it after trimming)...
            const id = getSpecElementValue(specEls[0]);

            // If so...
            if (id) {
                // Do the same for the second element,
                // which, if there, will give us a value.
                const val = (numEls > 1)
                    ? getSpecElementValue(specEls[1])
                    : undefined;

                // Watch for our special 'label' keyword.
                const lblChk = id.toLowerCase();

                // If this is that...
                if (lblChk === 'label') {

                    // Then if we have a value, it'll
                    // be our group label. Otherwise,
                    // we'll just skip over it.'
                    if (val) {
                        grpLabel = val;
                    }
                }
                else {
                    // NOT the group label. Add
                    // accessory info for it.
                    accys.push({
                        label: val ? val : getProductDescription(platform, id),
                        id: id
                    });
                }
            }
        }
    });

    // Return our results.
    return [grpLabel, accys];
}

const addAccyGroupFromPropValue = (
    platform: string,
    groups: ChoicesGroup[],
    propValue: string,
    type: ChoiceGroupType,
    dfltGrpLabel: string
) => {
    const [grpLabel, accys] = getPropValueAccys(platform, propValue);
    if (accys.length > 0) {
        const label = (grpLabel.length > 0) ? grpLabel : dfltGrpLabel;
        const accyGrp = makeChoicesGroup(type, label);
        accyGrp.subgroups[0].selectionInfo = accys;
        groups.push(accyGrp);
    }
}

export const getSupportedWiringTypes = (device: GraphicalDevice): number => {

    // Get eng info for the device.
    const engInfo = getEngineeringInfoFor(device);

    // If we can, return what it gives us,
    // which will just be NA for any device
    // that doesn't support any.
    if (engInfo) {
        return engInfo.getSupportedWiringTypes();
    }

    // If we're still here, we have none.
    return SupportedWiringType.NA;
}

export const getAccyDetails = (device: GraphicalDevice, requiredOnly: boolean):
    ChoicesGroup[] => {

    // Create a new empty array of ChoicesGroup objects.
    const groups = new Array<ChoicesGroup>()

    // See if the device supports any wiring type(s).
    const supportedWiringTypes = getSupportedWiringTypes(device);

    // If so...
    if (supportedWiringTypes !== SupportedWiringType.NA) {

        // Then it would HAVE to be a module. Cast.
        const asModule = device as ChassisModule;

        // Get the parent chassis.
        const parentChassis = asModule.parent;

        // If we can...
        if (parentChassis) {

            // Have a helper build us the wiring-related choices
            // group. If we get one, add it to our array.
            const tcGroup = getTerminalConnectivityChoices(asModule, parentChassis.defaultIOModWiring);
            if (tcGroup) {
                groups.push(tcGroup);
            }
        }
    }

    const engInfo = getEngineeringInfoFor(device);
    if (engInfo) {
        const platform = device.platform;

        // Required
        if (engInfo.accysReq) {
            addAccyGroupFromPropValue(platform, groups, engInfo.accysReq,
                ChoiceGroupType.Require, _grpLblReqAccys);
        }

        // One-of Required
        if (engInfo.accysReq1of) {
            addAccyGroupFromPropValue(platform, groups, engInfo.accysReq1of,
                ChoiceGroupType.RequireOneOf, _grpLblReq1ofAccys);
        }

        // If we're NOT supposed to consider JUST any
        // REQUIRED accessories, move to the 'optionals'.
        if (!requiredOnly) {

            // Recommended
            if (engInfo.accysRec) {
                addAccyGroupFromPropValue(platform, groups, engInfo.accysRec,
                    ChoiceGroupType.Recommend, _grpLblRecAccys);
            }

            // Optional
            if (engInfo.accysOpt) {
                addAccyGroupFromPropValue(platform, groups, engInfo.accysOpt,
                    ChoiceGroupType.Optional, _grpLblOptAccys);
            }
        }
    }
    else {
        logger.warn('getAccyDetails failed to get eng info for: ' + device.catNo);
    }

    // Return our final array,
    // empty or not.
    return groups;
}


//export const anyAccysPossibleFor = (device: GraphicalDevice): boolean => {
//    return (getAccyDetails(device, false).length > 0);
//}

// NOTE: In most cases, this function should only be called from
// other functions in ChassisProject.ts. So, the general function
// adds the accessories and any platform-specific function does not,
// in order to avoid calling it twice. In special cases, however,
// a platform-specific function CAN use it (Ex: CLX power supplies).
export const addRequiredAccys = (device: GraphicalDevice) => {

    // Later on, we MIGHT do some more intelligent checking and
    // potential updating of a device's accessories at certain
    // times. For example, we MIGHT want to sanitize an existing
    // device's accessories when a project is loaded in order to
    // make sure that all requirements are present, etc.
    // For NOW, however, we expect this function to ONLY be
    // called for a newly-added device. As such, it should NOT
    // already have any accessories. If it does, we'll just
    // display a message and return.
    if (device.accys) {
        displayAlertMsg('WARNING: An attempt was made to add required ' +
            'accessories to a device that already has accessories!');
        return;
    }

    // Get details about any REQUIRED
    // accessories relevant for the device.
    const accyDtls = getAccyDetails(device, true);

    // If we get anything back...
    if (accyDtls.length > 0) {

        // Set up an array to pre-collect anything
        // that we're going to want to add.
        const accysToAdd = new Array<string>();

        // Walk the groups that were returned. For each...
        for (let grpIdx = 0; grpIdx < accyDtls.length; grpIdx++) {

            // Get the group...
            const group = accyDtls[grpIdx];

            // At least for now, we assume that each accessory
            // group is made up of a single subgroup.
            const subgroup = group.subgroups[0];

            // And its type.
            switch (group.type) {

                // For Require type...
                case ChoiceGroupType.Require:
                    // Add ALL in the group.
                    subgroup.selectionInfo.forEach(info => {
                        accysToAdd.push(info.id);
                    })
                    break;

                // For RequireOneOf
                case ChoiceGroupType.RequireOneOf:

                    // If we have any selections possible...
                    if (subgroup.selectionInfo.length) {

                        // See if we have a case where the group
                        // gave us a default. Currently, the default
                        // is ONLY used for terminal wiring options,
                        // and is set to what best matches the
                        // default wiring option for the chassis
                        // a module resides in. If we have a default...
                        if (group.default && (group.default.length > 0)) {
                            // Use it.
                            accysToAdd.push(group.default);
                        }
                        else {
                            // Otherwise, use the id of the
                            // FIRST selection in the group.
                            accysToAdd.push(subgroup.selectionInfo[0].id);
                        }
                    }
                    break;

                // Optionals are NOT (by definition)
                // required. Just skip this group.
                case ChoiceGroupType.Recommend:
                case ChoiceGroupType.Optional:
                    break;

                default:
                    throw new Error('Unexpected group type in checkAndUpdateAccessories!');
            }
        }

        // If we added any to our array...
        if (accysToAdd.length > 0) {
            // Use that array as the device's
            // new collection.
            device.accys = accysToAdd;
        }
    }
}

export const getTerminalConnectivityChoices = (
    module: ChassisModule,
    dfltIOModWiring: IOModuleWiring
): ChoicesGroup | undefined => {

    // Get eng info for the module.
    const modInfo = getEngineeringInfoFor(module);

    // If we can...
    if (modInfo) {

        // Ask what type of wiring it can use, if any.
        const wiringTypes = modInfo.getSupportedWiringTypes();

        // If any...
        if (wiringTypes !== SupportedWiringType.NA) {

            // Then it SHOULD supply wiring options.
            const wiringOpts = modInfo.getWiringOptions();

            // If so...
            if (wiringOpts) {

                // Make the group for terminal connectivity.
                const tcGroup = makeChoicesGroup(ChoiceGroupType.RequireOneOf,
                    'Terminal Connectivity (One selection required)');

                if (wiringOpts.tbScrew.length) {
                    addChoice(tcGroup, 'Screw Terminal Base', wiringOpts.tbScrew);
                    if (dfltIOModWiring === IOModuleWiring.Screw) {
                        tcGroup.default = wiringOpts.tbScrew;
                    }
                }

                if (wiringOpts.tbSpring.length) {
                    addChoice(tcGroup, 'Spring-Clamp Terminal Base', wiringOpts.tbSpring);
                    if (dfltIOModWiring === IOModuleWiring.SpringClamp) {
                        tcGroup.default = wiringOpts.tbSpring;
                    }
                }

                if (wiringOpts.tbIOReady.length) {
                    addChoice(tcGroup, 'I/O Ready Cable', wiringOpts.tbIOReady);
                    if (dfltIOModWiring === IOModuleWiring.IOReadyCable) {
                        tcGroup.default = wiringOpts.tbIOReady;
                    }
                }

                return tcGroup;
            }
            else {
                // Any component that says it supports ANY sort of wiring
                // type(s) should ALWAYS be able to provide wiring opts.
                throw new Error('Unexpected ERROR in getTerminalConnectivityChoices for: '
                    + module.catNo);
            }
        }
    }

    return undefined;
}
