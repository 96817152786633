import React from 'react';
import { ChassisCompProps, getChassisRenderer } from '../implementation/ImplGeneral';
import { LogRender } from '../types/Globals';
import { logger } from '../util/Logger';


const ChassisComp = (props: ChassisCompProps) => {

    const PlatformChassisComp = getChassisRenderer(props.chassis.platform);


    if (LogRender.Layout) {
        logger.logRender('Chassis ' + props.chassis.name +
            ' bumper(' + props.bumper + ')');
    }

    if (PlatformChassisComp) {
        return (
            <PlatformChassisComp
                {...props}
            />
        );
    }
    else {
        return (null);
    }
}

export default React.memo(ChassisComp);

