import React, { useRef } from 'react';
import { Group } from 'react-konva';
import FancyPath, { FancyPathProps } from '../../../components/cabling/FancyPath';
import SelectableDeviceComp from "../../../components/SelectableDeviceComp";
import SlotIDComp from '../../../components/SlotIDComp';
import { getConfigSpec } from '../../../config';
import {
    canExtendChassis,
    ChassisCompProps,
    getDefaultXSlotWidth,
    getEmptySlotImage,
    getNumIOBases,
    getSlotID
} from "../../../implementation/ImplGeneral";
import { getModuleSlotBreakdown, getModuleSlotID } from "../../../model/ChassisProject";
import { Use5015TransparentImgs } from '../../../types/Globals';
import { ChassisModule, ModuleDragStatus } from "../../../types/ProjectTypes";
import { LocAndSize, Point } from '../../../types/SizeAndPosTypes';
import { CableSpec } from '../../../util/CableHelp';
import { getEmptyLoc, getOffsetPtArray } from '../../../util/GeneralHelpers';
import { getChassisRenderSpec, LayoutModeType } from "../../../util/LayoutModeHelp";
import ChassisElComp from '../../common/ChassisElComp';
import { ChassisElementType } from '../../common/CommonPlatformTypes';
import {
    FlexHALayoutInfo,
    flexHAGetSAPwrLocInfo,
    FlxHASizeDetails,
    getFHASizeDetails
} from '../model/FlexHALayout';


enum IOTBType {
    None = 'None',
    Simplex = 'Simplex',
    Duplex = 'Duplex',
    Cover = 'Cover'
}

const _getTBImageSource = (imgType: IOTBType): string => {

    if (imgType === IOTBType.None) {
        return '';
    }

    const configSpec = getConfigSpec();
    const basePath = `${configSpec.ISD_URL}/assets/flexHA/`;
    switch (imgType) {
        case IOTBType.Duplex:
            return basePath + '5015_IO_TB_Duplex.png';

        case IOTBType.Simplex:
            return basePath + '5015_IO_TB_Simplex.png';

        case IOTBType.Cover:
            return basePath + '5015_IO_TB_Cover.png';

        default:
            throw new Error('Unexpected type in _getTBImageSource!');
    }
}

const _getPwrJumperImgSrc = (): string => {
    const configSpec = getConfigSpec();
    return `${configSpec.ISD_URL}/assets/flexHA/5015_SA_Power_Jumper.png`;
}

const _getPwrTBCoverImgSrc = (): string => {
    const configSpec = getConfigSpec();
    return `${configSpec.ISD_URL}/assets/flexHA/5015_SA_Power_TB_Cover.png`;
}

const _addXSlot = (props: ChassisCompProps, sizeDtls: FlxHASizeDetails):
    [show: boolean, loc: LocAndSize, id: string] => {

    // Start with assumption we WON'T 
    // have an 'X' (extra empty) slot.
    let extWidth = 0;

    // If we can extend the chassis... Note: Other 
    // 'snap' platforms always allow module move 
    // within the same chassis to the Extended Slot, 
    // but FlexHA does NOT.
    if (canExtendChassis(props.chassis)) {
        // We will if the chassis is a drag target
        // AND it has an xSlotWidth...
        if (props.chassis.dragTarget && (props.chassis.xSlotWidth > 0)) {
            extWidth = props.chassis.xSlotWidth;
        }
        else {
            // Not a drag target. We STILL might show
            // the x-slot if either the chassis is
            // selected OR if we're in Copy Mode.
            const extPossible = (props.layoutMode?.type === LayoutModeType.Copy);

            // If either AND the chassis can be extended...
            if (extPossible || props.showAsSelected) {
                // Get the default X slot width from
                // the platform, if any.
                extWidth = getDefaultXSlotWidth(props.chassis.platform);
            }
        }
    }

    // If we ended up with a width...
    if (extWidth) {

        const numIOBases = getNumIOBases(props.chassis);

        const adptLoc = props.chassis.layout.slotLocs[0];

        const locXSlot: LocAndSize = {
            x: adptLoc.x + adptLoc.width + (numIOBases * sizeDtls.ioBaseInfo.baseSize.width),
            y: adptLoc.y,
            width: sizeDtls.ioBaseInfo.baseSize.width,
            height: sizeDtls.ioBaseInfo.baseSize.height
        };

        // We are not using slot IDs for FlexHA (yet)
        // but set it up correctly. The extended
        // slot ID will always be the length of 
        // the module array (last slot index + 1)
        const xSlotId = props.chassis.modules.length;

        // We DO want a right slot.
        return [true, locXSlot, xSlotId.toString()];
    }

    // We DON'T want one.
    return [false, getEmptyLoc(), ''];
}

const _getBackplateImgSrc = (): string => {
    const configSpec = getConfigSpec();
    const imgSrc = `${configSpec.ISD_URL}/assets/flexHA/5015_Backplate.png`;
    return imgSrc;
}

const ioBaseImg = Use5015TransparentImgs ? 'ALT_5015-A4IOKIT.png' : '5015-A4IOKIT.png';

const _getIOBaseImgSrc = (): string => {
    const configSpec = getConfigSpec();
    const imgSrc = `${configSpec.ISD_URL}/assets/flexHA/${ioBaseImg}`;
    return imgSrc;
}

//const _cblRadius = 16;

const _getCableProps = (spec: CableSpec, ptOrg: Point): FancyPathProps => {
    return {
        pts: getOffsetPtArray(spec.pathPts, ptOrg),
        stroke: spec.color,
        strokeWidth: spec.width,
        maxRadius: spec.radius,
    }
}


const FlexHAChassisComp = (props: ChassisCompProps) => {

    const backplateImgSrc = useRef<string>(_getBackplateImgSrc());
    const ioBaseImgSrc = useRef<string>(_getIOBaseImgSrc());

    const layout = props.chassis.layout as FlexHALayoutInfo;
    if (!layout) {
        throw new Error('FlexHAChassisComp passed chassis without valid layout!');
    }

    const defaultRender = (!props.renderType) || (!props.layoutMode);
    const modeForSpec = defaultRender ? undefined : props.layoutMode;

    const sizeDtls = getFHASizeDetails();

    // Call a helper to give us rendering details for the various 
    // elements of our chassis (ps, modules, empty slots, etc.)
    const elRenderSpec = getChassisRenderSpec(modeForSpec,
        props.chassis, true, (props.showAsSelected === true));

    // Get breakdown of our slots (which have modules and which are empty).
    // Note: the returned modSlots array will only contains the left-side slot
    // numbers for any 2-slot modules in the chassis. The emptySlots array
    // will contain ONLY those slots that already exist and are truly empty.
    const [modSlots, emptySlots] = getModuleSlotBreakdown(props.chassis.modules);

    // See if our chassis should be depicted as 'selected'.
    const showSelected = (props.showAsSelected === true);

    //// Determine the opacity we'll use for empty slots.
    //// We want them to be faded and lighter when we have
    //// add ('+') action buttons on them.
    //const emptySlotOpacity = showSelected ? 0.7 : 1.0;
    const [extraRightSlot, extraSlotLoc] = _addXSlot(props, sizeDtls);
    const emptySlotImg = getEmptySlotImage(props.chassis);

    const backplateLoc = { ...layout.backplateLoc };
    if (extraRightSlot) {
        backplateLoc.width += extraSlotLoc.width;
    }
    const bpClipLoc: LocAndSize = {
        x: backplateLoc.x + sizeDtls.bpSideExt,
        y: backplateLoc.y,
        width: backplateLoc.width - (2 * sizeDtls.bpSideExt),
        height: backplateLoc.height - sizeDtls.bpBtmExt
    }

    const bpBorder = {
        stroke: 'lightgray',
        strokeWidth: 2,
        strokeScaleEnabled: false
    }

    const iotbBorder = {
        stroke: 'black',
        strokeWidth: 1,
        strokeScaleEnabled: false
    }

    const [saLocs, jumperLocs, tbCoverLocs] = flexHAGetSAPwrLocInfo(props.chassis);
    saLocs;

    const saPJImgSrc = _getPwrJumperImgSrc();
    const saPwrTBCoverImgSrc = _getPwrTBCoverImgSrc();

    //const showSlotIds = defaultRender ? false : true;
    // Do not show slot IDs
    const showSlotIds = (props.showAsSelected === true);

    const useDarkImageryOption = true;

    const getIOTBImgUrl = (mod: ChassisModule | undefined): string => {

        if (mod) {
            if (mod.slotsUsed === 2) {
                return _getTBImageSource(IOTBType.Duplex);
            }
            if (mod.slotFiller) {
                return _getTBImageSource(IOTBType.Cover);
            }
            else {
                return _getTBImageSource(IOTBType.Simplex);
            }
        }
        else {
            return '';
        }
    }

    const renderModule = (slotNum: number) => {
        const module = props.chassis.modules[slotNum];
        if (module) {

            //const rndType = (props.renderType !== undefined)
            //    ? module.slotFiller ? elRenderSpec.slotFillers : elRenderSpec.modules
            //    : undefined;
            const rndType = (props.renderType !== undefined)
                ? elRenderSpec.modules
                : undefined;

            const slotId = showSlotIds
                ? getModuleSlotID(module)
                : undefined;

            const ioTBImgUrl = getIOTBImgUrl(module);

            const darkOpt = useDarkImageryOption && (slotNum !== 0);

            return (
                <Group key={slotNum}>
                    <SelectableDeviceComp
                        device={module}
                        devDragStatus={module.dragStatus}
                        showSelected={showSelected && (props.localDeviceSelected === module)}
                        renderType={rndType}
                        ptOrg={props.ptOrg}
                        relLocation={layout.slotLocs[slotNum]}
                        slotId={slotId}
                        darkImagery={darkOpt}
                    />
                    <ChassisElComp
                        elType={ChassisElementType.IOTermBlk}
                        renderType={elRenderSpec.ioTermBlks}
                        imgSrc={ioTBImgUrl}
                        ptOrg={props.ptOrg}
                        relLocation={layout.ioTBLocs[slotNum]}
                        borderProps={iotbBorder}
                    />
                </Group>
            );
        }
        else {
            return (
                null
            );
        }
    }

    // Note: In order to best preserve key uniqueness
    // from render to render, we'll ONLY use an incrementing
    // number as a key for ioBases.
    // Those should ALWAYS be the same for the same chassis layout.
    let nextIOBaseId = 1;
    let nextJmprId = 1;
    let nextCblId = 1;

    return (
        <>
            <ChassisElComp
                key={'BP'}
                elType={ChassisElementType.Backplate}
                renderType={elRenderSpec.backplate}
                imgSrc={backplateImgSrc.current}
                ptOrg={props.ptOrg}
                relLocation={backplateLoc}
                borderProps={bpBorder}
                clippedLoc={bpClipLoc}
            />

            {layout.ioBaseLocs.map(baseLoc => {
                return (
                    <ChassisElComp
                        key={nextIOBaseId++}
                        elType={ChassisElementType.IOBase}
                        renderType={elRenderSpec.ioBases}
                        imgSrc={ioBaseImgSrc.current}
                        ptOrg={props.ptOrg}
                        relLocation={baseLoc}
                    />
                );
            })}

            {modSlots.map(slot => {
                return (renderModule(slot));
            })}

            {showSlotIds && emptySlots.map(slot => {
                return <SlotIDComp
                    key={'SID' + slot}
                    slotId={getSlotID(props.chassis, slot)}
                    ptOrg={props.ptOrg}
                    relSlotLoc={layout.slotLocs[slot]}
                    emptySlot={true}
                />
            })}

            {props.chassis.ps
                ? < Group key={'PS'}>
                    <SelectableDeviceComp
                        device={props.chassis.ps}
                        devDragStatus={ModuleDragStatus.NA}
                        showSelected={showSelected && (props.localDeviceSelected === props.chassis.ps)}
                        renderType={props.renderType ? elRenderSpec.ps : undefined}
                        ptOrg={props.ptOrg}
                        relLocation={sizeDtls.psuLoc1}
                        darkImagery={useDarkImageryOption}
                    />
                    <SelectableDeviceComp
                        device={props.chassis.ps}
                        devDragStatus={ModuleDragStatus.NA}
                        showSelected={showSelected && (props.localDeviceSelected === props.chassis.ps)}
                        renderType={props.renderType ? elRenderSpec.ps : undefined}
                        ptOrg={props.ptOrg}
                        relLocation={sizeDtls.psuLoc2}
                        darkImagery={useDarkImageryOption}
                    />
                </Group >
                : null}

            {sizeDtls.psuCables.map(cblSpec => {
                return (
                    <FancyPath
                        key={'C' + nextCblId++}
                        pathProps={_getCableProps(cblSpec, props.ptOrg)}
                    />
                )
            })}

            {jumperLocs.map(loc => {
                return (
                    <ChassisElComp
                        key={'J' + nextJmprId++}
                        elType={ChassisElementType.Jumper}
                        renderType={elRenderSpec.jumper}
                        imgSrc={saPJImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={loc}
                    />
                )
            })}

            {tbCoverLocs.map(loc => {
                return (
                    <ChassisElComp
                        key={'TC' + nextJmprId++}
                        elType={ChassisElementType.Jumper}
                        renderType={elRenderSpec.jumper}
                        imgSrc={saPwrTBCoverImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={loc}
                    />
                )
            })}

            {extraRightSlot
                ? <>
                    <ChassisElComp
                        key={'XRS'}
                        elType={ChassisElementType.EmptySlot}
                        renderType={elRenderSpec.emptySlots}
                        imgSrc={emptySlotImg}
                        ptOrg={props.ptOrg}
                        relLocation={extraSlotLoc}
                    />
                </>
                : null
            }
        </>
    );

}

export default FlexHAChassisComp;

