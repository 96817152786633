import { useCallback, useRef, useState } from "react";
import { Size } from "../types/SizeAndPosTypes";
import { requestImage, ImageElType, ImageStatus } from "./ImageCache";

export const useCachedImage = (url: string):
    [ImageElType, ImageStatus, Size] => {

    const [stateToken, setStateToken] = useState(0);

    const urlRef = useRef<string>(url);
    const imgRef = useRef<ImageElType>(undefined);
    const statusRef = useRef<ImageStatus>('none');
    const imgReqested = useRef<boolean>(false);
    const imgSize = useRef<Size>({ width: 0, height: 0 });

    const onCallback = useCallback((image: ImageElType, status: ImageStatus) => {

        if ((image !== imgRef.current) || (status !== statusRef.current)) {
            imgRef.current = image;
            statusRef.current = status;
            imgSize.current = image
                ? { width: image.width, height: image.height }
                : { width: 0, height: 0 };
            setStateToken(stateToken + 1);
        }
        else {
            console.error('Unexpected - no change in callback!');
        }

    }, [stateToken]);

    if (url !== urlRef.current) {
        urlRef.current = url;
        imgRef.current = undefined;
        statusRef.current = 'none';
        imgSize.current = { width: 0, height: 0 };
        imgReqested.current = false;
    }

    if (!imgReqested.current) {
        const [img, status] = requestImage(url, onCallback);
        imgReqested.current = true;

        if (img !== imgRef.current) {
            imgRef.current = img;
        }
        if (status !== statusRef.current) {
            statusRef.current = status;
        }
    }

    return [imgRef.current, statusRef.current, imgSize.current];
};