import React, { useEffect, useRef, useState } from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import DetailsGroup from './DetailsGroup';
import { GraphicalDevice } from '../../types/ProjectTypes';
import {
    addDetailItem,
    DetailGroup,
    DetailGrpType,
    makeDetailGroup
} from '../../model/DeviceDetail';
import { RaiseDevData, getRaiseDeviceData } from '../../raiseDatabase/DeviceCache';


const createStatusGroupFromRaiseData = (data: RaiseDevData): DetailGroup => {
    const statusGrp = makeDetailGroup(DetailGrpType.Group);
    addDetailItem(statusGrp, 'Product Lifecycle', data.prodLifeCycleStatus);
    addDetailItem(statusGrp, 'Stock Status', data.stockStatusDisplay);
    addDetailItem(statusGrp, 'List Price', data.displayPrice);
    statusGrp.id = data.catalogNumber;
    return statusGrp;
}


interface Props {
    device: GraphicalDevice;
}

const DetailsStatus = (props: Props) => {
    const [statusGroup, setStatusGroup] = useState<DetailGroup>();
    const lastDevice = useRef<GraphicalDevice | undefined>(undefined);

    const device = props.device;

    useEffect(() => {

        // RAISE data fetch callback.
        const fetchCallback = (catalog: string, data: RaiseDevData) => {
            // Check that we still have the SAME product. 
            // Otherwise, we can get a callback for a 
            // different catalog and display the wrong data!
            if (lastDevice.current?.catNo === catalog) {
                setStatusGroup(createStatusGroupFromRaiseData(data));                   
            }
            return;
        }

        // Request the raise data. If the data for the catalog
        // has already been retrieved, the 'fetch callback' will
        // not be called. Otherwise, the callback will be executed
        // when the data is either fetched or qualifies to be re-fetched.
        const data = getRaiseDeviceData(device.catNo, fetchCallback, true);

        // If we have a new device....
        if (lastDevice.current !== device) {
           // Use whatever we got (so far) to create our
            // status group, and put that in our state var.
            setStatusGroup(createStatusGroupFromRaiseData(data));

            lastDevice.current = device;
        }
    }, [device, statusGroup])

    return (
        <div className='floating-details-status'>
            {statusGroup ? <DetailsGroup group={statusGroup} /> : null}
        </div>
    );
}

export default DetailsStatus;
