import React from 'react';
import { ToolbarAction } from './ToolbarActions';
import { LogMsgLevel } from '../util/ProjectLog';
import { Button, ButtonPropsColorOverrides, ButtonPropsVariantOverrides, SvgIconProps, Tooltip } from '@mui/material';
import { GoodIcon, InformationCircleIcon, ErrorsIcon, FailedIcon } from '@rockwell-automation-inc/ra-meatball';
import { OverridableStringUnion } from '@mui/types';

const getBtnDetails = (msgLevel: LogMsgLevel):
    [
        icon: (props: SvgIconProps) => JSX.Element,
        variant: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>,
        color: OverridableStringUnion<
            'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
            ButtonPropsColorOverrides
        >
    ] => {

    switch (msgLevel) {
        case LogMsgLevel.none:
            return [
                GoodIcon,
                'outlined',
                'success'
            ];

        case LogMsgLevel.info:
            return [
                InformationCircleIcon,
                'outlined',
                'ftBlue'
            ];

        case LogMsgLevel.warning:
            return [
                ErrorsIcon,
                'contained',
                'warning'
            ];

        default:
            return [
                FailedIcon,
                'contained',
                'error'
            ];
    }
}


interface Props {
    msgLevel: LogMsgLevel;
    onTBAction: (action: string) => void;
    disabled?: boolean;
}

const StatusToolbarButton = (props: Props) => {

    //const msgLevel = project.content.statusLog.logStatus;

    const [Icon, variant, color] = getBtnDetails(props.msgLevel);

    const onButtonClicked = (action: string) => {
        props.onTBAction(action);
    }

    return (
        <Tooltip title='display errors, warnings, info'>
            <Button
                id={ToolbarAction.ISSUES}
                startIcon={<Icon />}
                variant={variant}
                color={color}
                onClick={() => onButtonClicked(ToolbarAction.ISSUES)}
            >
                Issues
            </Button>
        </Tooltip>
    );
}

export default StatusToolbarButton;
