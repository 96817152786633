import React, { useState } from 'react';
import Konva from 'konva';
import { Group, Rect, Image } from 'react-konva';
import { LayoutActionType, ActBtnSpecs, DfltActBtnSpecs, CopyModeCompat, ActBtnInfo } from '../../types/LayoutActions';
import { LocAndSize } from '../../types/SizeAndPosTypes';
import Clipboard from '../images/Clipboard.png';
import ClipboardBlue from '../images/ClipboardBlue.png';
import ClipboardRed from '../images/ClipboardRed.png';
import MakeSimplex from '../images/BtnMakeSimplex.png';
import MakeDuplex from '../images/BtnMakeDuplex.png';
import TrashcanImage from '../images/Trashcan.png';
import { LogRender } from '../../types/Globals';
import { logger } from '../../util/Logger';
import { useCachedImage } from '../../imageHelp/useCachedImage';

const _extraSizeForModeBtns = 20;

const getImageSource = (btnInfo: ActBtnInfo) => {
    switch (btnInfo.action) {
        case LayoutActionType.ModeCopy:
            switch (btnInfo.compat) {
                case CopyModeCompat.Mismatch:
                    return ClipboardRed;

                case CopyModeCompat.Swap:
                    return ClipboardBlue;

                default:
                    return Clipboard;
            }

        case LayoutActionType.MakeDuplex:
            return MakeDuplex;

        case LayoutActionType.MakeSimplex:
            return MakeSimplex;

        case LayoutActionType.ModeDelete:
        default:
            return TrashcanImage;
    }
}

const getDefaultSpecs = (btnInfo: ActBtnInfo): ActBtnSpecs => {
    switch (btnInfo.action) {
        case LayoutActionType.MakeDuplex:
        case LayoutActionType.MakeSimplex:
            {
                // Return a modified Default Spec
                // for the Simplex/Duplex buttons.
                const def: ActBtnSpecs = { ...DfltActBtnSpecs };
                def.fill = '#99DD99CC'; // (btnInfo.action === LayoutActionType.MakeDuplex ? '#00FF0022' : '#FFFF0022');
                def.strokeWidth = 3;
                def.stroke = '#FFFFFF77';// (btnInfo.action === LayoutActionType.MakeDuplex ? '#00FF00AA' : '#FFFF00AA');
                def.mouseoverFill = '#FFFFFFCC';
                def.size = 25;
                return def;
            }
        default:
            break;
    }

    return DfltActBtnSpecs;
}

interface Props {
    btnInfo: ActBtnInfo;
    onClick: (actBtnInfo: ActBtnInfo) => void;
    btnSpecs?: ActBtnSpecs;
}

const ModeActionButton = (props: Props) => {
    const [isMouseover, setIsMouseover] = useState(false);
    const [image, /*imageStatus*/, /*imgSize*/] = useCachedImage(getImageSource(props.btnInfo));

    const specs = props.btnSpecs ? props.btnSpecs : getDefaultSpecs(props.btnInfo);
    const btnSize = specs.size + _extraSizeForModeBtns;
    const imgSize = btnSize * specs.imgSizePct;

    const btnCtr = props.btnInfo.ctrPt;

    const rctProps = {
        x: btnCtr.x - (btnSize / 2),
        y: btnCtr.y - (btnSize / 2),
        width: btnSize,
        height: btnSize,
        cornerRadius: btnSize * specs.cornerRadPct
    };

    const imgLoc: LocAndSize = {
        x: btnCtr.x - (imgSize / 2),
        y: btnCtr.y - (imgSize / 2),
        width: imgSize,
        height: imgSize
    };

    const commonProps = {
        stroke: isMouseover ? specs.mouseoverStroke : specs.stroke,
        fill: isMouseover ? specs.mouseoverFill : specs.fill,
        strokeWidth: specs.strokeWidth
    };


    const onPointerDown = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();
    }

    const onPointerClick = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();

        // Then notify our parent we were clicked.
        props.onClick(props.btnInfo);
    }

    const onMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'pointer';
        }
        setIsMouseover(true);
    }

    const onMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'default';
        }
        setIsMouseover(false);
    }


    if (LogRender.ActButtons) {
        logger.logRender('ModeActionButton');
    }


    return (
        <Group
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onPointerDown={onPointerDown}
            onPointerClick={onPointerClick}
        >
            <Rect
                {...rctProps}
                {...commonProps}
            />
            <Image
                image={image}
                {...imgLoc}
            />
        </Group>
    );

}

// Note: There's no point in memo-izing these at
// this point, and there is a small cost we avoid.
// At present, each time we're used, we're getting
// a NEW btnInfo prop, so we'd never actually use
// the memo as things are currently set up.
export default ModeActionButton;
//export default React.memo(ModeActionButton);


