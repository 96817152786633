import React, { useRef } from 'react';
import './ProjInfoBrief.scss';
import '../styles/Meteor.scss';
import { PlatformCLX } from '../platforms/PlatformConstants';
import { getIndustryDisplayStr, getInstallLocDisplayStr } from './LocationAndIndustry';
import { ChassisProject } from '../types/ProjectTypes';


interface Props {
    project: ChassisProject;
    platform: string;
}


const ProjInfoBrief = (props: Props) => {

    const titlePlatform = useRef('');
    const lastPlatform = useRef('');

    if (lastPlatform.current !== props.platform) {
        switch (props.platform) {
            case PlatformCLX:
                titlePlatform.current = 'ControlLogix 1756';
                break;
            default:
                titlePlatform.current = '';
                break;
        }
    }

    const industry = getIndustryDisplayStr(props.platform, props.project.config.industryID);
    const installLoc = getInstallLocDisplayStr(props.platform, props.project.config.installLocID);
    return (
        <div className="project-info-layout-text-section">
            <div className="proj-info-layout-platform-title a-type__subTitle2">
                {titlePlatform.current}
            </div>
            <div className="proj-info-layout-cfg-name">
                {props.project.config.projectName}
            </div>
            <div className="proj-info-layout-grid">
                <div className="proj-info-layout-grid-left">
                    Industry:
                </div>
                <div className="proj-info-layout-grid-right">
                    {industry}
                </div>
                <div className="proj-info-layout-grid-left">
                Installation Location:
                </div>
                <div className="proj-info-layout-grid-right">
                    {installLoc}
                </div>
            </div>
            <div style={{
                borderBottom: '1px solid rgba(0,0,0, 0.3)',
                alignSelf: 'flex-start',
                width: '95%',
                maxWidth: '280px'
            }}
             />
        </div >
    );

}

export default ProjInfoBrief;
