import { Enable5015FlexHAPlatform, Enable2080MicroPlatform } from "../types/Globals";
import { logger } from "../util/Logger";
import {
    BusCodeCLX,
    BusCodeCpLX,
    BusCodeFlex,
    BusCodeFlexHA,
    FamilyCLX,
    FamilyCpLX,
    FamilyFlex,
    FamilyFlexHA,
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex,
    PlatformFlexHA,
    PlatformMicro,
    BusCodeMicro,
    FamilyMicro
} from "./PlatformConstants";


const _mapPlatformToBusCode = new Map<string, string>();
const _mapBusCodeToPlatform = new Map<string, string>();
const _mapPlatformToFamily = new Map<string, string>();
const _mapFamilyToPlatform = new Map<string, string>();


const _loadEntry = (platform: string, busCode: string, family: string) => {
    _mapPlatformToBusCode.set(platform, busCode);
    _mapBusCodeToPlatform.set(busCode, platform);
    _mapPlatformToFamily.set(platform, family);
    _mapFamilyToPlatform.set(family, platform);
}

const _loadPlatformMaps = () => {
    _loadEntry(PlatformCLX, BusCodeCLX, FamilyCLX);
    _loadEntry(PlatformCpLX, BusCodeCpLX, FamilyCpLX);
    _loadEntry(PlatformFlex, BusCodeFlex, FamilyFlex);
    if(Enable2080MicroPlatform)
    _loadEntry(PlatformMicro, BusCodeMicro, FamilyMicro);
    if(Enable5015FlexHAPlatform)
        _loadEntry(PlatformFlexHA, BusCodeFlexHA, FamilyFlexHA);

    // FUTURE
    // _loadEntry(PlatformFlexHA, BusCodeFlexHA);
}

_loadPlatformMaps();

export const isSupportedPlatform = (platform: string): boolean => {
    if (_mapPlatformToBusCode.has(platform)) {
        return true;
    }

    logger.error('isSupportedPlatform - not supported: ' + platform);
    return false;
}

export const isSupportedBusCode = (busCode: string): boolean => {
    if (_mapBusCodeToPlatform.has(busCode)) {
        return true;
    }

    logger.error('isSupportedBusCode - not supported: ' + busCode);
    return false;
}

export const isSupportedFamily = (family: string): boolean => {
    if (_mapFamilyToPlatform.has(family)) {
        return true;
    }

    logger.error('isSupportedFamily - not supported: ' + family);
    return false;
}

// NOTE: Caller should have already pre-confirmed
// that platform is valid and supported. This function
// throws an Error if called with an invalid platform.
export const getBusCodeFromPlatform = (platform: string): string => {
    const busCode = _mapPlatformToBusCode.get(platform);
    if (busCode) {
        return busCode;
    }
    throw new Error('getBusinessCodeFromPlatform: Unsupported platform: ' + platform);
}

// NOTE: Caller should have already pre-confirmed
// that busCode is valid and supported. This function
// throws an Error if called with an invalid busCode.
export const getPlatformFromBusCode = (busCode: string) => {
    const platform = _mapBusCodeToPlatform.get(busCode);
    if (platform) {
        return platform;
    }
    throw new Error('getPlatformFromBusinessCode: Unsupported busCode: ' + busCode);
}

// NOTE: Caller should have already pre-confirmed
// that platform is valid and supported. This function
// throws an Error if called with an invalid platform.
export const getFamilyFromPlatform = (platform: string): string => {
    const family = _mapPlatformToFamily.get(platform);
    if (family) {
        return family;
    }
    throw new Error('getFamilyFromPlatform: Unsupported platform: ' + platform);
}

// NOTE: Caller should have already pre-confirmed
// that family is valid and supported. This function
// throws an Error if called with an invalid family.
export const getPlatformFromFamily = (family: string): string => {
    const platform = _mapFamilyToPlatform.get(family);
    if (platform) {
        return platform;
    }
    throw new Error('getPlatformFromFamily: Unsupported family: ' + family);
}


//export const getPlatformFromUrlComps = (locInfo: UrlComponents):
//    [result: StartupResult, platform: string] => {

    
//    const family = (locInfo.path.length > 1)
//        ? locInfo.path.substring(1)
//        : '';

//    if (family) {
//        if (isSupportedFamily(family)) {
//            return [StartupResult.OkToStart, getPlatformFromFamily(family)];
//        }
//    }


//    if (locInfo.search) {
//        const srchParms = new URLSearchParams(locInfo.search);
//    }

//    // Get the business code specified, if any.
//    const busCode = queryParams.get(BusCodeUrlParam);

//    // If there was one...
//    if (busCode) {

//        // Check to see if we recognize it 
//        // as one we support. If so...
//        if (isSupportedBusCode(busCode)) {

//            // Return the associated platform.
//            return getPlatformFromBusCode(busCode);
//        }
//        else {
//            logger.error('Requested Business code not supported: ' + busCode);
//        }
//    }

//    // If we're still here, return the
//    // fallback default platform.
//    return DefaultPlatform;
//}

