import React, { useState } from 'react';
import './OLD_ModuleSelection.scss'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { ModuleSelectionTreeDevice } from './ModuleSelectionTreeDevice';
import { ModuleTreeFolder } from '../util/ModSelectionHelp';
import { EngInfoModule } from '../engData/EngineeringInfo';


interface Props {
	folder: ModuleTreeFolder;
	level: number;
	onModuleAdded: (module: EngInfoModule, auto: boolean) => void;
}


export const ModuleSelectionTreeFolder = (props: Props) => {
	const [expanded, setExpanded] = useState(props.folder.defaultExpanded);

	let nextKey = 0;

	return (
		<div className="mstree-folder">
			<div className="mstree-folder-clickable" onClick={() => setExpanded(!expanded)}>
				<div className="mstree-folder-icon">
					{
						expanded ?
							<IndeterminateCheckBoxOutlinedIcon fontSize="small" />
							:
							<AddBoxOutlinedIcon fontSize="small" />
					}
				</div>
				<div className="mstree-folder-title">
					{props.folder.title}
				</div>
			</div>
			{
				expanded ?
					<div className="mstree-folder-children">
						{/*Add any folders*/}
						{
							props.folder.treeFolders.map((folder) => {
								return (<ModuleSelectionTreeFolder
									folder={folder}
									level={props.level + 1}
									onModuleAdded={props.onModuleAdded}
									key={nextKey++}
								/>
								)
							})
						}

						{/*Add any items*/}
						<div className='mstree-device-children'>
							{
								props.folder.treeDevices.map((dev) => {
									return (
										<ModuleSelectionTreeDevice
											device={dev}
											level={props.level + 1}
											onModuleAdded={props.onModuleAdded}
											key={nextKey++}
										/>
									)
								})
							}
						</div>

					</div>
					:
					null
			}
		</div>
	);
}

