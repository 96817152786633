import { clientConfig, IConfigSpec, IWindowEnvSpec } from "platform-services";
//import { IConfigSpec } from "platform-services";


declare global {
    interface Window {
        // Required for getting the environment
        // variables from /public/env.js for the
        // release appStage.
        env: IWindowEnvSpec;  
    }
  }

// NOTE: platform-services now provides all config
// and identity related interfaces and functionality.
// Client applications pass platform-services configuration
// and options using an IClientConfig object. That DOES 
// allow the identity configuration and a config specifying
// URL locations for things like API calls to be specified
// by the client, but CSA does NOT currently override anything,
// instead opting to use platform-services defaults. If there
// comes a time when CSA needs to override the config info,
// uncomment the following and include the override object in
// our IClientConfig object (see PlatformServicesConfig.ts).

//const local: IConfigSpec = {
//    ISD_URL: 'https://localhost:3000',
//    PW_API: 'https://productadvisor-bomapidev.rockwellautomation.com',
//    WEB_API: 'https://productadvisor-apidev.rockwellautomation.com',
//    BOM_API: 'https://productconfigurationapi.advisordev.rockwellautomation.com',
//    IDENTITY_SERVER: 'https://as-external-advisor-centralus-dev-adfs-proxy.azurewebsites.net',
//    GUIDESEL_API: 'https://guidedselection.advisordev.rockwellautomation.com/api/GuidedSelections',
//    COUNTRY_API: 'https://guidedselection.advisordev.rockwellautomation.com/api/Countries',
//    INDUSTRY_API: 'https://guidedselection.advisordev.rockwellautomation.com/api/Industries',
//    PRODSEL_API: 'https://controlsystemhardwaregen.advisordev.rockwellautomation.com/api/ProductSelections',
//    PLATFORMDATA_API: 'https://controlsystemengineeringdata.advisordev.rockwellautomation.com/EngineeringData/GetEngineeringDataByBusinessType',
//    PRODUCT_CATALOG: 'https://advisordev.rockwellautomation.com/',
//    PW_SITE: 'https://advisordev.rockwellautomation.com/projects/',
//    PCA_SITE: 'https://powercontrol.advisordev.rockwellautomation.com/',
//    RA_HEADER_ORIGIN: 'https://dev-aem.rockwellautomation.com',
//    PROJECTS_API: 'https://projectapi.advisordev.rockwellautomation.com',
//    CONTROLSYSTEMCONFIGURATION_API: 'https://controlsystemconfigurationapi.advisordev.rockwellautomation.com',
//    PROJECTS_SITE: 'https://projects.advisordev.rockwellautomation.com/',
//    CS_PAGE: 'https://advisordev.rockwellautomation.com/systemdesigner/controlsystem',
//    SERVER_ENV: 'DEV' // ('DEV', 'QA', or 'PROD')
//};

//const release: IConfigSpec = {
//    ISD_URL: window.env.ISD_URL,
//    PW_API: window.env.PW_API,
//    WEB_API: window.env.WEB_API,
//    BOM_API: window.env.BOM_API,
//    IDENTITY_SERVER: window.env.IDENTITY_SERVER,
//    GUIDESEL_API: window.env.GUIDESEL_API,
//    COUNTRY_API: window.env.COUNTRY_API,
//    INDUSTRY_API: window.env.INDUSTRY_API,
//    PRODSEL_API: window.env.PRODSEL_API,
//    PLATFORMDATA_API: window.env.PLATFORMDATA_API,
//    PRODUCT_CATALOG: window.env.PRODUCT_CATALOG,
//    PW_SITE: window.env.PW_SITE,
//    PCA_SITE: window.env.PCA_SITE,
//    RA_HEADER_ORIGIN: window.env.RA_HEADER_ORIGIN,
//    PROJECTS_API: window.env.PROJECTS_API,
//    CONTROLSYSTEMCONFIGURATION_API: window.env.CONTROLSYSTEMCONFIGURATION_API,
//    PROJECTS_SITE: window.env.PROJECTS_SITE,
//    CS_PAGE: window.env.CS_PAGE,
//    SERVER_ENV: window.env.SERVER_ENV // ('DEV', 'QA', or 'PROD')
//};

//export let csaCustomConfig = local;

//// Allow developers to know what config
//// is being used by exporting the appStage.
//export const appStageCSA = process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE.trim() : undefined;

//switch (appStageCSA) {
//    case 'local':
//        csaCustomConfig = local;
//        break;
//    case 'release':
//        csaCustomConfig = release;
//        break;

//    default:
//        // Per handling in Product Advisor, an error is thrown.
//        // We will add a bit more information though.
//        throw new Error(`Unable to determine Application Stage: process.env.REACT_APP_STAGE === ${process.env.REACT_APP_STAGE}(?)`);
//}

export const getConfigSpec = (): IConfigSpec => {
    if (clientConfig.configSpec) {
        return clientConfig.configSpec;
    }
    throw new Error('ERROR: Missing spec in getConfigSpec!');
}