import { EngInfoModule } from "../engData/EngineeringInfo";
import { getModuleSelectionInfo } from "../implementation/ImplGeneral";
import { Chassis, ModuleSlotRestriction } from "../types/ProjectTypes";
import { logger } from "./Logger";


export interface ModuleTreeFolder {
    title: string;
    treeFolders: ModuleTreeFolder[];
    treeDevices: EngInfoModule[];
    defaultExpanded: boolean;
}

const _getNewModuleTreeFolder = (title: string): ModuleTreeFolder => {
    return {
        title: title,
        treeDevices: [],
        treeFolders: [],
        defaultExpanded: false,
    }
}

const _getOrCreateSubFolder = (folder: ModuleTreeFolder, title: string): ModuleTreeFolder => {

    if (title) {
        const existing = folder.treeFolders.find(fldr => fldr.title === title);
        if (existing) {
            return existing;
        }
        else {
            const newSubFldr = _getNewModuleTreeFolder(title);
            folder.treeFolders.push(newSubFldr);
            return newSubFldr;
        }
    }
    else {
        return folder;
    }
}

const _addModToSelTree = (mod: EngInfoModule, tree: ModuleTreeFolder) => {
    const typeFldr = _getOrCreateSubFolder(tree, mod.type);
    const subTypeFldr = _getOrCreateSubFolder(typeFldr, mod.subType1);
    subTypeFldr.treeDevices.push(mod);
}

export const makeModuleTreeFor = (chassis: Chassis, restict: ModuleSlotRestriction): ModuleTreeFolder => {

    const tree = _getNewModuleTreeFolder('Modules');

    const suitables = getModuleSelectionInfo(chassis, restict);
    logger.warn('Module matches for chassis ' + chassis.catNo + ': ' + suitables.length);

    suitables.forEach(modInfo => {
        _addModToSelTree(modInfo, tree);
    });

    return tree;
}

