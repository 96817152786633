import React from 'react';
import { ChoicesSubgroup } from '../../types/MessageTypes';
import ChoiceGroupHdrTipIcon from './ChoiceGroupHdrTipIcon';
import './Choices.scss';


interface Props {
    subgroup: ChoicesSubgroup;
}


const ChoiceGroupHdr = (props: Props) => {

    return (
        <div className='choices-group-header'>
            {props.subgroup.label}
            {props.subgroup.generalTipInfo
                ? <ChoiceGroupHdrTipIcon
                    tipInfo={props.subgroup.generalTipInfo}
                />
                : null}
            {props.subgroup.situationalTipInfo
                ? <ChoiceGroupHdrTipIcon
                    tipInfo={props.subgroup.situationalTipInfo}
                />
                : null}
        </div>
    );
}

export default ChoiceGroupHdr;
