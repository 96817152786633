import React from 'react';
import { ProjectSetting, SettingOption } from '../types/SettingsTypes';
import './Settings.scss';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';


interface Props {
    setting: ProjectSetting;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
}

const ComboSettingMobile = (props: Props) => {

    const onChoiceMade = (event: SelectChangeEvent) => {
        //props.valueChanged(props.setting, event.target.value as string);
        const newOption = props.setting.options.find(x => x.id === event.target.value as string);
        props.valueChanged(props.setting, newOption);

    };

    let nextChoiceKey = 1;

    if (props.setting.visible === false)
        return null;

   return (
        <div>
            <FormControl>
                <InputLabel id="simple-select-label">{props.setting.name}</InputLabel>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={props.setting.selectedOption.id}
                    label={props.setting.name}
                    size={'small'}
                    sx={{ width: 280 }}
                    onChange={onChoiceMade}
                >
                   {props.setting.options.map(choice => {
                        return (
                            <MenuItem
                                key={nextChoiceKey++}
                                value={choice.id}
                            >
                                {choice.display}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
export default ComboSettingMobile;

