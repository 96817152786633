import { updateGuidedSelection } from '../../../model/GuidedSelection';
import { ChassisCfgGrpCategory, createNewLocAttributeInfo, LocAttributeInfo, onCfgLocAttrCreatedCallback } from '../../../types/ProjectTypes';
import { ChassisProject, EnvRating, IOModuleWiring, PSInputVoltage } from "../../../types/ProjectTypes";
import { getUserModuleSelectionsFromPointSectionInfo } from "../../../model/IOModule";
import { refreshLocAttrInfoSelectionArray } from "../../../model/GuidedSelection";
import { collectHardwareInfo, KeyValuePair, ProdSelCategory, PSHardwareInfo } from "../../../model/ProductSelection"
import { displayAlertMsg } from "../../../util/MessageHelp";
import { PlatformCLX } from '../../PlatformConstants';
import { IOEntryModeEnum } from '../../../types/SettingsTypes';
import { makeSettingGroup } from '../../../settings/SettingsHelp';
import { logger } from '../../../util/Logger';
import { convertGuidedSelAttrToAppVal } from '../../../implementation/ImplHardwareGen';


export const clxPrepareLocAttrHardwareForGen = (loc: LocAttributeInfo, project: ChassisProject) => {
    refreshLocAttrInfoSelectionArray(loc);

    // Hardcode for consistency: Attribute 'ER' - Env. Rating.
    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er)
        loc.hardware.envRating = convertGuidedSelAttrToAppVal(PlatformCLX, er.attrID, er.optionID, true) as EnvRating;

    // Hardcode necessity: Attribute 'CTRL' - defines whether a 
    // configuration will be generated with or without a Controller
    loc.hardware.remoteIOOnly = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'CTRL' && attr.optionID === 'No');

    // Hardcode necessity: Attribute 'CT' - Defines whether I/O 
    // will be placed in Controller chassis or if all I / O will 
    // be in remote chassis. Value 'Ded' for 'dedicated'.
    loc.hardware.ctrlDedicatedChassis = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'CT' && attr.optionID === 'Ded');

    // Hardcode necessity: Attribute 'NT' - Defines the level of
    // resiliancy on network connections.Simplex - No fault tolerance,
    // DLR - Single - fault tolerance, PRP - Multiple - fault tolerance".
    // Simplex does not require the Ctrl Chassis to have a Comm.
    const simplexArchitecture = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'NT' && attr.optionID === 'SIM');
    loc.hardware.ctrlChassisNeedsScanner = (simplexArchitecture === false);

    // Hardcode for Controller Availability ('CA');
    const ca = loc.arrAttributeNameToValue.find(x => x.attrID === 'CA');
    if (ca)
        loc.hardware.redCtrlChassis = convertGuidedSelAttrToAppVal(PlatformCLX, ca.attrID, ca.optionID, true) as boolean;

    loc.hardware.ioModuleSelections = getUserModuleSelectionsFromPointSectionInfo(loc.pointEntrySection, project.config.IOEntryMode);
    loc.hardware.ioModuleSelections.forEach((sel) => {
        for (let idx = 0; idx < sel.quantity; ++idx)
            loc.hardware.catIOModules.push(sel.catalog);
    });

    loc.hardware.ioModuleCount = loc.hardware.catIOModules.length;

    // Hardcode necessity: Attribute 'CS' - Defines the fixed slot
    // chassis size to be used in the configuration for all chassis.
    // (Auto - size of Ctrl/Remote Chassis(s) depending upon your
    // I/O requirements and Dedicated Ctrl Chassis).
    let multipleChassisAndPSU = false;
    const additionalAttributes: KeyValuePair[] = [];

    let chassisSize = 0;
    const cs = loc.arrAttributeNameToValue.find(x => x.attrID === 'CS');
    if (cs)
        chassisSize = Number(convertGuidedSelAttrToAppVal(PlatformCLX, cs.attrID, cs.optionID, false));

    if (isNaN(chassisSize) || chassisSize === 0) {
        // For Chassis Size, the Attr Option ID is the slot count of
        // the chassis. Pass these extra attributes to collectHardwareInfo().
        // The result should be that we get can get multiple Chassis(s)
        // and PSUs returned. Based on the Chassis Size we should be able
        // to determine which one is for the Controller chassis and which
        // is for the remote Remote chassis.
        clxSetAutoChassisSizesInHardware(loc);

        // If the Controller Chassis is Dedicated (no I/O allowed)
        // the numCtrlChassisSlot could be different from the
        // the remote I/O chassis slot count.
        if (loc.hardware.remoteIOOnly === false && loc.hardware.ctrlDedicatedChassis) {
            if (loc.hardware.numCtrlChassisSlot && loc.hardware.numCtrlChassisSlot !== 0) {
                additionalAttributes.push({ Key: 'CS', Value: loc.hardware.numCtrlChassisSlot.toString() });
                multipleChassisAndPSU = true;
            }
        }

        // We should always get a remote chassis slot count!
        if (loc.hardware.numChassisSlot) {
            additionalAttributes.push({ Key: 'CS', Value: loc.hardware.numChassisSlot.toString() });
        }
        else {
            throw new Error('Remote CLX Chassis slot count is undetermined!');
        }
    }
    else {
        loc.hardware.numChassisSlot = chassisSize;
    }

    const arrChassis: PSHardwareInfo[] = [];
    const arrPowrSupplies: PSHardwareInfo[] = [];

    const arrHardware = collectHardwareInfo(loc, additionalAttributes);
    arrHardware.forEach((hwInfo) => {
        switch (hwInfo.category) {
            case ProdSelCategory.Controller:
                loc.hardware.catController = hwInfo.mainCatalog;
                loc.hardware.catControllerPartner = hwInfo.associatedCatalogs;
                break;
            case ProdSelCategory.Chassis:
                if (multipleChassisAndPSU)
                    arrChassis.push(hwInfo);
                else
                    loc.hardware.catChassis = hwInfo.mainCatalog;
                break;
            case ProdSelCategory.Comm:
                loc.hardware.catScanner = hwInfo.mainCatalog;
                break;
            case ProdSelCategory.PSU:
                if (multipleChassisAndPSU)
                    arrPowrSupplies.push(hwInfo);
                else {
                    loc.hardware.catPowerSupply = hwInfo.mainCatalog;
                    loc.hardware.catRemotePowerSupplier = loc.hardware.catPowerSupply;
                }
                break;
            case ProdSelCategory.SlotFiller:
                loc.hardware.catSlotFiller = hwInfo.mainCatalog;
                break;
            default:
                displayAlertMsg('Unknown Hardware Category: ' + hwInfo.category);
                break;
        }
    });

    if (multipleChassisAndPSU) {
        // If we have only one entry in the arrays...
        if (arrChassis.length === 1 && arrPowrSupplies.length === 1) {
            // Sanity check - Both Ctrl and Remote chassis size should
            // always be the same when we have 1 element arrays.
            if (loc.hardware.numChassisSlot !== loc.hardware.numCtrlChassisSlot)
                throw new Error('Unable to resolve Controller and Remote Chassis components.');

            loc.hardware.catControllerChassis = arrChassis[0].mainCatalog;
            loc.hardware.catChassis = arrChassis[0].mainCatalog;
            loc.hardware.catPwrSupCtrlChassis = arrPowrSupplies[0].mainCatalog;
            loc.hardware.catPowerSupply = arrPowrSupplies[0].mainCatalog;
        }
        else {
            // Based on the Controller Chassis Size, get the
            // hardware info from our Chassis and PSU array.
            // Set the Controller Chassis and PSU.
            let chassisSizeAttr = (loc.hardware.numCtrlChassisSlot ? `CS:${loc.hardware.numCtrlChassisSlot}` : 'Error');
            let hwInfo = arrChassis.find((info) => info.attributes.some((attr) => attr === chassisSizeAttr));
            if (hwInfo)
                loc.hardware.catControllerChassis = hwInfo.mainCatalog;
            hwInfo = arrPowrSupplies.find((info) => info.attributes.some((attr) => attr === chassisSizeAttr));
            if (hwInfo)
                loc.hardware.catPwrSupCtrlChassis = hwInfo.mainCatalog;

            // Set the remote chassis
            chassisSizeAttr = (loc.hardware.numChassisSlot ? `CS:${loc.hardware.numChassisSlot}` : 'Error');
            hwInfo = arrChassis.find((info) => info.attributes.some((attr) => attr === chassisSizeAttr));
            if (hwInfo)
                loc.hardware.catChassis = hwInfo.mainCatalog;
            hwInfo = arrPowrSupplies.find((info) => info.attributes.some((attr) => attr === chassisSizeAttr));
            if (hwInfo)
                loc.hardware.catPowerSupply = hwInfo.mainCatalog;
        }

        // Set the remote and Ctrl power suppliers
        loc.hardware.catRemotePowerSupplier = loc.hardware.catPowerSupply;
        loc.hardware.catCtrlPowerSupplier = loc.hardware.catPwrSupCtrlChassis;

        // Validate that we have selections specifically for the
        // dedicated Controller Chassis case.
        if (!loc.hardware.catControllerChassis ||
            !loc.hardware.catChassis ||
            !loc.hardware.catPwrSupCtrlChassis ||
            !loc.hardware.catPowerSupply) {
            throw new Error('Failed to select valid CLX Controller and Remote chassis(s) and PSUs.');
        }
    }

    // Validate the hardware - we should always have values for
    // the remote I/O chassis/PSU/Comm
    if (!loc.hardware.catChassis ||
        !loc.hardware.catPowerSupply ||
        !loc.hardware.catScanner)
        throw new Error('Failed to select valid CLX Remote chassis(s) and PSUs.');

    // If we do NOT have Remote I/O Only and a dedicated Controller Chassis...
    if (multipleChassisAndPSU) {
        if (!loc.hardware.catControllerChassis ||
            !loc.hardware.catPwrSupCtrlChassis)
            throw new Error('Failed to select valid CLX Controller chassis(s) and PSUs.')
    }
    return;
}


export const clxSetAutoChassisSizesInHardware = (loc: LocAttributeInfo) => {
    // We are here because a user has selected AUTO CHASSIS SIZE....
    // The hardware should already have the ioModuleCount set
    // AND whether or not the Controller Chassis is Dedicated.

    // Hardcode: Attribute 'ER' - Envoronmental Rating.
    const erXT = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'ER' && attr.optionID === 'XT');

    // If we have a dedicated controller chassis (no I/O in it),
    // set the chassis size to 4/7 slot depending on Env Rating.
    // Note: if Ctrl not in dedicated chassis, the I/O chassis
    // size is used for the controller.
    if (loc.hardware.ctrlDedicatedChassis) {
        if (erXT)
            loc.hardware.numCtrlChassisSlot = 7;
        else
            loc.hardware.numCtrlChassisSlot = 4;
    }

    // For the remote I/O and possibly the controller chassis,
    // Check for the defined cases for 10 or 13 slot cases and
    // finally go big with the 17 slot (if exists) chassis.
    if ((loc.hardware.ioModuleCount <= 6)) {
        loc.hardware.numChassisSlot = 7;
    }
    else if ((loc.hardware.ioModuleCount <= 9) ||
        (loc.hardware.ioModuleCount >= 17 && loc.hardware.ioModuleCount <= 18)) {
        loc.hardware.numChassisSlot = 10;
    }
    else if ((loc.hardware.ioModuleCount <= 12) ||
        (loc.hardware.ioModuleCount >= 19 && loc.hardware.ioModuleCount <= 24) ||
        (loc.hardware.ioModuleCount >= 33 && loc.hardware.ioModuleCount <= 36)) {
        if (erXT)
            loc.hardware.numChassisSlot = 10;
        else
            loc.hardware.numChassisSlot = 13;
    }
    else {
        if (erXT)
            loc.hardware.numChassisSlot = 10;
        else
            loc.hardware.numChassisSlot = 17;
    }
}


export interface CLXChassisCfgData {
    envType: EnvRating;
    numSlots: number;
    wiringType: IOModuleWiring;
    highAvail: boolean;
    numRMs: number;
    psVoltage: PSInputVoltage;
    psSelCatalog: string;
    chassisCatalog: string;
}

export const createCLXChassisCfgData = (): CLXChassisCfgData => {
    return {
        envType: EnvRating.Standard,
        numSlots: 10,
        wiringType: IOModuleWiring.Screw,
        highAvail: false,
        numRMs: 0,
        psVoltage: PSInputVoltage.DC24V,
        psSelCatalog: '',
        chassisCatalog: '',
    };
}

export const clxGetCLXChassisCfgDataFromLocAttr = (loc: LocAttributeInfo): CLXChassisCfgData => {
    const data = createCLXChassisCfgData();
    // Refresh the selections in the loc's arrAttributeNameToValue.
    refreshLocAttrInfoSelectionArray(loc);

    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er)
        data.envType = convertGuidedSelAttrToAppVal(PlatformCLX, er.attrID, er.optionID, true) as EnvRating;

    const ca = loc.arrAttributeNameToValue.find(x => x.attrID === 'CA');
    if (ca)
        data.highAvail = convertGuidedSelAttrToAppVal(PlatformCLX, ca.attrID, ca.optionID, true) as boolean;

    const cv = loc.arrAttributeNameToValue.find(x => x.attrID === 'CV');
    if (cv)
        data.psVoltage = convertGuidedSelAttrToAppVal(PlatformCLX, cv.attrID, cv.optionID, true) as PSInputVoltage;

    const cs = loc.arrAttributeNameToValue.find(x => x.attrID === 'CS');
    if (cs)
        data.numSlots = convertGuidedSelAttrToAppVal(PlatformCLX, cs.attrID, cs.optionID, true) as number;

    const rtb = loc.arrAttributeNameToValue.find(x => x.attrID === 'RTB');
    if (rtb)
        data.wiringType = convertGuidedSelAttrToAppVal(PlatformCLX, rtb.attrID, rtb.optionID, true) as IOModuleWiring;

    //	const arrHW = collectHardwareInfo(loc, [{ Key: 'CS', Value: data.numSlots.toString() }], ProdSelCategory.PSU);
    const arrHW = collectHardwareInfo(loc, [{ Key: 'CS', Value: data.numSlots.toString() }]);
    arrHW.forEach((comp) => {
        switch (comp.category) {
            case ProdSelCategory.PSU:
                data.psSelCatalog = comp.mainCatalog;
                break;
            case ProdSelCategory.Chassis:
                data.chassisCatalog = comp.mainCatalog;
                break;
            default:
                break;
        }
    })

    return data;
}

////////////// Config/Edit Chassis Dlg driven off of Guided Selection ////////////////////////////
// We are using the Guided Selection and it's rules
// to drive most of the Chassis Cfg Dialog. For CLX,
// the Power Supply selection will be based on the
// Control Voltage selected in the Guided Selection,
// BUT the actual PSU choice we be from a PSU list
// generated based on Engineering Data.

let _cfgAttrCallback: onCfgLocAttrCreatedCallback | undefined = undefined;
export const clxGetLocAttrInfoForChassisEdit = (platform: string, callback: onCfgLocAttrCreatedCallback) => {
    _cfgAttrCallback = callback;

    const configAttrInfo = createNewLocAttributeInfo(PlatformCLX, '', '', IOEntryModeEnum.Basic);

    // Note: We skip validation so that everything is
    // reloaded and all options are present (3rd param === true)
    updateGuidedSelection(configAttrInfo, _onGdSelForChassisEditLoaded, true);
}

export const clxPrepareLocAttrForChassisConfig = (locAttrInfo: LocAttributeInfo): boolean => {
    // Start by pulling ALL of the settings into a groups.
    // The idea here is to rearrange the settings into 3
    // attribute groups: Cfg Page 1; Cfg Page 2; and all
    // the other settings not in the first 2 pages. The
    // pages relate to the Cfg Dlg Tabs.
    const cfgDlgPage1 = makeSettingGroup(ChassisCfgGrpCategory.Chassis);
    const cfgDlgPage2 = makeSettingGroup(ChassisCfgGrpCategory.Power);
    const cfgNonDisplay = makeSettingGroup(ChassisCfgGrpCategory.Hidden);

    // Preprocess our settings
    locAttrInfo.attrGroups.forEach((grp) => {
        grp.settings.forEach((setting) => {
            const idxAutoOption = setting.options.findIndex(x => x.id === 'Auto');
            if (idxAutoOption >= 0) {
                // Remove it from the setting options.
                setting.options.splice(idxAutoOption, 1);

                // Remove it from our map.
                if (setting.gsMapOptionTxtValToInfo) {
                    let mapKeyForAuto = '';
                    setting.gsMapOptionTxtValToInfo.forEach((val, key) => {
                        if (val.id === 'Auto')
                            mapKeyForAuto = key;
                    });

                    if (mapKeyForAuto)
                        setting.gsMapOptionTxtValToInfo.delete(mapKeyForAuto);
                }

                // Select the first entry IF Auto is selected.
                if (setting.selectedOption.id === 'Auto')
                    setting.selectedOption = setting.options[0];
            }
        })
    });

    // Note: the settings will be added in the order that
    // they appear in the guide selection, which at this
    // point is the correct order. If that changes, we will
    // need to add a sort function or a smarter way of
    // building out each page's setting array.
    locAttrInfo.attrGroups.forEach((grp) => {
        grp.settings.forEach((setting) => {
            switch (setting.id) {
                case 'ER':
                case 'CS':
                case 'RTB':
                case 'CA':
                    cfgDlgPage1.settings.push(setting);
                    break;
                case 'CV':
                    cfgDlgPage2.settings.push(setting);
                    break;
                default:
                    // 2024.4.22 Ignore any errors on
                    // the hidden attributes. These
                    // attributes should NOT impact 
                    // the Edit/Add of a chassis.
                    setting.ignoreError = true;

                    cfgNonDisplay.settings.push(setting);
                    break;
            }
        });
    });

    // Validate we have the correct number of settings in
    // each page. From above, Page1 has 4 and Page2 has 1.
    const valid = (cfgDlgPage1.settings.length === 4 && cfgDlgPage2.settings.length === 1);
    if (valid === false)
        logger.error('clxGetLocAttrInfoForChassisEdit(): Error - not all required attribute settings found in Guided Selection.');

    // Dump the original attr groups, and add
    // the new groups.
    locAttrInfo.attrGroups.length = 0;
    locAttrInfo.attrGroups.push(cfgDlgPage1);
    locAttrInfo.attrGroups.push(cfgDlgPage2);
    locAttrInfo.attrGroups.push(cfgNonDisplay);

    return valid;
}

const _onGdSelForChassisEditLoaded = (success: boolean, locAttrInfo: LocAttributeInfo | undefined) => {
    if (_cfgAttrCallback == null)
        throw new Error('clxGetLocAttrInfoForChassisEdit(): callback function undefined!');

    if (success && locAttrInfo) {
        const valid = clxPrepareLocAttrForChassisConfig(locAttrInfo);
        _cfgAttrCallback(valid, locAttrInfo);
        return;
    }

    throw new Error('_onGdSelForChassisEditLoaded(): Guided Selection failed!');
}