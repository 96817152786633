import { RegisterFlexCheckerImpl } from "./FlexChecker";
import { RegisterFlexEngDataImpl } from "./FlexEngDataImpl";
import { RegisterFlexGeneralImpl } from "./FlexGeneralImpl";
import { registerFlexHWGenImpl } from "./FlexHardwareImpl";



export const initFlexPlatform = (): boolean => {
	registerFlexHWGenImpl();
	RegisterFlexGeneralImpl();
	RegisterFlexCheckerImpl();
	RegisterFlexEngDataImpl();

	return true;
}