import productApiService, { raTimeoutMessage } from "../services/apis/ProductApiService";
import { getCurrencyValueFromString } from "../util/CurrencyHelp";
import ServiceResponse from '../services/apis/Response';
import { AxiosError } from "axios";
import { logger } from "../util/Logger";
import { SkipRaiseData } from "../types/Globals";
import { RADBCacheStatus, RADBFetchStatus, txtRADB_LOADING, txtRADB_NA } from "../types/APITypes";
// Our repository!
const _Repository = new Map<string, RaiseDevData>;

// In the event we get more than one REQUEST
// for a device BEFORE it is done fetching the
// data, we can broadcast to all requestors when
// the data has been resolved.
const _callbacks = new Map<string, _fetchCallback[]>;

// Abort after 15 seconds;
const _fetchTimeout = 15000;  

// Set of catalogs that have timed out.
const _mapCatalogToFetchErr = new Map<string, number>;
const _mapCatalogToFetchErrCount = new Map<string, number>;

// Max number of pending requests we will
// hit the server with at any given time.
// Any more will be 'Queued for Fetch'.
const _maxPendingRequests = 15;

// MAX errors before we will NOT attempts a re-fetch.
// Note: Timing out is not an error.
const _dataRefetchMaxErrors = 3;

// Arrays for currently being fetched and 
// 'awaiting to be fetched' catalogs.
const _pendingFetch = new Set<string>();
const _queuedForFetch: string[] = [];

const txtSkip = 'SKIP';

export interface RaiseDevData {
	status: RADBCacheStatus,
	lastFetchStatus: RADBFetchStatus,
	fetchErrorCount: number;
	catalogNumber: string;
	displayPrice: string;
	displayPriceVal: number;
	prodLifeCycleStatus: string;
	stockStatusDisplay: string;
}

const _skippedRaiseDevData: RaiseDevData = {
	status: RADBCacheStatus.None,
	lastFetchStatus: RADBFetchStatus.None,
	fetchErrorCount: 0,
	catalogNumber: '',
	displayPrice: txtSkip,
	displayPriceVal: 0,
	prodLifeCycleStatus: txtSkip,
	stockStatusDisplay: txtSkip
}

export const getNewRaiseDevData = (catalog: string, loadingData: boolean): RaiseDevData => {
	if (loadingData) {
		return {
			status: RADBCacheStatus.Loading,
			lastFetchStatus: RADBFetchStatus.None,
			fetchErrorCount: 0,
			catalogNumber: catalog,
			displayPrice: txtRADB_LOADING,
			displayPriceVal: 0,
			prodLifeCycleStatus: txtRADB_LOADING,
			stockStatusDisplay: txtRADB_LOADING,
		};
	}

	// Get the status and REMOVE the status entry from 
	// our map. This is an End-Of-Fetch call.
	const errStatus = getDataFetchStatus(catalog, true);
	return {
		status: RADBCacheStatus.Loaded,
		lastFetchStatus: errStatus.lastFetchStatus,
		fetchErrorCount: errStatus.totalErrCount,
		displayPrice: txtRADB_NA,
		displayPriceVal: 0,
		catalogNumber: catalog,
		prodLifeCycleStatus: txtRADB_NA,
		stockStatusDisplay: txtRADB_NA,
	};
}


const _doFetch = async (catalog: string) => {
	let response = null;
	try {
		// Get the request going. Note: for now we are
		// defaulting the locale to 'US'. When this
		// changes, we will also need to know how to
		// format the currency based on the locale!
		const result = await productApiService.GetDetailedProduct(catalog, 'Unknown', _fetchTimeout);
		response = new ServiceResponse(result);

		if (!response.isSuccessful()) {
			logger.error('Response NOT OK: ' + catalog);
			onCatalogError(catalog);
			return null;
		}
	}
	catch (error) {
		// If we timed out, the error will be an AxiosError. If so...
		if (error instanceof AxiosError) {
			// If we timed out, the code will be 'ECONNABORTED' and
			// the message will be raTimeoutMessage (RA_REQUEST_TIMEOUT) -
			// see productApiService.GetDetailedProduct() for details...
			if (error.code === 'ECONNABORTED' && error.message === raTimeoutMessage) {
				// Mark the catalog as timed out. This DOES qualify the
				// catalog for a re-fetch next time it is requested.
				onCatalogTimeOut(catalog);

				// We're done here.
				return null;
			}
		}

		// Get the fetch status but do NOT remove the entry
		// from our map. Note: When a request times out due to 
		// our AbortController, an error is thrown (i.e. not an error).
		// If the last request did NOT timeout...
		if (getDataFetchStatus(catalog, false).lastFetchStatus !== RADBFetchStatus.Timeout) {
			if (error instanceof Error)
				logger.error('!!! Error thrown (_doFetch): ' + catalog + ' -> ' + error.message);
			else
				logger.error('!!! Error thrown (_doFetch): ' + catalog + ' -> ' + String(error));

			// Mark the catalog fetch an having an error.
			onCatalogError(catalog);
		}

		// We're done.
		return null;
	}

	// There was not an error. Get the JSON and return it.
	const jsonResponse = response.getData();
	return jsonResponse;
}

const fetchProdData = async (catalog: string) => {
	let success = false;

	if (_pendingFetch.size >= _maxPendingRequests) {
		logger.logProdData(catalog + ' queued for fetch');
		_queuedForFetch.push(catalog);
	}
	else {
		try {
			logger.logProdData(catalog + ' fetching...');
			_pendingFetch.add(catalog);
			const response = await _doFetch(catalog);

			if (response) {
				let devData = response as RaiseDevData;

				if (devData.catalogNumber != null) {

					// Cleanup any problems in the data and trim
					// unwanted data from the original devData.
					devData = validateRaiseDevData(devData);
					// Add the entry in the map.
					_Repository.set(catalog, devData);

					// Notify the caller(s).
					notifyRequestor(catalog, devData);

					success = true;
				}
				else {
					// We have something that the raise db does not know...
					logger.warn(catalog + ' not found by Raise Query!');
				}
			}
		}
		catch (error) {
			// Get the fetch status but do NOT remove the entry
			// from our map. Note: When a request times out due to
			// our AbortController, an error is thrown (i.e. not an error).
			// If the request did NOT timeout...
			if (getDataFetchStatus(catalog, false).lastFetchStatus !== RADBFetchStatus.Timeout) {
				if (error instanceof Error)
					logger.error('!!! Error thrown (fetchProdData): ' + catalog + ' -> ' + error.message);
				else
					logger.error('!!! Error thrown (fetchProdData): ' + catalog + ' -> ' + String(error));

				onCatalogError(catalog); 
			}
		}

		// If we had an issue...
		if (success === false) {
			// Create an 'unknown' data object
			const unknown = getNewRaiseDevData(catalog, false);

			logger.error(catalog + ' NOT SUCCESSFUL' + '  Error Count: ' + unknown.fetchErrorCount);

			// Update the repository entry.
			_Repository.set(catalog, unknown);

			// Notify the requestor(s).
			notifyRequestor(catalog, unknown);
		}

		// Remove the catalog from our pending set.
		_pendingFetch.delete(catalog);

		// If we have any queued catalogs
		if (_queuedForFetch.length > 0) {
			let nextCatalog = _queuedForFetch.shift();
			while (nextCatalog == null && _queuedForFetch.length > 0)
				nextCatalog = _queuedForFetch.shift();

			if (nextCatalog != null) {
				logger.logProdData('Fetching next queued: ' + nextCatalog)
				fetchProdData(nextCatalog);
			}
		}
	}
}


// Note: by default, if we have cached device data that
// timed out, we will NOT re-fetch it. This behavior can
// be changed by setting the refetchTimedOutData param.
export const getRaiseDeviceData = (catalog: string, callback: _fetchCallback, refetchDataOnError = false): RaiseDevData => {

	if (SkipRaiseData) {
		return {
			..._skippedRaiseDevData,
			catalogNumber: catalog
		}
	}

	try {
		// Check our cache first.
		const cachedData = _Repository.get(catalog);
		if (cachedData != null) {
			// If the data is already in a loading state,
			// register the callback for the catalog. When
			// the data is resolved, all callbacks are called.
			if (cachedData.status === RADBCacheStatus.Loading) {
				// Register the callback and 
				// immediately return the data.
				registerCallback(catalog, callback);
				return cachedData;
			}

			let fetchAgain = false;
			if (refetchDataOnError && raiseDevDataQualifiesForRefetch(cachedData)) {
				if (_callbacks.has(catalog) === false) 
					fetchAgain = true;
			}

			if (!fetchAgain)
				return cachedData;
		}

		// Add a 'Loading' entry into our map so if we
		// get a another request before the request is
		// resolved, we do NOT start another fetch.
		const initData = getNewRaiseDevData(catalog, true);
		_Repository.set(catalog, initData);
		registerCallback(catalog, callback);

		// Get our fetch going...
		fetchProdData(catalog);

		return initData;
	}
	catch (error) {
		if (error instanceof Error)
			logger.error('!!! Error thrown while retrieving data for ' + catalog + ' -> ' + error.message);
		else
			logger.error('!!! Error thrown while retrieving data for ' + catalog + ' -> ' + String(error));

		onCatalogError(catalog);

		// Add an 'N/A' data entry into the repository.
		const unknown = getNewRaiseDevData(catalog, false);
		_Repository.set(catalog, unknown);

		// Notify the requestors...
		notifyRequestor(catalog, unknown);

		return unknown;
	}
}

export const getCachedPriceFor = (catNo: string): number => {

	const cachedData = _Repository.get(catNo);
	return cachedData ? cachedData.displayPriceVal : 0;

}


const validateRaiseDevData = (data: RaiseDevData): RaiseDevData => {
	// Here we are going to validate the data and return
	// an object with ONLY the data we need - meaning all
	// of the other properties returned in the json that we
	// do not need will be tossed.
	const strPrice = (data.displayPrice == null ? txtRADB_NA : data.displayPrice);

	// Get the status and REMOVE the status entry from 
	// our map. This is an End-Of-Fetch call.
	const errStatus = getDataFetchStatus(data.catalogNumber, true);

	return {
		catalogNumber: data.catalogNumber,
		status: RADBCacheStatus.Loaded,
		lastFetchStatus: errStatus.lastFetchStatus,
		fetchErrorCount: errStatus.totalErrCount,
		displayPrice: strPrice,
		displayPriceVal: getCurrencyValueFromString(strPrice),
		stockStatusDisplay: (data.stockStatusDisplay == null ? txtRADB_NA : data.stockStatusDisplay),
		prodLifeCycleStatus: (data.prodLifeCycleStatus == null ? txtRADB_NA : data.prodLifeCycleStatus),
	}
}


//// Requestor Callback Functionality

interface _fetchCallback {
	(catalog: string, data: RaiseDevData): void;
}


const registerCallback = (catalog: string, callback: _fetchCallback) => {
	// Add the callback to to a map of Catalog To
	// Array of Requestor Callbacks. When the data is 
	// resolved, broadcast the event to the callbacks.
	const entry = _callbacks.get(catalog);
	if (entry != null) {
		// Make sure we do not have the callback already.
		if (entry.some(x => x === callback) === false )
			entry.push(callback);
		else
			logger.logProdData('duplicate callback not registered.')
	}
	else {
		_callbacks.set(catalog, [callback]);
	}
}


const notifyRequestor = (catalog: string, data: RaiseDevData) => {
	// Notify the requestor(s) that the data has been resolved.
	const listeners = _callbacks.get(catalog);
	if (listeners != null) {
		listeners.forEach((fn) => fn(catalog, data));
		// Remove the entry.
		_callbacks.delete(catalog);
	}
}


//// Fetch Data Error/Timeout Functionality

const onCatalogTimeOut = (catalog: string) => {
	// Only mark the catalog as timed out if the data
	// in our respository is not marked as loaded.
	// Note: Unfortunately, reguardless if the request
	// really timeout or not, this gets called.
	// If the data is loaded, we're done.
	if (_Repository.get(catalog)?.status === RADBCacheStatus.Loaded)
		return;

	logger.logProdData(catalog + ' Timed Out');

	let err = _mapCatalogToFetchErr.get(catalog);
	if (err == null)  
		err = RADBFetchStatus.None; 

	_mapCatalogToFetchErr.set(catalog, err | RADBFetchStatus.Timeout);
}

const onCatalogError = (catalog: string) => {
	// If the catalog timed out, do not flag it as an error.
	if (getDataFetchStatus(catalog, false).lastFetchStatus === RADBFetchStatus.Timeout)
		return;

	let err = _mapCatalogToFetchErr.get(catalog);
	if (err == null)
		err = RADBFetchStatus.None;

	_mapCatalogToFetchErr.set(catalog, err | RADBFetchStatus.Error);
}

interface RADBDataFetchInfo {
	// lastFetchStatus is the status for the
	// LAST fetch attempt ONLY. Not cumulative.
	lastFetchStatus: number, 
	// count is the Total number of times we
	// could not fetch due to an ERROR, and not
	// a timeout problem.
	totalErrCount: number,
}


// Called at the END of a single fetch occurrence
const getDataFetchStatus = (catalog: string, clearMapEntry: boolean ): RADBDataFetchInfo => {
	const err = _mapCatalogToFetchErr.get(catalog);
	if (err) {
		// If not a timeout problem...
		let errCount = 0;
		if ((err & RADBFetchStatus.Error) !== 0)
			errCount = addErrorCount(catalog);

		// When getting the status before we package the final
		// data for the cache, clearMapEntry should be false.
		if (clearMapEntry)
			_mapCatalogToFetchErr.delete(catalog);

		return { lastFetchStatus: err, totalErrCount: errCount };
	}
	else if (clearMapEntry) {
		// The catalog no longer has an error state. make sure
		// we remove it from our error count map.
		_mapCatalogToFetchErrCount.delete(catalog);
	}

	return { lastFetchStatus: 0, totalErrCount: 0 };
}

// Add the error and return total error count
const addErrorCount = (catalog: string): number => {
	let total = 1;
	const count = _mapCatalogToFetchErrCount.get(catalog);
	if (count != null) {
		total = count + 1;
		_mapCatalogToFetchErrCount.set(catalog, total);
	}
	else {
		_mapCatalogToFetchErrCount.set(catalog, 1);
	}

	return total;
}

export const raiseDevDataQualifiesForRefetch = (data: RaiseDevData): boolean => {
	// If the data has reached the max error count...
	if (data.fetchErrorCount >= _dataRefetchMaxErrors)
		return false;

	// If the data has no errors and is loaded..
	if (data.lastFetchStatus === RADBFetchStatus.None && data.status === RADBCacheStatus.Loaded)
		return false;

	return true;
}