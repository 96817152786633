import React, { useEffect, useState } from 'react';
import '../styles/AppLayout.scss';
import {
    getConsolidatedBomItems,
    getOrganizedBomItems,
    BOMItem,
    updateSummary,
} from '../summary/SummaryHelp';
import { Bom } from '../summary/Bom';
import { RaiseBOMPricingManager } from '../raiseDatabase/SummaryPricingManager';
import { txtRADB_LOADING } from '../types/APITypes';
import SummaryRightToolbar from '../toolbar/SummaryRightToolbar';
import { logger } from '../util/Logger';
import { LogRender } from '../types/Globals';
import { ChassisProject } from '../types/ProjectTypes';

export const raBomPricingMgr = new RaiseBOMPricingManager();

interface Props {
    project: ChassisProject;
    onTBAction: (action: string) => void;
    setTotals:(total:string)=>void;
    setShowRefreshPricing:(showRefreshPricing:boolean)=>void;
    showRefreshIcon:boolean;
    setShowRefreshIcon:(showRefreshIcon:boolean)=>void;
}
 
export const ProjectSummary = (props: Props) => {

    const [bomType, setBomType] = useState('Organized');
    const [organizedBOM, setOrganizedBOM] = useState<BOMItem[]>([]);
    const [consolidatedBOM, setConsolidatedBOM] = useState<BOMItem[]>([]);

    const {setTotals,setShowRefreshPricing,showRefreshIcon,setShowRefreshIcon} = props;
    let nextKey = 0;

    useEffect(() => {
        const updateSummaryInformation = async () => {
            await updateSummary(props.project);
            setOrganizedBOM(getOrganizedBomItems());
            setConsolidatedBOM(getConsolidatedBomItems());
      
            // Init the pricing manager passing it the
            // setTotals() state accessor.
            raBomPricingMgr.initialize(setTotals, setShowRefreshPricing);

            // Run the pricing manager.
            raBomPricingMgr.run(consolidatedBOM);
        };

        updateSummaryInformation();

    }, [consolidatedBOM, props.project, setShowRefreshPricing, setTotals]);

    


    const onRefreshPricing = () => {
        const updateSummaryInformation = async () => {
            //await updateSummary(project);
            //setOrganizedBOM(getOrganizedBomItems());
            //setConsolidatedBOM(getConsolidatedBomItems());

            setTotals(txtRADB_LOADING);

            // Init the pricing manager passing it the
            // setTotals() state accessor.
            raBomPricingMgr.initialize(setTotals, setShowRefreshPricing);

            // Run the pricing manager.
            raBomPricingMgr.run(consolidatedBOM, true);
            setShowRefreshIcon(false);
        };

        updateSummaryInformation();
    }


    const handleBomTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBomType((event.target as HTMLInputElement).value);
    }

    if (LogRender.SummaryComps) {
        logger.logRender('Render ProjectSummary');
    }
    return (
        <div className="project-summary">
            {/* <span className="project-summary-hdr-title">LIST PRICE (USD)</span>
            <span className="project-summary-hdr-totals">{totals}</span> */}
            <div className="project-summary-toolbar-container">
                <SummaryRightToolbar
                    project={props.project}
                    showRefreshIcon={showRefreshIcon}
                    onRefreshPricing={onRefreshPricing}
                    handleBomTypeChange={handleBomTypeChange}
                    bomType={bomType}
                    onTBAction={props.onTBAction}
                    item = {consolidatedBOM}
                    exportFileName = { props.project.config.projectName? props.project.config.projectName: "Configuration001" }
                />
            </div>
            
            <div className="project-summary-bom-container">
                <Bom
                    bom={bomType === 'Organized' ? organizedBOM : consolidatedBOM}
                    bomType={bomType}
                    key={nextKey++}
                />
            </div>

        </div>
    );
}



  