import React from 'react';
import './PrettyText.scss';


const _boldStart = '<b>';
const _boldEnd = '</b>'


interface TextEl {
    bold: boolean;
    text: string;
}


const accumTextEls = (text: string, els: TextEl[], startAt = 0) => {
    const tagStart = text.indexOf(_boldStart, startAt);
    if (tagStart >= startAt) {
        if (tagStart > startAt) {
            els.push({ bold: false, text: text.substring(startAt, tagStart) });
        }
        const textStartPos = tagStart + 3
        const tagEnd = text.indexOf(_boldEnd, textStartPos);
        if (tagEnd > textStartPos) {
            els.push({ bold: true, text: text.substring(textStartPos, tagEnd) });
            accumTextEls(text, els, tagEnd + 4);
        }
    }
    else {
        const remText = text.substring(startAt);
        if (remText.length > 0) {
            els.push({ bold: false, text: text.substring(startAt) });
        }
    }
}


interface Props {
    text?: string;
    addClassName?: string;
}

const PrettyTextComp = (props: Props) => {

    if (props.text === undefined) {
        return (null);
    }

    const els = new Array<TextEl>();
    accumTextEls(props.text, els);

    let divClass = 'pretty-text-comp-text';
    let hlTextClass = 'pretty-text-comp-highlighted-text';
    if (props.addClassName) {
        divClass += ' ' + props.addClassName;
        hlTextClass += ' ' + props.addClassName;
    }

    let nextKey = 1;

    return (
        <div
            className={divClass}
        >
            {els.map(el => {
                if (el.bold) {
                    return (
                        <span
                            key={nextKey++}
                            className={hlTextClass}
                        >
                            {el.text}
                        </span>
                    );
                }
                else {
                    return (
                        <span key={nextKey++}>{el.text}</span>
                    );
                }
            })}
        </div>
    );
}

export default PrettyTextComp;
