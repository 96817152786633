import React, { useEffect, useRef, useState } from 'react';
import { ProjectSetting, SettingGroupInfo, SettingOption, SelectionType } from '../types/SettingsTypes';
import { ComboSetting, RequireComboSetting } from './ComboSetting';
import EditSetting from './EditSetting';
import './Settings.scss';
import '../styles/Meteor.scss'
import ComboSettingMobile from './ComboSettingMobile';
import { touchDevice } from '../appLayout/AppView';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SliderSetting } from './SliderSetting';
import { SwitchSetting, SwitchSettingNoHeader } from './SwitchSetting';
import {
    ComponentRenderInfo,
    getDesignPageCompRenderInfo,
    registerDesignPageCompRenderInfo,
    unregisterDesignPageCompRenderInfo
} from '../settings/DesignPageComponentRenderInfo';


// SettingGroup root/parent component org:
//      Depending on the 'Expandable Flag'...
//      SettingGroupExpandable or SettingGroupStatic
//          Design Components are added as children
//          of the Expandable/Static Setting Group.

interface PropsGroup {
    info: SettingGroupInfo;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
    guidedSelectionGrp?: boolean;
}
interface PropsSettings {
    info: SettingGroupInfo;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
}

// Design Component is inserted into the 
// Setting Group (static/expandable).
const Settings = (props: PropsSettings) => {
    let nextKey = 1;
    return (
        <>
            {
                props.info.settings.map(setting => {
                    switch (setting.type) {
                        case SelectionType.Combo:
                            if (touchDevice) {
                                return (
                                    <div key={nextKey++}>
                                        <ComboSettingMobile
                                            key={nextKey++}
                                            setting={setting}
                                            valueChanged={props.valueChanged}
                                        />
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div key={nextKey++}>
                                        <ComboSetting
                                            key={nextKey++}
                                            setting={setting}
                                            valueChanged={props.valueChanged}
                                        />
                                    </div>
                                );
                            }

                        case SelectionType.RequiredCombo:
                            return (
                                <div key={nextKey++}>
                                    <RequireComboSetting
                                        key={nextKey++}
                                        setting={setting}
                                        valueChanged={props.valueChanged}
                                    />
                                </div>
                            );

                        case SelectionType.Edit:
                            return (
                                <div key={nextKey++}>
                                    <EditSetting
                                        key={nextKey++}
                                        setting={setting}
                                        valueChanged={props.valueChanged}
                                    />
                                </div>
                            );

                        case SelectionType.Slider:
                            return (
                                <div key={nextKey++}>
                                    <SliderSetting
                                        key={nextKey++}
                                        setting={setting}
                                        valueChanged={props.valueChanged}
                                    />
                                </div>
                            );

                        case SelectionType.Switch:
                            return (
                                <div key={nextKey++}>
                                    <SwitchSetting
                                        key={nextKey++}
                                        setting={setting}
                                        valueChanged={props.valueChanged}
                                    />
                                </div>
                            );

                        case SelectionType.SwitchNoHeader:
                            return (
                                <div key={nextKey++}>
                                    <SwitchSettingNoHeader
                                        key={nextKey++}
                                        setting={setting}
                                        valueChanged={props.valueChanged}
                                    />
                                </div>
                            );

                        default:
                            return null;
                    }
                })
            }
            {
                (props.info.note && props.info.note.length)
                    ? <span className="setting-note">{props.info.note}</span>
                    : null
            }
        </>
    );
}

// SettingGroupExpandable contains the Setting Components
// in an 'Accordian', which is a drop-down control.
const SettingGroupExpandable = (props: PropsGroup) => {

    const [, setRenderCnt] = useState(1);
    const lastExpandState = useRef(props.info.expanded);

    useEffect(() => {
        // Register our refresh hooks.
        const hook: ComponentRenderInfo = { componentID: props.info.title, renderCount: 1, setRenderCount: setRenderCnt };
        registerDesignPageCompRenderInfo(hook);

        return (() => unregisterDesignPageCompRenderInfo(hook));
    }, [props.info.title]);

    let nextKey = 1;

    const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (lastExpandState.current !== isExpanded) {
            props.info.expanded = isExpanded;
            lastExpandState.current = props.info.expanded;
            const renderHook = getDesignPageCompRenderInfo(props.info.title);
            if (renderHook)
                renderHook.setRenderCount(++renderHook.renderCount);
        }
    }

    if (lastExpandState.current !== props.info.expanded) {
        lastExpandState.current = props.info.expanded;
        const renderHook = getDesignPageCompRenderInfo(props.info.title);
        if (renderHook)
            renderHook.setRenderCount(++renderHook.renderCount);
    }


    return (
        <div className="setting-group-expandable">
            <Accordion expanded={lastExpandState.current} onChange={handleChange} elevation={0} >
                <AccordionSummary
                    sx={{ maxWidth: 310 }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '22px', fontFamily: 'Barlow' }}>
                        {props.info.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Settings
                        info={props.info}
                        valueChanged={props.valueChanged}
                        key={nextKey++}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

// SettingGroupStatic contains the Setting Components
// in a DIV, where the Design are always shown, meaning
// the group is NOT collapsable/expandable.
const SettingGroupStatic = (props: PropsGroup) => {
    const [, setRenderCnt] = useState(1);

    useEffect(() => {
        // Register our refresh hooks.
        const hook: ComponentRenderInfo = { componentID: props.info.title, renderCount: 1, setRenderCount: setRenderCnt };
        registerDesignPageCompRenderInfo(hook);

        return (() => unregisterDesignPageCompRenderInfo(hook));
    }, [props.info.title]);


    let nextKey = 1;

    return (
        <div className="setting-group-static">
            {props.info.title ?
                <span
                    key={nextKey++}
                    className="a-type__body"
                >
                    {props.info.title}
                </span>
                :
                null
            }

            <Settings
                info={props.info}
                valueChanged={props.valueChanged}
                key={nextKey++}
            />
        </div>
    );
}


// SettingGroup Component can either be expandable
// or static. <SettingGroup> is the ONLY exported
// component - everything else in this file is
// hidden to the outside environment.
const SettingGroup = (props: PropsGroup) => {

    let nextKey = 1;

    return (
        props.info.expandable ?
            < SettingGroupExpandable
                info={props.info}
                valueChanged={props.valueChanged}
                key={nextKey++}
                guidedSelectionGrp={props.guidedSelectionGrp} />
            :
            <SettingGroupStatic
                info={props.info}
                valueChanged={props.valueChanged}
                key={nextKey++}
            />
    );
}

export default SettingGroup;