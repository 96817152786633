import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../styles/AppLayout.scss';
import { IOSettings } from './ProjectSettings';
import { ProjectSetting, SettingOption, SettingCode } from '../types/SettingsTypes';
import { useAppSelector } from '../redux/hooks';
import { ProjectViewType } from '../types/ViewTypes';
import { GuidedSelSettingsSection, SettingsSection } from '../settings/SettingsSection';
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { PointEntrySection } from '../settings/PointEntrySection';
import { contentChanging, suspendUndoSnapshots } from '../util/UndoRedo';
import { confirmAndCallBack, displayAlertMsg, displayUnderConstructionMsg } from '../util/MessageHelp';
import { addButton, IModalResultCallback, ModalRequestSpec, ModalStatus, requestModal } from '../modals/ModalHelp';
import SettingsToolbar from '../toolbar/SettingsToolbar';
import { ToolbarAction } from '../toolbar/ToolbarActions';
import { ProjInfoSettings } from '../projInfo/ProjInfoSettings';
import { checkProjectConfigSettings } from '../util/Checker';
import { logger } from '../util/Logger';
import { useAppDispatch } from '../redux/hooks';
import { setCurrentView } from '../redux/slices/ViewSlice';
import { LogRender } from '../types/Globals';
import { getGuidedSelInfoFromCache, prepareLocAttrHardwareForGen } from '../model/GuidedSelection';
import { getLocationSettings, updateAllChassisLayouts } from '../model/ChassisProject';
import { ChassisProject } from '../types/ProjectTypes';
import {
	ComponentRenderInfo,
	DesignPageChildID,
	getDesignPageCompRenderInfo,
	registerDesignPageCompRenderInfo,
	unregisterDesignPageCompRenderInfo
} from '../settings/DesignPageComponentRenderInfo';
import { PointEntryInfo } from '../types/IOPointEntryTypes';
import { AIBits } from '../types/IOModuleTypes';
import { exportTemplate } from '../platforms/PlatformTemplateHelp';
import { createDefaultPointEntry, createHardwareFromSettings, getHardwareGenErrors } from '../implementation/ImplHardwareGen';
import { getIOModTypes } from '../model/EngineeringData';
import { trackEvent } from '../analytics/EventTracking';
import { getHardwareErrorMessages } from '../implementation/ImplHardwareGeneralFn';
import { startupDetails } from '../redux/slices/StartupInfoSlice';
import { saveConfiguration } from '../userProject/ProjectSaveAndRestore';
import { isConfigModified } from '../userProject/UserProjectHelp';
import toast from '../toast/toast';
import {
	usePSAppSelector,
	selectUser,
} from "platform-services";
import { displayIoInfoModal } from '../platforms/micro/ioInfoModal/IOInfoModal';

const launchGenHWConfirmModal = (callback: IModalResultCallback) => {
	const msg = 'Regenerating hardware will override any changes previously ' +
		'made in the hardware layout. Skipping will allow you to continue ' +
		'editing your previous work.';

	const request: ModalRequestSpec = {
		includeButtons: true,
		closeOnInsideClick: false,
		stayOpenOnBackdropClick: true,
		title: 'Regenerate Hardware?',
		callback: callback,
		width: 600,
		message: msg,
	};

	addButton(request, 'CANCEL', ModalStatus.Cancelled, 'outlined', 'right');
	addButton(request, 'REGENERATE', ModalStatus.Yes, 'contained', 'right');
	addButton(request, 'SKIP', ModalStatus.No, 'outlined', 'left');


	requestModal(request);
}

interface Props {
	project: ChassisProject;
	imgSrc: string;
	valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
	contentChanged: () => void;
}


const ChassisSettings = (props: Props) => {

	const dispatch = useAppDispatch();

	const lastGSCacheKey = useRef<string | undefined>(undefined);
	const [, setRenderCnt] = useState(0);
	const forceLocalRender = useCallback(() => {
		const info = getDesignPageCompRenderInfo(DesignPageChildID.DesignPage);
		if (info)
			info.setRenderCount(++info.renderCount);
	}, []);

	const user = usePSAppSelector(selectUser);
	const startupDtls = useAppSelector(startupDetails);

	useEffect(() => {
		// Register our refresh hooks so that other comps can use them.
		const hook: ComponentRenderInfo = { componentID: DesignPageChildID.DesignPage, renderCount: 1, setRenderCount: setRenderCnt };
		registerDesignPageCompRenderInfo(hook);
		return (() => unregisterDesignPageCompRenderInfo(hook));
	}, [])

	const OnModuleListChanged = () => {
		forceLocalRender();
	}

	const onGenerateHardwareConfirmation = (status: number, data?: object) => {
		data; // Prevent a warning.
		// We ask the user to Create New HW (Yes), Do not
		// Create (No), or cancel.
		switch (status) {
			case ModalStatus.Yes:
				onGenerateHardware();
				break;
			case ModalStatus.No:
				dispatch(setCurrentView(ProjectViewType.Layout));
				if (isConfigModified()) {
					const userProjGuid = (startupDtls?.projectGuid)
						? startupDtls.projectGuid
						: '';
					saveConfiguration(userProjGuid, props.project);
				}
				break;
			default:
				return;
		}
	}

	const locAttrInfo = getLocationSettings(props.project);
	const platform = locAttrInfo.platform;

	if (lastGSCacheKey.current !== locAttrInfo.guidedSelCacheKey) {
		const info = getGuidedSelInfoFromCache(locAttrInfo.guidedSelCacheKey);
		if (info) {
			lastGSCacheKey.current = locAttrInfo.guidedSelCacheKey;
			if (info.defGuidedSelection) {
				// Create a new array instance and re-render.
				const msg = `The latest Guided Selection data for the ${locAttrInfo.platform} platform could not be retrieved.`;
				toast.warn(msg);
			}
		}
	}

	const onGotoHardwareLayout = () => {
		// Prepare the hardware for generation. This will add any errors
		// to locAttrInfo.hardware from the Guided Selections. Note: this
		// will reset locAttrInfo.hardware and the err messages.
		prepareLocAttrHardwareForGen(locAttrInfo, props.project);

		//2023.10.17 Check the Project Config Settings. This will add any
		// errors to locAttrInfo.hardware from settings outside the Guided
		// Selection.
		checkProjectConfigSettings(props.project, locAttrInfo.hardware);

		// Check if we have any errors in our settings. If so...
		if (locAttrInfo.hardware.mapErrMessages.size !== 0) {
			let message = 'Your inputs/requirements currently have errors associated with them. Please correct any errors and then Generate Hardware.\n\n';
			const arrMessages = getHardwareErrorMessages(locAttrInfo.hardware);
			arrMessages.forEach(x => {
				message += '  * ';
				message += x;
				message += '\n\n';
			});

			displayAlertMsg(message);

			// We're done.
			return;
		}

		// If there is existing hardware, notify the user it
		// will be deleted if they continue.
		if (props.project.content.racks.length > 0) {
			launchGenHWConfirmModal(onGenerateHardwareConfirmation);
			return;
		}

		onGenerateHardware();
		const generateHardwareClick = new CustomEvent("designGenerateHardware", {
			detail: {
				action: "Generate Hardware",
				properties: {
					category: "WebApp",
					label: "Generate Hardware",
				},
			},
		});
		document.getElementById("root")?.dispatchEvent(generateHardwareClick);
	}


	const onGenerateHardware = () => {
		// Uncomment to display DEBUG dump of components to
		// be generated.
		//displayAlertMsg(getHardwarePreview(hw));

		// BEFORE generating the new hardware, call our helper
		// to handle any related undo/redo work for us. That
		// will take a snapshot of whatever content we CURRENTLY
		// have (if any) prior to our generation.
		contentChanging(props.project.content);

		// Turn off undo snapshots that would otherwise
		// happen for each chassis and module we add. We
		// want everything we generate to be included in
		// the SAME undo snapshot.
		suspendUndoSnapshots(true);

		// Create the hardware and go to the selection view.
		createHardwareFromSettings(platform, locAttrInfo.hardware, props.project, true);

		// Turn undo snapshots back on.
		suspendUndoSnapshots(false);

		// Give any/all chassis a chance to update their own
		// layouts. For example, this gives snap-type chassis
		// like 5069 CpLX the chance to auto-place any FPD
		// modules, etc., and anything else needed that it
		// 'delayed' while undo snapshots were suspended.
		updateAllChassisLayouts(props.project.content);

		// Tag the content as 'new' so that our layout
		// can auto-scale appro
		props.project.content.newContent = true;

		//const imgUrls = new Set<string>();
		//collectImageUrls(project, imgUrls);
		//imgUrls.forEach(url => {
		//	preloadImage(url);
		//})

		// MOVED BELOW
		//// Notify our parent that content has been
		//// changed, and that the view should change
		//// to Layout.
		//props.contentChanged();
		//dispatch(setCurrentView(ProjectViewType.Layout));

		if (LogRender.ClearConsoleOnGenerate) {
			console.clear();
		}

		// 2023.12.6 Check for any Hardware Generation errors. 
		const errsHWGen = getHardwareGenErrors(locAttrInfo.hardware.platform, true);
		if (errsHWGen.length > 0) {
			let finalMessage = '';
			errsHWGen.forEach((x) => {
				const msg = `Hardware Generation: ${x}\n`;
				finalMessage += msg;
			});

			// We will pop an alert with the message(s). In general,
			// we should never get here and hopefully these messages
			// will only be diagnostic messages for detecting bad data.
			// Note: We encountered some issues displaying our modal
			// dialog here - using alert() avoids the issue.
			alert(finalMessage);
		}


		// Notify our parent that content has been
		// changed, and that the view should change
		// to Layout.
		props.contentChanged();

		// If modified...
		if (isConfigModified()) {
			const userProjGuid = (startupDtls?.projectGuid)
				? startupDtls.projectGuid
				: '';
			saveConfiguration(userProjGuid, props.project);
		}

		dispatch(setCurrentView(ProjectViewType.Layout));
	}

	const onAddPointEntry = () => {
		// Create a new point entry.
		const arrTypes = getIOModTypes(locAttrInfo.platform);
		if (arrTypes) {
			// Try to default to Analog Input.
			let newType = arrTypes.find(x => x === AIBits);
			if (!newType) {
				// Just take the first one...
				newType = arrTypes[0];
			}

			const entry = createDefaultPointEntry(locAttrInfo, newType);
			locAttrInfo.pointEntrySection.entries.push(entry);

			trackEvent('Added IO Row');
		}
		else {
			displayAlertMsg(`${locAttrInfo.platform} does not have any I/O to add.`);
		}
	}

	const onDeleteSelectedPointEntriesConfirmation = (status: number, data?: object) => {
		data; // Prevent a warning.
		if (status === ModalStatus.Confirmed) {
			locAttrInfo.pointEntrySection.entries = locAttrInfo.pointEntrySection.entries.filter(item => !item.selected);
			// Clear the 'Select All' checkbox.
			locAttrInfo.pointEntrySection.clearSelectAll = true;
			// trigger a recalc of point entries.
			locAttrInfo.pointEntrySection.externalMaskChanged = true;
		}
		const deleteIORowClick = new CustomEvent("designDeleteIORow", {
			detail: {
				action: "Deleted IO Row",
				properties: {
					category: "WebApp",
					label: "Deleted IO Row",
				},
			},
		});
		document.getElementById("root")?.dispatchEvent(deleteIORowClick);
	}

	const onDeleteSelectedPointEntries = () => {
		if (locAttrInfo.pointEntrySection.entries.some(x => x.selected === true)) {
			const msg = 'Are you sure you would like to delete the selected rows?';
			confirmAndCallBack(msg, onDeleteSelectedPointEntriesConfirmation, new Boolean(true));
			return;
		}
		const deleteIORowClick = new CustomEvent("designDeleteIORow", {
			detail: {
				action: "Deleted IO Row",
				properties: {
					category: "WebApp",
					label: "Deleted IO Row",
				},
			},
		});
		document.getElementById("root")?.dispatchEvent(deleteIORowClick);
	}

	const onCopySelectedPointEntries = () => {
		const entriesToCopy = locAttrInfo.pointEntrySection.entries.map((entry) => {
			if (entry.selected)
				return entry;
		});

		entriesToCopy.forEach((entry) => {
			if (entry) {
				// Clear the source entry's selected prop. When
				// we copy it, the copy will also be unselected.
				entry.selected = false;
				const entryCopy: PointEntryInfo = { ...entry };
				locAttrInfo.pointEntrySection.entries.push(entryCopy);

			}
		})
		const copyIORowClick = new CustomEvent("designCopyIORow", {
			detail: {
				action: "Copied IO Row",
				properties: {
					category: "WebApp",
					label: "Copied IO Row",
				},
			},
		});
		document.getElementById("root")?.dispatchEvent(copyIORowClick);

		// Clear the 'Select All' checkbox in the 
		// Point Entry Section component's header.
		locAttrInfo.pointEntrySection.clearSelectAll = true;
	}

	const onTBAction = (action: string) => {
		switch (action) {
			case ToolbarAction.ADDPOINTENTRY:
				onAddPointEntry();
				forceLocalRender();
				break;

			case ToolbarAction.DELETEPOINTENTRY:
				onDeleteSelectedPointEntries();
				forceLocalRender();
				break;

			case ToolbarAction.COPYPOINTENTRY:
				onCopySelectedPointEntries();
				forceLocalRender();
				break;

			case ToolbarAction.INFOPOINTENTRY:
				// Added this only for Micro 800 family.
				displayIoInfoModal()
				break;

			case ToolbarAction.LAYOUT:
				onGotoHardwareLayout();
				break;

			case ToolbarAction.EXPORT:
				exportTemplate(props.project, false, user); 
				break;

			case ToolbarAction.DOWNLOADTEMPLATE:
				// Note: Once we have more than one platform (CLX), we
				// will need to display a modal for the user to select
				// the platform for the template.
				exportTemplate(props.project, true);
				break;

			default:
				displayUnderConstructionMsg(action + ' action not implemented.');
				break;
		}
	}

	if (LogRender.Views) {
		logger.logRender('Render ChassisSettings');
	}

	return (
		<div className="chassis-settings">
			<Allotment separator={true}>
				<Allotment.Pane preferredSize={'20%'} minSize={320}>
					<div className="chassis-settings-side-panel">
						<ProjInfoSettings
							info={props.project}
							platform={platform}
						/>
						<div className="chassis-settings-side-panel-setting-groups">
							<SettingsSection
								project={props.project}
								settingSectionID={SettingCode.AttrGrpProjectSettings}
								valueChanged={props.valueChanged}
							/>
						</div>
						<div className="chassis-settings-side-panel-setting-groups">
							<GuidedSelSettingsSection
								project={props.project}
								settingSectionID={SettingCode.AttrGrpGuidedSelSettings}
								valueChanged={props.valueChanged}
							/>
						</div>
					</div>
				</Allotment.Pane>
				<Allotment.Pane preferredSize={'80%'} minSize={0}>
					<div className="chassis-settings-right-side-allotment">
						<SettingsToolbar
							project={props.project}
							onTBAction={onTBAction}
							platform={locAttrInfo.platform}
							refreshView={forceLocalRender}
						/>

						<IOSettings
							project={props.project}
							valueChanged={props.valueChanged}
						/>
						<div className="chassis-settings-io-entry-section">
							<PointEntrySection
								project={props.project}
								info={locAttrInfo.pointEntrySection}
								onModuleListChanged={OnModuleListChanged}
								contentChanged={props.contentChanged}
								key='PointEntry'
							/>
						</div>
					</div>
				</Allotment.Pane>
			</Allotment>
		</div>
	);
}

export default ChassisSettings;
