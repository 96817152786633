import { Point } from "../types/SizeAndPosTypes";


export interface CableSpec {
    pathPts: Point[];
    color: string;
    width: number;
    radius: number;
}

export const makeEmptyCableSpec = (color: string, width: number, radius: number): CableSpec => {
    return {
        pathPts: new Array<Point>(),
        color: color,
        width: width,
        radius: radius
    }    
}

export const offsetCableSpec = (spec: CableSpec, ptOffset: Point) => {
    spec.pathPts.forEach(pt => {
        pt.x += ptOffset.x;
        pt.y += ptOffset.y;
    });
}