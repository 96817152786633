/* eslint-disable react-hooks/rules-of-hooks */
import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { PlatformMicro } from "../../PlatformConstants";
import { AF_Base, AFType, getNewAutoFix, addAutoFix } from "../../../util/CheckerAutoFix";
import {  ChassisProject , MicroChassis, DeviceType} from "../../../types/ProjectTypes";
import { getNewProjectLog, LogMsgLevel, StatusLogType, addLogMessage } from "../../../util/ProjectLog";
import { getMicroModulesupSizeCheck } from "../../../util/EngInfoHelp";

import {
	addModuleAtSlot,
} from "../../../implementation/ImplGeneral";
import {
	microgetChassisTemplateForCat
} from "./MicroGeneralImpl";
import {
	getChassisAndRackIndexById,
} from "../../../model/ChassisProject";

const autofixCplxNoController = (
    project: ChassisProject,
    chassisID: string
  ): boolean => {
    const [chassis] = getChassisAndRackIndexById(project, chassisID);
    
   

 
    if (chassis) {
      // contentChanging(project.content);
      // const snapshotsWereSuspended = suspendUndoSnapshots(true);
      // addModuleAtSlot(chassis, chassis.catNo, 0, true);
      // suspendUndoSnapshots(snapshotsWereSuspended);
   
      // updateChassisLayout(chassis);
      // updateAllChassisLayouts(project.content);
      // return true;
      const ioModulesPresent = chassis.modules.filter(res=> res?.deviceType === DeviceType.IOExpansion)
      // First try to upsize the Slot 0 component.
    if (ioModulesPresent && microUpsizeSlot0ToSupportModCount(chassis, ioModulesPresent.length)) {
      return true;
    }
    }
    return false;
  };

  export const microUpsizeSlot0ToSupportModCount = (chassis: MicroChassis, expansionModules: number): boolean => {

    const platform = chassis.platform;

    const info =  getMicroModulesupSizeCheck(platform, expansionModules);
    if(info){
      addModuleAtSlot(chassis,info, 0, true);
      return true;
    }
  
  
    return true;
}

  const _afCommHasTooManyModules = (project: ChassisProject, af: AF_Base): boolean => {
    // Call our helper to get the chassis and rack index
    const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
    if (targetChassis == null || idxRack < 0) {
      alert('Unable to correct issue.');
      return true;
    }
  const getMicroChassis = targetChassis as MicroChassis
  const ioModulesPresent = getMicroChassis.modules.filter(res=> res?.deviceType === DeviceType.IOExpansion)
    // First try to upsize the Slot 0 component.
	if (ioModulesPresent && microUpsizeSlot0ToSupportModCount(getMicroChassis, ioModulesPresent.length)) {
		return true;
	}
    return true;
  }
  

 const microExecuteAutoFix = (af: AF_Base, project: ChassisProject, callback: () => void): boolean => {
	let success = false;
	switch (af.type) {
        case AFType.No_Controller:
            success = autofixCplxNoController(project, af.targetInstanceID);
            break;
            case AFType.Snap_CommHasTooManyModules:
              success = _afCommHasTooManyModules(project, af);
              break;

		default:
			window.alert('AutoFix not yet implemented: ' + af.type);
			break;
	}

	if (success)
		callback();

	return success;
}

export const microDoGeneralCheck = (chassis: MicroChassis) => {
  const platform = chassis.platform;
	const template = chassis.bu && chassis.bu.catNo && microgetChassisTemplateForCat(chassis.catNo, chassis.bu.catNo);
     const maxModsSupported = template ? template?.slotGroups[1] : 0;
      // Get an array of non-FPD/Interconnect Cable modules to the right of the comm.
      const arrMods = chassis.modules.filter((mod, idx) =>mod?.deviceType === DeviceType.IOExpansion && idx > 0);
      if (arrMods.length > maxModsSupported && chassis.bu?.imgSrc !== '') {
          // Create a new log if the chassis does not have one..
          if (chassis.statusLog == null)
              chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

          const af = getNewAutoFix(platform, chassis.id, AFType.Snap_CommHasTooManyModules);
          addAutoFix(af);
          const msg = `The currently selected controller does not support the number of Expansion I/O present.`;
          addLogMessage(
              chassis.statusLog,
              msg,
              LogMsgLevel.error,
              chassis,
              af.id);
      }
  


}

const microImpl: CheckerImplementation = {
    platform: PlatformMicro,
    doGeneralCheck: microDoGeneralCheck,
    doChassisPowerCheck: undefined,
    executeAutoFix: microExecuteAutoFix,
}


export const RegisterMicroCheckerImpl = () => {
    RegisterCheckerImpl(microImpl);
}
