import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA } from "../platforms/PlatformConstants";
import {
    SettingOptionInfo,
    ProjectSetting,
    SelectionType,
    IOEntryModeEnum,
    SettingRuleType,
    SettingRule,
    getSettingRuleIssues,
    SettingRuleMsgPlaceHolders
} from '../types/SettingsTypes';
import { ChassisProject, createNewLocAttributeInfo, IOModuleWiring, LocAttributeInfo } from "../types/ProjectTypes";
import { makeSettingGroup } from '../settings/SettingsHelp';
import { getLocationSettings, resetHardwareBuilder } from "./ChassisProject";
import { LogRender, UseDefaultGuidedSelection } from "../types/Globals";
import { getGuidedSelection_API } from "../services/selectionAPIs/SelectionApi";
import ServiceResponse from "../services/apis/Response";
import { AxiosError } from "axios";
import { raTimeoutMessage } from "../services/apis/ProductApiService";
import { logger } from "../util/Logger";
import CLXDefGuidedSelectionJSON from "../platforms/clx/data/CLXDefGuidedSelection.json"
import CpLXDefGuidedSelectionJSON from "../platforms/cplx/data/CpLXDefGuidedSelection.json"
import FlexDefGuidedSelectionJSON from "../platforms/flex/data/FlexDefGuidedSelection.json"
import FlexHADefGuidedSelectionJSON from "../platforms/flexHA/data/FlexHADefGuidedSelection.json"
import { IOModuleSelection } from "../types/IOModuleTypes";
import { PointEntrySectionInfo } from "../types/IOPointEntryTypes";
import {
    RADBCacheStatus,
    RADBFetchStatus,
    GSLoadTracker,
    GuidedSelectionInfo,
    onGuidedSelLoadedCallback,
    PlatformLoadTracker,
} from "../types/APITypes";
import {
    finalizeGuidedSelection,
    getInitialPointEntryInfo,
    getLocIOWiringTypeSel,
    prepLocAttrHardwareForGen
} from "../implementation/ImplHardwareGen";

const _MustEqualOperator = 'not_in';
const _MustNotEqualOperator = 'in';

export interface LocAttrInfoCollector {
    setAttrValidateOnChange: Set<string>;
    mapAttrIdToAttrGrpId: Map<string, string>;
}

interface GSAttributeGroup {
    name: string;
    selections: object[];
}

interface GSAttribute {
    propertyName: string;
    friendlyName: string;
    propertyDescription: string;
    defaultValue: string;
    image: string;
    literatureLink: string;
    longDescription: string;
    selectionType: string; // UI Control Type
    options: GSAttributeOption[];
}

interface GSAttributeOption {
    name: string;
    description: string;
    value: string;
    longDescription: string;
    literatureLink: string;
    image: string;
    constraints: string; // rules.
}

//////// HELPERS /////////////////////////////////////////////////////////////
export const createNewGuidedSelectionInfo = (
    platform: string,
    industryID: string,
    installLocID: string): GuidedSelectionInfo => {
    return {
        status: RADBCacheStatus.Loading,
        lastFetchStatus: RADBFetchStatus.None,
        platform: platform,
        industryID: industryID,
        installLocID: installLocID,
        defGuidedSelection: false,
    }
}

export const cloneLocAttributeInfo = (locAttrInfo: LocAttributeInfo): LocAttributeInfo => {
    const clone = createNewLocAttributeInfo(locAttrInfo.platform, locAttrInfo.industryID, locAttrInfo.installLocationID, locAttrInfo.ioEntryMode);

    // If we have an existing guided selection,
    // we should have it cached.
    const cacheKey = createGSCacheKey(locAttrInfo.platform, locAttrInfo.industryID, locAttrInfo.installLocationID);
    const cacheGS = _GSCache.get(cacheKey);
    if (cacheGS && cacheGS.jsonObj) {
        // Populate the clone information. Note: We are
        // not validating the clone, which means no options
        // will be omitted and the default values are set.
        _parseGuidedSelection(cacheGS.jsonObj, clone, false, true);

        // Update the selections.
        refreshLocAttrInfoSelectionArray(locAttrInfo);
        clone.attrGroups.forEach((grp) => {
            grp.settings.forEach((setting) => {
                const srcValue = locAttrInfo.arrAttributeNameToValue.find(x => x.attrID === setting.id);
                if (srcValue) {
                    // Set the selected option to what the src had.
                    const newOpt = setting.options.find(opt => opt.id === srcValue.optionID);
                    if (newOpt)
                        setting.selectedOption = newOpt;
                }
            });
        });

        // Now validate the clone.
        validateLocAttrInfo(clone);

        return clone;
    }

    // Where this function is called from, we should
    // always have the cache object. However, if in
    // the future this is not the case, a separate
    // clone function with a callback should be added.
    throw new Error('cloneLocAttributeInfo(): trying to clone info which is not cached!');
}

const createNewGSLoadTracker = (
    locAttrInfo: LocAttributeInfo,
    platform: string,
    industryID: string,
    installLocID: string,
    gsCacheKey: string,
    onGSLoadedCallback: onGuidedSelLoadedCallback,
    skipValidation: boolean): GSLoadTracker => {
    return {
        platform: platform,
        industryID: industryID,
        installLocID: installLocID,
        gsCacheKey: gsCacheKey,
        guidedSelectionLoaded: false,
        defGuidedSelection: false,
        locAttrInfo: locAttrInfo,
        platformChanged: false,
        guidedSelectionInfo: createNewGuidedSelectionInfo(platform, industryID, installLocID),
        onGSLoadedCallback: onGSLoadedCallback,
        skipValidation: skipValidation,
    };
}


export const setInitialPointEntryInfo = (locAttrInfo: LocAttributeInfo, savedUserModSelections: IOModuleSelection[]) => {
    locAttrInfo.pointEntrySection.entries =
        getInitialPointEntryInfo(locAttrInfo, savedUserModSelections);
    locAttrInfo.pointEntrySection.platform = locAttrInfo.platform;
    locAttrInfo.pointEntrySection.externalMaskChanged = true;
    locAttrInfo.pointEntrySection.externalPercentSpareIOChanged = false;
    locAttrInfo.pointEntrySection.initialized = true;
}

//////// PROJECT ACCESSORS ///////////////////////////////////////////////////// 
export const getCurrentLocPointEntrySection = (project: ChassisProject): PointEntrySectionInfo => {
    const loc = getLocationSettings(project);
    return loc.pointEntrySection;
}

export const getCurrentLocPlatform = (project: ChassisProject) => {
    const loc = getLocationSettings(project);
    return loc.platform;
}

export const getCurrentLocIOWiringForApp = (project: ChassisProject): IOModuleWiring => {
    const loc = getLocationSettings(project);
    return getLocIOWiringTypeSel(loc);
}


//////// CACHE ////////////////////////////////////////////////////////
// Map< cacheKey, GuidedSelectionInfo>
const _GSCache = new Map<string, GuidedSelectionInfo>();
const _pendingTrackers: GSLoadTracker[] = [];

export const createGSCacheKey = (platform: string, industryID: string, installLocationID: string) => {
    if (!industryID || !installLocationID)
        return `${platform}_DEFAULT`;
    return `${platform}_${industryID}_${installLocationID}`;
}


//////// ENTRY POINT //////////////////////////////////
// This will start the cache/request chain to update
// the Location (locAttrInfo) with the selected 
// guided selection specs.
export const updateGuidedSelection = (
    locAttrInfo: LocAttributeInfo,
    callbackGSLoaded: onGuidedSelLoadedCallback,
    skipValidation = false) => {

    // Has anything changed in the LocAttrInfo...
    const cacheKey = createGSCacheKey(locAttrInfo.platform, locAttrInfo.industryID, locAttrInfo.installLocationID);
    if (locAttrInfo.guidedSelCacheKey === cacheKey) {
        // Check that it is a complete location
        if (locAttrInfo.attrGroups.length > 0 && locAttrInfo.pointEntrySection.initialized) {
            // 2024.2.8 SPECIAL CASE: If the requester
            // wants to SKIP the G.S. validation, we must
            // load a fresh one. If NOT skipping...
            if (skipValidation === false) {
                // We're good!
                callbackGSLoaded(true, locAttrInfo);
                return;
            }
        }
    }

    // Set any flags, then update the LocAttrInfo stats...
    const platformChanged = (cacheKey.startsWith(locAttrInfo.platform) === false);
    locAttrInfo.guidedSelCacheKey = cacheKey;

    // Do we have the GS cached...
    const gsInfo = _GSCache.get(cacheKey);
    // If we have it and it's loaded...
    if (gsInfo && gsInfo.status === RADBCacheStatus.Loaded && gsInfo.jsonObj) {
        // Take the data and populate the locAttrInfo.
        if (_parseGuidedSelection(gsInfo.jsonObj, locAttrInfo, platformChanged, skipValidation)) {
            callbackGSLoaded(true, locAttrInfo);
            return;
        }

        // Something is BAD with the info and there
        // is a big problem....
        throw new Error(`updateGuidedSelection(): Guided Selection Info has invalid data source.`);
    }

    if (proceedWithRequest(locAttrInfo, callbackGSLoaded) === false) {
        // All this means is that there is already a request
        // pending for the location. Any adjustments to
        // tracking were made and we can just exit...
        return;
    }

    // Create our tracker.
    const gsTracker = createNewGSLoadTracker(
        locAttrInfo,
        locAttrInfo.platform,
        locAttrInfo.industryID,
        locAttrInfo.installLocationID,
        cacheKey,
        callbackGSLoaded,
        skipValidation);

    // Add it to our pending array.
    _pendingTrackers.push(gsTracker);

    _requestGuidedSelection(gsTracker);
}

const _PlatformTrackersInProgress: PlatformLoadTracker[] = [];
export const loadDefGuidedSelection = (tracker: PlatformLoadTracker) => {

    // If we have this platform's tracker already...
    if (_PlatformTrackersInProgress.some(x => x.platform === tracker.platform))
        return;

    const cacheKey = createGSCacheKey(tracker.platform, '', '');

    // Do we have the GS cached...
    const gsInfo = _GSCache.get(cacheKey);
    // If we have it and it's loaded...
    if (gsInfo && gsInfo.status === RADBCacheStatus.Loaded && gsInfo.jsonObj) {
        // I do NOT think we should get here since this
        // is part of the initial platform load, meaning
        // the platform should have been loaded/cached
        // already and we should never get here.
        tracker.defaultGuidedSelectionLoaded = true;
        tracker.onDefaultGuidedSelLoaded();
        return;
    }

    // Add the new tracker
    _PlatformTrackersInProgress.push(tracker);

    const tempLoc = createNewLocAttributeInfo(tracker.platform, '', '', IOEntryModeEnum.Basic);
    const gsTracker = createNewGSLoadTracker(
        tempLoc,
        tracker.platform,
        'Other', // Need an industry otherwise we error out on the API call.
        '',
        cacheKey,
        onDefaultPlatformGSLoaded,
        false);

    _requestGuidedSelection(gsTracker);
}

const onDefaultPlatformGSLoaded = (success: boolean, locAttrInfo: LocAttributeInfo | undefined) => {
    // Get the platform tracker - it should be there!
    if (locAttrInfo) {
        const platformTracker = _PlatformTrackersInProgress.find(x => x.platform === locAttrInfo.platform);
        if (platformTracker == null)
            throw new Error('onDefaultPlatformGSLoaded(): Platform Load Tracker not defined!');

        platformTracker.defaultGuidedSelectionLoaded = true;
        platformTracker.onDefaultGuidedSelLoaded();
        return;
    }
}

const proceedWithRequest = (
    locAttrInfo: LocAttributeInfo,
    callback: onGuidedSelLoadedCallback): boolean => {

    // Check if the location is already in a pending request.
    let idxExisting = -1;
    const oldTracker = _pendingTrackers.find((trk, index) => {
        if (trk.locAttrInfo === locAttrInfo) {
            idxExisting = index;
            return true;
        }
        return false;
    });

    // If we found the location is in a pending request...
    if (oldTracker && idxExisting > 0) {
        // If the request is for the same specs...
        if (oldTracker.gsCacheKey === locAttrInfo.guidedSelCacheKey) {
            // Update the callback (may have changed?)
            oldTracker.onGSLoadedCallback = callback;
            // We're done.
            return false;
        }

        // We have an old tracker for the same location with
        // different specs. Remove it and create a new one.
        _pendingTrackers.splice(idxExisting);
    }

    return true;
}

const _onGuidedSelResolved = (jsonObj: object, defaultJson: boolean, tracker: GSLoadTracker) => {
    // If the tracker is no longer pending...
    if (_pendingTrackers.indexOf(tracker) == null) {
        // This request was aborted. See proceedWithRequest()
        // where a tracker can be removed.
        return;
    }

    // The parse function will update the Location with
    // everuthing it needs. If it fails..
    if (_parseGuidedSelection(jsonObj, tracker.locAttrInfo, tracker.platformChanged, tracker.skipValidation) === false) {
        // If we had the default, that's not good
        if (defaultJson)
            throw new Error(`Invalid default Guided Selection for the ${tracker.platform} platform!`)

        // Recurse using the default json
        _onGuidedSelResolved(getDefGuidedSelectionJson(tracker.platform), true, tracker);
        return;
    }

    // Cache the info...
    const gsInfo = tracker.guidedSelectionInfo;
    gsInfo.defGuidedSelection = defaultJson;
    gsInfo.jsonObj = jsonObj;
    gsInfo.status = RADBCacheStatus.Loaded;

    _GSCache.set(tracker.gsCacheKey, gsInfo);

    // Remove the tracker for pending array.
    const idxTracker = _pendingTrackers.indexOf(tracker);
    if (idxTracker >= 0)
        _pendingTrackers.splice(idxTracker);

    tracker.onGSLoadedCallback(true, tracker.locAttrInfo);
}


const _requestGuidedSelection = async (tracker: GSLoadTracker) => {
    // If we are using the default...
    if (UseDefaultGuidedSelection) {
        _onGuidedSelResolved(getDefGuidedSelectionJson(tracker.platform), true, tracker);
        return;
    }

    try {
        const result = await getGuidedSelection_API(tracker.platform, tracker.industryID, tracker.installLocID);
        const response = new ServiceResponse(result);
        if (response.isSuccessful() && response.data) {
            _onGuidedSelResolved(response.data, false, tracker);
            return;
        }
    }
    catch (error) {
        // Start by marking the last fetch status as an error...
        tracker.guidedSelectionInfo.lastFetchStatus = RADBFetchStatus.Error;

        // If we timed out, the error will be an AxiosError. If so...
        if (error instanceof AxiosError) {
            // If we timed out, the code will be 'ECONNABORTED' and
            // the message will be raTimeoutMessage (RA_REQUEST_TIMEOUT) -
            // see productApiService.GetDetailedProduct() for details...
            if (error.code === 'ECONNABORTED' && error.message === raTimeoutMessage) {
                if (LogRender.SelectionAPIs)
                    logger.log(`REQUEST TIMED OUT: Guided Selection request for ${tracker.gsCacheKey} timed out.`);

                // Update the last fetch status to Timed Out.
                tracker.guidedSelectionInfo.lastFetchStatus = RADBFetchStatus.Timeout;
            }
            else if (LogRender.SelectionAPIs)
                logger.log(`REQUEST AxiosError: Guided Selection request for ${tracker.gsCacheKey}: Error: ${error.message}.`);

        }
        else if (LogRender.SelectionAPIs)
            logger.log(`REQUEST UNKNOWN ERROR: Guided Selection request for ${tracker.gsCacheKey} had a non Axios error.`);

        // Use the default.
        _onGuidedSelResolved(getDefGuidedSelectionJson(tracker.platform), true, tracker);

        return;
    }

    // If we are here, use the default.
    _onGuidedSelResolved(getDefGuidedSelectionJson(tracker.platform), true, tracker);
}

// TO DO: Default JSON should be provided by a function
// in the platform implementation and not imported directly here.
const getDefGuidedSelectionJson = (platform: string): object => {
	switch (platform) {
		case PlatformCLX:
			return CLXDefGuidedSelectionJSON;
		case PlatformCpLX:
            return CpLXDefGuidedSelectionJSON;
		case PlatformFlex:
			return FlexDefGuidedSelectionJSON;
		case PlatformFlexHA:
			return FlexHADefGuidedSelectionJSON;
		default:
			break;
	}

    throw new Error(`getDefGuidedSelectionJson(): Unable to find default Guided Selection JSON for the ${platform} platform.`);
}


const _parseGuidedSelection = (
    gsJsonObj: object,
    locAttrInfo: LocAttributeInfo,
    platformChanged: boolean,
    skipValidation: boolean): boolean => {

    const GSAttrGroups = Object.values(gsJsonObj);
    if (GSAttrGroups.length === 0)
        return false;

    // Reset the Loc Attr Info
    locAttrInfo.attrGroups.length = 0;
    locAttrInfo.arrAttributeNameToValue.length = 0;

    // Create a new collector...
    const gsCollector: LocAttrInfoCollector = {
        setAttrValidateOnChange: new Set<string>,
        mapAttrIdToAttrGrpId: new Map<string, string>()
    };

    // For each attribute group...
    GSAttrGroups.forEach((attrGrp) => {
        const grp = attrGrp as object;
        parseSelectionAttributes(grp, locAttrInfo, gsCollector);
    });

    // Use the collector passed in to mark attributes
    // as 'validate on change'.
    gsCollector.setAttrValidateOnChange.forEach((attrID) => {
        // Get the AttrGrp the attr is in...
        const attrGrp = locAttrInfo.attrGroups.find(x => x.title === gsCollector.mapAttrIdToAttrGrpId.get(attrID));
        if (attrGrp) {
            const attr = attrGrp.settings.find(x => x.id === attrID);
            if (attr)
                attr.validateOnChange = true;
        }
    });

    // Call the platform specific function to add/pull
    // any special information it needs.
    finalizeGuidedSelection(locAttrInfo, gsCollector);

    if (skipValidation === false) {
        // Validate it - this will remove any options
        // that are not valid given the default selections.
        validateLocAttrInfo(locAttrInfo);
    }

    // If we had a platform change or the point entry
    // is not initiallized...
    if (platformChanged || locAttrInfo.pointEntrySection.initialized === false) {
        setInitialPointEntryInfo(locAttrInfo, []);
    }
    else {
        // Set a flag to trigger a point entry update.
        locAttrInfo.pointEntrySection.externalMaskChanged = true;
    }

    return true;
}

const parseSelectionAttributes = (
    attrGroup: object,
    info: LocAttributeInfo | undefined,
    collector: LocAttrInfoCollector) => {
    if (info == null)
        return;

    const attrGrp = attrGroup as GSAttributeGroup;
    if (attrGrp.name && attrGrp.selections.length > 0) {
        // This is a bit iffy - the attribute group names in
        // the JSON do NOT include 'Attributes', just the
        // names 'System'/'Controller'/'Network'. We'll add
        // 'Attributes' if we do not have it.
        let title = attrGrp.name.trim();
        if (title.includes('Attributes') === false)
            title = `${title} Attributes`;

        const settingGrp = makeSettingGroup(title);

        // 2024.2.8 By default, expand the group.
        settingGrp.expanded = true;

        // Walk the attributes (selections)
        attrGrp.selections.forEach((attrObj) => {
            const projSetting = createProjectSettingFromGSAttribute(attrObj, collector);
            if (projSetting) {
                settingGrp.settings.push(projSetting);
            }
        });

        // If the grp has any settings...
        if (settingGrp.settings.length > 0) {
            // Add the setting 
            settingGrp.settings.forEach((attr) => {
                collector.mapAttrIdToAttrGrpId.set(attr.id, settingGrp.title);
            });

            info.attrGroups.push(settingGrp);
        }
    }
}

const createProjectSettingFromGSAttribute = (
    gsAttr: object,
    collector: LocAttrInfoCollector): ProjectSetting | undefined => {
    const attr = gsAttr as GSAttribute;
    if (attr.propertyName && attr.defaultValue && attr.options.length > 0) {
        const setting: ProjectSetting = {
            id: attr.propertyName,
            type: getUIControlTypeFromGSAttr(attr.selectionType),
            name: attr.propertyDescription,
            selectedOption: { id: '', display: '' },
            units: '',
            options: attr.options.map((opt) => { return { display: opt.description, id: opt.name }; }),
            valid: true,
            defaultMessage: attr.longDescription,
            visible: true,
            gsMapOptionTxtValToInfo: new Map<string, SettingOptionInfo>(),
        };

        // For each Attribute Option...
        attr.options.forEach((opt) => {
            // Process any rules for the option.
            let rules: SettingRule[] | undefined = undefined;
            if (opt.constraints) {
                rules = ParseAttributeRules(opt.constraints);
                if (rules) {
                    // Each attribute involved in a rule will be added to
                    // a set of attributes that we need to validate when
                    // they are changed. If rules are found, the set must
                    // include not only the attribute in the rule, but also
                    // this attribute that has the rule.
                    collector.setAttrValidateOnChange.add(attr.propertyName);

                    rules.forEach((rule) => {
                        collector.setAttrValidateOnChange.add(rule.attrID);
                    });
                }
            }

            // Add the Option to the map. Note: This map
            // is keyed by Display Text.
            if (setting.gsMapOptionTxtValToInfo)
                setting.gsMapOptionTxtValToInfo.set(opt.description, { id: opt.name, txtValue: opt.description, rules: rules });

            // If the Option is the default, set it as the
            // selected option for the attribute.
            if (opt.name === attr.defaultValue)
                setting.selectedOption = { id: opt.name, display: opt.description };
        });

        // The selected option should be valid and set!
        if (setting.selectedOption.id) {
            return setting;
        }
        else {
            throw new Error('Invalid Guided Selection data for ' + attr.propertyDescription);
        }
    }

    return undefined;
}

const ParseAttributeRules = (rules: string): SettingRule[] | undefined => {
    const arrAtrrIdToAttrValPair: SettingRule[] = [];

    // Example "rules": "$ER in ['CC', 'XT']", (where 'in' = _MustNotEqualOperator)
    // Split the string based on '$' - we could have instances
    // where we have "$CV in ['48D', '125D'] $ER in ['CC']",
    // which will be interpretted as: if 'CV is 48 or 125'
    // OR (not AND) 'ER is CC', this attribute is flagged as invalid.
    const arrRules = rules.split('$');

    // Create a regular expression - still weak on this, but we
    // are looking for any groups of characters and/or numbers.
    // Note: Flag 'g' means don't stop after first match. Flag 'i'
    // means case Insensitive. The '+' means more than one char.
    const regex = /[a-z0-9]+/gi;

    // For each rule....
    arrRules.forEach((rule) => {
        if (rule.trim().length > 5) {
            // Determine the type by the 'not in'/'in' operators.
            const type = (rule.includes(_MustEqualOperator) ? SettingRuleType.MustEqual : SettingRuleType.MustNotEqual);

            // We need to REMOVE the operator (_MustNotEqualOperator or
            // _MustEqualOperator) before collecting the array of words.
            // (i.e."$ER ['CC', 'XT'] => ['ER', 'CC', 'XT'] )
            const noOpRule = (type === SettingRuleType.MustEqual ? rule.replace(_MustEqualOperator, '') : rule.replace(_MustNotEqualOperator, ''));  

            // Split the rule into a Rule Part and Message
            // Part (if any). A message will be any text
            // to the right of the closing bracket ']'.
            const arrRuleParts = noOpRule.split(']');
            if (arrRuleParts.length > 0) {
                const rulePart = arrRuleParts[0];

                // Get an array of each 'word' in the rule. Example:
                // "CV  ['48D', '125D']" would return [CV,48D,125D];
                const arrWords = rulePart.match(regex);
                let attrID = '';
                if (arrWords) {
                    const optionIDs: string[] = [];
                    arrWords.forEach((word) => {
                        // The first word should be the Attribute ID and the
                        // rest should be Attribute Option IDs.
                        if (!attrID)
                            attrID = word;
                        else
                            optionIDs.push(word);
                    });

                    if (optionIDs.length > 0) {
                        // If we have a message, it will be the second
                        // item in the arrRuleParts array.
                        let msg = '';
                        if (arrRuleParts.length > 1 && arrRuleParts[1])
                            msg = arrRuleParts[1].trim();

                        arrAtrrIdToAttrValPair.push({ attrID: attrID, optionID: optionIDs, type: type, formatMessage: msg });
                    }
                }
            }
        }
    });

    if (arrAtrrIdToAttrValPair.length > 0)
        return arrAtrrIdToAttrValPair;

    return undefined;
}

const getUIControlTypeFromGSAttr = (gsType: string): SelectionType => {
    switch (gsType) {
        case 'Edit':
            return SelectionType.Edit;
        case 'Toggle':
            return SelectionType.Switch;
        case 'Slider':
            return SelectionType.Slider;
        default:
            break;
    }

    // Default to combo.
    return SelectionType.Combo;
}



//////// UTILITIES AND HELPERS //////////////////////////////

export const getLocAttributeSetting = (loc: LocAttributeInfo, attrID: string): ProjectSetting | undefined => {
    let retVal: ProjectSetting | undefined = undefined;

    loc.attrGroups.forEach((attrGrp) => {
        if (!retVal)
            retVal = attrGrp.settings.find(x => x.id === attrID);
    });

    return retVal;
}

export const getProjectSettingOptionInfo = (setting: ProjectSetting, optionID: string): SettingOptionInfo | undefined => {
    if (setting.gsMapOptionTxtValToInfo) {
        setting.gsMapOptionTxtValToInfo.forEach((optInfo) => {
            if (optInfo.id === optionID)
                return optInfo;
        });
    }
    return undefined;
}


export const refreshLocAttrInfoSelectionArray = (loc: LocAttributeInfo) => {
    loc.arrAttributeNameToValue.length = 0;
    loc.attrGroups.forEach((attrGrp) => {
        attrGrp.settings.forEach((setting) => {
            // For clarity
            const attrID = setting.id;
            const attrVal = setting.selectedOption.id;

            loc.arrAttributeNameToValue.push({ attrID: attrID, optionID: attrVal });
        })
    });
}

export const createLocAttrInfoSearchString = (loc: LocAttributeInfo): string => {
    // Create a search string, by concatenating each attribute
    // and value as "attrID:OptionID"
    let searchString = '';
    loc.arrAttributeNameToValue.forEach((attr) => {
        searchString += `${attr.attrID}:${attr.optionID} `;
    });

    return searchString;
}

export const validateLocAttrInfo = (loc: LocAttributeInfo) => {
    // Refresh the array of attribute selections, which will
    // update loc.arrAttributeNameToValue from which we can
    // build the Search String below. Note: For some reason
    // if we call this just before createLocAttrInfoSearchString(),
    // we do NOT get the expected results (?)
    refreshLocAttrInfoSelectionArray(loc);
    loc.hasRuleViolation = false;

    // If we have the location settings and they have been loaded...
    if (loc.arrAttributeNameToValue.length > 0) {
        // Create a Search String. This will give us a string
        // of every attribute and its selection in the form of
        // 'AttrID:OptionID AttrID:OptionID etc.'. We can then
        // check if a rule has been violated by searching the 
        // string for 'RuleAttrID:RuleOptionID'.
        const searchString = createLocAttrInfoSearchString(loc);

        // Find all attributes that need validation. These will
        // be the only attributes that we need to check. All other
        // settings should be valid. For each Attribute Group...
        loc.attrGroups.forEach((attrGrp) => {
            // For each setting in the Attribute Group...
            attrGrp.settings.forEach((setting) => {
                // Clear any error/warning
                setting.valid = true;
                setting.msgWarningOrError = '';

                // If we have a ValidateOnChange, we either have a setting
                // that was included in a different setting's rule(s) OR a
                // setting with a rule(s).
                if (setting.validateOnChange && setting.gsMapOptionTxtValToInfo) {
                    const arrOptionInfo: SettingOptionInfo[] = [];
                    let ruleFound = false;

                    // Note: setting.gsMapOptionTxtValToInfo will ALWAYS
                    // contain ALL of the Setting Options. They are NOT filtered.
                    // First look for any Options that have a Rule and while
                    // we are, collect an array of the options.
                    setting.gsMapOptionTxtValToInfo.forEach((optInfo) => {
                        // Collect the option.
                        arrOptionInfo.push(optInfo);

                        // Not sure if we need to check length yet,
                        // but do it any way.
                        if (optInfo.rules && optInfo.rules.length > 0)
                            ruleFound = true;
                    });

                    // If any options have a rule...
                    if (ruleFound) {
                        // Start by clearing ALL of the Setting Options.
                        // From above, arrOptionInfo has ALL of the options.
                        setting.options.length = 0;

                        // For each option...
                        arrOptionInfo.forEach((optInfo) => {
                            // Start by saying we will add the option.
                            let addOption = true;

                            // If the option has a rule(s)...
                            if (optInfo.rules) {
                                // Create an array of rules that match the selections.
                                const violatedRules = getSettingRuleIssues(searchString, setting, optInfo.rules);
                                violatedRules.forEach((rule) => {
                                    // If the selected option in the setting is NOT this
                                    // option, which is invalid....
                                    if (setting.selectedOption.id != optInfo.id) {
                                        // Do not add the option. It is NOT selected
                                        // so eliminate it from possible selection.
                                        addOption = false;
                                    }
                                    else {
                                        if (!setting.ignoreError) {
                                            // A rule has been violated. Create a meaningful
                                            // message and mark the attribute as invalid.
                                            // Note: We will ADD this option!
                                            addErrorStateToSetting(loc, setting, rule);

                                            // Set a flag at the location level.
                                            loc.hasRuleViolation = true;

                                            // Expand the attribute group if it is expandable.
                                            if (attrGrp.expandable)
                                                attrGrp.expanded = true;
                                        }
                                    }
                                });
                            }

                            if (addOption)
                                setting.options.push({ id: optInfo.id, display: optInfo.txtValue });
                        });
                    }
                    else if (setting.options.length !== arrOptionInfo.length) {
                        // There are places where a setting can be
                        // modified outside of this Validate function.
                        // For instance, the Chassis Cfg Dialog. If we
                        // are here, that is the case. Reset the options.
                        setting.options = [];
                        arrOptionInfo.forEach((optInfo) => {
                            setting.options.push({ id: optInfo.id, display: optInfo.txtValue });
                        });
                    }
                }
            })
        });
    }
}

const addErrorStateToSetting = (loc: LocAttributeInfo, settingWithErr: ProjectSetting, violatedRule: SettingRule) => {
    const settingNotCompatible = getLocAttributeSetting(loc, violatedRule.attrID);

    let msg = '';

    // If the rule has a formattable message...
    if (settingNotCompatible) {
        if (violatedRule.formatMessage) {
            // We have 4 things that can be replaced:
            //		- '*TARGID*' and '*TARGVAL*'
            //		- '*RULEID*' and '*RULEVAL*'
            msg = violatedRule.formatMessage.replace(SettingRuleMsgPlaceHolders.AttrName, settingWithErr.name);
            msg = msg.replace(SettingRuleMsgPlaceHolders.AttrValue, settingWithErr.selectedOption.display);
            msg = msg.replace(SettingRuleMsgPlaceHolders.RuleAttrName, settingNotCompatible.name);

            // IF WE have a placeholder for the rule attr value in the format string...
            if (violatedRule.formatMessage.includes(SettingRuleMsgPlaceHolders.RuleAttrValue)) {
                // If it is a Must Not Equal, we can use the incompopatible
                // setting's selected value. Otherwise, we need to build
                // the replacement text.
                if (violatedRule.type === SettingRuleType.MustNotEqual) {
                    msg = msg.replace(SettingRuleMsgPlaceHolders.RuleAttrValue, settingNotCompatible.selectedOption.display);
                }
                else {
                    // Get an array of option names that must match
                    // for the setting to be valid.
                    const arrOptionNames: string[] = [];
                    violatedRule.optionID.forEach((optID) => {
                        const info = getProjectSettingOptionInfo(settingNotCompatible, optID);
                        if (info)
                            arrOptionNames.push(info.txtValue);
                    });

                    if (arrOptionNames.length === 0) {
                        // something went wrong, clear the message
                        msg = '';
                    }
                    else {
                        const repText = buildReplacementText(arrOptionNames);
                        msg = msg.replace(SettingRuleMsgPlaceHolders.RuleAttrValue, repText);
                    }
                }
            }
        }
        else {
            // Generate generic messages.
            if (violatedRule.type === SettingRuleType.MustNotEqual)
                msg = `${settingWithErr.name}: "${settingWithErr.selectedOption.display}" is NOT compatible with ${settingNotCompatible.name}: "${settingNotCompatible.selectedOption.display}"`;
            else {
                // Get an array of option names that must match
                // for the setting to be valid.
                const arrOptionNames: string[] = [];
                violatedRule.optionID.forEach((optID) => {
                    const info = getProjectSettingOptionInfo(settingNotCompatible, optID);
                    if (info)
                        arrOptionNames.push(info.txtValue);
                });

                if (arrOptionNames.length === 0)
                    msg = '';
                else {
                    const repText = buildReplacementText(arrOptionNames);
                    msg = `A ${settingWithErr.name} of "${settingWithErr.selectedOption.display}" is ONLY compatible with a ${settingNotCompatible.name} of ${repText}`;
                }
            }
        }
    }

    // If we still do not have a message....
    if (!msg)
        msg = `${settingWithErr.name}: "${settingWithErr.selectedOption.display}" is not compatible with the configured system.`;

    // Note: When a setting has a msgWarningOrError and
    // valid == true, the setting is in a Warning State.
    settingWithErr.valid = false;

    // If the setting already has a message...
    if (settingWithErr.msgWarningOrError) {
        settingWithErr.msgWarningOrError += '\n\n';
        settingWithErr.msgWarningOrError += msg;
    }
    else {
        settingWithErr.msgWarningOrError = msg;
    }
}


// Utility for addErrorStateToSetting()
const buildReplacementText = (values: string[]): string => {
    // Build the replacement text - if we have 2 the text will be
    // 'X or Y'. If we have more, 'X, Y, or Z'
    let repText = '';
    const len = values.length;
    if (len === 1)
        repText = values[0];
    else {
        for (let idx = 0; idx < len; ++idx) {
            if (idx < len - 1) {
                repText += `${values[idx]}`;
                if (len > 2)
                    repText += ', ';
                else
                    repText += ' ';
            }
            else
                repText += `or ${values[idx]}`;
        }
    }

    return repText;
}

export const prepareLocAttrHardwareForGen = (loc: LocAttributeInfo, project: ChassisProject) => {
    // Reset/clean the hardware builder information.
    resetHardwareBuilder(loc.hardware);

    // Check the settings for any errors.
    loc.attrGroups.forEach((attrGrp) => {
        attrGrp.settings.forEach((setting) => {
            if (setting.valid === false && setting.msgWarningOrError)
                loc.hardware.mapErrMessages.set(setting.id, setting.msgWarningOrError);
        });
    });

    if (loc.hardware.mapErrMessages.size > 0)
        return;

    prepLocAttrHardwareForGen(loc, project);
}

export const getGuidedSelInfoFromCache = (key: string | undefined) => {
    if (key && _GSCache.get(key)) {
        return _GSCache.get(key);
    }

    return undefined;
}

export const generalFinalizeGuidedSelection = (locAttrInfo: LocAttributeInfo, collector: LocAttrInfoCollector) => {
    // prevent warning.
    collector;

    // The Guided Selection does NOT have information
    // for which attributes will require the I/O to 
    // be updated. We are hardcoding that here.
    let updateIOSet_ER = false;
    let updateIOSet_CV = false;
    let updateIOSet_SC = false;

    let setting = getLocAttributeSetting(locAttrInfo, 'ER');
    if (setting) {
        setting.updateIOSelections = true;
        updateIOSet_ER = true;
    }

    setting = getLocAttributeSetting(locAttrInfo, 'CV');
    if (setting) {
        setting.updateIOSelections = true;
        updateIOSet_CV = true;
    }

    setting = getLocAttributeSetting(locAttrInfo, 'SC');
    if (setting) {
        setting.updateIOSelections = true;
        updateIOSet_SC = true;
    }

    // I/O Redundancy - for the 5015 Platform. Other
    // platforms will not have this setting (so far).
    setting = getLocAttributeSetting(locAttrInfo, 'IO');
    if (setting) 
        setting.updateIOSelections = true;

    // Should ALWAYS have these set.
    if (updateIOSet_ER === false || updateIOSet_CV === false || updateIOSet_SC === false)
        throw new Error('generalFinalizeGuidedSelection() has not set updateIOSelections flag on all required settings!');
}