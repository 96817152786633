import React from 'react';
import {
    getModuleSlotBreakdown,
    getModuleSlotID,
} from '../../../model/ChassisProject';
import { CLXLayoutInfo } from '../types/CLXTypes';
import SelectableDeviceComp from '../../../components/SelectableDeviceComp';
import SlotIDComp from '../../../components/SlotIDComp';
import { getChassisRenderSpec } from '../../../util/LayoutModeHelp';
import { ChassisRendType, ModuleDragStatus } from '../../../types/ProjectTypes';
import { ChassisCompProps, getSlotID } from '../../../implementation/ImplGeneral';
import ChassisElComp from '../../common/ChassisElComp';
import { ChassisElementType } from '../../common/CommonPlatformTypes';


const CLXChassisComp = (props: ChassisCompProps) => {

    // Get our chassis layout. We should ALWAYS be able to.
    const layout = props.chassis.layout as CLXLayoutInfo;
    if (!layout) {
        throw new Error('ClxChassisComp passed chassis without valid layout!');
    }

    // This is a primary chassis unless our render type
    // specifically says we're a redundant secondary.
    const primaryChassis = (props.renderType !== ChassisRendType.RedSecondary);

    const defaultRender = (!props.renderType) || (!props.layoutMode);
    const modeForSpec = defaultRender ? undefined : props.layoutMode;

    // Call a helper to give us rendering details for the various 
    // elements of our chassis (ps, modules, empty slots, etc.)
    const elRenderSpec = getChassisRenderSpec(modeForSpec,
        props.chassis, primaryChassis, (props.showAsSelected === true));

    // Get breakdown of our slots (which have modules and which are empty).
    const [modSlots, emptySlots] = getModuleSlotBreakdown(props.chassis.modules);

    // See if our chassis should be depicted as 'selected'.
    const showSelected = (props.showAsSelected === true);

    // Determine the opacity we'll use for empty slots.
    // We want them to be faded and lighter when we have
    // add ('+') action buttons on them.
    const emptySlotOpacity = showSelected ? 0.7 : 1.0;

    const showSlotIds = defaultRender ? false : true;


    const renderModule = (slotNum: number) => {
        const module = props.chassis.modules[slotNum];
        if (module) {
            const rndType = (props.renderType !== undefined)
                ? module.slotFiller ? elRenderSpec.slotFillers : elRenderSpec.modules
                : undefined;
            const slotId = showSlotIds
                ? getModuleSlotID(module)
                : undefined;
            return (
                <SelectableDeviceComp
                    key={slotNum}
                    device={module}
                    devDragStatus={module.dragStatus}
                    showSelected={showSelected && (props.localDeviceSelected === module)}
                    renderType={rndType}
                    ptOrg={props.ptOrg}
                    relLocation={layout.slotLocs[slotNum]}
                    slotId={slotId}
                />
            );
        }
        else {
            return (
                null
            );
        }
    }

    // Note: In order to best preserve key uniqueness
    // from render to render, we'll ONLY use an incrementing
    // number as a key for separators (slot seps and grp seps).
    // Those should ALWAYS be the same to the same chassis layout.
    let nextSepId = 1;

    return (
        <>
            {props.chassis.ps
                ? <SelectableDeviceComp
                    key={'PS'}
                    device={props.chassis.ps}
                    devDragStatus={ModuleDragStatus.NA}
                    showSelected={showSelected && (props.localDeviceSelected === props.chassis.ps)}
                    renderType={props.renderType ? elRenderSpec.ps : undefined}
                    ptOrg={props.ptOrg}
                    relLocation={props.chassis.ps.loc}
                />
                : null}
            {modSlots.map(slot => {
                return (renderModule(slot));
            })}
            {emptySlots.map(slot => {
                return <ChassisElComp
                    key={slot}
                    elType={ChassisElementType.EmptySlot}
                    renderType={elRenderSpec.emptySlots}
                    imgSrc={layout.emptySlotImgSrc}
                    ptOrg={props.ptOrg}
                    relLocation={layout.slotLocs[slot]}
                    opacity={emptySlotOpacity}
                />
            })}
            {showSlotIds && emptySlots.map(slot => {
                return <SlotIDComp
                    key={'SID' + slot}
                    slotId={getSlotID(props.chassis, slot)}
                    ptOrg={props.ptOrg}
                    relSlotLoc={layout.slotLocs[slot]}
                    emptySlot={true}
                />
            })}
            {layout.slotSepLocs.length
                ? layout.slotSepLocs.map(loc => {
                    return <ChassisElComp
                        key={nextSepId++}
                        elType={ChassisElementType.SlotSep}
                        renderType={elRenderSpec.slotSeps}
                        imgSrc={layout.slotSepImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={loc}
                    />

                })
                : null}
            {layout.grpSepLocs.length
                ? layout.grpSepLocs.map(loc => {
                    return <ChassisElComp
                        key={nextSepId++}
                        elType={ChassisElementType.GrpSep}
                        renderType={elRenderSpec.slotGrpSeps}
                        imgSrc={layout.grpSepImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={loc}
                    />

                })
                : null}
            <ChassisElComp
                key={'RC'}
                elType={ChassisElementType.RightCap}
                renderType={elRenderSpec.rightCap}
                imgSrc={layout.rightCapImgSrc}
                ptOrg={props.ptOrg}
                relLocation={layout.rightCapLoc}
            />
        </>
    );

}

export default CLXChassisComp;


