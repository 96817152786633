import { Workbook, Worksheet } from "exceljs";
import { StatusLevel } from '../types/MessageTypes';
import { displayAlertMsg, displayUnexpectedErrorMsg } from "../util/MessageHelp";
import { ChassisProject } from "../types/ProjectTypes";
import { getPlatformsLoaded, LoadPlatform } from "../services/selectionAPIs/PlatformDataLoader";
import { User } from "oidc-client";
import { getCurrentLocPlatform, getLocAttributeSetting, refreshLocAttrInfoSelectionArray, updateGuidedSelection, validateLocAttrInfo } from "../model/GuidedSelection";
import { getIOPointFilterNameToValMap, getPointTypeBitsFromText } from "../model/IOModule";
import { getXLCellValueAsString, AddDataToTemplate, TPLFld_ProjName, TPLFld_Industry, TPLFld_InstLoc, loadXLTemplateInfo } from "./PlatformTemplateUtils";
import { linkExportElement } from "../appLayout/AppView";
import { PointTypeFilterMask } from "../types/IOModuleTypes";
import { createDefaultPointEntry, getIOPointFilterMap } from "../implementation/ImplHardwareGen";
import { getLocationSettings } from "../model/ChassisProject";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "./PlatformConstants";
import { addLocationAttrInfo, getExistingLocAttrInfoForPlatform, getLocationAttrInfo } from "../model/LocAttributeInfo";
import { IOEntryModeEnum } from "../types/SettingsTypes";
import { AllowNonAvailablePlatformTemplatesToLoad } from "../types/Globals";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const clxTemplateFile = require('./clx/templates/cs_1756SettingsTemplate.xlsx');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const cplxTemplateFile = require('./cplx/templates/cs_5069SettingsTemplate.xlsx');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const flexTemplateFile = require('./flex/templates/cs_5094SettingsTemplate.xlsx');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const flexHATemplateFile = require('./flexHA/templates/cs_5015SettingsTemplate.xlsx');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const microTemplateFile = require('./micro/templates/cs_2080SettingsTemplate.xlsx');


////// Globals /////////////////////////
let _workingInfo: XLTemplateInfo | undefined = undefined;

export type onTemplateLoadedCallback = () => void;
export const tplNoOp = () => { return; };


export interface XLTemplateInfo {
	action: XLTplAction;
	project: ChassisProject;
	workbook: Workbook;
	platform: string;
	platformBusCode: string;
	specSheet: Worksheet;
	settingSheet: Worksheet;
	onLoadedCallback: onTemplateLoadedCallback;
	spec: XLSpec;
}

export enum XLTplAction {
	None = 'None',
	Import = 'Import',
	Export = 'Export',
	Download = 'Download',
}

export interface XLSpec {
	dataSheet: Worksheet;
	mapVarNameToCellAddress: Map<string, string>;
	platformDataCellAddress: string;
	platformBusCode: string;
	ioEntryRowStart: number;
	ioEntryColType: string;
	ioEntryColFunction: string;
	ioEntryColPointQty: string;
	ioEntryColLocation: string;
	ioEntryColNotes: string;
	// The template's DataCell Sheet has a list of
	// Identifiers to CellAddr. Some identifiers need
	// 2 pieces of information: One for the Selection ID
	// cell; one for the the Selection Display value. When
	// we import, we only care about the ID cells. When we
	// export, we only will set values in the Display cells.
	// Any identifier with a source partner
	mapDisplay_AttrToCellAddr: Map<string, string>;
	valid: boolean;
}


export const createNewXLSpec = (specSheet: Worksheet): XLSpec => {
	return {
		dataSheet: specSheet,
		mapVarNameToCellAddress: new Map<string, string>(),
		platformDataCellAddress: '',
		platformBusCode: '',
		ioEntryRowStart: 0,
		ioEntryColType: '',
		ioEntryColFunction: '',
		ioEntryColPointQty: '',
		ioEntryColLocation: '',
		ioEntryColNotes: '',
		mapDisplay_AttrToCellAddr: new Map<string, string>(),
		valid: false,
	};
}


//////////////////// IMPORT RELATED ////////////////////////

interface ImportIOEntry {
	type: string;
	typeBits: number;
	function: string;
	functionBits: number;
	pointQty: number;
}

const getNewImportIOEntry = (): ImportIOEntry => {
	return {
		type: '',
		typeBits: 0,
		function: '',
		functionBits: 0,
		pointQty: 0,
	};
}


//////////////// IMPORT /////////////////////////////////////////////

export const importTemplate = async (file: File, project: ChassisProject, callback: onTemplateLoadedCallback) => {
	_workingInfo = undefined;

	try {
		_workingInfo = await loadXLTemplateInfo(file, project, callback);
		if (_workingInfo) {
			// If we are NOT allowing platform templates to be loaded
			// when we are NOT launched as that platform...
			if (!AllowNonAvailablePlatformTemplatesToLoad) {
				const arrPlatforms = getPlatformsLoaded();
				if (!arrPlatforms.find(x => x === _workingInfo?.platform)) {
					const msg = `Please check that the file you are trying to import is in the same format as the template.`
					displayAlertMsg(msg);
					return;
				}
			}
			_workingInfo.action = XLTplAction.Import;
			LoadPlatform(_workingInfo.platform, '', onPlatformInitForImport);
			return;
		}
	}
	catch (e) {
		// One instance when we can get here is when a user
		// selects an image file and an exception is thrown.
		e;
	}

	displayAlertMsg('Error - Please select a valid template to import.', StatusLevel.Warning);
}


export const onPlatformInitForImport = (platform: string, success: boolean) => {
	platform;
	if (success && _workingInfo) {
		finalizeImport();
	}
}


const finalizeImport = () => {
	if (_workingInfo == null)
		return;

	// We should have a default/valid locAttrInfo
	// in the project.
	let locAttrInfo = getLocationSettings(_workingInfo.project);

	// 2024.3.6 We potentially will have a different
	// platform coming in from what we currently have.
	// Start by looking for an existing loc for the
	// platform. At this time, we should have at most ONE.
	if (locAttrInfo.platform !== _workingInfo.platform) {
		const arrLocs = getExistingLocAttrInfoForPlatform(_workingInfo.platform);
		if (arrLocs && arrLocs[0] != null) {
			locAttrInfo = arrLocs[0];
			_workingInfo.project.config.currLocAttrID = arrLocs[0].id;
		}
		else {
			// Create a new location
			const idLoc = addLocationAttrInfo(_workingInfo.platform, '', '');
			const newLoc = getLocationAttrInfo(idLoc);
			if (newLoc == null)
				throw new Error('finalizeImport(): Failed to create new Location Settings.');

			locAttrInfo = newLoc;
			_workingInfo.project.config.currLocAttrID = idLoc;
		}
	}

	const mapVarToCellAddr: Map<string, string> = _workingInfo.spec.mapVarNameToCellAddress;

	// Update our location.
	locAttrInfo.platform = _workingInfo.platform;
	const valIndustry = getXLCellValueAsString(_workingInfo.settingSheet, mapVarToCellAddr.get(TPLFld_Industry));
	if (valIndustry)
		locAttrInfo.industryID = valIndustry;

	const valCountry = getXLCellValueAsString(_workingInfo.settingSheet, mapVarToCellAddr.get(TPLFld_InstLoc));
	if (valCountry)
		locAttrInfo.installLocationID = valCountry;

	// Note: Passing in true (last arg) will skip
	// validating the Guid.Sel. This is important
	// since we want ALL Options avaiable when importing.
	updateGuidedSelection(
		locAttrInfo,
		onGuidedSelectionLoaded,
		true); 
}


const onGuidedSelectionLoaded = (success: boolean) => {
	if (_workingInfo == null || !success) {
		_workingInfo = undefined;
		throw new Error('onGuidedSelectionLoaded(): Template Import failed due to Guided Selection not loading correctly.');
	}

	// We should have the InstallLoc and Industry IDs in the location.
	// move those to the project config.
	const locAttrInfo = getLocationSettings(_workingInfo.project);
	_workingInfo.project.config.industryID = locAttrInfo.industryID;
	_workingInfo.project.config.installLocID = locAttrInfo.installLocationID;

	// Get the project name.
	const addrCell = _workingInfo.spec.mapVarNameToCellAddress.get(TPLFld_ProjName);
	if (addrCell) {
		const strVal = getXLCellValueAsString(_workingInfo.settingSheet, addrCell);
		if (strVal)
			_workingInfo.project.config.projectName = strVal;
	}

	// Walk all of the sheet var names. If we have
	// any that are < 4 chars, try to get the attribute
	// from the locAttrInfo and set the value.
	refreshLocAttrInfoSelectionArray(locAttrInfo);

	let validateLocAttr = false;
	_workingInfo.spec.mapVarNameToCellAddress.forEach((addr, key) => {
		// If the key is an attribute...
		if (locAttrInfo.arrAttributeNameToValue.some(x => x.attrID === key)) {
			const setting = getLocAttributeSetting(locAttrInfo, key);
			if (setting) {
				// Get the option value from the template.
				if (addr) {
					const strVal = getXLCellValueAsString(_workingInfo?.settingSheet, addr )
					// Try to find the setting option
					const option = setting.options.find(x => x.id === strVal);
					if (option) {
						validateLocAttr = (validateLocAttr || setting.validateOnChange === true);
						setting.selectedOption = option;
					}
				}
			}
		}
	});

	// When we loaded the guided selection, we did NOT
	// validate it. Validate it now!
	validateLocAttrInfo(locAttrInfo);

	// Point entries - This data will (most likely) have multiple
	// rows. We start by getting how many rows the sheet currently
	// has. The SPEC has the starting ROW and the COLUMNS for each
	// piece of entry data.
	const sheetRowCount = _workingInfo.settingSheet.rowCount;

	let row = 0;
	let ioEntryColType = '';
	let ioEntryColFunction = '';
	let ioEntryColPointQty = '';
	if (_workingInfo.spec) {
		row = _workingInfo.spec.ioEntryRowStart;
		ioEntryColType = _workingInfo.spec.ioEntryColType;
		ioEntryColFunction = _workingInfo.spec.ioEntryColFunction;
		ioEntryColPointQty = _workingInfo.spec.ioEntryColPointQty;
	}

	// Verify we have everything we need.
	if (sheetRowCount === 0 || row > sheetRowCount || !ioEntryColType || !ioEntryColFunction || !ioEntryColPointQty) {
		_workingInfo.onLoadedCallback();
		_workingInfo = undefined;
		return;
	}

	// Get a map of I/O filters (Filter Name to Filter Bit).
	const functionMap = getIOPointFilterNameToValMap(PointTypeFilterMask, false, false);

	const arrImportIOEntries: ImportIOEntry[] = [];

	// 'Bad Rows' will be the number of blank/invalid
	// sequential rows. If we reach a threshold (5 for now)
	// we stop looking for I/O Entries.
	let badRows = 0;
	let advancedPointType = false;
	while (row < sheetRowCount && badRows < 5) {
		const impIOEntry = getNewImportIOEntry();
		let addrCell = '';

		// Get the I/O Type
		addrCell = `${ioEntryColType}${row}`;

		impIOEntry.type = getXLCellValueAsString(_workingInfo.settingSheet, addrCell);
		if(impIOEntry.type)
			impIOEntry.typeBits = getPointTypeBitsFromText(impIOEntry.type);


		// Are we good so far...
		if (impIOEntry.typeBits > 0) {
			addrCell = `${ioEntryColFunction}${row}`;
			impIOEntry.function = getXLCellValueAsString(_workingInfo.settingSheet, addrCell);
			if (impIOEntry.function) {
				// Try to find it in our map.
				functionMap.forEach((x) => {
					if (x.name === impIOEntry.function) {
						advancedPointType = true;
						impIOEntry.functionBits = x.value;
					}
				});
			}


			addrCell = `${ioEntryColPointQty}${row}`;
			const qty = Number(getXLCellValueAsString(_workingInfo.settingSheet, addrCell));
			if (isNaN(qty) === false && qty > 0)
				impIOEntry.pointQty = qty;

			arrImportIOEntries.push(impIOEntry);

			// Reset our 'bad rows'
			badRows = 0;
		}
		else {
			// Increment our bad rows.
			badRows++;
		}

		row++;
	}

	const platform = _workingInfo.platform;

	const mapTypeToFilter = getIOPointFilterMap(platform);
	// Do we have any I/O entries...
	if (arrImportIOEntries.length > 0) {
		// Dump all existing entries...
		locAttrInfo.pointEntrySection.entries.length = 0;
		arrImportIOEntries.forEach((x) => {
			const entry = createDefaultPointEntry(locAttrInfo, x.typeBits);
			entry.points = x.pointQty;
			// Check if the filter/function bit(s) are compatible.
			// Note: the Template does not enforce correct values.
			const allowedFilters = mapTypeToFilter.get(x.typeBits);
			if (allowedFilters && (allowedFilters & x.functionBits) === x.functionBits)
				entry.filterInclude |= x.functionBits; // OR the filter.
			locAttrInfo.pointEntrySection.entries.push(entry);
		});
	}

	// These changes will trigger a refresh of I/O point entries
	// and project attributes. Setting 'externalMaskChanged' will
	// select the correct I/O Modules.
	locAttrInfo.pointEntrySection.externalMaskChanged = true;

	// Update the config to match the location's Industry and InstallLoc.
	_workingInfo.project.config.industryID = locAttrInfo.industryID;
	_workingInfo.project.config.installLocID = locAttrInfo.installLocationID;

	if (advancedPointType) {
		locAttrInfo.ioEntryMode = IOEntryModeEnum.Advanced;
		_workingInfo.project.config.IOEntryMode = IOEntryModeEnum.Advanced;
	}

	_workingInfo.onLoadedCallback();

	_workingInfo = undefined;
}

//////////////// EXPORT /////////////////////////////////////////////

const getExportTemplateFileName = (project: ChassisProject, templateDownload: boolean): string => {
	// We should ALWAYS have a location and a platform.
	// However, if we don't, default to CLX.
	const platform = getCurrentLocPlatform(project);
	if (templateDownload) {
		switch (platform) {
			case PlatformCLX:
				return '1756-IO-List-Template.xlsx';
			case PlatformCpLX:
				return '5069-IO-List-Template.xlsx'; 
			case PlatformFlex:
				return '5094-IO-List-Template.xlsx';
			case PlatformFlexHA	: // 2024.5
				return '5015-IO-List-Template.xlsx';
			case PlatformMicro :
				return '2080-IO-List-Template.xlsx';
			default:
				throw new Error(`getExportTemplateFileName(): Platform <${platform}> not recognized!`);
		}
	}

	let name = 'NoConfigurationName.xlsx';
	if (project.config.projectName)
		name = `${project.config.projectName}.xlsx`;

	return name;
}

const dispatchDownloadevent = ()=> {
	const downloadTemplateClick = new CustomEvent("designDownloadTemplate", {
		detail: {
		action: "Downloaded Template",
		properties: {
			category: "WebApp",
			label: "Downloaded Template",
		},
		},
	});
	document.getElementById("root")?.dispatchEvent(downloadTemplateClick);
}

const disptachexportevent =() => {
	const exportIOListClick = new CustomEvent("designExportIOList", {
		detail: {
		action: "Export IO List",
		properties: {
			category: "WebApp",
			label: "Export IO List",
		},
		},
	});
	document.getElementById("root")?.dispatchEvent(exportIOListClick);
}

const _fetchTemplateFile = async (platform: string): Promise<Response> => {
	switch (platform) {
		case PlatformCLX:
			return fetch(clxTemplateFile);
		case PlatformCpLX:
			return fetch(cplxTemplateFile);
		case PlatformFlex:
			return fetch(flexTemplateFile);
		case PlatformFlexHA:
			return fetch(flexHATemplateFile); 
		case PlatformMicro:
			return fetch(microTemplateFile); 
		default:
			throw new Error(`_fetchTemplateFile(): Platform <${platform}> not recognized!`);
	}
}

export const exportTemplate = async (project: ChassisProject, downloadTemplate: boolean, user?: User) => {
	_workingInfo = undefined;

	// We should ALWAYS have a location and a platform.
	// However, if we don't, default to CLX.
	const platform = getCurrentLocPlatform(project);
	const action = (downloadTemplate ? XLTplAction.Download : XLTplAction.Export);

	// We are using this link element to export the template.
	// The actual anchor element is located in the AppView
	// component. If we do not have it...
	if (linkExportElement == null) {
		displayUnexpectedErrorMsg('Unable to export template.');
		return;
	}

	try {
		// Here we are taking our local template file, converting
		// it to a 'blob', then converting the blob to a file.
		const response = await _fetchTemplateFile(platform);
		const data = await response.blob();
		const metadata = {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
		};

		const templateName = getExportTemplateFileName(project, downloadTemplate);

		const file = new File([data], templateName, metadata);

		// Call LoadTemplate to set our target Workbook, Worksheet,
		// and Template Spec.
		_workingInfo = await loadXLTemplateInfo(file, project, undefined);
		if (_workingInfo) {
			_workingInfo.action = action;
			// If we are NOT simply downloading an empty template...
			if (action === XLTplAction.Export) {
				// Populate the template with the settings and point
				// entries. If something went sideways, return.
				if (AddDataToTemplate(_workingInfo, user) === false){ 
					return;
				}
			}

			/*const datacells = await _workingInfo.workbook.getWorksheet('DataCells');
			if (datacells !== undefined) {
				datacells.state = 'veryHidden'
			}
			const DropDownValues = await _workingInfo.workbook.getWorksheet('DropDownValues');
			if (DropDownValues !== undefined) {
				DropDownValues.state = 'veryHidden'
			}*/		

			const buffer = await _workingInfo.workbook.xlsx.writeBuffer();
			if (buffer) {
				const bllb = new Blob([buffer]);
				const url = URL.createObjectURL(bllb);
				if (linkExportElement) {
					linkExportElement.href = url;
					linkExportElement.download = templateName;
					linkExportElement.click();
				}
			}			
		}
		else {
			displayUnexpectedErrorMsg('Unable to export template. Please contact customer support.');
			return;
		}
		if (action === XLTplAction.Export) {
			disptachexportevent();
		}
		else {
			dispatchDownloadevent();
		}
	}
	catch (e) {
		e;
		displayUnexpectedErrorMsg('An unexpected error occurred during the template export.');
	}

	_workingInfo = undefined;
}