import React from 'react';
import './CommonToolbar.scss';
import '../styles/AppLayout.scss';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ExportIcon } from '@rockwell-automation-inc/ra-meatball';
import { linkExportElement } from "../appLayout/AppView";

import {
  Box, Button, Divider,
  FormControlLabel, FormGroup, IconButton,
  Switch, SwitchProps, styled
} from '@mui/material';
import { BOMItem, BOMItemProduct } from '../summary/SummaryHelp';
import { ChassisProject } from '../types/ProjectTypes';
import { useAppSelector } from '../redux/hooks';
import { startupDetails } from '../redux/slices/StartupInfoSlice';
import { saveInProgress } from '../redux/slices/SaveRestoreSlice';
import { txtRADB_LOADING } from '../types/APITypes';
import { getSummaryProjectBtnInfo } from '../userProject/UserProjectHelp';
import { ToolbarAction } from './ToolbarActions';


interface Props {
    project: ChassisProject;
    showRefreshIcon: boolean;
    onRefreshPricing: () => void;
    handleBomTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    bomType: string;
    onTBAction: (action: string) => void;
    item: BOMItem[]
    exportFileName: string;
}

const ExportToCsvButton = (item: BOMItem[], exportFileName : string ) => {
    const configurationName = exportFileName;
    // Create the CSV content
    console.log ( "print log", item)
    const csvContentArray = item[0].children.map(row=>
      {
        const data = row as BOMItemProduct
        if(data.totalCost==txtRADB_LOADING)
        return(`${data.catalogOrTitle},${data.quantity},"N/A"`)
      else
   // Add extra spaces for pricing  after each value to remove #### 
        return(`${data.catalogOrTitle},${data.quantity},${data.totalCost.replaceAll(",", "‚")}`)
      });
    csvContentArray.unshift(`Product,Qty,List Price`);
    const csvContent = csvContentArray.join("\n");
     
    // Create a Blob object representing the data as a CSV file
    const blob = new Blob(["\uFEFF"+csvContent], {  type: 'text/csv; charset=utf-8' });
    const url = URL.createObjectURL(blob);

   // Create a link element to trigger the download
    if (linkExportElement) {
      linkExportElement.href = url;
      linkExportElement.download = `${configurationName} Export.csv`;
      linkExportElement.click();
    }
}
const SummaryRightToolbar = (props: Props) => {

    const startupDtls = useAppSelector(startupDetails);
    const saving = useAppSelector(saveInProgress);

    const { project, showRefreshIcon, bomType, onRefreshPricing, handleBomTypeChange } = props;
    const [saveBtnText, disableSaveBtn, saveOrGotoAction] = saving
        ? ['SAVING', true, ToolbarAction.GOTOPROJECT]
        : getSummaryProjectBtnInfo(startupDtls?.projectGuid, project);

    return (
        <>
            <Box sx={{ maxWidth: "60%" }}>
                {showRefreshIcon ?
                    <div className="project-summary-refresh-pricing">
                        <IconButton onClick={onRefreshPricing} sx={{ borderRadius: "10px", width: "100%", padding: 0.5 }}>
                            <RefreshIcon htmlColor="darkorange" fontSize="large" />
                            <label className="project-summary-refresh-pricing-text">
                                Some products could not be loaded. Click here to try again.</label>
                        </IconButton>
                    </div >
                    :
                    null
                }
            </Box>

            <div className="project-summary-bom-toolbar-group">
                <div>
                    <FormGroup
                        row
                        onChange={handleBomTypeChange}
                    >
                        <FormControlLabel
                            value={bomType === "Organized" ? "Consolidated" : "Organized"}
                            control={<BOMSwitch />}
                            label="Consolidated BOM"
                            labelPlacement="start"
                            sx={{
                                color: bomType === "Organized" ? "#858383" : "inherit",
                                fontFamily: "Barlow", fontWeight: 400, lineHeight: "18px", fontSize: "14px"
                            }}
                        />
                    </FormGroup>
                </div>

        <div title='Export'>
						<span>
							<Button
								variant='outlined'
								startIcon={<ExportIcon />}
								onClick={() => ExportToCsvButton(props.item, props.exportFileName )}
								disabled={false}
								color='mediumGrey'
							>
								Export
							</Button>
						</span>
					</div>

                <div>
                    <Divider
                        orientation={'vertical'}
                        sx={{
                            height: '36px',
                            border: '1px solid lightgray',
                        }}
                    />
                </div>

                <div>
                    <Button
                        id={saveOrGotoAction}
                        variant='contained'
                        disabled={disableSaveBtn}
                        onClick={() => props.onTBAction(saveOrGotoAction)}
                    >
                        {saveBtnText}
                    </Button>
                </div>
            </div>

        </>
    );
}

export default SummaryRightToolbar;


const BOMSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: 8,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        //   backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'red'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      // opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    //   transition: theme.transitions.create(['background-color'], {
    // duration: 500,
    //   }),
  },
}));