import React from 'react';
import '../styles/AppLayout.scss';
import ProjectTree from '../projTree/ProjectTree';
import SelLeftPanelToolbar from '../toolbar/SelLeftPanelToolbar';
import ProjInfoBrief from '../projInfo/ProjInfoBrief';
import { getCurrentLocPlatform } from '../model/GuidedSelection';
import { ChassisProject } from '../types/ProjectTypes';


interface Props {
    project: ChassisProject;
    onTBAction: (action: string) => void;
    contentChanged: () => void;
}

const ChassisSelTreePanel = (props: Props) => {

    return (
        <div className='chassis-selection-left-panel'>
            <SelLeftPanelToolbar onTBAction={props.onTBAction} />
            <ProjInfoBrief
                project={props.project}
                platform={getCurrentLocPlatform(props.project)}
            />
            <ProjectTree
                racks={props.project.content.racks}
                contentChanged={props.contentChanged}
            />
        </div>
    );

}

export default ChassisSelTreePanel;

