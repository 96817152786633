import React, { ReactNode, useRef } from 'react';
import { displayAlertMsg } from '../util/MessageHelp';
import { HdrErrState } from './SettingHeader';
import { getMessageStdIcon, getSettingLabelColor, SettingIconButton } from './SettingIconButton';
import { Tooltip } from '@mui/material';



interface Props {
	state: HdrErrState;
	message: string;
	color?: string;
}


export const SettingsStatusCtrl = (props: Props) => {

	const _iconButtonCtrl = useRef<ReactNode>(null);
	const prevMessage = useRef<string>('');
	const prevState = useRef<HdrErrState>(HdrErrState.none);
	const ctrlColor = useRef<string>('');

	const onInfo = () => {
		if (props.message)
			displayAlertMsg(props.message, getMessageStdIcon(props.state));
	};

	if (props.state !== prevState.current || (props.message && props.message !== prevMessage.current)) {
		prevState.current = props.state;
		prevMessage.current = props.message;
		_iconButtonCtrl.current = <SettingIconButton message={props.message} state={props.state} iconSize={'16'} />
		ctrlColor.current = (props.color ? props.color : getSettingLabelColor(props.state));
	}

	return (
		<div className="a-type__legal">
			<Tooltip
				title={props.message}
			>
				<div
					className="setting-control-status-icon-div"
					style={{ color: ctrlColor.current }}
					onClick={onInfo}
				>
					{_iconButtonCtrl.current}
					<div className="setting-control-status-label">
						{props.state === HdrErrState.warning ?
							<label className="setting-control-status-label">Warning</label>
							:
							<label className="setting-control-status-label">Invalid Selection</label>
						}
					</div>
				</div>
			</Tooltip>
		</div>
	);
}