

////////////// Config/Edit Chassis Dlg driven off of Guided Selection ////////////////////////////
// We are using the Guided Selection and it's rules
// to drive most of the Chassis Cfg Dialog. For CLX,
// the Power Supply selection will be based on the
// Control Voltage selected in the Guided Selection,
// BUT the actual PSU choice we be from a PSU list
// generated based on Engineering Data.

import { updateGuidedSelection } from "../../../model/GuidedSelection";
import { makeSettingGroup } from "../../../settings/SettingsHelp";
import { ChassisCfgGrpCategory, createNewLocAttributeInfo, LocAttributeInfo, onCfgLocAttrCreatedCallback } from "../../../types/ProjectTypes";
import { IOEntryModeEnum } from "../../../types/SettingsTypes";
import { logger } from "../../../util/Logger";

let _cfgAttrCallback: onCfgLocAttrCreatedCallback | undefined = undefined;
export const flexHAGetLocAttrInfoForChassisEdit = (platform: string, callback: onCfgLocAttrCreatedCallback) => {
    _cfgAttrCallback = callback;

    const configAttrInfo = createNewLocAttributeInfo(platform, '', '', IOEntryModeEnum.Basic);

    // Note: We skip validation so that everything is
    // reloaded and all options are present (3rd param === true)
    updateGuidedSelection(configAttrInfo, _onGdSelForChassisEditLoaded, true);
}

export const flexHAPrepareLocAttrForChassisConfig = (locAttrInfo: LocAttributeInfo): boolean => {
    // Start by pulling ALL of the settings into a groups.
    // The idea here is to rearrange the settings into 3
    // attribute groups: Cfg Page 1; Cfg Page 2; and all
    // the other settings not in the first 2 pages. The
    // pages relate to the Cfg Dlg Tabs.
    const cfgDlgPage1 = makeSettingGroup(ChassisCfgGrpCategory.Chassis);
    const cfgDlgPage2 = makeSettingGroup(ChassisCfgGrpCategory.Power);
    const cfgNonDisplay = makeSettingGroup(ChassisCfgGrpCategory.Hidden);

    // Preprocess our settings
    locAttrInfo.attrGroups.forEach((grp) => {
        grp.settings.forEach((setting) => {
            const idxAutoOption = setting.options.findIndex(x => x.id === 'Auto');
            if (idxAutoOption >= 0) {
                // Remove it from the setting options.
                setting.options.splice(idxAutoOption, 1);

                // Remove it from our map.
                if (setting.gsMapOptionTxtValToInfo) {
                    let mapKeyForAuto = '';
                    setting.gsMapOptionTxtValToInfo.forEach((val, key) => {
                        if (val.id === 'Auto')
                            mapKeyForAuto = key;
                    });

                    if (mapKeyForAuto)
                        setting.gsMapOptionTxtValToInfo.delete(mapKeyForAuto);
                }

                // Select the first entry IF Auto is selected.
                if (setting.selectedOption.id === 'Auto')
                    setting.selectedOption = setting.options[0];
            }
        })
    });

    // Note: the settings will be added in the order that
    // they appear in the guide selection, which at this
    // point is the correct order. If that changes, we will
    // need to add a sort function or a smarter way of
    // building out each page's setting array.
    locAttrInfo.attrGroups.forEach((grp) => {
        grp.settings.forEach((setting) => {
            switch (setting.id) {
                case 'ER':
                case 'RTB':
                    cfgDlgPage1.settings.push(setting);
                    break;
                case 'CV':
                    cfgDlgPage2.settings.push(setting);
                    break;
                default:
                    // 2024.4.22 Ignore any errors on
                    // the hidden attributes. These
                    // attributes should NOT impact
                    // the Edit/Add of a chassis.
                    setting.ignoreError = true;
                    cfgNonDisplay.settings.push(setting);
                    break;
            }
        });
    });

    // Validate we have the correct number of settings in
    // each page. From above, Page1 has 4 and Page2 has 1.
    const valid = (cfgDlgPage1.settings.length === 2 && cfgDlgPage2.settings.length === 1);
    if (valid === false)
        logger.error('snapGetLocAttrInfoForChassisEdit(): Error - not all required attribute settings found in Guided Selection.');

    // Dump the original attr groups, and add
    // the new groups.
    locAttrInfo.attrGroups.length = 0;
    locAttrInfo.attrGroups.push(cfgDlgPage1);
    locAttrInfo.attrGroups.push(cfgDlgPage2);
    locAttrInfo.attrGroups.push(cfgNonDisplay);

    return valid;
}

const _onGdSelForChassisEditLoaded = (success: boolean, locAttrInfo: LocAttributeInfo | undefined) => {
    if (_cfgAttrCallback == null)
        throw new Error('Snap::_onGdSelForChassisEditLoaded(): callback function undefined!');

    if (success && locAttrInfo) {
        const valid = flexHAPrepareLocAttrForChassisConfig(locAttrInfo);
        _cfgAttrCallback(valid, locAttrInfo);
        return;
    }

    throw new Error('Snap::_onGdSelForChassisEditLoaded(): Guided Selection failed!');
}