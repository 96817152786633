import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'


interface Props {
    text: string;
}

const DetailsWatermark = (props: Props) => {

    return (
        <div className='floating-details-watermark'>
            <span className='a-type__subTitle2'>{props.text}</span>
        </div>
    );

}

export default DetailsWatermark;
