import React from 'react';
import './ButtonMenus.scss';
import { SvgIcon } from '@mui/material';
import { ImportedMuiIcon, ReactSVGComp } from '../util/IconHelp';

import MenuItem from '@mui/material/MenuItem';


export interface StandardMenuItemDetail {
    id: string;
    label: string;
    iconSvg?: ReactSVGComp;
    iconMui?: ImportedMuiIcon;
    disabled?: boolean;
}

interface Props {
    detail: StandardMenuItemDetail
    onClickItem: (id: string) => void;
}

const StandardMenuItem = (props: Props) => {

    const onClick = () => {
        props.onClickItem(props.detail.id);
    }

    const renderIcon = () => {
        if (props.detail.iconSvg) {
            const ReqSvgIcon = props.detail.iconSvg;
            return (
                <SvgIcon
                    component={ReqSvgIcon}
                    inheritViewBox
                    sx={{
                        stroke: '#000000',
                        fill: '#000000',
                        height: '12px',
                        width: '12px',
                        marginRight: '-4px'
                    }}
                />
            );
        }
        else if (props.detail.iconMui) {
            const MuiIcon = props.detail.iconMui;
            return (
                <MuiIcon
                    sx={{
                        stroke: '#000000',
                        fill: '#000000',
                        height: '12px',
                        width: '12px',
                        marginRight: '-4px'
                    }}
                />);
        }
        else {
            return (null);
        }

    }

    return (
        <MenuItem
            onClick={onClick}
            disableRipple
            disabled={props.detail.disabled === true}
            sx={{ padding: '2px 8px 2px 10px' }}
        >
            {renderIcon()}
            <span className='button-menu-item-text'>
                {props.detail.label}
            </span>
        </MenuItem>
    );
}

export default StandardMenuItem;

