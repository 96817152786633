import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ModalRequestSpec, requestModal } from '../modals/ModalHelp';
import HelpInfoPanel from './HelpInfoPanel';
import { InformationCircleIcon } from '@rockwell-automation-inc/ra-meatball';


const HelpIconButton = () => {

    const onClick = () => {
        const request: ModalRequestSpec = {
            includeButtons: false,
            closeOnInsideClick: true,
            stayOpenOnBackdropClick: false,
            title: 'Usage Information',
            content: HelpInfoPanel
        };

        requestModal(request);
    }

    return (
        <Tooltip title='Helpful tips'>
            <IconButton
                onClick={onClick}
                color='mediumGrey'
            >
                <InformationCircleIcon
                    fontSize='large'
                />
            </IconButton>
        </Tooltip>
    );
}

export default HelpIconButton;
