import React from 'react';
import { ModalButtonInfo } from './ModalHelp';
import { Button } from '@mui/material';


interface Props {
    btnInfo: ModalButtonInfo;
    onClickBtn: (resultStat: number) => void;
}

const ModalButton = (props: Props) => {

    const onBtnClick = () => {
        props.onClickBtn(props.btnInfo.resultStatus);
    }

    return (
        <Button
            id={props.btnInfo.btnText}
            variant={props.btnInfo.variant}
            onClick={onBtnClick}
            disabled={props.btnInfo.disabled}
        >
            {props.btnInfo.btnText}
        </Button>
    );
}

export default ModalButton;

