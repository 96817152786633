import { getBusCodeFromPlatform } from "../../platforms/PlatformIDs";
import { AxiosRequestConfig } from "axios";
import { getApiUrl } from "platform-services";
import { raTimeoutMessage } from "../apis/ProductApiService";
import axios from "axios";
import { ApiTypeID } from "../../types/APITypes";


// By default, APIs will time out after the 
// below value (in milliseconds).
const _timeoutAPI = 15000;

//// API platform Business Header values. 
//const getAPIBusiness = (platform: string) => {
//	switch (platform) {
//		case PlatformCLX:
//			return 'CS_1756';
//		case PlatformCpLX:
//			return 'CS_5069';
//		case PlatformFlex:
//			return 'CS_5094';
//		case PlatformFlexHA:
//			return 'CS_5015';
//	}

//	throw new Error('getAPIBusiness(): Unknown platform: <' + platform + '>');
//}


//////// GUIDED SELECTION ////////////////////////////////
let _lastGSConfig: AxiosRequestConfig = {};
export const getGuidedSelection_API = async (platform: string, industryID: string, countryCode: string, customTimeout: number = -1) => {
	_lastGSConfig = {
		headers: {
			"Content-Type": "application/json-patch+json",
			accept: "application/json",
			"Business": getBusCodeFromPlatform(platform),
		},
	};

	_lastGSConfig.timeout = _timeoutAPI;
	_lastGSConfig.timeoutErrorMessage = raTimeoutMessage;

	if (customTimeout > 0)
		_lastGSConfig.timeout = customTimeout;

	// 2024.2.21 URI encode the parameters. 
	// Some params have reserved characters 
	// (such as '&') which need to be escaped.
	const uriIndustry = encodeURIComponent(industryID);
	const uriCountry = encodeURIComponent(countryCode);
	const url = getApiUrl(ApiTypeID.GuidedSelection);

	const requestURL = `${url}?industry=${uriIndustry}&countryCode=${uriCountry}`;
	const response = await axios.get(requestURL, _lastGSConfig);
	return response;
}


//////// INDUSTRIES //////////////////////////////////
let _lastIndustryConfig: AxiosRequestConfig = {};
export const getIndustries_API = async (platform: string, customTimeout: number = -1) => {
	_lastIndustryConfig = {
		headers: {
			"Content-Type": "application/json-patch+json",
			accept: "application/json",
			"Business": getBusCodeFromPlatform(platform),
		},
	};

	_lastIndustryConfig.timeout = _timeoutAPI;
	_lastIndustryConfig.timeoutErrorMessage = raTimeoutMessage;

	if (customTimeout > 0)
		_lastIndustryConfig.timeout = customTimeout;

	const requestURL = getApiUrl(ApiTypeID.Industries);
	const response = await axios.get(requestURL, _lastIndustryConfig);
	return response;
}


//////// COUNTRIES //////////////////////////////////
let _lastCountryConfig: AxiosRequestConfig = {};
export const getCountries_API = async (platform: string, customTimeout: number = -1) => {
	_lastCountryConfig = {
		headers: {
			"Content-Type": "application/json-patch+json",
			accept: "application/json",
			"Business": getBusCodeFromPlatform(platform),
		},
	};

	_lastCountryConfig.timeout = _timeoutAPI;
	_lastCountryConfig.timeoutErrorMessage = raTimeoutMessage;

	if (customTimeout > 0)
		_lastCountryConfig.timeout = customTimeout;

	const requestURL = getApiUrl(ApiTypeID.Countries);
	const response = await axios.get(requestURL, _lastCountryConfig);
	return response;
}


//////// PRODUCT SELECTION /////////////////////////////
let _lastProductSelConfig: AxiosRequestConfig = {};
export const getProductSelection_API = async (platform: string, customTimeout: number = -1) => {
	_lastProductSelConfig = {
		headers: {
			"Content-Type": "application/json-patch+json",
			accept: "application/json",
			"Business": getBusCodeFromPlatform(platform),
		},
	};

	_lastProductSelConfig.timeout = _timeoutAPI;
	_lastProductSelConfig.timeoutErrorMessage = raTimeoutMessage;

	if (customTimeout > 0)
		_lastProductSelConfig.timeout = customTimeout;

	const requestURL = getApiUrl(ApiTypeID.ProductSelection);
	const response = await axios.get(requestURL, _lastProductSelConfig);
	return response;
}


//////// ENGINEERING DATA //////////////////////////////////
let _lastEngDataConfig: AxiosRequestConfig = {};
export const getEngineeringData_API = async (platform: string, customTimeout: number = -1) => {

	const businessCode = getBusCodeFromPlatform(platform);

	_lastEngDataConfig = {
		headers: {
			"Content-Type": "application/json-patch+json",
			accept: "application/json",
			"Business": businessCode
		},
	};

	_lastEngDataConfig.timeout = _timeoutAPI;
	_lastEngDataConfig.timeoutErrorMessage = raTimeoutMessage;

	if (customTimeout > 0)
		_lastEngDataConfig.timeout = customTimeout;

	const requestURL = getApiUrl(ApiTypeID.EngineeringData);

	const response = await axios.get(requestURL, _lastEngDataConfig);
	return response;
}
