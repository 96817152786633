import React from 'react';
import { EngInfoModule } from '../engData/EngineeringInfo';
import './OLD_ModuleSelection.scss'


interface Props {
	device: EngInfoModule;
	level: number;
	onModuleAdded: (module: EngInfoModule, auto: boolean) => void;
}


export const ModuleSelectionTreeDevice = (props: Props) => {

	const onClick = () => {
		props.onModuleAdded(props.device, false);
	}

	const onDoubleClick = () => {
		props.onModuleAdded(props.device, true);
	}

	return (
		<>
			<span
				className="mstree-device-catalog"
				onClick={onClick}
				onDoubleClick={onDoubleClick}
			>
				{props.device.catNo}
			</span>
			<span
				className="mstree-device-description"
				onClick={onClick}
				onDoubleClick={onDoubleClick}
			>
				{props.device.description}
			</span>
		</>
	);
}
