import { createTheme } from "@mui/material";
import { Theme as MeatballTheme } from "@rockwell-automation-inc/ra-meatball";

const Theme = createTheme(MeatballTheme, {
    components: {
        MuiSelect: {
            defaultProps: {
                ...MeatballTheme.components?.MuiSelect?.defaultProps,
                MenuProps: {
                  disablePortal: false,
                  disableRestoreFocus: false,
                  disableScrollLock: false,
                  marginThreshold: 0,
                },
            },
            styleOverrides: {
                ...MeatballTheme.components?.MuiSelect?.styleOverrides,
            },
        },
    },
});

export default Theme;