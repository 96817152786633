import { LogRender } from "../types/Globals";
import { logger } from "../util/Logger";


// The Design Page is the ChassisSettings.tsx component. The
// As child components are created, they will register their
// own state ComponentRenderInfo's.


// DesignPageChildID - enum for static components. The dynamically
// created Guided Selection Attr Grps will register their render
// functions in the Context using the group title as their ID.
// For example, 'System Attributes' or 'Network Attributes'.
// ComponentRenderInfo interface is used to register components.
export enum DesignPageChildID {
    PointEntrySection = 'PointEntrySection',
    GuidedSelSection = 'GuidedSelSection',
    SettingsToolbar = 'SettingsToolbar',
    DesignPage = 'DesignPage',
}

const _childrenCompRenderInfo: ComponentRenderInfo[] = [];

export interface ComponentRenderInfo {
    componentID: string;
    renderCount: number;
    setRenderCount: (n: number) => void;
}

const NoOpSetFunction = (n: number) => { n; return; }

export const createNewComponentRenderInfo = (): ComponentRenderInfo => {
    return {
        componentID: '',
        renderCount: 0,
        setRenderCount: NoOpSetFunction,
    }
}

export const getDesignPageCompRenderInfo = (id: string): ComponentRenderInfo | undefined => {
    if (_childrenCompRenderInfo.length > 0) {
        const info = _childrenCompRenderInfo.find(x => x.componentID === id);
        if (info)
            return info;
    }

    // In general, we will get here during startup
    // BEFORE the components are loaded/registered.
    if(LogRender.DesignPage)
        logger.log('getDesignPageCompRenderInfo() did not recognized ID of ' + id);

    return undefined;
}

export const registerDesignPageCompRenderInfo = (compRenderInfo: ComponentRenderInfo) => {
    // We should always have something in the ID...
    if (compRenderInfo.componentID) {
        // Check if we have it... If so, remove it.
        const idxInfo = _childrenCompRenderInfo.findIndex(x => x.componentID === compRenderInfo.componentID);
        if (idxInfo > -1)
            _childrenCompRenderInfo.splice(idxInfo, 1);

        _childrenCompRenderInfo.push(compRenderInfo);
        return;
    }
}

export const unregisterDesignPageCompRenderInfo = (compRenderInfo: ComponentRenderInfo) => {
    // We should always have something in the ID...
    if (compRenderInfo.componentID) {
        // Check if we have it... If so, remove it.
        const idxInfo = _childrenCompRenderInfo.findIndex(x => x.componentID === compRenderInfo.componentID);
        if (idxInfo > -1)
            _childrenCompRenderInfo.splice(idxInfo, 1);
        return;
    }
}