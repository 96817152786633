import { AltChassisSAPwrSupplyInfo, EngInfoChassis, EngInfoComponent, EngInfoModule } from "../engData/EngineeringInfo";
import {
    addChassis,
    copyAccys,
    getChassisName,
    getDefaultImageSource,
    getDeviceCategory,
    getProjectFromChassis,
    isValidSlotNumber,
    updateRackLayout
} from "../model/ChassisProject";
import { DetailGroup } from "../model/DeviceDetail";
import { PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants";
import { SelectedCompInfo } from "../selectComponents/SelectComponentsTypes";
import { LogImplDefaults } from "../types/Globals";
import { ActBtnInfo, LayoutActionType } from "../types/LayoutActions";
import {
    Chassis,
    ChassisElement,
    ChassisModule,
    ChassisProject,
    ChassisRendType,
    DeviceCategory,
    MicroChassis,
    ModuleDragStatus,
    ModuleSlotRestriction,
    NO_SLOT,
    Rack,
    SelectableDevice,
    SlotIDError
} from "../types/ProjectTypes";
import { LocAndSize, Point, Size } from "../types/SizeAndPosTypes";
import { getUniqueChassisName } from "../util/CheckerAutoFixProjHelpers";
import { DragDeviceInfo, DropResult, DropStatus } from "../util/DragAndDropHelp";
import {
    getAvailableModules,
    getChassisEngInfo,
    getEngInfoForComp,
    getModuleEngInfo
} from "../util/EngInfoHelp";
import { getEmptyLoc, getEmptyPt, getLocCenter, isPointInLoc, isPointInLocBu } from "../util/GeneralHelpers";
import { getNewInstanceId } from "../util/InstanceIdHelp";
import { LayoutMode } from "../util/LayoutModeHelp";
import { logger } from "../util/Logger";
import { isPlatformSnapType } from "../util/PlatformHelp";
import { getEmptyPowerBreakdown, PowerBreakdown } from "../util/PowerHelp";
import { chassisChanging, suspendUndoSnapshots } from "../util/UndoRedo";

// Registered General Implementations.
const _generalImplRegistry = new Map<string, GeneralImplSpec>();

// Specifications for optional platform-specific routes.
interface _implCreateChassis {
    (chasEngInfo: EngInfoChassis, psCatNo?: string, buCatNo?: string): Chassis | undefined;
}

interface _implReplaceChassisPowerSupply {
    (chassis: Chassis, psCatNo: string): boolean;
}

interface _implConfigureChassis {
    (
        project: ChassisProject,
        platform: string,
        selectChassisCallback: (chassis: Chassis | undefined) => void,
        selectDeviceCallback: (device: SelectableDevice | undefined) => void,
        contentChangedCallback: () => void,
        chassis?: Chassis): void;
}

interface _implGetNumSlots {
    (chassis: Chassis): number;
}

interface _implGetChassisSlotUsage {
    (chassis: Chassis): [slotsInUse: number, slotFillers: number, lastUsedSlot: number];
}

interface _implGetSlotID {
    (chassis: Chassis, slotNum: number): string;
}

interface _implCreateModule {
    (catNo: string): ChassisModule | null;
}

interface _implAddModuleToChassis {
    (chassis: Chassis, module: ChassisModule): boolean;
}

interface _implAddNewModuleAtSlot {
    (chassis: Chassis, slot: number): boolean;
}

interface _implAddModuleAtSlot {
    (chassis: Chassis, catNo: string, slot: number, envMismatchOk: boolean): boolean;
}

interface _implDeleteModuleAtSlot {
    (chassis: Chassis, slot: number): boolean;
}

interface _implOnChassisLoaded {
    (chassis: Chassis): void;
}

export interface _implGetImageSource {
    (category: DeviceCategory, imgName: string): string;
}

interface _implGetChassisElementAtPt {
    (chassis: Chassis, ptLocal: Point):
        [element: ChassisElement, slot: number, rightSide: boolean];
}

interface _implGetChassisDropStatus {
    (chassis: Chassis, dragInfo: DragDeviceInfo,
        touchEl: ChassisElement, slot: number): DropStatus;
}

interface _implDropDragDeviceOnChassis {
    (chassis: Chassis, dragInfo: DragDeviceInfo, touchEl: ChassisElement,
        slot: number): DropResult;
}

interface _implDuplicateChassis {
    (chassis: Chassis, insertCopyAt: number): Chassis | null;
}

export interface ActionRequestFuncType {
    (type: LayoutActionType, chassis: Chassis, slotNum: number): void;
}

//export enum ChassisRendType {
//    Standard = 'Standard',
//    RedPrimary = 'RedPrimary',
//    RedSecondary = 'RedSecondary'
//}

// WCS - NOTE: The .bumper property was
// added as a simple means of being able to make sure a 
// ChassisComp gets re-rendered, when all of the other props
// we send to it are otherwise unchanged.
// ChassisComp is now memo-ised and without at least ONE prop change,
// a memo copy of the previous rendering is used, even if something
// else changed INSIDE of our chassis (like adding or removing a
// module). The bumper prop always just gets whatever's in the
// Chassis object's .bump (formerly .renderBump) property.
export interface ChassisCompProps {
    chassis: Chassis;
    bumper: number;
    ptOrg: Point;
    showAsSelected?: boolean;
    localDeviceSelected?: SelectableDevice;
    renderType?: ChassisRendType;
    layoutMode?: LayoutMode;
}

export interface MicroChassisCompProps {
    chassis: MicroChassis;
    bumper: number;
    ptOrg: Point;
    showAsSelected?: boolean;
    localDeviceSelected?: SelectableDevice;
    renderType?: ChassisRendType;
    layoutMode?: LayoutMode;
}

interface _implGetChassisRenderer {
    (): React.FC<ChassisCompProps> | undefined;
}

interface _implGetChassisDetails {
    (chassis: Chassis): DetailGroup[];
}

interface _implGetDeviceDetails {
    (device: SelectableDevice): DetailGroup[];
}

interface _implGetPowerDetails {
    (chassis: Chassis): [supplied: PowerBreakdown, consumed: PowerBreakdown];
}

interface _implGetSlotLocation {
    (chassis: Chassis, slotNum: number): LocAndSize;
}

interface _implGetSlotFillerInfo {
    (chassis: Chassis): [string, boolean];
}

interface _implGetActBtnInfo {
    (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo;
}

interface _implGetMaxNewModules {
    (chassis: Chassis, restrict: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number;
}

interface _implGetDefaultChassisName {
    (chassis: Chassis): string;
}

interface _implGetSlotTypeRestriction {
    (chassis: Chassis, slotNum: number): ModuleSlotRestriction;
}

interface _implFilterAvailableModules {
    (chassis: Chassis, restrict: ModuleSlotRestriction, unfiltered: EngInfoModule[]): EngInfoModule[];
}

interface _implCanExtendChassis {
    (chassis: Chassis): boolean;
}

interface  _implDeleteBaseUnitAtChassis {
    (chassis: Chassis): boolean;
}

interface _implCanModuleBeAdded {
    (module: ChassisModule, chassis: Chassis): number;
}

interface _implGetModuleSlotRestriction {
    (modInfo: EngInfoComponent): ModuleSlotRestriction;
}

interface _implUpdateChassisLayout {
    (chassis: Chassis): void;
}

interface _implAddModulesToChassis {
    (chassis: Chassis, sels: SelectedCompInfo[],
        totalQty: number, targetSlot: number): void;
}

interface _implGetChassisSizeAsDrawn {
    (chassis: Chassis, copyMode: boolean): Size;
}

interface _implGetXSlotWidthFor {
    (modInfo: EngInfoComponent): number;
}

interface _implGetDefaultXSlotWidth {
    (platform: string): number;
}

interface _implGetEmptySlotImage {
    (chassis: Chassis): string;
}

interface _implDoesSlotQualifyForBtnAction {
    (mode: LayoutMode, type: LayoutActionType, chassis: Chassis, slotNum: number): boolean;
}

interface _implGetNumIOBases {
    (chassis: Chassis): number;
}

interface _implIsPointOnPS {
    (ptLocal: Point, chassis: Chassis): boolean;
}

interface _implIsPointOnBU {
    (ptLocal: Point, chassis: MicroChassis): boolean;
}

interface _implGetXSlotLoc {
    (chassis: Chassis): LocAndSize;
}

// Routing specifications for a platform.
export interface GeneralImplSpec {
    // Required
    platform: string;
    imageScaleFactor: number;

    // Optional
    createChassis?: _implCreateChassis;
    replaceChassisPowerSupply?: _implReplaceChassisPowerSupply,
    configureChassis?: _implConfigureChassis,
    getNumSlots?: _implGetNumSlots;
    getChassisSlotUsage?: _implGetChassisSlotUsage;
    getSlotID?: _implGetSlotID;
    createModule?: _implCreateModule;
    addModuleToChassis?: _implAddModuleToChassis;
    addNewModuleAtSlot?: _implAddNewModuleAtSlot;
    addModuleAtSlot?: _implAddModuleAtSlot;
    deleteModuleAtSlot?: _implDeleteModuleAtSlot;
    onChassisLoaded?: _implOnChassisLoaded;
    getImageSource?: _implGetImageSource;
    getChassisElementAtPt?: _implGetChassisElementAtPt;
    getChassisDropStatus?: _implGetChassisDropStatus,
    dropDragDeviceOnChassis?: _implDropDragDeviceOnChassis;
    duplicateChassis?: _implDuplicateChassis;
    getChassisRenderer?: _implGetChassisRenderer;
    getChassisDetails?: _implGetChassisDetails;
    getDeviceDetails?: _implGetDeviceDetails;
    getPowerDetails?: _implGetPowerDetails;
    getSlotLocation?: _implGetSlotLocation;
    getSlotFillerInfo?: _implGetSlotFillerInfo;
    getActBtnInfo?: _implGetActBtnInfo;
    getMaxNewModules?: _implGetMaxNewModules;
    getDefaultChassisName?: _implGetDefaultChassisName;
    filterAvailableModules?: _implFilterAvailableModules;
    canExtendChassis?: _implCanExtendChassis;
    deleteBaseUnitAtChassis?: _implDeleteBaseUnitAtChassis;
    canModuleBeAdded?: _implCanModuleBeAdded;
    getSlotTypeRestriction?: _implGetSlotTypeRestriction,
    getModuleSlotRestriction?: _implGetModuleSlotRestriction;
    updateChassisLayout?: _implUpdateChassisLayout;
    addModulesToChassis?: _implAddModulesToChassis;
    getChassisSizeAsDrawn?: _implGetChassisSizeAsDrawn;
    getXSlotWidthFor?: _implGetXSlotWidthFor;
    getDefaultXSlotWidth?: _implGetDefaultXSlotWidth;
    getEmptySlotImage?: _implGetEmptySlotImage;
    doesSlotQualifyForBtnAction?: _implDoesSlotQualifyForBtnAction;
    getNumIOBases?: _implGetNumIOBases;
    isPointOnPS?: _implIsPointOnPS;
    isPointOnBU?: _implIsPointOnBU;
    getXSlotLoc?: _implGetXSlotLoc;
}


// Implementation registration.
export const RegisterGeneralImpl = (impl: GeneralImplSpec) => {
    const platformID = impl.platform.trim();
    if (platformID && platformID === impl.platform) {
        if (!_generalImplRegistry.has(platformID)) {
            _generalImplRegistry.set(platformID, impl);
        }
        else {
            logger.warn('RegisterGeneralImpl ignoring duplicate register: ' + impl.platform);
        }
    }
    else {
        throw new Error('Invalid platform ID in RegisterGeneralImpl!');
    }
}


// Implementation retrieval - NOT EXPORTED.
const _getImplementation = (platform: string): GeneralImplSpec | undefined => {
    return _generalImplRegistry.get(platform);
}


// EXPORTED API
// NOTE: In ANY of the following, an empty or invalid
// platform ID will result in default functionality.

export const createChassis = (platform: string, chassisCat: string, psCatNo?: string, buCatNo?: string):
    Chassis | undefined => {

    const chasEngInfo = getChassisEngInfo(platform, chassisCat);
    if (chasEngInfo) {
        const impl = _getImplementation(platform);
        if (impl && impl.createChassis) {
            return impl.createChassis(chasEngInfo, psCatNo, buCatNo);
        }

        // Default - Do nothing
        if (LogImplDefaults && platform) {
            logger.logImpl('No createChassis implemented for: ' + platform);
        }
    }
    else {
        logger.error('createChassis got invalid chassis cat for platform ' +
            platform + ': ' + chassisCat)
    }


    return undefined;
}

export const replaceChassisPowerSupply = (chassis: Chassis, psCatNo: string):
    boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.replaceChassisPowerSupply) {
        return impl.replaceChassisPowerSupply(chassis, psCatNo);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No replaceChassisPowerSupply implemented for: ' + chassis.platform);
    }

    return false;
}

export const configureChassis = (
    platform: string,
    project: ChassisProject,
    selectChassisCallback: (chassis: Chassis | undefined) => void,
    selectDeviceCallback: (device: SelectableDevice | undefined) => void,
    contentChangedCallback: () => void,
    chassis?: Chassis
) => {

    const impl = _getImplementation(platform);
    if (impl && impl.configureChassis) {
        impl.configureChassis(
            project,
            platform,
            selectChassisCallback,
            selectDeviceCallback,
            contentChangedCallback,
            chassis);
    }
    else {
        // Default - Do nothing
        if (LogImplDefaults && platform) {
            logger.logImpl('No configureChassis implemented for: ' + platform);
        }
    }
}

export const getNumSlots = (chassis: Chassis): number => {

    // Allow platorm to override.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getNumSlots) {
        return impl.getNumSlots(chassis);
    }

    // Otherwise, std is just length of modules array.
    return chassis.modules.length;
}

export const getChassisSlotUsage = (chassis?: Chassis):
    [slotsInUse: number, slotFillers: number, lastUsedSlot: number] => {

    if (chassis) {
        const impl = _getImplementation(chassis.platform);
        if (impl && impl.getChassisSlotUsage) {
            return impl.getChassisSlotUsage(chassis);
        }

        // Default - Do nothing
        if (LogImplDefaults && chassis.platform) {
            logger.logImpl('No getChassisSlotUsage implemented for: ' + chassis.platform);
        }
    }

    return [0, 0, -1];
}

export const getSlotID = (chassis: Chassis, slotNum: number): string => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotID) {
        return impl.getSlotID(chassis, slotNum);
    }

    // Default
    const mod = chassis.modules[slotNum];
    if (mod) {
        // If the module is single-slot or has 'No Slot' ID...
        if (mod.slotsUsed <= 1 || mod.slotID === NO_SLOT)
            return mod.slotID.toString();

        const lastSlot = mod.slotID + (mod.slotsUsed - 1);
        return `${mod.slotID}..${lastSlot}`
    }
    else {
        if (isValidSlotNumber(chassis, slotNum)) {
            return slotNum.toString();
        }
        else {
            return SlotIDError.InvalidSlotNum;
        }
    }
}

export const getImageScale = (platform: string): number => {
    const impl = _getImplementation(platform);
    return (impl) ? impl.imageScaleFactor : 1.0;
}

export const getScaledImageSize = (modInfo: EngInfoComponent): Size => {
    const scale = getImageScale(modInfo.platform);
    return {
        width: modInfo.imgSize.width * scale,
        height: modInfo.imgSize.height * scale
    }
}

export const createModuleFor = (modInfo: EngInfoModule): ChassisModule => {
    const devCategory = getDeviceCategory(modInfo.type);
    return {
        id: getNewInstanceId(),
        platform: modInfo.platform,
        deviceType: modInfo.type,
        catNo: modInfo.catNo,
        description: modInfo.description,
        isPlaceholder: modInfo.isPlaceholder,
        extendedTemp: modInfo.envInfo.etOk,
        conformal: modInfo.envInfo.ccOk,
        accysPossible: modInfo.anyAccysPossible(),
        category: devCategory,
        imgSrc: getImageSource(modInfo.platform, devCategory, modInfo.imgName),
        imgSize: getScaledImageSize(modInfo),
        movable: true,
        slotIdx: -1,
        slotID: -1,
        slotsUsed: modInfo.slotsUsed,
        selected: false,
        dragStatus: ModuleDragStatus.NA,
        parent: undefined,
        isController: modInfo.isController,
        isComm: modInfo.isComm,
        isConnClient: modInfo.isConnClient,
        spclLocalConns: 0,
        slotFiller: modInfo.isSlotFiller(),
        isFPD: modInfo.isFPD,
        isInterconnect: modInfo.isInterconnect,
    };
}

export const createModule = (platform: string, catNo: string): ChassisModule | null => {

    const impl = _getImplementation(platform);
    if (impl && impl.createModule) {
        return impl.createModule(catNo);
    }

    // Default
    const modInfo = getModuleEngInfo(platform, catNo);
    if (modInfo) {
        return createModuleFor(modInfo);
    }

    return null;
}

export const addModuleToChassis = (chassis: Chassis, module: ChassisModule): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.addModuleToChassis) {
        return impl.addModuleToChassis(chassis, module);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No addModuleToChassis implemented for: ' + chassis.platform);
    }

    return false;
}

export const addNewModuleAtSlot = (chassis: Chassis, slot: number): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.addNewModuleAtSlot) {
        return impl.addNewModuleAtSlot(chassis, slot);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No addNewModuleAtSlot implemented for: ' + chassis.platform);
    }

    return false;
}

export const addModuleAtSlot = (chassis: Chassis, catNo: string,
    slot: number, envMismatchOk = false): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.addModuleAtSlot) {
        return impl.addModuleAtSlot(chassis, catNo, slot, envMismatchOk);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No addModuleAtSlot implemented for: ' + chassis.platform);
    }

    return false;
}

export const deleteModuleAtSlot = (chassis: Chassis, slotNum: number): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.deleteModuleAtSlot) {
        return impl.deleteModuleAtSlot(chassis, slotNum);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No deleteModuleAtSlot implemented for: ' + chassis.platform);
    }

    return false;
}

// Called to make any modifications required after
// the chassis was loaded (from JSON). Rehooks parent refs, etc.
export const onChassisLoaded = (chassis: Chassis): void => {

    // Standard for all chassis.
    // Set parent refs on modules and ps.
    const numSlots = chassis.modules.length;
    for (let slot = 0; slot < numSlots; slot++) {
        const mod = chassis.modules[slot];
        if (mod) {
            mod.parent = chassis;
        }
        else {
            // When loaded from JSON, empty slots can end up
            // with values of 'null'.If we find any such cases
            // here, we'll set the values to 'undefined' to match
            // the actual definition of our .modules array.
            if (mod === null) {
                chassis.modules[slot] = undefined;
            }
        }
    }

    if (chassis.ps) {
        chassis.ps.parent = chassis;
    }

    chassis.dragTarget = false;
    chassis.xSlotWidth = 0;

    // Allow platform to do anything additional reqd.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.onChassisLoaded) {
        return impl.onChassisLoaded(chassis);
    }
}

export const getImageSource = (platform: string,
    category: DeviceCategory, imgName: string): string => {

    const impl = _getImplementation(platform);
    if (impl && impl.getImageSource) {
        return impl.getImageSource(category, imgName);
    }

    // Default
    return getDefaultImageSource(imgName);
}

// Get the location of an 'X' (extra) slot, used by
// some platforms during drag and drop operations.
export const getXSlotLoc = (chassis: Chassis): LocAndSize => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getXSlotLoc) {
        return impl.getXSlotLoc(chassis);
    }

    // Default...
    // If the chassis is currently tagged as a drag
    // target AND it has a positive X Slot width...
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {

        // Get a copy of the last 'real' slot loc.
        const slotLoc = { ...chassis.layout.slotLocs[chassis.layout.slotLocs.length - 1] };

        // Shift right by its own width.
        slotLoc.x += slotLoc.width;

        // Then set the width to the XSlot
        // width value and return.
        slotLoc.width = chassis.xSlotWidth;
        return slotLoc;
    }

    // Just return an empty loc.
    return getEmptyLoc();
}

export const getChassisElementAtPt = (chassis: Chassis, ptLocal: Point):
    [
        element: ChassisElement,
        slot: number,
        rightSide: boolean  // Applicable only for slots
    ] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getChassisElementAtPt) {
        return impl.getChassisElementAtPt(chassis, ptLocal);
    }

    // Default behavior.
    // If the chassis is currently tagged as a drag
    // target with an 'X' slot specified...
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {

        // Get the loc of that extra slot.
        const xSlotLoc = getXSlotLoc(chassis);

        // If our point is inside, return as a slot
        // using the NEXT slot idx that WOULD be available.
        if (isPointInLoc(ptLocal, xSlotLoc)) {
            return [ChassisElement.Slot, chassis.modules.length, false];
        }
    }

    // If we're still here, get overall size as loc.
    const loc: LocAndSize = {
        x: 0,
        y: 0,
        width: chassis.layout.size.width,
        height: chassis.layout.size.height
    };

    // If the point is inside...
    if (isPointInLoc(ptLocal, loc)) {

        // If the chassis has a ps, and the point
        // is in the loc it provides...
        //if (chassis.ps && isPointInLoc(ptLocal, chassis.ps.loc)) {
        if (isPointOnPS(ptLocal, chassis)) {

            // We're pointing at the PS.
            return [ChassisElement.PS, NO_SLOT, false];
        }
         // We're pointing at the Base Unit.
        else if (isPointOnBU(ptLocal, chassis)) {

            // We're pointing at the PS.
            return [ChassisElement.BU, NO_SLOT, false];
        }
        else {

            // Otherwise, walk the slot locs, and return
            // the one we're pointing at (if any).
            const numSlotLocs = chassis.layout.slotLocs.length;
            for (let slot = 0; slot < numSlotLocs; slot++) {
                const loc = chassis.layout.slotLocs[slot];
                if (isPointInLoc(ptLocal, loc)) {
                    const ctrLoc = getLocCenter(loc);
                    const rightSide = (ptLocal.x > ctrLoc.x);
                    return [ChassisElement.Slot, slot, rightSide];
                }
            }
        }

        // If we're still here, we're pointing at the
        // chassis somewhere, but NOT at a slot.
        return [ChassisElement.Chassis, NO_SLOT, false];
    }
    else {
        // Point is NOT on the chassis at all.
        return [ChassisElement.None, NO_SLOT, false];
    }
}

export const getChassisDropStatus = (chassis: Chassis, dragInfo: DragDeviceInfo,
    touchEl: ChassisElement, slot: number): DropStatus => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getChassisDropStatus) {
        return impl.getChassisDropStatus(chassis, dragInfo, touchEl, slot);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getChassisDropStatus implemented for: ' + chassis.platform);
    }

    return DropStatus.NoDrop;
}

export const dropDragDeviceOnChassis = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slot: number,
): DropResult => {

    dragInfo.dragMod.dragStatus = ModuleDragStatus.NA;

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.dropDragDeviceOnChassis) {
        return impl.dropDragDeviceOnChassis(chassis, dragInfo,
            touchEl, slot);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No dropDragDeviceOnChassis implemented for: ' + chassis.platform);
    }

    return DropResult.DropFailed;
}

const _stdDupChassis = (chassis: Chassis, insertCopyAt: number): Chassis | null => {
    // Get the associated project.
    const project = getProjectFromChassis(chassis);

    // If we can...
    if (project) {

        const psCat = chassis.ps ? chassis.ps.catNo : undefined;

        // Add a new chassis, using the catNos of the
        // old and of its power supply (if it has one).
        const dup = addChassis(project, chassis.platform, chassis.catNo,
            insertCopyAt, psCat);

        // If the add worked...
        if (dup) {

            // orig here is still the same as chassis,
            // but 'orig' makes the following easier to
            // follow.
            const orig = chassis;

            // Our dup chassis should now have whatever
            // REQUIRED accys were appropriate added to
            // the chassis itself and its dup'd power supply.
            // However, we want it to be a real duplicate,
            // so we'll copy accys from orig to dup here for
            // both of those elements.
            copyAccys(orig, dup);
            if (orig.ps && dup.ps) {
                copyAccys(orig.ps, dup.ps);
            }

            // Copy default I/O wiring type.
            dup.defaultIOModWiring = chassis.defaultIOModWiring;

            // Start optimistic regarding
            // module duplication results.
            let addFailed = false;

            // Walk all modules in the orig chassis. For each...
            for (let slot = 0; slot < orig.modules.length; slot++) {

                // Get the original module (if there IS one at the slot).
                const origMod = orig.modules[slot];

                // If so...
                if (origMod) {

                    // Using the original's catNo, add the same module
                    // to the dup chassis at the same slot.
                    // If the add works...
                    if (addModuleAtSlot(dup, origMod.catNo, slot, true)) {

                        // Get the new module from the dup chassis.
                        const newMod = dup.modules[slot];

                        // If we can...
                        if (newMod) {
                            // Then COPY whatever accys found on
                            // the original module to the new one.
                            copyAccys(origMod, newMod);

                            // Bump the slot by the amount of
                            // slots occupied beyond 1.
                            slot += newMod.slotsUsed - 1;
                        }
                        else {
                            addFailed = true;
                        }
                    }
                    else {
                        addFailed = true;
                    }
                }
            }

            // Deal with any problems encountered above
            // during the module dup'ing process.
            if (addFailed) {
                throw new Error('ERROR: _stdDupChassis could not duplicate all modules!');
            }

            return dup;
        }
    }
    return null;
}

export const duplicateChassis = (chassis: Chassis, insertCopyAt: number): Chassis | null => {

    let dup: Chassis | null = null;

    // If there's a platform-specific implementation,
    // use it. Otherwise, dup using the std method.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.duplicateChassis) {
        dup = impl.duplicateChassis(chassis, insertCopyAt);
    }
    else {
        dup = _stdDupChassis(chassis, insertCopyAt);
    }

    // If we got a duplicate...
    if (dup) {
        // If the original chassis had a name,
        // we'll use the SAME name (plus a decorator) for
        // the duplicate. If the original had no name,
        // we'll leave the duplicate without one as well.
        if (chassis.name && chassis.name.length) {
            const project = getProjectFromChassis(chassis);
            if(project)
                dup.name = getUniqueChassisName(getChassisName(chassis), project);
        }
        return dup;
    }

    return null;
}

export const getChassisRenderer = (platform: string):
    React.FC<ChassisCompProps> | undefined => {

    const impl = _getImplementation(platform);
    if (impl && impl.getChassisRenderer) {
        return impl.getChassisRenderer();
    }

    // Default - Do nothing
    if (LogImplDefaults && platform) {
        logger.logImpl('No getChassisRenderer implemented for: ' + platform);
    }

    return undefined;
}

export const getChassisDetails = (chassis: Chassis): DetailGroup[] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getChassisDetails) {
        return impl.getChassisDetails(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getChassisDetails implemented for: ' + chassis.platform);
    }

    return [];
}

export const getDeviceDetails = (device: SelectableDevice): DetailGroup[] => {

    const impl = _getImplementation(device.platform);
    if (impl && impl.getDeviceDetails) {
        return impl.getDeviceDetails(device);
    }

    // Default - Do nothing
    if (LogImplDefaults && device.platform) {
        logger.logImpl('No getDeviceDetails implemented for: ' + device.platform);
    }

    return [];
}

export const getPowerDetails = (chassis: Chassis):
    [supplied: PowerBreakdown, consumed: PowerBreakdown] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getPowerDetails) {
        return impl.getPowerDetails(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getPowerDetails implemented for: ' + chassis.platform);
    }
    return [getEmptyPowerBreakdown(), getEmptyPowerBreakdown()];
}

export const getSlotLocation = (chassis: Chassis, slotNum: number): LocAndSize => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotLocation) {
        return impl.getSlotLocation(chassis, slotNum);
    }

    // Default implementation.
    // If there is an associated entry in the chassis
    // layout's slotLocs array, return it.
    if (slotNum < chassis.layout.slotLocs.length) {
        return chassis.layout.slotLocs[slotNum];
    }

    // If we're still here, just return an empty loc.
    return getEmptyLoc();
}

export const getSlotFillerInfo = (chassis: Chassis): [string, boolean] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotFillerInfo) {
        return impl.getSlotFillerInfo(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getSlotFillerInfo implemented for: ' + chassis.platform);
    }

    return ['', false];
}

export const hasGetActBtnInfo = (platform: string): boolean => {
    const impl = _getImplementation(platform);
    if (impl && impl.getActBtnInfo) {
        return true;
    }
    return false;
}

export const getActBtnInfo = (action: LayoutActionType, rack: Rack,
    slotNum: number): ActBtnInfo => {

    const impl = _getImplementation(rack.chassis.platform);
    if (impl && impl.getActBtnInfo) {
        return impl.getActBtnInfo(action, rack, slotNum);
    }

    // Default - Do nothing
    if (LogImplDefaults && rack.chassis.platform) {
        logger.logImpl('No getActionBtnInfo implemented for: ' + rack.chassis.platform);
    }

    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: getEmptyPt()
    };
}

export const getMaxNewModules = (chassis: Chassis, restrict: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getMaxNewModules) {
        return impl.getMaxNewModules(chassis, restrict, treatSlotFillerAsEmptySlot);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getMaxNewModules implemented for: ' + chassis.platform);
    }

    return 0;
}

export const getDefaultChassisName = (chassis: Chassis): string => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getDefaultChassisName) {
        return impl.getDefaultChassisName(chassis);
    }

    return 'Chassis';
}

export const getModuleSelectionInfo = (chassis: Chassis, restrict: ModuleSlotRestriction):
    EngInfoModule[] => {

    const allMods = getAvailableModules(chassis);
    if (allMods.length > 0) {
        const impl = _getImplementation(chassis.platform);
        if (impl && impl.filterAvailableModules) {
            return impl.filterAvailableModules(chassis, restrict, allMods);
        }
    }
    return allMods;
}

export const canExtendChassis = (chassis: Chassis): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.canExtendChassis) {
        return impl.canExtendChassis(chassis);
    }
    return false;
}

export const deleteBaseUnitAtChassis = (chassis: Chassis): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.deleteBaseUnitAtChassis) {
        return impl.deleteBaseUnitAtChassis(chassis);
    }
    return false;
}

// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
export const canModuleBeAdded = (module: ChassisModule, chassis: Chassis): number => {

    // FAIL on platform mismatch.
    if (module.platform !== chassis.platform) {
        return NO_SLOT;
    }

    // If we have a platform-specific implementation,
    // pass the request to that and return the result.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.canModuleBeAdded) {
        return impl.canModuleBeAdded(module, chassis);
    }

    // Default behavior.
    // Checks for enough adjacent slots if
    // module requires more than 1.
    const slotsNeeded = module.slotsUsed;

    let adjacentEmpties = 0;
    let firstEmptySlot = NO_SLOT;

    for (let slotIdx = 0; slotIdx < chassis.modules.length; slotIdx++) {

        // Get whatever's in the slot.
        const mod = chassis.modules[slotIdx];

        // For our purposes here, we'll ignore slot fillers
        // and treat those slots as though they were empty.
        if (mod && !mod.slotFiller) {
            adjacentEmpties = 0;
            firstEmptySlot = NO_SLOT;
        }
        else {
            if (firstEmptySlot === NO_SLOT) {
                firstEmptySlot = slotIdx;
            }
            adjacentEmpties += 1;
            if (adjacentEmpties >= slotsNeeded) {
                return firstEmptySlot;
            }
        }
    }
    return NO_SLOT;
}

export const getSlotTypeRestriction = (chassis: Chassis, slotNum: number): ModuleSlotRestriction => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotTypeRestriction) {
        return impl.getSlotTypeRestriction(chassis, slotNum);
    }
    return ModuleSlotRestriction.None;
}

export const getModuleSlotRestriction = (modInfo: EngInfoComponent): ModuleSlotRestriction => {
    const impl = _getImplementation(modInfo.platform);
    if (impl && impl.getModuleSlotRestriction) {
        return impl.getModuleSlotRestriction(modInfo);
    }
    return ModuleSlotRestriction.None;
}

export const updateChassisLayout = (chassis: Chassis, updateParentLayout = true) => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so, call it.
    if (impl && impl.updateChassisLayout) {
        impl.updateChassisLayout(chassis);
    }

    // REGARDLESS of whether anything was done above,
    // we'll do the parent content layout if asked.
    if (updateParentLayout && chassis.parent) {
        updateRackLayout(chassis.parent);
    }
}

export const addModulesToChassis = (chassis: Chassis,
    sels: SelectedCompInfo[],
    totalQty: number,
    targetSlot: number) => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.addModulesToChassis) {
        // Take an undo snapshot.
        chassisChanging(chassis);

        // Suspend them so we don't get one for
        // every new module added.
        const snapsWereSuspended = suspendUndoSnapshots(true);

        impl.addModulesToChassis(chassis, sels, totalQty, targetSlot);

        // DE-suspend snapshots.
        suspendUndoSnapshots(snapsWereSuspended);

        // Give the chassis a chance to do anything
        // that may have been delayed due to undo
        // snapshots being suspended. This will also
        // update our overall content by calling
        // updateRackLayout for us.
        updateChassisLayout(chassis);
    }

    // Default does nothing.
}

export const getChassisSizeAsDrawn = (chassis: Chassis, copyMode: boolean): Size => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.getChassisSizeAsDrawn) {

        return impl.getChassisSizeAsDrawn(chassis, copyMode);
    }

    // Default
    return { ...chassis.layout.size };
}

export const getXSlotWidthFor = (modInfo: EngInfoComponent): number => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(modInfo.platform);

    // If so...
    if (impl && impl.getXSlotWidthFor) {

        return impl.getXSlotWidthFor(modInfo);
    }

    // Default
    return 0;
}

export const getDefaultXSlotWidth = (platform: string): number => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(platform);

    // If so...
    if (impl && impl.getDefaultXSlotWidth) {

        return impl.getDefaultXSlotWidth(platform);
    }

    // Default
    return 0;
}

export const getEmptySlotImage = (chassis: Chassis): string => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.getEmptySlotImage) {

        return impl.getEmptySlotImage(chassis);
    }

    // Default
    return '';
}

export const doesSlotQualifyForBtnAction = (mode: LayoutMode, type: LayoutActionType, chassis: Chassis, slotNum: number): boolean => {
    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.doesSlotQualifyForBtnAction) {
        return impl.doesSlotQualifyForBtnAction(mode, type, chassis, slotNum);
    }

    // Default - Slot Qualifies!
    return true;
}

export const getNumIOBases = (chassis: Chassis): number => {
    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.getNumIOBases) {
        return impl.getNumIOBases(chassis);
    }

    // Default
    return 0;
}

export const isPointOnPS = (ptLocal: Point, chassis: Chassis): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.isPointOnPS) {
        return impl.isPointOnPS(ptLocal, chassis);
    }

    // Default
    if (chassis.ps && isPointInLoc(ptLocal, chassis.ps.loc)) {
        return true;
    }
    return false;
}

export const isPointOnBU = (ptLocal: Point, chassis: MicroChassis): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.isPointOnBU) {
        return impl.isPointOnBU(ptLocal, chassis);
    }

    // Default
    if (chassis.bu && isPointInLocBu(ptLocal, chassis.bu.loc, chassis.bu, chassis.ps)) {
        return true;
    }
    return false;
}

export const getChassisAltSAPwrSupplier = (chassis: Chassis, selectedDevice?: SelectableDevice): AltChassisSAPwrSupplyInfo | undefined => {
    if (chassis && chassis.isPlaceholder) {

        // TODO_FLEXHA - Power handling unknown at this time.
        if (chassis.platform === PlatformFlexHA)
            return undefined;
        // TODO_MICRO 800 - Power handling tabs
        if (chassis.platform === PlatformMicro)
            return undefined;
        // Get the selected device - could be a chassis or module.
        const infoSelDev = (selectedDevice ? getEngInfoForComp(selectedDevice.platform, selectedDevice.catNo) : undefined);
        if (infoSelDev && selectedDevice) {
            // If we are NOT a chassis...
            if (infoSelDev.isChassis === false) {
               // Is the selectable device an SA Power Supplier.
                // Note: Ctrl/Comm/FPD are all SA power suppliers
                // for the 'snap' platform types.
                if (infoSelDev.saPwrSupplier) {
                    const returnVal: AltChassisSAPwrSupplyInfo = { chassis: chassis, saPowerSupplier: selectedDevice as ChassisModule };
                    return returnVal;
                }
            }
        }

        // We're here because we did not satisfy the above.
        // The chassis catalog is a placeholder. So far only
        // the 'Snap' type platforms will have this situation. 
        // Verify that this is (or is not) the case. If NOT....
        if (isPlatformSnapType(chassis.platform) === false) 
            throw new Error('getChassisAltModAsPwrSupInfo(): Chassis is not an SA Power Snap platform!');

        const returnVal: AltChassisSAPwrSupplyInfo = { chassis: chassis, saPowerSupplier: undefined };

        // Set the power supplier to the first module.
        if (chassis.modules.length > 0)
            returnVal.saPowerSupplier = chassis.modules[0];

        return returnVal;
    }

    return undefined;
}

