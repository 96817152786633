import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'

import MinimizeIcon from '@mui/icons-material/Remove';
import RestoreIcon from '@mui/icons-material/OpenInNew';

import TitleBarButton from './TitleBarButton';

interface Props {
    title: string;
    minimized: boolean;
    onMinRest: () => void;
}

const DetailsTitle = (props: Props) => {

    const MinResIcon = props.minimized ? RestoreIcon : MinimizeIcon;

    return (
        <div
            className='floating-details-title'
            onClick={props.onMinRest}
        >
            <span className='a-type__subTitle2'>
                {props.title}
            </span>
            <div className='floating-details-title-buttons' >
                <TitleBarButton
                    icon={MinResIcon}
                    onClick={props.onMinRest}
                />
            </div>
        </div>
    );
}

export default DetailsTitle;

