import { RegisterFlexHAEngDataImpl } from "./FlexHAEngDataImpl";
import { RegisterFlexHAHWGenImpl } from "./FlexHAHardwareImpl";
import { RegisterFlexHAGeneralImpl } from "./FlexHAGeneralImpl";

export const initFlexHAPlatform = (): boolean => {
	// TODO_FLEXHA
	RegisterFlexHAHWGenImpl();
	RegisterFlexHAGeneralImpl();
	//RegisterFlexHACheckerImpl();
	RegisterFlexHAEngDataImpl();

	return true;
}