import React from 'react';
import { TextField, Box } from '@mui/material';	
import {  getRaiseDeviceData } from '../raiseDatabase/DeviceCache';
import { BOMHeader } from './BomHeader';
import './Summary.scss';
import { isBOMProduct, BOMItemProduct, createBOMItemProductDetailsFromRaiseData } from './SummaryHelp';


interface Props {
    item: BOMItemProduct;
    bomType?: string;
}

export const BOMProduct = (props: Props) => {

	// If we have the details pending, create a
	// new BOM details for the BOMItemProduct.
	if (props.item.detailsPending) {
		// Request the raise data with a dummy callback.
		const data = getRaiseDeviceData(props.item.catalogOrTitle, () => { return; });
		createBOMItemProductDetailsFromRaiseData(props.item, data);
	}

	const imgSource = props.item.imgSrc;

	let nextKey = 0;

	const QuantPriceItemEntry = (title:string, value:number|string)=>{
        return (
          <div className='bom-product-right-section-entry'>
            <p className='bom-product-right-section-entry__title'>
              {`${title}:`}
            </p>
            <span className='bom-product-right-section-entry__value'>
              {value}
            </span>
          </div>
        );
    }
//Extracting lead time 
const getLeadtimeVlaue =  props?.item?.details[0]?.value
const getLeadtimeMatch = getLeadtimeVlaue?.match(/\d/g)?.[0];

const leadTime  = getLeadtimeMatch  ? getLeadtimeVlaue?.substring(getLeadtimeVlaue?.indexOf(getLeadtimeMatch)) : getLeadtimeVlaue;
//checking for in stock catalog
const isStocked  = (getLeadtimeVlaue === 'Stocked, Ships in 1-5 days' ||  getLeadtimeVlaue === 'Limited Stock, Ships in 1-5 days');


	return (
    <>
      <div className="bom-product">
        <div className="bom-product-left-section">
          {/* Bom items Image */}
          <div className="bom-product-image-column">
            {imgSource.length ? (
              <img className="bom-product-image" src={imgSource} alt="img" />
            ) : null}
          </div>
          <div className="bom-header-container">
            {/* title & description line */}
            <div className="bom-header-title-row">
              <p className="bom-header-title-row-left"></p>
            </div>
            <p className="bom-item-title">
              {props.item.productType + " (" + props.item.catalogOrTitle + ")"}
            </p>
            <p className="bom-item-description">{props.item.description}</p>
          </div>
        </div>
        <div className="bom-product-mid-section">
          {isStocked ? (
            <p className="bom-header-title-row-right">{"In Stock"}</p>
          ) : null}
        </div>
        <div className="bom-product-right-section">
          <Box display="flex" justifyContent="flex-end">
            <div className="bom-product-right-sub-section">
              {QuantPriceItemEntry("Lead Time", leadTime)}
              {QuantPriceItemEntry("List Price", props.item.totalCost)}
            </div>
            <div className="bom-product-right-quantity">
              <TextField
                id="outlined-required"
                label="Qty"
                type="number"
                size="small"
                style={{ width: 58 }}
                InputProps={{
                  inputProps: {
                    sx: { textAlign: "center", pt: 1, pb: 1 },
                  },
                }}
                value={props.item.quantity}
                sx={{
                  mb: 1,
                  fieldset: {
                    borderWidth: "1px !important",
                    borderColor: "#E5E6E9 !important",
                  },
                }}
              />
            </div>
          </Box>
        </div>
      </div>
      {props.bomType === 'Organized' && props.item.children.length > 0
        ? props.item.children.map((child) => {
            if (isBOMProduct(child)) {
              return (
                <BOMProduct item={child as BOMItemProduct} key={nextKey++}  />
              );
            } else {
              return <BOMHeader header={child} key={nextKey++} />;
            }
          })
        : null}
    </>
  );
	
	}
