import React from 'react';
import './CommonToolbar.scss';
import { ReactComponent as DeleteIconSVG } from '../svg/delete.svg';
import { ReactComponent as AddIconSVG } from '../svg/add.svg';
import { ReactComponent as ArrowUpDownSVG } from '../svg/arrow-up-down.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Divider, Tooltip } from '@mui/material';
import {
    Chassis,
    ChassisModule,
    ChassisProject,
    DeviceType,
    NO_SLOT,
    SelectableDevice
} from '../types/ProjectTypes';
import { LayoutModeType, LayoutMode } from '../util/LayoutModeHelp';
import {
    projectHasAnyEmptySlots,
    projectHasAnyModules,
    projectHasAnySlotFillers
} from '../model/ChassisProject';
import { canRedo, canUndo } from '../util/UndoRedo';
import { ToolbarAction } from './ToolbarActions';
import StatusToolbarButton from './StatusToolbarButton';
import HelpIconButton from './HelpIconButton';
import { isClxRedundancyMod } from '../platforms/clx/model/CLXChassis';
import { StandardMenuItemDetail } from '../controls/StandardMenuItem';
import StandardButtonWithMenu from '../controls/StandardButtonWithMenu';
import ToolbarButtonWithMenu from '../controls/ToolbarButtonWithMenu';
import {
    useLayoutMode,
    useSelectedChassis,
    useSelectedDevice
} from '../context/SelectionInfoContext';
import { LogRender } from '../types/Globals';
import { logger } from '../util/Logger';
import { ChevronDownIcon, CloseIcon, CopyIcon, DeleteIcon, MaximizeIcon, MoreIcon, SettingsIcon, UndoIcon, ZoomInIcon, ZoomOutIcon } from '@rockwell-automation-inc/ra-meatball';
import { TooltipButtonProps } from '../types/TooltipButtonProps';
import { Expand as ExpandIcon } from '@mui/icons-material';
import { canModuleBeAdded } from '../implementation/ImplGeneral';
import { PlatformCLX, PlatformMicro } from '../platforms/PlatformConstants';
import { useAppSelector } from '../redux/hooks';
import { saveInProgress } from '../redux/slices/SaveRestoreSlice';


const advModeBtnProps: TooltipButtonProps = {
    id: 'AdvancedModes',
    variant: 'icon',
    children: (
        <MoreIcon
            fontSize='large'
        />
    ),
    tip: 'advanced modes for copy and delete',
    color: 'mediumGrey',
};

const chasActBtnProps: TooltipButtonProps = {
    id: 'ChassisActions',
    variant: 'outlined',
    children: 'Chassis Actions',
    endIcon: <ChevronDownIcon />,
    disabled: false,
    tip: 'chassis related actions',
};

const addSlotFillerMenuItems = (project: ChassisProject, items: StandardMenuItemDetail[]) => {
    items.push({
        id: ToolbarAction.ADDSLOTFILLERS,
        label: 'Add Slot Fillers to empty slots',
        iconSvg: AddIconSVG,
        disabled: !projectHasAnyEmptySlots(project)
    });

    items.push({
        id: ToolbarAction.REMOVESLOTFILLERS,
        label: 'Remove all Slot Fillers',
        iconSvg: DeleteIconSVG,
        disabled: !projectHasAnySlotFillers(project)
    });
}

const getLayoutModesMenuItems = (canCopy: boolean, project: ChassisProject): StandardMenuItemDetail[] => {
    const items = new Array<StandardMenuItemDetail>();

    items.push({
        id: ToolbarAction.COPYMODE,
        label: 'Quick Copy Mode',
        iconMui: ContentCopyIcon,
        disabled: canCopy ? undefined : true
    });

    items.push({
        id: ToolbarAction.DELMODE,
        label: 'Quick Delete Mode',
        iconSvg: DeleteIconSVG,
        disabled: !projectHasAnyModules(project)
    });

    return items;
}

const getChasActMenuItems = (project: ChassisProject): StandardMenuItemDetail[] => {
    const items = new Array<StandardMenuItemDetail>();

    items.push({
        id: ToolbarAction.ADDCHASSIS,
        label: 'Add chassis',
        iconSvg: AddIconSVG
    });

    items.push({
        id: ToolbarAction.ORGANIZECHASSIS,
        label: 'Organize chassis',
        iconSvg: ArrowUpDownSVG
    });

    /* items.push({
        id: ToolbarAction.EDITALLCHASSIS,
        label: 'Edit all chassis',
        iconSvg: EditIconSVG
    }); */

    addSlotFillerMenuItems(project, items);

    return items;
}

const getDeleteInfo = (
    selChassis: Chassis | undefined,
    selDevice: SelectableDevice | undefined
): [vis: boolean, tip: string] => {

    // TODO_FLEXHA - Disable the delete for the FlexHA Adapter(?)
    if (selChassis) {
        if (selDevice && (selDevice.deviceType !== DeviceType.PS)) {
            return [true, 'delete selected module'];
        }
        else {
            return [true, 'delete selected chassis'];
        }
    }
    else {
        return [false, ''];
    }

}

// Helper used to determine if/how a given device COULD be
// duplicated. Returns two booleans:
//    localDupOk - device could be copied into the same chassis
//    projDupOk - device could be copied to SOMEWHERE in the project.
const canDupDevice = (
    project: ChassisProject,
    chassis: Chassis,
    device: SelectableDevice): [localDupOk: boolean, projDupOk: boolean] => {

    // Power supply cannot be duplicated.
    // Return immediately.
    if (device.deviceType === DeviceType.PS) {
        return [false, false];
    }

    // Get the device as a module. If it's not a PS,
    // then it must be a module.
    const module = device as ChassisModule;

    // Return negatives if the module is an RM.
    // Can't duplicate those.
    if (isClxRedundancyMod(module)) {
        return [false, false];
    }

    // See if the module could fit in the chassis provided.
    // If so, we can return affirmatives for BOTH cases.
    // If a copy would fit into that chassis, then it can
    // also be copied SOMEWHERE in the project (that chassis).
    if (canModuleBeAdded(module, chassis) > NO_SLOT) {
        return [true, true];
    }

    // If we're still here, see how many racks we have.
    const numRacks = project.content.racks.length;

    // For each...
    for (let idx = 0; idx < numRacks; idx++) {

        // Get the rack.
        const rack = project.content.racks[idx];

        // If this one isn't the one we already looked
        // at, see if we could fit a copy here. If so,
        // we can't make a local duplicate, but we COULD
        // make a duplicate to SOMEWHERE.
        if ((rack.chassis !== chassis) &&
            (canModuleBeAdded(module, rack.chassis) > NO_SLOT)) {
            return [false, true];
        }
    }

    // If we get here, no duplicate of the device is possible.
    return [false, false];
}


const getDupInfo = (
    project: ChassisProject,
    selChassis: Chassis | undefined,
    selDevice: SelectableDevice | undefined
): [dupableMod: boolean, locDupOK: boolean, projDupOk: boolean, tip: string] => {

    // If we have a chassis.
    if (selChassis) {

        // Then see if we ALSO have a device. If so...
        if (selDevice) {

            // Call a helper to tell us if/where a copy would be possible.
            const [locDupOk, projDupOk] = canDupDevice(project, selChassis, selDevice);

            // Return that detail.
            const dupableMod = locDupOk || projDupOk;
            return [dupableMod, locDupOk, projDupOk, 'duplicate selected module'];
        }
        else {
            // Chassis, but no device. 
            // Chassis is always dup-able at the project level.
            return [false, false, true, 'duplicate selected chassis'];
        }
    }
    else {
        // Nothing selected. No dup possible.
        return [false, false, false, ''];
    }

}

const getModeInfo = (mode: LayoutMode): [normal: boolean, xTip: string] => {
    switch (mode.type) {
        case LayoutModeType.Copy:
            return [false, 'end Copy mode'];

        case LayoutModeType.Delete:
            return [false, 'end Delete mode'];

        default:
            return [true, ''];
    }
}


interface Props {
    project: ChassisProject;
    onTBAction: (action: string) => void;
}

const LayoutToolbar = (props: Props) => {

    const { layoutMode } = useLayoutMode();
    const { selectedChassis } = useSelectedChassis();
    const { selectedDevice } = useSelectedDevice();
    const saving = useAppSelector(saveInProgress);

    const noContent = (props.project.content.racks.length === 0);

    const [normalMode, xTip] = getModeInfo(layoutMode);
    chasActBtnProps.disabled = !normalMode;

    const [allowDelete, delTip] = normalMode
        ? getDeleteInfo(selectedChassis, selectedDevice)
        : [false, ''];

    const [selIsDupableMod, locDupOk, projDupOk, dupTip] = normalMode
        ? getDupInfo(props.project, selectedChassis, selectedDevice)
        : [false, false, false, ''];

    const enableTBCopy = selIsDupableMod ? locDupOk : projDupOk;
    const enableQuickCopy = (selIsDupableMod && projDupOk);

    const onButtonClicked = (action: string) => {
        props.onTBAction(action);
    }

    const advModeItems = getLayoutModesMenuItems(enableQuickCopy, props.project);
    const chasActItems = getChasActMenuItems(props.project);

    const statusBtnMsgLevel = props.project.content.statusLog.logStatus;

    // Disable the 'settings btn', which currently only
    // affects Redundant Capable Platforms (CLX), when
    // we do not have a red-capable chassis OR we are not
    // in a 'normal' mode (i.e. copy/delete/etc. mode).
    const redPlatformPresent = props.project.content.racks.some(x => x.chassis.platform === PlatformCLX, PlatformMicro);
    const disableSettingsBtn = (!normalMode || !redPlatformPresent);

    if (LogRender.Layout) {
        logger.logRender('Render layout toolbar ')
    }

    return (
        <div className='layout-tb-wrapper-div'>
            <div className='common-toolbar'>
                <Tooltip title='settings'>
                    <span>
                        <Button
                            id={ToolbarAction.SETTINGS}
                            variant='icon'
                            disabled={disableSettingsBtn}
                            onClick={() => onButtonClicked(ToolbarAction.SETTINGS)}
                            color='mediumGrey'
                        >
                            <SettingsIcon
                                fontSize='large'
                            />
                        </Button>
                    </span>
                </Tooltip>
                <div className='common-toolbar-button-group'>
                    <Tooltip title='undo'>
                        <span>
                            <Button
                                id={ToolbarAction.UNDO}
                                variant='icon'
                                disabled={!canUndo()}
                                onClick={() => onButtonClicked(ToolbarAction.UNDO)}
                                color='mediumGrey'
                            >
                                <UndoIcon
                                    fontSize='large'
                                />
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title='redo'>
                        <span>
                            <Button
                                id={ToolbarAction.REDO}
                                variant='icon'
                                disabled={!canRedo()}
                                onClick={() => onButtonClicked(ToolbarAction.REDO)}
                                color='mediumGrey'
                            >
                                <UndoIcon
                                    fontSize='large'
                                    sx={{
                                        transform: 'scaleX(-1)',
                                    }}
                                />
                            </Button>
                        </span>
                    </Tooltip>
                </div>
                <div className='common-toolbar-button-group'>
                    <Tooltip title='zoom in'>
                        <span>
                            <Button
                                id={ToolbarAction.ZOOMIN}
                                variant='icon'
                                disabled={noContent}
                                onClick={() => onButtonClicked(ToolbarAction.ZOOMIN)}
                                color='mediumGrey'
                            >
                                <ZoomInIcon
                                    fontSize='large'
                                />
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title='zoom out'>
                        <span>
                            <Button
                                id={ToolbarAction.ZOOMOUT}
                                variant='icon'
                                disabled={noContent}
                                onClick={() => onButtonClicked(ToolbarAction.ZOOMOUT)}
                                color='mediumGrey'
                            >
                                <ZoomOutIcon
                                    fontSize='large'
                                />
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title='zoom to fit width'>
                        <span>
                            <Button
                                id={ToolbarAction.RESIZE_W}
                                variant='icon'
                                disabled={noContent}
                                onClick={() => onButtonClicked(ToolbarAction.RESIZE_W)}
                                color='mediumGrey'
                            >
                                <ExpandIcon
                                    fontSize='large'
                                    sx={{
                                        transform: 'rotate(90deg)',
                                    }}
                                />
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title='zoom to fit all'>
                        <span>
                            <Button
                                id={ToolbarAction.RESIZE_A}
                                variant='icon'
                                disabled={noContent}
                                onClick={() => onButtonClicked(ToolbarAction.RESIZE_A)}
                                color='mediumGrey'
                            >
                                <MaximizeIcon
                                    fontSize='large'
                                />
                            </Button>
                        </span>
                    </Tooltip>
                </div>
                <div className='common-toolbar-button-group'>
                    <Tooltip title={delTip}>
                        <span>
                            <Button
                                id={ToolbarAction.DELETE}
                                variant='icon'
                                disabled={!allowDelete}
                                onClick={() => onButtonClicked(ToolbarAction.DELETE)}
                                color='mediumGrey'
                            >
                                <DeleteIcon
                                    fontSize='large'
                                />
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip title={dupTip}>
                        <span>
                            <Button
                                id={ToolbarAction.COPY}
                                variant='icon'
                                disabled={!enableTBCopy}
                                onClick={() => onButtonClicked(ToolbarAction.COPY)}
                                color='mediumGrey'
                            >
                                <CopyIcon
                                    fontSize='large'
                                />
                            </Button>
                        </span>
                    </Tooltip>
                    {normalMode
                        ? (
                            <ToolbarButtonWithMenu
                                buttonProps={advModeBtnProps}
                                menuItems={advModeItems}
                                onTBAction={props.onTBAction}
                            />
                        )
                        : (
                            <Tooltip title={xTip}>
                                <Button
                                    id={ToolbarAction.NORMALMODE}
                                    variant='icon'
                                    onClick={() => onButtonClicked(ToolbarAction.NORMALMODE)}
                                    color='mediumGrey'
                                    sx={{ p: '10px' }}
                                >
                                    <CloseIcon
                                        fontSize='small'
                                    />
                                </Button>
                            </Tooltip>
                        )
                    }
                </div>
                <HelpIconButton />
                <div className='common-toolbar-button-group common-toolbar-right-group' >
                    <StandardButtonWithMenu
                        buttonProps={chasActBtnProps}
                        menuItems={chasActItems}
                        onTBAction={props.onTBAction}
                    />
                    <StatusToolbarButton
                        msgLevel={statusBtnMsgLevel}
                        onTBAction={props.onTBAction}
                        disabled={!normalMode}
                    />
                    <Divider
                        flexItem
                        orientation='vertical'
                        sx={{ height: 36 }}
                    />
                    <Tooltip title='Go to Summary page'>
                        <Button
                            id={ToolbarAction.SUMMARY}
                            disabled={saving}
                            variant='contained'
                            onClick={() => onButtonClicked(ToolbarAction.SUMMARY)}
                        >
                            Summary
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

export default LayoutToolbar;
