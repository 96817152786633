import { addModuleAtSlot } from "../implementation/ImplGeneral";
import {
	addChassis,
	chassisChanged,
	detachModule,
	getChassisAndRackIndexById,
	updateAllChassisLayouts
} from "../model/ChassisProject";
import {
	getAddedCtlrMemViaUpsize,
	getPerformanceUsage,
	PrfAspect,
	ProjectCommsInfo,
	runCommCalcs
} from "../model/CommDetails";
import { CLXMigrateChassisSpecs, clxMigrateModulesToNewChassis } from "../platforms/clx/model/CLXAutoFixHelpers";
import { chkrCLXChassisComponents, getNewCLXChassisComponents } from "../platforms/clx/model/CLXChecker";
import { Chassis, ChassisModule, ChassisProject } from "../types/ProjectTypes";
import { contentChanging, suspendUndoSnapshots } from "./UndoRedo";
import { clxGetL8SCatNo, clxSplitIOModules } from "../platforms/clx/model/CLXAutoFixSysPerf";
import { swapCtlrForSafetyCtrl } from "../implementation/ImplChecker";
import { getCompatibleControllers, getConnClientRoleFor, getControllerRoleFor } from "./EngInfoHelp";
import { EngInfoController } from "../engData/EngineeringInfo";
import { PlatformCLX } from "../platforms/PlatformConstants";

const _autoFixNoSafetyCtlr = (project: ChassisProject): boolean => {

	// Run our own calcs, but opt out of issue logging. We don't want
	// to RE-ADD the same issue that got us here in the first place.
	const [commsInfo, ctlrChassis] = runCommCalcs(project, false);

	// If, after our calc run, we now have safety
	// control available somewhere, just return 
	// false. Fix should be complete.
	if (commsInfo.allCtlrChassis.advSafetyOk) {
		return false;
	}

	let safetyIO = false;
	let AdvCtrlReq = false;
	let firstChassis: Chassis | undefined = undefined;
	const lenRacks = project.content.racks.length;
	for (let idx = 0; idx < lenRacks; ++idx) {
		const rack = project.content.racks[idx];
		if (!rack)
			continue;

		if (!firstChassis)
			firstChassis = rack.chassis;

		if (!safetyIO || !AdvCtrlReq) {
			const len = rack.chassis.modules.length;
			for (let idxMod = 0; idxMod < len; ++idxMod) {
				const mod = rack.chassis.modules[idxMod];
				if (mod && mod.isConnClient) {
					const role = getConnClientRoleFor(rack.chassis.platform, mod.catNo);
					if (role.safetyIO)
						safetyIO = true;
					if (role.advancedCtrlReqd)
						AdvCtrlReq = true;

					if (safetyIO && AdvCtrlReq)
						break;
				}
			}
		}

		if (safetyIO && AdvCtrlReq)
			break;
	}

	if (!safetyIO) {
		return false;
	}

	if (ctlrChassis.length === 0) {
		if (firstChassis) {
			const platform = firstChassis.platform;
			const compatibleCtrls = getCompatibleControllers(firstChassis) as EngInfoController[];
			const compatibleSafCtrls = compatibleCtrls.filter(ctrl => ctrl.safetyController === true);
			if (compatibleSafCtrls) {
				// Create a new chassis
				const newChassis = addChassis(project, platform, firstChassis.catNo, 0, firstChassis.ps?.catNo);
				if (newChassis) {
					let ctrlAdded = false;
					if (AdvCtrlReq) {
						const ctrl = compatibleSafCtrls.find((ct) => {
							const role = getControllerRoleFor(platform, ct.catNo);
							if (role && role.isAdvanced)
								return true;
							return false;
						});

						if (ctrl) {
							ctrlAdded = addModuleAtSlot(newChassis, ctrl.catNo, 0, true);
						}
					}

					if (!ctrlAdded) {
						addModuleAtSlot(newChassis, compatibleSafCtrls[0].catNo, 0, true);
					}

					newChassis.name = getUniqueChassisName('Controller', project);
					updateAllChassisLayouts(project.content);

					return true;
				}
			}
		}

		return false;
	}

	// The first thing we'll check for is to make
	// sure that any controller chassis that has any
	// LOCAL safety I/O module(s) also has an appropriate
	// safety controller:
	//   - Local I/O modules are required to be controlled
	//     by a controller in the same chassis.
	//   - An ADVANCED Safety controller (L8) is required
	//     to control local safety I/O, regardless of whether
	//     any such module otherwise requires an Advanced
	//     controller. In CLX, there are only 2 Safety I/O
	//     modules, and BOTH also require Advanced anyway.
	// Here we'll look at the 'accumulated' support we got
	// back. If ANY controller chassis have any local Safety
	// I/O, then the accum's flag will ALSO be set. If so...
	if (commsInfo.allCtlrChassis.anyLocalSafetyIO) {

		// THEN, we'll walk each controller chassis itself
		// to see if any don't meet the requirements. For each...
		for (let chIdx = 0; chIdx < ctlrChassis.length; chIdx++) {

			// Get the chassis.
			const ctlrChas = ctlrChassis[chIdx];

			// As part of the runCommCalcs process, each
			// controller chassis SHOULD now have its
			// .ctlrCommsSpt prop set, containing support-
			// related info for just the given chassis, NOT
			// the accum version. If it has the prop...
			if (ctlrChas.ctlrCommsSpt) {

				// If the chassis has any LOCAL safety IO...
				if (ctlrChas.ctlrCommsSpt.anyLocalSafetyIO) {

					// If it's also redundant, split the IO
					// off to a different chassis first. IO can't
					// reside on a redundant chassis.
					if (ctlrChas.redundant) {
						if (ctlrChas.platform === PlatformCLX)
							return clxSplitIOModules(project, ctlrChas, '');
						else
							throw new Error('_autoFixNoSafetyCtlr(): Non-CLX chassis marked redundant!')
					}
					else {
						// Not redundant. Pass to a helper that will
						// swap an existing controller in the chassis
						// for a suitable L8S.
						return swapCtlrForSafetyCtrl(ctlrChas, AdvCtrlReq);
					}
				}
			}
			else {
				// Unexpected Error.
				throw new Error('_autoFixNoSafetyCtlr(): Chassis without ctlrCommsSpt');
			}
		}
	}

	// If we're still here, none of our controller chassis
	// had any safety I/O. We know that we'll need a safety
	// controller, and those can't go into a redundant chassis.
	// Look for the first NON-redundant one we have, if any.
	let firstNonRedChas: Chassis | undefined = undefined;
	for (let chIdx = 0; chIdx < ctlrChassis.length; chIdx++) {

		// Get the chassis.
		const ctlrChas = ctlrChassis[chIdx];
		if (!ctlrChas.redundant) {
			firstNonRedChas = ctlrChas;
			break;
		}
	}

	// If we found one...
	if (firstNonRedChas) {
		// Call our helper to swap one of the existing
		// controllers for a suitable L8S.
		return swapCtlrForSafetyCtrl(firstNonRedChas, AdvCtrlReq);
	}
	else {
		// All existing controller chassis are redunant.
		// Get the last (redundant) one.
		const lastChas = ctlrChassis[ctlrChassis.length - 1];
		if (lastChas == null || lastChas.platform !== PlatformCLX) {
			throw new Error('_autoFixNoSafetyCtlr(): Either chassis not found or Non-CLX chassis marked redundant!')
		}

		// Get its rack idx.
		const [, idxRack] = getChassisAndRackIndexById(project, lastChas.id);

		// Create a spec for copying it.
		const comps: chkrCLXChassisComponents = getNewCLXChassisComponents();
		const migrateSpec: CLXMigrateChassisSpecs = {
			chassisChkrComps: comps,
			chrkModsToMigrate: [],
			sourceChassis: lastChas,
			project: project,
			idxSourceChassis: idxRack,
		}

		// Use the migrate helper to create our new chassis,
		// even though we're not actually having it move 
		// any modules for us.
		const [success, newChassis] =
			clxMigrateModulesToNewChassis(migrateSpec, true, true);

		// If that works...
		if (success && (newChassis !== null)) {

			// Get a suitable L8S catalog number.
			const catNoL8S = clxGetL8SCatNo(newChassis);

			// And add it to the new chassis.
			addModuleAtSlot(newChassis, catNoL8S, 1);
			return true;
		}
		else {
			alert('All controller chassis redundant, and migrate failed!');
			return false;
		}
	}
}

export const autoFixNoSafetyController = (project: ChassisProject): boolean => {

	let anySuccessful = false;

	// We'll control snapshots ourselves, since
	// we may be adding more than 1 new chassis.
	// Take a snapshot for undo/redo.
	contentChanging(project.content);

	// Suspend any more snapshots while we add our chassis.
	const snapshotsWereSuspended = suspendUndoSnapshots(true);

	// Call our _autoFix helper repeatedly until
	// nothing still needs fixing.
	// Limit this loop to the number of chassis(s)
	// in the project. 
	const nRacks = project.content.racks.length;
	let loops = 0;
	while (loops <= nRacks && _autoFixNoSafetyCtlr(project)) {
		++loops;
		anySuccessful = true;
	}

	// We're done. Set undo suspends
	// back to where we started.
	suspendUndoSnapshots(snapshotsWereSuspended);

	updateAllChassisLayouts(project.content);

	return anySuccessful;
}


export const getPrfShortage = (aspect: PrfAspect, commsInfo: ProjectCommsInfo, threshold: number): number => {

	if (threshold <= 0) {
		throw new Error('Invalid threshold in _getPrfShortage!');
	}

	// Get number used and available for the requested aspect.
	const [used, avail] = getPerformanceUsage(aspect, commsInfo);

	// Determine the minimum we'd need to have
	// available so as to NOT go over the threshold.
	const minReqd = Math.ceil(used / threshold);

	// The shortage is the minimum we need minus
	// what's avialable, but not less than 0.
	const shortage = Math.max(0, minReqd - avail);
	return shortage;
}

export const collectCtlrs = (chassis: Chassis, ctlrs: ChassisModule[]) => {
	const numSlots = chassis.modules.length;
	for (let slot = 0; slot < numSlots; slot++) {
		const mod = chassis.modules[slot];
		if (mod && mod.isController) {
			ctlrs.push(mod)
		}
	}
}

export const getUniqueChassisName = (rootChassisName: string, project: ChassisProject): string => {
	// Start the new chassis name as the root
	let newChassisName = rootChassisName;
	// Modify the new chassis name until unique.
	let namePostFix = 2;
	while (project.content.racks.some((rack) => rack.chassis.name === newChassisName)) {
		newChassisName = `${rootChassisName}_${namePostFix++}`;
	}

	return newChassisName;
}

export const isMemAvailViaUpsizing = (allCtlrs: ChassisModule[], shortage: number) => {

	let gainable = 0;

	for (let idx = 0; idx < allCtlrs.length; idx++) {

		const ctlr = allCtlrs[idx];
		const [, gain] = getAddedCtlrMemViaUpsize(ctlr.platform, ctlr.catNo, true);
		gainable += gain;
		if (gainable >= shortage) {
			return true;
		}
	}

	return false;
}

export const bumpControllerSizes = (allCtlrs: ChassisModule[], shortage: number): boolean => {

	// Accumulate how much memory is
	// gained along the way.
	let totalGained = 0;
	let anyUpsized = false;
	const chassisModified = new Set<Chassis>();

	// For each controller we're given...
	for (let idx = 0; idx < allCtlrs.length; idx++) {

		// Get it.
		const ctlr = allCtlrs[idx];

		// And its parent chassis.
		const chassis = ctlr.parent;

		// If we can...
		if (chassis) {

			// Get upsize info available. Note that by using
			// false as the second arg, we're asking for the
			// NEXT up-size, not the max size.
			const [upSizeCat, gain] = getAddedCtlrMemViaUpsize(ctlr.platform, ctlr.catNo, false);

			// If we get a catalog number back and
			// any associated memory gain...
			if ((upSizeCat.length > 0) && (gain > 0)) {

				// Remember the slot location of the
				// controller in its parent chassis.
				const slot = ctlr.slotIdx;

				// Detach (remove) it from the chassis.
				detachModule(ctlr);

				// Add the upSize version to the same
				// chassis at the same slot location.
				addModuleAtSlot(chassis, upSizeCat, slot);
				chassisModified.add(chassis);

				anyUpsized = true;

				// Add what we gained to our total.
				totalGained += gain;

				// If we've already reached our
				// goal, stop.
				if (totalGained >= shortage) {
					break;
				}
			}
		}
	}

	// Render bump any chassis we modified above.
	chassisModified.forEach(chas => {
		chassisChanged(chas);
	});

	return anyUpsized;
}
