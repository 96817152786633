import { appStage } from "platform-services";
import { getConfigSpec } from "../config";
import { DEV_DEBUG } from "../types/Globals";

export enum ServerEnv {
    DEV = 'DEV',
    QA = 'QA',
    PROD = 'PROD'
}

export enum AppStage {
    local = 'local',
    release = 'release',
}

export const isAppStageLocal = (): boolean => {
    return (appStage === 'release' ? false : true); 
}

export const isServerEnvProd = (): boolean => {
    return (getConfigSpec().SERVER_ENV === ServerEnv.PROD);
}

export const isServerEnvQA = (): boolean => {
    return (getConfigSpec().SERVER_ENV === ServerEnv.QA);
}

export const isServerEnvDev = (): boolean => {
    const configSpec = getConfigSpec();
    return (!configSpec.SERVER_ENV ||
        DEV_DEBUG ||
        (configSpec.SERVER_ENV === ServerEnv.DEV));
}