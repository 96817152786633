/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { ProductSelectionAttribute } from "../selectComponents/SelectComponentsTypes";
import Checkbox from "@mui/material/Checkbox";


interface SearchSidebarProps {
    selectionAttributes: ProductSelectionAttribute[];
    selectedAttributes: ProductSelectionAttribute[];
    setSelectedAttributes: (selectionAttributes: ProductSelectionAttribute[]) => void;
    handleFilterChange: (selectionAttribute: ProductSelectionAttribute) => void
}

const SearchSidebar = (props: SearchSidebarProps) => {
    const ignoredGroups = ["Degree of protection (IP)", "Line Voltage", "Control Voltage"]

    const groupBy = (xs: any[], key: string) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const unique = (arr: any[], keyProps: string[]): ProductSelectionAttribute[] => {
        const kvArray = arr.map(entry => {
            const key = keyProps.map(k => entry[k]).join('|');
            return [key, entry];
        });
        //@ts-ignore
        const map = new Map(kvArray);
        return Array.from(map.values()) as ProductSelectionAttribute[];
    }

    const sortArray = unique(props.selectionAttributes, ['description', 'value']).sort(i => i.attrSeqIdx).map(i => i.description)

    const groupedAttributes =
        ((attributes: ProductSelectionAttribute[]) => {
            return groupBy(
                unique(attributes, ['description', 'value']), "description")
        })(props.selectionAttributes);

    let nextKey = 1;

    return (
        <>
            {Object.keys(groupedAttributes)
                .sort((a, b) => sortArray.indexOf(a) - sortArray.indexOf(b))
                .filter(i => !ignoredGroups.includes(i))
                .map(group => {
                    return (
                        <Box key={nextKey++} display="flex" flexDirection="column">
                            <Typography display="flex" fontWeight="bold" sx={{ mb: 1 }}>
                                {
                                    group
                                }
                            </Typography>
                            {groupedAttributes[group].map((i: ProductSelectionAttribute) => {
                                return (
                                    <FormControlLabel
                                        key={nextKey++}

                                        label={i.value}
                                        sx={{ ml: 0 }}
                                        control={
                                            <Checkbox
                                                checked={props.selectedAttributes.find(k => k.name === i.name && k.value === i.value) !== undefined}
                                                onClick={() => props.handleFilterChange(i)}
                                            />
                                        }
                                    />
                                )
                            })}
                        </Box>
                    )
                })}
        </>
    )
}

export default SearchSidebar;
