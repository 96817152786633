import React, { useState, useEffect, useCallback } from 'react';
import './SelectComponents.scss';
import { PostProductGetProducts, PostProductGetProductsProduct, PostProductGetProductsResponse, ProductSelectionAttribute, SelectComponentsProps, SelectedCompInfoExtended } from './SelectComponentsTypes';
import ProductCards from './ProductCards';
import productApiService from '../services/apis/ProductApiService';
import ServiceResponse from '../services/apis/Response';
import { Box, Button, Grid, Typography } from '@mui/material';
import SearchForm from '../searchForm/SearchForm';
import SearchSidebar from '../searchSidebar/SearchSidebar';
import SelectedProductCards from '../selectedProducts/SelectedProductCards';
import { LoadingIndicator } from '@rockwell-automation-inc/ra-meatball';

export const SelectComponents = (props: SelectComponentsProps) => {
    const [searchCriteria, setSearchCriteria] = useState("");
    const [selectedMap, setSelectedMapState] = useState<SelectedCompInfoExtended[]>([]);
    const [products, setProducts] = useState<PostProductGetProductsResponse>();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAttributes, setSelectedAttributes] = useState<ProductSelectionAttribute[]>([]);

    const getQtyFor = (component: string): number => {
        const qty = selectedMap.find(i => i.id === component)?.quantity
        if (qty) {
            return qty;
        }
        else {
            return 0;
        }
    }

    useEffect(() => {
        const getRequest = (): PostProductGetProducts => {
            if (props.catNoChoices) {
                const request: PostProductGetProducts = {
                    catalogNumbers: props.catNoChoices,
                    includePimAttributes: true
                };

                return request;
            }
            else {
                const request: PostProductGetProducts = {
                    catalogNumbers: [],
                    includePimAttributes: true
                };
                return request;
            }
        };

        const getData = async () => {
            const request = getRequest()
            const result = await productApiService.PostProductGetProducts(request)
            const response = new ServiceResponse(result)
            if (response.isSuccessful()) {
                setProducts(response.getData());
            }
            setIsLoading(false);
        };

        getData();
    }, [props.catNoChoices]);

    const setSelectedMap = (arrSelections: SelectedCompInfoExtended[]) => {
        props.selections.length = 0;
        props.selections.push(...arrSelections);
        setSelectedMapState(arrSelections);
    }

    const onQuantityChanged = (catNo: SelectedCompInfoExtended) => {
        const existingProduct = selectedMap.find(i => i.id === catNo.id);

        if (!existingProduct) {
            setSelectedMap([...selectedMap, catNo]);
            props.checkResults && props.checkResults([...selectedMap, catNo]);
        }
        else {
            setSelectedMap(selectedMap.map(i => {
                if (i.id === catNo.id) {
                    return {
                        ...i,
                        quantity: catNo.quantity
                    }
                }
                return i
            }))
            props.checkResults && props.checkResults(selectedMap.map(i => {
                if (i.id === catNo.id) {
                    return {
                        ...i,
                        quantity: catNo.quantity
                    }
                }
                return i
            }))
        }
    }

    const onAdd = (product: SelectedCompInfoExtended) => {
        setSelectedMap([...selectedMap, product]);
        props.checkResults && props.checkResults([...selectedMap, product]);
    }

    const handleDelete = (product: SelectedCompInfoExtended) => {
        const existingSelectedProducts = selectedMap.filter(i => i.id !== product.id);
        setSelectedMap(existingSelectedProducts);
        props.checkResults && props.checkResults(existingSelectedProducts);
    }

    const handleSubmit = (query: string) => {
        setSearchCriteria(query);
    }

    const errorMessage = () => {
        if (selectedMap.map(({ quantity }) => +quantity).reduce((a, b) => a + b, 0) > (props.maxQuantity || 0)) {

            return (
                <Box sx={{ position: "absolute", bottom: "25px", left: "2rem", }}>
                    <Typography sx={{ color: 'red' }}>
                        Selected number of Modules exceeds the possible number that can be added to this Chassis. Please remove Modules to continue.
                    </Typography>
                </Box>
            )

        }
    }

    const handleFilterChange = (selectionAttribute: ProductSelectionAttribute) => {
        const existing = selectedAttributes.find(i => i.description === selectionAttribute.description && i.value === selectionAttribute.value)
        if (!existing) {
            setSelectedAttributes([...selectedAttributes, selectionAttribute])
        }
        else {
            setSelectedAttributes(selectedAttributes.filter(i => i.id !== selectionAttribute.id))
        }
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const groupBy = (xs: any[], key: string): { [key: string]: ProductSelectionAttribute[] } => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const unique = (arr: any[], keyProps: string[]): ProductSelectionAttribute[] => {
        const kvArray = arr.map(entry => {
            const key = keyProps.map(k => entry[k]).join('|');
            return [key, entry];
        });
        //@ts-ignore
        const map = new Map(kvArray);
        return Array.from(map.values()) as ProductSelectionAttribute[];
    }


    const handleProductFilter = useCallback((products: PostProductGetProductsProduct[]): PostProductGetProductsProduct[] => {
        let result: PostProductGetProductsProduct[] = [];
        if (selectedAttributes.length <= 0) {
            result = products
        }
        else {
            const groupedAttributes =
                ((attributes: ProductSelectionAttribute[]) => {
                    return groupBy(
                        unique(attributes, ['description', 'value']), "description")
                })(selectedAttributes);

            console.log("groups", groupedAttributes)


            products.forEach(product => {
                let qualifies = true;
                Object.keys(groupedAttributes).forEach(group => {
                    const attrs = groupedAttributes[group];

                    if (!product.selectionAttributes.find(i => attrs.find(k => k.description === i.description && k.value === i.value))) {
                        qualifies = false
                    }
                })

                if (qualifies)
                    result.push(product);
            })
        }
        return result
    }, [selectedAttributes]);

    const handleSearchFilter = (filterProduct: PostProductGetProductsProduct[]): PostProductGetProductsProduct[] => {
        return filterProduct.filter(i => (i.catalogNumber + i.description).toUpperCase().includes(searchCriteria.toUpperCase()));
    }

    useEffect(() => {
        console.log('here', handleProductFilter(products?.products || []))
        console.log('here2', selectedAttributes)
    }, [handleProductFilter, products?.products, selectedAttributes])

    if (isLoading) {
        return <LoadingIndicator />
    }
    return (
        <>
            <Box display="flex" flexDirection="column" width="100%">
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={9}>
                        <Box display="flex" flexDirection="row" sx={{ gap: 2 }}>
                            <Box display="flex" flexDirection="column" sx={{ width: "30%" }}>
                                <Typography marginBottom={1} >Categories based on chassis settings</Typography>
                                {selectedAttributes.length >= 1 && (
                                    <Button
                                        variant="text"
                                        sx={{ width: "fit-content", minWidth: "auto", p: 0, justifyContent: "flex-start" }}
                                        onClick={() => setSelectedAttributes([])}
                                    >
                                        Clear All
                                    </Button>
                                )}
                            </Box>
                            <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                <SearchForm onSubmit={handleSubmit} />
                                {products && handleSearchFilter(handleProductFilter(products?.products || [])).length <= 0 && (
                                    <>
                                        <Typography display="flex" sx={{ mt: 2 }}>Your search returned no results.</Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography>
                                Selected {selectedMap.map(({ quantity }) => +quantity).reduce((a, b) => a + b, 0)} of {props.maxQuantity}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ height: "380px", mt: 0, pt: 0 }}>
                    <Grid item xs={9} display="flex" flexDirection="column" sx={{ height: "100%", pt: "0 !important" }}>
                        <Box display="flex" flexDirection="row" sx={{ overflow: "scroll", rowGap: 2 }}>
                            <Box display="flex" flexDirection="column" sx={{ width: "30%" }}>
                                <SearchSidebar
                                    selectionAttributes={products?.products.map(product => product.selectionAttributes).flat() || []}
                                    selectedAttributes={selectedAttributes}
                                    setSelectedAttributes={setSelectedAttributes}
                                    handleFilterChange={handleFilterChange}
                                />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    width: "100%",
                                    minHeight: "400px",
                                    gap: "10px"
                                }}
                            >
                                {props.detailedChoices
                                    &&
                                    handleSearchFilter(handleProductFilter(products?.products || [])).map((choice, index) => {
                                        return <ProductCards
                                            key={choice.catalogNumber + index}
                                            comp={{
                                                catalogNumber: choice.catalogNumber,
                                                description: choice.description,
                                                estimatedLeadTime: choice.estimatedLeadTime,
                                                id: choice.id,
                                                listPrice: -1,
                                                listPriceCurrency: "",
                                                listPriceDisplay: choice.displayPrice,
                                                pricing: "",
                                                prodLifeCycleStatus: "",
                                                stockStatus: "",
                                                stockStatusDisplay: "",
                                                templateId: "",
                                                templateTitle: "",
                                                photo: props.detailedChoices?.find(i => i.catNo === choice.catalogNumber)?.imgSrc || ""
                                            }}
                                            onAdd={onAdd}
                                        />
                                    })
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sx={{ height: "100%", overflowY: "scroll", pt: "0 !important" }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{
                                '.MuiPaper-root:not(:last-of-type)': {
                                    marginBottom: "1rem"
                                }
                            }}
                        >
                            {selectedMap.map((selectedProduct, index) => {
                                return (
                                    <SelectedProductCards
                                        key={`${selectedProduct.catNo}_${index}_${selectedProduct.id}`}
                                        comp={selectedProduct}
                                        quantity={getQtyFor(selectedProduct.id)}
                                        onQuantityChanged={onQuantityChanged}
                                        handleDelete={() => handleDelete(selectedProduct)}
                                    />
                                )
                            })}
                        </Box>

                        {errorMessage()}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}