import React from 'react';
import { IconButton } from '@mui/material';
import { CloseIcon } from '@rockwell-automation-inc/ra-meatball';


const ToastCloseBtn = () => {

    return (
        <IconButton
            sx={{ marginLeft: 'auto' }}
        >
            <CloseIcon
                sx={{
                    fontSize: '1rem',
                    alignSelf: 'center',
                    color: '#757575'
                }}
            />
        </IconButton>
    );

}

export default ToastCloseBtn;