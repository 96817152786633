import React from 'react';
import './MessagePanel.scss';
import { SvgIcon } from '@mui/material';
import { PanelMessage } from '../types/MessageTypes';
import { getStatusIconInfo } from '../util/IconHelp';
import PrettyTextComp from './PrettyTextComp';

export const DefaultFltMsgPanelWidth = 400;
export const DefaultFltMsgPanelTopOffset = 15;

interface PanelCompProps {
    message: PanelMessage;
    addPrettyTextClass?: string;
}


const PanelMessageComp = (props: PanelCompProps) => {
    const [Icon, iconColor] = getStatusIconInfo(props.message.level);

    const divClass = Icon
        ? 'message-panel-message-with-icon'
        : 'message-panel-message-no-icon';

    return (
        <div className={divClass} >
            {Icon
                ?
                <div>
                    <SvgIcon
                        component={Icon}
                        inheritViewBox
                        sx={{ color: iconColor, stroke: iconColor, fill: iconColor }}
                    />
                </div>
                : null}
            <PrettyTextComp
                text={props.message.text}
                addClassName={props.addPrettyTextClass}
            />
        </div>
    );

}


interface Props {
    messages: PanelMessage[];
    addPrettyTextClass?: string;
}

const MessagePanel = (props: Props) => {

    let nextKey = 1;

    return (
        <div className='message-panel-messages'>
            {props.messages.map(msg => {
                return (
                    <PanelMessageComp 
                        key={nextKey++}
                        message={msg}
                        addPrettyTextClass={props.addPrettyTextClass}
                    />
                );
            })}
        </div>
    );
}

export default MessagePanel;

