import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import { stdToastDuration } from './toast';
import ToastCloseBtn from './ToastCloseBtn';


const ToastHost = () => {

    return (
        <ToastContainer
            position="top-right"
            autoClose={stdToastDuration}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            closeButton={<ToastCloseBtn />}
            style={{
                width: '23rem',
                top: "240px"
            }}
        />
    );

}

export default ToastHost;

