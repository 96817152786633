import { addModuleAtSlot, deleteModuleAtSlot, getMaxNewModules, getSlotTypeRestriction } from "../../implementation/ImplGeneral";
import { convertAppValToGuidedSelAttrOptID, finalizeHardwareGenChassis, sortIOModulesForHWGen } from "../../implementation/ImplHardwareGen";
import { addChassis, getPowerBreakdown, getProjectFromChassis } from "../../model/ChassisProject";
import { doPostHWCreateCorrections } from "../../model/CommDetails";
import { cloneLocAttributeInfo, getLocAttributeSetting, prepareLocAttrHardwareForGen } from "../../model/GuidedSelection";
import { getExistingLocAttrInfoForPlatform, getLocationAttrInfo } from "../../model/LocAttributeInfo";
import { SelectedCompInfo } from "../../selectComponents/SelectComponentsTypes";
import { getSelectedCompSlotQuantity } from "../../selectComponents/SelectCompsWrapper";
import { IOModuleSelection } from "../../types/IOModuleTypes";
import { Chassis, ChassisProject, HardwareBuilder, Rack , ModuleSlotRestriction,MicroChassis} from "../../types/ProjectTypes";
import { PSUWarningThreshold } from "../../util/Checker";
import { getUniqueChassisName } from "../../util/CheckerAutoFixProjHelpers";
import { getEngInfoForComp } from "../../util/EngInfoHelp";
import { unexpectedError } from "../../util/ErrorHelp";
import { isPlatformSnapType } from "../../util/PlatformHelp";
import { addPowerBreakdown, getEmptyPowerBreakdown, IsPowerThresholdExceeded, PowerBreakdown } from "../../util/PowerHelp";
import { contentChanging, suspendUndoSnapshots } from "../../util/UndoRedo";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../PlatformConstants";
import { snapAddModuleAtSlot } from "../snap/SnapGeneralImpl";
import { getChassisEnvType } from "./ChassisConfig";
import {  getPluginModInfoMap, getIOModInfoMap } from "../../model/EngineeringData";  
import {
    AIBits,
    AOBits,
    DIBits,
    DOBits,
} from "../../types/IOModuleTypes";


const _HWGenErrorLog: string[] = [];
const clearErrorLog = () => _HWGenErrorLog.length = 0;

const addModuleFailedToLog = (catalog: string) => {
	const log = 'One or more ' + catalog + ' could not be added to a chassis.';
	if (_HWGenErrorLog.find(x => x === log) == null)
		_HWGenErrorLog.push(log);
}


export const getCreateHWFromSettingsErrors = (clearLog: boolean): string[] => {
	// Copy the log.
	const log = [..._HWGenErrorLog];

	if (clearLog)
		clearErrorLog();

	return log;
}

const _hwCreate_AddModuleToChassis = (
	chassis: Chassis,
	modCatalog: string,
	idxSlot: number,
	envMismatchOk = false): [success: boolean, nextSlot: number] => {
	let success = false;
	if (modCatalog) {
		success = addModuleAtSlot(chassis, modCatalog, idxSlot, envMismatchOk);
		if (!success)
			addModuleFailedToLog(modCatalog);
	}

	let nextSlot = idxSlot + 1;
	if (success) {
		const modJustAdded = chassis.modules[idxSlot];
		if (modJustAdded)
			nextSlot = idxSlot + modJustAdded.slotsUsed;
			
	}
	return [success, nextSlot];
}


// Note: we are not using this right now, but might???
const _hwCreate_AddSlotFillers = (chassis: Chassis, catSlotFiller: string, chassisSlotCount: number, idxSlotStart: number) => {
	// Walk slots, starting at start idx provided. For each.
	for (let slotIdx = idxSlotStart; slotIdx < chassisSlotCount; slotIdx++) {
		// Add the module at the current slot idx.
		// If that FAILS, we're done.
		// 2024.5.16 Update - continue to end of chassis. We
		// will not check if there is a failure.
		_hwCreate_AddModuleToChassis(chassis, catSlotFiller, slotIdx, true);
	}
}

export const getArrayOfIOModules = (selections: IOModuleSelection[]): string[] => {
	const arrayIOMods: string[] = [];
	selections.forEach(x => {
		if (x.quantity > 0 && x.catalog.length > 0) {
			// Changed for the use of 'concat' to simple loop.
			for (let idx = 0; idx < x.quantity; ++idx) {
				arrayIOMods.push(x.catalog);
			}
		}
	})

	return arrayIOMods;
}


//Logic we used based on points the way of displaying slots in the Micro 800 family
export const getArrayOfIOModulesMicroAll = (		
  selections: IOModuleSelection[],
  spareCapacity: number,
  totSlot?: number,
  numExpansion?: number,
  numPlugin?: number,
  catChassis?: string,
): string[] => {
  const arrayIOMods: string[] = [];
  selections = selections.filter((x) => x.quantity !== 0);
  totSlot &&
    numPlugin &&
    selections.forEach((x) => {
	// spare capacity calculation here
	// development of embedded i/o is pending
	const capacity = Math.ceil(x.selectedPoints[0].userPointCount + (x.selectedPoints[0].userPointCount * spareCapacity));
      if (x.quantity > 0 && x.catalog.length > 0) {
        let defaultPlugin = "";
        const ioModuleInfo = getIOModInfoMap(PlatformMicro);
		const pluginModuleInfo = getPluginModInfoMap(PlatformMicro);
		const chasInfo = catChassis && getEngInfoForComp(PlatformMicro,catChassis)
		//Default selection of Plugin based on DI,DO,AI and AO
        if (((x.selectedPoints[0].typeID && DIBits)=== DIBits) && chasInfo) {
			defaultPlugin = "2080-IQ4"; 
        }
        if (((x.selectedPoints[0].typeID && DOBits)=== DOBits) && chasInfo) {
			defaultPlugin = "2080-OB4";
        }
        if (((x.selectedPoints[0].typeID && AIBits)=== AIBits) && chasInfo) {
			defaultPlugin = "2080-IF4";
        }
        if (((x.selectedPoints[0].typeID && AOBits)=== AOBits) && chasInfo) {
			defaultPlugin = "2080-OF2";
        }
        const modInfoPlugin = pluginModuleInfo.get(defaultPlugin);
        const modInfoIoExpansion = ioModuleInfo.get(x.catalog);
        let i = 0;
        let flag = false;

        let remainder =
		arrayIOMods.length === 0
            ? 0
            : arrayIOMods.length % totSlot;
        if (remainder <= numPlugin) {
          remainder =
		arrayIOMods.length === 0
              ? 0
              : arrayIOMods.length % totSlot;
        } else if (remainder > numPlugin) {
          remainder =
              arrayIOMods.length === 0
              ? 0
              : arrayIOMods.length % totSlot;
        }

        while (
          i < capacity &&
          totSlot &&
          numPlugin
        ) {
          let inputType = true;
          if (
            x.selectedPoints[0].typeID === AIBits ||
            x.selectedPoints[0].typeID === DIBits
          ) {
            inputType = true;
          }

          if (
            x.selectedPoints[0].typeID === AOBits ||
            x.selectedPoints[0].typeID === DOBits
          ) {
            inputType = false;
          }

          if (modInfoPlugin && numPlugin) {
            for (let idx = 0 + remainder; idx < numPlugin; ++idx) {
			// Calculation of quantity based on the plugin
              i += Math.ceil(
                inputType
                  ? modInfoPlugin.points.input
                  : modInfoPlugin.points.output
              );
              arrayIOMods.push(defaultPlugin);
              remainder = 0;
              if (i >= capacity) {
                flag = true;
                break;
              }
            }
          }
          if (modInfoIoExpansion && numExpansion && totSlot && numPlugin) {
            if (!flag) {
              for (
                let idy = remainder !== 0 ? remainder : numPlugin;
                idy < totSlot;
                ++idy
              ) {
				// Calculation of quantity based on the I/O expansion
                i += Math.ceil(
                  inputType
                    ? modInfoIoExpansion.points.input
                    : modInfoIoExpansion.points.output
                );
                arrayIOMods.push(x.catalog);
                remainder = 0;
                if (i >= capacity) {
                  break;
                }
              }
            }
          }
        }
      }
    });

  return arrayIOMods;
};


export class HWGenPowerTracker {
	constructor(platform: string, psuThreshold?: number) {
		this.startingPower = getEmptyPowerBreakdown();
		this.currentPower = getEmptyPowerBreakdown();
		this.platform = platform;
		// If no threshoold passed in, use WARNING.
		this.threshold = (psuThreshold ? psuThreshold : PSUWarningThreshold);
	}

	startingPower: PowerBreakdown;
	currentPower: PowerBreakdown;
	platform: string;
	threshold: number;

	startTrackingPwr(catalog: string | undefined): boolean {
		// Clear the current power...
		const targetCat = (catalog ? catalog : '');
		this.startingPower = getPowerBreakdown(this.platform, targetCat);
		this.currentPower = getEmptyPowerBreakdown();

		switch (this.platform) {
			case PlatformCpLX:
			case PlatformFlex:
			case PlatformFlexHA:
				{
					// Cleanup any DATA errors.
					if (this.startingPower.modPower === 0) this.startingPower.modPower = 10000000;

					// Ignore SA power
					//if (this.startingPower.saPower === 0) this.startingPower.saPower = 10000000;
					this.startingPower.saPower = 10000000
				}
				return true;

			case PlatformCLX:
				{
					// If we have any 0s, set them to a LARGE
					// number. This would indicate a problem
					// with the data and we do NOT want to get
					// into an infinite loop.
					if (this.startingPower.mAat24V === 0) this.startingPower.mAat24V = 10000000;
					if (this.startingPower.mAat5V === 0) this.startingPower.mAat5V = 10000000;
					if (this.startingPower.mWatt === 0) this.startingPower.mWatt = 10000000;

				}
				return true;

			case PlatformMicro: // TODO_MICRO
				return false;
		}

		return false;
	}

	trackPower(catalog: string | undefined): boolean {
		if (!catalog) {
			// We have an empty/undefined module catalog.
			// 2024.3.25 Changed threshold to Warning from Error.
			const error = IsPowerThresholdExceeded(this.platform, this.currentPower, this.startingPower, this.threshold);
			return (error === false);
		}

		// Add the module's power draw to the total draw.
		addPowerBreakdown(this.currentPower, getPowerBreakdown(this.platform, catalog), true);

		// Make any adjustments
		switch (this.platform) {
			case PlatformCpLX:
			case PlatformFlex:
			case PlatformFlexHA:
				{
					// Ignore SA Power.
					this.currentPower.saPower = 0;
				}
				break;

			case PlatformCLX:
			case PlatformMicro:
			default:
				break;
		}

		// We are just checking if the threshold
		// has been exceeded.
		const error = IsPowerThresholdExceeded(this.platform, this.currentPower, this.startingPower, this.threshold);
		return (error === false);
	}
}

export const genCreateHardwareFromSettingsMicro = (hardware: HardwareBuilder, project: ChassisProject, clearHardware: boolean) => {
	clearErrorLog();

	if (clearHardware) {
		// Clear the current content.
		project.content.racks.length = 0;
	}

	// Create a Power tracker
	const platform = hardware.platform;
	const pwrTrk = new HWGenPowerTracker(platform);

	let chassis = null;
	let chassisSlotCount = 0;

	// If we have valid Controller Chassis Specs
	if ( hardware.catChassis && hardware.numCtrlChassisSlot) {
		// Create the controller chassis here. It may be the
		// same chassis as the remote I/O chassis(s) or NOT.
		// This chassis will be the first chassis used when
		// we enter the 'I/O module' while loop below.

		// Use the general addChassis function so that the
		// chassis gets any accessories it requires. The
		// general one also does the layout update for us.
		chassis = addChassis(project, platform, hardware.catChassis, -1, hardware.catPowerSupply, hardware.catScanner);
		chassisSlotCount = hardware.numCtrlChassisSlot;

		if (!chassis) {
			_HWGenErrorLog.push('Error: Failed to create controller chassis. Hardware generation aborted.');
			return;
		}
		else {
			chassis.name = 'Controller';
			pwrTrk.startTrackingPwr(hardware.catCtrlPowerSupplier);
		}
	}

	// Verify the information for the remote chassis.
	const catRemoteChassis = (hardware.catChassis ? hardware.catChassis : null);
	const numRemoteSlots = (hardware.numChassisSlot ? hardware.numChassisSlot : null);
	const spareCapacity = hardware.spareCapacity ? hardware.spareCapacity : 0;
	const catRemotePSU = hardware.catPowerSupply ? hardware.catPowerSupply :'';
	if (!numRemoteSlots || !catRemoteChassis) {
		// Regardless if we need the remote chassis or not,
		// something went sideways if we do NOT have it.
		_HWGenErrorLog.push('Error: Remote chassis data is invalid.. Hardware generation aborted.');
		return;
	}
	//Logic we used based on DI,DO,AI and AO points the way of displaying slots in the Micro 800 family
	const arrIOModules = getArrayOfIOModulesMicroAll( hardware.ioModuleSelections, spareCapacity, hardware.numCtrlChassisSlot, hardware.ExpansionIO, hardware.Plugin, hardware.catChassis).reverse();
	let ioModulesLeft = arrIOModules.length;
	let ioModulesAddedToChassis = 0;
	let devCatalog = '';
	let idxSlot = 0;
	while (ioModulesLeft > 0) {
		// Do we need a new remote chassis for I/O...
		if (!chassis || idxSlot >= chassisSlotCount) {
			// Create a new remote I/O chassis.
			// Use the general addChassis function so that the
			// chassis gets any accessories it requires. The
			// general one also does the layout update for us.
			chassis = addChassis(project, platform, catRemoteChassis, -1, catRemotePSU, hardware.catScanner);
			if (!chassis) {
				_HWGenErrorLog.push(`${platform} Chassis failed to be created.`);
				break;
			}
			chassis.name = 'Controller';
			// Reset our counters.
			chassisSlotCount = numRemoteSlots;
			idxSlot = 0;
			ioModulesAddedToChassis = 0;
		
		}
		// Add the I/O module.
		devCatalog = arrIOModules[--ioModulesLeft];

		// 2023.11.7 We can enter this loop when do NOT have any I/O modules,
		// but still need to add the controller/comm to the controller chassis.
		// When this occurs, the device catalog will be UNDEFINED (i.e. getting 
		// arrIOModules[-1]). Instead of checking the index, check for the catalog
		// being valid. This will also cover any empty strings in the I/O array.
		if (devCatalog) {
			const [ success ] = _hwCreate_AddModuleToChassis(chassis, devCatalog, idxSlot);
			
			if (success) {
				// Up until this point, we will assume that the
				// additions of Proc/Partner/Scanner/etc. will
				// not cause a power issue. However, I/O might.
				if (pwrTrk.trackPower(devCatalog) === false) {
					// 2024.3.25 As a precaution against an
					// infinite loop, we will add at least 1
					// I/O module before we say the chassis is
					// past the power threshold limit.
					if (ioModulesAddedToChassis > 0) {
							// Increment the slot index. We could not delete
							// the module that put the power in excess of the
							// power supply's capability.
							++idxSlot;
							_HWGenErrorLog.push(`Error: Unable to remove module - Power exceeded in chassis ${chassis.name}`);


						// Set our slot index to the chassis size.
						// This will create a new chassis.
						idxSlot = chassisSlotCount;
					}
				}

				ioModulesAddedToChassis++;
				++idxSlot;
			}
		}
	}

	// If we have cleared the hardware (default), we are
	// generating a completely new system. We will run
	// some post HW Gen checks and try to Auto-Fix some 
	// select SYSTEM issues (if they exist).
	if (clearHardware) {
		doPostHWCreateCorrections(project);
	}

	return;
}

const _finalizeChassis = (chassis?: Chassis | null, catSlotFiller?: string, chassisSlotCount?: number, idxSlot?: number) => {
	if (chassis) {
		// Add slot fillers if needed. Note: From Product Selection, the
		// slot filler catalog will be undefined if we are NOT adding them.
		if (catSlotFiller && chassisSlotCount && idxSlot)
			_hwCreate_AddSlotFillers(chassis, catSlotFiller, chassisSlotCount, idxSlot);

		// Call a platform specific finalization.
		finalizeHardwareGenChassis(chassis);
	}
}

export const genCreateHardwareFromSettings = (hardware: HardwareBuilder, project: ChassisProject, clearHardware: boolean) => {
	clearErrorLog();

	if (clearHardware) {
		// Clear the current content.
		project.content.racks.length = 0;
	}

	// Create a Power tracker
	const platform = hardware.platform;
	const pwrTrk = new HWGenPowerTracker(platform);

	let chassis = null;
	let chassisSlotCount = 0;

	// If we have valid Controller Chassis Specs
	if (!hardware.remoteIOOnly && hardware.catController && hardware.numCtrlChassisSlot && hardware.catControllerChassis) {
		// Create the controller chassis here. It may be the
		// same chassis as the remote I/O chassis(s) or NOT.
		// This chassis will be the first chassis used when
		// we enter the 'I/O module' while loop below.

		// Use the general addChassis function so that the
		// chassis gets any accessories it requires. The
		// general one also does the layout update for us.
		chassis = addChassis(project, platform, hardware.catControllerChassis, -1, hardware.catPwrSupCtrlChassis);
		chassisSlotCount = hardware.numCtrlChassisSlot;

		if (!chassis) {
			_HWGenErrorLog.push('Error: Failed to create controller chassis. Hardware generation aborted.');
			return;
		}
		else {
			chassis.name = 'Controller';
			pwrTrk.startTrackingPwr(hardware.catCtrlPowerSupplier);
		}
	}

	// Verify the information for the remote chassis.
	const catRemoteChassis = (hardware.catChassis ? hardware.catChassis : null);
	const catRemoteScanner = (hardware.catScanner ? hardware.catScanner : null);
	const numRemoteSlots = (hardware.numChassisSlot ? hardware.numChassisSlot : null);
	const catRemotePowerSupplier = (hardware.catRemotePowerSupplier ? hardware.catRemotePowerSupplier : null);
	const catRemotePSU = hardware.catPowerSupply;
	if (!numRemoteSlots || !catRemoteScanner || !catRemoteChassis || !catRemotePowerSupplier) {
		// Regardless if we need the remote chassis or not,
		// something went sideways if we do NOT have it.
		_HWGenErrorLog.push('Error: Remote chassis data is invalid.. Hardware generation aborted.');
		return;
	}

	let controllerAdded = (hardware.remoteIOOnly ? hardware.remoteIOOnly : false);
	let scannerAdded = false;
	let cntRemoteChassis = 1; // Used to name the remote chassis(s).
	// Note: Since we add modules from the tail of the array, we
	// reverse the order of the modules.
	const arrIOModules = getArrayOfIOModules(hardware.ioModuleSelections).reverse();

	// 2024.5.17 Some platforms need special sorting.
	// Pass the array in and set the flag that the
	// order is reversed.
	sortIOModulesForHWGen(platform, arrIOModules, true);

	let ioModulesLeft = arrIOModules.length;
	let ioModulesAddedToChassis = 0;
	let devCatalog = '';
	let idxSlot = 0;
	while (ioModulesLeft > 0 || !controllerAdded || !scannerAdded) {
		// Do we need a new remote chassis for I/O...
		if (!chassis || idxSlot >= chassisSlotCount) {
			// Create a new remote I/O chassis.
			// Use the general addChassis function so that the
			// chassis gets any accessories it requires. The
			// general one also does the layout update for us.
			chassis = addChassis(project, platform, catRemoteChassis, -1, catRemotePSU);

			pwrTrk.startTrackingPwr(catRemotePowerSupplier);

			if (!chassis) {
				_HWGenErrorLog.push(`${platform} Chassis failed to be created.`);
				break;
			}

			// Name the chassis.
			if (controllerAdded) {
				chassis.name = 'Remote' + cntRemoteChassis.toString().padStart(3, '0');
				cntRemoteChassis++;
			}
			else {
				chassis.name = 'Controller';
			}

			// Reset our counters.
			chassisSlotCount = numRemoteSlots;
			idxSlot = 0;
			ioModulesAddedToChassis = 0;
			scannerAdded = false;
		}

		// Do we need to add the controller...
		if (controllerAdded === false) {
			if (hardware.catController) {
				const [success, nextSlot] = _hwCreate_AddModuleToChassis(chassis, hardware.catController, idxSlot);
				if (success) {
					idxSlot = nextSlot;

					// If the controller is NOT the power supplier,
					// add the controller as a consumer (trackPower).
					// Otherwise, start a fresh power tracker using
					// the Ctrl Chassis power supplier which is the
					// controller in CompactLogix.
					if (hardware.catCtrlPowerSupplier !== hardware.catController)
						pwrTrk.trackPower(hardware.catController);
					else
						pwrTrk.startTrackingPwr(hardware.catCtrlPowerSupplier);
				}
			}

			if (hardware.catControllerPartner) {
				// Note: from Guided Selection, partners can be
				// things like a co-processor or RM module. All we
				// know is that they are placed next to the Controller.
				// Note: Using a 'for loop' since forEach loses
				// the definition of 'chassis' variable (type 'any' error)
				const len = hardware.catControllerPartner.length;
				for (let idx = 0; idx < len; ++idx) {
					const partner = hardware.catControllerPartner[idx];
					const [success, nextSlot] = _hwCreate_AddModuleToChassis(chassis, partner, idxSlot);
					if (success) {
						idxSlot = nextSlot;
						pwrTrk.trackPower(partner);
					}
				}
			}

			if (hardware.ctrlChassisNeedsScanner && hardware.catScanner) {
				const [success, nextSlot] = _hwCreate_AddModuleToChassis(chassis, hardware.catScanner, idxSlot);
				if (success) {
					idxSlot = nextSlot;
					pwrTrk.trackPower(hardware.catScanner);
				}
			}

			controllerAdded = true;
			scannerAdded = true;

			// If we have a dedicated controller chassis...
			if (hardware.ctrlDedicatedChassis || hardware.redCtrlChassis) {
				// Finalize the chassis.
				_finalizeChassis(chassis, hardware.catSlotFiller, chassisSlotCount, idxSlot);


				// Clear the chassis and re-enter the while loop.
				// This will create a new remote chassis.
				chassis = null;
				continue;
			}
		}

		if (scannerAdded === false) {
			// Add our scanner.
			const [success, nextSlot] = _hwCreate_AddModuleToChassis(chassis, catRemoteScanner, idxSlot);
			if (success) {
				idxSlot = nextSlot;

			if (catRemoteScanner !== hardware.catRemotePowerSupplier)
				pwrTrk.trackPower(catRemoteScanner);
			}

			scannerAdded = true;
		}

		// Add the I/O module.
		devCatalog = arrIOModules[--ioModulesLeft];

		// 2023.11.7 We can enter this loop when do NOT have any I/O modules,
		// but still need to add the controller/comm to the controller chassis.
		// When this occurs, the device catalog will be UNDEFINED (i.e. getting 
		// arrIOModules[-1]). Instead of checking the index, check for the catalog
		// being valid. This will also cover any empty strings in the I/O array.
		if (devCatalog) {
			const [success, nextSlot] = _hwCreate_AddModuleToChassis(chassis, devCatalog, idxSlot);
			if (success) {
				// Up until this point, we will assume that the
				// additions of Proc/Partner/Scanner/etc. will
				// not cause a power issue. However, I/O might.
				if (pwrTrk.trackPower(devCatalog) === false) {
					// 2024.3.25 As a precaution against an
					// infinite loop, we will add at least 1
					// I/O module before we say the chassis is
					// past the power threshold limit.
					if (ioModulesAddedToChassis > 0) {
						// Remove the last added module.
						if (deleteModuleAtSlot(chassis, idxSlot)) {
							// Increment our I/O modules left. Note: Only
							// reset ioModulesLeft when we DID delete it.
							++ioModulesLeft;
						}
						else {
							// Increment the slot index. We could not delete
							// the module that put the power in excess of the
							// power supply's capability.
							idxSlot = nextSlot;
							_HWGenErrorLog.push(`Error: Unable to remove module - Power exceeded in chassis ${chassis.name}`);
						}

						// We are not adding any more modules to the
						// chassis. Finalize it (Add slot fillers, etc.).
						_finalizeChassis(chassis, hardware.catSlotFiller, chassisSlotCount, idxSlot);

						// Set our slot index to the chassis size.
						// This will create a new chassis.
						idxSlot = chassisSlotCount;
					}
				}

				ioModulesAddedToChassis++;
				idxSlot = nextSlot;
			}
		}
	}

	// Finalize the chassis (Add slot fillers, etc.).
	_finalizeChassis(chassis, hardware.catSlotFiller, chassisSlotCount, idxSlot);

	// If we have cleared the hardware (default), we are
	// generating a completely new system. We will run
	// some post HW Gen checks and try to Auto-Fix some 
	// select SYSTEM issues (if they exist).
	if (clearHardware) {
		doPostHWCreateCorrections(project);
	}

	return;
}

const addFailedCatalogToMap = (map: Map<string, number>, catalog: string) => {
	const entry = map.get(catalog);
	if (entry) {
		map.set(catalog, entry + 1);
		return;
	}

	map.set(catalog, 1);	
}

// Note: Caller guarantees that there are enough slots available.
export const snapAddModulesToChassis = (chassis: Chassis,
	sels: SelectedCompInfo[],
	totalQty: number,
	targetSlot: number) => {

	const platform = chassis.platform;
	const pwrTrk = new HWGenPowerTracker(platform);

	// First determine of we need a new chassis.
	const totalToAdd = getSelectedCompSlotQuantity(platform, sels);
	const totalCanAdd = getMaxNewModules(chassis, getSlotTypeRestriction(chassis, targetSlot), true);
	// If we can add all of the modules to the
	// original chassis, we will NOT enforce
	// power constraints. Otherwise, if we
	// have to create additional chassis(s),
	// we will make sure all chassis do not 
	// exceed the power Warning Threshold.
	const modSlot0 = chassis.modules[0];
	const trackPower = (modSlot0 && totalToAdd > totalCanAdd);

	if (trackPower) {
		pwrTrk.startTrackingPwr(modSlot0.catNo);

		// Add the existing mods to pwr tracker.
		const lenModArr = chassis.modules.length;
		for (let idx = 1; idx < lenModArr; ++idx) {
			const mod = chassis.modules[idx];
			if (mod)
				pwrTrk.trackPower(mod.catNo);
		}

	}

	if (targetSlot === 0) {
		if (totalQty === 1 && sels.length === 1) {
			// Allow a replacement for Slot 0 (not implemented, but
			// same mechanism can be used).
			if (modSlot0) {
				deleteModuleAtSlot(chassis, 0);
				// Re-check that Slot 0 is empty, If NOT...
				if (chassis.modules[0]) {
					unexpectedError(`Action Cancelled: Could not remove ${modSlot0.catNo} from Slot 0 of chassis ${chassis.name}.`);
					return;
				}
			}

			const infoSlot0 = getEngInfoForComp(chassis.platform, sels[0].catNo);
			// If we can get the Eng Info...
			if (infoSlot0) {
				// No matter which snap platform (as of now),
				// the module should be a controller or comm.
				if (infoSlot0.isComm || infoSlot0.isController) {
					if (snapAddModuleAtSlot(chassis, sels[0].catNo, 0, true))
						return;
				}
			}

			// If we get here, we had a selection,
			// but something went wrong. Notify the user.
			const modCat = (infoSlot0 ? ` ${infoSlot0.catNo} ` : '');
			const msg = `The selected module ${modCat} could not be added to Slot 0 of ${chassis.name}`;
			unexpectedError(msg, false);
			return;
		}

		throw new Error('snapAddModulesToChassis(): Invalid call targeting left slot.');
	}
	else {
		const mapFailedCats = new Map<string, number>();
		let idxInsert = -1;
		const modExisting = chassis.modules[targetSlot];
		if (modExisting && modExisting.slotFiller)
			idxInsert = targetSlot;

		sels.forEach(sel => {
			let qtyRem = sel.quantity;
			while (qtyRem > 0) {
				// If the catalog is already in the failed map, 
				// it will fail again.
				if (mapFailedCats.get(sel.catNo)) {
					// Add it to the failed map, update
					// quantity left, and Continue...
					addFailedCatalogToMap(mapFailedCats, sel.catNo);
					--qtyRem;
					continue;
				}

				if (snapAddModuleAtSlot(chassis, sel.catNo, idxInsert, true) === false) {
					addFailedCatalogToMap(mapFailedCats, sel.catNo);
				}
				else if (trackPower){
					// Module added.
					if (pwrTrk.trackPower(sel.catNo) === false) {
						// We are just going to walk the module array
						// backward and remove the first module with
						// the catalog of the one we just added.
						const len = chassis.modules.length;
						for (let idx = len - 1; len > 0; --idx) {
							const mod = chassis.modules[idx];
							if (mod && mod.catNo === sel.catNo) {
								// Splice it out
								chassis.modules.splice(idx, 1);
								// Add it to failed modules
								addFailedCatalogToMap(mapFailedCats, sel.catNo);
								// break out of 'for loop' (not 'while loop').
								break;
							}
						}
					}
				}

				// Regardless of success, set idxInsert
				// to -1 so that the rest of modules
				// are added to the end of the chassis.
				idxInsert = -1;

				// Decrement the number of remaining modules.
				qtyRem--;
			}
		});

		if (mapFailedCats.size > 0)
			_createAdditionalChassis(mapFailedCats, chassis);
	}
}

export const clxAddModulesToChassis = (chassis: Chassis,
	sels: SelectedCompInfo[],
	totalQty: number,
	targetSlot: number) => {

	const platform = chassis.platform;
	const lenModArr = chassis.modules.length;
	const pwrTrk = new HWGenPowerTracker(platform);

	// First determine of we need a new chassis.
	const totalToAdd = getSelectedCompSlotQuantity(platform, sels);
	const totalCanAdd = getMaxNewModules(chassis, getSlotTypeRestriction(chassis, targetSlot), true);

	const trackPower = (totalToAdd > totalCanAdd);
	if (trackPower && chassis.ps) 
		pwrTrk.startTrackingPwr(chassis.ps.catNo);


	// Get slot idx's of all empty slots in the chassis.
	const emptySlots: number[] = [];
	let slotFillers: number[] = [];
	for (let idx = 0; idx < lenModArr; ++idx) {
		const mod = chassis.modules[idx];
		if (mod) {
			if (trackPower)
				pwrTrk.trackPower(mod.catNo);

			if (mod.slotFiller) {
				// If the slot filler occupies our starting slot...
				if (idx === targetSlot) {
					// Add it to the array of empty slots.
					// We do NOT need to delete it! (I'm 90%+ on this).
					//deleteModuleAtSlot(chassis, idx);
					emptySlots.push(idx);
				}
				else {
					slotFillers.push(idx);
				}
			}
		}
		else {
			emptySlots.push(idx);
		}
	}

	// Find the idx (in that array) of our target, 
	// which is SUPPOSED to be empty or a Slot Filler.
	const targIdx = emptySlots.indexOf(targetSlot);

	// Sanity check that it's in there.
	if (targIdx < 0) {
		throw new Error('Unexpected ERROR in _addModulesToChassis!');
	}

	// Determine the number of empties from that 
	// target rightward.
	const numTargToRight = emptySlots.length - targIdx;

	// Then decide where we need to start. If there're
	// enough empties from the target rightward, we'll
	// start at the target. Otherwise, we'll move leftward
	// as needed.
	let startIdx = (numTargToRight >= totalQty)
		? targIdx
		: emptySlots.length - totalQty;

	// Reverse the order of slot fillers so that
	// fillers toward the start of the chassis
	// are at the END of the array.
	slotFillers = slotFillers.reverse();
	while (startIdx < 0 && slotFillers.length > 0) {
		const sfIdx = slotFillers.pop();
		if (sfIdx != null) {
			emptySlots.push(sfIdx);
			startIdx++;
		}
	}

	// If for some reason we have too many modules
	// for the available slots, add what we can. 
	// Make sure the startIdx is >= 0.
	startIdx = Math.max(0, startIdx);

	const mapFailedCats = new Map<string, number>();

	let emptiesIdx = startIdx;
	sels.forEach(sel => {
		let qtyRem = sel.quantity;
		while (qtyRem > 0) {
			if (emptiesIdx >= emptySlots.length) {
				addFailedCatalogToMap(mapFailedCats, sel.catNo);
				qtyRem--;
				continue;
			}

			if (addModuleAtSlot(chassis, sel.catNo, emptySlots[emptiesIdx], true) === false) {
				addFailedCatalogToMap(mapFailedCats, sel.catNo);
			}
			else if (trackPower) {
				// Module added.
				if (pwrTrk.trackPower(sel.catNo) === false) {
					deleteModuleAtSlot(chassis, emptySlots[emptiesIdx]);

					// Add it to failed modules
					addFailedCatalogToMap(mapFailedCats, sel.catNo);

					// Do not increment emptiesIdx.
					qtyRem--;
					continue;
				}
			}
			emptiesIdx++;
			qtyRem--;
		}
	});

	if (mapFailedCats.size > 0) {
		_createAdditionalChassis(mapFailedCats, chassis);
	}
}

export const microAddModulesToChassis = (chassis: MicroChassis,
	sels: SelectedCompInfo[],
	totalQty: number,
	targetSlot: number) => {
	const lenModArr = chassis.layout.slotLocs.length;
	// First determine of we need a new chassis.
    const restrictType = getSlotTypeRestriction(chassis, targetSlot);
	// Get slot idx's of all empty slots in the chassis.
	const emptySlots: number[] = [];
	let slotFillers: number[] = [];
	const pluginModules = chassis?.pluginModules?.length ? chassis?.pluginModules?.length : 0;
	if(restrictType === ModuleSlotRestriction.SlotWithPlugin){
		for (let idx = 0; idx < pluginModules; ++idx) {
			const mod = chassis.modules[idx];
			if (mod) {

				if (mod.slotFiller) {
					// If the slot filler occupies our starting slot...
					if (idx === targetSlot) {
						// Add it to the array of empty slots.
						// We do NOT need to delete it! (I'm 90%+ on this).
						//deleteModuleAtSlot(chassis, idx);
						emptySlots.push(idx);
					}
					else {
						slotFillers.push(idx);
					}
				}
			}
			else {
				emptySlots.push(idx);
			}
		}
	}
	else{
		for (let idx = 0; idx < lenModArr; ++idx) {
			const mod = chassis.modules[idx];
			if (mod) {

				if (mod.slotFiller) {
					// If the slot filler occupies our starting slot...
					if (idx === targetSlot) {
						// Add it to the array of empty slots.
						// We do NOT need to delete it! (I'm 90%+ on this).
						//deleteModuleAtSlot(chassis, idx);
						emptySlots.push(idx);
					}
					else {
						slotFillers.push(idx);
					}
				}
			}
			else {
				emptySlots.push(idx);
			}
		}
	}

	// Find the idx (in that array) of our target, 
	// which is SUPPOSED to be empty or a Slot Filler.
	const targIdx = emptySlots.indexOf(targetSlot);

	// Determine the number of empties from that 
	// target rightward.
	const numTargToRight = emptySlots.length - targIdx;

	// Then decide where we need to start. If there're
	// enough empties from the target rightward, we'll
	// start at the target. Otherwise, we'll move leftward
	// as needed.
	let startIdx = (numTargToRight >= totalQty)
		? targIdx
		: emptySlots.length - totalQty;

	// Reverse the order of slot fillers so that
	// fillers toward the start of the chassis
	// are at the END of the array.
	slotFillers = slotFillers.reverse();
	while (startIdx < 0 && slotFillers.length > 0) {
		const sfIdx = slotFillers.pop();
		if (sfIdx != null) {
			emptySlots.push(sfIdx);
			startIdx++;
		}
	}

	// If for some reason we have too many modules
	// for the available slots, add what we can. 
	// Make sure the startIdx is >= 0.
	startIdx = Math.max(0, startIdx);

	const mapFailedCats = new Map<string, number>();

	let emptiesIdx = startIdx;
	sels.forEach(sel => {
		let qtyRem = sel.quantity;
		while (qtyRem > 0) {
			if (emptiesIdx >= emptySlots.length) {
				addFailedCatalogToMap(mapFailedCats, sel.catNo);
				qtyRem--;
				continue;
			}

			if (addModuleAtSlot(chassis, sel.catNo, emptySlots[emptiesIdx], true) === false) {
				addFailedCatalogToMap(mapFailedCats, sel.catNo);
			}
			emptiesIdx++;
			qtyRem--;
		}
	});

	if (mapFailedCats.size > 0) {
		_createAdditionalChassis(mapFailedCats, chassis);
	}
}

const _createAdditionalChassis = (mapModsToAdd: Map<string, number>, srcChassis: Chassis) => {
    if (mapModsToAdd.size === 0)
        return;

    // Get the project from the chassis.
    const project = getProjectFromChassis(srcChassis);
    const platform = srcChassis.platform;

    // If we not tracking power, we
    // are assuming that we are NOT
    // creating additional chassis(s).
    // Check the project and Pwr Tracking...
    if (!project) {
        let msg = `One or more of the following modules could NOT be added to chassis ${srcChassis.name}: \n\n`;
        mapModsToAdd.forEach((cnt, cat) => {
            msg += '     ' + cat + ` (x ${cat})` + '/n'
        });

        unexpectedError(msg, false);
        return;
    }

    contentChanging(project.content);
    const wasSuspeneded = suspendUndoSnapshots(true);

    //if (!modSlot0 || modSlot0.isController) {
    // Start by cloning the current LocAttrInfo.
    let loc = getLocationAttrInfo(project.config.currLocAttrID);
    if (!loc || loc.platform !== platform) {
        const arrLocs = getExistingLocAttrInfoForPlatform(platform);
        loc = (arrLocs ? arrLocs[0] : undefined);
    }
    const clone = (loc ? cloneLocAttributeInfo(loc) : null);
    if (!clone) {
        let msg = `Could not determine required Comm Card. One or more of the following modules could NOT be added to a chassis: \n\n`;
        mapModsToAdd.forEach((cnt, cat) => {
            msg += '     ' + cat + ` (x ${cat})` + '/n'
        });

        unexpectedError(msg, false);
        suspendUndoSnapshots(wasSuspeneded);
        return;
    }

    const er = getLocAttributeSetting(clone, 'ER');
    if (er) {
        const erOptID = convertAppValToGuidedSelAttrOptID(platform, 'ER', getChassisEnvType(srcChassis));
        const opt = er.options.find(x => x.id === erOptID);
        if (opt)
            er.selectedOption = opt;
    }

    prepareLocAttrHardwareForGen(clone, project);

    // Replace the comm if the current chassis has a comm.
    if (isPlatformSnapType(platform)) {
        const modSlot0 = srcChassis.modules[0];
        if (modSlot0 && modSlot0.isComm && !modSlot0.isController)
            clone.hardware.catScanner = modSlot0.catNo;
    }
	else if (platform === PlatformCLX) {
		clone.hardware.catChassis = srcChassis.catNo;
		clone.hardware.numChassisSlot = srcChassis.modules.length;

		if (srcChassis.ps) {
			clone.hardware.catPowerSupply = srcChassis.ps.catNo;
			clone.hardware.catRemotePowerSupplier = srcChassis.ps.catNo;
		}

        const comm = srcChassis.modules.find((mod) => {
            // If the module is a comm, but NOT a Ctrl...
            if (mod && mod.isComm && !mod.isController) {
                return true;
            }

            return false;
        });

        if (comm)
            clone.hardware.catScanner = comm.catNo;
    }

    // Clear the controller values.
    clone.hardware.remoteIOOnly = true;
	clone.hardware.catController = undefined;
	clone.hardware.catCtrlPowerSupplier = undefined;

    // Load the module selections from our
    // failed catalog map.
    clone.hardware.ioModuleSelections.length = 0;
    mapModsToAdd.forEach((qty, cat) => {
        clone.hardware.ioModuleSelections.push({ catalog: cat, quantity: qty, selectedPoints: [] });
    });

    // The new chassis(s) will be at the tail
    // of the rack groups. We'll create the
    // hardware, location the original chassis,
    // then move the new chassis(s) immediately
    // after the original one.
    const idxCurrChassis = project.content.racks.findIndex(rack => rack.chassis === srcChassis);
    const lenCurrRacks = project.content.racks.length;

    genCreateHardwareFromSettings(clone.hardware, project, false);

	// Undo our suspension of snapshots.
	suspendUndoSnapshots(wasSuspeneded);

    const lenNewRacks = project.content.racks.length;
    if (lenNewRacks <= lenCurrRacks) {
        // Nothing was added.
        let msg = `New chassis could not be created. One or more of the following modules could NOT be added to a chassis: \n\n`;
        mapModsToAdd.forEach((cat) => {
            msg += '     ' + cat + '/n'
        });

        unexpectedError(msg, false);

        return;
    }

    // Place the new chassis(s) immediately after
    // the original. If the original is NOT at the end...
    if (idxCurrChassis < lenCurrRacks - 1) {
        // Collect the new chassis(s)
        const arrNewChassis: Rack[] = [];
		for (let idx = lenCurrRacks; idx < lenNewRacks; ++idx) {
			const rack = project.content.racks[idx];
			rack.chassis.name = getUniqueChassisName('Remote', project);
			arrNewChassis.push(rack);
		}

        // Shorten the Racks array to remove
        // the new chassis(s)
        project.content.racks.length = lenCurrRacks;

        // Insert the new chassis(s)
        const lenRackToInsert = arrNewChassis.length;
        for (let idx = 0; idx < lenRackToInsert; ++idx) {
            project.content.racks.splice(idxCurrChassis + idx + 1, 0, arrNewChassis[idx]);
        }
    }


	contentChanging(project.content);

	project.content.newContent = true;
}