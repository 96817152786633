import React from 'react';
import './HelpInfoPanel.scss';


interface InfoGroup {
    header: string;
    items: string[];
}

const addItem = (grp: InfoGroup, item: string) => {
    grp.items.push(item);
}

const makeInfo = (): InfoGroup[] => {
    const tipsGrp: InfoGroup = {
        header: 'Helpful tips on using this Chassis Advisor:',
        items: []
    };
    addItem(tipsGrp, 'Select a module to see information or take action on it');
    addItem(tipsGrp, 'Select the Chassis name to see information about the entire chassis or edit its settings');
    addItem(tipsGrp, 'You can customize the behavior of certain display settings or behaviors within the Settings Page (Gear Icon)');
    addItem(tipsGrp, 'Certain toolbar actions will only be available under certain conditions (Delete will appear when you have a module or chassis selected');

    const dragGrp: InfoGroup = {
        header: 'Drag and Drop:',
        items: []
    };
    addItem(dragGrp, 'You can move modules around using drag and drop');
    addItem(dragGrp, 'You can copy modules by holding down the Ctrl key while using drag and drop');

    const info = new Array<InfoGroup>();
    info.push(tipsGrp);
    info.push(dragGrp);
    return info;
}

interface ItemProps {
    itemText: string;
}

const ListGrpItem = (props: ItemProps) => {

    return (
        <li className='help-info-list-item'> {props.itemText }</li >
        );
}

interface ItemGroupProps {
    grp: InfoGroup;
}

const ItemGroup = (props: ItemGroupProps) => {

    let nextKey = 1;

    return (
        <>
            <span className='help-info-header'>
                {props.grp.header}
            </span>
            <ol className='help-info-list'>
                {props.grp.items.map(item => {
                    return (
                        <ListGrpItem
                            key={nextKey++}
                            itemText={item}
                        />
                    );
                })}
            </ol>
        </>
        );
}

const HelpInfoPanel = () => {

    const info = makeInfo();

    let nextKey = 1;

    return (
        <div className='help-info-panel'>
            <div className='help-info-text'>
                {info.map(grp => {
                    return (
                        <ItemGroup
                            key={nextKey++}
                            grp={grp}
                        />
                        );
                }) }
            </div>
            <div className='help-info-dismissal'>
                <span>
                    Click or press any key to continue...
                </span>
            </div>
        </div>
    );
}

export default HelpInfoPanel;
