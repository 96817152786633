import React, { useState } from 'react';
import { IconButton, Snackbar, SnackbarContent, SnackbarOrigin } from '@mui/material';
import { LayoutModeType, LayoutMode } from '../util/LayoutModeHelp';
import PrettyTextComp from './PrettyTextComp';
import { makeBold } from '../util/GeneralHelpers';
import { CloseIcon, InformationCircleIcon } from '@rockwell-automation-inc/ra-meatball';


const sbOrg: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
};


const getMessageText = (modeInfo: LayoutMode): string => {

    let msg = '';

    switch (modeInfo.type) {
        case LayoutModeType.Copy:
            msg = makeBold('Advanced Copy Mode: ' + modeInfo.origCat) +
                '\nClick the clipboard icons on empty ' +
                'slots to insert copies.';
            break;

        case LayoutModeType.Delete:
            msg = makeBold('Advanced Delete Mode') +
                '\nClick the trash can icons on ' +
                'any modules you want to remove.';
            break;

        default:
            throw new Error('Unexpected type in getMessageText!');
    }
    msg += '\nPress ' + makeBold('Esc') + ' or click the ' + makeBold('X') +
        ' toolbar button to return to normal mode.'
    return msg;
}


interface CloseButtonProps {
    onClicked: () => void;
}

const CloseButton = (props: CloseButtonProps) => {

    return (
        <IconButton onClick={props.onClicked}>
            <CloseIcon />
        </IconButton>
    );
}


interface Props {
    layoutMode: LayoutMode;
}

const LayoutModeDispInfo = (props: Props) => {

    // State used for 'early close'. When true, we're
    // still in a layout mode that WOULD show the info 
    // window, but the user has closed it.
    const [earlyClose, setEarlyClose] = useState(false);

    // Our snackbar window is ONLY Ok to show
    // if we're in copy or delete mode (NOT 
    // when in normal or drag mode).
    const showOk = ((props.layoutMode.type !== LayoutModeType.Normal) &&
        (props.layoutMode.type !== LayoutModeType.Drag));

    // If not ok to show, make sure that
    // our earlyClose state is false.
    if (!showOk && earlyClose) {
        setEarlyClose(false);
    }

    // Our window should be open if:
    //  1. We've determined that it's Ok to show, and
    //  2. The user hasn't manually closed it early.
    const isOpen = showOk && !earlyClose;

    // Handler for our own close button.
    const onClickCloseBtn = () => {
        setEarlyClose(true);
    }

    const message = isOpen ? getMessageText(props.layoutMode) : '';

    return (
        <Snackbar
            anchorOrigin={sbOrg}
            open={isOpen}
        >
            <SnackbarContent
                action={
                    <CloseButton onClicked={onClickCloseBtn} />
                }
                variant='outlined'
                elevation={0}
                message={
                    <>
                        <InformationCircleIcon color='info' />
                        <PrettyTextComp
                            text={message}
                            addClassName='pretty-text-y-shifted'
                        />
                    </>
                }
                color='inherit'
                sx={{
                    bgcolor: 'background.paper',
                    color: 'inherit',
                    alignItems: 'flex-start',
                    borderColor: 'info.main',
                    '&-message': {
                        alignItems: 'flex-start',
                    },
                    '&-action': {
                        color: 'inherit',
                    },
                }}
            />
        </Snackbar>
    );
}

export default LayoutModeDispInfo;

