import { addModuleAtSlot } from "../../../implementation/ImplGeneral";
import {
    chassisChanged,
	detachModule,
	getChassisAndRackIndexById,
	updateRackLayout
} from "../../../model/ChassisProject";
import {
	getWarningThreshold,
	PrfAspect,
	runCommCalcs,
} from "../../../model/CommDetails";
import {
	Chassis,
	ChassisModule,
	ChassisProject,
	DeviceType,
	ResultStatus
} from "../../../types/ProjectTypes";
import { chkrModule } from "../../../util/Checker";
import { AF_Base } from "../../../util/CheckerAutoFix";
import { getControllerRoleFor } from "../../../util/EngInfoHelp";
import { contentChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import {
	CLXMigrateChassisSpecs,
	clxMigrateModulesToNewChassis,
	getNewCLXMigrateChassisSpecs
} from "./CLXAutoFixHelpers";
import { clxGetNumAvailableSlots, getLargerChassis } from "./CLXChassis";
import {
	chkrCLXChassisComponents,
	clxScanChassis,
	getNewCLXChassisComponents
} from "./CLXChecker";
import { replaceChassis } from "../../common/ChassisConfigReplaceChassis"
import {
	bumpControllerSizes,
	collectCtlrs,
	getPrfShortage,
	isMemAvailViaUpsizing
} from "../../../util/CheckerAutoFixProjHelpers";


enum ChasCtlrTypes {
	None = 'None',
	NonAdvOnly = 'NonAdvOnly',
	AdvOnly = 'AdvOnly',
	Mixed = 'Mixed'
}

//const _collectCtlrs = (chassis: Chassis, ctlrs: ChassisModule[]) => {
//	const numSlots = chassis.modules.length;
//	for (let slot = 0; slot < numSlots; slot++) {
//		const mod = chassis.modules[slot];
//		if (mod && (mod.deviceType === DeviceType.Controller)) {
//			ctlrs.push(mod)
//		}
//	}
//}

const _findCtlrsAndScanners = (chassis: Chassis): [
	ctlrs: ChassisModule[],
	scanners: ChassisModule[],
	ctlrTypes: ChasCtlrTypes
] => {

	const ctlrs = new Array<ChassisModule>();
	const scanners = new Array<ChassisModule>();
	let types = ChasCtlrTypes.None;
	let anyAdv = false;
	let anyNonAdv = false;

	const numSlots = chassis.modules.length;
	for (let slot = 0; slot < numSlots; slot++) {
		const mod = chassis.modules[slot];
		if (mod) {
			switch (mod.deviceType) {
				case DeviceType.Controller:
					ctlrs.push(mod);
					if (mod.catNo.startsWith('1756-L8')) {
						anyAdv = true;
					}
					else {
						anyNonAdv = true;
					}
					break;

				case DeviceType.CommModule:
					scanners.push(mod);
					break;

				default:
					break;
			}
		}
	}

	if (anyAdv) {
		types = anyNonAdv ? ChasCtlrTypes.Mixed : ChasCtlrTypes.AdvOnly;
	}
	else if (anyNonAdv) {
		types = ChasCtlrTypes.NonAdvOnly;
	}

	return [ctlrs, scanners, types];
}

const _getFirstCtrl = (ctlrs: ChassisModule[], needAdvanced: boolean):
	ChassisModule | undefined => {

	for (let idx = 0; idx < ctlrs.length; idx++) {
		const ctlr = ctlrs[idx];
		if (needAdvanced) {
			if (getControllerRoleFor(ctlr.platform, ctlr.catNo).isAdvanced) {
				return ctlr;
			}
		}
		else {
			return ctlr;
		}
	}
	return undefined;
}


const _getFirstCtlrForDup = (chassis: Chassis, needAdvanced: boolean): [
	ctlr: ChassisModule | undefined,
	scannerCat: string,
	redErrorIfDup: boolean
] => {

	// Start with assumption that we won't need a scanner.
	let scannerCat: string = '';

	// Collect controller and scanner info from the chassis.
	const [ctlrs, scanners, types] = _findCtlrsAndScanners(chassis);

	// Get the first controller suitable for our requirements.
	const firstSuitableCtlr = _getFirstCtrl(ctlrs, needAdvanced);

	const isL8 = firstSuitableCtlr &&
		(firstSuitableCtlr.catNo.startsWith('1756-L8'));

	// If we found a suitable controller...
	if (firstSuitableCtlr) {

		// And we have any scanners present.
		if (scanners.length > 0) {

			// We still won't necessarily use it.
			let useScanner = false;

			// If the controller is an L8, we'll use
			// the scanner ONLY if it was clearly present
			// to serve the L8 (no L7s in the chassis).
			useScanner = isL8 ? (types === ChasCtlrTypes.AdvOnly) : true;

			if (useScanner) {
				scannerCat = scanners[0].catNo;
			}
		}
	}

	// Finally, determine if duplicating a controller
	// we found locally would cause a redundancy violation.
	const redDupErr = chassis.redundant && (isL8 || (ctlrs.length > 1));

	// Return our results.
	return [firstSuitableCtlr, scannerCat, redDupErr];
}


// Special purpose. Used only by _insertModule below.
const _reclaimSlot = (chassis: Chassis, slotIdx: number): boolean => {
	let occupant = chassis.modules[slotIdx];
	if (occupant && occupant.slotFiller) {
		// _insertModule() below is doing exactly what
		// it says, inserting a module into the chassis'
		// module array. HOWEVER, it is NOT updating the
		// slot indexes of the modules, which detachModule()
		// uses. We could change the detachModule function to
		// 'find' the module in the module array OR update the
		// slot index here. Since detatchModule() is called in
		// many places, probably safer to update the slot index
		// in this isolated case.
		occupant.slotIdx = slotIdx;
		detachModule(occupant);
		occupant = undefined;
	}
	if (!occupant) {
		chassis.modules.splice(slotIdx, 1);
		return true;
	}
	return false;
}

const _insertModule = (chassis: Chassis, catNo: string, slot: number) => {

	// Get the (original) size of the chassis.
	const origSize = chassis.modules.length;

	// Assume we won't need to 'reclaim' space we
	// temporarily added (larger than actual size)
	let reclaimReqd = false;

	// If we were told to insert PAST the
	// end of the chassis...
	if (slot >= origSize) {

		// We WILL need to reclaim.
		reclaimReqd = true;

		// Make sure the slot we actually
		// use is just ONE past the real size.
		slot = origSize;
	}

	// Get the current resident of the slot
	// specified, if anything.
	let modAtSlot = reclaimReqd ? undefined : chassis.modules[slot];

	// If it's a slot filler...
	if (modAtSlot && modAtSlot.slotFiller) {

		// Remove it.
		detachModule(modAtSlot);

		// Then re-get what's at the slot.
		modAtSlot = chassis.modules[slot];

		// Nothing should be there now.
		if (modAtSlot) {
			throw new Error('Filler detach failed in _insertModule!');
		}
	}

	// If there's (still) something in the slot, we'll
	// TEMPORARILY insert another entry into the module
	// array at the specified location. Note that after
	// we do this, the array will be one slot LARGER than
	// the number of slots provided by the chassis.
	if (modAtSlot) {
		chassis.modules.splice(slot, 0, undefined);
		reclaimReqd = true;
	}

	// Add the module.
	addModuleAtSlot(chassis, catNo, slot, true);

	// If we added the temporary slot...
	if (reclaimReqd) {

		// We need to find a suitable other slot
		// to 'reclaim'. By that, we mean that we'll
		// actually remove the slot from the array.
		let reclaimed = false;

		// First, we'll try every slot to the right of
		// our insert position.
		for (let slotIdx = slot + 1; slotIdx < chassis.modules.length; slotIdx++) {
			if (_reclaimSlot(chassis, slotIdx)) {
				reclaimed = true;
				break;
			}
		}

		// If that didn't work, we'll try to the left.
		if (!reclaimed) {
			for (let slotIdx = slot - 1; slotIdx >= 0; slotIdx--) {
				if (_reclaimSlot(chassis, slotIdx)) {
					reclaimed = true;
					break;
				}
			}
		}

		// At this point, we SHOULD have succeeded.
		if (reclaimed) {

			// Sanity check that the array is back to
			// its original size. If so...
			if (chassis.modules.length === origSize) {

				// Walk ALL of the slots in the chassis. For each...
				for (let slotNum = 0; slotNum < origSize; slotNum++) {

					// Get the current occupant, if anything.
					const occupant = chassis.modules[slotNum];

					// If we HAVE an occupant, make sure his
					// .slotLocation property is set correctly.
					if (occupant && (occupant.slotIdx !== slotNum)) {
						occupant.slotIdx = slotNum;
					}
				}
			}
			else {
				// Unexpected.
				throw new Error('Unexpected error 1 in _insertModule.');
			}
		}
		else {
			// Unexpected.
			throw new Error('Unexpected error 2 in _insertModule.');
		}
	}
}

const _getL7SafetyPartnerCat = (chassis: Chassis): string => {
	if (chassis.extendedTemp) {
		return '1756-L7SPXT';
	}
	if (chassis.conformal) {
		return '1756-L7SPK';
	}
	return '1756-L7SP';
}

const _getRedundancyModCat = (chassis: Chassis): string => {
	if (chassis.extendedTemp) {
		return '1756-RM2XT';
	}
	if (chassis.conformal) {
		return '1756-RM2K';
	}
	return '1756-RM2';
}

// We're given a chassis, and an existing controller module
// that resides in that chassis. Our mission is to create
// a NEW matching chassis, and to add a COPY of the controller
// into it.
const _dupCtlrToNewChassis = (
	project: ChassisProject,
	srcChassis: Chassis,
	ctlr: ChassisModule,
	safetyPartnerCat: string,
	scannerCat: string): boolean => {

	const [chassisFound, idxRack] = getChassisAndRackIndexById(project, srcChassis.id);

	if (chassisFound !== srcChassis) {
		throw new Error('Mismatch error in _dupCtlrToNewChassis');
	}

	const comps: chkrCLXChassisComponents = getNewCLXChassisComponents();
	const migrateSpec: CLXMigrateChassisSpecs = {
		chassisChkrComps: comps,
		chrkModsToMigrate: [],
		sourceChassis: srcChassis,
		project: project,
		idxSourceChassis: idxRack
	}

	const [success, newChassis] =
		clxMigrateModulesToNewChassis(migrateSpec, false, true);

	if (success && (newChassis !== null)) {
		let slotNum = 0;

		// Add the scanner if provided.
		if (scannerCat.length > 0) {
			addModuleAtSlot(newChassis, scannerCat, slotNum++);
		}

		// Add the controller.
		addModuleAtSlot(newChassis, ctlr.catNo, slotNum++);

		// Add the safety partner module if provided.
		if (safetyPartnerCat.length > 0) {
			addModuleAtSlot(newChassis, safetyPartnerCat, slotNum++);
		}

		// If redundant, add the redundancy module.
		if (srcChassis.redundant) {
			addModuleAtSlot(newChassis, _getRedundancyModCat(newChassis), slotNum++);
		}
	}

	return success;
}

const _addNewCtlrToChassis = (project: ChassisProject, chassis: Chassis,
	needAdvanced: boolean): boolean => {

	// Try to find a first suitable controller in the chassis,
	// and an associated scanner to use if necessary.
	const [firstCtlr, scannerCat, redDupErr] = _getFirstCtlrForDup(chassis, needAdvanced);

	// If we can find one...
	if (firstCtlr) {

		// And get perf info for it.
		const ctlrInfo = getControllerRoleFor(chassis.platform, firstCtlr.catNo);

		// Get safety-related info if applicable.
		const safetyPartnerReqd = (ctlrInfo.isSafetyController && !ctlrInfo.isAdvanced);
		const safetyPartnerCat = safetyPartnerReqd ? _getL7SafetyPartnerCat(chassis) : '';

		// If a local dup would cause a redundancy violation...
		if (redDupErr) {
			// Then hand our info off to another helper that will add a
			// NEW chassis for the new controller, and return ITS results.
			return _dupCtlrToNewChassis(project, chassis, firstCtlr, safetyPartnerCat, scannerCat);
		}

		// If we're still here...
		// Determine how many slots we'd need.
		//   2 if we need a safety partner
		//   1 otherwise
		const slotsReqd = safetyPartnerReqd ? 2 : 1;

		let chassisReplaced = false;

		// Get available (empty or filler) slots.
		let numAvailSlots = clxGetNumAvailableSlots(chassis);

		// If the existing chassis doesn't have enough...
		if (numAvailSlots < slotsReqd) {

			// See if a larger chassis is available.
			const [newChassisCat, ] = getLargerChassis(chassis.catNo);

			// If so...
			if (newChassisCat.length > 0) {

				// Swap the chassis.
				const psCat = chassis.ps ? chassis.ps.catNo : '';
				const [rslt] = replaceChassis(chassis, newChassisCat, psCat, false);

				// If that worked...
				if (rslt === ResultStatus.Success) {

					chassisReplaced = true;

					// Then get the NEW number of available slots.
					numAvailSlots = clxGetNumAvailableSlots(chassis);
				}
				else {
					// Unexpected.
					throw new Error('replaceCLXChassis failed in _addNewCtlrToChassis!');
				}
			}
			else {
				// Then hand our info off to another helper that will add a
				// NEW chassis for the new controller, and return ITS results.
				return _dupCtlrToNewChassis(project, chassis, firstCtlr, safetyPartnerCat, scannerCat);
			}
		}

		// If we're still here, we should have enough
		// room to add the new controller to the chassis.
		_insertModule(chassis, firstCtlr.catNo, firstCtlr.slotIdx + 1);
		if (safetyPartnerReqd) {
			_insertModule(chassis, safetyPartnerCat, firstCtlr.slotIdx + 2);
		}

		chassisChanged(chassis);
		if (chassisReplaced && chassis.parent) {
			updateRackLayout(chassis.parent)
		}

		return true;
	}
	else {
		// No suitable controller found
		// in the chassis provided.
		return false;
	}
}

const _addNewController = (project: ChassisProject, ctlrChassis: Chassis[],
	needAdvancedCtlr: boolean): boolean => {

	for (let idx = 0; idx < ctlrChassis.length; idx++) {
		if (_addNewCtlrToChassis(project, ctlrChassis[idx], needAdvancedCtlr)) {
			return true;
		}
	}

	return false;
}

export const clxSplitIOModules = (project: ChassisProject, chassis: Chassis,
	scannerCatNo: string): boolean => {

	//// Use our scan helper to determine what's
	//// all in the chassis.
	const chkrComps = getNewCLXChassisComponents();
	clxScanChassis(chassis, chkrComps);

	// We're looking specifically for I/O modules.
	const ioModComps = new Array<chkrModule>();

	// For each 'allModComps' (that COULD be I/O) reported...
	for (let idx = 0; idx < chkrComps.allModComps.length; idx++) {

		// Get the comp info.
		const modComp = chkrComps.allModComps[idx];

		// If it's for an I/O module, add it to our collector.
		if (modComp.module.deviceType === DeviceType.IOModule) {
			ioModComps.push(modComp);
		}
	}

	// If we found any...
	if (ioModComps.length > 0) {
		// Get the idx location of our chassis.
		const [, idxRack] = getChassisAndRackIndexById(project, chassis.id);

		// Create a new migration spec. Here, we'll specify
		// that we want to 'migrate' the IO modules.
		const migrateSpec = getNewCLXMigrateChassisSpecs(chkrComps, ioModComps, chassis, project);

		// Provide our chassis idx and the catNo of the
		// scanner we want for the new chassis.
		migrateSpec.idxSourceChassis = idxRack;
		migrateSpec.scannerCatalog = scannerCatNo;

		// Migrate the modules and return the result.
		const [success,] = clxMigrateModulesToNewChassis(migrateSpec);

		chassisChanged(chassis);

		return success;
	}

	// No IO modules are present.
	// We can't do anything.
	return false;
}

const _dupScannerModule = (project: ChassisProject, chassis: Chassis, scannerToDup: ChassisModule): boolean => {

	const slotsReqd = 1;

	let chassisReplaced = false;

	// Get available (empty or filler) slots.
	let numAvailSlots = clxGetNumAvailableSlots(chassis);

	// If the existing chassis doesn't have enough...
	if (numAvailSlots < slotsReqd) {

		// See if a larger chassis is available.
		const [newChassisCat, ] = getLargerChassis(chassis.catNo);

		// If so...
		if (newChassisCat.length > 0) {

			// Swap the chassis.
			const psCat = chassis.ps ? chassis.ps.catNo : '';
			const [rslt] = replaceChassis(chassis, newChassisCat, psCat, false);

			// If that worked...
			if (rslt === ResultStatus.Success) {

				chassisReplaced = true;

				// Then get the NEW number of available slots.
				numAvailSlots = clxGetNumAvailableSlots(chassis);
			}
			else {
				// Unexpected.
				throw new Error('replaceCLXChassis failed in _addNewCtlrToChassis!');
			}
		}
		else {
			// If we get here (which will probably never happen),
			// we can't increase the size of our chassis, because
			// it's already the largest size. In this case, we'll
			// call another helper which will attempt to make
			// room in the existing chassis by splitting off any
			// IO modules to a new chassis. Return its result.
			return clxSplitIOModules(project, chassis, scannerToDup.catNo);
		}
	}

	// If we're still here, we should have enough
	// room to add the new scanner to the chassis.
	_insertModule(chassis, scannerToDup.catNo, scannerToDup.slotIdx + 1);

	chassisChanged(chassis);
	if (chassisReplaced && chassis.parent) {
		updateRackLayout(chassis.parent)
	}

	return true;
}

const _addNewScanner = (project: ChassisProject, ctlrChassis: Chassis[]): boolean => {

	let worstRatio = 100;
	let targetChassis: Chassis | undefined = undefined;
	let targetScanner: ChassisModule | undefined = undefined;

	// For each controller chassis we're given...
	for (let idx = 0; idx < ctlrChassis.length; idx++) {

		// Get the chassis.
		const chassis = ctlrChassis[idx];

		// Collect controller and scanner info from the chassis.
		const [ctlrs, scanners, types] = _findCtlrsAndScanners(chassis);

		// We're only interested in chassis that have ONLY
		// non-advanced (L7) controller(s) and at LEAST ONE
		// scanner module. If this one meets that criteria...
		if ((types === ChasCtlrTypes.NonAdvOnly) && (scanners.length > 0)) {

			// Determine the ratio of scanners to
			// controllers in the chassis.
			const ratio = scanners.length / ctlrs.length;

			// If that ratio is worse than what
			// we've seen so far...
			if (ratio < worstRatio) {

				// This is now the worst.
				worstRatio = ratio;

				// Remember the chassis and the first
				// of its scanner modules.
				targetChassis = chassis;
				targetScanner = scanners[0];
			}
		}
	}

	// If we ended up with enough details...
	if (targetChassis && targetScanner) {

		// Call a helper to duplicate the target scanner
		// in the target chassis, and return the result.
		return _dupScannerModule(project, targetChassis, targetScanner);
	}

	// Add not possible.
	return false;
}

const _fixMemory = (project: ChassisProject,
	ctlrChassis: Chassis[],
	shortage: number): boolean => {

	// Collect all controllers in the system.
	const allCtlrs = new Array<ChassisModule>();
	ctlrChassis.forEach(chassis => {
		collectCtlrs(chassis, allCtlrs);
	})

	// Call a helper to tell us if upsizing (alone) could give
	// us enough extra memory to cover our shortage. If so...
	if (isMemAvailViaUpsizing(allCtlrs, shortage)) {

		// Then bump each controller up one size as
		// possible. Along the way, we'll stop if
		// we cover our shortage.
		// Note that after this step, we might
		// STILL be short of memory. If so, we'll
		// end up getting called again, bumping a
		// size at a time, etc.
		return bumpControllerSizes(allCtlrs, shortage);
	}
	else {
		// Otherwise, call our helper to add another
		// controller for us and return its result.
		// On the next cycle, we might have enough memory,
		// or might be able to up-size, or add yet another
		// controller, etc.
		return _addNewController(project, ctlrChassis, false);
	}

	return false;
}

const _autoFixSysPerf = (project: ChassisProject): boolean => {

	// Run our own calcs, but opt out of issue logging. We don't want
	// to RE-ADD the same issue that got us here in the first place.
	const [commsInfo, ctlrChassis] = runCommCalcs(project, false);

	// We SHOULD have at LEAST 1 controller chassis.
	if (ctlrChassis.length === 0) {
		throw new Error('No controller chassis in _autoFixSysPerf!');
	}

	// Determine the percentage level of performance
	// support that a warning would be included.
	const warnAt = getWarningThreshold(commsInfo);

	// If the project has any node-type controllers...
	if (commsInfo.allCtlrChassis.anyEnetNodeType) {

		// See if we have an issue there.
		const nodesShort = getPrfShortage(PrfAspect.nodes, commsInfo, warnAt);

		// If so, our solution will be to add a new controller.
		// Note that the new one needs to be advanced in
		// order for it to give us any more Enet Nodes supported.
		if (nodesShort > 0) {
			return _addNewController(project, ctlrChassis, true);
		}
	}

	// If still here, check controller CIP Conns.
	const ctlrCIPConnsShort = getPrfShortage(PrfAspect.ctlrCIPConns, commsInfo, warnAt);

	// If we have an issue there, our solution will again be
	// to add another controller. Here, though, we don't care
	// whether it's advanced or not.
	if (ctlrCIPConnsShort > 0) {
		return _addNewController(project, ctlrChassis, false);
	}

	// If still here, check COMM CIP Conns.
	const commCIPConnsShort = getPrfShortage(PrfAspect.commCIPConns, commsInfo, warnAt);
	if (commCIPConnsShort > 0) {
		return _addNewScanner(project, ctlrChassis);
	}

	const ppsShort = getPrfShortage(PrfAspect.pps, commsInfo, warnAt);
	if (ppsShort > 0) {
		return _addNewScanner(project, ctlrChassis);
	}

	const memoryShort = getPrfShortage(PrfAspect.memory, commsInfo, warnAt);
	if (memoryShort > 0) {
		return _fixMemory(project, ctlrChassis, memoryShort);
	}

	return false;
}

export const autoFixSysPerformance = (project: ChassisProject): boolean => {

	let anySuccessful = false;

	// We'll control snapshots ourselves, since
	// we may be adding more than 1 new chassis.
	// Take a snapshot for undo/redo.
	contentChanging(project.content);

	// Suspend any more snapshots while we add our chassis.
	const snapshotsWereSuspended = suspendUndoSnapshots(true);

	// Call our _autoFix helper repeatedly until
	// nothing still needs fixing.
	while (_autoFixSysPerf(project)) {
		anySuccessful = true;
	}

	// We're done. Set undo suspends
	// back to where we started.
	suspendUndoSnapshots(snapshotsWereSuspended);

	return anySuccessful;
}

const _getL7SafetyCompInfo = (chassis: Chassis, slot: number):
	[isL7SafetyCtlr: boolean, isL7SafetyPartner: boolean] => {

	const mod = ((slot >= 0) && (slot < chassis.modules.length))
		? chassis.modules[slot]
		: undefined;

	if (mod) {
		switch (mod.deviceType) {
			case DeviceType.Controller:
				{
					const ctrlInfo = getControllerRoleFor(mod.platform, mod.catNo);
					return [ctrlInfo.isSafetyController && !ctrlInfo.isAdvanced, false];
				}
				break;

			case DeviceType.SafetyPartner:
				return [false, mod.catNo.startsWith('1756-L7')];

			default:
				break;
		}
	}
	return [false, false];
}

const _addSafetyPartner = (project: ChassisProject, chassis: Chassis, idxRack: number,
	ctlr: ChassisModule, spCat: string): boolean => {

	const slotsReqd = 1;
	let chassisReplaced = false;

	// Get available (empty or filler) slots.
	let numAvailSlots = clxGetNumAvailableSlots(chassis);

	// If the existing chassis doesn't have enough...
	if (numAvailSlots < slotsReqd) {

		// See if a larger chassis is available.
		const [newChassisCat, ] = getLargerChassis(chassis.catNo);

		// If so...
		if (newChassisCat.length > 0) {

			// Swap the chassis.
			const psCat = chassis.ps ? chassis.ps.catNo : '';
			const [rslt] = replaceChassis(chassis, newChassisCat, psCat, false);

			// If that worked...
			if (rslt === ResultStatus.Success) {

				chassisReplaced = true;

				// Then get the NEW number of available slots.
				numAvailSlots = clxGetNumAvailableSlots(chassis);
			}
			else {
				// Unexpected.
				throw new Error('replaceCLXChassis failed in _addSafetyPartner!');
			}
		}
		else {
			// We have a case where we can't get a larger chassis.
			// One way or another, we'll be adding another chassis,
			// but we need to know what scanner to use. Find what's
			// being used on the current chassis.
			// Collect controller and scanner info from the chassis.
			const [, scanners,] = _findCtlrsAndScanners(chassis);

			// If we found at least 1...
			if (scanners.length > 0) {
				const scannerCatNo = scanners[0].catNo;

				// Try to split off any I/O modules to a different chassis.
				const anyMoved = clxSplitIOModules(project, chassis, scannerCatNo);

				// If that did anything...
				if (anyMoved) {

					// Then we NOW should have at least
					// one empty slot. Recurse back into
					// our own function and return its result.
					return _addSafetyPartner(project, chassis, idxRack, ctlr, spCat);
				}
				else {
					// No I/O. Here we need to split
					// our actual controller off to
					// a new chassis.
					// Set up our 'migration' spec.
					const comps: chkrCLXChassisComponents = getNewCLXChassisComponents();
					const migrateSpec: CLXMigrateChassisSpecs = {
						chassisChkrComps: comps,
						chrkModsToMigrate: [],
						sourceChassis: chassis,
						project: project,
						idxSourceChassis: idxRack,
					}

					// Use the migrate helper to create our new chassis,
					// even though we're not actually having it move 
					// any modules for us.
					const [success, newChassis] =
						clxMigrateModulesToNewChassis(migrateSpec, false, true);

					// If that works...
					if (success && (newChassis !== null)) {
						let slotNum = 0;

						// Add the scanner.
						addModuleAtSlot(newChassis, scannerCatNo, slotNum++);

						// Add the controller.
						addModuleAtSlot(newChassis, ctlr.catNo, slotNum++);

						// Add the safety partner module.
						addModuleAtSlot(newChassis, spCat, slotNum++);

						// Remove the controller module from the old
						// chassis, and render-bump it.
						detachModule(ctlr);
						chassisChanged(chassis);

						return true;
					}
					else {
						return false;
					}
				}
			}
			else {
				// Just fail. If we wanted to, we COULD
				// use a default scanner catNo, but we'd
				// need to be sure it was suitable for our
				// chassis environment-wise.
				return false;
			}
		}
	}

	// If we're still here, we should have enough
	// room to add the new safety partner to the chassis.
	_insertModule(chassis, spCat, ctlr.slotIdx + 1);

	chassisChanged(chassis);
	if (chassisReplaced && chassis.parent) {
		updateRackLayout(chassis.parent)
	}

	return true;
}

export const autoFixMissingSafetyPartner = (project: ChassisProject, autoFix: AF_Base): boolean => {

	// Call our helper to get the chassis and rack index
	const [chassis, idxRack] = getChassisAndRackIndexById(project, autoFix.targetInstanceID);

	// Abort if we don't get info.
	if (chassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}

	// We'll control snapshots ourselves, since
	// we may be adding more than 1 new chassis.
	// Take a snapshot for undo/redo.
	contentChanging(project.content);

	// Suspend any more snapshots while we add our chassis.
	const snapshotsWereSuspended = suspendUndoSnapshots(true);

	// Set up an array to hold L7 safety controllers
	// that don't yet have partner modules next to them.
	const noPartnerCtlrs = new Array<ChassisModule>();

	// See how many slots we have.
	const numSlots = chassis.modules.length;

	// For each...
	for (let slot = 0; slot < numSlots; slot++) {

		// See if whatever's at the current slot
		// is either an L7 safety ctlr or an SP.
		const [isSafetyCtlr, isSP] = _getL7SafetyCompInfo(chassis, slot);

		// If a controller...
		if (isSafetyCtlr) {

			// Check to see if the NEXT slot contains an SP.
			const [, nextIsSP] = _getL7SafetyCompInfo(chassis, slot + 1);

			// If so..
			if (nextIsSP) {
				// Bump our slot number.
				// Both modules are OK.
				slot++;
			}
			else {
				// Otherwise add the ctlr to our 
				// no-partners array.
				const ctlr = chassis.modules[slot];
				if (ctlr) {
					noPartnerCtlrs.push(ctlr);
				}
				else {
					throw new Error('Unexpected error 1 in autoFixMissingSafetyPartner');
				}
			}
		}
		// No an L7 safety controller.
		else {
			// If it's an SP
			if (isSP) {
				// Then it's either an extra or in the
				// wrong place. Remove it.
				const sp = chassis.modules[slot];
				if (sp) {
					detachModule(sp);
				}
				else {
					throw new Error('Unexpected error 2 in autoFixMissingSafetyPartner');
				}
			}
		}
	}

	// Since we were asked to fix the situation, we SHOULD
	// have found AT LEAST one ctlr without a safety partner
	// module directly to the right of it. If so...
	let anyFixed = false;
	if (noPartnerCtlrs.length > 0) {

		// Get the appropriate SP catalog number.
		const spCat = _getL7SafetyPartnerCat(chassis);

		// Then walk the problem cases backwards. For each...
		for (let idx = noPartnerCtlrs.length - 1; idx >= 0; idx--) {

			// Call a helper to handle the add for us.
			if (_addSafetyPartner(project, chassis, idxRack, noPartnerCtlrs[idx], spCat)) {
				anyFixed = true;
			}
		}
	}

	// We're done. Set undo suspends
	// back to where we started.
	suspendUndoSnapshots(snapshotsWereSuspended);

	return anyFixed;
}

export const clxGetL8SCatNo = (chassis: Chassis, swapFrom: ChassisModule | undefined = undefined): string => {
	swapFrom;
	if (chassis.extendedTemp) return '1756-L81EXTS';
	if (chassis.conformal) return '1756-L81ESK'
	return '1756-L81ES';
}

const _getCtlrsByType = (chassis: Chassis):
	[ctlrs7: ChassisModule[],
		ctlrs8: ChassisModule[],
		ctlrs7S: ChassisModule[],
		ctlrs8S: ChassisModule[]] => {

	const ctlrs7 = new Array<ChassisModule>();
	const ctlrs8 = new Array<ChassisModule>();
	const ctlrs7S = new Array<ChassisModule>();
	const ctlrs8S = new Array<ChassisModule>();

	const numSlots = chassis.modules.length;

	for (let slot = 0; slot < numSlots; slot++) {
		const mod = chassis.modules[slot];
		if (mod && (mod.deviceType === DeviceType.Controller)) {
			const ctlrInfo = getControllerRoleFor(mod.platform, mod.catNo);
			if (ctlrInfo.isSafetyController) {
				if (ctlrInfo.isAdvanced) {
					ctlrs8S.push(mod);
				}
				else {
					ctlrs7S.push(mod);
				}
			}
			else {
				if (ctlrInfo.isAdvanced) {
					ctlrs8.push(mod);
				}
				else {
					ctlrs7.push(mod);
				}
			}
		}
	}
	return [ctlrs7, ctlrs8, ctlrs7S, ctlrs8S];
}

const _getCtlrToSwap = (chassis: Chassis): ChassisModule | undefined => {

	const [ctlrs7, ctlrs8, ctlrs7S, ctlrs8S] = _getCtlrsByType(chassis);
	if (ctlrs8S.length) {
		throw new Error('_getCtlrToSwap found L8S?');
	}

	if (ctlrs7S.length) return ctlrs7S[0];
	if (ctlrs8.length) return ctlrs8[0];
	if (ctlrs7.length) return ctlrs7[0];

	return undefined;
}

// Helper. Makes sure that a chassis has a safety controller
// of (at least) the requested type. If not, it either adds
// one, or replaces another contoller. Return indicates 
// whether any changes were made (true) or not (false).
export const clxSwapCtlrForSafetyCtrl = (chassis: Chassis): boolean => {

	// Get the best existing controller to swap for a
	// a new advanced safety controller (L8S), if any.
	const ctlrToSwap = _getCtlrToSwap(chassis);

	// If we found a controller to swap...
	if (ctlrToSwap) {
		// Then use whatever we got to get an
		// appropriate L8S catalog number to use.
		const catNoL8S = clxGetL8SCatNo(chassis, ctlrToSwap);

		// Remember what slot it was in.
		const oldSlot = ctlrToSwap.slotIdx;

		// Then remove the old controller module.
		detachModule(ctlrToSwap);

		// Add the new L8S in its place.
		addModuleAtSlot(chassis, catNoL8S, oldSlot);

		// See what's directly to the right.
		const modToRight = chassis.modules[oldSlot + 1];

		// If it's a safety partner, remove that as well.
		if (modToRight && (modToRight.deviceType === DeviceType.SafetyPartner)) {
			detachModule(modToRight);
		}

		chassisChanged(chassis);
		return true;
	}

	throw new Error('clxSwapCtlrForSafetyCtrl(): No saftey controller found to swap!');
}
