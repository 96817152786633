import React, { ReactNode, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ProjectSetting, SettingOption } from '../types/SettingsTypes';
import './Settings.scss';
import '../styles/Meteor.scss'
import { HdrErrState } from './SettingHeader';
import { SettingIconButton } from './SettingIconButton';
import { SettingsStatusCtrl } from './SettingsStatusCtrl';
import { DesignPageChildID, getDesignPageCompRenderInfo } from '../settings/DesignPageComponentRenderInfo';

interface Props {
    setting: ProjectSetting;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
}

interface InnerProps {
	setting: ProjectSetting;
	valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
	required: boolean;
}

const ComboSetting = (props: Props) => {
	return <_ComboSetting
		setting={props.setting}
		valueChanged={props.valueChanged}
		required={false}
		/>
}

const RequireComboSetting = (props: Props) => {
	return <_ComboSetting
		setting={props.setting}
		valueChanged={props.valueChanged}
		required={true}
	/>
}

const _ComboSetting = (props: InnerProps) => {
	const [render, setRender] = useState(1);
	const _iconButtonLabel = useRef<ReactNode>(null);
	const prevMessage = useRef<string>('');
	const prevState = useRef<HdrErrState>(HdrErrState.none);

	// Place any hooks above here!.
	if (props.setting.visible === false)
		return null;


	const onChoiceMade = (event: unknown, newValue: string | undefined) => {
		const newOption = props.setting.options.find(x => x.display === newValue);
		if (newOption) {
			props.valueChanged(props.setting, newOption);

			if (props.setting.validateOnChange) {
				// Note: validateOnChange should only be set on settings
				// in the Guided Selection Attribute groups, where the
				// below code will re-render those groups. Otherwise,
				// when outside of these groups, the combo will not refresh
				// and update the combo with the current selection (i.e. 
				// previous selection will be displayed.)
				const renderInfo = getDesignPageCompRenderInfo(DesignPageChildID.GuidedSelSection);
				if (renderInfo)
					renderInfo.setRenderCount(++renderInfo.renderCount);
			}
			else {
				// 2024.1.12 If we do not re-render, The control reverts
				// to the previous value when focus is lost(?).
				setRender(render + 1);
			}

			if (props.setting.updateIOSelections) {
				const renderInfo = getDesignPageCompRenderInfo(DesignPageChildID.PointEntrySection);
				if (renderInfo)
					renderInfo.setRenderCount(++renderInfo.renderCount);
			}
		}
	}

	const textColor = (props.setting.valid ? '#000000' : '#DF3704');
	const labelColor = (props.setting.valid ? '#808080' : '#DF3704');
	const error = !props.setting.valid;

	const message = (props.setting.msgWarningOrError ? props.setting.msgWarningOrError : props.setting.defaultMessage);

	let state = HdrErrState.info;
	if (props.setting.msgWarningOrError) {
		state = (props.setting.valid ? HdrErrState.warning : HdrErrState.error);
	}

	if (state !== prevState.current || (message && message !== prevMessage.current)) {
		prevState.current = state;
		prevMessage.current = message;
		_iconButtonLabel.current = <SettingIconButton message={message} state={state} iconSize={'16'} />
	}

	const getLabel = () => {
		// If we are required AND we do not have anything in the display string...
		if (props.required && !props.setting.selectedOption.display) {
			return (
				<div className="setting-required-combo-label-container">
					<label className="setting-required-combo-def-label-text">{props.setting.name}</label>
					<label className="setting-required-combo-def-label-reqired">* Required</label>
				</div>
			);
		}

		return (
			<div className="setting-control-label-div" style={{ color: labelColor }}>
				<label className="a-type__caption" style={{ fontSize: '16px' }}>{props.setting.name}</label>
				{_iconButtonLabel.current}
			</div>
		);
	}

	const showStatusIcon = (state === HdrErrState.error || state === HdrErrState.warning);

	return (
		<div className={showStatusIcon ? "setting-control-container-error" : "setting-control-container"}>
			<div className="setting-control-body">
				
				<Autocomplete
					disablePortal
					options={props.setting.options.map(x => {return x.display })}
					value={props.setting.selectedOption.display}
					// isOptionEqualToValue is needed to prevent 'value not in options'
					// warning. Basiccally, we ALWAYS say it is good (i.e. opt = opt).
					isOptionEqualToValue={(option, ) => option === option}
					onChange={onChoiceMade}
					sx={{
						width: 280,
					}}
					autoComplete={false}
					disableClearable
					renderOption={(props, option: string) => (
						<li {...props} style={{ fontSize: 16, fontWeight: 400, fontFamily: 'Barlow' }}>{option}</li>
					)}
					renderInput={(params) =>
						<TextField
							{...params}
							size={'small'}
							error={error}
							sx={{ input: { color: textColor, fontSize: 16, fontWeight: 400 , fontFamily: 'Barlow' } }}
							margin="none"
							label={getLabel()}
						/>}
				/>
				<label className="a-type__body">{props.setting.units}</label>
			</div>
			{showStatusIcon ?
				<SettingsStatusCtrl
					state={state}
					message={message}
					/>
				:
				null
			}

		</div>
	);
}

export { ComboSetting, RequireComboSetting };

