import React from 'react';
import { Box, Button } from '@mui/material';
import { UndoIcon } from '@rockwell-automation-inc/ra-meatball';
import RefreshIcon from '@mui/icons-material/Refresh';
import toast from './toast';

interface ToastErrorProps {
	text: string | React.ReactNode;
	onUndo?: () => void;
	onRetry?: () => void;
}

const ToastError: React.FC<ToastErrorProps> = ({ text, onUndo, onRetry }) => {
	return (
		<>
			<Box sx={{ mb: 1 }}>{text}</Box>
			{(onUndo || onRetry) && (
				<Box sx={{ textTransform: 'uppercase' }} display="flex" flexDirection="row">
					{onUndo && (
						<Button
							variant="contained"
							color="secondary"
							size="small"
							sx={{ mr: 2, px: 3 }}
							onClick={() => onUndo()}
						>
							<UndoIcon fontSize="small" sx={{ mr: 1 }} />
							Undo
						</Button>
					)}
					{onRetry && (
						<Button
							variant="outlined"
							size="small"
							sx={{ px: 3 }}
							onClick={() => {
								toast.dismiss();
								onRetry();
							}}
						>
							<RefreshIcon fontSize="small" sx={{ mr: 1 }} />
							Retry
						</Button>
					)}
				</Box>
			)}
		</>
	);
};

export default ToastError;
