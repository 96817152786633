import { store } from "../redux/store";
import { MaxLocalSnapshotAgeDays } from "../types/Globals";
import { ChassisProject } from "../types/ProjectTypes";
import { StartupDetails } from "../util/AppStartupHelp";
import { getDaysBtwDates } from "../util/DateHelp";
import { logger } from "../util/Logger";
import { isConfigModified, makeConfigExchangePackage } from "./UserProjectHelp";
import { ConfigurationExchange } from "./UserProjectTypes";


const _expectedGuidLen = 36;
const _snapshotKeyStart = 'CSA:C:';
const _snapshotKeyProjTrans = ':P:';


const getCfgSnapshotKey = (userProjGuid: string, cid: string): string => {
    return _snapshotKeyStart + cid + _snapshotKeyProjTrans + userProjGuid;
}

const isCfgSnapshotKey = (key: string): boolean => {
    if (key.startsWith(_snapshotKeyStart)) {
        if (key.indexOf(_snapshotKeyProjTrans) > 0) {
            return true;
        }
    }
    return false;
}


const _storeLocal = (userProjGuid: string, cfg: ChassisProject) => {

    // Get the key for this userProj/cfg combination.
    const pkey = getCfgSnapshotKey(userProjGuid, cfg.id);

    // Use the SAME helper used for actual config saves
    // to package up what we do save. The only difference
    // here is that we don't care about any BOM, and
    // we don't want any time wasted creating that.
    const pkg = makeConfigExchangePackage(userProjGuid, cfg, false, true);

    // Convert the pkg to a string, and store
    // in local storage at our key.
    const json = JSON.stringify(pkg, null, 0);
    localStorage.setItem(pkey, json);
    logger.logPersist('persist: pkg stored, size: ' + json.length);
}


export const persistToLocalStorage = (cfg: ChassisProject) => {

    // Get our startup details.
    const startDtls = store.getState().startupInfo.startupDetails;

    // If we can...
    if (startDtls) {

        // We'll take a snapshot of our configuration every
        // time we're called and the modified flag is set.
        if (isConfigModified()) {

            const userProjGuid = startDtls.projectGuid
                ? startDtls.projectGuid
                : '';

            // Save the config to localStorage.
            _storeLocal(userProjGuid, cfg);
        }
    }
    else {
        throw new Error('No startup details in persistLocal!');
    }
}

const _isDateNewer = (d1s: string, d2s: string): boolean => {
    const compRslt = d1s.localeCompare(d2s);
    return (compRslt > 0);
}

const _getSavedConfigSnapshot = (pkey: string): ConfigurationExchange | undefined => {

    // Get whatever's stored at the requested key.
    const dataStr = localStorage.getItem(pkey);

    // If anything...
    if (dataStr) {

        // Parse into a config.
        const cfg = JSON.parse(dataStr) as ConfigurationExchange;

        // ALL valid cfgs will have an id (our cid) that SHOULD
        // be a guid. We'll at least check to see if the length
        // of it matches what we'd expect.
        if (cfg.id && (cfg.id.length == _expectedGuidLen)) {
            return cfg;
        }
        else {
            logger.error('ERROR: Config snapshot not readable!');
        }
    }

    // If we're still here, there was no valid,
    // matching snapshot stored.
    return undefined;
}

export const getSavedConfigSnapshot = (cid: string, projGuid: string)
    : ConfigurationExchange | undefined => {

    // Construct the localStorage key we would use for this case.
    const pkey = getCfgSnapshotKey(projGuid, cid);

    // Call our helper to retrieve a cfg stored there.
    const cfg = _getSavedConfigSnapshot(pkey);

    // If we get one...
    if (cfg) {

        // Sanity check to make sure cid and projGuid BOTH match.
        // If so, return it. Otherwise, log an error.
        if ((cfg.id === cid) && (cfg.projectId === projGuid)) {
            return cfg;
        }
        else {
            logger.error('ERROR: Config snapshot MISMATCH!');
        }
    }

    // If we're still here, there was no valid,
    // matching snapshot stored.
    return undefined;
}

const removeConfigSnapshot = (cfgExch: ConfigurationExchange) => {

    // Construct the localStorage key we would use for this case.
    const pkey = getCfgSnapshotKey(cfgExch.projectId, cfgExch.id);
    const entry = localStorage.getItem(pkey);
    if (entry) {
        localStorage.removeItem(pkey);
    }
    else {
        logger.error('Unexpected: removeConfigSnapshot failed to find matching entry');
    }
}

export const updateConfigFromLocalPersist = (dtls: StartupDetails, cid: string): boolean => {

    // If we got a cid...
    if (cid) {

        // Determine what we should use for a projGuid.
        // If our startup details already has a user proj, 
        // we'll use ITS guid. If not, an empty string.
        const projGuid = dtls.projectGuid ? dtls.projectGuid : '';

        // Try to get a matching saved snapshot.
        let snapshot = getSavedConfigSnapshot(cid, projGuid);

        // Special case.
        // This covers the case where an unauthorized user (not
        // logged in) started a restricted configuration, made
        // some changes on the design page, and THEN logged in
        // to continue. In that case, the key used for any locally
        // saved snapshots would have user '' for the projectGuid
        // part. AFTER login, the cid should still match, but NOW
        // we would ALSO have a projectGuid. If we didn't already
        // find a snapshot, we'll check a little further...
        if (!snapshot) {
            // After a login on a NEW configuration,
            // we WOULD have a projGuid here, but we would
            // NOT have a config already in our startup details.
            // If conditions match that...
            if (projGuid && !dtls.configInfo) {

                // See if we can find a snapshot matching
                // the cid, but WITHOUT a projGuid in its key.
                snapshot = getSavedConfigSnapshot(cid, '');

                // If we can...
                if (snapshot) {

                    // Remember the 'old' key that was used.
                    const oldKey = getCfgSnapshotKey('', cid);

                    // Change the projectId in the snapshot
                    // to match what we have NOW.
                    snapshot.projectId = projGuid;

                    // Determine the NEW key we should use
                    const newKey = getCfgSnapshotKey(projGuid, cid);

                    // Effectively replace the old snapshot with
                    // our modified version and re-save it at the
                    // new key.
                    const json = JSON.stringify(snapshot, null, 0);
                    localStorage.setItem(newKey, json);

                    // Then remove the old (projGuid-less) snapshot.
                    localStorage.removeItem(oldKey);
                }
            }
        }

        // If we have a snapshot at this point...
        if (snapshot) {
            // Start with the assumption that we WILL use it.
            let usePrst = true;

            // But, if there's already a config in startup dtls...
            if (dtls.configInfo) {

                // Assume we won't.
                usePrst = false;

                // Get mod dates from each.
                const dtlsModDate = dtls.configInfo.lastModifiedDateUtc;
                const prstModDate = snapshot.lastModifiedDateUtc;

                // If we can, compare them, and decide to
                // use the localStorage version if newer.
                if (dtlsModDate && prstModDate) {
                    if (_isDateNewer(prstModDate, dtlsModDate)) {
                        usePrst = true;
                    }
                }
                else {
                    logger.error('ERROR: Missing last modified data!');
                }
            }

            // If we're using the local storage version...
            if (usePrst) {
                // Place it into our startup details, replacing
                // whatever was in there (if anything).
                dtls.configInfo = snapshot;
            }
            else {
                // Otherwise, it's been superceded.
                // Remove it.
                removeConfigSnapshot(snapshot);
            }

            // Return our result.
            return usePrst;
        }

        return false;
    }
    else {
        throw new Error('Unexpected call to updateConfigFromLocalPersist');
    }
}


export const removeLocalPersist = (userProjGuid: string, cfg: ChassisProject) => {

    // Get the key for this userProj/cfg combination.
    const pkey = getCfgSnapshotKey(userProjGuid, cfg.id);

    // And remove the entry for it from
    // localStorage (if it's in there).
    localStorage.removeItem(pkey);
}

// Remove any local snapshots from localStorage that are OLDER than
// the number of days specified by our MaxLocalSnapshotAgeDays global.
export const cleanupResidualSnapshots = () => {

    // Set up array to hold all exising snapshot keys.
    const snaps = new Array<string>();

    // Walk all localStorage keys and collect any
    // whose keys match our snapshot format standard.
    for (let i = 0; i < localStorage.length; i++) {
        const lsKey = localStorage.key(i);
        if (lsKey && isCfgSnapshotKey(lsKey)) {
            snaps.push(lsKey);
        }
    }

    // If we find any...
    if (snaps.length > 0) {

        // Create another array to collect those
        // keys for entries we want to delete.
        const snapsToDelete = new Array<string>();

        // For each snapshot key we found...
        for (let i = 0; i < snaps.length; i++) {

            // Get the config exchange info stored there.
            const cfg = _getSavedConfigSnapshot(snaps[i]);

            // If we can (and it looks valid)...
            if (cfg) {

                // It SHOULD have a last-modified date. If so...
                if (cfg.lastModifiedDateUtc) {

                    // Determine its age in days.
                    const ageDays = getDaysBtwDates(cfg.lastModifiedDateUtc);

                    // If it's older than our max age, we'll delete it.
                    if (ageDays > MaxLocalSnapshotAgeDays) {
                        snapsToDelete.push(snaps[i]);
                    }
                }
                else {
                    // Unexpected, but we'll delete
                    // it if it has no date.
                    snapsToDelete.push(snaps[i]);
                }
            }
            else {
                // Unexpected, but we'll delete
                // anything that isn't a valid cfg.
                snapsToDelete.push(snaps[i]);
            }
        }

        // If we found any we want to delete, do so.
        if (snapsToDelete.length > 0) {
            snapsToDelete.forEach(pkey => {
                localStorage.removeItem(pkey);
            });
        }
    }
}
