import React, { useState } from 'react';
import '../styles/AppLayout.scss';
import ProjInfoSummary from '../projInfo/ProjInfoSummary';
import { ProjectSummary } from './ProjectSummary';
import { getProjInfoForSummary } from '../util/ProjInfoHelp';
import { ProjectViewType } from '../types/ViewTypes';
import { Allotment } from 'allotment';
import { ToolbarAction } from '../toolbar/ToolbarActions';
import { txtRADB_LOADING } from '../types/APITypes';
import { logger } from '../util/Logger';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setCurrentView } from '../redux/slices/ViewSlice';
import { startupDetails } from '../redux/slices/StartupInfoSlice';
import { LogRender } from '../types/Globals';
import { ChassisProject } from '../types/ProjectTypes';
import { saveConfiguration } from '../userProject/ProjectSaveAndRestore';
import { gotoProjectURL } from '../userProject/UserProjectHelp';

interface Props {
    project: ChassisProject;
    chassisImgSrc: string;
}

export const ChassisSummary = (props: Props) => {

    const dispatch = useAppDispatch();
    const startupDtls = useAppSelector(startupDetails);

    const [totals, setTotals] = useState(txtRADB_LOADING);
    const [showRefreshIcon, setShowRefreshIcon] = useState<boolean>(false);
    const [showRefreshPricing, setShowRefreshPricing] = useState(false);
    const projInfoForSummary = getProjInfoForSummary(props.project, props.chassisImgSrc);

    const onTBAction = (action: string) => {
        switch (action) {
            case ToolbarAction.LAYOUT:
                dispatch(setCurrentView(ProjectViewType.Layout));
                break;

            case ToolbarAction.SAVEPROJECT:
                {
                    const userProjGuid = (startupDtls?.projectGuid)
                        ? startupDtls.projectGuid
                        : '';
                    saveConfiguration(userProjGuid, props.project);
                }
                break;

            case ToolbarAction.GOTOPROJECT:
                gotoProjectURL(startupDtls?.projectGuid);
                break;
            
            default:
                break;
        }
    }


    if (LogRender.Views) {
        logger.logRender('Render ChassisSummary');
    }

    return (
        <div className='chassis-summary-view'>
            <div className="chassis-summary">
                <Allotment separator={true}>
                    <Allotment.Pane preferredSize={'20%'} minSize={320}>
                        <ProjInfoSummary
                            project={props.project}
                            info={projInfoForSummary}
                            onTBAction={onTBAction}
                            totals={totals}
                            setShowRefreshIcon={setShowRefreshIcon}
                            showRefreshPricing={showRefreshPricing}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane preferredSize={'80%'} minSize={500}>
                        <ProjectSummary
                            project={props.project}
                            onTBAction={onTBAction}
                            setTotals={setTotals}
                            setShowRefreshIcon={setShowRefreshIcon}
                            setShowRefreshPricing={setShowRefreshPricing}
                            showRefreshIcon={showRefreshIcon}
                        />
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );

}

