import {
    EngInfoBasic,
    EngInfoIOModule,
    EngInfoMap,
    EngInfoPLUGINModule,
    EngInfoPowerSupply
} from "../engData/EngineeringInfo";
import {
    EnvRating,
    LoadStatus,
    PSInputVoltage
} from "./ProjectTypes";


export interface PSMatches {
    singles: EngInfoPowerSupply[];
    redundants: EngInfoPowerSupply[];
}

export type InVltgToPSMatches = Map<PSInputVoltage, PSMatches>;
export type PSMap = Map<EnvRating, InVltgToPSMatches>;
export type IOModuleMap = Map<string, EngInfoIOModule>;
export type PLUGINModuleMap = Map<string, EngInfoPLUGINModule>;
export interface EngInfoPackage {

    // Status tracking during package loading.
    status: LoadStatus;

    platform: string;

    // Map keyed by catalog number containing
    // engineering data as loaded from API.
    infoMap: EngInfoMap;

    // Map keyed by catalog number. Values
    // are pre-cast to EngInfoIOModule type.
    ioModInfo: IOModuleMap;

    //Used to list all plugin and io
    pluginModInfo: IOModuleMap;

    ioModPlugin:PLUGINModuleMap;

    // Precollected and sorted I/O module
    // types. Used in design phase.
    ioModTypes: number[];

    // Used only where chassis-based power
    // supplies are applicable (like CLX).
    // Map is two level, keyed first by env
    // rating and then by PS input voltage.
    mappedPS: PSMap;

    // Used only if/as needed for a given
    // platform.
    extraData?: object;
}


export const makeEmptyPSMatches = (): PSMatches => {
    return {
        singles: new Array<EngInfoPowerSupply>(),
        redundants: new Array<EngInfoPowerSupply>()
    }
}

export const makeEmptyEngDataPackage = (platform: string): EngInfoPackage => {
    return {
        status: LoadStatus.Startup,
        platform: platform,
        infoMap: new Map<string, EngInfoBasic>(),
        pluginModInfo: new Map<string, EngInfoIOModule>(),
        ioModInfo: new Map<string, EngInfoIOModule>(),
        ioModPlugin:new Map<string, EngInfoPLUGINModule>(),
        ioModTypes: new Array<number>(),

        mappedPS: new Map<EnvRating, InVltgToPSMatches>(),
    }
}

// Used for parsing Engineering Data strings
// in the form of "cat1:2;cat2:1;cat3:2;etc."
export interface ICatalogAndCount {
    catalog: string;
    count: number;
}
