import React from 'react';
import './FloatingDetails.scss';
import { DetailItem } from '../../model/DeviceDetail';


interface Props {
    item: DetailItem;
}

const DetailsItem = (props: Props) => {

    return (
        <>
            <span className='details-item-label'>
                {props.item.name + ': '}
            </span>
            <span className='details-item-value'>
                {props.item.value}
            </span>
        </>
    );

}

export default DetailsItem;

