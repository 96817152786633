import React, { useCallback } from 'react';
import './ProjectTree.scss';
import { Chassis, Rack, SelectableDevice } from '../types/ProjectTypes';
import ProjectTreeChassis from './ProjectTreeChassis';
import { LogRender } from '../types/Globals';
import { logger } from '../util/Logger';
import { useSelectedChassis, useSelectedDevice } from '../context/SelectionInfoContext';
import { bringChassisIntoView } from '../model/ChassisProject';


interface Props {
    racks: Rack[];
    contentChanged: () => void;
}

const ProjectTree = (props: Props) => {

    const { selectChassis } = useSelectedChassis();
    const { selectDevice } = useSelectedDevice();


    const onItemClick = useCallback((chassis: Chassis, device: SelectableDevice | undefined) => {
        selectDevice(device);
        selectChassis(chassis);
        bringChassisIntoView(chassis);
    }, [selectChassis, selectDevice]);

    if (LogRender.ProjTree) {
        logger.logRender('ProjectTree');
    }

    let nextKey = 1;

    return (
        <div className="proj-tree">
            <div className="proj-tree-content">
                {props.racks.map(rack => {
                    return (
                        <ProjectTreeChassis
                            key={nextKey++}
                            chassis={rack.chassis}
                            onItemClick={onItemClick}
                            contentChanged={props.contentChanged}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default ProjectTree
