import {
    BOMItem,
    BOMItemProduct,
    BOMItemType,
    getTotalProductCount
} from "../summary/SummaryHelp";
import {
    RADBCacheStatus,
    txtRADB_LOADING,
    txtRADB_NA,
 } from "../types/APITypes";
import { formatToDollars } from "../util/CurrencyHelp";
import {
    getRaiseDeviceData,
    RaiseDevData,
    raiseDevDataQualifiesForRefetch
} from "./DeviceCache";


export interface RaiseDBDevDataCallback {
    (catalog: string, data: RaiseDevData): void;
}

export interface summarySetComponentStateTotalPrice {
    (catalog: string): void;
}

export interface summarySetComponentStateShowRefresh {
    (show: boolean): void;
}

export class RaiseBOMPricingManager {
    constructor() {
        this.mapFetchingToBOMProd = new Map<string, BOMItemProduct>;
        this.fnSetTotalsComponentCallback = () => { return; };
        this.fnSetShowRefreshComponentCallback = () => { return; };
        this.raiseFetchInProgress = false;
        this.bomTotalPrice = 0;
        this.productTimedOut = false;
        this.refetchTimedOutData = false;
    }

    // Data
    mapFetchingToBOMProd: Map<string, BOMItemProduct>;
    raiseFetchInProgress: boolean;
    bomTotalPrice: number;
    productTimedOut: boolean;
    refetchTimedOutData: boolean;
    fnSetTotalsComponentCallback: summarySetComponentStateTotalPrice;
    fnSetShowRefreshComponentCallback: summarySetComponentStateShowRefresh;

    reset() {
        this.mapFetchingToBOMProd.clear();
        this.raiseFetchInProgress = false;
        this.bomTotalPrice = 0;
        this.productTimedOut = false;
        this.refetchTimedOutData = false;
    }

    initialize(fnSetPrice: summarySetComponentStateTotalPrice, fnSetShowRefresh: summarySetComponentStateShowRefresh) {
        this.reset();
        this.fnSetTotalsComponentCallback = fnSetPrice;
        this.fnSetShowRefreshComponentCallback = fnSetShowRefresh;
    }

    calculateTotalBOMPrice = async (products: BOMItem[]) => {
        products.forEach((prod) => {
            if (prod.bomItemType === BOMItemType.Product) {
                const bomProduct = prod as BOMItemProduct;
                // Request the data...
                const data = this.getRaiseDeviceDevData(prod.catalogOrTitle, bomProduct, this.raiseDataCallback);

                // If we need to fetch the data....
                if (data.status === RADBCacheStatus.Loading) {
                    // Set the item cost to 'Loading'.
                    bomProduct.totalCost = txtRADB_LOADING;
                }
                else {
                    // The data was cached...
                    const totalCost = data.displayPriceVal * bomProduct.quantity;
                    // 2024.4.10 We are NOT going to deal with
                    // numbers here. The displayPrice has the
                    // correct price for a SINGLE unit formatted
                    // with the correct currency. Set the total
                    // cost to the displayPrice (not using displayPriceVal).
                    //bomProduct.totalCost = (totalCost === 0 ? txtRADB_NA : formatToDollars(totalCost));
                    bomProduct.totalCost = (totalCost === 0 ? txtRADB_NA : data.displayPrice);
                    this.bomTotalPrice += totalCost;
                    this.productTimedOut = (this.productTimedOut || raiseDevDataQualifiesForRefetch(data));
                }
            }
        })
    }

    run(itemsConsildated: BOMItem[], refetchTimedOutData = false) {
        this.fnSetShowRefreshComponentCallback(false);
        // The consolidated bom should have ALL products as
        // children of the header.
        if (itemsConsildated.length === 1) {
            const bomMainHeader = itemsConsildated[0];
            const products = bomMainHeader.children;
            this.refetchTimedOutData = refetchTimedOutData;

            // Define an inline async function.
            const _collectBomItemsTotals = async () => {
                await this.calculateTotalBOMPrice(products);
                if (this.awaitingBOMProductData() === false) {
                    const totalsText = `${formatToDollars(this.bomTotalPrice)} / ${getTotalProductCount()} Items`;
                    this.fnSetShowRefreshComponentCallback(this.productTimedOut);
                    this.fnSetTotalsComponentCallback(totalsText);
                }
            }

            // Call the above inline async function.
            _collectBomItemsTotals();
        }
    }

    // We need to define 2 callback functions for retrieving
    // raise data. The problem with callbacks is that we lose
    // the 'this' pointer. By defining an arrow function,
    // _raiseDataCallBack() is called in the context of this 
    // class (i.e. the 'this reference' is this class.);
    raiseDataCallback = (catalog: string, data: RaiseDevData) => { this._raiseDataCallBack(catalog, data); }
    _raiseDataCallBack(catalog: string, data: RaiseDevData) {
        const bomProduct = this.getAwaitingBOMProduct(catalog);
        if (bomProduct != null) {
            // Update the BOM Product and our total cost.
            const totalCost = data.displayPriceVal * bomProduct.quantity;
            // 2024.4.10 We are NOT going to deal with
            // numbers here. The displayPrice has the
            // correct price for a SINGLE unit formatted
            // with the correct currency. Set the total
            // cost to the displayPrice (not using displayPriceVal).
            //bomProduct.totalCost = (totalCost === 0 ? txtRADB_NA : formatToDollars(totalCost));
            bomProduct.totalCost = (totalCost === 0 ? txtRADB_NA : data.displayPrice);
            this.bomTotalPrice += totalCost;
            this.productTimedOut = (this.productTimedOut || raiseDevDataQualifiesForRefetch(data));

            // Remove the product from our manager, which
            // return the number of remaining unresolved data
            // requests. If we have none...
            if (this.removeAwaitingBOMProduct(catalog) === 0) {
                // Notify the component we are done.

                // Show the component's refresh option if we had
                // any items that timed out.
                this.fnSetShowRefreshComponentCallback(this.productTimedOut);

                const totalsText = `${formatToDollars(this.bomTotalPrice)} / ${getTotalProductCount()} Items`;
                this.fnSetTotalsComponentCallback(totalsText);

                this.reset();
            }
        }
    }

    getRaiseDeviceDevData(catalog: string, bomProd: BOMItemProduct, callback: RaiseDBDevDataCallback): RaiseDevData {
        const data = getRaiseDeviceData(catalog, callback, this.refetchTimedOutData);
        if (data.status === RADBCacheStatus.Loading)
            this.addAwaitingBOMProduct(catalog, bomProd);

        return data;
    }

    addAwaitingBOMProduct(catalog: string, bomProd: BOMItemProduct) { this.mapFetchingToBOMProd.set(catalog, bomProd); }
    removeAwaitingBOMProduct(catalog: string): number { this.mapFetchingToBOMProd.delete(catalog); return this.mapFetchingToBOMProd.size; }
    getAwaitingBOMProduct(catalog: string): BOMItemProduct | undefined { return this.mapFetchingToBOMProd.get(catalog); }

    awaitingBOMProductData() { return this.mapFetchingToBOMProd.size !== 0; }
}