import { Chassis } from "../types/ProjectTypes";

export enum LogMsgLevel {
	none = 0,
	info = 1,
	warning = 2,
	error = 3,
}

export enum ProjectStatusFilterButtonType{
	Info = "Information",
    Warning="Warning",
    Error="Error"
}


export enum StatusLogType {
	unknown = 0,
	project = 1,
	chassis = 2,
}

export interface ProjectLogMessage {
	message: string;
	level: LogMsgLevel;
	idObject: string;
	idAutoFix: string;
	customFixBtnText?: string;
}

export const getNewProjectLogMessage = (
	message: string | undefined = undefined,
	level: LogMsgLevel | undefined = undefined,
	chassis: Chassis | null = null,
	idAutoFix: string = ''): ProjectLogMessage => {
	return {
		message: message == null ? '' : message,
		level: level == null ? LogMsgLevel.none : level,
		idObject: chassis && chassis.name ? chassis.name : '',
		idAutoFix: idAutoFix,
	};
}

export interface ProjectLog {
	messages: ProjectLogMessage[];
	logStatus: LogMsgLevel;
	errors: number;
	warnings: number;
	infos: number;
	show: boolean;
	logType: StatusLogType;
	chassis?: Chassis
}

export const getNewProjectLog = (logType: StatusLogType): ProjectLog => {
	return {
	messages: [],
	logStatus: LogMsgLevel.none,
	errors: 0,
	warnings: 0,
	infos: 0,
	show: false,
	logType: logType,
	};
}

// Log Utilities
export const addLogMessage = (
	log: ProjectLog,
	message: string,
	level: LogMsgLevel,
	chassis: Chassis | null = null,
	idAutoFix: string = '',
	customFixBtnTxt: string = ''
) => {
	// For now, prefix the message with the level text.
	let finalFormatMessage = message;
	switch (level) {
		case LogMsgLevel.error:
			finalFormatMessage = 'Error: ' + message;
			log.errors++;
			break;
		case LogMsgLevel.warning:
			finalFormatMessage = 'Warning: ' + message;
			log.warnings++;
			break;
		case LogMsgLevel.info:
			finalFormatMessage = 'Information: ' + message;
			log.infos++;
			break;
	}

	// Update the log status if new msg is at a higher level.
	if (log.logStatus < level) {
		log.logStatus = level;
		log.show = true;
	}

	// Add the message to the array.
	const msg = getNewProjectLogMessage(finalFormatMessage, level, chassis, idAutoFix);
	if (customFixBtnTxt) {
		msg.customFixBtnText = customFixBtnTxt;
	}
	log.messages.push(msg);
}


export const mergeLog = (destinationLog: ProjectLog, sourceLog: ProjectLog, mergeMessages: boolean) => {
	destinationLog.errors += sourceLog.errors;
	destinationLog.warnings += sourceLog.warnings;
	destinationLog.infos += sourceLog.infos;

	if (mergeMessages)
		destinationLog.messages.push(...sourceLog.messages);

	if (destinationLog.logStatus < sourceLog.logStatus)
		destinationLog.logStatus = sourceLog.logStatus;

	destinationLog.show = (destinationLog.show || sourceLog.show);
}

const _logMessageSortPredicate = (a: ProjectLogMessage, b: ProjectLogMessage): number => {
	// if A level is less than B, put B before A (return 1).
	if (a.level < b.level)
		return 1;
	else if (a.level > b.level)
		return -1;

	// levels are equal, sort on object ID (most likely to be the chassis name)
	return a.idObject.localeCompare(b.idObject);
}

export const sortLogMessages = (log: ProjectLog) => {
	log.messages.sort(_logMessageSortPredicate);
}