//// Component Interfaces ///////
// PointEntry Component.
export interface PointEntryInfo {
    // Platform (clx/cplx/etc.)
    platform: string;

    // Bitsets
    typeID: number; // base type (DI/DO/AI/AO)
    filterInclude: number;
    filterExclude: number;

    // Selections for Advance and Basic I/O Entry Mode.
    advancedModule?: string;
    basicModule?: string;
    isAdvModDefault: boolean;

    // In Advanced Mode, combo catalog options.
    moduleDisplayStrs: string[];
    moduleCatalogs: string[];

    // Selected Points.
    points: number;

    // Calculated module count.
    advancedModCount: number;
    basicModCount: number;

    // Advanced phase 2 features.
    moduleSelInMultipleEntries: boolean;
    consolidateModule: boolean;

    // UI
    selected: boolean;
    indexEntry: number;
    onDelete: ((idx: number) => void) | null;
    onDuplicate: ((idx: number) => void) | null;
    OnChanged: () => void; // callback

    savedSelectionInvalid: boolean;
    invalidEntry: boolean;
}

// PointEntrySection Component
export interface PointEntrySectionInfo {
    entries: PointEntryInfo[];
    platform: string;       // Main platform
    externalMaskChanged: boolean;
    externalPercentSpareIOChanged: boolean;
    initialized: boolean;
    clearSelectAll: boolean;
}

export interface PointTypeEntry {
    catalog: string;
    display: string;
}

export interface PointTypeGroup {
    typeID: number; // DI/DO/AI/AO
    options: PointTypeEntry[];
    groupNotSupported: boolean;
}

export const createNewPointEntrySectionInfo = (): PointEntrySectionInfo => {
    return {
        entries: [],
        externalMaskChanged: false,
        externalPercentSpareIOChanged: false,
        initialized: false,
        platform: '',
        clearSelectAll: false,
    };
}

