import { detachModule, getModuleSlotBreakdown } from "../../model/ChassisProject";
import { Chassis, SlotModulePair } from "../../types/ProjectTypes";
import { PlatformCpLX } from "../PlatformConstants";


export const snapGetModuleLayoutAndPrepForReplace = (chassis: Chassis)
	: SlotModulePair[] => {

	const platform = chassis.platform;

	// Construct an array to hold data pairs, each of which
	// will contain a slot number and a module catalog number.
	const dataPairs = new Array<SlotModulePair>();

	// Determine which slots contain modules in the chassis (if any).
	const [modSlots,] = getModuleSlotBreakdown(chassis.modules);

	// See how many we have in use.
	const slotsInUse = modSlots.length;
	let maxPlatformSlots = (platform === PlatformCpLX ? 32 : 17);

	// If we have an Interconnect Cable present,
	// add an additional slot to the Max.
	if (chassis.modules.some(mod => mod && mod.isInterconnect))
		maxPlatformSlots++;

	if (slotsInUse > maxPlatformSlots)
		throw new Error(`snapGetModuleLayoutAndPrepForReplace(): Slots in use (${slotsInUse}) exceeds ${platform} maximum.`);

	// If any...
	if (slotsInUse > 0) {
		// Walk each entry in the modSlots array. For each...
		for (let i = 0; i < slotsInUse; i++) {
			// Get the slot number.
			const slot = modSlots[i];

			// Then get the module located in the
			// slot of our chassis. We SHOULD ALWAYS
			// get a module here.
			const module = chassis.modules[slot];

			// If we do...
			if (module) {
				// Add a data pair using the current slot and module itself.
				dataPairs.push({ slot: slot, module: module });

				// Then 'detach' the module
				// from its parent chassis.
				detachModule(module);
			}
			else {
				// Unexpected.
				throw new Error('Unexpected ERROR 1 in cplxGetModuleLayout!');
			}
		}

		// Now see how many data pairs we collected.
		const numPairs = dataPairs.length;

		// That number SHOULD match the number of
		// slots that were reported to contain modules.
		// If so...
		if (numPairs !== slotsInUse) {
			// Unexpected.
			throw new Error('Unexpected ERROR 2 in cplxGetModuleLayout!');
		}
	}

	// Return our final array of pairs.
	return dataPairs;
}