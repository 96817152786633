import { logger } from "./Logger";
import { displayUnexpectedErrorMsg } from "./MessageHelp";

const _displayErrMsgByDefault = true;
const _throwErrorByDefault = true;

interface CallbackErrorData {
    msg: string;
}

const dispErrCallback = (status: number, data?: object) => {
    const errData = data as CallbackErrorData;
    const errorMsg = (errData && errData.msg && errData.msg.length)
        ? errData.msg
        : 'UNEXPECTED ERROR: dispErrCallback missing error message!';

    throw new Error(errorMsg);
}

// Helper function to deal with an unexpected error.
export const unexpectedError = (
    msg: string,
    displayMsg = _displayErrMsgByDefault,
    throwError = _throwErrorByDefault
) => {

    // Start by extending the message with std lead text.
    const fullMsg = 'UNEXPECTED ERROR: ' + msg;

    // We'll ALWAYS log these to the console.
    logger.error(fullMsg);

    // If we're supposed to display the message
    // to the user (as a modal popup)...
    if (displayMsg) {
        // Then, we need to decide if we're ALSO supposed
        // to throw an Error. If so, we'll have to do that
        // indirectly, AFTER we get a callback telling us
        // that our popup has actually been dismissed.
        // Otherwise, the exception would just immediately
        // be thrown, and we'd never actually see the popup.
        // If we WILL also be throwing an error...
        if (throwError) {

            // Set up a data object with our
            // message in it.
            const data: CallbackErrorData = {
                msg: fullMsg
            };

            // Call our display helper, and pass it our
            // callback function and our data. We'll get
            // the callback AFTER the popup has been
            // dismissed, and we'll throw the error there.
            displayUnexpectedErrorMsg(fullMsg, dispErrCallback, data);
            }
        else {
            // Not throwing an error. Just call our
            // display helper with no callback or data.
            displayUnexpectedErrorMsg(fullMsg);
        }
    }
    else if (throwError) {
        // We're supposed to throw an error, and we
        // DID NOT display the message to the user.
        // In that case, we can just throw it directly.
        throw new Error(fullMsg);
    }
}