import {  PlatformMicro } from "../../PlatformConstants";
import { ActBtnInfo, DfltActBtnSpecs, LayoutActionType } from "../../../types/LayoutActions";
import { getLocCenter, offsetLoc, isPointInLocBu } from "../../../util/GeneralHelpers";
import { microConfigureChassis } from "../config/MICROChassisConfig";
import {
    updateChassisLayout,
} from "../../../implementation/ImplGeneral";
import {

	detachModule,

} from '../../../model/ChassisProject';
import {
	Chassis,
	ChassisElement,
	ChassisModule,
	PSInputVoltage,
	ChassisPowerSupply,
	ChassisBaseUnit,
	DeviceCategory,
	ModuleDragStatus,
	Rack,
	IOModuleWiring,
    SlotIDError,
	DeviceType,
	ModuleSlotRestriction,SlotModulePair,
	MicroChassis
} from "../../../types/ProjectTypes";
import { LocAndSize, Size, Point } from "../../../types/SizeAndPosTypes";
import { getNewInstanceId } from "../../../util/InstanceIdHelp";
import {
	getDeviceCategory,
	getModuleSlotBreakdown,
	isValidSlotNumber,
    isDeviceCompatibleWithChassis,
    getDefaultImageSource,
} from "../../../model/ChassisProject";
import {
	MICROChassisImages,
	MICROLayoutInfo,
	MICROChassisTemplate,
} from "../types/MicroTypes";
import MicroChassisComp from "../components/MicroChasisComp";
import { StageUnitsPerMM } from "../../../util/LayoutHelp";
import { chassisChanging } from "../../../util/UndoRedo";
import { unexpectedError } from "../../../util/ErrorHelp";

import { logger } from "../../../util/Logger";
import {
    EngInfoChassis,
	EngInfoCommModule,
	EngInfoModule,
} from "../../../engData/EngineeringInfo";
import {
	getChassisEngInfo,
	getMicroModules,
	getCommDetailRoles,
	getModuleEngInfo,
	getPowerSupplyEngInfo
} from "../../../util/EngInfoHelp";
import { addRequiredAccys } from "../../../util/AccessoriesHelp";
import {
	MicroChassisCompProps,
	createModule,
	GeneralImplSpec,
	getNumSlots,
	RegisterGeneralImpl
} from "../../../implementation/ImplGeneral";
import { ChassisElementType, ImageInfo } from "../../common/CommonPlatformTypes";
import { microAddModulesToChassis } from "../../common/HardwareGen";

// const _microChassisImgLoc = `${config.ISD_URL}assets/micro/chassisImgs/`;


// There seems to be some variance in actual product dims,
// but all of the images we use to render MICRO chassis
// elements are the same height - 280 pixels. And, in all
// cases, the given image's height overlays our entire chassis'
// height. That is, each individual image and the combination
// of all of them comprising a chassis rendering are always
// 280 pixels in height. 
const _microElemImageHt = 280; // pixels

// Again, there appears to be some minor variance in height dims
// according to product documentation between specific chassis,
// but they're close enough to establish what WE will consider
// to be the (nominal) height of an entire MICRO chassis (not
// including any mounting tabs that we don't show). That total
// height, as well as the height of the elements that make UP
// the chassis, are ALL considered to be 145 mm tall.
const _microNominalHt = 145;   // millimeters

// Determine a multiplier we can use to convert a
// number in image-size units to millimeters.
const _microImageSizeToMM = _microNominalHt / _microElemImageHt;

const _microImageScaleFactor = _microImageSizeToMM * StageUnitsPerMM;

const _getSizesInStageUnits = true;

const _getMICROSlotSize = (): Size => {

	// Slot images are 70 x 280 pixels.
	// Create a corresponding size in mm.
	const slotSize: Size = {
		width: 70 * _microImageSizeToMM,
		height: 280 * _microImageSizeToMM
	};

	// Then convert to stage units
	// (if we're doing that).
	if (_getSizesInStageUnits) {
		slotSize.width *= StageUnitsPerMM;
		slotSize.height *= StageUnitsPerMM;
	}

	// return the final size.
	return slotSize;
}

const _getMICROSlotGroups = ( slots: string, plugin: string): number[] => {
	return [parseInt(plugin),parseInt(slots)];
}

const assignModuleSlotLocationsMicro = (
    chassis: MicroChassis,
) => {

    // ID starts at 0.
    let nextID = 0;

    // For each module...
    for (let idx = 0; idx < chassis?.modules?.length; idx++) {

        // Get the module.
        const mod = chassis?.modules[idx];

        // If we have a module...
        if (mod) {

            // ALL modules get their .slotIdx prop set
            // to match their position in the chassis.
            mod.slotIdx = idx;

			mod.slotID = nextID;
			nextID++;
            
        }
        else {
            nextID += 1;
        }
    }
}


const _getMICROChasElImgInfo = (xt: boolean, el: ChassisElementType): ImageInfo => {

	// Start by creating an 'empty' ImageInfo.
	const imgInfo: ImageInfo = {
		imgSrc: '',
		width: 0,
		height: 0
	};

	// Depending on env and element type,
	// we'll have different URLs for our 
	// image, and differing widths of the
	// actual images themselves.
	let imageUrl = '';
	let imageWidth = 0;

	// ALL of our images, however, 
	// have the SAME height.
	const imageHeight = _microElemImageHt;

	if (xt) {
		switch (el) {
			case ChassisElementType.EmptySlot:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.emptySlotXT);
				imageWidth = 70;
				break;

			case ChassisElementType.SlotSep:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.slotSepXT);
				imageWidth = 30;
				break;

			case ChassisElementType.RightCap:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.rightCapXT);
				imageWidth = 0;
				break;

			default:
				break;
		}
	}
	else {
		switch (el) {
			case ChassisElementType.EmptySlot:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.emptySlotStd);
				imageWidth = 70;
				break;

			case ChassisElementType.GrpSep:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.grpSepStd);
				imageWidth = 20;
				break;

			case ChassisElementType.RightCap:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.rightCapStd);
				imageWidth = 0;
				break;

			default:
				break;
		}
	}

	// If we got a location of the image in question...
	if (imageUrl.length) {
		// Convert our image's height and width to millimeters.
		let scaledWidth = imageWidth * _microImageSizeToMM;
		let scaledHeight = imageHeight * _microImageSizeToMM;

		// Then, if we're supposed to provide width and
		// height back in Stage units, multiply by 
		// the number of those per millimeter.
		if (_getSizesInStageUnits) {
			scaledWidth = scaledWidth * StageUnitsPerMM;
			scaledHeight = scaledHeight * StageUnitsPerMM;
		}

		// Push our results into the ImageInfo object.
		imgInfo.imgSrc = imageUrl;
		imgInfo.width = scaledWidth;
		imgInfo.height = scaledHeight;
	}

	// Return whatever we ended up with.
	return imgInfo;
}



const _getMICROChassisTemplate = (
	extendedTemp: boolean,
	conformal: boolean,
	slots: string,
	plugin: string
): MICROChassisTemplate => {
	const groups = _getMICROSlotGroups(slots, plugin);
	return {
		extendedTemp: extendedTemp,
		conformal: conformal,
		totalSlots: groups[0],
		slotGroups: groups,
		emptySlot: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.EmptySlot),
		slotSep: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.SlotSep),
		grpSep: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.GrpSep),
		rightCap: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.RightCap)
	};
}


//slot modules for plugin and i/o expansion sepeartion
export const microgetSlotID= (chassis: MicroChassis, slotNum: number): string => {
    const pluginSlot =
      chassis.pluginModules?.length && chassis.pluginModules?.length !== 0
        ? chassis.pluginModules?.length
        : 0;

    // Default
    const mod = chassis.modules[slotNum];
    if (mod) {
      return mod.deviceType === "Expansion I/O" && pluginSlot
        ? (mod.slotID - pluginSlot + 1).toString()
        : (mod.slotID + 1).toString();
    } else {
      if (isValidSlotNumber(chassis, slotNum) && pluginSlot) {
        if (slotNum < pluginSlot) {
          return (slotNum + 1).toString();
        } else {
          return (slotNum - pluginSlot + 1).toString();
        }
      } else {
        return SlotIDError.InvalidSlotNum;
      }
    }
  };

const microGetChassisSlotUsage = (chassis: Chassis):
	[
		slotsInUse: number,
		slotFillers: number,
		lastUsedSlot: number
	] => {

	// Init return values.
	let slotsInUse = 0;
	let slotFillers = 0;
	let lastUsedSlot = -1;

	// Walk modules array. Note that we DON'T auto-increment
	// our slotIdx in the for statement parms, and do that
	// manually inside the look instead.
	for (let slotIdx = 0; slotIdx < chassis.modules.length;) {

		// Get the module at the given slot if there is one.
		const mod = chassis.modules[slotIdx];

		// If so...
		if (mod) {
			// Bump our total slots in use by the
			// number of slots used by the module.
			slotsInUse += mod.slotsUsed;

			// If the module is a slot filler,
			// bump that quantity as well.
			if (mod.slotFiller) {
				slotFillers += 1;
			}

			// Change our last used slot to be the 
			// LAST slot used by the module.
			lastUsedSlot = mod.slotIdx + mod.slotsUsed - 1;

			// Bump our idx by that size.
			slotIdx += mod.slotsUsed;
		}
		else {
			// No module at the slot. 
			// Just increment our idx.
			slotIdx++;
		}
	}

	// Return our final numbers.
	return [slotsInUse, slotFillers, lastUsedSlot];
}

// Returns number of slots either empty
// or currently occupied by a slot filler.
export const microGetNumAvailableSlots = (chassis: Chassis): number => {
	const numSlots = getNumSlots(chassis);
    const [slotsInUse, slotFillers,] = microGetChassisSlotUsage(chassis);
    const inUseNotInclFillers = slotsInUse - slotFillers;
    return (numSlots - inUseNotInclFillers);
}


// WCS: no need for platform specific override.
// What we had here is now default behavior.
// Warning: incoming slotNum expected to ALREADY have
// been range-checked for specific chassis application.
//const microGetSlotID = (chassis: Chassis, slotNum: number): string => {
//	if (isValidSlotNumber(chassis, slotNum)) {
//		return slotNum.toString();
//	}
//	else {
//		return SlotIDError.InvalidSlotNum;
//	}
//}

export const getMICROChassisBasics = (chassisCatno: string, buCatNo: string):
	[et: boolean, conformal: boolean, Eio: string, plugin: string] => {
	const getEngData = getMicroModules(PlatformMicro, buCatNo);
	const info = getChassisEngInfo(PlatformMicro, chassisCatno);
	if (info && getEngData) {
		return [info.envInfo.etOk, info.envInfo.ccOk, getEngData.Eio,getEngData.plugin ];
	}
	else {
		throw new Error('getMicroChassisBasics FAILED to get eng data for: ' + chassisCatno);
	}
}



export const microgetChassisTemplateForCat = (chassisCat: string, buCatNo: string): MICROChassisTemplate | null => {
	const [xt, conformal, Eio, plugin] = getMICROChassisBasics(chassisCat,buCatNo );
	return _getMICROChassisTemplate(xt, conformal, Eio, plugin);
}


const microGetImageSource = (category: DeviceCategory, imgName: string): string => {
	let imgSrc = '';
	switch (category) {
		case DeviceCategory.Chassis:
		case DeviceCategory.BU:
			imgSrc = 'https://configurator.rockwellautomation.com/api/doc/'+imgName;
			break

		case DeviceCategory.Module:
			imgSrc = getDefaultImageSource(imgName);
			break;
			case DeviceCategory.Other:
				imgSrc = getDefaultImageSource(imgName);
				break;
		default:
			imgSrc = getDefaultImageSource(imgName);
			break;
	}

	if (imgSrc.charAt(imgSrc.length - 4) !== '.') {
		imgSrc += '.png';
	}

	return imgSrc;
}

const _makeEmptyMICROLayout = (xt: boolean, conformal: boolean): MICROLayoutInfo => {
	return {
		platform: PlatformMicro,
		numFPDs: 0,
		extendedTemp: xt,
		pluginSlot:0,
		ioExpansionSlot:0,
		conformal: conformal,
		numSlots: 0,
		emptySlotImgSrc: '',
		slotLocs: new Array<LocAndSize>(),
		slotSepImgSrc: '',
		slotSepLocs: new Array<LocAndSize>(),
		grpSepImgSrc: '',
		grpSepLocs: new Array<LocAndSize>(),
		rightCapImgSrc: '',
		rightCapLoc: { x: 0, y: 0, width: 0, height: 0 },
		size: { width: 0, height: 0 }
	};
}


const _microMakeChassisBU = (pbsCat: string, ps?: ChassisPowerSupply,slotWidth?: number):
	ChassisBaseUnit | undefined=> {
	slotWidth  = slotWidth || 0;
	const psEngInfo = getMicroModules(PlatformMicro, pbsCat);
	let powerSupply = 0;
	if(ps){
		powerSupply = ps.imgSize.width
	}
	if (psEngInfo) {
	let imageWidth = 0;
	let widthCalc = 15;
		if(parseInt(psEngInfo.plugin) === 5){
			imageWidth =  370;
		}
		if(parseInt(psEngInfo.plugin) === 3){
			imageWidth =  250;
			widthCalc = 13;
		}
		if(parseInt(psEngInfo.plugin) === 2){
			imageWidth = 160;

		}
		const scaledSize: Size = {
			width: (imageWidth * _microImageSizeToMM )+ (widthCalc * parseInt(psEngInfo.plugin)),
			height: (150 * _microImageSizeToMM +68) 
		};
		if (_getSizesInStageUnits) {
			scaledSize.width *= StageUnitsPerMM;
			scaledSize.height *= StageUnitsPerMM;
		}
        const initialWidth = imageWidth - slotWidth;
		const bu: ChassisBaseUnit| undefined = {
			id: getNewInstanceId(),
			platform: PlatformMicro,
			initialWidth:initialWidth < 50 ? slotWidth : initialWidth,
			deviceType: DeviceType.BU,
			catNo: pbsCat,
			description: psEngInfo.description,
			isPlaceholder: psEngInfo.isPlaceholder,
			extendedTemp: psEngInfo.envInfo.etOk,
			conformal: psEngInfo.envInfo.ccOk,
			accysPossible: psEngInfo.anyAccysPossible(),
			category: DeviceCategory.BU,
			imgSrc: microGetImageSource(DeviceCategory.BU, psEngInfo.imgName),
			imgSize: scaledSize,
			selected: false,
			movable: false,
			dragStatus: ModuleDragStatus.NA,
			loc: {
				x: powerSupply + 0,
				y: 0,
				width: scaledSize.width,
				height: scaledSize.height
			},
		
		
			parent: undefined
		};
		addRequiredAccys(bu);

		return bu;

	}
}


const getImageInfo = (info: ImageInfo): [imgSrc: string, width: number, height: number] => {
	return [info.imgSrc, info.width, info.height];
}

const _makeMICROLayoutInfo = (tmpl: MICROChassisTemplate,buCatNo: string, ps?: ChassisPowerSupply):
	[layout: MICROLayoutInfo,bu: ChassisBaseUnit | undefined] => {
	const layout = _makeEmptyMICROLayout(tmpl.extendedTemp, tmpl.conformal);

	const [, slotWidth, slotHeight] = getImageInfo(tmpl.emptySlot);
	const [, rightCapWidth, rightCapHeight] = getImageInfo(tmpl.rightCap);

	layout.pluginSlot = tmpl.slotGroups[0];
	layout.ioExpansionSlot = tmpl.slotGroups[1];
	layout.emptySlotImgSrc = 'https://configurator.rockwellautomation.com/api/doc/2085_Empty.png';
	layout.slotSepImgSrc = '';
	layout.grpSepImgSrc = '';
	layout.rightCapImgSrc = 'https://configurator.rockwellautomation.com/api/doc/2085_ERC_DI.png';

	let lastX = 0;

	layout.size = { width: 0, height: 0 };
	const bu: ChassisBaseUnit | undefined =  _microMakeChassisBU(buCatNo, ps, slotWidth)
  let xps = 0;

	for (let grpIdx = 0; grpIdx < tmpl.slotGroups.length; grpIdx++) {
    if(ps){
			xps =  ps.imgSize.width;
		}
		// lastX calculation of i/o expansion included addition of Controller image width 
		if (bu && grpIdx === 1) {
			lastX =  xps + bu.imgSize.width;
		}
		// lastX calculation of Plugin included addition of starting width is difffrence based on each controller
		if (bu && grpIdx === 0) {	  
			if((tmpl.slotGroups[0]) === 5){
				lastX = xps + 250;
			}
			if((tmpl.slotGroups[0]) === 2){
				lastX = xps + 150;
			}
			if((tmpl.slotGroups[0]) === 3){
				lastX = xps + 150;
			}
	}
		const slotsInGrp = tmpl.slotGroups[grpIdx];
		layout.numSlots += slotsInGrp;
        //grpIdx is 0 means it is plugin
		if(grpIdx === 0){

			for (let slotIdx = 0; slotIdx < slotsInGrp; slotIdx++) {
			
				layout.slotLocs.push({ x: lastX, y: 80, width: slotWidth, height: 385 });
				lastX += slotWidth;
			}
		}
		// grpIdx is 1 means it is i/o expansion
		if(grpIdx === 1){

			for (let slotIdx = 0; slotIdx < slotsInGrp; slotIdx++) {
			
				layout.slotLocs.push({ x: lastX, y: 0, width: slotWidth, height: slotHeight });
				lastX += slotWidth;
			}
		}
	}
	layout.rightCapLoc = { x: lastX, y: 0, width: 0, height: 0 };
	lastX += rightCapWidth;

	layout.size.width = lastX;
	layout.size.height = rightCapHeight;
    
	return [layout, bu];
}

export const microCreatePowerSupply = (psCat?: string, inputVoltage?: PSInputVoltage): ChassisPowerSupply | undefined => {
    if (!psCat)
        return undefined;

    const psEngInfo = getPowerSupplyEngInfo(PlatformMicro, psCat);
    if (psEngInfo) {
        const psInputVltg = psEngInfo.getInputVoltage(inputVoltage);

        const scaledSize: Size = {
            width: psEngInfo.imgSize.width * _microImageSizeToMM,
            height: psEngInfo.imgSize.height * _microImageSizeToMM
        };
        if (_getSizesInStageUnits) {
            scaledSize.width *= StageUnitsPerMM;
            scaledSize.height *= StageUnitsPerMM;
        }

        const ps: ChassisPowerSupply = {
            id: getNewInstanceId(),
            platform: PlatformMicro,
            deviceType: DeviceType.PS,
            catNo: psCat,
            description: psEngInfo.description,
            isPlaceholder: psEngInfo.isPlaceholder,
            extendedTemp: psEngInfo.envInfo.etOk,
            conformal: psEngInfo.envInfo.ccOk,
            accysPossible: psEngInfo.anyAccysPossible(),
            category: DeviceCategory.PS,
            imgSrc:  'https://configurator.rockwellautomation.com/api/doc/2080-PS120-240VAC.png',
            imgSize: scaledSize,
            selected: false,
            movable: false,
            dragStatus: ModuleDragStatus.NA,
            redundant: psEngInfo.redundant,
            loc: {
                x: 0,
                y: 0,
                width: scaledSize.width,
                height: scaledSize.height
            },
            inputVoltage: psInputVltg,
            parent: undefined
        };

        addRequiredAccys(ps);

        return ps;
    }
    return undefined;
}

export const microAttachModuleAtSlot = (chassis: Chassis, module: ChassisModule, slot: number, skipUpdate: boolean): boolean => {
    // It is STRONGLY recommended that a call to
    // microCompactModuleArray() is made after
    // attaching modules.

    // If we do not have enough slots, add empty slots...
    if (slot >= chassis.modules.length) {
        // We will add undefined until we get to the slot
        // where the module should go.
        while (slot >= chassis.modules.length)
            chassis.modules.push(undefined);
    }
    else {
        // We have a slot present. If it's not empty...
        const mod = chassis.modules[slot];
        if (mod)
            return false;
    }

    // Set the slot and parent.
    chassis.modules[slot] = module;
    module.parent = chassis;

    // Set the slot lables for micro 800.
    assignModuleSlotLocationsMicro(chassis);

    if (!skipUpdate) {
        updateChassisLayout(chassis);
    }

    return true;
}

export const microGetModuleLayoutAndPrepForReplace = (chassis: Chassis)
	: SlotModulePair[] => {

	// Construct an array to hold data pairs, each of which
	// will contain a slot number and a module catalog number.
	const dataPairs = new Array<SlotModulePair>();

	// Determine which slots contain modules in the chassis (if any).
	const [modSlots,] = getModuleSlotBreakdown(chassis.modules);

	// See how many we have in use.
	const slotsInUse = modSlots.length;
	// If any...
	if (slotsInUse > 0) {
		// Walk each entry in the modSlots array. For each...
		for (let i = 0; i < slotsInUse; i++) {
			// Get the slot number.
			const slot = modSlots[i];

			// Then get the module located in the
			// slot of our chassis. We SHOULD ALWAYS
			// get a module here.
			const module = chassis.modules[slot];

			// If we do...
			if (module) {
				// Add a data pair using the current slot and module itself.
				dataPairs.push({ slot: slot, module: module });

				// Then 'detach' the module
				// from its parent chassis.
				detachModule(module);
			}
			else {
				// Unexpected.
				throw new Error('Unexpected ERROR 1 in cplxGetModuleLayout!');
			}
		}

		// Now see how many data pairs we collected.
		const numPairs = dataPairs.length;

		// That number SHOULD match the number of
		// slots that were reported to contain modules.
		// If so...
		if (numPairs !== slotsInUse) {
			// Unexpected.
			throw new Error('Unexpected ERROR 2 in cplxGetModuleLayout!');
		}
	}

	// Return our final array of pairs.
	return dataPairs;
}

const microUpdateChassis = (chassis: MicroChassis) => {
	// Once this isIoSeperate flag is true update chassis happens during deletion of controller no need to go this function

	if(chassis && chassis?.isIoSeperate ){
		assignModuleSlotLocationsMicro(chassis);
		const buCatNo = chassis.bu ? chassis?.bu?.catNo : chassis?.buCatnumber
		const template = buCatNo && microgetChassisTemplateForCat(chassis.catNo, buCatNo);
		if(template ){
			const [layout,bu] = _makeMICROLayoutInfo(template, buCatNo, chassis?.ps);
			chassis.layout = layout;
			chassis.bu = bu;
			chassis.isBU = true;
			if(bu){
				bu['parent'] = chassis;
			}
		}

	}

}


const microCreateChassis = (chasEngInfo: EngInfoChassis, psCatNo?: string, buCatNo?: string): MicroChassis | undefined => {
	const template = buCatNo && microgetChassisTemplateForCat(chasEngInfo.catNo, buCatNo);
	const ps =   microCreatePowerSupply(psCatNo);
	if(template){
		const layout = _makeEmptyMICROLayout(false, false);

		const chassis: MicroChassis = {
			id: getNewInstanceId(),
			bump: 0,
			bu: undefined,
			dragTarget: false,
			buCatnumber:buCatNo,
			xSlotWidth: 0,
			isIoSeperate: true,
			platform: PlatformMicro,
			deviceType: DeviceType.Chassis,
			catNo: chasEngInfo.catNo,
			description: chasEngInfo.description,
			isPlaceholder: chasEngInfo.isPlaceholder,
			isBU: true,
			imgSrc: 'https://configurator.rockwellautomation.com/api/doc/2080-LC50_48.png',
			extendedTemp: false,
			conformal: template.conformal,
			accysPossible: chasEngInfo.anyAccysPossible(),
			ps: ps,
			layout: layout,
			modules: new Array<ChassisModule>(template.slotGroups[0]),
			pluginModules: new Array<ChassisModule>(template.slotGroups[0]),
			ioExpansionModule: new Array<ChassisModule>(template.slotGroups[1]),
			selected: false,
			parent: undefined,
			redundant: false,
			defaultIOModWiring: IOModuleWiring.Screw,
			statusLog: undefined,
		};
  
		updateChassisLayout(chassis)
		// Success.
		return chassis;
	}

	return undefined;

}

//interface SpecialLocalConnMod extends OLD_ModuleProdData {
//	conn_local?: string;
//}

// SPECIAL HANDLING ONLY. Should only be called for a module
// already determined to NOT be any of: controller, comm, or conn client.
// Used to detect special-case modules that aren't recognized as
// communication-related, but DO add LOCAL connection requirements
// to (some processor found in) the chassis they're located in.
const _getSpecialLocalConnRqmt = (modInfo: EngInfoModule): number => {

	// We use this to pick up connection requirements for our
	// 'Communication Module' modules that are NOT qualified as comms,
	// which would include DeviceNet and RIO adapters.
	// IFF the incoming type MIGHT be one of those...
	if (modInfo.isCommModule) {
		const asCommMod = modInfo as EngInfoCommModule;
		return asCommMod.localConns;
	}

	// If we're still here, just return 0.
	// The incoming modData does NOT indicate
	// any special local connection requirement.
	return 0;
}

const microCreateModule = (catNo: string): ChassisModule | null => {

	//const modData = getMICROProdData(catNo) as OLD_ModuleProdData;
	const modInfo = getModuleEngInfo(PlatformMicro, catNo);

	if (modInfo) {
		const devType = modInfo.type;
		const slotSize = _getMICROSlotSize();
		const commDtlsRoles = getCommDetailRoles(PlatformMicro, catNo);
		const specialLocalConns = commDtlsRoles.anyRoles
			? 0
			: _getSpecialLocalConnRqmt(modInfo);
		return {
			id: getNewInstanceId(),
			platform: PlatformMicro,
			deviceType: devType,
			catNo: catNo,
			description: modInfo.description,
			isPlaceholder: modInfo.isPlaceholder,
			extendedTemp: modInfo.envInfo.etOk,
			conformal: modInfo.envInfo.ccOk,
			accysPossible: modInfo.anyAccysPossible(),
			category: getDeviceCategory(devType),
			imgSrc: microGetImageSource(DeviceCategory.Module, modInfo.imgName),
			imgSize: modInfo.type === "Plug-In" ? { width: 200, height: 100 } : { width: 548, height: slotSize.height },
			parent: undefined,
			movable: true,
			slotIdx: -1,
			slotID: -1,
			slotsUsed: 1,
			selected: false,
			dragStatus: ModuleDragStatus.NA,
			isController: commDtlsRoles.isController,
			isComm: commDtlsRoles.isComm,
			isConnClient: commDtlsRoles.isConnClient,
			spclLocalConns: specialLocalConns,
			slotFiller:false,
			isFPD: false,
			isInterconnect: false,
			//okInAnyChassisType: modData.okInAnyChassisType
		};
	}

	logger.error('createModule could not create ChassisModule from: ' + catNo);
	return null;
}

export const microAddModuleAtSlot = (
	chassis: MicroChassis,
	catNo: string,
	slotNum: number,
): boolean => {

	// Create the requested module.
	const module = createModule(chassis.platform, catNo);
	//  plugin and io expansion modules added 
	if (module && (module.deviceType !== DeviceType.Controller && chassis.bu?.imgSrc !== '')) {

		// BEFORE making any change, call our helper
		// to handle any related undo/redo work for us.
		chassisChanging(chassis)
		// THEN, make the actual changes.
		chassis.modules[slotNum] = module;
		module.parent = chassis;
		module.slotIdx = slotNum;
		module.slotID = slotNum;
		addRequiredAccys(module);

		return true;
	}
	// controller modules modules added 
	else if(module && (module.deviceType == DeviceType.Controller)){
		const template = microgetChassisTemplateForCat(chassis.catNo, module.catNo);
		// Maximum IO expoansion found is 8 for microcontroller family
		const maxIoExpansion = 9;
		const buCat = module.catNo;
		if(template && buCat ){
			const prevIoexpansion = template?.slotGroups[1] ? template?.slotGroups[1] : 0;
			template.slotGroups[1] = maxIoExpansion; 
			// chassis.modules= new Array<ChassisModule>(template.slotGroups[0]),
			// chassis.pluginModules= new Array<ChassisModule>(template.slotGroups[0])
		
			const [layout,bu] = _makeMICROLayoutInfo(template, buCat, chassis.ps);
			// if not provided.
			chassis.layout = layout;
			chassis.bu = bu;
			const ioModulesSave= chassis?.modules?.filter(res=> res?.deviceType === DeviceType.IOExpansion) ?
			chassis?.modules?.filter(res=> res?.deviceType === DeviceType.IOExpansion) : [] ;
			chassis.modules= new Array<ChassisModule>(template.slotGroups[0])
			chassis.pluginModules =  new Array<ChassisModule>(template?.slotGroups[0])
			chassis.modules=  [...chassis.modules,...ioModulesSave]
			const pluginLength =  chassis?.pluginModules?.length ? chassis?.pluginModules?.length : 0;
			const widthX = chassis?.bu?.imgSize?.width ? chassis.bu?.imgSize?.width : 0;
			const ioExpansionWidth = 137.00787401574806;
			const ioExpansionHeight = 548.0314960629922;
			let lastX = 0;
			chassis?.layout?.slotLocs?.splice(pluginLength,maxIoExpansion )
			const moduleLength = (prevIoexpansion >= ioModulesSave.length) ? prevIoexpansion : ioModulesSave.length ;
			lastX = widthX;
			for (let slotIdx = 0; slotIdx < pluginLength; slotIdx++){
				if(slotIdx<pluginLength){
					chassis.modules[slotIdx]= undefined;
				}
			}
			for (let slotIdx = pluginLength; slotIdx < (pluginLength + moduleLength); slotIdx++) {
				chassis?.layout?.slotLocs.push({ x: lastX, y: 0, width: ioExpansionWidth, height: ioExpansionHeight });
				lastX += ioExpansionWidth;
			}
		
			if(bu){
				bu['parent'] = chassis;
			}
			assignModuleSlotLocationsMicro(chassis);
	
		
		}
		chassis.isIoSeperate = false;
		chassisChanging(chassis)
		addRequiredAccys(module);
		return true;
		}
		// once controller deleted, io expansion seperation needed and make maximum slots 
		else if (module && (module.deviceType !== DeviceType.Controller && chassis.bu?.imgSrc === '')) {
			// BEFORE making any change, call our helper
		// to handle any related undo/redo work for us.
		chassisChanging(chassis)
		// THEN, make the actual changes.
		chassis.modules[slotNum] = module;
		module.parent = chassis;
		module.slotIdx = slotNum;
		module.slotID = slotNum;
		chassis.isIoSeperate = false;
		addRequiredAccys(module);
		
		return true;
	}
		
		
		return false;
	}
	

const dropMICROChassisModule = (
	chassis: Chassis,
	mod: ChassisModule,
	element: ChassisElement,
	slotNum: number
): boolean => {

	// If the element provided is a slot..
	if (element === ChassisElement.Slot) {

		// And we were given a valid slot number...
		if (isValidSlotNumber(chassis, slotNum)) {

			// See if that slot already has a module in it.
			const occupant = chassis.modules[slotNum];

			// If so, we can't add the requested module there.
			if (occupant) {
				return false;
			}
			else {
				// We WILL be adding the module, but
				// BEFORE making any change, call our helper
				// to handle any related undo/redo work for us.
				chassisChanging(chassis);

				mod.dragStatus = ModuleDragStatus.NA;
				mod.slotIdx = slotNum;
				mod.slotID = slotNum;
				mod.parent = chassis;
				chassis.modules[slotNum] = mod;
				return true;
			}
		}
		else {
			throw new Error('dropMICROChassisModule - invalid chassis or slot number!');
		}
	}
	return false;
}

const _isModuleValidInSlot = (
	chassis: Chassis,
	module: ChassisModule,
	slotNum: number
): boolean => {
	if (isValidSlotNumber(chassis, slotNum)) {
		const occupant = chassis.modules[slotNum];
		if (!occupant) {
			return true;
		}
	}

	return false;
}

const microGetMaxNewModules = (chassis: MicroChassis, restriction: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {

	// MICRO doesn't distinguish any modules as
	// being restricted to certain slots only. 
	let maxNew = 0;
	const maxMicroSlot = 8;
	const pluginSlot = chassis?.pluginModules?.length ? chassis?.pluginModules?.length : 0;

	//Iteration for plugin and i/o expansion restriction
    if(restriction === ModuleSlotRestriction.SlotWithPlugin){
      for (let slot = 0; slot < pluginSlot ; slot++) {
        const mod = chassis.modules[slot];
        if (mod == null) {
          maxNew += 1;
        }
        else if (treatSlotFillerAsEmptySlot && mod.slotFiller) {
          maxNew += 1;
        }
      }

	}
	if(restriction === ModuleSlotRestriction.SlotWithIOexpansion){
		let layoutLength = chassis.layout.slotLocs.length;
		if(chassis.bu?.imgSrc === ''){
			layoutLength =  maxMicroSlot  + pluginSlot
		}
		for (let slot = pluginSlot; slot < layoutLength ; slot++) {
			const mod = chassis.modules[slot];
			if (mod == null) {
				maxNew += 1;
			}
			else if (treatSlotFillerAsEmptySlot && mod.slotFiller) {
				maxNew += 1;
			}
		}
	}

	if(restriction === ModuleSlotRestriction.SlotWithBU){
		maxNew = 1;
		}
		
	return maxNew;
}


const microAddModuleToChassis = (
	chassis: Chassis,
	module: ChassisModule
): boolean => {

	//if (_doDeviceAndChassisEnvsMatch(chassis, module.xt, module.conformal)) {
	if (isDeviceCompatibleWithChassis(module, chassis)) {
		const [, emptySlots] = getModuleSlotBreakdown(chassis.modules);
		for (let idx = 0; idx < emptySlots.length; idx++) {
			const slotNum = emptySlots[idx];
			if (_isModuleValidInSlot(chassis, module, slotNum)) {
				if (dropMICROChassisModule(chassis, module, ChassisElement.Slot, slotNum)) {
					return true;
				}
				else {
					unexpectedError('dropMICROChassisModule FAILED after slot validatation?');
				}
			}
		}
	}

	return false;
}

const microAddNewModuleAtSlot = (chassis: Chassis, slot: number): boolean => {
	// Warning suppression
	chassis;
	slot;

	// Function is not currently used, and will be ultimately
	// replaced with a modal-based approach of some sort.
	// Just Fail for now.
	return false;
}
export const iterationSlotMicro=(chassis: Chassis, pluginLength: number):[totalUsedPluginSlots: number, totalUsedExpansionSlots: number]=>{
	let totalUsedPluginSlots = 0;
    let totalUsedExpansionSlots = 0;
		chassis.modules.forEach((mod, index) => {
		if (mod && index < pluginLength) {
			totalUsedPluginSlots++;
		} else if (mod && index >= pluginLength) {
			totalUsedExpansionSlots++;
		}
    });
	return [totalUsedPluginSlots, totalUsedExpansionSlots]
}

const microDeleteModuleAtSlot = (chassis: MicroChassis, slot: number): boolean => {
    const pluginLength = chassis.pluginModules?.length ? chassis.pluginModules?.length : 0
	// If slot number is in valid range...
	if ((slot >= 0) && (slot < chassis.modules.length)) {

		// Get the module at the specified slot.
		const module = chassis.modules[slot];

		// If there is one...
		if (module) {

			// BEFORE making any change, call our helper
			// to handle any related undo/redo work for us.
			chassisChanging(chassis);

			// Make sure the module doesn't have
			// a ref back to our chassis.
			module.parent = undefined;

			// Set the associated modules entry to null.
			chassis.modules[slot] = undefined;
			if(slot >= pluginLength){
				chassis.modules.splice(slot,1);
			}
			assignModuleSlotLocationsMicro(chassis);
			// updateChassisLayout(chassis)
			// getModuleSlotID(chassis.modules)
			// Success.
			return true;
			
		}
		else {
			// Unexpected
			unexpectedError('Request to delete module at slot that has no module in it!');
		}
	}
	else {
		// Unexpected
		unexpectedError('Request to delete module at INVALID slot number!');
	}

	// Fail. We didn't delete anything.
	return false;
}

const microGetActionBtnInfo = (action: LayoutActionType,
	rack: Rack, slotNum: number): ActBtnInfo => {
	//Logic for micro 800 layout once deleted controller/base unit	
	if(slotNum === -1){
    const getMicroChassis = rack.chassis as MicroChassis
	const layout = getMicroChassis.bu as ChassisBaseUnit;
	const slotLoc = { ...layout.loc };
	offsetLoc(slotLoc, rack.ptOrg);
	const pt = getLocCenter(slotLoc);
	pt.y += DfltActBtnSpecs.size;
	return {
		action: action,
		chassis: rack.chassis,
		slot: slotNum,
		ctrPt: pt
	};
		
	}
	else{

	const layout = rack.chassis.layout as MICROLayoutInfo;
	const slotLoc = { ...layout.slotLocs[slotNum] };
	offsetLoc(slotLoc, rack.ptOrg);
	const pt = getLocCenter(slotLoc);
	pt.y += DfltActBtnSpecs.size;
	return {
		action: action,
		chassis: rack.chassis,
		slot: slotNum,
		ctrPt: pt
	};
	}
	
}


const microGetChassisRenderer = (): React.FC<MicroChassisCompProps> => {
	return MicroChassisComp;
}


export const microGetSlotTypeRestrictionMicro = (
  chassis: MicroChassis,
  slotNum: number)=> {
    const pluginLength = chassis?.pluginModules?.length
    ? chassis?.pluginModules?.length
    : 0;
    if (slotNum < pluginLength && slotNum >= 0 ) {
      return ModuleSlotRestriction.SlotWithPlugin;
    } 
	else if(slotNum === -1){
		return ModuleSlotRestriction.SlotWithBU;
	} 
	else {
      return ModuleSlotRestriction.SlotWithIOexpansion;
    }
}


export const microFilterAvailableModulesMicro = (
    chassis: Chassis,
    restrict: ModuleSlotRestriction,
    unfiltered: EngInfoModule[]): EngInfoModule[] => {
    if (restrict === ModuleSlotRestriction.SlotWithPlugin) {
        return unfiltered.filter(mod => mod.isPlugin);
    }
	else if(restrict === ModuleSlotRestriction.SlotWithBU){
		return unfiltered.filter(mod => mod.isBU);
	}
    return unfiltered.filter(mod => mod.isIO);
}


export const microDefaultChassisName = (chassis: Chassis): string => {
	if (chassis.catNo.length) {
		return chassis.catNo;
	}
	else {
		return 'Chassis';
	}
}

const microCanExtendChassis = (chassis: Chassis) => {
    const maxSlots = chassis.layout.slotLocs.length;
    return (maxSlots > chassis.modules.length);
}

const microDeleteBaseUnitAtChassis = (chassis: Chassis) => {
	const getMicroChassis = chassis as MicroChassis
    const isBUimage = getMicroChassis.bu?.imgSrc === '';
    return isBUimage;
}

const microIsPointOnBU = (ptLocal: Point, chassis: MicroChassis)=>{
	if (chassis.bu && isPointInLocBu(ptLocal, chassis.bu.loc, chassis.bu, chassis.ps)) {
        return true;
    }
    return false;
}



const microGetChassisSizeAsDrawn = (chassis: MicroChassis): Size => {


	let extraWidth = chassis?.layout?.slotLocs?.[chassis.modules.length]?.width ? chassis?.layout?.slotLocs?.[chassis.modules.length]?.width : 0;
    if(chassis.modules.length === chassis.pluginModules?.length){
		extraWidth += 70;
	}
	const size = {
		width:  extraWidth + chassis.layout.slotLocs[chassis.modules.length - 1].x + chassis.layout.slotLocs[chassis.modules.length - 1].width,
		height: chassis.layout.size.height
	}
    return size;
}

export const RegisterMicroGeneralImpl = () => {

	const impl: GeneralImplSpec = {
		platform: PlatformMicro,
		imageScaleFactor: _microImageScaleFactor,
		createChassis: microCreateChassis,
        getSlotID: microgetSlotID,
		getChassisSlotUsage: microGetChassisSlotUsage,
		createModule: microCreateModule,
		addModuleToChassis: microAddModuleToChassis,
		addNewModuleAtSlot: microAddNewModuleAtSlot,
		addModuleAtSlot: microAddModuleAtSlot,
		getActBtnInfo: microGetActionBtnInfo,
		getChassisRenderer: microGetChassisRenderer,
		addModulesToChassis: microAddModulesToChassis,
		getMaxNewModules: microGetMaxNewModules,
		filterAvailableModules: microFilterAvailableModulesMicro,
		getSlotTypeRestriction: microGetSlotTypeRestrictionMicro,
		configureChassis: microConfigureChassis,
		deleteModuleAtSlot: microDeleteModuleAtSlot,
		getDefaultChassisName: microDefaultChassisName,
		canExtendChassis: microCanExtendChassis,
		getChassisSizeAsDrawn: microGetChassisSizeAsDrawn,
		deleteBaseUnitAtChassis: microDeleteBaseUnitAtChassis,
		isPointOnBU:microIsPointOnBU,
		updateChassisLayout: microUpdateChassis,
	};

	RegisterGeneralImpl(impl);

}



