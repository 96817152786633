

export const getDateStringNowUTC = (): string => {
    const dateNow = new Date();
    return dateNow.toISOString();
}


// Note: In ALL of the following timespan functions,
// NOW is used as the newer date if not provided.
// All date strings provided expected to be UTC.
export const getSecondsBtwDates = (olderDateStr: string, newerDateStr?: string): number => {
    const newerDate = newerDateStr
        ? new Date(newerDateStr)
        : new Date(); 
    const olderDate = new Date(olderDateStr);
    const msSpan = newerDate.valueOf() - olderDate.valueOf();
    return (msSpan > 0) ? Math.round(msSpan / 1000) : 0;
}

export const getMinutesBtwDates = (olderDateStr: string, newerDateStr?: string): number => {
    const secs = getSecondsBtwDates(olderDateStr, newerDateStr);
    return Math.round(secs / 60);
}

export const getHoursBtwDates = (olderDateStr: string, newerDateStr?: string): number => {
    const mins = getMinutesBtwDates(olderDateStr, newerDateStr);
    const hours = mins / 60;
    return Number(hours.toFixed(1));
}

export const getDaysBtwDates = (olderDateStr: string, newerDateStr?: string): number => {
    const hours = getHoursBtwDates(olderDateStr, newerDateStr);
    const days = hours / 24;
    return Number(days.toFixed(1));
}
