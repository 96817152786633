import { LocAttributeInfo } from "./ProjectTypes";

export type onGuidedSelLoadedCallback = (success: boolean, locAttrInfo: LocAttributeInfo | undefined) => void;
export type onGSAPIResolved = (jsonObj: object, defSrcUsed: boolean, tracker: GSLoadTracker) => void;

export enum RADBCacheStatus {
    None = 0,
    Loading = 1,
    Loaded = 2,
}

export enum RADBFetchStatus {
    None = 0x00,
    Timeout = 0x01,
    Error = 0x02,
}

// Standardize the loading text.
export const txtRADB_LOADING = 'Loading...';
export const txtRADB_NA = 'N/A';

export interface GuidedSelectionInfo {
    status: RADBCacheStatus;
    lastFetchStatus: RADBFetchStatus;
    platform: string;
    industryID: string;
    installLocID: string;
    defGuidedSelection: boolean;
    jsonObj?: object;
}

export interface GSLoadTracker {
    platform: string;
    industryID: string;
    installLocID: string;
    gsCacheKey: string;
    guidedSelectionLoaded: boolean;
    defGuidedSelection: boolean;
    locAttrInfo: LocAttributeInfo;
    platformChanged: boolean;
    guidedSelectionInfo: GuidedSelectionInfo;
    onGSLoadedCallback: onGuidedSelLoadedCallback;
    skipValidation: boolean;
}

// Values are in Config.JS - any additional
// platform related API URLs should be added
// there and here.
export enum ApiTypeID {
    GuidedSelection = 'GUIDESEL_API',
    ProductSelection = 'PRODSEL_API',
    ProductData = 'PLATFORMDATA_API',
    Industries = 'INDUSTRY_API',
    Countries = 'COUNTRY_API',
    EngineeringData = 'PLATFORMDATA_API'
}

export interface PlatformLoadTracker {
    platform: string;
    productSelectionLoaded: boolean;
    industriesLoaded: boolean;
    countriesLoaded: boolean;
    engineeringDataLoaded: boolean;
    defaultGuidedSelectionLoaded: boolean;
    defProductSelection: boolean;
    defProductData: boolean;
    defIndustries: boolean;
    defCountries: boolean;
    defEngineeringData: boolean;
    onAPIResolvedCallBack: onAPIResolvedCallback;
    onDefaultGuidedSelLoaded: onDefaultGuidedSelectionLoaded;
    onAllLoadedCallback: onPlatformLoadedCallback;
}

export const createNewPlatformLoadTracker = (
    platform: string,
    onAllLoadedCallback: onPlatformLoadedCallback,
    apiResolvedCallback: onAPIResolvedCallback,
    onDefGSelLoadedCallback: onDefaultGuidedSelectionLoaded
): PlatformLoadTracker => {
    return {
        platform: platform,
        productSelectionLoaded: false,
        industriesLoaded: false,
        countriesLoaded: false,
        engineeringDataLoaded: false,
        defaultGuidedSelectionLoaded: false,
        defProductSelection: false,
        defProductData: false,
        defIndustries: false,
        defCountries: false,
        defEngineeringData: false,
        onAPIResolvedCallBack: apiResolvedCallback,
        onDefaultGuidedSelLoaded: onDefGSelLoadedCallback,
        onAllLoadedCallback: onAllLoadedCallback,
    };
}

// Callback types.
export type onAPIResolvedCallback = (apiType: ApiTypeID, platformTracker: PlatformLoadTracker) => void;
export type onDefaultGuidedSelectionLoaded = () => void;
export type onPlatformLoadedCallback = (platform: string, success: boolean) => void; // To notify a caller the API request (and parsing) is complete.

